import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrencyCodes } from '@onevesthq/ov-enums';
import { isNil, isUndefined, sum } from 'lodash/fp';
import {
  Box, Grid, Skeleton, Typography,
} from '../../../../1-primative';
import { Card, CardContent } from '../../../../2-component';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { translateBackend } from '../../../../../assets/i18n/config';
import { PieChart } from '../../../../2-component/pieChart/pieChart';
import { formatMoneyValue, formatPercentValue } from '../../../../../util';
import { SelectionChip } from '../../../../3-pattern';
import { currencySymbol } from '../../../../../util/currency';
import { UserContext } from '../../../../../providers/userContextProvider';
import { GroupBy } from './tableHoldings';

type PieChartGroupBy = Exclude<GroupBy, 'security'>;

export const PieChartHoldings = ({
  holdings, loading, options, variant, mainVariable = 'value',
}: {
  holdings: any[], loading: boolean, options: any, variant?: 'elevated' | 'outlined' | 'none'; mainVariable?: 'value' | 'percentage',
}): JSX.Element => {
  const [data, setData] = useState<any[]>([]);
  const [totalValue, setTotalValue] = useState(0);
  const [groupBy, setGroupBy] = useState<PieChartGroupBy>('primary');
  const [activeItem, setActiveItem] = useState<any>(null);
  const { ref, sys } = useThemeTokens();
  const { t } = useTranslation(['client']);
  const { activeCurrency } = useContext(UserContext);

  useEffect(() => {
    if (groupBy === 'primary' && holdings) {
      const d: any = {};
      holdings.forEach((holding) => {
        if (d[holding?.financialProduct?.primaryAssetClass?.id]) {
          d[holding.financialProduct?.primaryAssetClass?.id].totalCents += holding.totalCents;
        } else {
          d[holding?.financialProduct?.primaryAssetClass?.id] = {
            totalCents: holding.totalCents,
            translatedName: holding.financialProduct.primaryAssetClass?.translatedName,
            currency: holding?.currency,
            riskLevel: holding.financialProduct?.primaryAssetClass?.riskLevel || 100,
          };
        }
      });
      setData(Object.values(d));
    } else if (groupBy === 'secondary' && holdings) {
      const d: any = {};
      holdings.forEach((holding) => {
        if (d[holding?.financialProduct?.secondaryAssetClass?.id]) {
          d[holding.financialProduct.secondaryAssetClass.id].totalCents += holding.totalCents;
        } else {
          d[holding?.financialProduct?.secondaryAssetClass?.id] = {
            totalCents: holding.totalCents,
            translatedName: holding?.financialProduct?.secondaryAssetClass?.translatedName,
            currency: holding?.currency,
            riskLevel: holding.financialProduct?.secondaryAssetClass?.riskLevel || 100,
          };
        }
      });
      setData(Object.values(d));
    } else if (groupBy === 'tertiary' && holdings) {
      const d: any = {};
      holdings.forEach((holding) => {
        if (holding.financialProduct?.tertiaryAssetClass?.id) {
          if (d[holding.financialProduct.tertiaryAssetClass.id]) {
            d[holding.financialProduct.tertiaryAssetClass.id].totalCents += holding.totalCents;
          } else {
            d[holding.financialProduct.tertiaryAssetClass.id] = {
              totalCents: holding.totalCents,
              translatedName: holding.financialProduct.tertiaryAssetClass.translatedName,
              currency: holding?.currency,
              riskLevel: holding.financialProduct?.tertiaryAssetClass?.riskLevel || 100,
            };
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (d.NONE) {
            d.NONE.totalCents += holding.totalCents;
          } else {
            d.NONE = {
              totalCents: holding.totalCents,
              translatedName: {
                en: t('none'),
                fr: t('none'),
              },
              currency: holding?.currency,
            };
          }
        }
      });
      setData(Object.values(d));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holdings, groupBy]);

  useEffect(() => {
    if (holdings) setTotalValue(sum(holdings.map((x: any) => x.totalCents)));
  }, [holdings]);

  const colors = [
    ref.palette.supportOne50,
    ref.palette.supportTwo50,
    ref.palette.supportThree50,
    ref.palette.supportFour50,
    ref.palette.supportFive50,
    ref.palette.supportSix50,
  ];

  const primaryTitle = () => (options.primaryAssetClassTitle ? translateBackend(options.primaryAssetClassTitle) : t('primaryAssetClass'));
  const secondaryTitle = () => (options.secondaryAssetClassTitle ? translateBackend(options.secondaryAssetClassTitle) : t('secondaryAssetClass'));
  const tertiaryTitle = () => (options.tertiaryAssetClassTitle ? translateBackend(options.tertiaryAssetClassTitle) : t('tertiaryAssetClass'));

  const sortedData = () => (
    data.sort((a, b) => {
      if (options.sortAssetClassesBy === 'riskLevel') {
        return (a.riskLevel - b.riskLevel);
      }
      return (b.totalCents - a.totalCents);
    })
  );

  const showPrimaryAssetClasses = (isNil(options.showPrimaryAssetClasses) || options.showPrimaryAssetClasses);
  const showSecondaryAssetClasses = (isNil(options.showSecondaryAssetClasses) || options.showSecondaryAssetClasses);
  const showTertiaryAssetClasses = (isNil(options.showTertiaryAssetClasses) || options.showTertiaryAssetClasses);

  useEffect(() => {
    const mapGroupBy: { [key in PieChartGroupBy]: boolean } = {
      primary: showPrimaryAssetClasses,
      secondary: showSecondaryAssetClasses,
      tertiary: showTertiaryAssetClasses,
    };
    if (!mapGroupBy[groupBy]) {
      const groupByEntries = Object.entries(mapGroupBy) as [PieChartGroupBy, boolean][];
      const validGroupByIndex = groupByEntries.findIndex((elem) => elem[1]);
      if (validGroupByIndex === -1) {
        setGroupBy('primary');
      } else {
        setGroupBy(groupByEntries[validGroupByIndex][0]);
      }
    }
  }, [showPrimaryAssetClasses, showSecondaryAssetClasses, showTertiaryAssetClasses, groupBy]);

  return (
    <>
      {loading ? (
        <Skeleton width='100%' height='486px' variant='rectangular' sx={{ mt: 2 }}></Skeleton>
      ) : (
        <Card sx={{ mt: 2 }} variant={variant}>
          <CardContent sx={{ paddingBottom: '16px! important' }}>
            <Box sx={{ pt: 1, pl: 2 }}>
              <SelectionChip
                onChange={(e: any) => setGroupBy(e as 'primary' | 'secondary' | 'tertiary')}
                value={groupBy}
                options={[
                  ...(showPrimaryAssetClasses
                    ? [{ value: 'primary', label: primaryTitle(), dataTestId: 'holdings-pie-chart-selection-chip-primary' }]
                    : []),
                  ...(showSecondaryAssetClasses
                    ? [{ value: 'secondary', label: secondaryTitle(), dataTestId: 'holdings-pie-chart-selection-chip-secondary' }]
                    : []),
                  ...((holdings?.some((elem) => elem.financialProduct?.tertiaryAssetClass)) && showTertiaryAssetClasses
                    ? [{ value: 'tertiary', label: tertiaryTitle(), dataTestId: 'holdings-pie-chart-selection-chip-tertiary' }] : []),
                ]}
              />
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box display='flex' position='relative' alignItems='center' justifyContent='center' pl={{ xs: 3, md: 10 }} pr={{ xs: 3, md: 10 }} pt={3} pb={3}>
                  <PieChart
                    datasets={[{
                      label: '',
                      data: sortedData().map((x: any) => x.totalCents),
                    }]}
                    labels={data.map((x: any) => translateBackend(x.translatedName))}
                    onHover={(index: number | undefined) => setActiveItem(!isUndefined(index) ? data[index] : null)}
                    customColors={sortedData().map((x: any, i: number) => colors[(x.riskLevel < 100 && options.sortAssetClassesBy === 'riskLevel' ? x.riskLevel : i) % 6])}
                  />
                  <Box textAlign='center' position='absolute' left='50%' top='50%' sx={{ transform: 'translate(-50%,-50%)' }} >
                    {
                      activeItem ? (
                        <>
                          <Typography data-testid={'active-pie-chart-holdings-asset-title'} sx={{ color: sys.color.onSurfaceVariant }} variant='bodyLarge'>
                            {translateBackend(activeItem.translatedName)}
                          </Typography>
                          <Typography
                            data-testid='active-pie-chart-holdings-value'
                            variant='displayMedium'
                            sx={{ fontSize: Math.abs(activeItem?.totalCents ?? 0) > 100000000 ? '24px' : '32px' }}
                          >
                            {mainVariable === 'value'
                              ? formatMoneyValue(activeItem?.totalCents, currencySymbol[activeItem?.currency as CurrencyCodes ?? CurrencyCodes.CAD], 2, false)
                              : ((activeItem?.totalCents ?? 0) >= 0 && formatPercentValue((activeItem?.totalCents || 0) / totalValue))
                            }
                          </Typography>
                          {mainVariable === 'value' && (
                            <Typography
                              data-testid='active-pie-chart-holdings-percentage'
                              weight='bold'
                              variant='bodyLarge'
                            >
                              {(activeItem?.totalCents ?? 0) >= 0 && `${formatPercentValue((activeItem?.totalCents || 0) / totalValue)} ${t('ofTotal')}`}
                            </Typography>
                          )}
                        </>
                      ) : (
                        <>
                          <Typography data-testid='pie-chart-holdings-asset-title' sx={{ color: sys.color.onSurfaceVariant }} variant='bodyLarge'>
                            {groupBy === 'primary' ? primaryTitle() : groupBy === 'secondary' ? secondaryTitle() : tertiaryTitle()}
                          </Typography>
                          <Typography
                            data-testid='pie-chart-holdings-value'
                            variant='displayMedium'
                            sx={{
                              fontSize: Math.abs(totalValue) > 100000000 ? '24px' : '32px',
                            }}
                          >
                            {mainVariable === 'value' ? formatMoneyValue(totalValue, currencySymbol[activeCurrency as CurrencyCodes], 2, false) : '100.00%'}
                          </Typography>
                          {mainVariable === 'value' && <Typography data-testid='pie-chart-holdings-percentage' weight='bold' variant='bodyLarge'>100% {t('ofTotal')}</Typography>}
                        </>
                      )
                    }
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display='flex' justifyContent='center' flexDirection='column' minHeight='100%'>
                  {sortedData().map((x: any, i: number) => (
                    <Box
                      data-testid={`pie-chart-holdings-${groupBy}-tile-${i}`}
                      key={x.translatedName?.en ? `${x.translatedName.en}-${i}` : i}
                      onMouseEnter={() => setActiveItem(x)}
                      onMouseLeave={() => setActiveItem(undefined)}
                      display='flex'
                      justifyContent='space-between'
                      sx={{
                        borderBottom: activeItem?.translatedName !== x.translatedName && i + 1 < data.length ? `2px solid ${sys.color.surfaceContainer}` : `2px solid ${sys.color.surface}`,
                        p: 1.5,
                        backgroundColor: activeItem?.translatedName === x.translatedName ? sys.color.surfaceContainer : 'none',
                        borderRadius: activeItem?.translatedName === x.translatedName ? sys.borderRadius.md : '0px',
                      }}
                    >
                      <Box display='flex' flexDirection='row' justifyContent='start' alignItems='center'>
                        <Box sx={{
                          borderRadius: '10px', height: '8px', width: '8px', backgroundColor: colors[(x.riskLevel < 100 && options.sortAssetClassesBy === 'riskLevel' ? x.riskLevel : i) % 6], mr: 1,
                        }} />
                        <Typography variant='bodyLarge'>{translateBackend(x.translatedName)}</Typography>
                      </Box>
                      <Typography variant='bodyLarge' weight='bold'>
                        {mainVariable === 'value'
                          ? formatMoneyValue(x.totalCents, currencySymbol[x.currency as CurrencyCodes ?? CurrencyCodes.CAD], 2, false)
                          : formatPercentValue(x.totalCents / totalValue)
                        }
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
};

import { gql, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Typography, Box, Skeleton } from '../../../1-primative';
import {
  Button, Badge, Card, CardContent,
} from '../../../2-component';
import {
  BulkImportStates, ImportFile, ImportFileLineItemStates, ImportFileStates,
} from '../../../../interfaces/bulkImport';
import { getOrganizationUserName } from '../../../../interfaces/organizationUser';
import { useGlobalToast } from '../../../../providers/globalToastProvider';
import { usePermissions } from '../../../../providers/userContextProvider';
import ImportFileLineItemsTable from './components/importFileLineItemsTable';
import { FETCH_BULK_IMPORT } from '../bulkImportFiles/bulkImportFiles';

export const FETCH_IMPORT_FILE = gql`
  query fetchImportFile($importFileId: ObjectID!) {
    fetchImportFile(importFileId: $importFileId) {
      importFile {
        id
        fileDocument { name }
        type
        state
        queuedLineItemsCount
        failedLineItemsCount
        processedLineItemsCount
        bulkImport {
          id
          organization { id name }
          organizationUser { id firstName lastName }
        }
      }
    }
  }
`;

export const FETCH_QUEUED_LINE_ITEMS = gql`
  query fetchQueuedLineItems($importFileId: ObjectID!) {
    fetchImportFileLineItems(input: {
      filter:{
        importFileId: $importFileId,
        states: ${ImportFileLineItemStates.QUEUED},
      },
      pagination: { perPage:0 },
    }) {
      totalCount
    }
  }
`;

export const TRANSITION_IMPORT_FILE = gql`
  mutation transitionImportFile($input: TransitionImportFileInput!){
    transitionImportFile(input: $input) {
      importFile {
        id
        state
      }
    }
  }
`;

export const BulkImportFileLineItemsSettings = () => {
  const { t } = useTranslation('bulkImport');
  const params = useParams();
  const { importFileId } = params;
  const [importFile, setImportFile] = useState<ImportFile>({});
  const [hasQueuedItems, setHasQueuedItems] = useState(false);
  const { showToast } = useGlobalToast();
  const { permissions } = usePermissions();

  const {
    data, loading, startPolling, stopPolling,
  } = useQuery(FETCH_IMPORT_FILE, {
    variables: {
      importFileId,
    },
    fetchPolicy: 'no-cache',
  });

  const { data: queuedLinesCountData, refetch: refetchQueuedLinesCount } = useQuery(FETCH_QUEUED_LINE_ITEMS, {
    variables: {
      importFileId,
    },
    fetchPolicy: 'no-cache',
  });

  const [transitionImportFile] = useMutation(TRANSITION_IMPORT_FILE, { refetchQueries: [FETCH_IMPORT_FILE, FETCH_BULK_IMPORT] });

  const resumeProcessing = async () => {
    await transitionImportFile({
      variables: {
        input: {
          importFileId: importFile.id,
          transition: 'process',
        },
      },
    });
    showToast({ severity: 'success', message: t('bulkImport:processBulkImportMessage') });
  };

  useEffect(() => {
    if (data) {
      setImportFile(data.fetchImportFile.importFile);
      if (data.fetchImportFile.importFile.state !== BulkImportStates.PROCESSING) {
        stopPolling();
        if (data.fetchImportFile.importFile.id) {
          refetchQueuedLinesCount();
        }
      } else {
        startPolling(5000);
      }
    }
  }, [data, startPolling, stopPolling, refetchQueuedLinesCount]);

  useEffect(() => {
    setHasQueuedItems(!!queuedLinesCountData?.fetchImportFileLineItems?.totalCount);
  }, [queuedLinesCountData]);

  const disabledProcessButton = !hasQueuedItems || !importFile.state || ![ImportFileStates.FAILED, ImportFileStates.PARTIALLY_FAILED].includes(importFile.state);

  const getStatusColor = (state?: string) => {
    if (state === 'COMPLETED' || state === 'PROCESSED') return 'positive';
    if (state === 'FAILED' || state === 'PARTIALLY_FAILED') return 'negative';
    return 'warning';
  };

  return (
    <Box>
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Box display='flex' justifyContent='space-between' alignItems='end'>
            { loading ? (
              <Box>
                <Skeleton width='200px' />
                <Skeleton width='200px' />
                <Skeleton width='200px' />
                <Skeleton width='200px' />
                <Skeleton width='200px' />
                <Skeleton width='200px' />
              </Box>
            ) : (
              <Box>
                <Typography>{t('table.organization')}: &nbsp; {importFile.bulkImport?.organization?.name}</Typography>
                <Typography>{t('table.createdBy')}: &nbsp; {getOrganizationUserName(importFile.bulkImport?.organizationUser)}</Typography>
                <Typography>{t('fileTable.type')}: &nbsp; {t(`fileTypes.${importFile.type}`)}</Typography>
                <Typography>{t('fileTable.name')}: &nbsp; {importFile.fileDocument?.name}</Typography>
                <Typography>
                  {t('fileTable.queuedLineItems')}: &nbsp; {importFile.queuedLineItemsCount} &nbsp;
                  {t('fileTable.failedLineItems')}: &nbsp; {importFile.failedLineItemsCount} &nbsp;
                  {t('fileTable.processedLineItems')}: &nbsp; {importFile.processedLineItemsCount}
                </Typography>
                <Badge label={t(`bulkImport:states.${importFile.state}`)} color={getStatusColor(importFile.state)} sx={{ mt: 1 }} />
              </Box>
            )}
            {permissions.includes('write:bulk_import') && (
              <Button disabled={disabledProcessButton} onClick={resumeProcessing} label={t('resumeProcessButton')} />
            )}
          </Box>
        </CardContent>
      </Card>
      <ImportFileLineItemsTable importFile={importFile} />
    </Box>
  );
};

export { ImportFileBreadcrumb } from './components/importFileBreadcrumb';

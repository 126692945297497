import {
  Chart as ChartJS,
  CategoryScale,
  PointElement,
  Filler,
  Tooltip,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useThemeTokens } from '../../../providers/themeTokenProvider';

ChartJS.register(
  CategoryScale,
  PointElement,
  Filler,
  Tooltip,
);

const isNegative = (value?: number) => value && value < 0;

export const PieChart = ({
  datasets, labels, onHover = () => { }, tooltip = false, customColors, height, width, cutoutPercentage = 70,
}: {
  datasets: any[], labels: string[], onHover?: (i: number) => void, tooltip?: boolean, customColors?: any[],
  height?: string, width?: string, cutoutPercentage?: number
}) => {
  const { ref, sys } = useThemeTokens();
  const colors = customColors ?? [
    ref.palette.supportOne50,
    ref.palette.supportTwo50,
    ref.palette.supportThree50,
    ref.palette.supportFour50,
    ref.palette.supportFive50,
    ref.palette.supportSix50,
  ];

  const options = {
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    cutout: `${cutoutPercentage}%`,
    plugins: {
      tooltip: {
        enabled: tooltip,
      },
      legend: {
        display: false,
      },
    },
    onHover: (event: any, chartElement: any) => onHover(chartElement.length > 0 ? chartElement[0].index : undefined),
  };

  const fullData = {
    labels,
    datasets: (datasets || []).map((x: any, index: number) => ({
      fill: true,
      label: x.label,
      data: x.data,
      backgroundColor: (x?.data ?? []).map((value: any, i: number) => (isNegative(value) ? sys.color.surface : colors[i % colors.length])),
      borderWidth: (x?.data ?? []).map((value: any) => (isNegative(value) ? 1 : 0)),
      borderColor: (x?.data ?? []).map((_:any, i:number) => colors[i % colors.length]),
      borderDash: [5, 5],
      hoverBackgroundColor: (x?.data ?? []).map((value: any, i: number) => (isNegative(value) ? sys.color.surface : colors[i % colors.length])),
      hoverBorderColor: (x?.data ?? []).map((_:any, i:number) => colors[i % colors.length]),
    })),
  };

  const chartStyles = {
    height: height || '400px',
    width: width || '400px',
  };

  return (
    <Doughnut options={options} data={fullData} style={chartStyles} />
  );
};

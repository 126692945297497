import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Grid, Box } from '../../../../1-primative';
import { TextField, IconButton, Button } from '../../../../2-component';

interface AttributeFieldsProps {
  attributesList: { key: string; value: string }[];
  onAttributeChange: (headers: { key: string; value: string }[]) => void;
  attributeKeyLabel: string;
  attributeValueLabel: string;
}

const Attribute: React.FC<AttributeFieldsProps> = ({
  attributesList,
  onAttributeChange,
  attributeKeyLabel,
  attributeValueLabel,
}) => {
  const { t } = useTranslation(['devSettings']);
  const [attributes, setAttributes] = useState<{ key: string; value: string }[]>([]);

  useEffect(() => {
    setAttributes(attributesList.length ? attributesList : [{ key: '', value: '' }]);
  }, [attributesList]);

  const handleAttributeChange = (index: number, field: 'key' | 'value', val: string) => {
    const updatedAttributes = [...attributes];

    const timestamp = Date.now();

    if (field === 'key') {
      // Prefix the key with an underscore
      updatedAttributes[index][field] = `${timestamp}-${val}`;
    } else {
      updatedAttributes[index][field] = val;
    }

    setAttributes(updatedAttributes);
    onAttributeChange(updatedAttributes);
  };
  const addAttribute = () => {
    setAttributes([...attributes, { key: '', value: '' }]);
  };

  const removeAttribute = (index: number) => {
    const updatedAttributes = [...attributes];
    updatedAttributes.splice(index, 1);
    setAttributes(updatedAttributes);
    onAttributeChange(updatedAttributes);
  };

  return (
    <>
      {attributes.map((attribute, index) => (
        <Grid container key={index} sx={{ ml: 2, mt: 2 }}>
          <Grid item xs={5}>
            <TextField
              label={attributeKeyLabel}
              value={attribute.key.replace(/^\d+-/, '')}
              onChange={(event: any) => handleAttributeChange(index, 'key', event.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={5} ml={2}>
            <TextField
              label={attributeValueLabel}
              value={attribute.value}
              onChange={(event: any) => handleAttributeChange(index, 'value', event.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={1} justifyContent='center' display='flex' sx={{ mt: 1 }} alignItems='end'>
            <IconButton onClick={() => removeAttribute(index)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Box mt={2} ml={2} mb={2}>
        <Button label={t('WebHookModal.addAttributeBtn')} variant="outlined" onClick={addAttribute} />
      </Box>
    </>
  );
};

export default Attribute;

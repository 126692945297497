import { CurrencyCodes } from '@onevesthq/ov-enums';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { translateBackend } from '../../../../../assets/i18n/config';
import type { Account } from '../../../../../interfaces';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import {
  formatMoneyValue, formatPercentValue, generateClientNameString, getBookCostCents, getCustomFieldValueByKey,
} from '../../../../../util';
import { currencySymbol } from '../../../../../util/currency';
import { Box } from '../../../../1-primative';
import { TableCell } from '../../../../2-component';
import { CurrencyFlag, CurrencyIndicator } from '../../../../3-pattern';
import { BookValueType } from '../../../../../providers/statsHooks';
import { showAccountNumber } from '../../../workflowCompletion/subSteps/utils';

export const AccountCell = ({
  account, type, totalMarketValue, useExternalStatisticsEnabled, displayCurrency, isFirst, dataTestId = 'account-cell', maskedAccountNumber, displayAccountNumberWithDashes,
}: { account: Account, type: string, totalMarketValue: number, useExternalStatisticsEnabled?: boolean, displayCurrency?: boolean, isFirst?: boolean, dataTestId?: string,
  maskedAccountNumber?: boolean, displayAccountNumberWithDashes?: boolean }) => {
  const { t } = useTranslation('accountTypes');
  const { sys } = useThemeTokens();

  const statistics = useExternalStatisticsEnabled ? account.custodianStatistics : account.statistics;
  // The simpleReturnAmount is actually coming in cents.
  // TODO: update history-service to return simpleReturnAmountCents and deprecate simpleReturnAmount
  const simpleReturnAmountCents = useExternalStatisticsEnabled ? statistics?.simpleReturnAmountCents : statistics?.simpleReturnAmount;

  const totalBookCostCents = statistics?.holdings?.reduce((p, h) => (
    p + getBookCostCents(h, BookValueType.ACCOUNT)
  ), 0) ?? 0;
  const totalInvestedMarketValueCents = statistics?.holdings?.reduce((p, h) => (
    p + (!h.financialProduct?.isCash && !!h.adjustedCostBaseCents ? (h.totalCents ?? 0) : 0)
  ), 0) ?? 0;
  const unrealizedGainsAndLossCents = totalInvestedMarketValueCents - totalBookCostCents;
  const unrealizedGainsAndLossPercent = totalBookCostCents !== 0 ? (unrealizedGainsAndLossCents / Math.abs(totalBookCostCents)) : 0;

  const leadingComponent = useMemo(() => (
    displayCurrency && account?.baseCurrency ? <CurrencyFlag baseCurrency={account?.baseCurrency} /> : undefined
  ), [displayCurrency, account?.baseCurrency]);

  const getColorByValue = (value: number) => {
    if (value === 0) return sys.color.onSurface;
    return value >= 0 ? sys.color.positive : sys.color.negative;
  };

  const accountHolders = () => {
    const names = [
      generateClientNameString(account.user, true, false),
      ...(account?.affiliations ?? []).filter((aff: any) => ['JOINT', 'AUTHORIZED_INDIVIDUAL', 'TRUSTEE'].includes(aff.type)).map((affiliation: any) => affiliation.user.firstName),
    ];
    return names.join(', ');
  };

  const getUnrealizedGainsAndLoss = () => (
    <>
      {`${formatMoneyValue(unrealizedGainsAndLossCents)}
      (${formatPercentValue(unrealizedGainsAndLossPercent)})`}
    </>
  );

  const getSimpleReturn = () => (
    <>
      {`${formatMoneyValue(simpleReturnAmountCents ?? 0)}
      (${formatPercentValue(statistics?.simpleReturnPercent ?? 0)})`}
    </>
  );

  const getTwrr = (): number => statistics?.timeWeightedReturn ?? 0;
  const getMwrr = (): number => statistics?.moneyWeightedReturn ?? 0;

  const getCurrentValue = () => formatMoneyValue(statistics?.marketValueCents ?? 0, currencySymbol[statistics?.currency as CurrencyCodes ?? CurrencyCodes.CAD]);

  const getPercentageValue = () => {
    const value = (((statistics?.marketValueCents ?? 0) / totalMarketValue));
    return (
      <TableCell testId={dataTestId} number isFirst={isFirst} leadingComponent={leadingComponent}>
        {`${formatPercentValue(value, 1)}`}
      </TableCell>
    );
  };

  const getCustomFieldValue = () => {
    const customFieldValues = [];
    if (account?.customFields && account.customFields.length > 0) {
      customFieldValues.push(...account.customFields);
    }
    if (account?.custodianCustomFields && account.custodianCustomFields.length > 0) {
      customFieldValues.push(...account.custodianCustomFields);
    }
    if (customFieldValues.length > 0) {
      return getCustomFieldValueByKey(type, customFieldValues, t);
    }
    return '-';
  };

  switch (type) {
    case 'holder':
      return (
        <TableCell testId={dataTestId} isFirst={isFirst} leadingComponent={leadingComponent}>{accountHolders()}</TableCell>
      );
    case 'nickname':
      return (
        <TableCell testId={dataTestId} isFirst={isFirst} leadingComponent={leadingComponent}>
          {translateBackend(account.translatedNickName) || account?.nickName || '-'}
        </TableCell>
      );
    case 'name':
      return (
        <TableCell testId={dataTestId} isFirst={isFirst} leadingComponent={leadingComponent}>{t(`accountTypes:${account.type}`)}</TableCell>
      );
    case 'accountNumber':
      return (
        <TableCell testId={dataTestId} isFirst={isFirst} leadingComponent={leadingComponent}>
          {account.custodianAccountNumber && showAccountNumber(account.custodianAccountNumber, maskedAccountNumber, displayAccountNumberWithDashes)}
        </TableCell>
      );
    case 'repCode':
      return (
        <TableCell testId={dataTestId} isFirst={isFirst} leadingComponent={leadingComponent}>{account.repCode?.code || '-'}</TableCell>
      );
    case 'program':
      return (
        <TableCell testId={dataTestId} isFirst={isFirst} leadingComponent={leadingComponent}>
          {translateBackend((account?.subAccounts ?? [])[0]?.theme?.translatedName)}
        </TableCell>
      );
    case 'unrealizedGainLoss':
      return (
        <TableCell testId={dataTestId} number isFirst={isFirst} leadingComponent={leadingComponent} sx={{
          textAlign: 'right', color: getColorByValue(unrealizedGainsAndLossCents),
        }}>
          {getUnrealizedGainsAndLoss()}
        </TableCell>
      );
    case 'bookCost':
      return (
        <TableCell testId={dataTestId} number isFirst={isFirst} leadingComponent={leadingComponent} sx={{
          textAlign: 'right',
        }}>
          {formatMoneyValue(totalBookCostCents)}
        </TableCell>
      );
    case 'simpleReturn':
      return (
        <TableCell testId={dataTestId} number isFirst={isFirst} leadingComponent={leadingComponent} sx={{
          textAlign: 'right', color: getColorByValue(simpleReturnAmountCents ?? 0),
        }}>
          {getSimpleReturn()}
        </TableCell>
      );
    case 'twrr':
      return (
        <TableCell testId={dataTestId} number isFirst={isFirst} leadingComponent={leadingComponent} sx={{
          textAlign: 'right', color: getColorByValue(getTwrr()),
        }}>
          {formatPercentValue(getTwrr())}
        </TableCell>
      );
    case 'mwrr':
      return (
        <TableCell testId={dataTestId} number isFirst={isFirst} leadingComponent={leadingComponent} sx={{
          textAlign: 'right', color: getColorByValue(getMwrr()),
        }}>
          {formatPercentValue(getMwrr())}
        </TableCell>
      );
    case 'percentOfTotal':
      return getPercentageValue();
    case 'currentValue':
      return (
        <TableCell testId={dataTestId} right bold number isFirst={isFirst} leadingComponent={
          <Box display='flex' alignItems='center' gap={1}>
            {leadingComponent}
            <CurrencyIndicator statistics={statistics} useExternalStatisticsEnabled={useExternalStatisticsEnabled} titleSx={{ color: sys.color.surface }} />
          </Box>
        }>
          {getCurrentValue()}
        </TableCell>
      );
    case 'custodianName':
      return (
          <TableCell testId={dataTestId} isFirst={isFirst} leadingComponent={leadingComponent}>{account.custodianAccountName ?? '-'}</TableCell>
      );
    default:
      return (
        <TableCell testId={dataTestId} isFirst={isFirst}>{getCustomFieldValue()}</TableCell>
      );
  }
};

import { useState, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { isUndefined, isEmpty } from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import { PieChart } from '../../2-component/pieChart/pieChart';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { Card, CardContent } from '../../2-component';
import { getBackendLanguage } from '../../../assets/i18n/config';
import { Security } from '../securitiesSelectionTable/securitiesSelectionTable';

interface AssetClassGroup {
  totalPercentage: number;
  name: string;
  color: string;
}

interface PieChartSecuritiesProps {
  securities: Security[];
  variant?: 'elevated' | 'outlined' | 'none';
  modelPortfolioName?: string;
}

const DEFAULT_BORDER_RADIUS = '4px';
const DEFAULT_COLOR = '#FFFFFF';

const ensureString = (value: unknown, defaultValue: string): string => {
  if (typeof value === 'string') {
    return value;
  }
  return defaultValue;
};

export const PieChartSecurities = ({
  securities = [],
  variant = 'none',
  modelPortfolioName = '',
}: PieChartSecuritiesProps) => {
  const [activeItem, setActiveItem] = useState<AssetClassGroup | null>(null);
  const { ref, sys } = useThemeTokens();
  const { t } = useTranslation(['shared', 'modelPortfolios']);

  const colors = useMemo(() => ({
    supportOne: ensureString(ref.palette.supportOne50, DEFAULT_COLOR),
    supportTwo: ensureString(ref.palette.supportTwo50, DEFAULT_COLOR),
    supportThree: ensureString(ref.palette.supportThree50, DEFAULT_COLOR),
    supportFour: ensureString(ref.palette.supportFour50, DEFAULT_COLOR),
    supportFive: ensureString(ref.palette.supportFive50, DEFAULT_COLOR),
    supportSix: ensureString(ref.palette.supportSix50, DEFAULT_COLOR),
  }), [ref.palette]);

  const assetClassData = useMemo(() => {
    const assetClassGroups = securities.reduce((acc: Record<string, AssetClassGroup>, security: Security) => {
      const assetClass = security.primaryAssetClass?.translatedName?.[getBackendLanguage()] || 'Other';
      if (acc[assetClass]) {
        acc[assetClass].totalPercentage += security.targetPercentage || 0;
      } else {
        acc[assetClass] = {
          totalPercentage: security.targetPercentage || 0,
          name: assetClass,
          color: security.color || 'supportOne', // Default color if none provided
        };
      }
      return acc;
    }, {});

    return Object.values(assetClassGroups).sort((a, b) => b.totalPercentage - a.totalPercentage);
  }, [securities]);

  const pieChartDataAndColors = useMemo(() => {
    const totalPercentage = assetClassData.reduce((acc: number, x: any) => acc + Number(x.totalPercentage), 0);
    const remainingPercentage = Math.max(0, 100 - totalPercentage);

    const pieChartData = [
      ...assetClassData.map((x: any) => Number(x.totalPercentage)),
      remainingPercentage,
    ];

    const pieChartColors = [
      ...assetClassData.map((x: any) => colors[x.color as keyof typeof colors]),
      sys.color.surfaceContainer,
    ];

    return { pieChartData, pieChartColors };
  }, [assetClassData, colors, sys.color.surfaceContainer]);

  const dataState = useMemo(() => {
    const isDummyData = securities.length === 1 && !securities[0].primaryAssetClass?.translatedName;
    const hasData = !isEmpty(securities) && !isDummyData;
    return { isDummyData, hasData };
  }, [securities]);

  return (
    <Card variant={variant}>
      <CardContent sx={{ p: '14px !important' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box position='relative' sx={{ height: 270 }}>
            <PieChart
              datasets={dataState.hasData ? [{
                label: '',
                data: pieChartDataAndColors.pieChartData,
              }] : [{
                label: '',
                data: [100],
              }]}
              labels={dataState.hasData ? assetClassData.map((x: AssetClassGroup) => x.name) : ['Empty']}
              onHover={(index: number | undefined) => {
                if (dataState.hasData && !isUndefined(index) && index < assetClassData.length) {
                  setActiveItem(assetClassData[index]);
                } else {
                  setActiveItem(null);
                }
              }}
              customColors={dataState.hasData ? pieChartDataAndColors.pieChartColors : [sys.color.surfaceContainer || '']}
              cutoutPercentage={80}
            />
            <Box
              textAlign='center'
              position='absolute'
              left='50%'
              top='50%'
              sx={{ transform: 'translate(-50%,-50%)' }}
            >
              {!dataState.hasData ? (
                <>
                  <Typography sx={{ color: sys.color.onSurfaceVariant, fontSize: '0.875rem' }}>
                    {modelPortfolioName}
                  </Typography>
                  <Typography sx={{ fontSize: '0.875rem', fontWeight: 500 }}>
                    0.00%
                  </Typography>
                </>
              ) : activeItem ? (
                <>
                  <Typography sx={{ color: sys.color.onSurfaceVariant, fontSize: '0.875rem' }}>
                    {activeItem.name}
                  </Typography>
                  <Typography sx={{ fontSize: '0.875rem', fontWeight: 500 }}>
                    {`${Number(activeItem.totalPercentage).toFixed(2)}%`}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography sx={{ color: sys.color.onSurfaceVariant, fontSize: '0.875rem' }}>
                    {modelPortfolioName}
                  </Typography>
                  <Typography sx={{ fontSize: '0.875rem', fontWeight: 500 }}>
                    {`${assetClassData.reduce((acc: number, x: any) => acc + Number(x.totalPercentage), 0).toFixed(2)}%`}
                  </Typography>
                </>
              )}
            </Box>
          </Box>

          {dataState.hasData ? (
            <Box>
              {assetClassData.map((item: AssetClassGroup, i: number) => {
                const isActive = activeItem?.name === item.name;
                const isLast = i + 1 === assetClassData.length;
                const color = ensureString(colors[item.color as keyof typeof colors], colors.supportOne);
                const surfaceContainerColor = ensureString(sys.color.surfaceContainer, DEFAULT_COLOR);
                const surfaceColor = ensureString(sys.color.surface, DEFAULT_COLOR);
                const borderRadius = ensureString(sys.borderRadius.md, DEFAULT_BORDER_RADIUS);

                return (
                  <Box
                    key={item.name}
                    onMouseEnter={() => setActiveItem(item)}
                    onMouseLeave={() => setActiveItem(null)}
                    display='flex'
                    justifyContent='space-between'
                    alignItems='left'
                    sx={{
                      borderBottom: !isActive && !isLast ? `2px solid ${surfaceContainerColor}` : `2px solid ${surfaceColor}`,
                      py: 0.5,
                      px: 1,
                      backgroundColor: isActive ? surfaceContainerColor : 'none',
                      borderRadius: isActive ? borderRadius : '0px',
                    }}
                  >
                    <Box display='flex' flexDirection='row' justifyContent='start' alignItems='center'>
                      <Box sx={{
                        borderRadius: '10px',
                        height: '8px',
                        width: '8px',
                        backgroundColor: color,
                        mr: 1,
                      }} />
                      <Typography sx={{ fontSize: '0.75rem' }}>{item.name}</Typography>
                    </Box>
                    <Box display='flex' alignItems='center' gap={2}>
                      <Typography sx={{ fontSize: '0.75rem', color: sys.color.onSurfaceVariant }}>
                        {`${Number(item.totalPercentage || 0).toFixed(2)}%`}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          ) : (
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='left'
              sx={{
                py: 0.5,
                px: 1,
              }}
            >
              <Box display='flex' flexDirection='row' justifyContent='start' alignItems='center'>
                <Box sx={{
                  borderRadius: '10px',
                  height: '8px',
                  width: '8px',
                  backgroundColor: sys.color.surfaceContainer,
                  mr: 1,
                }} />
                <Typography sx={{ fontSize: '0.75rem' }}>{t('modelPortfolios:modelPortfolioTable.assetClass')}</Typography>
              </Box>
              <Box display='flex' alignItems='center' gap={2}>
                <Typography sx={{ fontSize: '0.75rem', color: sys.color.onSurfaceVariant }}>
                  --%
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

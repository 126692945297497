import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Box } from '../../../1-primative';
import {
  Button, Form, SettingsSwitch,
} from '../../../2-component';
import { AmountField, NumberField, SettingsCard } from '../../../3-pattern';
import { UserContext } from '../../../../providers/userContextProvider';
import { useGlobalToast } from '../../../../providers/globalToastProvider';

const FETCH_ORGANIZATION = gql`
  query fetchOrganization($organizationId: String!) {
    fetchOrganization(organizationId: $organizationId) {
      organization {
        id
        name
        reviewTransactions
        blockSuspiciousTransactions
        minInitialDepositCents
        minRecurringDepositCents
        minDepositCents
        cashOnHoldToWithdrawDays
        cashOnHoldToTradeDays
      }
    }
  }
`;

const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        id
      }
    }
  }
`;

export const TransfersSettings = () => {
  const { activeOrganization } = useContext(UserContext);
  const { showToast } = useGlobalToast();
  const { t } = useTranslation(['settings', 'shared']);
  const [organization, setOrganization] = useState<any>(null);

  const { data, loading } = useQuery(FETCH_ORGANIZATION, {
    variables: { organizationId: activeOrganization?.id },
  });

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION, {
    variables: {
      input: {
        organizationId: activeOrganization?.id,
        reviewTransactions: organization?.reviewTransactions,
        blockSuspiciousTransactions: organization?.blockSuspiciousTransactions,
        minInitialDepositCents: organization?.minInitialDepositCents,
        minRecurringDepositCents: organization?.minRecurringDepositCents,
        minDepositCents: organization?.minDepositCents,
        cashOnHoldToWithdrawDays: organization?.cashOnHoldToWithdrawDays,
        cashOnHoldToTradeDays: organization?.cashOnHoldToTradeDays,
      },
    },
    onCompleted: async () => {
      showToast({
        message: t('shared:successfullySaved'),
        severity: 'success',
      });
    },
  });

  useEffect(() => {
    if (data?.fetchOrganization?.organization) {
      setOrganization(data?.fetchOrganization?.organization);
    }
  }, [data]);

  return (
    <SettingsCard
      title={t('transfers.title')}
      description={t('transfers.description')}
    >
      <Form onSubmit={updateOrganization}>
        <Box display='flex' flexDirection='column' gap={2}>
          <SettingsSwitch
            label={t('transfers.reviewTransactions')}
            checked={organization?.reviewTransactions}
            onChange={() => setOrganization({ ...organization, reviewTransactions: !organization?.reviewTransactions })}
            testId='transfers-settings-review-transactions'
            underline
          />
          <SettingsSwitch
            label={t('transfers.blockSuspiciousTransactions')}
            checked={organization?.blockSuspiciousTransactions}
            onChange={() => setOrganization({ ...organization, blockSuspiciousTransactions: !organization?.blockSuspiciousTransactions })}
            testId='transfers-settings-block-suspicious-transactions'
            underline
          />
          <AmountField
            label={t('transfers.minInitialDepositCents')}
            amount={((organization?.minInitialDepositCents ?? 0) / 100).toString()}
            decimalPlaces={0}
            setAmount={(e: any) => setOrganization({ ...organization, minInitialDepositCents: e * 100 })}
            testId='transfers-settings-min-initial-deposit-cents'
          />
          <AmountField
            label={t('transfers.minRecurringDepositCents')}
            amount={((organization?.minRecurringDepositCents ?? 0) / 100).toString()}
            decimalPlaces={0}
            setAmount={(e: any) => setOrganization({ ...organization, minRecurringDepositCents: e * 100 })}
            testId='transfers-settings-min-recurring'
          />
          <AmountField
            label={t('transfers.minDepositCents')}
            amount={((organization?.minDepositCents ?? 0) / 100).toString()}
            decimalPlaces={0}
            setAmount={(e: any) => setOrganization({ ...organization, minDepositCents: e * 100 })}
            testId='transfers-settings-min-deposit-cents'
          />
          <NumberField
            label={t('transfers.cashOnHoldToWithdrawDays')}
            number={organization?.cashOnHoldToWithdrawDays}
            decimalPlaces={0}
            setNumber={(e: any) => setOrganization({ ...organization, cashOnHoldToWithdrawDays: e })}
            testId='transfers-settings-cash-on-hold-to-withdraw-days'
          />
          <NumberField
            label={t('transfers.cashOnHoldToTradeDays')}
            number={organization?.cashOnHoldToTradeDays}
            decimalPlaces={0}
            setNumber={(e: any) => setOrganization({ ...organization, cashOnHoldToTradeDays: e })}
            testId='transfers-settings-cash-on-hold-to-trade-days'
          />
          <Box display='flex' justifyContent='flex-end'>
            <Button type='submit' disabled={loading} label={t('shared:save')} dataTestId='settings-transfers-submit-button'/>
          </Box>
        </Box>
      </Form>
    </SettingsCard>
  );
};

import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { NewRole } from './components/newRole';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import { EditRole } from './components/editRole';
import { Typography, Box, Skeleton } from '../../../1-primative';
import {
  Table, TableHeadCell, TableRow, TableCell, TableBody, Pagination, CardContent, Card, Badge,
} from '../../../2-component';
import { usePageState } from '../../../../util/usePageState';
import { AuthAudienceTypes } from '../../../../interfaces/organizationUser';
import { SettingsTitle } from '../../../3-pattern';

const FETCH_ROLES = gql`
  query fetchRoles($input: FetchRolesInput!) {
    fetchRoles(input: $input) {
      totalCount
      roles {
        id
        translatedName { en fr }
        translatedDescription { en fr }
        organization { id name }
        roleProfile {
          id
          translatedDescription { en }
          translatedName { en }
          template {
            permissions
            accessiblePages
            dashboards { id name }
          }
        }
        nonIndividualPageConfiguration { id }
        individualPageConfiguration{ id }
        goalPageConfiguration { id }
        accountPageConfiguration { id }
        householdPageConfiguration { id }
        individualTableStaticPageWidgetConfiguration { id }
        nonIndividualTableStaticPageWidgetConfiguration { id }
        householdTableStaticPageWidgetConfiguration { id }
        makeHouseholdDefaultView
        subAccountPageConfiguration { id }
        permissions
        accessiblePages
        dashboards { id name }
        navigationStyle
        defaultAuthenticationConnection
      }
    }
  }
`;

const PAGE_SIZE = 20;

export const RolesSettings = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [page, setPage] = usePageState(1, 'page');
  const [selectedRole, setSelectedRole] = useState<any>(null);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const { t } = useTranslation(['orgSettings', 'shared', 'settings']);
  const {
    loading, error, data, refetch, previousData,
  } = useQuery(FETCH_ROLES, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          authAudience: AuthAudienceTypes.ONEHUB, // exclude Client Role
        },
        pagination: {
          sortField: 'translatedName.en', sortDesc: true, perPage: PAGE_SIZE, offSet: (page - 1) * PAGE_SIZE,
        },
      },
    },
    fetchPolicy: 'no-cache',
  });

  if (error) (<Typography>Error</Typography>);
  return (
    <>
      <SettingsTitle title={t('settings:roles.title')} description={t('settings:roles.description')} />
      <Card loading={loading}>
        {permissions.includes('write:roles') && (
          <CardContent>
            <Box display='flex' justifyContent='flex-end'>
              <NewRole afterCreate={refetch} defaultOrg={activeOrganization.id} />
            </Box>
          </CardContent>
        )}
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableHeadCell>{t('roleTable.name')}</TableHeadCell>
              <TableHeadCell>{t('roleTable.navigationStyle')}</TableHeadCell>
              <TableHeadCell right>{t('roleTable.noPermissions')}</TableHeadCell>
              <TableHeadCell right>{t('roleTable.organization')}</TableHeadCell>
            </TableRow>
            {loading && !previousData && [...Array(15)].map((x: any, i: number) => (
              <TableRow key={i}>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
              </TableRow>
            ))}
            {data?.fetchRoles?.roles?.map((role: any) => (
              <TableRow
                hover
                key={role.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={() => {
                  setSelectedRole(role);
                  setUpdateDialogOpen(true);
                }}
              >
                <TableCell component="th" scope="row">{role.translatedName.en}</TableCell>
                <TableCell><Badge label={role.navigationStyle} /></TableCell>
                <TableCell right>{role.permissions.length}</TableCell>
                <TableCell right>{role.organization.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={Math.ceil(((data || previousData)?.fetchRoles?.totalCount ?? 0) / PAGE_SIZE)}
          page={page}
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />
        {updateDialogOpen && (
          <EditRole
            roleToUpdate={selectedRole}
            afterUpdate={() => {
              setUpdateDialogOpen(false);
              refetch();
            }}
            handleClose={() => setUpdateDialogOpen(false)}
          />
        )}
      </Card>
    </>
  );
};

import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, Button, Card, CardContent, Typography, IconButton,
} from '../..';
import { Individuals } from '../individuals/individuals';
import { NonIndividuals } from '../nonIndividuals/nonIndividuals';
import { Households } from '../households/households';
import { EditStaticPageWidget } from './components/editStaticPageWidget';
import { useGlobalToast } from '../../../providers/globalToastProvider';

export const StaticPageWidgetConfigurationVisual = ({ staticPageWidget, setStaticPageWidget, update }: {
  staticPageWidget: any, setStaticPageWidget: (value: any) => void, update: (e: any) => void,
}) => {
  const { t } = useTranslation('pageConfiguration');
  const { showToast } = useGlobalToast();
  const [widget, setWidget] = useState<any>(staticPageWidget.widget);
  const [open, setOpen] = useState(false);

  const staticPageWidgetTable = (staticWidget: any) => {
    switch (staticWidget.type) {
      case 'INDIVIDUAL_TABLE':
        return <Individuals options={staticWidget.options} />;
      case 'NON_INDIVIDUAL_TABLE':
        return <NonIndividuals options={staticWidget.options} />;
      case 'HOUSEHOLD_TABLE':
        return <Households options={staticWidget.options} />;
      default:
        return null;
    }
  };

  const onSave = () => {
    setStaticPageWidget({ ...staticPageWidget, widget });
    update({
      variables: {
        input: {
          staticPageWidgetConfigurationId: staticPageWidget.id,
          name: staticPageWidget.name,
          widget: { type: widget.type, options: widget.options },
        },
      },
      onCompleted: () => showToast({ severity: 'success', message: t('saveStaticPageWidgetSuccessMessage') }),
    });
  };

  return (
    <Box>
      <Card>
        <CardContent>
          <Typography variant='headingMedium'>{t('staticPageWidgetSettings')} - {widget.type}</Typography>
          <Box display='flex' flexDirection='row' justifyContent='space-between' mt={3}>
            <Box display='flex' justifyContent='start' alignItems='end'>
              <Button label={t('save')} sx={{ ml: 2, mr: 2 }} onClick={onSave} />
            </Box>
          </Box>
        </CardContent>
      </Card>

      <Box display='flex' sx={{ flexDirection: 'column' }} justifyContent='start' alignItems='start' mt={6}>
        <Box maxWidth='1080px' width='100%' ml={{ xs: '0px', sm: '16px' }} position='relative'>
          <IconButton onClick={() => setOpen(true)} sx={{
            position: 'absolute', right: '8px', top: '-40px', zIndex: 2,
          }}>
            <EditIcon />
          </IconButton>
          {staticPageWidgetTable(widget)}
          <EditStaticPageWidget setWidget={setWidget} widget={widget} open={open} setOpen={setOpen} />
        </Box>
      </Box>
    </Box>
  );
};

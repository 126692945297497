import { useContext, useEffect, useState } from 'react';
import {
  gql,
  useQuery,
} from '@apollo/client';
import { RiskProfileVisual } from './riskProfile.visual';
import { WorkflowContext } from '../../workflowCompletion';

const FETCH_USER = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        investmentKnowledge
        suggestedFinancialProduct {
          id
          riskProfileName {
            en
            fr
          }
          riskProfileDescription {
            en
            fr
          }
          children {
            id
            percentage
            financialProduct {
              id
              translatedName {
                en
                fr
              }
              primaryAssetClass {
                id
                translatedName {
                  en
                  fr
                }
                translatedDescription {
                  en
                  fr
                }
                key
              }
            }
          }
        }
      }
    }
  }
`;

export const FETCH_GOAL = gql`
  query fetchGoal($id: ObjectID!) {
    fetchGoal(goalId: $id) {
      goal {
        id
        timeHorizon
        user {
          investmentKnowledge
        }
        subAccounts {
          id
          suggestedFinancialProduct {
            id
            riskProfileName {
              en
              fr
            }
            riskProfileDescription {
              en
              fr
            }
            children {
              id
              percentage
              financialProduct {
                id
                translatedName {
                  en
                  fr
                }
                primaryAssetClass {
                  id
                  translatedName {
                    en
                    fr
                  }
                  translatedDescription {
                    en
                    fr
                  }
                  key
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const RiskProfile = ({
  options,
  userId,
  onNext,
  stepLoading,
}: {
  options: any
  userId: string,
  onNext: () => void,
  stepLoading: boolean,
}) => {
  const { workflowData } = useContext(WorkflowContext);
  const goalId = workflowData.currentGoalId;

  const [riskProfileData, setRiskProfileData] = useState<any>({
    riskProfileName: null,
    riskProfileDescription: null,
    children: [],
    investmentKnowledge: null,
    timeHorizon: null,
  });

  const { data: userData, loading: userDataLoading } = useQuery(FETCH_USER, {
    variables: { userId: workflowData.currentUserId ?? userId },
    fetchPolicy: 'no-cache',
    skip: !!goalId,
  });

  const { data: goalData, loading: goalDataLoading } = useQuery(FETCH_GOAL, {
    variables: { id: goalId },
    fetchPolicy: 'no-cache',
    skip: !goalId,
  });

  useEffect(() => {
    const [currentSubAccount] = goalData?.fetchGoal.goal?.subAccounts || [];
    if (!goalDataLoading && goalId && currentSubAccount && goalData) {
      setRiskProfileData({
        id: goalData.fetchGoal.goal.id,
        riskProfileName: currentSubAccount.suggestedFinancialProduct?.riskProfileName?.en,
        riskProfileDescription: currentSubAccount.suggestedFinancialProduct?.riskProfileDescription?.en,
        children: currentSubAccount.suggestedFinancialProduct?.children,
        investmentKnowledge: goalData.fetchGoal.goal.user.investmentKnowledge,
        timeHorizon: goalData.fetchGoal.goal.timeHorizon,
      });
    } else if (!userDataLoading && !goalId && userData) {
      setRiskProfileData({
        id: userData.fetchUser.user.id,
        riskProfileName: userData.fetchUser.user.suggestedFinancialProduct?.riskProfileName?.en,
        riskProfileDescription: userData.fetchUser.user.suggestedFinancialProduct?.riskProfileDescription?.en,
        children: userData.fetchUser.user.suggestedFinancialProduct?.children,
        investmentKnowledge: userData.fetchUser.user.investmentKnowledge,
        timeHorizon: null,
      });
    }
  }, [userData, goalData, goalId, userId, userDataLoading, goalDataLoading]);

  return (
    <RiskProfileVisual
      options={options}
      riskProfileData={riskProfileData}
      loading={ userDataLoading || goalDataLoading || stepLoading}
      continueFunc={onNext}
    />
  );
};

import MoreVertIcon from '@mui/icons-material/MoreVert';
import ClearIcon from '@mui/icons-material/Clear';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  Typography, Box, Link as MuiLink,
  Skeleton,
} from '../../1-primative';
import {
  Table, TableHeadCell,
  TableRow, TableCell, TableBody, TextField,
  MenuItem, Pagination, Badge, Menu,
  DateField,
  IconButton,
  Card,
  CardContent,
  SelectField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from '../../2-component';
import { FilterModal } from '../../3-pattern';
import { TransferModal } from '../../4-module/widgets/pendingTransfers/components/transferModal';
import { formatMoneyValue } from '../../../util';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import { EntityTypes } from '../../../interfaces';
import { useLocalization } from '../../../util/useLocalization';
import { STATE_CHANGES_FIELD } from '../../../util/reusableGraphQLFields';

const FETCH_ALERT_REPORTS = gql`
  query fetchAlertReports($input: FetchAlertReportsInput!) {
    fetchAlertReports(input: $input) {
      reports {
        id
        state
        rules {
          type
          translatedDescription {
            en
          }
        }
        transfer {
          id
          type
          source
          amountCents
          state
          createdAt
          ${STATE_CHANGES_FIELD}
          bankAccount {
            name
            bankAccountNumber
            institutionNumber
          }
          isSuspicious
          alertReport {
            rules {
              type
              translatedDescription {
                en
                fr
              }
            }
          }
          subAccount {
            id
            goal {
              id
              name
            }
            statistics {
              marketValueCents
              moneyAvailableCents
              availableFundsToWithdrawCents
            }
            account {
              id
              type
              user {
                id
                firstName
                lastName
                entityName
                type
                fraudRisk {
                  score
                  lastUpdatedBy
                  notes
                  riskReasons {
                    translatedDisplayName {
                      en
                      fr
                    }
                    translatedDescription {
                      en
                      fr
                    }
                  }
                }
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;

const TRANSITION_REPORT = gql`
  mutation transitionReport($input: TransitionReportInput!) {
    transitionReport(input: $input) {
      report {
        id
      }
    }
  }
`;

enum ReportStates {
  INITIATED = 'INITIATED',
  REVIEW = 'REVIEW',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
}

const transitions = [
  {
    name: 'review',
    from: [ReportStates.INITIATED],
  },
  {
    name: 'approved',
    from: [ReportStates.INITIATED, ReportStates.REVIEW],
  },
  {
    name: 'denied',
    from: [ReportStates.INITIATED, ReportStates.REVIEW],
  },
];

export const TransactionMonitoring = () => {
  const { t } = useTranslation(['components']);
  const { permissions } = usePermissions();
  const { localizedDateTime } = useLocalization();
  const { activeOrganization } = useContext(UserContext);
  const [page, setPage] = usePageState(1, 'page');
  const [currentReport, setCurrentReport] = useState<any>({});
  const [modalOpen, setModalOpen] = useState(false);
  const [riskModalOpen, setRiskModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [transitionReport] = useMutation(TRANSITION_REPORT);
  const [filterState, setFilterState] = usePageState('ANY', 'state');
  const [filterTransferType, setFilterTransferType] = usePageState('ANY', 'type');
  const [filterCreatedAfter, setFilterCreatedAfter] = usePageState<string | undefined>(undefined, 'after');
  const [filterCreatedBefore, setFilterCreatedBefore] = usePageState<string | undefined>(undefined, 'before');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const pageSize = 15;
  const INDIVIDUAL_PATH = 'clients';
  const NON_INDIVIDUAL_PATH = 'nonIndividualClients';
  const {
    loading, data, previousData, refetch,
  } = useQuery(FETCH_ALERT_REPORTS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          transferType: filterTransferType === 'ANY' ? undefined : filterTransferType,
          state: filterState === 'ANY' ? undefined : filterState,
          createdAtAfter: filterCreatedAfter === null ? undefined : filterCreatedAfter,
          createdAtBefore: filterCreatedBefore === null ? undefined : filterCreatedBefore,
        },
        pagination: {
          sortField: 'createdAt', sortDesc: true, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
    onCompleted: () => {
      setIsLoading(false);
    },
  });

  const isIndividualUser = (user?: any): boolean => user && (!user.type || user.type === EntityTypes.INDIVIDUAL);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const stateColor = (state: any) => (state === 'RECONCILED' || state === 'APPROVED' ? 'positive' : ['FAILED', 'CANCELED', 'DENIED'].includes(state) ? 'negative' : 'warning');

  const scoreColor = (score: any) => ([0, 1].includes(score) ? 'primary' : score === 3 ? 'destructive' : 'destructive');

  const transition = (transferId: string, _isScheduled: boolean, variables: any) => {
    setIsLoading(true);
    transitionReport({
      variables: { input: { transferId, ...variables } },
      onCompleted: refetch,
    });
  };

  return (
    <Card loading={isLoading}>
      <CardContent>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <SelectField
            value={filterState}
            label=''
            onChange={(e: any) => setFilterState(e.target.value)}
            fullWidth
            sx={{ width: 200 }}
            placeholder={t('any')}
          >
            <MenuItem value={'ANY'} key={'ANY'}>{t('ANY')}</MenuItem>
            <MenuItem value={'INITIATED'} key={'INITIATED'}>{t('alertReportStates.INITIATED')}</MenuItem>
            <MenuItem value={'REVIEW'} key={'REVIEW'}>{t('alertReportStates.REVIEW')}</MenuItem>
            <MenuItem value={'APPROVED'} key={'APPROVED'}>{t('alertReportStates.APPROVED')}</MenuItem>
            <MenuItem value={'DENIED'} key={'DENIED'}>{t('alertReportStates.DENIED')}</MenuItem>
          </SelectField>
          <FilterModal>
            <Box display='flex' gap={2} flexDirection='column'>
              <SelectField
                value={filterTransferType}
                label={t('activityTable.type')}
                onChange={(e: any) => setFilterTransferType(e.target.value)}
                fullWidth
                placeholder={t('any')}
              >
                <MenuItem value={'ANY'} key={'ANY'}>{t('ANY')}</MenuItem>
                <MenuItem value='DEPOSIT' key={'DEPOSIT'}>{t('DEPOSIT')}</MenuItem>
                <MenuItem value='WITHDRAW' key={'WITHDRAW'}>{t('WITHDRAW')}</MenuItem>
              </SelectField>
              <DateField
                label={t('components:fromDate')}
                fullWidth
                value={filterCreatedAfter || null}
                onChange={(createdAt: any) => setFilterCreatedAfter(dayjs(createdAt).format('YYYY-MM-DD'))}
                renderInput={(params: any) => <TextField fullWidth size="small" {...params} />}
                InputProps={{ endAdornment: filterCreatedAfter && (<IconButton onClick={() => setFilterCreatedAfter(undefined)}><ClearIcon /></IconButton>) }}
              />
              <DateField
                label={t('components:toDate')}
                fullWidth
                value={filterCreatedBefore || null}
                onChange={(createdAt: any) => setFilterCreatedBefore(dayjs(createdAt).format('YYYY-MM-DD'))}
                renderInput={(params: any) => <TextField fullWidth size="small" {...params} />}
                InputProps={{ endAdornment: filterCreatedBefore && (<IconButton onClick={() => setFilterCreatedBefore(undefined)}><ClearIcon /></IconButton>) }}
              />
            </Box>
          </FilterModal>
        </Box>
      </CardContent>
      <Table aria-label="table">
        <TableBody>
          <TableRow>
            <TableHeadCell>{t('activityTable.client')}</TableHeadCell>
            <TableHeadCell>{t('activityTable.account')}</TableHeadCell>
            <TableHeadCell>{t('activityTable.type')}</TableHeadCell>
            <TableHeadCell>{t('activityTable.source')}</TableHeadCell>
            <TableHeadCell>{t('activityTable.amount')}</TableHeadCell>
            <TableHeadCell>{t('activityTable.state')}</TableHeadCell>
            <TableHeadCell>{t('activityTable.createdAt')}</TableHeadCell>
            <TableHeadCell>{t('activityTable.alertState')}</TableHeadCell>
            <TableHeadCell>{t('activityTable.fraudRisk')}</TableHeadCell>
            {permissions.includes('transition:alert_report') && (
              <TableHeadCell align='right'>{t('activityTable.actions')}</TableHeadCell>
            )}
          </TableRow>
          { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
            <TableRow key={i}>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              {permissions.includes('transition:alert_report') && (
                <TableCell><Skeleton width='100%' /></TableCell>
              )}
              </TableRow>
          ))}
          {((data || previousData)?.fetchAlertReports?.reports || []).map((report: any) => (
            <TableRow
              hover
              onClick={() => {
                setCurrentReport(report);
                setModalOpen(true);
              }}
              key={report.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
            >
              <TableCell component="th" scope="row">
                <MuiLink
                  component={Link}
                  to={`/${isIndividualUser(report.transfer?.subAccount?.account?.user) ? INDIVIDUAL_PATH : NON_INDIVIDUAL_PATH}/${report.transfer?.subAccount?.account?.user?.id}`}>

                  {isIndividualUser(report.transfer?.subAccount?.account?.user)
                    ? `${report.transfer?.subAccount?.account?.user?.firstName} ${report.transfer?.subAccount?.account?.user?.lastName}`
                    : `${report.transfer?.subAccount?.account?.user?.entityName}`}
                </MuiLink>
              </TableCell>
              <TableCell>
                {report.transfer?.subAccount?.goal?.name} - {report.transfer?.subAccount?.account?.type}
              </TableCell>
              <TableCell>
                <Badge label={report.transfer?.type} />
              </TableCell>
              <TableCell>
                {t(`transferSources.${report.transfer?.source}`)}
              </TableCell>
              <TableCell>
                {formatMoneyValue(report.transfer?.amountCents)}
              </TableCell>
              <TableCell>
                <Badge
                  label={report.transfer?.state}
                  color={stateColor(report.transfer?.state)}
                />
              </TableCell>
              <TableCell>
                {localizedDateTime(report.transfer?.createdAt)}
              </TableCell>
              <TableCell>
                <Badge
                  label={report.state}
                  color={stateColor(report.state)}
                />
              </TableCell>
              <TableCell>
                <Button
                  size='sm'
                  variant='tonal'
                  onClick={(e: any) => {
                    setCurrentReport(report);
                    e.stopPropagation();
                    setRiskModalOpen(true);
                  }}
                  label={report.transfer?.subAccount?.account?.user?.fraudRisk?.score ?? 'null'}
                  color={scoreColor(report.transfer?.subAccount?.account?.user?.fraudRisk?.score)}
                />
              </TableCell>
              {permissions.includes('transition:alert_report') && (
                <TableCell align='right'>
                  <IconButton size='small'
                    disabled={['APPROVED', 'DENIED'].includes(report.state)}
                    onClick={(e) => {
                      setCurrentReport(report);
                      e.stopPropagation();
                      handleClick(e);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={Math.ceil(((data || previousData)?.fetchAlertReports?.totalCount ?? 0) / pageSize)}
        page={page}
        onChange={(_e, newPage) => setPage(newPage)}
        sx={{
          p: 1,
          textAlign: 'right',
          '.MuiPagination-ul': {
            justifyContent: 'end',
          },
        }}
      />
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {transitions.map((tr: any) => (
          tr.from.includes(currentReport.state) && (
            <MenuItem key={tr.name} onClick={async () => {
              setIsLoading(true);
              await transitionReport({ variables: { input: { reportId: currentReport.id, transition: tr.name } } });
              refetch();
            }}>
              {t(`reportTransitions.${tr.name}`)}
            </MenuItem>
          )
        ))}
      </Menu>
      {currentReport.transfer && (
        <TransferModal transfer={currentReport.transfer} handleClose={() => setModalOpen(false)} open={modalOpen} options={{}} transition={transition} />
      )}
      <Dialog open={riskModalOpen} onClose={() => setRiskModalOpen(false)} maxWidth='sm' fullWidth>
        <DialogTitle onClose={() => setRiskModalOpen(false)}>
          {t('activityTable.fraudRisk')}
        </DialogTitle>
        <DialogContent>
          <Box display='flex' justifyContent='space-evenly'>
            <Box display='flex' flexDirection='column'>
              <Typography variant='bodyLarge'>{t('fraudRiskScore')}</Typography>
              <Typography variant='displayMedium'>{currentReport.transfer?.subAccount?.account?.user?.fraudRisk?.score}</Typography>
            </Box>
            <Box display='flex' flexDirection='column'>
              <Typography variant='bodyLarge'>{t('lastUpdatedBy')}</Typography>
              <Typography variant='displayMedium'>{t(currentReport.transfer?.subAccount?.account?.user?.fraudRisk?.lastUpdatedBy)}</Typography>
            </Box>
          </Box>
        </DialogContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableHeadCell>{t('activityTable.fraudRisk')}</TableHeadCell>
              <TableHeadCell>{t('activityTable.alertDescription')}</TableHeadCell>
            </TableRow>
            {currentReport.transfer?.subAccount?.account?.user?.fraudRisk?.riskReasons?.map((reason: any) => (
              <TableRow key={reason}>
                <TableCell component="th" scope="row">
                  {reason.translatedDisplayName?.en}
                </TableCell>
                <TableCell>
                  {reason.translatedDescription?.en}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Dialog>
    </Card>
  );
};

export default TransactionMonitoring;

import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function ModelPortfoliosBreadcrumb() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const searchParams = new URLSearchParams(location.search);
  const modelPortfoliosPath = `/modelPortfolios${searchParams.toString() ? `?${searchParams.toString()}` : ''}`;

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(modelPortfoliosPath);
  };

  return (
    <span onClick={handleClick} style={{ cursor: 'pointer' }}>
      {t('navMenu:menu.modelPortfolios')}
    </span>
  );
}

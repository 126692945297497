import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { SelectField, MenuItem } from '../../2-component';

/* TODO: remove GOALS_DETAIL, SUB_ACCOUNTS_DETAIL, ACCOUNTS_DETAIL types from the backend */
const FETCH_PAGE_TYPES = gql`
  query fetchPageTypes {
    __type(name:"Pages") {
    enumValues{
      name
    }
  }
}
`;

export const AccessiblePagesSelect = (
  { label, accessiblePages, onChange }:
  { label: string, accessiblePages: string[], onChange: (event: any) => void },
) => {
  const { data: fetchResponse } = useQuery(FETCH_PAGE_TYPES);
  const [pages, setPages] = useState<string[]>([]);

  useEffect(() => {
    if (fetchResponse?.__type) setPages(fetchResponse.__type.enumValues.map((v:Record<string, string>) => v.name));
  }, [fetchResponse]);

  return (
    <SelectField
      label={label}
      multiple
      value={accessiblePages}
      onChange={onChange}
      fullWidth
    >
      {
        pages.map((x: any) => (
          <MenuItem key={x} value={ x }>
            {x}
          </MenuItem>
        ))
      }
    </SelectField>
  );
};

import { TFunction } from 'i18next';

export const getAccountName = (object: any, t: TFunction): string => {
  const subAccountName = object?.subAccount?.goal?.name;
  const accountType = object.subAccount?.account?.type
    ? t(`accountTypes:${object.subAccount.account.type}`)
    : object?.account?.type
      ? t(`accountTypes:${object.account.type}`)
      : undefined;

  if (!subAccountName && !accountType) return '-';
  return `${subAccountName || ''}${subAccountName && accountType ? ' - ' : ''}${accountType || ''}`;
};

import { useTranslation } from 'react-i18next';
import DocumentManagement from '../../documentManagement';
import { SettingsTitle } from '../../../3-pattern';

export const Forms = () => {
  const { t } = useTranslation(['settings']);
  return (
    <>
      <SettingsTitle title={t('settings:forms.title')} description={t('settings:forms.description')} />
      <DocumentManagement />
    </>
  );
};

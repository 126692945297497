import { useContext, useRef, useState } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CREATE_FILE_DOCUMENT, FETCH_FILE_UPLOAD_URL } from '../../client/components/documentUpload';
import { UserContext } from '../../../providers/userContextProvider';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import { FETCH_DOCUMENTS } from '../../client/components/documents';
import RichTextEditor from '../../../ovComponents/3-pattern/richTextEditor/richTextEditor';

const EditableTemplate = () => {
  const { t } = useTranslation(['orgSettings', 'shared']);
  const editorRef = useRef<any>(null);
  const [uploadPending, setUploadPending] = useState(false);
  const { showToast } = useGlobalToast();
  const { activeOrganization, userContext } = useContext(UserContext);
  const [template, setTemplate] = useState('');
  const [name, setName] = useState('');

  const [fetchFileUploadUrl] = useLazyQuery(FETCH_FILE_UPLOAD_URL, { fetchPolicy: 'no-cache' });
  const [createFileDocument] = useMutation(CREATE_FILE_DOCUMENT);
  const log = async () => {
    if (editorRef.current) {
      const newHtml = new File([editorRef.current.getContent()], name, { type: 'text/html; charset=UTF-8' });
      await doUpload(newHtml);
    }
  };
  const doUpload = async (uploadingFile: File) => {
    const createFileInput: any = {
      objectType: 'ORGANIZATION',
      objectId: activeOrganization.id,
      fileName: uploadingFile?.name,
      type: 'TEMPLATE',
      sourceId: activeOrganization.id, // will be replaced by templateId or autogenerate Id
    };

    /* (1) fetch the S3 upload URL from backend */
    const queryResult = await fetchFileUploadUrl({ variables: { input: { ...createFileInput, userId: userContext.id, generateUniqueFileName: true } } });
    const uploadUrl = queryResult?.data?.fetchFileUploadUrl.temporarySignedURL;
    const s3Path = queryResult?.data?.fetchFileUploadUrl.s3Path;
    if (!uploadUrl || queryResult?.error) {
      showToast({ severity: 'error', message: `Upload failed, step 1 of 3 (FETCH_FILE_UPLOAD_URL) ${queryResult?.error}` });
      return;
    }
    /* (2) do the upload */
    try {
      setUploadPending(true);
      const uploaded: Response = await fetch(new Request(uploadUrl, { method: 'PUT', body: uploadingFile }));
      if (!uploaded.ok) throw new Error(`${uploaded.status} ${uploaded.statusText}`);
    } catch (e: any) {
      showToast({ severity: 'error', message: `Upload failed, step 2 of 3 (HTTP PUT) ${e}` });
      return;
    } finally {
      setUploadPending(false);
    }

    /* (3) create the fileDocument within backend */
    createFileInput.name = uploadingFile.name;
    createFileInput.mediaType = uploadingFile.type;
    createFileInput.sharedClient = true;
    createFileInput.sharedCustodian = true;
    createFileInput.permissionType = 'ORGANIZATIONAL';
    createFileInput.sourceType = 'TEMPLATE';
    createFileInput.s3Path = s3Path;

    try {
      await createFileDocument({
        variables: { input: createFileInput },
        refetchQueries: [FETCH_DOCUMENTS],
      });
    } catch (error) {
      showToast({ severity: 'error', message: `Upload failed, step 3 of 3 (CREATE_FILE_DOCUMENT) ${error}` });
      return;
    }
    showToast({ severity: 'success', message: `Document uploaded successfully: ${uploadingFile.name}` });
  };
  return (
    <Grid container spacing={2} px={2}>
      <Grid item xs={6}>
        <TextField
          fullWidth
          data-testid='templateName'
          value={name}
          placeholder={t('orgSettings:editableTemplate.placeholder')}
          label={t('orgSettings:editableTemplate.templateName')}
          onChange={(e) => setName(e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <Button disabled={!(name && template)} sx={{ marginBottom: '5px', width: '100%', height: '100%' }} variant='contained' onClick={log}>
          {t('orgSettings:editableTemplate.saveTemplate')}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <RichTextEditor readOnly={uploadPending} id='editFormTemplateMceEditor' value={template} setValue={setTemplate} />
      </Grid>
    </Grid>
  );
};

export default EditableTemplate;

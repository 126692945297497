import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Grid, MenuItem } from '@mui/material';
import { Transfer, User } from 'interfaces';
import { colors } from 'ovComponents/0-tokens';
import { Box, Typography } from 'ovComponents/1-primative';
import {
  Button, Dialog, Form, SelectField,
} from 'ovComponents/2-component';
import { translateBackend } from 'assets/i18n/config';
import ExternalTransferDialogContent, { ExternalTransfer } from './types/externalTransfer/externalTransfer.dialogContent';
import ExternalTransferReviewItem from './types/externalTransfer/externalTransfer.reviewItem';
import BankAccountTransferDialogContent, { BankAccountTransfer } from './types/bankAccountTransfer/bankAccountTransfer.dialogContent';
import BankAccountTransferReviewItem from './types/bankAccountTransfer/bankAccountTransfer.reviewItem';
import SubAccountTransferDialogContent, { SubAccountTransfer } from './types/subAccountTransfer/subAccountTransfer.dialogContent';
import SubAccountTransferReviewItem from './types/subAccountTransfer/subAccountTransfer.reviewItem';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';
import { DeprecatedBadge } from '../../../../3-pattern/deprecatedBadge/deprecatedBadge';

type TransferType = Transfer | BankAccountTransfer | ExternalTransfer | SubAccountTransfer;

export const TransferVisual = ({
  options, userData, updateTransfers, transfers, continueFunc,
  workflowCompletion,
}: {
  options: any, userData: Partial<User>, isLoading: boolean, transfers: TransferType[], workflowCompletion?: any,
  updateUser: (user: Partial<User>) => void, updateTransfers: (transfers: TransferType[]) => void, continueFunc: () => void,
}) => {
  const { sendAnalytic } = useContext(AnalyticsContext);
  const { t } = useTranslation(['client', 'accountTypes', 'workflowCompletions']);
  const [open, setOpen] = useState<boolean>(false);
  const [transferSource, setTransferSource] = useState<string>('');

  const handleAccountCreated = (transfer: TransferType): void => {
    updateTransfers([...transfers, transfer]);
    setTransferSource('');
    setOpen(false);
  };

  const deleteTransfer = (index: number): void => {
    // slice not working for useState array
    const tempTransfer: TransferType[] = [];
    transfers.forEach((item: TransferType, index2: number) => {
      if (index2 !== index) tempTransfer.push(item);
    });
    updateTransfers([...tempTransfer]);
  };

  const onSubmit = () => {
    sendAnalytic(ovAnalyticsEvents.workflowsTransfersContinueButtonSelect, {
      workflowStepTitle: options?.title,
      workflowId: workflowCompletion?.workflow?.id,
      workflowName: workflowCompletion?.workflow?.name,
      activeWorkflowCompletionId: workflowCompletion?.id,
      objectId: workflowCompletion?.objectId,
      objectType: workflowCompletion?.objectType,
    });
    continueFunc();
  };

  const getTransferContent = (value: string): JSX.Element => {
    switch (value) {
      case 'EXTERNAL_TRANSFER_IN': {
        return (
          <ExternalTransferDialogContent
            onDialogClose={() => setOpen(false)}
            userData={userData}
            onTransferCreated={(item) => handleAccountCreated(item)}
            disclaimer= {options.enableExternalTransfer?.disclaimer}
          />
        );
      }
      case 'BANK_ACCOUNT': {
        return (
          <BankAccountTransferDialogContent
            onDialogClose={() => setOpen(false)}
            userData={userData}
            onTransferCreated={(item) => handleAccountCreated(item)}
            disclaimer= {options.enableEft?.disclaimer}
          />
        );
      }
      case 'TRANSFER_TO_SUB_ACCOUNT': {
        return (
          <SubAccountTransferDialogContent
            onDialogClose={() => setOpen(false)}
            userData={userData}
            onTransferCreated={(item) => handleAccountCreated(item)}
            disclaimer= {options.enableTransferPortfolio?.disclaimer}
          />
        );
      }
      default: {
        return <></>;
      }
    }
  };

  const getTransferReviewItem = (transfer: TransferType, index: number): JSX.Element => {
    if ('institution' in transfer || ('source' in transfer && transfer.source === 'EXTERNAL_TRANSFER_IN')) {
      return (<ExternalTransferReviewItem key={index} transfer={transfer} onDeleteTransfer={() => deleteTransfer(index)} />);
    }
    if ('bankAccount' in transfer) {
      return (<BankAccountTransferReviewItem key={index} transfer={transfer} onDeleteTransfer={() => deleteTransfer(index)} />);
    }
    if ('subAccount' in transfer || ('source' in transfer && transfer.source === 'TRANSFER_TO_SUB_ACCOUNT')) {
      return (<SubAccountTransferReviewItem key={index} transfer={transfer} onDeleteTransfer={() => deleteTransfer(index)} />);
    }
    return <></>;
  };

  const transfersToBeCreated = transfers.filter((transfer) => !(transfer as Transfer).id);

  return (
    <Form onSubmit={onSubmit}>
      <Box display={'flex'} alignItems={'center'} gap={2}>
        <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
        <DeprecatedBadge/>
      </Box>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>
        <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.subtitle)}</ReactMarkdown>
      </Typography>
      {transfers.map((item, index) => getTransferReviewItem(item, index))}
      {transfers.length > 0 && (
        <Typography variant='headingOverline' sx={{ color: colors.neutral600, mb: 2, mt: 3 }}>
          {t('workflowCompletions:addAnotherTransfer')}
        </Typography>
      )}
      <Grid container display='flex' flexDirection='row' sx={{ mb: 2 }} justifyContent='space-between' spacing={1} alignItems='end'>
        <Grid item xs={8}>
          <SelectField
            onChange={(e: any) => setTransferSource(e.target.value)}
            label={t('workflowCompletions:source')}
            fullWidth
            value={transferSource}
          >
            {options?.enableEft?.value && (
              <MenuItem value='BANK_ACCOUNT'>{t('workflowCompletions:sourceOfTransfer.bankAccount')}</MenuItem>
            )}
            {options?.enableExternalTransfer?.value && (
              <MenuItem value='EXTERNAL_TRANSFER_IN'>{t('workflowCompletions:sourceOfTransfer.externalTransferIn')}</MenuItem>
            )}
            {options?.enableTransferPortfolio?.value && (
              <MenuItem value='TRANSFER_TO_SUB_ACCOUNT'>{t('workflowCompletions:sourceOfTransfer.transferToSubAccount')}</MenuItem>
            )}
          </SelectField>
        </Grid>
        <Grid item xs={4}>
          <Button variant='outlined' onClick={() => setOpen(true)} label={t('workflowCompletions:start')} type='button' sx={{
            textAlign: 'center',
          }} />
        </Grid>
      </Grid>

      <Box display="flex" justifyContent="end">
        {transfersToBeCreated.length === 0 || transfersToBeCreated.length < 0
          ? null
          : (
            <Button sx={{ mt: 3 }} type='submit' label={t(
              `workflowCompletions:${transfersToBeCreated.length === 1 ? 'createTransferButtonTitle' : 'createTransfersButtonTitle'}`,
              { number: transfersToBeCreated.length },
            )}/>
          )
        }
      </Box>
      <Dialog
        sx={{
          '.MuiDialog-container': {
            '.MuiPaper-root': {
              padding: '20px',
            },
          },
        }}
        open={open}
        onClose={() => setOpen(false)}
        maxWidth='sm'
        fullWidth
      >
        {getTransferContent(transferSource)}
      </Dialog>
    </Form>
  );
};

export default TransferVisual;

import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from '../../../../3-pattern';

export const DELETE_ROLE = gql`
mutation deleteRole($roleId: ObjectID!) {
  deleteRole(roleId: $roleId) {
    deleted
  }
}
`;

export const DeleteRole = ({
  open,
  roleToDelete,
  afterDelete,
  handleClose,
}: {
  open: boolean,
  roleToDelete: any,
  afterDelete: () => void,
  handleClose: () => void
}) => {
  const { t } = useTranslation(['orgSettings']);

  const [deleteRole, { loading }] = useMutation(DELETE_ROLE, {
    variables: {
      roleId: roleToDelete?.id,
    },
  });

  if (!roleToDelete) {
    return <></>;
  }

  return (
    <ConfirmationModal
      onCancel={handleClose}
      onConfirm={async () => { await deleteRole(); afterDelete(); } }
      open={open}
      loading={loading}
      title={`${t('roleDeleteModal.deleteRole')} ${roleToDelete.translatedName.en}?`}
      bodyText={`${t('roleDeleteModal.deletedCompletely')} ${t('roleDeleteModal.wantToProceed')}`}
    >
    </ConfirmationModal>
  );
};

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CountryCodes } from '@onevesthq/ov-enums';
import { kebabCase } from 'lodash';
import {
  eligibleTaxIdTypes, encryptedTaxIdPlaceholder, taxIdTypeFormatType, TaxIdTypes, User,
} from '../../../../../../interfaces';
import { Radio, RadioGroup, TextField } from '../../../../../2-component';

export const TaxIdInputs = ({
  user,
  affiliateUser,
  error,
  setAffiliateUser,
  onBlur,
  disabled = false,
}: {
  user?: Partial<User>;
  affiliateUser: Partial<User>;
  error: boolean;
  setAffiliateUser: (user: Partial<User>) => void;
  onBlur: () => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const [showEncryptedTaxId, setShowEncryptedTaxId] = useState<string | undefined>();
  const [taxIdType, setTaxIdType] = useState<TaxIdTypes | undefined>(affiliateUser.taxIdType);
  const [taxId, setTaxId] = useState<string | undefined>();

  const countryOfTaxResidence = user?.countryOfTaxResidence ?? CountryCodes.CA;
  const taxIdTypesShown = eligibleTaxIdTypes(countryOfTaxResidence, true);
  const defaultTaxIdType = taxIdTypesShown[0];

  // no taxIdType selected yet - pick default
  useEffect(() => {
    if (!taxIdType && defaultTaxIdType) setTaxIdType(defaultTaxIdType);
  }, [taxIdType, defaultTaxIdType, setTaxIdType]);

  // show encrypted "*** *** ***" until touched
  useEffect(() => {
    if (affiliateUser.taxIdExists !== undefined && !taxId) setShowEncryptedTaxId(encryptedTaxIdPlaceholder(taxIdType));
  }, [affiliateUser.taxIdExists, taxId, taxIdType]);

  // propagate upwards
  useEffect(() => {
    if (taxId !== undefined) setAffiliateUser({ ...affiliateUser, taxIdType, taxId });
    if (taxId === '') setAffiliateUser({ ...affiliateUser, taxIdType: undefined, taxId: undefined });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxId]);

  const taxIdLabel = `${t(`taxId:${taxIdType}`)} (${t(`taxId:abbreviated.${taxIdType}`)})`;

  return (
    <>
      {taxIdTypesShown.length > 1 && (
        <RadioGroup
          disabled={disabled}
          testId='tax-id-types'
          value={taxIdType ?? ''}
          label={t('workflowCompletions:residencyInformation.taxIdType')}
          onChange={(e: any) => setTaxIdType(e.target.value)}
        >
          {taxIdTypesShown.map((type) => (
            <Radio key={type} testId={`tax-id-type-${kebabCase(type)}`} label={type} value={type} size='small' />
          ))}
        </RadioGroup>
      )}

      <TextField
        fullWidth
        sx={{ mt: 2 }}
        disabled={disabled}
        testId='affiliate-taxId'
        type={taxIdTypeFormatType(taxIdType)}
        label={taxIdLabel}
        value={showEncryptedTaxId ? '' : taxId}
        placeholder={showEncryptedTaxId}
        onChange={(e: any) => {
          if (showEncryptedTaxId) setShowEncryptedTaxId(undefined);
          setTaxId(e.target.value);
        }}
        onBlur={onBlur}
        error={error}
      />
    </>
  );
};

import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Box } from '../../../1-primative';
import {
  Button, Form,
} from '../../../2-component';
import { SettingsCard } from '../../../3-pattern';
import { UserContext } from '../../../../providers/userContextProvider';
import { useGlobalToast } from '../../../../providers/globalToastProvider';
import { AccountTypes } from '../../../../interfaces';
import AccountTaxRanks from './components/editTaxRanking';

export interface AccountTypeTaxRank {
  accountType: AccountTypes,
  taxRank: number,
}

const FETCH_ORGANIZATION = gql`
  query fetchOrganization($organizationId: String!) {
    fetchOrganization(organizationId: $organizationId) {
      organization {
        id
        name
        accountTypeTaxRanks {
          accountType
          taxRank
        }
      }
    }
  }
`;

const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        id
      }
    }
  }
`;

export const TaxRankingsSettings = () => {
  const { activeOrganization } = useContext(UserContext);
  const { showToast } = useGlobalToast();
  const { t } = useTranslation(['settings', 'shared']);
  const [taxRank, setTaxRank] = useState<AccountTypeTaxRank[]>([]);

  const { data, loading } = useQuery(FETCH_ORGANIZATION, {
    variables: { organizationId: activeOrganization?.id },
  });

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION, {
    variables: {
      input: {
        organizationId: activeOrganization?.id,
        accountTypeTaxRanks: taxRank,
      },
    },
    onCompleted: async () => {
      showToast({
        message: t('shared:successfullySaved'),
        severity: 'success',
      });
    },
  });

  useEffect(() => {
    if (data?.fetchOrganization?.organization) {
      setTaxRank((data?.fetchOrganization?.organization.accountTypeTaxRanks ?? []).map((x: any) => ({ ...x, __typename: undefined })));
    }
  }, [data]);

  return (
    <SettingsCard
      title={t('taxRankings.title')}
      description={t('taxRankings.description')}
    >
      <Form onSubmit={updateOrganization}>
      <Box display='flex' flexDirection='column' gap={2}>
          <AccountTaxRanks taxRanks={taxRank} setTaxRank={setTaxRank} />
          <Box display='flex' justifyContent='flex-end'>
            <Button type='submit' disabled={loading} label={t('shared:save')} dataTestId='settings-general-submit-button'/>
          </Box>
        </Box>
      </Form>
    </SettingsCard>
  );
};

import { useApolloClient, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  useCallback,
  useContext, useEffect, useMemo, useState,
} from 'react';
import { CurrencyCodes } from '@onevesthq/ov-enums';
import { useNavigate } from 'react-router-dom';
import {
  Box, Skeleton, Typography,
} from '../../../1-primative';
import { FETCH_MEMBER_ACCOUNTS, FETCH_MEMBERS } from './householdMembers.queries';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';
import {
  Avatar, Card, CardContent,
} from '../../../2-component';
import { formatMoneyValue } from '../../../../util';
import { UserContext } from '../../../../providers/userContextProvider';
import { currencySymbol } from '../../../../util/currency';
import {
  Account, NavigationStyles, OrganizationUserAccessTypes, Relationship,
} from '../../../../interfaces';
import { AvatarGroup } from '../../../2-component/avatarGroup/avatarGroup';

const BASE_TYPES = [
  'CHILD',
  'COMMON_LAW',
  'GRANDCHILD',
  'GRANDPARENT',
  'GUARDIAN',
  'NEPHEW_NIECE',
  'OTHER',
  'PARENT',
  'PRIMARY_CAREGIVER',
  'SIBLING',
  'SPOUSE',
];

export const HouseholdMembers = ({ objectId, useCustodianData, options }: { objectId: string, useCustodianData?: boolean, options?: any }) => {
  const { ref } = useThemeTokens();
  const navigate = useNavigate();
  const {
    userContext, setActiveEntity, activeCurrency,
  } = useContext(UserContext);
  const { t } = useTranslation('affiliationTypes');
  const client = useApolloClient();
  const [members, setMembers] = useState<Relationship[]>([]);
  const [membersJointAccounts, setMembersJointAccounts] = useState<any>({});
  const { loading } = useQuery(FETCH_MEMBERS(useCustodianData, activeCurrency), {
    variables: {
      clientGroupId: objectId,
      currency: activeCurrency,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => setMembers(data?.fetchClientGroup?.clientGroup?.relationships ?? []),
  });

  useEffect(() => {
    const getMembersAccount = async () => {
      const jointAccountsResult: any = {};

      for (let index = 0; index < members.length; index++) {
        const member = members[index];

        // eslint-disable-next-line no-await-in-loop
        const res = await client.query({
          query: FETCH_MEMBER_ACCOUNTS(useCustodianData, activeCurrency),
          variables: {
            input: {
              filter: {
                clientGroupId: objectId,
                userId: member.user.id,
              },
            },
            currency: activeCurrency,
          },
        });
        const accounts: Account[] = res?.data?.fetchAccounts?.accounts ?? [];

        accounts.forEach((account: Account) => {
          const marketValueCents = (useCustodianData ? account.custodianStatistics?.marketValueCents : account.statistics?.marketValueCents) ?? 0;
          if (Object.hasOwn(jointAccountsResult, account.id)) {
            jointAccountsResult[account.id] = {
              ...jointAccountsResult[account.id],
              members: [...jointAccountsResult[account.id].members, { ...member, index }].sort((a, b) => (
                b.type === 'Primary' ? 1 : a.type === 'Primary' ? -1 : (a.index - b.index)
              )),
            };
          } else {
            jointAccountsResult[account.id] = { members: [{ ...member, index }], marketValueCents };
          }
        });
      }
      setMembersJointAccounts(jointAccountsResult);
    };
    if (options?.showJointAccountTile) getMembersAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members, activeCurrency, objectId, useCustodianData, options]);

  const jointAccountsTiles = useMemo(() => {
    const result: any[] = [];
    const keys = Object.keys(membersJointAccounts);

    keys.forEach((accountId) => {
      if (membersJointAccounts[accountId].members.length > 1) {
        const foundElemIndex = result.findIndex((elem) => JSON.stringify(elem.members) === JSON.stringify(membersJointAccounts[accountId].members));
        if (foundElemIndex >= 0) {
          const newElem = { ...result[foundElemIndex], marketValueCents: membersJointAccounts[accountId].marketValueCents + result[foundElemIndex].marketValueCents };
          result.splice(foundElemIndex, 1, newElem);
        } else {
          result.push(membersJointAccounts[accountId]);
        }
      }
    });

    return result;
  }, [membersJointAccounts]);

  const getJointMarketValue = useCallback((userId: string) => (options?.showJointAccountTile
    ? jointAccountsTiles.reduce((total: number, tile: any) => {
      const tileWithMember = tile.members.find((member: any) => member.user.id === userId);
      return total + (tileWithMember ? tile.marketValueCents : 0);
    }, 0)
    : 0
  ), [jointAccountsTiles, options]);

  const color = [
    { bgColor: ref.palette.supportOne40, color: ref.palette.supportOne60 },
    { bgColor: ref.palette.supportTwo40, color: ref.palette.supportTwo60 },
    { bgColor: ref.palette.supportThree40, color: ref.palette.supportThree60 },
    { bgColor: ref.palette.supportFour40, color: ref.palette.supportFour60 },
    { bgColor: ref.palette.supportFive40, color: ref.palette.supportFive60 },
    { bgColor: ref.palette.supportSix40, color: ref.palette.supportSix60 },
  ];

  const getHouseholdValidEntity = (userId: any) => {
    const entities = userContext?.entities?.filter((x) => !x.entity.affiliateOnly);
    const validEntity = entities?.find((elem) => elem.entity.id === userId);
    const isHouseholdDefaultView = !!userContext.role?.makeHouseholdDefaultView && (validEntity?.entity.households?.length ?? 0) > 0;
    if (isHouseholdDefaultView && validEntity) {
      return validEntity;
    }
    return undefined;
  };

  if (loading) {
    return (
      <Skeleton width='100%' height='80px' variant='rectangular'></Skeleton>
    );
  }

  const getAvatarChar = (user: any) => {
    if (user.entityName) {
      return user.entityName[0];
    }
    if (user.firstName) {
      return user.firstName[0];
    }
    if (user.lastName) {
      return user.lastName[0];
    }
    return '-';
  };

  return (
    <>
      {members.map((member: Relationship, index: number) => (
        <Card
          key={member.user.id}
          sx={{ mb: 2, cursor: userContext.accessType === OrganizationUserAccessTypes.ORGANIZATION || getHouseholdValidEntity(member.user.id) ? 'pointer' : null }}
          onClick={() => {
            if (userContext.accessType === OrganizationUserAccessTypes.ORGANIZATION) {
              if (userContext.role?.makeHouseholdDefaultView && userContext.role.navigationStyle === NavigationStyles.SIMPLE) {
                navigate(`/households/${objectId}/clients/${member.user.id}`);
              } else {
                navigate(`/clients/${member.user.id}`);
              }
            } else {
              const validEntity = getHouseholdValidEntity(member.user.id);
              if (validEntity) {
                if (validEntity?.entity.households) {
                  navigate(`/households/${objectId}/clients/${member.user.id}`);
                  setActiveEntity(validEntity.entity);
                }
              }
            }
          }}
        >
          <CardContent sx={{ paddingBottom: '16px !important' }}>
            <Box display='flex' flexDirection='row'>
              <Box display='flex' alignItems='center' mr={1}>
                <Avatar size='large' bgColor={color[index % 6].bgColor} fgColor={color[index % 6].color}>{getAvatarChar(member.user)}</Avatar>
              </Box>
              <Box
                width='100%'
                display='flex'
                flexDirection='column'
                justifyContent={options?.hideHouseholdMemberTypes || !options?.showJointAccountTile ? 'center' : 'top'}
              >
                <Box display='flex' justifyContent='space-between'>
                  <Typography variant='bodyLarge' weight='bold'>{member.user.entityName ? member.user.entityName : `${member.user.firstName} ${member.user.lastName}`}</Typography>
                  <Typography variant='bodyLarge' weight='bold'>
                    {formatMoneyValue(
                      (((useCustodianData ? member.user.custodianStatistics : member.user.statistics)?.marketValueCents ?? 0) - getJointMarketValue(member.user.id)),
                      currencySymbol[activeCurrency as CurrencyCodes ?? CurrencyCodes.CAD],
                    )}
                  </Typography>
                </Box>
                {!options?.hideHouseholdMemberTypes && (
                  <Box display='flex'>
                    <Typography variant='bodyMedium'>{BASE_TYPES.includes(member.type) ? t(`affiliationRelationship.${member.type}`) : member.type}</Typography>
                  </Box>
                )}
                {options?.showJointAccountTile && (
                  <Box display='flex' justifyContent='space-between'>
                    <Typography variant='bodySmall' colorVariant='variant'>{t('totalWithJoint')}</Typography>
                    <Typography variant='bodySmall' colorVariant='variant'>
                      {formatMoneyValue(
                        ((useCustodianData ? member.user.custodianStatistics : member.user.statistics)?.marketValueCents ?? 0),
                        currencySymbol[activeCurrency as CurrencyCodes ?? CurrencyCodes.CAD],
                      )}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
      ))}
      {options?.showJointAccountTile && jointAccountsTiles.map((tile: any, idx: number) => (
        <Card key={idx} sx={{ mb: 2 }}>
          <CardContent sx={{ paddingBottom: '16px !important' }}>
            <Box display='flex' flexDirection='row'>
              <Box display='flex' mr={1}>
                <AvatarGroup
                  max={3}
                  elements={tile.members.map((member: any) => ({
                    size: 'large',
                    bgColor: color[member.index % 6].bgColor,
                    fgColor: color[member.index % 6].color,
                    children: getAvatarChar(member.user),
                  }))}
                  maxAvatarProps={{ size: 'large' }}
                />
              </Box>
              <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' width='100%'>
                <Typography variant='bodyLarge' weight='bold'>
                  {`${tile.members.map((member: any) => (
                    member.user.entityName ? member.user.entityName : `${member.user.firstName}`
                  )).join(', ').replace(/,(?=[^,]+$)/, ' &')} ${t('JOINT')}` }
                </Typography>
                <Typography variant='bodyLarge' weight='bold'>
                  {formatMoneyValue(tile.marketValueCents, currencySymbol[activeCurrency as CurrencyCodes ?? CurrencyCodes.CAD])}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      ))}
    </>
  );
};

import { useTranslation } from 'react-i18next';
import {
  Pagination as MuiPagination, TablePagination as MuiTablePagination, TablePaginationProps, PaginationProps,
} from '@mui/material';
import { SelectField } from '../selectField/selectField';
import { Box, Typography } from '../../1-primative';
import { MenuItem } from '../menuItem/menuItem';

interface IPaginationProps extends PaginationProps {
  perPage?: number;
  total?: number;
  onChangePerPage?: (perPage: number) => void;
  showPerPage?: boolean;
}

export const Pagination = ({
  perPage = 15, onChangePerPage, total, showPerPage = true, ...props
}: IPaginationProps) => {
  const { t } = useTranslation('shared');

  const page = props.page || 1;
  const offSet = perPage * (page - 1);

  const lastItem = total
    ? Math.min(offSet + perPage, total)
    : 0;

  return (
    <Box display='flex' justifyContent={ total ? 'space-between' : 'end' } alignItems='center' overflow='hidden'>
      {total && <Typography variant="bodySmall">{t('paginationSummary', { start: offSet + 1, end: lastItem, total })}</Typography>}
      <Box display='flex' alignItems='center' overflow='hidden'>
        {
          showPerPage && perPage && onChangePerPage && (
            <SelectField
              label=''
              value={perPage}
              onChange={(e: any) => onChangePerPage(e.target.value)}
              size='small'
              sx={{ p: 0 }}
            >
              <MenuItem value={15}>15 {t('perPage')}</MenuItem>
              <MenuItem value={25}>25 {t('perPage')}</MenuItem>
              <MenuItem value={50}>50 {t('perPage')}</MenuItem>
              <MenuItem value={100}>100 {t('perPage')}</MenuItem>
            </SelectField>
          )
        }
        <MuiPagination {...props} />
      </Box>
    </Box>
  );
};

export const TablePagination = (props: TablePaginationProps) => (
  <MuiTablePagination {...props} />
);

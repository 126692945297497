import { gql, useQuery } from '@apollo/client';
import { Box, Typography } from '../../../../1-primative';
import { NumberField } from '../../../../3-pattern';
import { AccountTypes } from '../../../../../interfaces';
import { translateBackend } from '../../../../../assets/i18n/config';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';

export const FETCH_ACCOUNT_TYPES = gql`
  query fetchAccountTypes {
    fetchAccountTypes {
      accountTypes {
        value
        translatedName {
          en
        }
      }
    }
  }
`;

export interface AccountTypeTaxRank {
  accountType: AccountTypes,
  taxRank: number,
}

const AccountTaxRanks = ({ taxRanks, setTaxRank }: { taxRanks: AccountTypeTaxRank[], setTaxRank: React.Dispatch<React.SetStateAction<AccountTypeTaxRank[]>> }) => {
  const { error, data } = useQuery(FETCH_ACCOUNT_TYPES);
  const { sys } = useThemeTokens();
  if (error) (<Typography>Error</Typography>);
  let accountTypesData: any[] = [];
  if (data) {
    accountTypesData = accountTypesData.concat(data.fetchAccountTypes.accountTypes).filter(
      (t) => t.value && !t.value.startsWith('USA_') && !t.value.startsWith('UK_'),
    );
    if (accountTypesData !== undefined && accountTypesData.length > 0) {
      accountTypesData = accountTypesData.sort((a: any, b: any) => (translateBackend(a.translatedName).localeCompare(translateBackend(b.translatedName))));
    }
  }

  const accountTaxRank = (accountType: AccountTypes): number | '' => taxRanks?.find((x) => x.accountType === accountType)?.taxRank ?? '';
  const updateFields = (accountType: AccountTypes, inputValue?: number) => {
    let value = 1;

    if (inputValue && inputValue > 0) value = inputValue;

    const newArr: AccountTypeTaxRank[] = taxRanks.map((x) => ({ ...x, __typename: undefined }));
    const index = newArr.findIndex((x) => x.accountType === accountType);
    if (index !== -1) {
      newArr[index] = { accountType, taxRank: value };
    } else {
      newArr.push({ accountType, taxRank: value });
    }

    setTaxRank(newArr);
  };

  return (
    <Box display='flex' flexDirection='column' gap={2}>
      {accountTypesData.map((x: any) => (
        <Box display='flex' justifyContent='space-between' alignItems='center' pb={2} borderBottom={`1px solid ${sys.color.outlineVariant}`}>
          <Typography variant='labelLarge'>{translateBackend(x.translatedName)}</Typography>
          <NumberField
            label=''
            decimalPlaces={0}
            number={(accountTaxRank(x.value) ?? 0).toString()}
            setNumber={(value: any) => updateFields(x.value, Number(value))}
          />
        </Box>
      ))}
    </Box>
  );
};

export default AccountTaxRanks;

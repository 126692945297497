import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';
import { TranslatedString, FormTemplate } from '../../../../interfaces';
import { translateBackend } from '../../../../assets/i18n/config';
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  TextField,
} from '../../../2-component';
import { Box, Grid } from '../../../1-primative';

const TitleEdit = ({
  formTemplate,
  value,
  onChange,
  openDialog,
  setOpenDialog,
  rows = 1,
  multiline,
}: {
  formTemplate?: FormTemplate | null,
  value: TranslatedString,
  onChange: Dispatch<SetStateAction<TranslatedString>>,
  rows?: number,
  openDialog: boolean,
  setOpenDialog: any,
  error?: boolean,
  errorText?: string,
  multiline?: boolean,
}) => {
  const { t } = useTranslation('components');

  return (
    <Dialog onClose={() => setOpenDialog(false)} open={openDialog} fullWidth>
        <DialogTitle>{t('translate')}{translateBackend(formTemplate?.translatedDisplayName)}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                value={value?.en}
                label={t('languages.english')}
                onChange={(e: any) => onChange({ ...value, en: e.target.value.trimStart() })}
                multiline={multiline}
                rows={rows > 1 ? rows : undefined}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                value={value?.fr}
                label={t('languages.french')}
                onChange={(e: any) => onChange({ ...value, fr: e.target.value.trimStart() })}
                multiline={multiline}
                rows={rows > 1 ? rows : undefined}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogFooter>
          <Box display='flex' justifyContent='end'>
            <Button variant='filled' onClick={() => setOpenDialog(false)} label={t('done')} />
          </Box>
        </DialogFooter>
      </Dialog>
  );
};
export default TitleEdit;

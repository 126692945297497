import { kebabCase } from 'lodash/fp';
import { useMatches } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from '../../2-component';

export type AppBreadcrumbsProps = { overrideColor?: string };
export function AppBreadcrumbs({ overrideColor }: AppBreadcrumbsProps) {
  const { t } = useTranslation();
  const matches = useMatches();
  const routesWithBreadcrumb = matches.filter((match:any) => Boolean(match.handle?.breadcrumb));
  const items = routesWithBreadcrumb.map((route) => {
    const theLabel = (route.handle as any)?.breadcrumb;
    const testId = typeof (theLabel) === 'function' ? `${kebabCase(theLabel.name)}-link` : `${kebabCase(theLabel)}-breadcrumb-link`;
    return {
      href: route.pathname,
      label: typeof (theLabel) === 'function' ? theLabel : t(theLabel),
      testId,
    };
  });
  return (
    <Breadcrumbs items={items} overrideColor={overrideColor} />
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import {
  gql, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import {
  useState, useEffect, createContext, useContext,
} from 'react';
import { CountryCodes, CurrencyCodes } from '@onevesthq/ov-enums';
import omitDeep from 'omit-deep-lodash';
import { adjust } from '../theme/colors';
import { delay } from '../util';
import {
  EntityTypes, OrganizationUserAccessTypes, Languages, ClientGroup, Role, BareLocalization, Integration, EnabledJurisdictions, TranslatedString,
  UserNotificationPreference,
} from '../interfaces';
import { useAuthContext } from './ovApolloProvider';
import { availableFeatureFlagsVar } from '../util/localVariables';
import { setBackendLanguage } from '../assets/i18n/config';
import filterHouseholdsWithAccess from '../util/filterHouseholdsWithAccess';
import { CustodianConnection } from '../interfaces/custodianConnection';
import { useGlobalToast } from './globalToastProvider';
import { DowntimeSchedule, DowntimeSchedulePlatform } from '../interfaces/downtimeSchedule';
import { modifyFavIcon, modifyTabTitle, setOrgPublicSettings } from '../util/browserTabUpdates';

export type DowntimeType = Pick<DowntimeSchedule, 'translatedTitle' | 'translatedDescription'>;

interface Widget {
  id: string,
  type: string,
  options: any,
}

interface Tab {
  id: string,
  icon: string,
  label: { en: string, fr: string },
  widgets: Widget[],
}

export interface PageConfiguration {
  id: string,
  tabs: Tab[],
  options?: any,
}

export interface UserContextProps {
  id?: string,
  firstName?: string,
  lastName?: string,
  email?: string,
  phone?: string,
  language?: Languages,
  accessType?: OrganizationUserAccessTypes,
  notificationPreferences?: UserNotificationPreference[],
  role?: Role,
  avatar?: string,
  availableCurrencies?: string[],
  organization?: {
    id: string,
    name: string,
    subdomain: string,
    theme?: { sideBarColor?: string, logo?: string },
    supportUrl: string,
    helpCentreUrl: string,
    enableContactSupport?: boolean,
    enableResourcesAndArticles?: boolean,
    logoutRedirectUrl?: string,
    enableInactivityTimeOut?: boolean,
    inactivityTimeoutInMinutes?: number,
    clientHostedLoginURL?: string,
    clientHostedLoginRedirectUri?: string,
  },
  applicableDowntimeSchedule?: DowntimeType,
  entities?: [{
    entity: {
      id: string,
      affiliateOnly?: boolean,
      type?: EntityTypes,
      entityName?: string,
      firstName?: string,
      lastName?: string,
      primaryEmail?: string,
      households?: ClientGroup[],
    }
  }],
}

export type SetUserContextType = React.Dispatch<React.SetStateAction<UserContextProps>>;

type UserContextType = {
  userContext: UserContextProps,
  setUserContext: SetUserContextType,
  activeOrganization: ActiveOrganizationProps,
  setActiveOrganizationId: React.Dispatch<React.SetStateAction<string | undefined>>,
  activeEntity: ActiveEntityProps | undefined,
  setActiveEntity: React.Dispatch<React.SetStateAction<any | undefined>>,
  activeHousehold: ClientGroup | undefined,
  setActiveHousehold: React.Dispatch<React.SetStateAction<ClientGroup | undefined>>,
  activeLanguage: Languages | undefined,
  setActiveLanguage: React.Dispatch<React.SetStateAction<Languages>>,
  organizationIds: string[],
  setOrganizationIds: React.Dispatch<React.SetStateAction<string[]>>,
  userId: string | undefined,
  setUserId: React.Dispatch<React.SetStateAction<string | undefined>>
  activeCurrency: string,
  setActiveCurrency: React.Dispatch<React.SetStateAction<string>>,
  availableCurrencies: string[],
  setAvailableCurrencies: React.Dispatch<React.SetStateAction<string[]>>,
  loading: boolean,
  integrations: Integration[],
  custodianConnection: CustodianConnection,
  baseCurrency: string,
  closed: boolean,
  displayMenuBar: boolean,
  setClosed: React.Dispatch<React.SetStateAction<boolean>>,
  avatar?: string,
  refetch: () => void,
};

export interface ExternalProvider {
  id: string,
  idVerificationProvider: { templateId: string, vendor: string },
  bankingConnectorProvider: { clientIframeUrl: string, vendor: string },
}

export interface ActiveOrganizationProps {
  id?: string,
  name?: string,
  availableFeatureFlags?: string[],
  themeTokens?: any,
  theme?: { logo?: string, sideBarColor?: string, graphColors?: string[] },
  externalProvider?: ExternalProvider,
  reviewTransactions?: boolean,
  displayMenuBar?: boolean,
  allowLogout?: boolean,
  displayCurrency?: boolean,
  allowPortfolioPerGoal?: boolean,
  useParentIntegrations?: boolean,
  allowPostOptimizationEditing?: boolean,
  applicableLocalization: BareLocalization,
  jurisdictions: EnabledJurisdictions,
  minRecurringDepositCents?: number,
  profileReviewTimeInMonths?: number,
  requireSubTradeRequestApproval?: boolean,
  allowViewSubTradeRequestPairs?: boolean,
  allowPendingTransactions?: boolean,
  faviconLink?: string,
  browserTabTitle?: string,
  supportUrl?: string,
  helpCentreUrl?: string,
  enableContactSupport?: boolean,
  enableResourcesAndArticles?: boolean,
  logoutRedirectUrl?: string,
  enableInactivityTimeOut?: boolean,
  inactivityTimeoutInMinutes?: number,
  clientHostedLoginURL?: string,
  clientHostedLoginRedirectUri?: string,
  minDepositCents?: number,
  minInitialDepositCents?: number,
  productShelfCurrencies?: CurrencyCodes[],
  errorPageMessage?: TranslatedString,
  errorPageDescription?: TranslatedString,
  errorPageButtonText?: TranslatedString,
  errorPageBackgroundColor?: string,
  errorPageBackgroundImage?: string,
  errorPageShowNavbar?: boolean,
}

export interface ActiveEntityProps {
  id?: string,
  type?: string,
  entityName?: string,
  firstName?: string,
  lastName?: string,
  primaryEmail?: string,
  households?: ClientGroup[],
  availableFeatureFlags?: string[]
}

const PAGE_CONFIGURATION = `#graphql
  id
  type
  options
  tabs { id icon url label { en fr } widgets { id type options } }
`;

const STATIC_PAGE_WIDGET_CONFIGURATION = `#graphql
  id
  widget { id type options }
`;

export const ME = (permissions?: string[]) => gql`
  query me {
    me {
      id
      firstName
      lastName
      language
      email
      notificationPreferences {
        notificationGroup {
          id
          name { en fr }
          description { en fr }
          notificationChannels {
            type
            value
            allowEditByClient
          }
        }
        preferences {
          enableEmail
          enableInApp
          enablePush
        }
      }
      phone
      accessType
      avatar
      role {
        id
        translatedName { en fr }
        permissions
        accessiblePages
        publicRoleProfile { id translatedName { en fr } }
        ${permissions && permissions.includes('read:dashboards') ? `#graphql
          dashboards {
            id
            name
            widgets {
              width
              height
              x
              y
              title
              format
              type
              reportType
              columns
              sortDesc
              sortField
              filters { field comparison value }
              grouping { field interval type valueField valueAggregation }
            }
          }
        ` : ''}
        navigationStyle
        individualPageConfiguration { ${PAGE_CONFIGURATION} }
        nonIndividualPageConfiguration { ${PAGE_CONFIGURATION} }
        goalPageConfiguration { ${PAGE_CONFIGURATION} }
        accountPageConfiguration { ${PAGE_CONFIGURATION} }
        householdPageConfiguration { ${PAGE_CONFIGURATION} }
        subAccountPageConfiguration { ${PAGE_CONFIGURATION} }
        individualTableStaticPageWidgetConfiguration { ${STATIC_PAGE_WIDGET_CONFIGURATION} }
        nonIndividualTableStaticPageWidgetConfiguration { ${STATIC_PAGE_WIDGET_CONFIGURATION} }
        householdTableStaticPageWidgetConfiguration { ${STATIC_PAGE_WIDGET_CONFIGURATION} }
        makeHouseholdDefaultView
      }
      organization {
        id
        subdomain
        name
        themeTokens
        supportUrl
        helpCentreUrl
        logoutRedirectUrl
        enableInactivityTimeOut
        inactivityTimeoutInMinutes
        clientHostedLoginURL
        clientHostedLoginRedirectUri
        defaultSignUpRole { id }
      }
      applicableDowntimeSchedule (platform: ${DowntimeSchedulePlatform.WEB}) {
        translatedTitle { en fr }
        translatedDescription { en fr }
      }
      ${permissions && permissions.includes('read:organization_user_entities') ? `#graphql
        entities {
          entity {
            id
            type
            affiliateOnly
            entityName
            firstName
            lastName
            primaryEmail
            households {
              id
              relationships { accessType user { id } }
              organization { id }
            }
          }
        }
      ` : ''}
    }
  }
`;

export const FETCH_ORGANIZATION = (organizationId: string, permissions?: string[]) => gql`
  query fetchOrganization {
    fetchOrganization(organizationId: "${organizationId}") {
      organization {
        id
        name
        availableFeatureFlags
        supportUrl
        helpCentreUrl
        enableContactSupport
        enableResourcesAndArticles
        logoutRedirectUrl
        enableInactivityTimeOut
        inactivityTimeoutInMinutes
        ${permissions && permissions.includes('read:custodian_connection') ? `#graphql
          defaultCustodianConnection {
            id
            name
            default
            type
            enableFetchCustodianStatistics
            enableFetchCustodianSnapshotHistory
            enableFetchCustodianTransactions
            enableFetchCustodianSuitability
            enableFetchCustodianAffiliates
            enableFetchCustodianUpcomingTransactions
            enableFetchCustodianStatements
            enableFetchCustodianCustomFields
            enableFetchCustodianProjectedIncome
            enableFetchCustodianCustomFields
            organization { id }
            accountTypeSettings { type baseCurrency availableCurrencies isMultiCurrencyEnabled }
          }
        ` : ''}
        themeTokens
        useParentIntegrations
        allowPostOptimizationEditing
        requireSubTradeRequestApproval
        allowViewSubTradeRequestPairs
        allowPendingTransactions
        minRecurringDepositCents
        profileReviewTimeInMonths
        theme { sideBarColor logo }
        applicableLocalization { countries languages defaultLanguage defaultCurrency dateFormat }
        jurisdictions { all only }
        reviewTransactions
        faviconLink
        browserTabTitle
        externalProvider {
          id
          idVerificationProvider { templateId vendor }
          bankingConnectorProvider { clientIframeUrl vendor }
        }
        displayMenuBar
        allowLogout
        displayCurrency
        allowPortfolioPerGoal
        clientHostedLoginURL
        clientHostedLoginRedirectUri
        minDepositCents
        minInitialDepositCents
        productShelfCurrencies
        errorPageBackgroundColor
        errorPageDescription { en fr }
        errorPageMessage { en fr }
        errorPageButtonText { en fr }
        errorPageBackgroundImage
        errorPageShowNavbar
      }
    }
  }
`;

export const CREATE_ENTITY = gql`
  mutation createEntity($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
        affiliateOnly
        type
        entityName
        firstName
        lastName
        primaryEmail
        households { id organization { id } }
      }
    }
  }
`;

const FETCH_INTEGRATIONS = gql`
  query fetchIntegrations($input: FetchIntegrationsInput!) {
    fetchIntegrations(input: $input) {
      totalCount
      integrations { id configuration provider type }
    }
  }
`;

/* fallback defaults when no localization is set */
const LOCALIZATION_DEFAULTS: BareLocalization = {
  name: 'defaults',
  countries: [CountryCodes.CA],
  languages: ['en', 'fr'],
  defaultCurrency: CurrencyCodes.CAD,
  defaultLanguage: 'en',
  dateFormat: 'YYYY-MM-DD',
};

const ACTIVE_ORG_SKELETON = {
  applicableLocalization: LOCALIZATION_DEFAULTS,
  jurisdictions: { all: true },
};

export const UserContext = createContext<UserContextType>({
  userContext: {},
  setUserContext: () => { },
  userId: undefined,
  setUserId: () => { },
  activeOrganization: ACTIVE_ORG_SKELETON,
  setActiveOrganizationId: () => { },
  activeEntity: {},
  setActiveEntity: () => { },
  activeHousehold: undefined,
  setActiveHousehold: () => { },
  organizationIds: [],
  activeLanguage: undefined,
  setActiveLanguage: () => { },
  setOrganizationIds: () => { },
  activeCurrency: '',
  setActiveCurrency: () => { },
  availableCurrencies: [],
  setAvailableCurrencies: () => { },
  loading: true,
  integrations: [],
  custodianConnection: { accountTypeSettings: [] },
  baseCurrency: '',
  displayMenuBar: false,
  closed: false,
  setClosed: () => { },
  refetch: () => { },
});

export const UserContextConsumer = UserContext.Consumer;

function restoreOrgDataFromLocalStorage() {
  try {
    return JSON.parse(localStorage.getItem('activeOrganizationData') || '{}');
  } catch {
    return {};
  }
}

const savedOrganizationId = localStorage.getItem('activeOrganizationId') || undefined;
const savedOrganization = restoreOrgDataFromLocalStorage();

const UserContextProvider = ({ children }: { children: any }) => {
  const { setToastTheme } = useGlobalToast();
  const {
    isEmbedded, permissions, appLogout, setSettings,
  } = useAuthContext();

  const [userContext, setUserContext] = useState<UserContextProps>({});
  const [userId, setUserId] = useState<string | undefined>();
  const [closed, setClosed] = useState(window.localStorage.getItem('closed') === 'true');
  const [loading, setLoading] = useState(true);
  const localStorageFavIcon = window.localStorage.getItem('faviconLink') || '';
  const localStorageBrowserTitle = window.localStorage.getItem('browserTabTitle') || '';
  const [faviconLink, setFaviconLink] = useState(localStorageFavIcon);
  const [browserTabTitle, setBrowserTabTitle] = useState(localStorageBrowserTitle);
  const [displayMenuBar, setDisplayMenuBar] = useState(!isEmbedded);

  const [activeHousehold, setActiveHousehold] = useState<ClientGroup | undefined>(undefined);
  const [activeOrganizationId, setActiveOrganizationId] = useState<string | undefined>(savedOrganizationId);
  const [activeOrganization, setActiveOrganization] = useState<ActiveOrganizationProps>(savedOrganization);
  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const [custodianConnection, setCustodianConnection] = useState<CustodianConnection>({});
  const [activeEntity, setActiveEntity] = useState<ActiveEntityProps>();
  const [activeLanguage, setActiveLanguage] = useState<Languages>(Languages.ENGLISH);
  const [activeCurrency, setActiveCurrency] = useState<string>(savedOrganization.applicableLocalization?.defaultCurrency ?? 'CAD');
  const [baseCurrency, setBaseCurrency] = useState<string>(savedOrganization.applicableLocalization?.defaultCurrency ?? 'CAD');
  const [availableCurrencies, setAvailableCurrencies] = useState<string[]>([]);
  const [organizationIds, setOrganizationIds] = useState<string[]>([]);

  const { data, refetch } = useQuery(ME(permissions), { onError: () => appLogout });

  const [fetchIntegrations] = useLazyQuery(FETCH_INTEGRATIONS);
  const [createEntity] = useMutation(CREATE_ENTITY);

  const [fetchOrg, { data: organizationData }] = useLazyQuery(FETCH_ORGANIZATION(activeOrganizationId ?? '', permissions), {
    onError: (error) => {
      if (error.graphQLErrors.some((e) => e.extensions?.code === 'NOT_ACCESSIBLE')) {
        window.localStorage.removeItem('activeOrganizationId');
        setActiveOrganizationId(undefined);
      }
    },
  });

  /* user profile (ME) has been loaded */
  useEffect(() => {
    if (data) {
      setLoading(false);
      setUserContext({ ...data.me });
      setActiveLanguage(data.me?.language ?? Languages.ENGLISH);
      setSettings({
        enableInactivityTimeOut: data.me?.organization?.enableInactivityTimeOut,
        inactivityTimeoutInMinutes: data.me?.organization?.inactivityTimeoutInMinutes,
        clientHostedLoginRedirectUri: data.me?.organization?.clientHostedLoginRedirectUri,
        clientHostedLoginURL: data.me?.organization?.clientHostedLoginURL,
        auth0RedirectUri: data.me?.organization?.logoutRedirectUrl,
      });
    }
  }, [data]);

  /* update backend language */
  useEffect(() => {
    setBackendLanguage(activeLanguage);
  }, [activeLanguage]);

  useEffect(() => {
    modifyFavIcon(faviconLink);
    modifyTabTitle(browserTabTitle);
  }, [faviconLink, browserTabTitle]);

  /* user profile (ME) has been loaded AND there's no organization set - use their default */
  useEffect(() => {
    if (data?.me?.organization?.id) {
      if (!activeOrganizationId) setActiveOrganizationId(data.me.organization.id);
    }
    if (data?.me?.entities?.length) {
      if (!activeEntity?.id) {
        const urlPath = window.location.pathname;
        const urlEntity = data.me.entities.find((e: any) => urlPath.includes(e.entity.id || ''))?.entity;
        const entity = urlEntity ?? data.me.entities[0].entity;
        setActiveEntity(entity);

        const householdsWithAccess = filterHouseholdsWithAccess((entity?.households ?? []), entity?.id);
        if (householdsWithAccess.length > 0) setActiveHousehold(householdsWithAccess[0]);
      }
    }
    if (data?.me?.role?.permissions?.includes('read:integrations')) {
      fetchIntegrations({
        variables: { input: { filter: { organizationId: activeOrganizationId } } },
        onCompleted: (res: any) => setIntegrations(res?.fetchIntegrations?.integrations),
      });
    }
  }, [data, activeOrganizationId, setActiveOrganizationId, activeEntity, setActiveEntity, setActiveHousehold]);

  /* Automatically creates Individual entity during sign-up */
  useEffect(() => {
    if (
      data?.me?.entities?.length === 0
      && data?.me?.accessType === OrganizationUserAccessTypes.ENTITY
      && data?.me?.organization?.defaultSignUpRole?.id === data?.me?.role?.id
      && data?.me?.role?.permissions?.includes('write:client_basic')
    ) {
      setLoading(true);
      createEntity({
        variables: {
          input: {
            type: EntityTypes.INDIVIDUAL,
            language: Languages.ENGLISH,
            primaryEmail: data?.me?.email,
            phone: data?.me?.phone ?? undefined,
            firstName: data?.me?.firstName ?? undefined,
            lastName: data?.me?.lastName ?? undefined,
            organizationId: data?.me?.organization?.id,
          },
        },
        onCompleted: async (resp) => {
          if (resp.createUser.user.id) {
            // TODO: add GraphQL subscription to confirm it's ready to redirect user
            await delay(1000); // Add 1s delay so that workflow can be created for the new entity
            setActiveEntity(resp.createUser.user);
            setLoading(false);
          }
        },
      });
    }
  }, [data]);

  /* active organization set or changed - load org details */
  useEffect(() => {
    if (activeOrganizationId !== undefined) {
      fetchOrg();
      window.localStorage.setItem('activeOrganizationId', activeOrganizationId);
    }
  }, [activeOrganizationId, fetchOrg]);

  /* updated closed */
  useEffect(() => {
    window.localStorage.setItem('closed', closed ? 'true' : 'false');
  }, [closed]);

  /* organization profile (FETCH_ORGANIZATION) has been loaded */
  useEffect(() => {
    if (organizationData?.fetchOrganization?.organization) {
      const org: any = omitDeep(organizationData.fetchOrganization.organization, '__typename');
      const baseColor = org.theme?.sideBarColor || '#22394F';

      const graphColors = [
        baseColor,
        adjust(baseColor, -10),
        adjust(baseColor, -20),
        adjust(baseColor, -30),
        adjust(baseColor, -40),
        adjust(baseColor, 10),
        adjust(baseColor, 20),
        adjust(baseColor, 30),
        adjust(baseColor, 40),
        adjust(baseColor, 50),
      ];

      org.theme = { ...org.theme, graphColors };
      if (!org.applicableLocalization) org.applicableLocalization = LOCALIZATION_DEFAULTS;
      if (isEmbedded) setDisplayMenuBar(!!org.displayMenuBar);
      setActiveOrganization(org as ActiveOrganizationProps);
      setToastTheme(org?.themeTokens?.comp);

      availableFeatureFlagsVar(org.availableFeatureFlags);
      setCustodianConnection(org?.defaultCustodianConnection);
      window.localStorage.setItem('activeOrganizationData', JSON.stringify(org));
      setBaseCurrency(org.applicableLocalization.defaultCurrency);
      setActiveCurrency(org.applicableLocalization.defaultCurrency);
      setOrgPublicSettings(org, setFaviconLink, setBrowserTabTitle);
    }
  }, [organizationData, isEmbedded]);

  return (
    <UserContext.Provider value={{
      userContext,
      setUserContext,
      userId,
      setUserId,
      activeOrganization,
      setActiveOrganizationId,
      activeEntity,
      setActiveEntity,
      activeHousehold,
      setActiveHousehold,
      activeLanguage,
      setActiveLanguage,
      organizationIds,
      setOrganizationIds,
      activeCurrency,
      setActiveCurrency,
      availableCurrencies,
      setAvailableCurrencies,
      closed,
      setClosed,
      loading,
      integrations,
      custodianConnection,
      baseCurrency,
      displayMenuBar,
      refetch,
    }}>
      {window.localStorage.getItem('activeOrganizationData') && children}
    </UserContext.Provider>
  );
};

export const usePermissions = () => {
  const userContext = useContext(UserContext);
  const [permissions, setPermissions] = useState<string[]>(userContext.userContext?.role?.permissions || []);
  const [accessiblePages, setAccessiblePages] = useState<string[]>(userContext.userContext?.role?.accessiblePages || []);

  useEffect(() => {
    if (userContext.userContext) {
      setPermissions(userContext.userContext.role?.permissions || []);
      setAccessiblePages(userContext.userContext.role?.accessiblePages || []);
    }
  }, [userContext]);

  return { permissions, accessiblePages };
};

export const useFeatureFlags = () => {
  const { activeOrganization } = useContext(UserContext);

  const flags = activeOrganization?.availableFeatureFlags ?? [];

  const flagEnabled = (flag: string): boolean => flags.includes(flag);

  return { flags, flagEnabled };
};

export default UserContextProvider;

import { CircularProgress } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { StaticPageWidgetConfigurationVisual } from './staticPageWidgetConfiguration.visual';
import { FETCH_STATIC_PAGE_WIDGET_CONFIGURATION, UPDATE_STATIC_PAGE_WIDGET_CONFIGURATION } from './staticPageWidgetConfiguration.queries';
import { Box } from '../../1-primative';

export type StaticPageWidgetType = {
  id: string;
  type: string;
  options: any;
};

export type StaticPageWidgetConfigurationType = {
  id: string;
  name: string;
  widget: StaticPageWidgetType;
};

export const StaticPageWidgetConfiguration = () => {
  const [staticPageWidget, setStaticPageWidget] = useState<StaticPageWidgetConfigurationType | undefined>(undefined);
  const { id } = useParams<{ id: string }>();

  const { loading } = useQuery(FETCH_STATIC_PAGE_WIDGET_CONFIGURATION, {
    variables: { staticPageWidgetConfigurationId: id },
    onCompleted: (data: any) => setStaticPageWidget(data?.fetchStaticPageWidgetConfiguration?.staticPageWidgetConfiguration),
  });

  const [update] = useMutation(UPDATE_STATIC_PAGE_WIDGET_CONFIGURATION, {
    onCompleted: (updatedData: any) => setStaticPageWidget(updatedData?.updateStaticPageWidgetConfiguration?.staticPageWidgetConfiguration),
  });

  if (loading || !staticPageWidget) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' height='100vh'>
        <CircularProgress />
      </Box>
    );
  }

  return <StaticPageWidgetConfigurationVisual staticPageWidget={staticPageWidget} setStaticPageWidget={setStaticPageWidget} update={update} />;
};

import {
  Typography, Box, CircularProgress, Table, TableHead, TableRow, TableCell, TableBody, Grid, Pagination,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { omit } from 'lodash/fp';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import NewNotificationGroup from './newNotificationGroup';
import { getBackendLanguage, translateBackend } from '../../../assets/i18n/config';
import { NotificationChannel, NotificationChannelTypes, NotificationGroup } from '../../../interfaces';
import NotificationChannelsDefinitions from './notificationChannelsDefinitions';
import {
  Button, Dialog, DialogContent, DialogFooter, DialogTitle, Form, IconButton, Typography as OVTypography,
  TranslatableTextField,
} from '../../../ovComponents';

const FETCH_NOTIFICATION_GROUPS = gql`
  query fetchNotificationGroups($input: FetchNotificationGroupsInput!) {
    fetchNotificationGroups(input: $input) {
      notificationGroups {
        id
        name { en fr }
        description { en fr }
        notificationChannels {
          type
          value
          setDefaultState
          allowEditByClient
        }
      }
    }
  }
`;

const UPDATE_NOTIFICATION_GROUP = gql`
  mutation updateNotificationGroup($input: UpdateNotificationGroupInput!) {
    updateNotificationGroup(input: $input) {
      notificationGroup {
        id
      }
    }
  }
`;

const DELETE_NOTIFICATION_GROUP = gql`
  mutation deleteNotificationGroup($input: DeleteNotificationGroupInput!) {
    deleteNotificationGroup(input: $input)
  }
`;

const FETCH_NOTIFICATION_DEFINITIONS = gql`
  query fetchNotificationDefinitions($input: FetchNotificationDefinitionsInput!) {
    fetchNotificationDefinitions(input: $input) {
      totalCount
    }
  }
`;

export const defaultNotificationChannels: NotificationChannel[] = [
  {
    type: NotificationChannelTypes.IN_APP,
    value: true,
    setDefaultState: true,
    allowEditByClient: false,
  },
  {
    type: NotificationChannelTypes.PUSH,
    value: false,
    setDefaultState: false,
    allowEditByClient: false,
  },
  {
    type: NotificationChannelTypes.WEBHOOK,
    value: false,
    setDefaultState: false,
    allowEditByClient: false,
  },
  {
    type: NotificationChannelTypes.EMAIL,
    value: false,
    setDefaultState: false,
    allowEditByClient: false,
  },
];

export const getNotificationChannels = (notificationChannels: NotificationChannel[]): NotificationChannel[] => defaultNotificationChannels.map((nc) => {
  let updatedNotificationChannel: any = notificationChannels.find((elem: NotificationChannel) => elem.type === nc.type) ?? nc;
  updatedNotificationChannel = omit(['__typename'], updatedNotificationChannel);
  updatedNotificationChannel.setDefaultState = !!updatedNotificationChannel.setDefaultState;
  updatedNotificationChannel.allowEditByClient = !!updatedNotificationChannel.allowEditByClient;
  return updatedNotificationChannel;
});

const NotificationGroups = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [page, setPage] = usePageState(1, 'page');
  const [open, setOpen] = useState(false);
  const [notificationGroups, setNotificationGroups] = useState<NotificationGroup[]>([]);
  const [activeNotificationGroup, setActiveNotificationGroup] = useState<NotificationGroup>({
    id: '',
    name: { en: '', fr: '' },
    description: { en: '', fr: '' },
    notificationChannels: defaultNotificationChannels,
  });
  const pageSize = 20;
  const { t } = useTranslation(['orgSettings', 'shared']);

  const {
    loading, error, data, refetch, previousData,
  } = useQuery(FETCH_NOTIFICATION_GROUPS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
        pagination: { perPage: 50 },
      },
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data?.fetchNotificationGroups?.notificationGroups) {
      const newNotificationGroups: NotificationGroup[] = data.fetchNotificationGroups.notificationGroups.map((ng: NotificationGroup) => ({
        ...ng,
        notificationChannels: getNotificationChannels(ng.notificationChannels),
      }));
      setNotificationGroups([...newNotificationGroups]);
    }
  }, [data]);

  const [updateNotificationGroup] = useMutation(UPDATE_NOTIFICATION_GROUP, {
    variables: {
      input: {
        notificationGroupId: activeNotificationGroup?.id,
        name: { en: activeNotificationGroup?.name?.en, fr: activeNotificationGroup?.name?.fr },
        description: (activeNotificationGroup?.description?.en ? { en: activeNotificationGroup?.description?.en, fr: activeNotificationGroup?.description?.fr } : null),
        notificationChannels: activeNotificationGroup.notificationChannels,
      },
    },
  });

  const { loading: definitionsLoading, data: definitionData } = useQuery(FETCH_NOTIFICATION_DEFINITIONS, {
    variables: {
      input: {
        filter: {
          notificationGroupId: activeNotificationGroup?.id,
          organizationId: activeOrganization.id,
        },
      },
    },
    skip: !activeNotificationGroup?.id,
  });

  const [deleteNotificationGroup] = useMutation(DELETE_NOTIFICATION_GROUP, {
    variables: {
      input: {
        notificationGroupId: activeNotificationGroup?.id,
      },
    },
    onCompleted: refetch,
  });

  if (error) (<Typography>Error</Typography>);

  return (
    <Box sx={{ m: '-24px -24px' }}>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 100 }} />
        </Box>
      ) : (
        <>
          <Grid container>
            <Grid item xs={12}>
              { permissions.includes('write:notification_group') && (
                <NewNotificationGroup afterCreate={refetch} defaultOrg={activeOrganization.id} />
              )}
            </Grid>
          </Grid>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant='overline'>{t('notificationGroupTable.name')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody data-testid={'notification-group-table'}>
              {notificationGroups.map((notificationGroup) => (
                <TableRow
                  key={notificationGroup.id}
                  sx={{ cursor: 'pointer', '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={() => {
                    setActiveNotificationGroup(notificationGroup);
                    setOpen(true);
                  }}
                >
                  <TableCell component="th" scope="row">
                    {translateBackend(notificationGroup.name)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchNotificationGroups?.totalCount ?? 0) / pageSize)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />
        </>
      )}
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth='xs' fullWidth data-testid='edit-notification-group-modal'>
        <DialogTitle>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            <OVTypography variant='headingSmall'>{t('editNotificationGroupModal.title')}</OVTypography>
            <IconButton onClick={() => setOpen(false)}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <Form onSubmit={async () => {
          await updateNotificationGroup();
          await refetch();
          setOpen(false);
        }}>
          <DialogContent>
            <Box display='flex' flexDirection='column' gap={2}>
              <TranslatableTextField
                fullWidth
                label={t('notificationGroupModal.name')}
                value={activeNotificationGroup.name}
                onChange={(value) => setActiveNotificationGroup({ ...activeNotificationGroup, name: value })}
                fallbackLanguage={getBackendLanguage()}
                testId='notification-group-name'
              />
              <TranslatableTextField
                fullWidth
                label={t('notificationGroupModal.description')}
                value={activeNotificationGroup.description || { en: '', fr: '' }}
                onChange={(value) => setActiveNotificationGroup({ ...activeNotificationGroup, description: value })}
                fallbackLanguage={getBackendLanguage()}
                testId='notification-group-description'
              />
              {activeNotificationGroup.notificationChannels.map((notificationChannel, index) => (
                <NotificationChannelsDefinitions
                  key={notificationChannel.type}
                  index={index}
                  notificationChannel={notificationChannel}
                  notificationObject={activeNotificationGroup}
                  setNotificationObject={setActiveNotificationGroup}
                />
              ))}
            </Box>
          </DialogContent>
          <DialogFooter>
            <Box display='flex' justifyContent='flex-end' gap={1}>
              <Button
                label={t('notificationGroupModal.remove')}
                variant='tonal'
                color='destructive'
                onClick={async () => {
                  await deleteNotificationGroup();
                  setOpen(false);
                }}
                disabled={definitionsLoading || definitionData?.fetchNotificationDefinitions?.totalCount > 0}
                dataTestId='remove-button'
              />
              <Button type='submit' disabled={!activeNotificationGroup?.name?.en} label={t('shared:save')} dataTestId='save-button' />
            </Box>
          </DialogFooter>
        </Form>
      </Dialog>
    </Box>
  );
};

export default NotificationGroups;

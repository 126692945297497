import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/AddRounded';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePermissions } from '../../../../../providers/userContextProvider';
import { usePageState } from '../../../../../util/usePageState';
import {
  BulkImport, BulkImportStates, ImportFile, ImportFileStates,
} from '../../../../../interfaces/bulkImport';
import AddImportFileModal from './addImportFileModal';
import { Typography, Box, Skeleton } from '../../../../1-primative';
import {
  Table, TableHeadCell,
  TableRow, TableCell, TableBody, Pagination,
  Badge, IconButton, Menu, MenuItem,
  CardContent,
  Card,
} from '../../../../2-component';
import { ConfirmationModal } from '../../../../3-pattern/confirmationDialog/confirmationDialog';
import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import { FETCH_BULK_IMPORT, FETCH_QUEUED_FILES } from '../bulkImportFiles';
import { FETCH_IMPORT_FILE, TRANSITION_IMPORT_FILE } from '../../bulkImportFileLineItems/bulkImportFileLineItems';

export const FETCH_IMPORT_FILES = gql`
  query fetchImportFiles($input: FetchImportFilesInput!) {
    fetchImportFiles(input: $input) {
      totalCount
      importFiles {
        id
        type
        state
        order
        failedLineItemsCount
        processedLineItemsCount
        queuedLineItemsCount
        createdAt
        fileDocument { id type name }
      }
    }
  }
`;

const DELETE_IMPORT_FILE = gql`
  mutation deleteImportFile($input: DeleteImportFileInput!){
    deleteImportFile(input: $input) {
      importFile {
        id
      }
    }
  }
`;

const ImportFilesTable = ({ bulkImport }: { bulkImport: BulkImport }) => {
  const { permissions } = usePermissions();
  const { t } = useTranslation(['bulkImport']);
  const { showToast } = useGlobalToast();
  const navigate = useNavigate();
  const [activeImportFile, setActiveImportFile] = useState<ImportFile>({});
  const [addFileModalOpen, setAddFileModalOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 20;
  const {
    loading, error, data, previousData, refetch, startPolling, stopPolling,
  } = useQuery(FETCH_IMPORT_FILES, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filter: {
          bulkImportId: bulkImport.id,
        },
        pagination: {
          sortField: 'order', sortDesc: false, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  const [deleteImportFile, { loading: deleteLoading }] = useMutation(DELETE_IMPORT_FILE, { refetchQueries: [FETCH_QUEUED_FILES] });
  const [transitionImportFile] = useMutation(TRANSITION_IMPORT_FILE, { refetchQueries: [FETCH_IMPORT_FILE, FETCH_IMPORT_FILES, FETCH_BULK_IMPORT] });

  const processFile = () => {
    transitionImportFile({
      variables: {
        input: {
          importFileId: activeImportFile.id,
          transition: 'process',
        },
      },
    });
  };

  const disabledAddFileButton = !bulkImport.state || [
    BulkImportStates.COMPLETED,
    BulkImportStates.CANCELED,
    BulkImportStates.PROCESSING,
  ].includes(bulkImport.state);

  const getStatusColor = (state?: string) => {
    if (state === 'COMPLETED' || state === 'PROCESSED') return 'positive';
    if (state === 'FAILED' || state === 'PARTIALLY_FAILED') return 'negative';
    return 'warning';
  };

  useEffect(() => {
    if (bulkImport.state === BulkImportStates.PROCESSING) {
      startPolling(5000);
    } else {
      stopPolling();
      if (bulkImport.id) {
        refetch();
      }
    }
  }, [bulkImport.state, bulkImport.id, startPolling, stopPolling, refetch]);

  if (error) (<Typography>Error</Typography>);

  return (
    <Card loading={loading}>
      {permissions.includes('write:bulk_import') && (
        <CardContent>
          <Box display='flex' justifyContent='flex-end'>
            <IconButton disabled={disabledAddFileButton} onClick={() => setAddFileModalOpen(true)}>
              <AddIcon />
            </IconButton>
          </Box>
        </CardContent>
      )}
      <Table sx={{ minWidth: 650 }} aria-label="table">
        <TableBody>
          <TableRow>
            <TableHeadCell>{t('fileTable.order')}</TableHeadCell>
            <TableHeadCell>{t('fileTable.name')}</TableHeadCell>
            <TableHeadCell>{t('fileTable.type')}</TableHeadCell>
            <TableHeadCell>{t('fileTable.queuedLineItems')}</TableHeadCell>
            <TableHeadCell>{t('fileTable.failedLineItems')}</TableHeadCell>
            <TableHeadCell>{t('fileTable.processedLineItems')}</TableHeadCell>
            <TableHeadCell>{t('table.state')}</TableHeadCell>
            {permissions.includes('write:bulk_import') && (
              <TableHeadCell right>{t('fileTable.actions')}</TableHeadCell>
            )}
          </TableRow>
          { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
            <TableRow key={i}>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              {permissions.includes('write:bulk_import') && (
                <TableCell><Skeleton width='100%' /></TableCell>
              )}
            </TableRow>
          ))}
          {(data || previousData)?.fetchImportFiles?.importFiles?.map((importFile: ImportFile) => (
            <TableRow
              hover
              key={importFile.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
              onClick={() => navigate(`file/${importFile.id}`)}
            >
              <TableCell>{importFile.order}</TableCell>
              <TableCell>{importFile.fileDocument?.name}</TableCell>
              <TableCell>{t(`fileTypes.${importFile.type}`)}</TableCell>
              <TableCell>{(importFile.queuedLineItemsCount || 0)}</TableCell>
              <TableCell>{(importFile.failedLineItemsCount || 0)}</TableCell>
              <TableCell>{(importFile.processedLineItemsCount || 0)}</TableCell>
              <TableCell><Badge label={t(`bulkImport:states.${importFile.state}`)} color={getStatusColor(importFile.state)}/></TableCell>
              {permissions.includes('write:bulk_import') && (
                <TableCell right>
                  <IconButton size='small'
                    onClick={(e) => {
                      e.stopPropagation();
                      setActiveImportFile(importFile);
                      setAnchorEl(e.currentTarget);
                    }}
                    disabled={disabledAddFileButton || !importFile.state || ![ImportFileStates.QUEUED, ImportFileStates.FAILED, ImportFileStates.PARTIALLY_FAILED].includes(importFile.state)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={Math.ceil(((data || previousData)?.fetchBulkImports?.totalCount ?? 0) / pageSize)}
        page={page}
        onChange={(_e, newPage) => setPage(newPage)}
        sx={{
          p: 1,
          textAlign: 'right',
          '.MuiPagination-ul': {
            justifyContent: 'end',
          },
        }}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem key={1} onClick={() => setDeleteConfirmationOpen(true)}>{t('bulkImport:fileTable.menu.delete')}</MenuItem>
        {activeImportFile?.state && [ImportFileStates.FAILED, ImportFileStates.PARTIALLY_FAILED].includes(activeImportFile.state) && (
          <MenuItem key={2} onClick={() => processFile()}>{t('bulkImport:fileTable.menu.process')}</MenuItem>
        )}
      </Menu>
      <ConfirmationModal
        open={deleteConfirmationOpen}
        onCancel={() => setDeleteConfirmationOpen(false)}
        onConfirm={async () => {
          await deleteImportFile({
            variables: { input: { importFileId: activeImportFile.id } },
          });
          showToast({ severity: 'success', message: t('bulkImport:deleteFileMessage') });
          setDeleteConfirmationOpen(false);
          refetch();
        }}
        loading={deleteLoading}
        title={t('bulkImport:deleteFileConfirmationDialog.title')}
        bodyText={t('bulkImport:deleteFileConfirmationDialog.text')}
      />
      {addFileModalOpen && (
        <AddImportFileModal
          bulkImport={bulkImport}
          handleClose={() => {
            setAddFileModalOpen(false);
            refetch();
          }}
        />
      )}
    </Card>
  );
};

export default ImportFilesTable;

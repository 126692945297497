import {
  createContext, useContext, useEffect, useState, useMemo,
} from 'react';
import { EventsType, useIdleTimer } from 'react-idle-timer';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import { useGlobalToast } from './globalToastProvider';
import InactiveLayoutModal from '../ovComponents/3-pattern/inActivityLayout/inactivityLayout';
import { useAuthContext } from './ovApolloProvider';
import { UserContext } from './userContextProvider';

const SILENT_REFRESH = gql`
  query refreshCustodianAuthToken($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        custodianStatistics { marketValueCents }
      }
    }
  }
`;

const LAST_ACTIVITY_TIME_STAMP = 'lastActivityTimestamp';

const InactivityTimeoutContext = createContext({});

const InactivityTimeoutProvider = ({ children }: { children: any }) => {
  const { appLogout, settings } = useAuthContext();
  const { showToast } = useGlobalToast();
  const { t } = useTranslation(['shared']);
  const { activeEntity } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [refresh] = useLazyQuery(SILENT_REFRESH, { fetchPolicy: 'network-only' });

  const enableInactivityTimeOut = settings?.enableInactivityTimeOut || false;
  const isHostedLogin = settings?.clientHostedLoginRedirectUri && settings?.clientHostedLoginURL;

  const inactivityTimeoutInMilliseconds = useMemo(() => (settings?.inactivityTimeoutInMinutes || 15) * 60000, [settings?.inactivityTimeoutInMinutes]);

  const events: EventsType[] = [
    'mousemove', 'keydown', 'keyup', 'click', 'DOMMouseScroll',
    'mousewheel', 'mousedown', 'touchstart', 'touchmove', 'scroll', 'focus',
  ];

  const silentRefresh = () => {
    if (isHostedLogin && activeEntity?.id) {
      refresh({ variables: { userId: activeEntity?.id } });
    }
    // reloads screen when [silentRefresh] is called
    // window.location.reload();
  };

  const logout = async () => {
    showToast({ severity: 'error', message: t('SessionTimeoutLayoutModal.bodyText') });
    await appLogout();
  };

  const stayLoggedIn = async () => {
    setOpen(false);
    reset();
    silentRefresh();
    localStorage.setItem(LAST_ACTIVITY_TIME_STAMP, new Date().toISOString());
  };

  const { reset, getRemainingTime } = useIdleTimer({
    timeout: inactivityTimeoutInMilliseconds,
    promptBeforeIdle: inactivityTimeoutInMilliseconds / 10,
    events,
    disabled: !enableInactivityTimeOut,
    crossTab: true,
    throttle: inactivityTimeoutInMilliseconds / 4,
    onAction: () => {
      localStorage.setItem(LAST_ACTIVITY_TIME_STAMP, new Date().toISOString());
      silentRefresh();
    },
    onIdle: logout,
    onPrompt: async () => {
      if (getRemainingTime() <= 0) {
        await logout();
        return;
      }
      silentRefresh();
      setOpen(true);
    },
  });

  useEffect(() => {
    const lastActivityTimestamp = localStorage.getItem(LAST_ACTIVITY_TIME_STAMP);
    if (!lastActivityTimestamp) localStorage.setItem(LAST_ACTIVITY_TIME_STAMP, new Date().toISOString());
  }, []);

  useEffect(() => {
    if (!enableInactivityTimeOut) return;

    document.addEventListener('visibilitychange', async () => {
      if (enableInactivityTimeOut && getRemainingTime() <= 0) {
        await logout();
        return;
      }
      silentRefresh();
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!enableInactivityTimeOut) return;

    const lastActivityTimestamp = localStorage.getItem(LAST_ACTIVITY_TIME_STAMP);
    if (!lastActivityTimestamp) return;

    const lastActivityDate = new Date(lastActivityTimestamp);
    const currentTime = new Date();
    const timeDiff = currentTime.getTime() - lastActivityDate.getTime();
    if (timeDiff >= inactivityTimeoutInMilliseconds) {
      logout();
    } else {
      silentRefresh();
    }
    // eslint-disable-next-line
  }, [inactivityTimeoutInMilliseconds]);

  return (
    <InactivityTimeoutContext.Provider value={{}}>
      {children}
      <InactiveLayoutModal
        open={open}
        onLogout={logout}
        stayLoggedIn={stayLoggedIn}
        onSessionTimeOut={logout}
      />
    </InactivityTimeoutContext.Provider>
  );
};

export default InactivityTimeoutProvider;

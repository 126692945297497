import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Box } from '../../../1-primative';
import {
  Button, Form, SettingsSwitch,
} from '../../../2-component';
import { NumberField, SettingsCard } from '../../../3-pattern';
import { UserContext } from '../../../../providers/userContextProvider';
import { useGlobalToast } from '../../../../providers/globalToastProvider';

const FETCH_ORGANIZATION = gql`
  query fetchOrganization($organizationId: String!) {
    fetchOrganization(organizationId: $organizationId) {
      organization {
        id
        name
        excludeAdminFeesFromClients
        requireFeeApproval
        revenueShareBps
        revenueShareTaxes
      }
    }
  }
`;

const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        id
      }
    }
  }
`;

export const FeeSettings = () => {
  const { activeOrganization } = useContext(UserContext);
  const { showToast } = useGlobalToast();
  const { t } = useTranslation(['settings', 'shared']);
  const [organization, setOrganization] = useState<any>(null);

  const { data, loading } = useQuery(FETCH_ORGANIZATION, {
    variables: { organizationId: activeOrganization?.id },
  });

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION, {
    variables: {
      input: {
        organizationId: activeOrganization?.id,
        excludeAdminFeesFromClients: organization?.excludeAdminFeesFromClients,
        requireFeeApproval: organization?.requireFeeApproval,
        revenueShareBps: parseFloat(organization?.revenueShareBps),
        revenueShareTaxes: parseFloat(organization?.revenueShareTaxes),
      },
    },
    onCompleted: async () => {
      showToast({
        message: t('shared:successfullySaved'),
        severity: 'success',
      });
    },
  });

  useEffect(() => {
    if (data?.fetchOrganization?.organization) {
      setOrganization(data?.fetchOrganization?.organization);
    }
  }, [data]);

  return (
    <SettingsCard
      title={t('feeSettings.title')}
      description={t('feeSettings.description')}
    >
      <Form onSubmit={updateOrganization}>
        <Box display='flex' flexDirection='column' gap={2}>
          <SettingsSwitch
            checked={!(organization?.excludeAdminFeesFromClients ?? false)}
            onChange={async (event) => setOrganization({ ...organization, excludeAdminFeesFromClients: !event })}
            label={t('feeSettings.chargeAdminFeesToClient')}
            testId='fee-settings-charge-admin-fees-to-client'
            underline
          />
          <SettingsSwitch
            checked={!(organization?.requireFeeApproval)}
            onChange={async (event) => setOrganization({ ...organization, requireFeeApproval: !event })}
            label={t('feeSettings.requireFeeApproval')}
            testId='fee-settings-require-fee-approval'
          />
          <NumberField
            label={t('feeSettings.revenueShareBps')}
            number={((organization?.revenueShareBps ?? 0)).toString()}
            decimalPlaces={2}
            setNumber={(e: any) => setOrganization({ ...organization, revenueShareBps: e })}
            testId='fee-settings-revenue-share-bps'
          />
          <NumberField
            label={t('feeSettings.revenueShareTaxes')}
            number={((organization?.revenueShareTaxes ?? 0)).toString()}
            decimalPlaces={2}
            setNumber={(e: any) => setOrganization({ ...organization, revenueShareTaxes: e })}
            testId='fee-settings-revenue-share-taxes'
          />
          <Box display='flex' justifyContent='flex-end'>
            <Button type='submit' disabled={loading} label={t('shared:save')} dataTestId='fee-settings-submit-button'/>
          </Box>
        </Box>
      </Form>
    </SettingsCard>
  );
};

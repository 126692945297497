import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isNull, isUndefined, kebabCase } from 'lodash/fp';
import { Close } from '@mui/icons-material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { BankAccountSelectField } from 'ovComponents/3-pattern/bankAccountSelectField/bankAccountSelect';
import {
  Box, Grid, Link, Typography,
} from 'ovComponents/1-primative';
import {
  Button, DateField, Dialog, DialogContent, DialogFooter, DialogTitle, Form, IconButton, MenuItem, SelectField,
} from 'ovComponents/2-component';

import dayjs from 'dayjs';
import { useThemeTokens } from 'providers/themeTokenProvider';
import { AmountField, NumberField } from 'ovComponents/3-pattern';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { BankAccount } from '../../../../../../interfaces';

const getAge = (dob?: Date): string => {
  if (!dob) return '0';
  const today = new Date();
  const birthDate = new Date(dob);
  let myAge = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    myAge -= 1;
  }
  return myAge.toString();
};

export const CREATE_SCHEDULED_INCOME_FUND_TRANSFER = gql`
  mutation createScheduledIncomeFundTransfer($input: CreateScheduledIncomeFundTransferInput!) {
    createScheduledIncomeFundTransfer(input: $input) {
      scheduledIncomeFundTransfer { id }
    }
  }
`;

export const UPDATE_SCHEDULED_INCOME_FUND_TRANSFER = gql`
  mutation updateScheduledIncomeFundTransfer($input: UpdateScheduledIncomeFundTransferInput!) {
    updateScheduledIncomeFundTransfer(input: $input) {
      scheduledIncomeFundTransfer { id }
    }
  }
`;

const FETCH_USER = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        dateOfBirth
        language
        bankAccounts { id name bankAccountNumber }
      }
    }
  }
`;

enum TaxOptionTypes {
  NO_TAX_ON_MINIMUM = 'NO_TAX_ON_MINIMUM',
  SPECIAL_TAX_RATE = 'SPECIAL_TAX_RATE',
  TAX_ON_FULL = 'TAX_ON_FULL',
}

interface PaymentData {
  totalAnnualWithdrawal: string;
  frequency: string;
  amount: number | undefined;
  amountPayableType: string;
  scheduledDate: string;
  age: string;
  specialTaxRate: number | undefined;
  taxOption: TaxOptionTypes;
}

const PAYMENT_INITIAL_DATA = {
  totalAnnualWithdrawal: '',
  frequency: '',
  amount: undefined,
  amountPayableType: '',
  scheduledDate: '',
  age: '',
  taxOption: TaxOptionTypes.NO_TAX_ON_MINIMUM,
  specialTaxRate: 0,
};

export const PaymentInstructionModal = ({
  open, setOpen, action = 'create', userId, refetch, account, fundTitle,
}: {
  open: boolean; setOpen: (open: boolean) => void; action?: 'create' | 'edit'; userId: string; refetch?: any; account: any; fundTitle?: string;
}) => {
  const { t } = useTranslation(['paymentInstruction']);
  const { sys } = useThemeTokens();

  const { data, loading } = useQuery(FETCH_USER, { variables: { userId }, fetchPolicy: 'no-cache' });

  const [focused, setFocused] = useState<string[]>([]);
  const [bankAccount, setBankAccount] = useState<BankAccount | null>({
    id: '',
    name: '',
    bankAccountNumber: '',
    bankAccountNumberDisclosed: '',
    institutionNumber: '',
    transitNumber: '',
  });
  const [paymentData, setPaymentData] = useState<PaymentData>(PAYMENT_INITIAL_DATA);

  const decideWhoseAgeWasSelected = () => {
    if (!account?.scheduledIncomeFundTransfer?.dateOfBirth) return '';
    if (account?.affiliations?.[0] && account.affiliations[0]?.user?.dateOfBirth === account?.scheduledIncomeFundTransfer?.dateOfBirth) {
      return 'MY_SPOUSE';
    }
    return 'MY_AGE';
  };

  useEffect(() => {
    if (!open) return;

    setFocused([]);
    setPaymentData({
      totalAnnualWithdrawal: (!isNull(account?.scheduledIncomeFundTransfer?.annualAmountCents) || account?.scheduledIncomeFundTransfer?.annualAmountCents) ? 'Custom' : 'Minimum',
      frequency: account?.scheduledIncomeFundTransfer?.frequency ?? '',
      amount: account?.scheduledIncomeFundTransfer?.annualAmountCents ?? undefined,
      amountPayableType: account?.scheduledIncomeFundTransfer?.amountPayableType,
      scheduledDate: account?.scheduledIncomeFundTransfer?.scheduledDate ?? '',
      age: decideWhoseAgeWasSelected(),
      taxOption: account?.scheduledIncomeFundTransfer?.taxOption ?? TaxOptionTypes.NO_TAX_ON_MINIMUM,
      specialTaxRate: Number(account?.scheduledIncomeFundTransfer?.specialTaxRate) ?? 0,
    });
    setBankAccount({
      id: account?.scheduledIncomeFundTransfer?.bankAccount?.id ?? '',
      name: account?.scheduledIncomeFundTransfer?.bankAccount?.name ?? '',
      bankAccountNumber: account?.scheduledIncomeFundTransfer?.bankAccount?.bankAccountNumber ?? '',
      bankAccountNumberDisclosed: '',
      institutionNumber: '',
      transitNumber: '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, open]);

  const getDOB = () => {
    if (paymentData.age === 'MY_AGE') return loading ? '' : data.fetchUser.user.dateOfBirth;
    if (account.affiliations.length > 0) return account.affiliations[0].user.dateOfBirth;
    return '';
  };

  const [updateScheduledIncomeFundTransfer] = useMutation(UPDATE_SCHEDULED_INCOME_FUND_TRANSFER, {
    variables: {
      input: {
        scheduledIncomeFundTransferId: account?.scheduledIncomeFundTransfer?.id,
        dateOfBirth: getDOB(),
        subAccountId: account.subAccounts && account.subAccounts.length > 0 ? account.subAccounts[0].id : '',
        bankAccountId: bankAccount?.id,
        frequency: paymentData.frequency,
        scheduledDate: paymentData.scheduledDate,
        annualAmountCents: paymentData.totalAnnualWithdrawal.toLowerCase() === 'minimum' ? 0 : Number(paymentData.amount),
        amountPayableType: paymentData.amountPayableType,
        taxOption: paymentData.taxOption || TaxOptionTypes.NO_TAX_ON_MINIMUM,
        specialTaxRate: (paymentData.taxOption === TaxOptionTypes.SPECIAL_TAX_RATE) ? Number(paymentData.specialTaxRate) : 0,
        transferredAmountCents: 0,
      },
    },
    onCompleted: () => {
      refetch && refetch();
      setOpen(false);
    },
  });

  const [createScheduledIncomeFundTransfer] = useMutation(CREATE_SCHEDULED_INCOME_FUND_TRANSFER, {
    variables: {
      input: {
        dateOfBirth: getDOB(),
        subAccountId: account.subAccounts && account.subAccounts.length > 0 ? account.subAccounts[0].id : '',
        bankAccountId: bankAccount?.id,
        frequency: paymentData.frequency,
        scheduledDate: paymentData.scheduledDate,
        annualAmountCents: paymentData.totalAnnualWithdrawal.toLowerCase() === 'minimum' ? undefined : Number(paymentData.amount),
        amountPayableType: paymentData.totalAnnualWithdrawal.toLowerCase() === 'minimum' ? undefined : paymentData.amountPayableType,
        taxOption: paymentData.taxOption || TaxOptionTypes.NO_TAX_ON_MINIMUM,
        transferredAmountCents: 0,
        specialTaxRate: (paymentData.taxOption === TaxOptionTypes.SPECIAL_TAX_RATE) ? Number(paymentData.specialTaxRate) : 0,
      },
    },
    onCompleted: () => {
      refetch && refetch();
      setOpen(false);
    },
  });

  const submit = () => {
    if (!validate()) return;

    if (action === 'create') {
      createScheduledIncomeFundTransfer();
      return;
    }
    updateScheduledIncomeFundTransfer();
  };

  const validate = () => {
    const invalidFields: string[] = [];

    if (!paymentData.age) invalidFields.push('age');
    if (!paymentData.frequency) invalidFields.push('paymentFrequency');
    if (!paymentData.scheduledDate) invalidFields.push('scheduledDate');
    if (!bankAccount?.id) invalidFields.push('bankAccount');
    if (!paymentData.totalAnnualWithdrawal) invalidFields.push('totalAnnualWithdrawal');

    if (paymentData.totalAnnualWithdrawal.toLowerCase() === 'custom') {
      if (!paymentData.amount) invalidFields.push('amount');
      if (!paymentData.amountPayableType) invalidFields.push('amountPayableType');
    }

    setFocused(invalidFields);
    return invalidFields.length === 0;
  };

  const customLabel = (label: string, hasMarginTop: boolean) => (
    <Typography display='flex' alignItems='center' colorVariant='variant' sx={{ mt: hasMarginTop ? 2 : 0 }}>
      {label}
      <InfoOutlinedIcon style={{ fontSize: '14px', marginLeft: sys.spacing.sm }} />
    </Typography>
  );

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth='sm' fullWidth>
      <DialogTitle sx={{ border: 'none', marginBottom: '0px' }}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='headingLarge'>{t('paymentInstruction:modal.title')}</Typography>
          <IconButton data-testid='icon-btn' onClick={() => setOpen(false)}><Close /></IconButton>
        </Box>

        <Typography component={'span'} variant='bodyMedium' sx={{ fontWeight: '400', mt: 2 }}>
          <span>{t('paymentInstruction:modal.subtitle.1', { fundPrefix: fundTitle })}</span>
          <Link sx={{ mx: 0.5 }}>{t('paymentInstruction:modal.subtitle.2')}</Link>
          <span>{t('paymentInstruction:modal.subtitle.3')}</span>
        </Typography>
      </DialogTitle>

      <Form onSubmit={submit}>
        <DialogContent>
          {customLabel(t('paymentInstruction:modal.ageSelectLabel'), false)}
          <SelectField fullWidth label=''
            testId='select-age'
            value={paymentData.age}
            onChange={(e: any) => setPaymentData({ ...paymentData, age: e.target.value })}
            onBlur={() => setFocused([...focused, 'age'])}
            error={!paymentData.age && focused.includes('age')}
          >
            {(!loading && data?.fetchUser?.user.dateOfBirth) && (
              <MenuItem value='MY_AGE' data-testid='select-age-my-age'>
                {t('paymentInstruction:modal.myAgeSelectOption', { age: getAge(data.fetchUser.user.dateOfBirth) })}
              </MenuItem>
            )}
            {(!loading && account.affiliations.length > 0) && (
              <MenuItem data-testid='select-age-spouse-age' value='SPOUSE_AGE'>
                {t('paymentInstruction:modal.mySpousesAgeSelectOption', { age: getAge(account.affiliations[0].user.dateOfBirth) })}
              </MenuItem>
            )}
          </SelectField>

          {customLabel(t('paymentInstruction:modal.totalAnnualWithdrawalLabel'), true)}
          <SelectField fullWidth label=''
            testId='total-withdrawal-amount'
            value={paymentData.totalAnnualWithdrawal}
            onChange={(e: any) => setPaymentData({ ...paymentData, totalAnnualWithdrawal: e.target.value })}
            onBlur={() => setFocused([...focused, 'totalAnnualWithdrawal'])}
            error={!paymentData.totalAnnualWithdrawal && focused.includes('totalAnnualWithdrawal')}
          >
            <MenuItem data-testid='total-withdrawal-amount-option-minimum' value='Minimum'>
              {t('paymentInstruction:modal.totalAnnualWithdrawalMinimumAmountOption')}
            </MenuItem>
            <MenuItem data-testid='total-withdrawal-amount-option-custom' value='Custom'>
              {t('paymentInstruction:modal.totalAnnualWithdrawalCustomAmountOption')}
            </MenuItem>
          </SelectField>

          <Grid container spacing={2} sx={{ mt: 0.5 }}>
            {paymentData.totalAnnualWithdrawal.toLowerCase() === 'custom' && (
              <Grid item xs={6}>
                <AmountField
                  testId='total-annual-withdrawal'
                  label={t('paymentInstruction:modal.amountLabel')}
                  amount={paymentData.amount ? (paymentData.amount / 100).toString() : ''}
                  setAmount={(e) => setPaymentData({ ...paymentData, amount: Number(e) * 100 })}
                  onBlur={() => setFocused([...focused, 'amount'])}
                  error={!paymentData.amount && focused.includes('amount')}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <SelectField fullWidth
                testId='payment-frequency'
                label={t('paymentInstruction:modal.paymentFrequencyLabel')}
                value={paymentData.frequency}
                onChange={(e: any) => setPaymentData({ ...paymentData, frequency: e.target.value })}
                onBlur={() => setFocused([...focused, 'paymentFrequency'])}
                error={!paymentData.frequency && focused.includes('paymentFrequency')}
              >
                {['ANNUALLY', 'SEMI_ANNUALLY', 'QUARTERLY', 'MONTHLY'].map((value) => (
                  <MenuItem key={value} data-testid={`payment-frequency-option-${kebabCase(value)}`} value={value}>{t(`paymentInstruction:modal.${value}`)}</MenuItem>
                ))}
              </SelectField>
            </Grid>
            {paymentData.totalAnnualWithdrawal.toLowerCase() === 'custom' && (
              <Grid item xs={6}>
                {customLabel(t('paymentInstruction:modal.withdrawalTypeLabel'), false)}
                <SelectField fullWidth label=''
                  testId='withdrawal-type'
                  value={paymentData.amountPayableType}
                  onChange={(e: any) => setPaymentData({ ...paymentData, amountPayableType: e.target.value })}
                  onBlur={() => setFocused([...focused, 'amountPayableType'])}
                  error={!paymentData.amountPayableType && focused.includes('amountPayableType')}
                >
                  <MenuItem data-testid='withdrawal-type-option-net' value='NET'>{t('paymentInstruction:modal.withdrawalTypeNetOption')}</MenuItem>
                  <MenuItem data-testid='withdrawal-type-option-gross' value='GROSS'>{t('paymentInstruction:modal.withdrawalTypeGrossOption')}</MenuItem>
                </SelectField>
              </Grid>
            )}
            <Grid item xs={6}>
              <DateField fullWidth
                dataTestId='start-date'
                onChange={(date: any) => setPaymentData({ ...paymentData, scheduledDate: dayjs(date?.toString()).format('YYYY-MM-DD') })}
                disabled={loading}
                label={t('paymentInstruction:modal.startDateLabel')}
                value={paymentData.scheduledDate}
                onBlur={() => setFocused([...focused, 'scheduledDate'])}
                error={!paymentData.scheduledDate && focused.includes('scheduledDate')}
              />
            </Grid>
          </Grid>

          <Grid sx={{ mt: 2 }}>
            <SelectField
              value={paymentData.taxOption}
              label={t('components:scheduledFundTransferModal.taxOptionType')}
              fullWidth
              onChange={(e: any) => {
                setPaymentData({ ...paymentData, taxOption: e.target.value });
              }}
              data-testid='select-tax-option-type'
            >
              <MenuItem key='NO_TAX_ON_MINIMUM' value='NO_TAX_ON_MINIMUM'>{t('components:scheduledFundTransferModal.taxOptions.NO_TAX_ON_MINIMUM')}</MenuItem>
              <MenuItem key='SPECIAL_TAX_RATE' value='SPECIAL_TAX_RATE'>{t('components:scheduledFundTransferModal.taxOptions.SPECIAL_TAX_RATE')}</MenuItem>
              <MenuItem key='TAX_ON_FULL' value='TAX_ON_FULL'>{t('components:scheduledFundTransferModal.taxOptions.TAX_ON_FULL')}</MenuItem>
            </SelectField>
          </Grid>
          {paymentData.taxOption === TaxOptionTypes.SPECIAL_TAX_RATE && (
            <Grid sx={{ mt: 2 }}>
              <NumberField
                      label={t('components:scheduledFundTransferModal.specialTaxRate')}
                      decimalPlaces={2}
                      number={paymentData.specialTaxRate?.toString() ?? ''}
                      setNumber={(value: any) => {
                        setPaymentData({ ...paymentData, specialTaxRate: value });
                      }}
                      error={((!isUndefined(paymentData.specialTaxRate) && (paymentData.specialTaxRate > 100 || paymentData.specialTaxRate < 0)))}
                      sx={{ mt: 2 }}
                      data-testid='number-special-tax-rate'
              />
            </Grid>
          )}

          <BankAccountSelectField
            data-testid='bank-account-select'
            sx={{ mt: 2 }}
            setBankAccount={setBankAccount}
            bankAccount={bankAccount}
            userId={userId}
            onBlur={() => setFocused([...focused, 'bankAccount'])}
            error={!bankAccount?.id && focused.includes('bankAccount')}
          />
          <Box bgcolor={sys.color.surfaceContainer} borderRadius={sys.borderRadius.md} padding={sys.spacing.xl} marginTop={sys.spacing.xxl}>
            <Typography component='span' variant='bodyMedium' sx={{ fontWeight: '400' }}>
              <p>{t('paymentInstruction:modal.note1')}</p>
              <p>{t('paymentInstruction:modal.note2')}</p>
            </Typography>
          </Box>
        </DialogContent>
        <DialogFooter>
          <Box display='flex' justifyContent='end' p={1}>
            <Button dataTestId='payment-instructions-done-btn' label={action === 'create' ? t('shared:done') : t('client:form.update')} type='submit' variant='tonal' />
          </Box>
        </DialogFooter>
      </Form>
    </Dialog>
  );
};

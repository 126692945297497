import { gql, useMutation, useQuery } from '@apollo/client';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import { usePageState } from '../../../../util/usePageState';
import { FeeTier, FeeTierStates } from '../../../../interfaces';
import EditFeeGrid from './components/editFeeGrid';
import NewFeeGrid from './components/newFeeGrid';
import { Typography, Box, Skeleton } from '../../../1-primative';
import {
  Table, TableHeadCell,
  TableRow, TableCell, TableBody, Pagination,
  TextField, Tooltip, IconButton, Menu, MenuItem, Badge,
  Card, CardContent,
} from '../../../2-component';
import { ConfirmationModal, SettingsTitle } from '../../../3-pattern';
import { UpdateOrganizationSwitch } from '../../../4-module/updateOrganizationSwitch/updateOrganizationSwitch';
import { useGlobalToast } from '../../../../providers/globalToastProvider';
import { DefaultOrgFeeGridBox } from './components/defaultOrgFeeGridBox';

export const FETCH_FEE_GRIDS = gql`
  query fetchFeeTiers($input: FetchFeeTiersInput!) {
    fetchFeeTiers(input: $input) {
      totalCount
      feeTiers {
        id
        state
        name
        default
        chargeForCash
        chargeForCashOnHold
        flatAnnualFeeBps
        grid { minMarketValueCents annualFeeBps annualFeeCents }
        gridScopeType
        blendedGrid
        organization { id name }
      }
    }
  }
`;

const TRANSITION_FEE_GRID = gql`
  mutation transitionFeeTier($input: TransitionFeeTierInput!) {
    transitionFeeTier(input: $input) {
      feeTier {
        id
      }
    }
  }
`;

export const FeeGridsSettings = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { showToast } = useGlobalToast();
  const { t } = useTranslation(['feeAndBilling', 'shared']);
  const [feeGridOpen, setFeeGridOpen] = useState(false);
  const [selectedFeeGrid, setSelectedFeeGrid] = useState<any>(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 15;
  const [searchText, setSearchText] = usePageState('', 'q');

  const {
    loading, data, previousData,
  } = useQuery(FETCH_FEE_GRIDS, {
    skip: !activeOrganization.id,
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          name: searchText,
          states: [FeeTierStates.ACTIVE, FeeTierStates.INACTIVE],
        },
        pagination: {
          sortField: 'name', sortDesc: false, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState<null | HTMLElement>(null);
  const contextMenuOpen = Boolean(contextMenuAnchorEl);

  const openContextMenu = (onElement: HTMLElement, feeTier: FeeTier) => {
    setSelectedFeeGrid(feeTier);
    setContextMenuAnchorEl(onElement);
  };
  const closeContextMenu = () => setContextMenuAnchorEl(null);

  const [transitionFeeTier, { loading: transitionLoading }] = useMutation(TRANSITION_FEE_GRID, {
    refetchQueries: [FETCH_FEE_GRIDS],
  });

  const toggleFeeTierState = async (feeTier: FeeTier) => {
    const response = await transitionFeeTier({
      variables: {
        input: {
          feeTierId: feeTier.id,
          transition: feeTier.state === FeeTierStates.ACTIVE ? 'deactivate' : 'activate',
        },
      },
    });
    if (response?.data) {
      showToast({ severity: 'success', message: t('feeAndBilling:feeGrid.edit.updatedToastMessage') });
    }
  };

  const deleteFeeTier = async (feeTier: FeeTier) => {
    const response = await transitionFeeTier({
      variables: {
        input: {
          feeTierId: feeTier.id,
          transition: 'archive',
        },
      },
    });
    if (response?.data) {
      showToast({ severity: 'success', message: t('feeAndBilling:feeGrid.edit.deletedToastMessage') });
    }
    setDeleteConfirmationOpen(false);
  };

  const canWrite = permissions.includes('write:fee_tier') && permissions.includes('transition:fee_tier');
  const isInheirited = (feeTier: FeeTier) => feeTier.organization?.id !== activeOrganization.id;

  return (
    <>
      <SettingsTitle title={t('settings:feeGrids.title')} description={t('settings:feeGrids.description')} />
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <DefaultOrgFeeGridBox />
        <Card loading={loading}>
          <CardContent>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Box display={'flex'} alignItems={'center'} gap={2}>
                <TextField
                  label=''
                  value={searchText}
                  onChange={(event: any) => {
                    setSearchText(event.target.value);
                    setPage(1);
                  }}
                  leadingIcon='search'
                />
                {permissions.includes('write:advanced_organization_settings') ? (
                  <UpdateOrganizationSwitch
                    field='useParentFeeTiers'
                    label={t('settings:feeGrids.useParentFeeGrids')}
                    organizationId={activeOrganization.id}
                    refetchQueries={[FETCH_FEE_GRIDS]}
                  />
                ) : <Box/>}
              </Box>
              {canWrite && (
                <NewFeeGrid />
              )}
            </Box>
          </CardContent>
          <Table sx={{ minWidth: 650 }} aria-label="table">
            <TableBody>
              <TableRow>
                <TableHeadCell>{t('feeAndBilling:feeGrid.table.name')}</TableHeadCell>
                <TableHeadCell>{t('feeAndBilling:feeGrid.table.type')}</TableHeadCell>
                <TableHeadCell>{t('feeAndBilling:feeGrid.table.state')}</TableHeadCell>
                <TableHeadCell>{t('feeAndBilling:feeGrid.table.organization')}</TableHeadCell>
                {canWrite && (<TableHeadCell></TableHeadCell>)}
              </TableRow>
              { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
                <TableRow key={i}>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                </TableRow>
              ))}
              {(data || previousData)?.fetchFeeTiers?.feeTiers?.map((feeTier: FeeTier) => (
                <TableRow
                  hover
                  onClick={(e) => {
                    setSelectedFeeGrid(feeTier);
                    setFeeGridOpen(true);
                  }}
                  key={feeTier.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
                >
                  <TableCell><Typography>{feeTier.name}</Typography></TableCell>
                  <TableCell>
                    <Typography>
                      {feeTier.flatAnnualFeeBps
                        ? t('feeAndBilling:feeGrid.table.flat')
                        : (feeTier.blendedGrid ? t('feeAndBilling:feeGrid.table.blendedGrid') : t('feeAndBilling:feeGrid.table.volumeGrid'))
                      }
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Badge
                      label={feeTier.state === FeeTierStates.ACTIVE ? t('feeAndBilling:feeGrid.table.active') : t('feeAndBilling:feeGrid.table.inactive')}
                      color={feeTier.state === FeeTierStates.ACTIVE ? 'positive' : 'negative'}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography>{feeTier.organization?.name}</Typography>
                  </TableCell>
                  {canWrite && (
                    <TableCell align="right">
                      {!isInheirited(feeTier) ? (
                        <IconButton
                          size='small'
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.preventDefault();
                            event.stopPropagation();
                            openContextMenu(event.currentTarget, feeTier);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      ) : (
                        <Tooltip title={t('shared:inheritedTooltip', { model: 'Fee Grid', organization: feeTier.organization?.name })}>
                          <Badge label={t('shared:inheritedFlag')} />
                        </Tooltip>)}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchFeeTiers?.totalCount ?? 0) / pageSize)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />
          {selectedFeeGrid && (
            <EditFeeGrid
              feeTierToUpdate={selectedFeeGrid}
              open={feeGridOpen}
              handleClose={() => {
                setFeeGridOpen(false);
                setSelectedFeeGrid(null);
              }}
              canWrite={canWrite}
              isInheirited={isInheirited(selectedFeeGrid)}
              organizationId={activeOrganization?.id}
            />
          )}
        </Card>
        {selectedFeeGrid && canWrite && (
          <>
            <Menu
              anchorEl={contextMenuAnchorEl}
              open={contextMenuOpen}
              onClose={closeContextMenu}
              onClick={closeContextMenu}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              sx = {{ cursor: 'pointer' }}
            >
              <MenuItem onClick={() => toggleFeeTierState(selectedFeeGrid)}>{selectedFeeGrid.state === FeeTierStates.ACTIVE ? t('shared:deactivate') : t('shared:activate')}</MenuItem>
              <MenuItem onClick={() => setDeleteConfirmationOpen(true)}>{t('shared:delete')}</MenuItem>
            </Menu>
            <ConfirmationModal
              open={deleteConfirmationOpen}
              loading={transitionLoading}
              title={t('feeAndBilling:feeGrid.delete.confirmationTitle')}
              bodyText={t('feeAndBilling:feeGrid.delete.confirmationMessage')}
              onConfirm={() => deleteFeeTier(selectedFeeGrid)}
              onCancel={() => setDeleteConfirmationOpen(false)}
            />
          </>
        )}
      </Box>
    </>
  );
};

import { useContext, useState } from 'react';
import { omit } from 'lodash/fp';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  DowntimeSchedule, DowntimeSchedulePlatform, DowntimeScheduleState, DowntimeScheduleTransitionStates,
} from '../../../interfaces/downtimeSchedule';
import { UserContext } from '../../../providers/userContextProvider';
import {
  Button, DateField, Form, MenuItem, SelectField, Switch, TextField, TranslatableTextField,
} from '../../2-component';
import { Box, Typography } from '../../1-primative';
import { TranslatedString } from '../../../interfaces';
import { getBackendLanguage } from '../../../assets/i18n/config';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { useTextFieldTokens } from '../../2-component/textField/textField.tokens';
import { MulitRoleSelect } from '../../3-pattern/roleSelect/roleSelect';

const CREATE_DOWNTIME_SCHEDULE = gql`
  mutation createDowntimeSchedule($input: CreateDowntimeScheduleInput!) {
    createDowntimeSchedule(input: $input) {
      downtimeSchedule { id }
    }
  }
`;

const UPDATE_DOWNTIME_SCHEDULE = gql`
  mutation updateDowntimeSchedule($input: UpdateDowntimeScheduleInput!) {
    updateDowntimeSchedule(input: $input) {
      downtimeSchedule { id }
    }
  }
`;

const TRANSITION_DOWNTIME_SCHEDULE = gql`
  mutation transitionDowntimeSchedule($input: TransitionDowntimeScheduleInput!) {
    transitionDowntimeSchedule(input: $input) {
      downtimeSchedule { id }
    }
  }
`;

const downtimeSchedulePlatforms = [
  DowntimeSchedulePlatform.WEB,
  DowntimeSchedulePlatform.ANDROID,
  DowntimeSchedulePlatform.IOS,
];

const mapTransitionStates = {
  [DowntimeScheduleState.ACTIVE]: DowntimeScheduleTransitionStates.activate,
  [DowntimeScheduleState.ARCHIVED]: DowntimeScheduleTransitionStates.archive,
  [DowntimeScheduleState.INACTIVE]: DowntimeScheduleTransitionStates.deactivate,
};

const emptyTranslatedString: TranslatedString = {
  en: '',
  fr: '',
};

const DowntimeScheduleForm = ({
  afterSubmit, action, baseDowntimeSchedule = {}, onTransition,
}: {
  afterSubmit: () => void, action: 'create' | 'edit', baseDowntimeSchedule?: Partial<DowntimeSchedule>, onTransition?: () => void,
}) => {
  const [downtimeSchedule, setDowntimeSchedule] = useState({
    ...baseDowntimeSchedule,
    platforms: baseDowntimeSchedule.platforms || [...downtimeSchedulePlatforms],
    translatedDescription: baseDowntimeSchedule.translatedDescription ?? emptyTranslatedString,
    translatedTitle: baseDowntimeSchedule.translatedTitle ?? emptyTranslatedString,
    translatedNote: baseDowntimeSchedule.translatedNote ?? emptyTranslatedString,
  });
  const [focused, setFocused] = useState<string[]>([]);
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation('orgSettings');
  const { comp } = useThemeTokens(useTextFieldTokens());

  const [createDowntimeSchedule] = useMutation(CREATE_DOWNTIME_SCHEDULE, {
    variables: {
      input: {
        name: downtimeSchedule.name,
        translatedNote: downtimeSchedule.translatedNote,
        organizationId: activeOrganization.id,
        platforms: downtimeSchedule.platforms,
        startTime: downtimeSchedule.startTime,
        endTime: downtimeSchedule.endTime,
        excludeRoles: downtimeSchedule.excludeRoles,
        helpLink: downtimeSchedule.helpLink,
        translatedDescription: downtimeSchedule.translatedDescription,
        translatedTitle: downtimeSchedule.translatedTitle,
      },
    },
    onCompleted: afterSubmit,
  });

  const [updateDowntimeSchedule] = useMutation(UPDATE_DOWNTIME_SCHEDULE, {
    variables: {
      input: {
        downtimeScheduleId: downtimeSchedule.id,
        name: downtimeSchedule.name,
        translatedNote: omit(['__typename'], downtimeSchedule.translatedNote),
        platforms: downtimeSchedule.platforms,
        startTime: downtimeSchedule.startTime,
        endTime: downtimeSchedule.endTime,
        excludeRoles: downtimeSchedule.excludeRoles,
        helpLink: downtimeSchedule.helpLink,
        translatedDescription: omit(['__typename'], downtimeSchedule.translatedDescription),
        translatedTitle: omit(['__typename'], downtimeSchedule.translatedTitle),
      },
    },
    onCompleted: afterSubmit,
  });

  const [transitionDowntimeSchedule] = useMutation(TRANSITION_DOWNTIME_SCHEDULE, {
    variables: {
      input: {
        downtimeScheduleId: downtimeSchedule.id,
        transition: mapTransitionStates[downtimeSchedule.state as DowntimeScheduleState],
      },
    },
    onCompleted: () => {
      if (onTransition) onTransition();
    },
  });

  const validate = () => {
    const invalidFieldSet: string[] = [];
    if (!downtimeSchedule.name) invalidFieldSet.push('name');
    if (!downtimeSchedule.startTime) invalidFieldSet.push('startTime');
    if (!downtimeSchedule.excludeRoles || downtimeSchedule.excludeRoles.length === 0) invalidFieldSet.push('excludeRoles');
    if (!downtimeSchedule.translatedTitle?.[getBackendLanguage()]) invalidFieldSet.push('translatedTitle');

    setFocused(invalidFieldSet);
    return invalidFieldSet.length === 0;
  };

  const onSubmit = () => {
    if (!validate()) {
      return;
    }
    if (action === 'create') {
      createDowntimeSchedule();
    } else {
      updateDowntimeSchedule();
      if (downtimeSchedule.state && downtimeSchedule.state !== baseDowntimeSchedule.state) transitionDowntimeSchedule();
    }
  };

  const handlePlatforms = (platform: DowntimeSchedulePlatform, value: boolean) => {
    const newPlatforms = [...(downtimeSchedule.platforms ?? [])];
    if (!newPlatforms.includes(platform) && value) newPlatforms.push(platform);
    if (newPlatforms.includes(platform) && !value) {
      const platformIndex = newPlatforms.findIndex((elem) => elem === platform);
      newPlatforms.splice(platformIndex, 1);
    }

    setDowntimeSchedule({ ...downtimeSchedule, platforms: newPlatforms });
  };

  return (
    <Form onSubmit={onSubmit}>
      <TextField
        fullWidth
        label={t('downtimeScheduleModal.name')}
        testId='downtime-schedule-name'
        value={downtimeSchedule.name}
        onChange={(e: any) => setDowntimeSchedule({ ...downtimeSchedule, name: e.target.value })}
        sx={{ mb: 2 }}
        onBlur={() => setFocused([...focused, 'name'])}
        error={!downtimeSchedule.name && focused.includes('name')}
      />
      <TranslatableTextField
        fullWidth
        label={t('downtimeScheduleModal.downtimeScheduleTitle')}
        data-testid='downtime-schedule-translated-title'
        sx={{ mb: 2 }}
        value={downtimeSchedule.translatedTitle as TranslatedString}
        onChange={(value) => setDowntimeSchedule({ ...downtimeSchedule, translatedTitle: value })}
        onBlur={() => setFocused([...focused, 'translatedTitle'])}
        error={!downtimeSchedule.translatedTitle?.[getBackendLanguage()] && focused.includes('translatedTitle')}
      />
      <TranslatableTextField
        fullWidth
        label={t('downtimeScheduleModal.downtimeScheduleDescription')}
        data-testid='downtime-schedule-translated-description'
        sx={{ mb: 2 }}
        value={downtimeSchedule.translatedDescription as TranslatedString}
        onChange={(value) => setDowntimeSchedule({ ...downtimeSchedule, translatedDescription: value })}
      />
      <TranslatableTextField
        fullWidth
        label={t('downtimeScheduleModal.note')}
        data-testid='downtime-schedule-translated-note'
        sx={{ mb: 2 }}
        value={downtimeSchedule.translatedNote as TranslatedString}
        onChange={(value) => setDowntimeSchedule({ ...downtimeSchedule, translatedNote: value })}
      />
      <Box sx={{ mb: 2 }}>
        <Typography sx={{ mb: 0.5, color: comp.textField.labelColor }} variant="labelSmall">
          {t('downtimeScheduleModal.platforms')}
        </Typography>
        {downtimeSchedulePlatforms.map((platform) => (
          <Switch
            label={t(`downtimeSchedulePlatform.${platform}`)}
            testId={`downtime-schedule-platform-${platform}`}
            checked={!!downtimeSchedule.platforms?.includes(platform)}
            onChange={(e) => handlePlatforms(platform, e)}
            sx={{ mb: 1 }}
            size='small'
          />
        ))}
      </Box>
      <Box sx={{ mb: 2 }}>
        <DateField
          fullWidth
          hideTimeContainer
          testId
          type='DATE_TIME'
          label={t('downtimeScheduleModal.startTime')}
          data-testid='downtime-schedule-start-time'
          value={downtimeSchedule.startTime}
          onChange={(e: any) => setDowntimeSchedule({ ...downtimeSchedule, startTime: e })}
          onBlur={() => setFocused([...focused, 'startTime'])}
          error={!downtimeSchedule.startTime && focused.includes('startTime')}
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <DateField
          fullWidth
          hideTimeContainer
          type='DATE_TIME'
          label={t('downtimeScheduleModal.endTime')}
          data-testid='downtime-schedule-end-time'
          value={downtimeSchedule.endTime}
          onChange={(e: any) => setDowntimeSchedule({ ...downtimeSchedule, endTime: e })}
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <MulitRoleSelect
          label={t('downtimeScheduleModal.excludeRoles')}
          data-testid='downtime-schedule-exclude-roles'
          selectedRoleIds={downtimeSchedule.excludeRoles || []}
          onChange={(value: any[]) => setDowntimeSchedule({ ...downtimeSchedule, excludeRoles: value })}
          onBlur={() => setFocused([...focused, 'excludeRoles'])}
          error={(!downtimeSchedule.excludeRoles || downtimeSchedule.excludeRoles.length === 0) && focused.includes('excludeRoles')}
        />
      </Box>
      {action === 'edit' && (
        <SelectField
          fullWidth
          label={t('downtimeScheduleModal.state')}
          testId='downtime-schedule-state'
          value={downtimeSchedule.state}
          disabled={baseDowntimeSchedule.state === DowntimeScheduleState.ARCHIVED}
          sx={{ mb: 2 }}
          onChange={(e: any) => setDowntimeSchedule({ ...downtimeSchedule, state: e.target.value })}
        >
          {baseDowntimeSchedule.state === DowntimeScheduleState.ARCHIVED ? (
            <MenuItem value={baseDowntimeSchedule.state}>{t('downtimeScheduleState.ARCHIVED')}</MenuItem>
          ) : (
            Object.values(DowntimeScheduleState).map((state, i) => (
              <MenuItem key={i} value={state}>{t(`downtimeScheduleState.${state}`)}</MenuItem>
            ))
          )}
        </SelectField>
      )}
      <Box display='flex' justifyContent='flex-end' mt={2}>
        <Button type='submit' dataTestId={`downtime-schedule-${action}-button`} label={t(`downtimeScheduleModal.${action}`)} />
      </Box>
    </Form>
  );
};

export default DowntimeScheduleForm;

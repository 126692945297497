/* eslint-disable operator-linebreak */
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { translateBackend } from '../../../assets/i18n/config';
import { CustomField } from '../../../interfaces/customField';
import EditCustomField from './components/editCustomField';
import NewCustomField from './components/newCustomField';
import {
  Typography, Box,
  Skeleton,
} from '../../1-primative';
import {
  Badge,
  Card, CardContent,
  Pagination, Table,
  TableBody, TableCell, TableHeadCell,
  TableRow, Tooltip,
} from '../../2-component';
import { usePageState } from '../../../util/usePageState';
import { UpdateOrganizationSwitch } from '../../4-module/updateOrganizationSwitch/updateOrganizationSwitch';

export const FETCH_CUSTOM_FIELDS = gql`
  query fetchCustomFields($input: FetchCustomFieldsInput!) {
    fetchCustomFields(input: $input) {
      totalCount
      customFields {
        id
        format
        key
        objectType
        selectOptions {
          displayText {
            en
            fr
          }
          value
        }
        state
        translatedDescription {
          en
          fr
        }
        type
        translatedName {
          en
          fr
        }
        organization {
          name
          id
        }
        workflowStep
        externalField
        conditionalRules {
          field
          comparison
          value
        }
      }
    }
  }
`;

const CustomFields = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const { t } = useTranslation(['orgSettings', 'shared']);
  const [selectedCustomField, setSelectedCustomField] = useState<CustomField>();
  const [page, setPage] = usePageState(1, 'page');
  const [sortField, setSortField] = usePageState('createdAt', 'sf');
  const [sortDesc, setSortDesc] = usePageState(true, 'sd');
  const [pageSize, setPageSize] = usePageState(15, 'ps');

  const {
    loading,
    error, data, refetch,
    previousData,
  } = useQuery(FETCH_CUSTOM_FIELDS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
        pagination: {
          sortField, sortDesc, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
    fetchPolicy: 'no-cache',
  });

  if (error) <Typography>Error</Typography>;
  const isInheirited = (customField: CustomField) => customField.organization?.id !== activeOrganization.id;

  const onSort = (field: string) => {
    if (sortField === field) {
      setSortDesc(!sortDesc);
    }
    setSortField(field);
  };
  const sortDirection = (field: string) => (sortField === field ? sortDesc ? 'desc' : 'asc' : undefined);

  return (
    <>
      <Card loading={loading}>
        <CardContent>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Box>
              {permissions.includes('write:advanced_organization_settings') && (
                <UpdateOrganizationSwitch
                  field='useParentCustomFields'
                  label={t('settings:customFields.useParentCustomFields')}
                  organizationId={activeOrganization.id}
                  refetchQueries={[FETCH_CUSTOM_FIELDS]}
                />
              )}
            </Box>
            <Box display='flex'>{permissions.includes('write:custom_fields') && <NewCustomField afterCreate={refetch} defaultOrg={activeOrganization.id} />}</Box>
          </Box>
        </CardContent>
        <Box sx={{ overflowX: 'auto' }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableHeadCell isSortable onClick={() => onSort('key')} sortDirection={sortDirection('key')}>
                  {t('customFieldTable.key')}
                </TableHeadCell>
                <TableHeadCell>{t('customFieldTable.fieldLabel')}</TableHeadCell>
                <TableHeadCell>{t('customFieldTable.inputFormat')}</TableHeadCell>
                <TableHeadCell>{t('customFieldTable.assiciateTo')}</TableHeadCell>
                <TableHeadCell></TableHeadCell>
              </TableRow>
              {loading &&
                !previousData &&
                [...Array(10)].map((x: any, i: number) => (
                  <TableRow key={i}>
                    <TableCell>
                      <Skeleton width='100%' />
                    </TableCell>
                    <TableCell>
                      <Skeleton width='100%' />
                    </TableCell>
                    <TableCell>
                      <Skeleton width='100%' />
                    </TableCell>
                    <TableCell>
                      <Skeleton width='100%' />
                    </TableCell>
                    <TableCell>
                      <Skeleton width='100%' />
                    </TableCell>
                  </TableRow>
                ))}
              {data?.fetchCustomFields?.customFields?.map((customField: CustomField) => (
                <TableRow
                  hover
                  key={customField.id}
                  sx={{ cursor: 'pointer', '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={() => {
                    setSelectedCustomField(customField);
                    setUpdateDialogOpen(true);
                  }}
                >
                  <TableCell component='th' scope='row'>
                    {customField.key}
                  </TableCell>
                  <TableCell>{translateBackend(customField.translatedName)}</TableCell>
                  <TableCell>{t(`customFieldModal.formats.${customField.format}`)}</TableCell>
                  <TableCell>{t(`customFieldModal.objectTypes.${customField.objectType}`)}</TableCell>
                  <TableCell>
                    {isInheirited(customField) ? (
                      <Tooltip title={t('shared:inheritedTooltip', { model: 'Custom Field', organization: customField.organization?.name })}>
                        <Badge label={t('shared:inheritedFlag')} />
                      </Tooltip>
                    ) : (
                      customField.organization?.name
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Pagination
          count={Math.ceil(((data || previousData)?.fetchCustomFields?.totalCount ?? 0) / pageSize)}
          page={page}
          perPage={pageSize}
          onChangePerPage={(newPageSize) => setPageSize(newPageSize)}
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />
      </Card>
      {selectedCustomField && (
        <EditCustomField
          open={updateDialogOpen}
          customFieldToUpdate={selectedCustomField}
          afterUpdate={() => {
            setUpdateDialogOpen(false);
            refetch();
          }}
          handleClose={() => setUpdateDialogOpen(false)}
          inherited={isInheirited(selectedCustomField)}
        />
      )}
    </>
  );
};

export default CustomFields;

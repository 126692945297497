import {
  ListItem, Typography, TextField, MenuItem,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationStyles } from 'interfaces';
import PageConfigurationSelect from 'components/inputs/pageConfigurationSelect';
import OrganizationSelect from '../../../components/inputs/organizationSelect';
import PermissionsSelect from '../../../components/inputs/permissionsSelect';
import CreateNewModal from '../../../components/modals/createNewModal';
import TranslatableString from '../../../components/inputs/translatableString';
import AccessiblePagesSelect from '../../../components/inputs/accessiblePagesSelect';
import DashboardsSelect from '../../../components/inputs/dashboardsSelect';
import StaticPageWidgetConfigurationSelect from '../../../components/inputs/staticPageWidgetConfiguration';

type RoleProfileState = {
  translatedName: { en: string };
  translatedDescription: { en: string };
  organizationId: string | undefined;
  template: {
    permissions: string[];
    accessiblePages: any[];
    dashboards: any[];
    nonIndividualPageConfigurationId?: string | null;
    individualPageConfigurationId?: string | null;
    goalPageConfigurationId?: string | null;
    accountPageConfigurationId?: string | null;
    householdPageConfigurationId?: string | null;
    subAccountPageConfigurationId?: string | null;
    individualTableStaticPageWidgetConfigurationId?: string | null;
    nonIndividualTableStaticPageWidgetConfigurationId?: string | null;
    householdTableStaticPageWidgetConfigurationId?: string | null;
    navigationStyle: NavigationStyles;
  }
};

const CREATE_ROLE_PROFILE = gql`
  mutation createRoleProfile($input: CreateRoleProfileInput!) {
    createRoleProfile(input: $input) {
      roleProfile { id }
    }
  }
`;

const DEFAULT_INITIAL_STATE = {
  translatedName: { en: '' },
  translatedDescription: { en: '' },
  organizationId: '',
  template: {
    permissions: [],
    accessiblePages: [],
    dashboards: [],
    nonIndividualPageConfigurationId: null,
    individualPageConfigurationId: null,
    goalPageConfigurationId: null,
    accountPageConfigurationId: null,
    householdPageConfigurationId: null,
    subAccountPageConfigurationId: null,
    individualTableStaticPageWidgetConfigurationId: null,
    nonIndividualTableStaticPageWidgetConfigurationId: null,
    householdTableStaticPageWidgetConfigurationId: null,
    navigationStyle: NavigationStyles.FULL,
  },
};

const NewRoleProfile = ({ afterCreate, defaultOrg }: { afterCreate: () => void, defaultOrg?: string }) => {
  const initialState = { ...DEFAULT_INITIAL_STATE, organizationId: defaultOrg ?? '' };

  const { t } = useTranslation(['orgSettings']);
  const [roleProfile, setRoleProfile] = useState<RoleProfileState>(initialState);
  const [createRoleProfile, { loading }] = useMutation(CREATE_ROLE_PROFILE, { variables: { input: roleProfile }, onCompleted: () => setRoleProfile(initialState) });

  const create = async () => {
    await createRoleProfile();
    afterCreate();
  };

  const disabled = !(roleProfile.translatedName.en && roleProfile.translatedDescription.en && roleProfile.organizationId);

  return (
    <CreateNewModal
      state={roleProfile}
      initialState={initialState}
      loading={loading}
      disabled={disabled}
      title={t('roleProfileModal.title')}
      onSubmit={create}
      sx={{ float: 'right', m: 1 }}
      maxWidth='md'
    >
      <ListItem>
        <TranslatableString label={t('roleProfileModal.name')} value={roleProfile.translatedName} onChange={(value) => setRoleProfile({ ...roleProfile, translatedName: value })} />
      </ListItem>
      <ListItem>
        <TranslatableString label={t('roleProfileModal.description')}value={roleProfile.translatedDescription}onChange={(value) => setRoleProfile({ ...roleProfile, translatedDescription: value })} />
      </ListItem>
      <ListItem>
        <OrganizationSelect label={t('roleProfileModal.organization')} value={roleProfile.organizationId} onChange={(event: any) => {
          setRoleProfile({ ...roleProfile, organizationId: event.target.value as string });
        }} />
      </ListItem>
      <ListItem>
        <AccessiblePagesSelect label={t('roleProfileModal.accessiblePages')} accessiblePages={roleProfile.template.accessiblePages} onChange={(event: any) => {
          setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, accessiblePages: event.target.value } }));
        }} />
      </ListItem>
      <ListItem>
        <DashboardsSelect label={t('roleProfileModal.dashboards')} selectedDashboards={roleProfile.template.dashboards.map((x: any) => x)} onChange={(event: any) => {
          setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, dashboards: event.target.value } }));
        }} />
      </ListItem>

      <ListItem>
        <Typography>{t('roleProfileModal.permissions')}</Typography>
      </ListItem>
      <ListItem>
        <TextField select value={roleProfile.template.navigationStyle ?? NavigationStyles.FULL} label={t('roleTable.navigationStyle')} sx={{ width: '100%' }} onChange={(event: any) => {
          setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, navigationStyle: event.target.value } }));
        }}>
          <MenuItem key='1' value={NavigationStyles.FULL}>{NavigationStyles.FULL}</MenuItem>
          <MenuItem key='2' value={NavigationStyles.SIMPLE}>{NavigationStyles.SIMPLE}</MenuItem>
        </TextField>
      </ListItem>

      <ListItem>
        <PageConfigurationSelect
          filter={{ type: 'NON_INDIVIDUAL' }}
          value={roleProfile?.template.nonIndividualPageConfigurationId ?? ''}
          label={t('roleModal.nonIndividualPageConfiguration')}
          organizationId={roleProfile.organizationId ?? ''}
          onChange={(event: any) => setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, nonIndividualPageConfigurationId: event.target.value } }))}
        />
      </ListItem>
      <ListItem>
        <PageConfigurationSelect
          filter={{ type: 'INDIVIDUAL' }}
          value={roleProfile.template.individualPageConfigurationId ?? ''}
          label={t('roleModal.individualPageConfiguration')}
          organizationId={roleProfile.organizationId ?? ''}
          onChange={(event: any) => setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, individualPageConfigurationId: event.target.value } }))}
        />
      </ListItem>
      <ListItem>
        <PageConfigurationSelect
          filter={{ type: 'GOAL' }}
          value={roleProfile.template.goalPageConfigurationId ?? ''}
          label={t('roleModal.goalPageConfiguration')}
          organizationId={roleProfile.organizationId ?? ''}
          onChange={(event: any) => setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, goalPageConfigurationId: event.target.value } }))}
        />
      </ListItem>
      <ListItem>
        <PageConfigurationSelect
          filter={{ type: 'ACCOUNT' }}
          value={roleProfile.template.accountPageConfigurationId ?? ''}
          label={t('roleModal.accountPageConfiguration')}
          organizationId={roleProfile.organizationId ?? ''}
          onChange={(event: any) => setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, accountPageConfigurationId: event.target.value } }))}
        />
      </ListItem>
      <ListItem>
        <PageConfigurationSelect
          filter={{ type: 'SUB_ACCOUNT' }}
          value={roleProfile.template.subAccountPageConfigurationId ?? ''}
          label={t('roleModal.subAccountPageConfiguration')}
          organizationId={roleProfile.organizationId ?? ''}
          onChange={(event: any) => setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, subAccountPageConfigurationId: event.target.value } }))}
        />
      </ListItem>
      <ListItem>
        <PageConfigurationSelect
          filter={{ type: 'HOUSEHOLD' }}
          value={roleProfile.template.householdPageConfigurationId ?? ''}
          label={t('roleModal.householdPageConfiguration')}
          organizationId={roleProfile.organizationId ?? ''}
          onChange={(event: any) => setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, householdPageConfigurationId: event.target.value } }))}
        />
      </ListItem>

      <ListItem>
        <StaticPageWidgetConfigurationSelect
          filter={{ widgetType: 'INDIVIDUAL_TABLE' }}
          value={roleProfile.template.individualTableStaticPageWidgetConfigurationId ?? ''}
          label={t('roleModal.individualTableStaticPageWidgetConfiguration')}
          organizationId={roleProfile.organizationId ?? ''}
          onChange={(event: any) => {
            setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, individualTableStaticPageWidgetConfigurationId: event.target.value } }));
          }}
        />
      </ListItem>
      <ListItem>
        <StaticPageWidgetConfigurationSelect
          filter={{ widgetType: 'NON_INDIVIDUAL_TABLE' }}
          value={roleProfile.template.nonIndividualTableStaticPageWidgetConfigurationId ?? ''}
          label={t('roleModal.nonIndividualTableStaticPageWidgetConfiguration')}
          organizationId={roleProfile.organizationId ?? ''}
          onChange={(event: any) => {
            setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, nonIndividualTableStaticPageWidgetConfigurationId: event.target.value } }));
          }}
        />
      </ListItem>
      <ListItem>
        <StaticPageWidgetConfigurationSelect
          filter={{ widgetType: 'HOUSEHOLD_TABLE' }}
          value={roleProfile.template.householdTableStaticPageWidgetConfigurationId ?? ''}
          label={t('roleModal.householdTableStaticPageWidgetConfiguration')}
          organizationId={roleProfile.organizationId ?? ''}
          onChange={(event: any) => {
            setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, householdTableStaticPageWidgetConfigurationId: event.target.value } }));
          }}
        />
      </ListItem>

      <ListItem>
        <PermissionsSelect permissions={roleProfile.template.permissions} onChange={(event: any) => {
          let newPermissions = [...roleProfile.template.permissions];

          if (Array.isArray(event.target.value) && event.target.checked) {
            newPermissions.push(...event.target.value);
          } else if (Array.isArray(event.target.value)) {
            event.target.value.forEach((perm: string) => {
              newPermissions = newPermissions.filter((p: string) => p !== perm);
            });
          } else if (event.target.checked) {
            newPermissions.push(event.target.value);
          } else {
            newPermissions = newPermissions.filter((p: string) => p !== event.target.value);
          }
          setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, permissions: newPermissions } }));
        }} />
      </ListItem>
    </CreateNewModal>
  );
};

export default NewRoleProfile;

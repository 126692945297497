import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import { Column, DownloadButton, ExportApiReportFormatters } from '../../3-pattern';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';

export const EXPORT_FINANCIAL_PRODUCTS_HISTORY = ({ hasOrgHistoryPermission }:
{ hasOrgHistoryPermission: boolean }) => `query FetchFinancialProductPriceHistory($input: FinancialProductPriceHistoryInput!) {
  fetchFinancialProductPriceHistory(input: $input) {
    priceHistory {
      date
      financialProductId
      priceCents
      ${hasOrgHistoryPermission ? `orgHistory {
        ticker
        quantity
        totalCents
      }` : ''}
      financialProduct {
        ticker
        name
        exchange
        primaryAssetClass {
          id
          key
          name
          state
        }
        secondaryAssetClass {
          id
          key
          name
          state
        }
        currency
        currentPriceCents
        originalCurrency
        autoUpdatePrices
        taxRank
        substituteFinancialProduct {
          id
          name
          ticker
        }
        schedule {
          id
          name
        }
        originalCurrentPriceCents
      }
    }
  }
}`;

interface DownloadProductShelfProps {
  filter: any;
  selected: string[];
  onDownloadComplete?: () => void;
}

const DownloadProductShelf = ({ filter, selected, onDownloadComplete }: DownloadProductShelfProps) => {
  const { t } = useTranslation(['components']);
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [historyStartDate, setHistoryStartDate] = useState<string | null>(
    dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
  );
  const [historyEndDate, setHistoryEndDate] = useState<string | null>(
    dayjs().format('YYYY-MM-DD'),
  );
  const MAX_DATE_WINDOW = 365;
  const { showToast } = useGlobalToast();

  const resetState = () => {
    setHistoryStartDate(dayjs().subtract(1, 'day').format('YYYY-MM-DD'));
    setHistoryEndDate(dayjs().format('YYYY-MM-DD'));
    onDownloadComplete?.();
  };

  const columns: Column[] = [
    { gqlAlias: 'financialProduct.ticker', header: t('productShelf.exportHeaders.ticker') },
    { gqlAlias: 'financialProduct.name', header: t('productShelf.exportHeaders.name') },
    { gqlAlias: 'financialProduct.exchange', header: t('productShelf.exportHeaders.exchange') },
    { gqlAlias: 'financialProduct.primaryAssetClass.id', header: t('productShelf.exportHeaders.primaryAssetClass') },
    { gqlAlias: 'financialProduct.secondaryAssetClass.id', header: t('productShelf.exportHeaders.secondaryAssetClass') },
    { gqlAlias: 'financialProduct.substituteFinancialProduct.id', header: t('productShelf.exportHeaders.substitute') },
    { gqlAlias: 'financialProduct.taxRank', header: t('productShelf.exportHeaders.taxRank') },
    { gqlAlias: 'financialProduct.schedule.id', header: t('productShelf.exportHeaders.tradeSchedule') },
    { gqlAlias: 'financialProduct.originalCurrency', header: t('productShelf.exportHeaders.originalCurrency') },
    {
      gqlAlias: 'financialProduct.originalCurrentPriceCents',
      header: t('productShelf.exportHeaders.originalPrice'),
      formatter: ExportApiReportFormatters.DIVIDE_BY_100,
    },
    { gqlAlias: 'financialProduct.currency', header: t('productShelf.exportHeaders.currency') },
    {
      gqlAlias: 'financialProduct.currentPriceCents',
      header: t('productShelf.exportHeaders.currentPrice'),
      formatter: ExportApiReportFormatters.DIVIDE_BY_100,
    },
    {
      gqlAlias: 'financialProduct.autoUpdatePrices',
      header: t('productShelf.exportHeaders.autoUpdatePrice'),
    },
    { gqlAlias: 'date', header: t('productShelf.exportHeaders.historyDate') },
    {
      gqlAlias: 'priceCents',
      header: t('productShelf.exportHeaders.priceHistory'),
      formatter: ExportApiReportFormatters.DIVIDE_BY_100,
    },
  ];

  const additionalColumns: Column[] = [];

  if (permissions.includes('read:financial_product_org_history')) {
    additionalColumns.push({ gqlAlias: 'orgHistory.quantity', header: t('productShelf.exportHeaders.holdingsQuantityHistory') });
    additionalColumns.push({
      gqlAlias: 'orgHistory.totalCents',
      header: t('productShelf.exportHeaders.holdingsValueHistory'),
      formatter: ExportApiReportFormatters.DIVIDE_BY_100,
    });
  }

  const handleDateValidationError = (message: string) => {
    showToast({
      severity: 'error',
      message,
    });
  };

  return (
    <DownloadButton
      configurable
      disabled={selected.length === 0}
      gql={EXPORT_FINANCIAL_PRODUCTS_HISTORY({
        hasOrgHistoryPermission: permissions.includes('read:financial_product_org_history'),
      })}
      fileName={`product_shelf_${dayjs().format('YYYY-MM-DD')}`}
      filter={{
        organizationId: activeOrganization.id,
        startDate: historyStartDate,
        endDate: historyEndDate,
        financialProductIds: selected.length > 0 ? selected : undefined,
      }}
      columns={columns}
      additionalColumns={additionalColumns}
      queryPath="fetchFinancialProductPriceHistory"
      datasetPath="priceHistory"
      progressTitle={t('productShelf.exportConfig.progressTitle')}
      dateRangeConfig={{
        fromLabel: t('productShelf.exportConfig.historyFrom'),
        toLabel: t('productShelf.exportConfig.historyTo'),
        fromDate: historyStartDate,
        toDate: historyEndDate,
        onFromDateChange: setHistoryStartDate,
        onToDateChange: setHistoryEndDate,
      }}
      maxDateWindow={MAX_DATE_WINDOW}
      onDateValidationError={handleDateValidationError}
      onExportComplete={resetState}
    />
  );
};

export default DownloadProductShelf;

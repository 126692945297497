import { gql } from '@apollo/client';

export const PREVIEW_FORM_AGREEMENT = gql`
  query previewFormAgreement($input: PreviewFormAgreementInput!) {
    previewFormAgreement(input: $input) { html }
  }
`;

export const FETCH_FORM_AGREEMENT_SIGNED_URL = gql`
  query fetchFormAgreement($input: FetchFormAgreementInput!) {
    fetchFormAgreement(input: $input) { id signedUrl }
  }
`;

export const SIGN_FORM_AGREMETNS = gql`
  mutation signFormAgreements($input: SignFormAgreementsInput!) {
    signFormAgreements(input: $input) {
      formAgreements {
        id
        type
        version
        signedAt
      }
    }
  }
`;

export const FETCH_SUB_ACCOUNT_INCOMPLETE_FORMS = gql`
  query fetchSubAccountIncompleteForms($subAccountId: ObjectID!) {
    fetchSubAccount(subAccountId: $subAccountId) {
      subAccount {
        id
        state
        account { id type state }
        allIncompleteFormAgreements {
          type
          minVersion
          user { id }
          account { id }
          goal { id name }
          subAccount { id }
          scheduledTransfer { id type }
          translatedDisplayName { en fr }
          templateUrl
        }
      }
    }
  }
`;

const ALL_LATEST_FORM_AGREEMENTS = `#graphql
  id
  type
  translatedDisplayName { en fr }
  signedAt
  user { id }
  account { id type custodianAccountNumber }
  subAccount{
    id
    goal { name }
    account { id type custodianAccountNumber }
  }
  goal { id name }
  signedUrl
  scheduledTransfer {
    state
    subAccount { account { id type custodianAccountNumber } }
  }
`;

export const FETCH_ALL_LATEST_FORM_AGREEMENT = gql`
  query fetchFormAgreements($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        allLatestFormAgreements { ${ALL_LATEST_FORM_AGREEMENTS} }
      }
    }
  }
`;

export const FETCH_ALL_ACCOUNT_LATEST_FORM_AGREEMENT = gql`
  query fetchAccountFormAgreements($accountId: ObjectID!) {
    fetchAccount(accountId: $accountId) {
      account {
        latestFormAgreements { ${ALL_LATEST_FORM_AGREEMENTS} }
      }
    }
  }
`;

export const FETCH_ALL_HOUSEHOLD_LATEST_FORM_AGREEMENT = gql`
  query fetchClientGroupFormAgreements($clientGroupId: ObjectID!) {
    fetchClientGroup(clientGroupId: $clientGroupId) {
      clientGroup {
        allLatestFormAgreements { ${ALL_LATEST_FORM_AGREEMENTS} }
      }
    }
  }
`;

export const RENDER_FORM_AGREEMENT = gql`
  query renderFormAgreement($input: RenderFormAgreementInput!) {
    renderFormAgreement(input: $input) { html }
  }
`;

export const FETCH_ACCOUNT_STATEMENTS = gql`
  query fetchAccountStatements($input: FetchAccountStatementsInput!) {
    fetchAccountStatements(input: $input) {
      accountStatements {
        id
        type
        fileName
        translatedFileName { en fr }
        signedUrl
        state
        referenceDate
      }
      totalCount
    }
  }
`;

export const FETCH_ACCOUNT_STATEMENT = gql`
  query fetchAccountStatement($accountStatementId: ObjectID!) {
    fetchAccountStatement(accountStatementId: $accountStatementId) {
      accountStatement {
        id
        signedUrl
      }
    }
  }
`;

export const FETCH_FILE_DOCUMENTS = gql`
  query fetchFileDocuments($input: FetchFileDocumentsInput!) {
    fetchFileDocuments(input: $input) {
      fileDocuments {
        id
        type
        name
        fileName
        uploadedAt
        permissionType
        sharedClient
        sharedWithRoleProfiles {
          id
        }
      }
      totalCount
    }
  }
`;

export const FETCH_FILE_DOCUMENT = gql`
  query fetchFileDocument($fileDocumentId: ObjectID!) {
    fetchFileDocument(fileDocumentId: $fileDocumentId) {
      fileDocument {
        id
        downloadUrl
      }
    }
  }
`;

export const FETCH_USER_ACCOUNTS = gql`
  query fetchAccounts($input: FetchAccountsInput!) {
    fetchAccounts(input: $input) {
      accounts { id type custodianAccountNumber }
    }
  }
`;

export const FETCH_CUSTODIAN_STATEMENTS = gql`
  query fetchCustodianStatements($input: FetchCustodianStatementsInput!) {
    fetchCustodianStatements(input: $input) {
      totalCount
      statements {
        referenceDate
        fileName
        s3key
        signedUrl
        signedUrlFormat
        fetchExternalDocument
        clientId
        documentId
        viewId
        translatedFileName { en }
      }
    }
  }
`;

export const FETCH_CUSTODIAN_EXTERNAL_DOCUMENT = gql`
  query fetchCustodianExternalDocument($input: FetchCustodianExternalDocumentInput!) {
    fetchCustodianExternalDocument(input: $input) { signedUrlFormat document }
  }
`;

export const UPDATE_FILE_DOCUMENT = gql`
  mutation updateFileDocument($input: UpdateFileDocumentInput!) {
    updateFileDocument(input: $input) {
      fileDocument { id }
    }
  }
`;

export const FETCH_ROLE_PROFILES = gql`
query fetchRoleProfiles($input: FetchRoleProfilesInput!) {
  fetchRoleProfiles(input: $input) {
    roleProfiles {
      id
    }
  }
}
`;

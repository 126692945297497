import { gql, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TransactionTypes } from '../../../interfaces/transaction';
import { MenuItem, SelectField } from '../../2-component';

const FETCH_TRANSACTION_TYPES = gql`
  query fetchTransactionTypes($input: FetchTransactionsTypesInput!) {
    fetchTransactionTypes(input: $input){
      types
      totalCount
    }
  }
`;

const FETCH_CUSTODIAN_TRANSACTIONS_QUERY = gql`
  query fetchCustodianTransactionTypes($input: FetchCustodianTransactionTypesInput!) {
    fetchCustodianTransactionTypes(input: $input){
      types
      totalCount
    }
  }
`;

type Filters = {
  userId?: string;
  subAccountId?: string;
  accountId?: string;
  goalId?: string;
  clientGroupId?: string;
};

export const TransactionTypeSelect = ({
  onChange, value, label, size = 'small', onBlur, error, filter, useCustodianData, sx = {}, showRelevantType,
}:{
  onChange: (event: any) => void, value?: string, label?: string, size?: 'small' | 'medium',
  onBlur?: any, error?: any, filter?: Filters, useCustodianData?: boolean, sx?: any; showRelevantType?: boolean;
}) => {
  const { t } = useTranslation(['client', 'components']);
  const [transactionTypes, setTransactionTypes] = useState<any>(showRelevantType ? [] : Object.values(TransactionTypes));
  const { loading } = useQuery(useCustodianData ? FETCH_CUSTODIAN_TRANSACTIONS_QUERY : FETCH_TRANSACTION_TYPES, {
    variables: { input: { filter } },
    skip: !showRelevantType,
    onCompleted: (data) => {
      setTransactionTypes(useCustodianData ? data.fetchCustodianTransactionTypes.types : data.fetchTransactionTypes.types);
    },
    fetchPolicy: 'no-cache',
  });

  return (
    <>
      <SelectField
        label={label ?? t('components:type')}
        testId='transaction-type-select'
        placeholder={t('components:any')}
        onChange={(e: any) => onChange(e.target.value)}
        value={value}
        size={size}
        onBlur={onBlur}
        error={error}
        sx={sx}
        fullWidth
        clearable
      >
        {
          loading ? <MenuItem>...</MenuItem> : (
            transactionTypes.map((x: TransactionTypes) => (
            <MenuItem value={x} key={x}>
              {t(`components:transaction.types.${x}`)}
            </MenuItem>
            ))
          )
        }
      </SelectField>
    </>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery } from '@apollo/client';
import { CurrencyCodes } from '@onevesthq/ov-enums';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, Grid, Skeleton, Typography,
} from '../../../1-primative';
import { Card, CardContent } from '../../../2-component';
import { formatMoneyValue } from '../../../../util';
import { PageObjectType } from '../../../5-page';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';
import { translateBackend } from '../../../../assets/i18n/config';
import { useLocalization } from '../../../../util/useLocalization';
import { UserContext } from '../../../../providers/userContextProvider';
import { currencySymbol } from '../../../../util/currency';
import { Transaction } from '../../../../interfaces/transaction';

const FETCH_LEDGER = gql`
  query fetchTransactions($input: FetchTransactionsInput!) {
    fetchTransactions(input: $input) {
      transactions {
        id
        valueCents
        date
        description
        type
        currency
        quantity
        financialProduct { ticker }
        account { id type }
        subAccount {
          id
          goal { name type }
        }
      }
      totalCount
    }
  }
`;

const FETCH_CUSTODIAN_LEDGER = gql`
  query fetchCustodianTransactions($input: FetchCustodianTransactionsInput!) {
    fetchCustodianTransactions(input: $input) {
      transactions {
        valueCents
        date
        type
        currency
        financialProduct { ticker }
        account { id type }
      }
    }
  }
`;

const baseFilter = (objectType: PageObjectType, objectId: string) => {
  switch (objectType) {
    case PageObjectType.INDIVIDUAL:
      return { userId: objectId };
    case PageObjectType.NON_INDIVIDUAL:
      return { userId: objectId };
    case PageObjectType.HOUSEHOLD:
      return { clientGroupId: objectId };
    case PageObjectType.GOAL:
      return { goalIds: [objectId] };
    case PageObjectType.ACCOUNT:
      return { accountIds: [objectId] };
    case PageObjectType.SUB_ACCOUNT:
      return { subAccountIds: [objectId] };
    default:
      return {};
  }
};

export const RecentTransactions = ({
  objectId, objectType, singleColumn = false, options = {},
}: {
  objectId: string, objectType: PageObjectType, singleColumn?: boolean, options?: any,
}) => {
  const { sys } = useThemeTokens();
  const { localizedDate } = useLocalization();
  const { t } = useTranslation(['components', 'shared', 'accountTypes']);
  const { custodianConnection } = useContext(UserContext);

  const [pagination] = useState<{ offSet: number, perPage: number }>({ offSet: 0, perPage: 3 });
  const [transactions, setTransactions] = useState<Transaction[]>([]);

  const useCustodianData = options.useCustodianStatistics && [PageObjectType.ACCOUNT, PageObjectType.HOUSEHOLD].includes(objectType) && custodianConnection?.enableFetchCustodianTransactions;

  const query = useCustodianData ? FETCH_CUSTODIAN_LEDGER : FETCH_LEDGER;

  const [fetchLedger, { loading }] = useLazyQuery(query, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      const ledgerTransactions = useCustodianData ? data.fetchCustodianTransactions?.transactions : data.fetchTransactions?.transactions;
      setTransactions(ledgerTransactions);
    },
  });

  useEffect(() => {
    const variables = {
      input: {
        filter: { ...baseFilter(objectType, objectId) },
        pagination: {
          ...pagination, sortField: useCustodianData ? 'date' : 'createdAt', sortDesc: false,
        },
      },
    };
    fetchLedger({ variables });
  }, [objectType, objectId, pagination]);

  const addTypeDetails = (transaction: Transaction) => {
    let details = '';
    if (transaction.financialProduct) details = ` - ${transaction.financialProduct.ticker}`;
    return details;
  };

  return (
    <>
      <Typography variant={singleColumn ? 'bodyLarge' : 'headingSmall'} weight='bold' mb={2} mt={singleColumn ? 3 : 0}>
        {options.customTitle ? translateBackend(options.customTitle) : t('components:recentTransactions.title')}
      </Typography>

      {loading ? (
        <Grid container spacing={2} direction={singleColumn ? 'column' : 'row'}>
          <Grid item xs={12} sm={4}><Skeleton variant='rectangular' height='76px' width='100%' /></Grid>
          <Grid item xs={12} sm={4}><Skeleton variant='rectangular' height='76px' width='100%' /></Grid>
          <Grid item xs={12} sm={4}><Skeleton variant='rectangular' height='76px' width='100%' /></Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} direction={singleColumn ? 'column' : 'row'}>
          {transactions.map((transaction, i) => (
            <Grid item xs={12} md={4} key={useCustodianData ? `${transaction.account?.id}-${i}` : transaction.id}>
              <Card>
                <CardContent sx={{ padding: '8px 12px !important' }}>
                  <Typography variant='bodySmall' sx={{ color: sys.color.onSurfaceVariant, mb: 1 }}>{localizedDate(transaction.date)}</Typography>
                  <Typography variant='bodySmall' sx={{ color: sys.color.onSurfaceVariant }}>{t(`components:transaction.types.${transaction.type}`)}{addTypeDetails(transaction)}</Typography>
                  <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Typography variant='bodyMedium' weight='bold'>{t(`accountTypes:${transaction.account?.type}`)}</Typography>
                    <Typography variant='bodyMedium' weight='bold' sx={{ mr: 0.5 }}>
                      {`${formatMoneyValue(transaction.valueCents, currencySymbol[transaction.currency as unknown as CurrencyCodes ?? CurrencyCodes.CAD])} ${transaction.currency}`}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {(transactions.length === 0 && !loading) && (
        <Card sx={{ margin: '15px 0px' }}>
          <CardContent sx={{ padding: '12px !important' }} >
            <Typography weight='bold' variant='bodyLarge' sx={{ textAlign: 'center' }} >{t('components:transaction.noTransactions')}</Typography>
          </CardContent>
        </Card>
      )}
    </>
  );
};

import { gql, useQuery } from '@apollo/client';
import { MenuItem } from '../../2-component/menuItem/menuItem';
import { SelectField } from '../../2-component/selectField/selectField';

export const FETCH_STATIC_PAGE_WIDGET_CONFIGURATIONS = gql`
  query fetchStaticPageWidgetConfigurations($input: FetchStaticPageWidgetConfigurationsInput!) {
    fetchStaticPageWidgetConfigurations(input: $input) {
      staticPageWidgetConfigurations { id name }
    }
  }
`;

export const StaticPageWidgetConfigurationSelect = ({
  onChange, value, label, organizationId, disabled = false, size, filter,
}: {
  onChange: (event: any) => void, value: string, label: string, organizationId: string, disabled?: boolean, size?: 'small' | 'medium' | undefined, filter?: { widgetType: string },
}) => {
  const { loading, data } = useQuery(FETCH_STATIC_PAGE_WIDGET_CONFIGURATIONS, {
    variables: { input: { filter: { organizationId: organizationId || undefined, ...filter }, pagination: { perPage: 50 } } },
  });

  return (
    <SelectField value={data ? value : 'undefined'} label={label} sx={{ width: '100%', height: '100%' }} onChange={onChange} size={size} fullWidth disabled={disabled}>
      <MenuItem key='empty' value={undefined}>-</MenuItem>
      {loading ? <MenuItem>...</MenuItem> : (
        data?.fetchStaticPageWidgetConfigurations.staticPageWidgetConfigurations.map((x: any) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)
      )}
    </SelectField>
  );
};

/* eslint-disable @typescript-eslint/no-shadow */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '../../../1-primative';
import {
  Button, Card, CardContent, MenuItem, SelectField, TextField,
} from '../../../2-component';
import { AccountTypeSelect } from '../../../3-pattern';

export enum ReportComparison {
  TEXT_SEARCH = 'TEXT_SEARCH',
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  INCLUDES = 'INCLUDES',
  NOT_INCLUDES = 'NOT_INCLUDES',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  GREATER_THAN_AGO = 'GREATER_THAN_AGO',
  LESS_THAN_AGO = 'LESS_THAN_AGO',
}

interface Filter {
  field: string,
  comparison: ReportComparison,
  value: string
}

const NewFilter = ({
  availableFilters, setFilters, filters, type,
}: {
  filters: Filter[], setFilters: (filters: any) => void, availableFilters: any[], type: string,
}) => {
  const { t } = useTranslation('customReports');
  const [field, setField] = useState(null);
  const [comparison, setComparison] = useState(null);
  const [value, setValue] = useState(null);
  const [valueArr, setValueArr] = useState<string[]>([]);

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setValueArr(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const currentComparison = () => availableFilters.find((x: Filter) => x.field === field)?.comparisons.find((x: any) => x.comparison === comparison);
  return (
    <Card variant='outlined'>
      <CardContent>
        <SelectField
          value={field}
          label={t('field')}
          onChange={(e: any) => setField(e.target.value)}
          data-testid="field-dropdown"
          fullWidth
        >
          {
            availableFilters.filter((x: any) => x.field !== 'organization').map((x: any) => (
              <MenuItem key={x.field} value={x.field}>{t(`column.${x.field}`)}</MenuItem>
            ))
          }
        </SelectField>
        {
          field && (
            <SelectField
              value={comparison}
              label={t('comparison')}
              sx={{ mt: 1 }}
              onChange={(e: any) => setComparison(e.target.value)}
              data-testid="comparison-dropdown"
              fullWidth
            >
              {
                availableFilters.find((x: Filter) => x.field === field)?.comparisons.map((x: any) => (
                  <MenuItem key={x.comparison} value={x.comparison}>{x.comparison.toLowerCase().replaceAll('_', ' ')}</MenuItem>
                ))
              }
            </SelectField>
          )
        }
        {
          field && comparison && currentComparison() && (
            <>
              {(currentComparison().valueTypes === 'SINGLE_SELECT'
                && field === 'type' && type === 'ACCOUNT'
                && (currentComparison().comparison === 'EQUALS' || currentComparison().comparison === 'NOT_EQUALS'))
                && (
                  <Box sx={{ mt: 1 }}>
                    <AccountTypeSelect
                      size='medium'
                      value={value ?? ''}
                      label={t('value')}
                      onChange={(e: any) => setValue(e)}
                      data-testid="value-dropdown"
                    />
                  </Box>
                )
              }
              {(currentComparison().valueTypes === 'SINGLE_SELECT'
                && (field !== 'type' || type !== 'ACCOUNT')
                && (currentComparison().comparison === 'EQUALS'
                || currentComparison().comparison === 'NOT_EQUALS'))
                && (
                  <SelectField
                    value={value}
                    label={t('value')}
                    sx={{ mt: 1 }}
                    onChange={(e: any) => setValue(e.target.value)}
                    data-testid="value-dropdown"
                    fullWidth
                  >
                    {
                      currentComparison().options.map((x: any) => (
                        <MenuItem key={x} value={x}>{x}</MenuItem>
                      ))
                    }
                  </SelectField>
                )
              }
              {(currentComparison().valueTypes === 'SINGLE_SELECT' && currentComparison().comparison === 'INCLUDES') && (
              <SelectField
                multiple
                value={valueArr}
                label={t('value')}
                sx={{ mt: 1 }}
                onChange={handleChange}
                data-testid="value-dropdown"
                fullWidth
              >
                {
                  currentComparison().options.map((x: any) => (
                    <MenuItem key={x} value={x}>{x}</MenuItem>
                  ))
                }
              </SelectField>)}

              {currentComparison().valueTypes !== 'SINGLE_SELECT' && (
                <TextField
                  type={currentComparison().valueTypes === 'NUMBER' ? 'number' : 'default'}
                  value={value}
                  label={t('value')}
                  sx={{ mt: 1 }}
                  onChange={(e: any) => setValue(e.target.value)}
                  data-testid="value-text"
                  fullWidth
                />
              )
              }
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant='tonal' sx={{ mt: 1 }} onClick={() => {
                  setFilters([...filters, {
                    field,
                    comparison,
                    value: currentComparison().valueTypes === 'DATE' ? new Date(value || '') : (currentComparison().comparison === 'INCLUDES' ? valueArr.join(', ') : value),
                  }]);
                  setField(null);
                  setComparison(null);
                  setValue(null);
                }} label={t('addFilter')} />
              </Box>
            </>
          )
        }
      </CardContent>
    </Card>
  );
};

export default NewFilter;

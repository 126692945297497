import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { Add, Close } from '@mui/icons-material';
import { UserContext } from '../../../../../providers/userContextProvider';
import { usePageState } from '../../../../../util/usePageState';
import { translateBackend } from '../../../../../assets/i18n/config';
import { Typography, Box, Skeleton } from '../../../../1-primative';
import {
  Button, Card, Table, TableHeadCell, TableRow,
  TableCell, TableBody, Pagination,
  Dialog, DialogContent, DialogTitle, IconButton,
  CardContent,
  SettingsTitle,
} from '../../../..';
import { NotificationDefinitionForm } from './notificationDefinitionForm';
import { NotificationDefinition } from '../../../../../interfaces';
import { getNotificationChannels } from './notificationGroups';

const FETCH_NOTIFICATION_DEFINITIONS = gql`
  query fetchNotificationDefinitions($input: FetchNotificationDefinitionsInput!) {
    fetchNotificationDefinitions(input: $input) {
      notificationDefinitions {
        id
        name
        titleTemplate { en fr }
        bodyTemplate { en fr }
        linkTemplate
        linkText { en fr }
        triggerObjectType
        triggerEventType
        roleProfiles { id translatedName { en fr } }
        notificationGroup {
          id
          name { en fr }
        }
        organization {
          name
        }
        notificationChannels {
          type
          value
          setDefaultState
          allowEditByClient
        }
      }
    }
  }
`;

const NotificationDefinitions = () => {
  const { activeOrganization } = useContext(UserContext);
  const [page, setPage] = usePageState(1, 'page');
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState<'edit' | 'create'>('edit');
  const [notificationDefinitions, setNotificationDefinitions] = useState<NotificationDefinition[]>([]);
  const [activeNotificationDefinition, setActiveNotificationDefinition] = useState<Partial<NotificationDefinition>>({});
  const pageSize = 20;
  const { t } = useTranslation(['orgSettings', 'shared', 'settings']);

  const {
    loading, error, data, refetch, previousData,
  } = useQuery(FETCH_NOTIFICATION_DEFINITIONS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
        pagination: { perPage: 50 },
      },
    },
  });

  useEffect(() => {
    if (data?.fetchNotificationDefinitions?.notificationDefinitions) {
      const newNotificationDefinitions: NotificationDefinition[] = data.fetchNotificationDefinitions.notificationDefinitions.map((nd: NotificationDefinition) => ({
        ...nd,
        notificationChannels: getNotificationChannels(nd.notificationChannels),
      }));
      setNotificationDefinitions([...newNotificationDefinitions]);
    }
  }, [data]);

  const onCloseDialog = () => {
    setOpen(false);
    setActiveNotificationDefinition({});
  };

  if (error) (<Typography>Error</Typography>);

  return (
    <>
      <SettingsTitle title={t('settings:notificationDefinitions.title')} description={t('settings:notificationDefinitions.description')} />
      <Card loading={loading}>
        <CardContent>
          <Box sx={{
            display: 'flex', justifyContent: 'end', alignItems: 'center',
          }}>
            <Button onClick={() => {
              setAction('create');
              setOpen(true);
            }} label={t('notificationDefinitionTable.add')} leadingIcon={Add} />
          </Box>
        </CardContent>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody data-testid={'notification-definition-table'}>
            <TableRow>
              <TableHeadCell>{t('notificationDefinitionTable.name')}</TableHeadCell>
              <TableHeadCell>{t('notificationDefinitionTable.object')}</TableHeadCell>
              <TableHeadCell>{t('notificationDefinitionTable.event')}</TableHeadCell>
              <TableHeadCell>{t('notificationDefinitionTable.notificationGroup')}</TableHeadCell>
              <TableHeadCell>{t('notificationDefinitionTable.roleProfiles')}</TableHeadCell>
              <TableHeadCell>{t('notificationDefinitionTable.organization')}</TableHeadCell>
            </TableRow>
            { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
              <TableRow key={i}>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
              </TableRow>
            ))}
            {notificationDefinitions.map((notificationDefinition) => (
              <TableRow
                key={notificationDefinition.id}
                sx={{ cursor: 'pointer', '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={() => {
                  setActiveNotificationDefinition(notificationDefinition);
                  setAction('edit');
                  setOpen(true);
                }}
              >
                <TableCell component="th" scope="row">
                  {notificationDefinition.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {t(`notificationDefinitionTable.triggerObjectType.${notificationDefinition.triggerObjectType}`)}
                </TableCell>
                <TableCell component="th" scope="row">
                  {notificationDefinition.triggerEventType ? t(`notificationDefinitionTable.triggerEventType.${notificationDefinition.triggerEventType}`) : t('notificationDefinitionTable.workflow')}
                </TableCell>
                <TableCell component="th" scope="row">
                  {translateBackend(notificationDefinition.notificationGroup.name)}
                </TableCell>
                <TableCell component="th" scope="row">
                  {notificationDefinition.roleProfiles.map((roleProfile: any) => translateBackend(roleProfile.translatedName)).join(', ')}
                </TableCell>
                <TableCell component="th" scope="row">
                  {notificationDefinition.organization.name}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={Math.ceil(((data || previousData)?.fetchNotificationDefinitions?.totalCount ?? 0) / pageSize)}
          page={page}
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />
        <Dialog open={open} onClose={onCloseDialog} fullWidth maxWidth='sm' data-testid={`notification-definition-${action}-modal`}>
          <DialogTitle>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
              {t('notificationDefinitionModal.title')}
              <IconButton onClick={onCloseDialog}>
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <NotificationDefinitionForm baseNotificationDefinition={activeNotificationDefinition} action={action} afterSubmit={() => {
              setOpen(false);
              setActiveNotificationDefinition({});
              refetch();
            }} onRemove={() => {
              setOpen(false);
              setActiveNotificationDefinition({});
              refetch();
            }}triggerEvent={action === 'create' || activeNotificationDefinition.triggerEventType !== null} />
          </DialogContent>
        </Dialog>
      </Card>
    </>
  );
};

export default NotificationDefinitions;

import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import NewSchedule from './components/newSchedule';
import EditSchedule from './components/editSchedule';
import { usePageState } from '../../../../util/usePageState';
import { useLocalization } from '../../../../util/useLocalization';
import {
  Typography, Box, Skeleton,
} from '../../../1-primative';
import {
  Table, TableHeadCell,
  TableRow, TableCell, TableBody, Pagination,
  Card,
  CardContent,
} from '../../../2-component';
import { SettingsTitle } from '../../../3-pattern';
import { UpdateOrganizationSwitch } from '../../../4-module/updateOrganizationSwitch/updateOrganizationSwitch';

interface Schedule {
  id: string,
  name: string,
  dates: Date[],
  nextDate: Date,
  associatedFinancialProductsCount: number,
  organization: { id: string },
}

export const FETCH_SCHEDULES = gql`
query fetchSchedules($input: FetchSchedulesInput!) {
  fetchSchedules(input: $input) {
    totalCount
    schedules {
      id
      name
      dates
      nextDate
      associatedFinancialProductsCount
      organization { id }
    }
  }
}
`;
export const TradingSchedulesSettings = () => {
  const { t } = useTranslation(['schedule']);
  const [editOpen, setEditOpen] = useState(false);
  const [page, setPage] = usePageState(1, 'page');
  const [activeSchedule, setActiveSchedule] = useState<any>({});
  const { permissions } = usePermissions();
  const { localizedDate } = useLocalization();
  const { activeOrganization } = useContext(UserContext);
  const pageSize = 15;
  const {
    loading, error, data, refetch, previousData,
  } = useQuery(FETCH_SCHEDULES, {
    skip: !activeOrganization.id,
    variables: {
      input: {
        filter: { organizationId: activeOrganization.id },
        pagination: {
          sortField: 'createdAt', sortDesc: false, perPage: pageSize, offset: (page - 1) * pageSize,
        },
      },
    },
  });

  if (error) (<Typography>Error</Typography>);

  return (
    <>
      <SettingsTitle title={t('settings:tradingSchedules.title')} description={t('settings:tradingSchedules.description')} />
      <Card>
        <CardContent>
          <Box justifyContent='space-between' alignItems='center' display='flex'>
            {permissions.includes('write:advanced_organization_settings') ? (
              <UpdateOrganizationSwitch
                field='useParentSchedules'
                label={t('settings:tradingSchedules.useParentSchedules')}
                organizationId={activeOrganization.id}
                refetchQueries={[FETCH_SCHEDULES]}
              />
            ) : <Box/>}
            {permissions.includes('write:schedules') ? (
              <Box>
                <NewSchedule afterCreate={refetch} />
                </Box>
            ) : null}
          </Box>
        </CardContent>
        <Table sx={{ minWidth: 650 }} aria-label="table">
          <TableBody>
            <TableRow>
              <TableHeadCell>{t('schedule:table.name')}</TableHeadCell>
              <TableHeadCell>{t('schedule:table.nextDate')}</TableHeadCell>
              <TableHeadCell>{t('schedule:table.noOfSchedules')}</TableHeadCell>
              <TableHeadCell>{t('schedule:table.noOfAttachedProducts')}</TableHeadCell>
            </TableRow>
            { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
              <TableRow key={i}>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
              </TableRow>
            ))}
            {(data || previousData)?.fetchSchedules?.schedules?.map((scheduleItem: Schedule) => (
              <TableRow
                hover={permissions.includes('write:schedules')}
                key={scheduleItem.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: permissions.includes('write:schedules') ? 'pointer' : 'auto' }}
                onClick={() => {
                  if (permissions.includes('write:schedules')) {
                    setActiveSchedule(scheduleItem);
                    setEditOpen(true);
                  }
                }}
              >
                <TableCell component="th" scope="row">
                  {scheduleItem.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {localizedDate(scheduleItem.nextDate)}
                </TableCell>
                <TableCell component="th" scope="row" align='right'>
                  {(scheduleItem.dates && scheduleItem.dates.length) || 0}
                </TableCell>
                <TableCell component="th" scope="row" align='right'>
                  {scheduleItem.associatedFinancialProductsCount}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <EditSchedule
            afterUpdate={() => {
              setActiveSchedule({});
              setEditOpen(false);
              refetch();
            }}
            scheduleToUpdate={activeSchedule}
            open={editOpen}
            handleClose={() => setEditOpen(false)}
          />
        </Table>
        <Pagination
          count={Math.ceil(((data || previousData)?.fetchSchedules?.totalCount ?? 0) / pageSize)}
          page={page}
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />
      </Card>
    </>
  );
};

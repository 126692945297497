import dayjs from 'dayjs';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { Add, Close } from '@mui/icons-material';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import { usePageState } from '../../../../util/usePageState';
import {
  Box, Button, Card, CardContent, Dialog, DialogContent, DialogTitle, IconButton, Pagination,
  SettingsTitle, Skeleton, Table, TableBody, TableCell, TableHeadCell, TableRow, Typography,
} from '../../..';
import { DowntimeSchedule, DowntimeScheduleState } from '../../../../interfaces/downtimeSchedule';
import DowntimeScheduleForm from '../../../4-module/downtimeScheduleForm/downtimeScheduleForm';

const FETCH_DOWNTIME_SCHEDULES = gql`
  query fetchDowntimeSchedules($input: FetchDowntimeSchedulesInput!) {
    fetchDowntimeSchedules(input: $input) {
      downtimeSchedules {
        id
        name
        translatedNote { en fr }
        platforms
        startTime
        endTime
        excludeRoles
        helpLink
        state
        organization { id name }
        translatedDescription { en fr }
        translatedTitle { en fr }
      }
      totalCount
    }
  }
`;

enum DowntimeScheduleStatus {
  UPCOMING = 'UPCOMING',
  ONGOING = 'ONGOING',
  COMPLETED = 'COMPLETED',
}

export const DowntimeSchedulesSettings = () => {
  const { activeOrganization } = useContext(UserContext);
  const { permissions } = usePermissions();
  const [page, setPage] = usePageState(1, 'page');
  const { t } = useTranslation(['orgSettings', 'shared', 'settings']);

  const [open, setOpen] = useState(false);
  const [action, setAction] = useState<'edit' | 'create'>('edit');
  const [activeDowntimeSchedule, setActiveDowntimeSchedule] = useState<Partial<DowntimeSchedule>>({});
  const perPage = 20;

  const {
    loading, error, data, refetch, previousData,
  } = useQuery(FETCH_DOWNTIME_SCHEDULES, {
    variables: {
      input: {
        filter: {
          organization: activeOrganization.id,
        },
        pagination: {
          offSet: (page - 1) * perPage,
          perPage,
        },
      },
    },
  });

  useEffect(() => function cleanupOnUnmount() { setPage(1); }, [setPage]);

  const onCloseDialog = () => {
    setOpen(false);
    setActiveDowntimeSchedule({});
  };

  const getDowntimeScheduleStatus = (downtimeSchedule: DowntimeSchedule) => {
    if (downtimeSchedule.state !== DowntimeScheduleState.ACTIVE) return undefined;
    const now = dayjs().utc();
    const startTime = dayjs(downtimeSchedule.startTime);
    const endTime = downtimeSchedule.endTime && dayjs(downtimeSchedule.endTime);

    if (now.isBefore(startTime)) return DowntimeScheduleStatus.UPCOMING;
    if (endTime && now.isAfter(endTime)) return DowntimeScheduleStatus.COMPLETED;
    return DowntimeScheduleStatus.ONGOING;
  };

  if (error) (<Typography>Error</Typography>);

  return (
    <>
      <SettingsTitle title={t('settings:downtimeSchedule.title')} description={t('settings:downtimeSchedule.description')} />
      <Card loading={loading}>
        <CardContent>
          <Box sx={{
            display: 'flex', justifyContent: 'end', alignItems: 'center',
          }}>
            {permissions.includes('write:organization_settings') && (
              <Button
                label={t('downtimeScheduleTable.add')}
                leadingIcon={Add}
                onClick={() => {
                  setAction('create');
                  setOpen(true);
                }}
              />
            )}
          </Box>
        </CardContent>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableHeadCell>{t('downtimeScheduleTable.name')}</TableHeadCell>
              <TableHeadCell>{t('downtimeScheduleTable.state')}</TableHeadCell>
              <TableHeadCell>{t('downtimeScheduleTable.status')}</TableHeadCell>
              <TableHeadCell>{t('downtimeScheduleTable.organization')}</TableHeadCell>
            </TableRow>
            { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
              <TableRow key={i}>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
              </TableRow>
            ))}
            {((data || previousData)?.fetchDowntimeSchedules?.downtimeSchedules || []).map((downtimeSchedule: DowntimeSchedule) => (
              <TableRow
                key={downtimeSchedule.id}
                pointer={permissions.includes('write:organization_settings')}
                onClick={permissions.includes('write:organization_settings') ? (() => {
                  setActiveDowntimeSchedule(downtimeSchedule);
                  setAction('edit');
                  setOpen(true);
                }) : undefined}
              >
                <TableCell component="th" scope="row">{downtimeSchedule.name}</TableCell>
                <TableCell component="th" scope="row">{t(`downtimeScheduleState.${downtimeSchedule.state}`)}</TableCell>
                <TableCell component="th" scope="row">
                  {getDowntimeScheduleStatus(downtimeSchedule) ? t(`downtimeScheduleStatus.${getDowntimeScheduleStatus(downtimeSchedule)}`) : '-'}
                </TableCell>
                <TableCell component="th" scope="row">{downtimeSchedule.organization.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={Math.ceil(((data || previousData)?.fetchDowntimeSchedules?.totalCount ?? 0) / perPage)}
          page={page}
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />
        <Dialog open={open} onClose={onCloseDialog} fullWidth maxWidth='sm'>
          <DialogTitle>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
              <Typography variant='headingSmall'>{t('downtimeScheduleModal.title')}</Typography>
              <IconButton onClick={onCloseDialog}><Close /></IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DowntimeScheduleForm
              baseDowntimeSchedule={activeDowntimeSchedule}
              action={action}
              afterSubmit={() => {
                setOpen(false);
                setActiveDowntimeSchedule({});
                refetch();
              }}
              onTransition={() => {
                setOpen(false);
                setActiveDowntimeSchedule({});
                refetch();
              }}
            />
          </DialogContent>
        </Dialog>
      </Card>
    </>
  );
};

import { gql, useQuery } from '@apollo/client';
import { Typography } from '../../1-primative';
import { SelectField, MenuItem } from '../../2-component';

export const FETCH_PAGE_CONFIGURATIONS = gql`
  query fetchPageConfigurations($input: FetchPageConfigurationsInput!) {
    fetchPageConfigurations(input: $input) {
      pageConfigurations {
        id
        name
      }
    }
  }
`;

export const PageConfigurationSelect = ({
  onChange, value, label, organizationId, disabled = false, includeEmpty = false, size, filter,
}: {
  onChange: (event: any) => void, value: string, label: string, organizationId: string, disabled?: boolean, includeEmpty?: boolean, size?: 'small' | 'medium' | undefined, filter?: any,
}) => {
  const { loading, error, data } = useQuery(FETCH_PAGE_CONFIGURATIONS, {
    variables: {
      input: {
        filter: {
          organizationId: organizationId || undefined,
          ...filter,
        },
        pagination: { perPage: 50 },
      },
    },
  });

  if (error) (<Typography>Error</Typography>);

  return (
    <>
      <SelectField
        value={data ? value : 'undefined'}
        label={label}
        sx={{ width: '100%', height: '100%' }}
        onChange={onChange}
        size={size}
        fullWidth
        disabled={disabled}
      >
        <MenuItem key='empty' value={undefined}>-</MenuItem>
        {
          loading ? <MenuItem>...</MenuItem> : (
            data?.fetchPageConfigurations.pageConfigurations.map((x: any) => (
              <MenuItem key={x.id} value={ x.id }>{ x.name }</MenuItem>
            ))
          )
        }
      </SelectField>
    </>
  );
};

import { gql } from '@apollo/client';
import { MAX_SEARCH_RESULTS } from '../../../../../../components/inputs/clientSelect';

export const SEARCH_CLIENTS_FOR_AFFILIATES = gql`
  query searchUsers($query: String!, $organizationId: ObjectID!, $affiliateUsersOnly: Boolean, $restrictToOrganizationId: Boolean) {
    searchUsers(input: {
      filter: { query: $query, organizationId: $organizationId, affiliateUsersOnly: $affiliateUsersOnly, restrictToOrganizationId: $restrictToOrganizationId }
      pagination: { perPage: ${MAX_SEARCH_RESULTS} },
    }) {
      totalCount
      users {
        id
        type
        firstName
        entityName
        middleName
        lastName
        dateOfBirth
        primaryEmail
        affiliateOnly
        taxIdExists
        sinExists
        employmentStatus
        companyType
        jobTitle
        citizenships
        isOfficerOfPublicCompany
        isOwnerOfPublicCompany
        isMemberOfIiroc
        politicallyExposedForeignPerson
        politicallyExposedDomesticPerson
        closeAssociateOfPEP
        headOfInternationalOrganization
        maritalStatus
        foreignTaxInformation {
          id
          foreignTaxCountry
          foreignTaxNumber
        }
        physicalAddress {
          city
          province
          streetName
          country
          postal
          unitNumber
          houseNumber
          neighborhood
        }
      }
    }
  }
`;

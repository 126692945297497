import { gql, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Tooltip } from '../../../../2-component';
import { FormModal } from '../../../../3-pattern';
import { BillingSchedule, BillingScheduleFrequencies } from '../../../../../interfaces';
import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import BillingScheduleForm from './billingScheduleForm';
import { FETCH_ORGANIZATION_DEFAULT_BILLING_SCHEDULE } from './defaultOrgBillingScheduleBox';
import { FETCH_BILLING_SCHEDULES } from '../billingSchedules';

export const UPDATE_BILLING_SCHEDULE = gql`
  mutation updateBillingSchedule($input: UpdateBillingScheduleInput!) {
    updateBillingSchedule(input: $input) {
      billingSchedule {
        id
      }
    }
  }
`;

const emptyState: BillingSchedule = {
  frequency: BillingScheduleFrequencies.MONTHLY,
  default: false,
};

const EditBillingSchedule = ({
  billingScheduleToUpdate, handleClose, canWrite, isInheirited, organizationId,
}: {
  billingScheduleToUpdate: BillingSchedule,
  handleClose: () => void,
  canWrite: boolean,
  isInheirited: boolean,
  organizationId?: string,
}) => {
  const { t } = useTranslation(['feeAndBilling', 'shared']);
  const { showToast } = useGlobalToast();
  const [billingSchedule, setBillingSchedule] = useState(billingScheduleToUpdate);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    setBillingSchedule(billingScheduleToUpdate);
  }, [billingScheduleToUpdate]);

  const [updateBillingSchedule, { loading }] = useMutation(UPDATE_BILLING_SCHEDULE, {
    variables: {
      input: {
        billingScheduleId: billingScheduleToUpdate?.id,
      },
    },
    refetchQueries: [FETCH_ORGANIZATION_DEFAULT_BILLING_SCHEDULE, FETCH_BILLING_SCHEDULES],
  });
  const update = async () => {
    const inputProps = {
      default: billingSchedule.default ?? false,
      frequency: billingSchedule.frequency ?? BillingScheduleFrequencies.MONTHLY,
      nextCalculationDate: billingSchedule.nextCalculationDate,
      nextBillingDate: billingSchedule.nextBillingDate,
    };
    const response = await updateBillingSchedule({
      variables: {
        input: {
          billingScheduleId: billingScheduleToUpdate?.id,
          ...inputProps,
        },
      },
    });
    if (response?.data) {
      showToast({ severity: 'success', message: t('feeAndBilling:billingSchedule.edit.updatedToastMessage') });
      setBillingSchedule(emptyState);
      handleClose();
    }
  };

  return (
    <FormModal loading={loading} disabled={!valid} title={t('feeAndBilling:billingSchedule.edit.title')} formButton={t('shared:update')} onSubmit={update} open handleClose={handleClose} >
      {isInheirited && canWrite && (
        <Tooltip title={t('shared:inheritedTooltip', { model: 'Billing Schedule', organization: billingSchedule.organization?.name })}>
          <Badge label={t('shared:inheritedFlag')} sx={{ mb: 1 }} />
        </Tooltip>
      )}
      <BillingScheduleForm billingSchedule={billingSchedule} setBillingSchedule={setBillingSchedule} readonly={!canWrite || isInheirited} setValid={setValid} organizationId={organizationId} />
    </FormModal>
  );
};

export default EditBillingSchedule;

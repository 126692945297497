/* eslint-disable react-hooks/exhaustive-deps */
import {
  Typography, Box, CircularProgress, Table, TableHead,
  TableRow, TableCell, TableBody, Pagination,
  Chip, Grid, TextField, MenuItem, IconButton,
  Menu, Link as MuiLink, ListItem, Stack,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import { useCorporateContext } from '../../../pages/corporation';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import { useHouseholdContext } from '../../../pages/household';
import { DateTime } from '../../misc/dateTime/dateTime';
import { formatMoneyValue, generateClientNameString } from '../../../util';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import TransferModal from '../../modals/transferModal';
import NewTransfer from './newTransfer';
import { SubAccountSelect } from '../../inputs/subAccountSelect';
import FormModal from '../../modals/formModal';
import { usePageState } from '../../../util/usePageState';
import { useClientContext } from '../../../pages/client';
import { TransferSourcesTypes } from '../../../interfaces/transaction';
import UpdateTransfer from './updateTransfer';
import { STATE_CHANGES_FIELD } from '../../../util/reusableGraphQLFields';

export const FETCH_TRANSFERS_QUERY = (permissions: string[]) => `query fetchTransfers($input: FetchTransfersInput!) {
  fetchTransfers(input: $input) {
    transfers {
      id
      amountCents
      initiatedByRepresentative
      state
      type
      source
      sourceId
      ${STATE_CHANGES_FIELD}
      instantInvest
      dividendFor {
        ticker
      }
      fromSubAccount {
        id
        account {
          type
        }
      }
      failReason
      cancelReason
      cancelReconciledReason
      bankAccount {
        id
        name
        institutionNumber
        bankAccountNumber
        transitNumber
        ${permissions.includes('read:bank_account_disclosed') ? 'transitNumberDisclosed' : ''}
        ${permissions.includes('read:bank_account_disclosed') ? 'bankAccountNumberDisclosed' : ''}
      }
      isWithdrawAll
      ${permissions.includes('read:alert_report_basic') ? 'isSuspicious' : ''}
      ${permissions.includes('write:alert_report_basic') ? `
        alertReport {
          id
          rules {
            type
            translatedDescription {
              en
            }
          }
        }
      ` : ''}
      subAccount {
        id
        account {
          id
          type
          user {
            id
            type
            entityName
            ${permissions.includes('read:client_low_risk_pii') ? 'firstName middleName lastName' : ''}
          }
          organization {
            id
          }
        }
        goal {
          name
        }
        financialProduct {
          translatedName {
            en
          }
        }
        statistics {
          marketValueCents
          moneyAvailableCents
          availableFundsToWithdrawCents
        }
      }
      createdAt
      reconciledAt
      isRecurring
      manualProcess {
        id
        type
        signedUrl
        institution { name }
        transferAccount
        transferAccountNumber
        transferMethod
      }
    }
    totalCount
  }
}`;

export const FETCH_TRANSFERS = (permissions: string[]) => gql`${FETCH_TRANSFERS_QUERY(permissions)}`;

const TRANSITION_TRANSFER = gql`
  mutation transitionTransfer($input: TransitionTransferInput!) {
    transitionTransfer(input: $input) {
      transfer {
        id
      }
    }
  }
`;

enum TransferStates {
  INITIATED = 'INITIATED',
  REVIEWING = 'REVIEWING',
  READY = 'READY',
  REQUESTED = 'REQUESTED',
  PROCESSING = 'PROCESSING',
  RECONCILED = 'RECONCILED',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
}

const transitions = [
  {
    name: 'initiate',
    from: [TransferStates.REVIEWING],
  },
  {
    name: 'ready',
    from: [TransferStates.INITIATED, TransferStates.REVIEWING],
  },
  {
    name: 'request',
    from: [TransferStates.READY, TransferStates.REVIEWING],
  },
  {
    name: 'process',
    from: [TransferStates.REQUESTED, TransferStates.REVIEWING],
  },
  {
    name: 'reconcile',
    from: [TransferStates.REQUESTED, TransferStates.PROCESSING],
  },
  {
    name: 'review',
    from: [TransferStates.INITIATED, TransferStates.READY,
      TransferStates.REQUESTED, TransferStates.PROCESSING],
  },
  {
    name: 'fail',
    from: [TransferStates.REQUESTED, TransferStates.PROCESSING],
  },
  {
    name: 'cancel',
    from: [TransferStates.INITIATED, TransferStates.READY,
      TransferStates.PROCESSING, TransferStates.REVIEWING],
  },
  {
    name: 'cancelReconciled',
    from: [TransferStates.RECONCILED],
  },
];

export const OneoffTransfersTable = ({
  showClient = false,
  showReconciledAt = false,
  filter = {},
  onTransition = () => {},
  onNewTransfer = () => {},
  onFilterChange,
}: {
  showClient?: boolean;
  showReconciledAt?: boolean;
  filter?: any;
  onTransition?: () => void;
  onNewTransfer?: any;
  onFilterChange?: (filter: any) => void;
}) => {
  const { t } = useTranslation(['components']);
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { showToast } = useGlobalToast();
  const clientContext = useClientContext();
  const householdContext = useHouseholdContext();
  const corporateContext = useCorporateContext();
  const [page, setPage] = usePageState(1, 'tfr/page');
  const [source, setSource] = usePageState('any', 'tfr/source');
  const [type, setType] = usePageState('any', 'tfr/type');
  const [state, setState] = usePageState('any', 'tfr/state');
  const [subAccountId, setSubAccountId] = usePageState(filter.subAccountId ?? 'any', 'tfr/sa');
  const [transitionName, setTransitionName] = useState<string>('');
  const [transitionReason, setTransitionReason] = useState<string>('');
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [activeTransfer, setActiveTransfer] = useState<any>({});
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const pageSize = 15;

  const [transitionTransfer] = useMutation(TRANSITION_TRANSFER, {
    onError: (error: any) => {
      showToast({ message: error?.graphQLErrors[0]?.extensions?.response?.body?.errors[0]?.message || '', severity: 'error' });
    },
  });
  const generateSourceFilter = (filteredSource: string): any => {
    if (filteredSource === 'any') return { source: undefined };
    if (filteredSource === 'USER') {
      return {
        source: 'USER',
        initiatedByRepresentative: false,
      };
    }
    if (filteredSource === 'REPRESENTATIVE') {
      return {
        source: 'USER',
        initiatedByRepresentative: true,
      };
    }
    return { source: filteredSource };
  };

  const organizationId = (filter?.clientGroupId || filter?.userIds)
    ? (corporateContext?.orgSettings?.id ?? householdContext?.orgSettings?.id)
    : (clientContext?.orgSettings?.id ?? activeOrganization.id);

  const queryFilter = {
    ...generateSourceFilter(source),
    type: type === 'any' ? undefined : type,
    state: state === 'any' ? undefined : state,
    subAccountId: subAccountId === 'any' ? undefined : subAccountId,
    organizationId: organizationId ?? activeOrganization.id,
  };

  const {
    loading, error, data, previousData, refetch,
  } = useQuery(FETCH_TRANSFERS(permissions), {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    variables: {
      input: {
        filter: {
          ...filter,
          ...queryFilter,
        },
        pagination: {
          sortField: 'createdAt',
          sortDesc: false,
          perPage: pageSize,
          offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  const emitFilterChange = (arg: Record<string, any>) => {
    if (onFilterChange) {
      onFilterChange({ ...queryFilter, ...arg });
    }
  };

  useEffect(() => emitFilterChange({}), []);

  useEffect(() => function cleanupOnUnmount() {
    setSubAccountId(filter.subAccountId ?? 'any');
    setSource('any');
    setType('any');
    setState('any');
    setPage(1);
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const stateColor = (forState: any) => (forState === 'RECONCILED' ? 'success' : ['FAILED', 'CANCELED'].includes(forState) ? 'error' : 'warning');

  const sourceDetails = (transfer: any): string => {
    if (transfer.dividendFor) {
      return ` - ${transfer.dividendFor.ticker}`;
    } if (transfer.bankAccount) {
      return ` - ${transfer.bankAccount.institutionNumber}`;
    }
    return '';
  };

  const handleTransitionWithReason = async (event: any) => {
    event.preventDefault();

    const reasonTypes = {
      fail: 'failReason',
      cancel: 'cancelReason',
      cancelReconciled: 'cancelReconciledReason',
    } as const;

    const reasonType = reasonTypes[transitionName as keyof typeof reasonTypes];

    if (reasonType) {
      await transitionTransfer({
        variables: {
          input: {
            transferId: activeTransfer.id,
            transition: transitionName,
            [reasonType]: transitionReason,
          },
        },
      });
      refetch();
      onTransition();
      setShowReasonModal(false);
      setTransitionReason('');
    }
  };

  const onClick = async (event: any) => {
    setTransitionName(event);
    if (['fail', 'cancel', 'cancelReconciled'].includes(event)) {
      setShowReasonModal(true);
    } else {
      await transitionTransfer({
        variables: {
          input: {
            transferId: activeTransfer.id,
            transition: event,
          },
        },
      });
      refetch();
      onTransition();
    }
  };

  const transferSources = TransferSourcesTypes;

  const transferStates = [
    'INITIATED',
    'REVIEWING',
    'READY',
    'REQUESTED',
    'PROCESSING',
    'RECONCILED',
    'FAILED',
    'CANCELED',
  ];

  const generateSourceText = (transfer: any): string => {
    if (['TO_ACCOUNT', 'TO_SUB_ACCOUNT'].includes(transfer.source as string)) {
      const accType = t(`accountTypes:${transfer?.fromSubAccount?.account?.type}`);
      return t('transferTo', { subAccount: accType });
    }
    if (['FROM_ACCOUNT', 'FROM_SUB_ACCOUNT'].includes(transfer.source as string)) {
      const accType = t(`accountTypes:${transfer?.fromSubAccount?.account?.type}`);
      return t('transferFrom', { subAccount: accType });
    }
    const sourceOption = transfer.initiatedByRepresentative ? 'REPRESENTATIVE' : transfer.source;
    return t(`transferSources.${sourceOption}`);
  };

  const navigationLink = (user: any): string => {
    if (user.type === 'INDIVIDUAL') return `/clients/${user.id}`;
    return `/nonIndividualClients/${user.id}`;
  };

  if (error) (<Typography>Error</Typography>);

  return (
    <Box>
      <Grid container sx={{ p: 2 }} spacing={1}>
        {filter.userId && (
          <Grid item xs={3}>
            <SubAccountSelect
              forObject='USER'
              forId={filter.userId}
              value={subAccountId}
              setSubAccount={(e) => { setSubAccountId(e.target.value); emitFilterChange({ subAccountId: e.target.value }); }}
              label={t('subAccount')}
              size='small'
              includeAnyOption
            />
          </Grid>
        )}

        <Grid item xs={3}>
          <TextField
            select
            value={source}
            label={t('activityTable.source')}
            onChange={(e: any) => { setSource(e.target.value); emitFilterChange(generateSourceFilter(e.target.value)); }}
            size='small'
            fullWidth
            placeholder={t('any')}
          >
            <MenuItem value={'any'}>{t('any')}</MenuItem>
            {Object.values(transferSources).map((x: any) => (
              <MenuItem value={x} key={x}>{t(`transferSources.${x}`)}</MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={2}>
          <TextField
            select
            value={type}
            label={t('activityTable.type')}
            onChange={(e: any) => { setType(e.target.value); emitFilterChange({ type: e.target.value }); }}
            size='small'
            fullWidth
            placeholder={t('any')}
          >
            <MenuItem value={'any'}>{t('any')}</MenuItem>
            <MenuItem value='DEPOSIT'>{t('DEPOSIT')}</MenuItem>
            <MenuItem value='WITHDRAW'>{t('WITHDRAW')}</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={2}>
          <TextField
            select
            value={state}
            label={t('activityTable.state')}
            onChange={(e: any) => { setState(e.target.value); emitFilterChange({ state: e.target.value }); }}
            size='small'
            fullWidth
            placeholder={t('any')}
          >
            <MenuItem value={'any'}>{t('any')}</MenuItem>
            {transferStates.map((x: any) => (
              <MenuItem value={x} key={x}>{t(x)}</MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          {loading && previousData ? (<CircularProgress size='30px' sx={{ marginTop: '6px' }} />) : <></>}
        </Grid>
      </Grid>
      {loading && !previousData ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
        <Grid container sx={{ overflow: 'hidden' }}>
          <Grid item xs={12} sx={{ overflow: 'auto' }}>
            <Table aria-label="table">
              <TableHead>
                <TableRow>
                  {showClient && (
                    <TableCell><Typography variant='overline'>{t('activityTable.client')}</Typography></TableCell>
                  )}
                  <TableCell><Typography variant='overline'>{t('activityTable.account')}</Typography></TableCell>
                  <TableCell><Typography variant='overline'>{t('activityTable.type')}</Typography></TableCell>
                  <TableCell><Typography variant='overline'>{t('activityTable.source')}</Typography></TableCell>
                  <TableCell><Typography variant='overline'>{t('activityTable.amount')}</Typography></TableCell>
                  <TableCell><Typography variant='overline'>{t('activityTable.state')}</Typography></TableCell>
                  <TableCell><Typography variant='overline'>{t('activityTable.autoTransfer')}</Typography></TableCell>
                  <TableCell><Typography variant='overline'>{t('activityTable.createdAt')}</Typography></TableCell>
                  {showReconciledAt && (
                    <TableCell><Typography variant='overline'>{t('activityTable.reconciledAt')}</Typography></TableCell>
                  )}
                  {permissions.includes('transition:transfer') && (
                    <TableCell align='right'><Typography variant='overline'>{t('activityTable.actions')}</Typography></TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {(data || previousData)?.fetchTransfers?.transfers?.map((transfer: any) => (
                  <TableRow
                    hover
                    onClick={() => {
                      setActiveTransfer(transfer);
                      setModalOpen(true);
                    }}
                    selected={transfer.id === activeTransfer.id}
                    key={transfer.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
                  >
                    {showClient && (
                      <TableCell component="th" scope="row">
                        <MuiLink
                          component={Link}
                          to={navigationLink(transfer.subAccount.account.user)}>
                          {/* to={`/${clients}/${transfer.subAccount.account.user.id}`}> */}
                          {generateClientNameString(transfer.subAccount.account.user)}
                        </MuiLink>
                      </TableCell>
                    )}
                    <TableCell component="th" scope="row">
                      {transfer.subAccount.goal.name} - {t(`accountTypes:${transfer.subAccount.account.type}`)}
                    </TableCell>
                    <TableCell><Chip size='small' label={transfer.isWithdrawAll ? 'WITHDRAW ALL' : transfer.type} color={transfer.isSuspicious ? 'error' : 'default'} /></TableCell>
                    <TableCell>{generateSourceText(transfer)}<b>{sourceDetails(transfer)}</b></TableCell>
                    <TableCell>{formatMoneyValue(transfer.amountCents)}</TableCell>
                    <TableCell>
                      <Chip
                        size='small'
                        label={transfer.state}
                        color={stateColor(transfer.state)}
                      />
                    </TableCell>
                    <TableCell>{transfer.isRecurring ? t('transfersTable.yes') : t('transfersTable.no')}</TableCell>
                    <TableCell><DateTime variant='subtitle2' date={transfer.createdAt} /></TableCell>
                    {showReconciledAt && (
                      <TableCell><DateTime variant='subtitle2' date={transfer.reconciledAt} /></TableCell>
                    )}
                    {(permissions.includes('transition:transfer') || permissions.includes('write:adjustment_transfer')) && (
                      <TableCell align='right'>
                        {permissions.includes('write:adjustment_transfer') && (
                          <IconButton size='small' onClick={(e) => {
                            e.stopPropagation();
                            setActiveTransfer(transfer);
                            setEditModalOpen(true);
                          }}>
                            <EditIcon />
                          </IconButton>
                        )}

                        {permissions.includes('transition:transfer') && (
                          <IconButton
                            sx={{ marginLeft: 1 }}
                            size='small'
                            onClick={(e) => {
                              e.stopPropagation();
                              setActiveTransfer(transfer);
                              handleClick(e);
                            }}
                            disabled={transitions.filter((x: any) => x.from.includes(transfer.state)).length === 0}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {(data || previousData)?.fetchTransfers?.transfers?.length === 0
              && <Stack sx={{ marginTop: '5em', marginBottom: '5em' }}>
                <Grid display="flex" justifyContent="center">
                  <InfoIcon/>
                </Grid>
                <Grid display="flex" justifyContent="center">
                <Typography sx={{ fontWeight: '600' }}>
                  {t('activityTable.noTransfersExist')}
                </Typography>
                </Grid>
                <Grid display="flex" justifyContent="center" sx={{ marginTop: '1em' }}>
                  <NewTransfer
                    afterCreate={onNewTransfer}
                    {...filter.userId && { forObject: 'USER', forId: filter.userId }}
                    {...filter.clientGroupId && { forObject: 'CLIENT_GROUP', forId: filter.clientGroupId }}
                    longLabel
                    presetSubaccount={filter.subAccountId}
                    presetAccount={filter.accountId}
                    presetSubAccountMarketValueCents={filter.subAccountMarketValueCents}
                  />
                </Grid>
              </Stack>
            }
          </Grid>
          {(data || previousData)?.fetchTransfers?.transfers?.length > 0
            && <Pagination
              count={Math.ceil(((data || previousData)?.fetchTransfers?.totalCount ?? 0) / pageSize)}
              page={page}
              onChange={(_e, newPage) => setPage(newPage)}
              sx={{
                p: 1,
                textAlign: 'right',
                '.MuiPagination-ul': {
                  justifyContent: 'end',
                },
              }}
            />
          }
        </Grid>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {
            (data || previousData)?.fetchTransfers?.transfers?.length > 0
            && transitions.map((transition: any) => {
              let shouldRender = false;

              if (activeTransfer.state === 'REVIEWING') {
                if (activeTransfer.type === 'WITHDRAW') {
                  shouldRender = ['cancel', 'initiate'].includes(transition.name);
                }
                if (activeTransfer.type === 'DEPOSIT') {
                  shouldRender = ['cancel', 'ready'].includes(transition.name);
                }
              } else {
                shouldRender = transition.from.includes(activeTransfer.state);
              }

              return (
                shouldRender && (
                  <MenuItem
                    key={transition.name}
                    onClick={() => {
                      onClick(transition.name);
                    }}
                  >
                    {t(`transferTransitions.${transition.name}`)}
                  </MenuItem>
                )
              );
            })
          }
        </Menu>

          {modalOpen
            && <TransferModal transfer={activeTransfer} handleClose={() => setModalOpen(false)} />
          }
          <FormModal
            open={showReasonModal}
            loading={loading}
            title={t('transferTransitions.reason')}
            onSubmit={handleTransitionWithReason}
            formButton={t('confirm')}
            handleClose={() => {
              setShowReasonModal(false);
              setTransitionReason('');
            }}
            disabled={transitionReason.length === 0}
          >
            <ListItem>
              <TextField
                label={t('transferTransitions.reason')}
                fullWidth
                value={transitionReason}
                onChange={(e) => setTransitionReason(e.target.value)}
              />
            </ListItem>
            <></>
          </FormModal>

          <UpdateTransfer
            open={editModalOpen}
            activeTransfer={activeTransfer}
            onClose={() => {
              setEditModalOpen(false);
              setActiveTransfer({});
            }}
          />
        </>
      )}
    </Box>
  );
};

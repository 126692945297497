import { gql, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { remove } from 'lodash/fp';
import { DeleteRole } from './deleteRole';
import { NavigationStyles, AuthenticationConnections, TranslatedString } from '../../../../../interfaces';
import { Box } from '../../../../1-primative';
import {
  TranslatableTextField, MenuItem, SelectField, Switch,
} from '../../../../2-component';
import {
  PageConfigurationSelect, OrganizationSelect, PermissionsSelect, FormModal, AccessiblePagesSelect, DashboardsSelect, SingleRoleProfileSelect,
  StaticPageWidgetConfigurationSelect,
} from '../../../../3-pattern';
import { usePermissions } from '../../../../../providers/userContextProvider';

type EditRoleProps = {
  afterUpdate: () => void;
  roleToUpdate: any,
  handleClose: () => void;
};

type RoleInput = {
  id: string,
  permissions: string[],
  dashboards: string[],
  accessiblePages: string[],
  navigationStyle: NavigationStyles,
  nonIndividualPageConfigurationId: string,
  individualPageConfigurationId: string,
  goalPageConfigurationId: string,
  accountPageConfigurationId: string,
  householdPageConfigurationId: string,
  subAccountPageConfigurationId: string,
  individualTableStaticPageWidgetConfigurationId: string,
  nonIndividualTableStaticPageWidgetConfigurationId: string,
  householdTableStaticPageWidgetConfigurationId: string,
};

type RoleState = {
  id?: string;
  translatedName: TranslatedString;
  translatedDescription: TranslatedString;
  organization: { id: string };
  roleProfile?: { id: string };
  nonIndividualPageConfiguration?: { id: string };
  individualPageConfiguration?: { id: string };
  goalPageConfiguration?: { id: string };
  accountPageConfiguration?: { id: string };
  householdPageConfiguration?: { id: string };
  makeHouseholdDefaultView?: boolean;
  subAccountPageConfiguration?: { id: string };
  individualTableStaticPageWidgetConfiguration?: { id: string };
  nonIndividualTableStaticPageWidgetConfiguration?: { id: string };
  householdTableStaticPageWidgetConfiguration?: { id: string };
  permissions: string[];
  accessiblePages: any[];
  dashboards: any[];
  navigationStyle: NavigationStyles,
  defaultAuthenticationConnection?: string,
};

const UPDATE_ROLE = gql`
mutation updateRole($input: UpdateRoleInput!) {
  updateRole(input: $input) {
    role { id }
  }
}
`;

export const EditRole = ({ afterUpdate, roleToUpdate, handleClose }: EditRoleProps) => {
  const { t } = useTranslation(['orgSettings', 'shared']);
  const { permissions } = usePermissions();
  const [role, setRole] = useState<RoleState>(roleToUpdate);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [dashboards, setDashboards] = useState<string[]>(roleToUpdate?.dashboards?.map((x: any) => x.id) || []);

  const [updateRole, { loading }] = useMutation(UPDATE_ROLE, {
    variables: {
      input: {
        roleId: role?.id,
        translatedName: { en: role?.translatedName?.en, fr: role?.translatedName?.fr },
        translatedDescription: { en: role?.translatedDescription?.en, fr: role?.translatedDescription?.fr },
        organizationId: role?.organization?.id,
        roleProfileId: role?.roleProfile?.id,
        permissions: role?.permissions,
        accessiblePages: role?.accessiblePages,
        navigationStyle: role?.navigationStyle,
        defaultAuthenticationConnection: role?.defaultAuthenticationConnection || null,
        nonIndividualPageConfigurationId: role?.nonIndividualPageConfiguration?.id || null,
        individualPageConfigurationId: role?.individualPageConfiguration?.id || null,
        goalPageConfigurationId: role?.goalPageConfiguration?.id || null,
        accountPageConfigurationId: role?.accountPageConfiguration?.id || null,
        householdPageConfigurationId: role?.householdPageConfiguration?.id || null,
        makeHouseholdDefaultView: role?.makeHouseholdDefaultView || null,
        subAccountPageConfigurationId: role?.subAccountPageConfiguration?.id || null,
        individualTableStaticPageWidgetConfigurationId: role?.individualTableStaticPageWidgetConfiguration?.id || null,
        nonIndividualTableStaticPageWidgetConfigurationId: role?.nonIndividualTableStaticPageWidgetConfiguration?.id || null,
        householdTableStaticPageWidgetConfigurationId: role?.householdTableStaticPageWidgetConfiguration?.id || null,
        dashboards,
      },
    },
  });

  useEffect(() => {
    setRole(roleToUpdate);
    setDashboards(roleToUpdate?.dashboards?.map((x: any) => x.id) || []);
  }, [roleToUpdate]);

  const onDeleteMenuClick = () => setDeleteDialogOpen(true);

  const update = async () => {
    await updateRole();
    afterUpdate();
  };

  if (!role) {
    return <></>;
  }

  const menuItems = [
    <MenuItem key="deleteItem" onClick={onDeleteMenuClick} >{t('shared:delete')}</MenuItem>,
  ];

  const handleRoleProfileChange = (data: RoleInput) => {
    setRole((prev) => ({
      ...prev,
      roleProfile: { id: data.id },
      permissions: data.permissions,
      accessiblePages: data.accessiblePages,
      navigationStyle: data.navigationStyle,
      nonIndividualPageConfigurationId: { id: data.nonIndividualPageConfigurationId },
      individualPageConfigurationId: { id: data.individualPageConfigurationId },
      goalPageConfigurationId: { id: data.goalPageConfigurationId },
      accountPageConfigurationId: { id: data.accountPageConfigurationId },
      householdPageConfigurationId: { id: data.householdPageConfigurationId },
      subAccountPageConfigurationId: { id: data.subAccountPageConfigurationId },
      individualTableStaticPageWidgetConfigurationId: { id: data.individualTableStaticPageWidgetConfigurationId },
      nonIndividualTableStaticPageWidgetConfigurationId: { id: data.nonIndividualTableStaticPageWidgetConfigurationId },
      householdTableStaticPageWidgetConfigurationId: { id: data.householdTableStaticPageWidgetConfigurationId },
    }));
    setDashboards(data.dashboards.map((x: any) => x.id));
  };

  return (
    <>
      <FormModal
        key={role.id} loading={loading} title={t('roleModal.editTitle')} formButton={t('shared:update')} onSubmit={update} open handleClose={handleClose} maxWidth='md' menuItems={menuItems}
      >
        <Box display='flex' flexDirection='column' gap={2}>
          <TranslatableTextField label={t('roleModal.name')} value={role.translatedName} fullWidth onChange={(value) => setRole({ ...role, translatedName: value })} />
          <TranslatableTextField label={t('roleModal.description')} value={role.translatedDescription} fullWidth onChange={(value) => setRole({ ...role, translatedDescription: value })} />
          <OrganizationSelect value={role.organization.id} label={t('roleModal.organization')} onChange={(event: any) => setRole({ ...role, organization: { id: event as string } })} />
          <SingleRoleProfileSelect value={role?.roleProfile?.id} label={t('roleModal.roleProfile')} organizationId={role.organization.id} onChange={handleRoleProfileChange} />

          {permissions.includes('write:advanced_organization_settings') && (
            <>
              <AccessiblePagesSelect accessiblePages={role.accessiblePages} label={t('roleModal.accessiblePages')} onChange={(event: any) => {
                setRole({ ...role, accessiblePages: event.target.value });
              }} />
              <DashboardsSelect selectedDashboards={dashboards} label={t('roleModal.dashboards')} onChange={(event: any) => setDashboards(event.target.value)} />
              <SelectField value={role.navigationStyle ?? NavigationStyles.FULL} label={t('roleTable.navigationStyle')} fullWidth onChange={(event: any) => {
                setRole({ ...role, navigationStyle: event.target.value });
              }}>
                <MenuItem key='1' value={NavigationStyles.FULL}>{NavigationStyles.FULL}</MenuItem>
                <MenuItem key='2' value={NavigationStyles.SIMPLE}>{NavigationStyles.SIMPLE}</MenuItem>
              </SelectField>
              <SelectField fullWidth value={role?.defaultAuthenticationConnection ?? 'NA'} label={t('organizationDetails.defaultAuthenticationConnectionTitle')} onChange={(e: any) => {
                setRole({ ...role, defaultAuthenticationConnection: e.target.value === 'NA' ? undefined : e.target.value });
              }}>
                <MenuItem key={'NA'} value={'NA'}>{t('roleModal.undefinedDefaultAuthenticationConnection')}</MenuItem>
                {Object.values(AuthenticationConnections).map((x: any) => (
                  <MenuItem key={x} value={x}>{t(`organizationDetails.defaultAuthenticationConnectionOptions.${x}`)}</MenuItem>
                ))}
              </SelectField>

              {permissions.includes('read:page_configurations') && (
                <>
                  <PageConfigurationSelect
                    filter={{ type: 'NON_INDIVIDUAL' }}
                    value={role.nonIndividualPageConfiguration?.id as string}
                    label={t('roleModal.nonIndividualPageConfiguration')}
                    organizationId={role.organization.id}
                    onChange={(event: any) => setRole({ ...role, nonIndividualPageConfiguration: { id: event.target.value as string } })}
                  />
                  <PageConfigurationSelect
                    filter={{ type: 'INDIVIDUAL' }}
                    value={role.individualPageConfiguration?.id as string}
                    label={t('roleModal.individualPageConfiguration')}
                    organizationId={role.organization.id}
                    onChange={(event: any) => setRole({ ...role, individualPageConfiguration: { id: event.target.value as string } })}
                  />
                  <PageConfigurationSelect
                    filter={{ type: 'GOAL' }}
                    value={role.goalPageConfiguration?.id as string}
                    label={t('roleModal.goalPageConfiguration')}
                    organizationId={role.organization.id}
                    onChange={(event: any) => setRole({ ...role, goalPageConfiguration: { id: event.target.value as string } })}
                  />
                  <PageConfigurationSelect
                    filter={{ type: 'ACCOUNT' }}
                    value={role.accountPageConfiguration?.id as string}
                    label={t('roleModal.accountPageConfiguration')}
                    organizationId={role.organization.id}
                    onChange={(event: any) => setRole({ ...role, accountPageConfiguration: { id: event.target.value as string } })}
                  />
                  <PageConfigurationSelect
                    filter={{ type: 'SUB_ACCOUNT' }}
                    value={role.subAccountPageConfiguration?.id as string}
                    label={t('roleModal.subAccountPageConfiguration')}
                    organizationId={role.organization.id}
                    onChange={(event: any) => setRole({ ...role, subAccountPageConfiguration: { id: event.target.value as string } })}
                  />
                  <PageConfigurationSelect
                    filter={{ type: 'HOUSEHOLD' }}
                    value={role.householdPageConfiguration?.id as string}
                    label={t('roleModal.householdPageConfiguration')}
                    organizationId={role.organization.id}
                    onChange={(event: any) => setRole({ ...role, householdPageConfiguration: { id: event.target.value as string } })}
                  />
                  <Switch label={t('roleModal.makeHouseholdDefaultView')} checked={!!role.makeHouseholdDefaultView} onChange={(e) => setRole({ ...role, makeHouseholdDefaultView: e })} />

                  <StaticPageWidgetConfigurationSelect
                    filter={{ widgetType: 'INDIVIDUAL_TABLE' }}
                    value={role.individualTableStaticPageWidgetConfiguration?.id as string}
                    label={t('roleModal.individualTableStaticPageWidgetConfiguration')}
                    organizationId={role.organization.id}
                    onChange={(event: any) => setRole({ ...role, individualTableStaticPageWidgetConfiguration: { id: event.target.value as string } })}
                  />
                  <StaticPageWidgetConfigurationSelect
                    filter={{ widgetType: 'NON_INDIVIDUAL_TABLE' }}
                    value={role.nonIndividualTableStaticPageWidgetConfiguration?.id as string}
                    label={t('roleModal.nonIndividualTableStaticPageWidgetConfiguration')}
                    organizationId={role.organization.id}
                    onChange={(event: any) => setRole({ ...role, nonIndividualTableStaticPageWidgetConfiguration: { id: event.target.value as string } })}
                  />
                  <StaticPageWidgetConfigurationSelect
                    filter={{ widgetType: 'HOUSEHOLD_TABLE' }}
                    value={role.householdTableStaticPageWidgetConfiguration?.id as string}
                    label={t('roleModal.householdTableStaticPageWidgetConfiguration')}
                    organizationId={role.organization.id}
                    onChange={(event: any) => setRole({ ...role, householdTableStaticPageWidgetConfiguration: { id: event.target.value as string } })}
                  />
                </>
              )}
              <PermissionsSelect permissions={role.permissions} onChange={(permission: string, checked: boolean) => {
                let p = [...(role?.permissions ?? [])];
                if (checked) {
                  p.push(permission);
                } else {
                  p = remove((x) => x === permission, p);
                }
                setRole({ ...role, permissions: p });
              }} />
            </>
          )}
        </Box>
      </FormModal>
      <DeleteRole open={deleteDialogOpen} roleToDelete={role} handleClose={() => setDeleteDialogOpen(false)} afterDelete={() => {
        setDeleteDialogOpen(false);
        afterUpdate();
      }} />
    </>
  );
};

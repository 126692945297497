import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  type MouseEvent, useContext, useEffect, useRef, useState,
} from 'react';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import AddIcon from '@mui/icons-material/Add';
import {
  Box, Button, Card, IconButton, Pagination, Table, TableBody, TableCell, TableRow, TextField, Typography, Menu, MenuItem, Badge,
  TableHeadCell, CardContent, Skeleton,
} from '../..';
import { translateBackend } from '../../../assets/i18n/config';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { DeleteArticle } from './components/deleteArticle';
import { FilterArticles } from './components/filterArticles';
import { CreateArticle } from './components/createArticle';
import { EditArticle } from './components/editArticle';
import type { Article } from './components/createAndEditNewsAndInsightsForm';
import { UpdateOrganizationSwitch } from '../../4-module/updateOrganizationSwitch/updateOrganizationSwitch';
import { FETCH_ARTICLES, TRANSITION_ARTICLE } from './components/queries';

interface ArticleRef extends Required<Article> {
  state: 'DRAFT' | 'ACTIVE' | 'INACTIVE',
}

const PAGE_SIZE = 15;

const statesToVariantMap: Record<string, 'positive' | 'warning' | 'neutral' | 'negative'> = {
  ACTIVE: 'positive',
  INACTIVE: 'warning',
  DRAFT: 'neutral',
};

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
};

export const ManageNewsAndInsight = () => {
  const selectedArticleRef = useRef<ArticleRef>();
  const { permissions } = usePermissions();
  const { t } = useTranslation(['shared', 'newsAndInsight']);
  const { activeOrganization } = useContext(UserContext);

  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [stateToBeFiltered, setStateToBeFiltered] = useState<string | undefined>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedArticleState, setSelectedArticleState] = useState<Article | null>(null);

  const [openCreateArticleDialog, setOpenCreateArticleDialog] = useState(false);
  const [openEditArticleDialog, setOpenEditArticleDialog] = useState(false);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [openDeleteArticleDialog, setOpenDeleteArticleDialog] = useState(false);

  const [transitionArticle, { loading: transitionArticleLoading }] = useMutation(TRANSITION_ARTICLE, {
    variables: { input: { articleId: '', transition: 'activate' } },
  });

  const {
    loading, data, previousData, refetch,
  } = useQuery(FETCH_ARTICLES, {
    variables: {
      input: {
        filter: { searchText, state: stateToBeFiltered, organizationId: activeOrganization.id },
        pagination: {
          sortField: 'createdAt', sortDesc: true, perPage: PAGE_SIZE, offSet: (page - 1) * PAGE_SIZE,
        },
      },
    },
  });

  const open = Boolean(anchorEl);
  const hasWritePermission = permissions.includes('write:news_and_insights');

  const handleClose = () => setAnchorEl(null);
  const handleClick = (event: MouseEvent<HTMLElement>, article: any) => {
    event.stopPropagation();
    selectedArticleRef.current = article;
    setAnchorEl(event.currentTarget);
  };

  const handleEditClickedOption = () => {
    handleClose();
    if (selectedArticleRef.current) setSelectedArticleState(selectedArticleRef.current);
    setOpenEditArticleDialog(true);
  };

  const publishOrUnPublishArticle = async () => {
    const transition = selectedArticleRef.current?.state === 'DRAFT' ? 'activate' : 'review';
    await transitionArticle({ variables: { input: { articleId: selectedArticleRef.current?.id ?? '', transition } } });

    refetch();
    handleClose();
  };

  const clearFilter = () => {
    setStateToBeFiltered(undefined);
    setOpenFilterDialog(false);
  };

  const statesToReadableWordMap: Record<string, string> = {
    ACTIVE: t('shared:published'),
    INACTIVE: t('shared:inactive'),
    DRAFT: t('shared:saved'),
  };

  useEffect(() => {
    refetch();
  }, [refetch, searchText]);

  return (
    <Box>
      <Card variant='elevated' sx={{ overflowX: 'auto' }}>
        <CardContent>
          <Box display='flex' alignItems='center'justifyContent='space-between'>
            <Box display='flex' alignItems='center' gap={2}>
              <TextField label="" value={searchText} leadingIcon="search" onChange={((e: { target: { value: string } }) => {
                setSearchText(e.target.value);
                setPage(1);
              })} />
              {permissions.includes('write:advanced_organization_settings') && (
                <UpdateOrganizationSwitch field='useParentArticles' label={t('settings:newsAndInsights.useParentArticles')} organizationId={activeOrganization.id} refetchQueries={[FETCH_ARTICLES]} />
              )}
            </Box>
            <Box display='flex' alignItems='center'>
              <IconButton onClick={() => setOpenFilterDialog(true)} sx={{ mr: 2 }} testId='news-and-insights-filter-button'><FilterListRoundedIcon /></IconButton>
              {hasWritePermission && (
                <Button label={t('shared:add')} leadingIcon={AddIcon} dataTestId='news-and-insights-add-button' onClick={() => {
                  setSelectedArticleState(null);
                  setOpenCreateArticleDialog(true);
                }} />
              )}
            </Box>
          </Box>
        </CardContent>

        <Table aria-label="table">
          <TableBody>
            <TableRow>
              <TableHeadCell>{t('newsAndInsight:articleName')}</TableHeadCell>
              <TableHeadCell>{t('newsAndInsight:uploadedBy')}</TableHeadCell>
              <TableHeadCell>{t('newsAndInsight:lastUpdated')}</TableHeadCell>
              <TableHeadCell>{t('newsAndInsight:articleLink')}</TableHeadCell>
              <TableHeadCell>{t('newsAndInsight:status')}</TableHeadCell>
              {hasWritePermission && <TableHeadCell />}
            </TableRow>
            {loading && !previousData ? (
              <>
                {[...Array(15)].map((_x: any, i: number) => (
                  <TableRow key={i}>
                    <TableCell><Skeleton width='100%' /></TableCell>
                    <TableCell><Skeleton width='100%' /></TableCell>
                    <TableCell><Skeleton width='100%' /></TableCell>
                    <TableCell><Skeleton width='100%' /></TableCell>
                    <TableCell><Skeleton width='100%' /></TableCell>
                    <TableCell><Skeleton width='100%' /></TableCell>
                  </TableRow>
                ))}
              </>
            ) : (data || previousData)?.fetchArticles?.articles?.map((article: any) => (
              <TableRow hover pointer key={article.id} onClick={() => {
                if (hasWritePermission) {
                  selectedArticleRef.current = article;
                  handleEditClickedOption();
                }
              }}>
                <TableCell sx={{ maxWidth: '150px' }}>{translateBackend(article.translatedName)}</TableCell>
                <TableCell>{`${article.createdBy.firstName} ${article.createdBy.lastName}`}</TableCell>
                <TableCell>{formatDate(article.updatedAt)}</TableCell>
                <TableCell sx={{ maxWidth: '350px' }}>
                  <Typography width='100%' whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis' fontWeight={400}>{article.articleUrl}</Typography>
                </TableCell>
                <TableCell>
                  <Badge label={statesToReadableWordMap[article.state]} color={statesToVariantMap[article.state]}/>
                </TableCell>
                {hasWritePermission && (
                  <TableCell>
                    <IconButton onClick={(e) => handleClick(e, article)}><MoreVertRoundedIcon /></IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination page={page} onChange={(_e, newPage) => setPage(newPage)} count={Math.ceil(((data || previousData)?.fetchArticles?.totalCount ?? 0) / PAGE_SIZE)} sx={{
          p: 1, textAlign: 'right', '.MuiPagination-ul': { justifyContent: 'end' },
        }} />
      </Card>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} anchorOrigin={{ vertical: 'center', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <MenuItem onClick={handleEditClickedOption}>
          <EditRoundedIcon sx={{ marginRight: '8px' }} />
          {t('shared:edit')}
        </MenuItem>
        <MenuItem onClick={publishOrUnPublishArticle}>
          {selectedArticleRef.current?.state === 'ACTIVE' ? (<VisibilityOffRoundedIcon sx={{ marginRight: '8px' }} />) : (<RemoveRedEyeRoundedIcon sx={{ marginRight: '8px' }} />)}
          {selectedArticleRef.current?.state === 'ACTIVE' ? t('shared:unpublish') : t('shared:publish')}
        </MenuItem>
        <MenuItem onClick={() => {
          setOpenDeleteArticleDialog(true);
          handleClose();
        }}>
          <DeleteRoundedIcon sx={{ marginRight: '8px' }} />
          {t('shared:delete')}
        </MenuItem>
      </Menu>

      {openCreateArticleDialog && (
        <CreateArticle open={openCreateArticleDialog} setOpen={setOpenCreateArticleDialog} refetch={refetch} loading={transitionArticleLoading} transitionArticle={transitionArticle} />
      )}
      {openEditArticleDialog && (
        <EditArticle open={openEditArticleDialog} setOpen={setOpenEditArticleDialog} refetch={refetch} article={selectedArticleState} />
      )}
      <FilterArticles open={openFilterDialog} setOpen={setOpenFilterDialog} state={stateToBeFiltered} setState={setStateToBeFiltered} loading={transitionArticleLoading} clearFilter={clearFilter} />
      <DeleteArticle open={openDeleteArticleDialog} setOpen={setOpenDeleteArticleDialog} article={selectedArticleRef} refetch={refetch} loading={transitionArticleLoading} />
    </Box>
  );
};

export default ManageNewsAndInsight;

import dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { uniq } from 'lodash/fp';
import {
  Alert, Button, IconButton, TextField, DateField,
} from '../../../../2-component';
import { Box } from '../../../../1-primative';

const ScheduleDates = ({ schedule, setSchedule, isEdit }: { schedule: any, setSchedule: (obj: any) => void, isEdit?: boolean }) => {
  const { t } = useTranslation(['schedule']);
  const addNewDate = (): void => {
    setSchedule((prevState: any) => ({
      ...prevState,
      dates: prevState.dates.concat(''),
    }));
  };
  const updateDateItem = (day: string, newDate: string) => {
    setSchedule((prevState: any) => ({
      ...prevState,
      dates: prevState.dates.map((value: string) => (value === day ? newDate : value)),
    }));
  };
  const removeDateItem = (day: string) => {
    setSchedule((prevState: any) => ({
      ...prevState,
      dates: prevState.dates.filter((value: string) => value !== day),
    }));
  };
  const hasDuplicates = uniq(schedule?.dates).length !== schedule?.dates.length;
  return (
    <Box display='flex' flexDirection='column' gap={2} mt={2}>
      {(schedule?.dates || []).map((d: string, index: number) => (
        <Box key={`${d}_${index}`} display='flex' justifyContent='space-between' alignItems='center'>
          <DateField
            data-testid={`schedule-date-${d}`}
            label={t('schedule:addModal.scheduleDate')}
            value={d}
            onChange={(date: any) => updateDateItem(d, dayjs(date?.toString()).format('YYYY-MM-DD'))}
            renderInput={(params: any) => <TextField fullWidth size='small' {...params} />}
          />
          <IconButton
            data-testid="delete-date"
            edge="end"
            sx={{ pl: 1 }}
            onClick={
              () => {
                removeDateItem(d);
              }
            }
            size='small'
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      {schedule.type === 'Manual' || isEdit ? (
        <Box display='flex' justifyContent='end'>
          <Button variant='tonal' onClick={addNewDate} label={t('schedule:addModal.addDate')} />
        </Box>
      ) : null}
      {hasDuplicates ? (
        <Alert sx={{ width: '100%' }} severity="error">{t('schedule:addModal.duplicateError')}</Alert>
      ) : null}
    </Box>
  );
};

export default ScheduleDates;

import dayjs from 'dayjs';
import { Typography } from '@mui/material';
import { useLocalization } from '../../../util/useLocalization';

export const Date = (props: any) => {
  let { date } = props;
  const { localizedDate } = useLocalization();

  if (!date) {
    return null;
  }

  if (typeof date === 'string') {
    if (props.isYearEnd) {
      const parsedDate = dayjs(date);
      date = `${parsedDate.format('DD-MM')}`;
    } else {
      date = date.substring(0, 10); // Drop `+HHMM` timezone part
    }
  }
  const dayjsObject = dayjs.isDayjs(date) ? date : dayjs(date);

  return <Typography {...props}>
    { props.isYearEnd
      ? date
      : localizedDate(
        props.timezone
          ? dayjsObject.tz(props.timezone)
          : dayjsObject,
      )
    }
  </Typography>;
};

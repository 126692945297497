import {
  ListItem, Typography, TextField, MenuItem,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { remove } from 'lodash/fp';
import PageConfigurationSelect from 'components/inputs/pageConfigurationSelect';
import { NavigationStyles } from 'interfaces';
import OrganizationSelect from '../../../components/inputs/organizationSelect';
import RoleProfileSelect from '../../../components/inputs/roleProfileSelect';
import PermissionsSelect from '../../../components/inputs/permissionsSelect';
import CreateNewModal from '../../../components/modals/createNewModal';
import TranslatableString from '../../../components/inputs/translatableString';
import AccessiblePagesSelect from '../../../components/inputs/accessiblePagesSelect';
import DashboardsSelect from '../../../components/inputs/dashboardsSelect';
import { AuthenticationConnections } from '../../../interfaces';
import StaticPageWidgetConfigurationSelect from '../../../components/inputs/staticPageWidgetConfiguration';

type RoleInput = {
  id: string;
  permissions: string[];
  dashboards: string[];
  accessiblePages: string[];
  navigationStyle: NavigationStyles;
  nonIndividualPageConfigurationId: string;
  individualPageConfigurationId: string;
  goalPageConfigurationId: string;
  accountPageConfigurationId: string;
  householdPageConfigurationId: string;
  subAccountPageConfigurationId: string;
  individualTableStaticPageWidgetConfigurationId: string,
  nonIndividualTableStaticPageWidgetConfigurationId: string,
  householdTableStaticPageWidgetConfigurationId: string,
};

type RoleState = {
  translatedName: { en: string };
  translatedDescription: { en: string };
  organizationId: string | undefined;
  roleProfileId?: string;
  permissions: string[];
  accessiblePages: any[];
  dashboards: any[];
  defaultAuthenticationConnection?: string,
  nonIndividualPageConfigurationId?: string | null;
  individualPageConfigurationId?: string | null;
  goalPageConfigurationId?: string | null;
  accountPageConfigurationId?: string | null;
  householdPageConfigurationId?: string | null;
  subAccountPageConfigurationId?: string | null;
  individualTableStaticPageWidgetConfigurationId?: string | null;
  nonIndividualTableStaticPageWidgetConfigurationId?: string | null;
  householdTableStaticPageWidgetConfigurationId?: string | null;
  navigationStyle: NavigationStyles;
};

const CREATE_ROLE = gql`
  mutation createRole($input: CreateRoleInput!) {
    createRole(input: $input) {
      role { id }
    }
  }
`;

const DEFAULT_INITIAL_STATE = {
  translatedName: { en: '' },
  translatedDescription: { en: '' },
  organizationId: '',
  roleProfileId: undefined as string | undefined,
  permissions: [],
  accessiblePages: [],
  dashboards: [],
  nonIndividualPageConfigurationId: null,
  individualPageConfigurationId: null,
  goalPageConfigurationId: null,
  accountPageConfigurationId: null,
  householdPageConfigurationId: null,
  subAccountPageConfigurationId: null,
  individualTableStaticPageWidgetConfigurationId: null,
  nonIndividualTableStaticPageWidgetConfigurationId: null,
  householdTableStaticPageWidgetConfigurationId: null,
  navigationStyle: NavigationStyles.FULL,
};

const NewRole = ({ afterCreate, defaultOrg }: { afterCreate: () => void; defaultOrg?: string }) => {
  const initialState = { ...DEFAULT_INITIAL_STATE, organizationId: defaultOrg ?? '' };

  const { t } = useTranslation(['orgSettings']);
  const [role, setRole] = useState<RoleState>(initialState);
  const [createRole, { loading }] = useMutation(CREATE_ROLE, { variables: { input: role }, onCompleted: () => setRole(initialState) });

  const close = () => setRole(initialState);
  const create = async () => {
    await createRole();
    afterCreate();
  };

  const disabled = !(role.translatedName.en && role.translatedDescription.en && role.organizationId);

  const handleRoleProfileChange = (data: RoleInput) => {
    setRole((prev) => ({
      ...prev,
      roleProfileId: data.id,
      permissions: data.permissions,
      dashboards: data.dashboards.map((x: any) => x.id),
      accessiblePages: data.accessiblePages,
      navigationStyle: data.navigationStyle,
      nonIndividualPageConfigurationId: data.nonIndividualPageConfigurationId,
      individualPageConfigurationId: data.individualPageConfigurationId,
      goalPageConfigurationId: data.goalPageConfigurationId,
      accountPageConfigurationId: data.accountPageConfigurationId,
      householdPageConfigurationId: data.householdPageConfigurationId,
      subAccountPageConfigurationId: data.subAccountPageConfigurationId,
      individualTableStaticPageWidgetConfigurationId: data.individualTableStaticPageWidgetConfigurationId,
      nonIndividualTableStaticPageWidgetConfigurationId: data.nonIndividualTableStaticPageWidgetConfigurationId,
      householdTableStaticPageWidgetConfigurationId: data.householdTableStaticPageWidgetConfigurationId,
    }));
  };

  return (
    <CreateNewModal
      state={role}
      initialState={initialState}
      loading={loading}
      disabled={disabled}
      title={t('roleModal.title')}
      onSubmit={create}
      sx={{ float: 'right', m: 1 }}
      maxWidth='md'
      onClose={close}
    >
      <ListItem>
        <TranslatableString label={t('roleModal.name')} value={role.translatedName} onChange={(value) => setRole({ ...role, translatedName: value })} />
      </ListItem>
      <ListItem>
        <TranslatableString label={t('roleModal.description')} value={role.translatedDescription} onChange={(value) => setRole({ ...role, translatedDescription: value })} />
      </ListItem>
      <ListItem>
        <OrganizationSelect value={role.organizationId} label={t('roleModal.organization')} onChange={(event: any) => setRole({ ...role, organizationId: event.target.value as string })} />
      </ListItem>
      <ListItem>
        <RoleProfileSelect value={role.roleProfileId} label={t('roleModal.roleProfile')} organizationId={role.organizationId} onChange={handleRoleProfileChange} />
      </ListItem>
      <ListItem>
        <AccessiblePagesSelect accessiblePages={role.accessiblePages} label={t('roleModal.accessiblePages')} onChange={(event: any) => setRole({ ...role, accessiblePages: event.target.value })} />
      </ListItem>
      <ListItem>
        <DashboardsSelect selectedDashboards={role.dashboards.map((x: any) => x)} label={t('roleModal.dashboards')} onChange={(event: any) => setRole({ ...role, dashboards: event.target.value })} />
      </ListItem>
      <ListItem>
        <TextField select value={role.navigationStyle ?? NavigationStyles.FULL} label={t('roleTable.navigationStyle')} sx={{ width: '100%' }} onChange={(event: any) => {
          setRole((prevRole: any) => ({ ...prevRole, navigationStyle: event.target.value }));
        }}>
          <MenuItem key='1' value={NavigationStyles.FULL}>{NavigationStyles.FULL}</MenuItem>
          <MenuItem key='2' value={NavigationStyles.SIMPLE}>{NavigationStyles.SIMPLE}</MenuItem>
        </TextField>
      </ListItem>
      <ListItem>
        <TextField select fullWidth value={role?.defaultAuthenticationConnection ?? 'NA'} label={t('organizationDetails.defaultAuthenticationConnectionTitle')} onChange={(e) => {
          setRole({ ...role, defaultAuthenticationConnection: e.target.value === 'NA' ? undefined : e.target.value });
        }}>
          <MenuItem key={'NA'} value={'NA'}>{t('roleModal.undefinedDefaultAuthenticationConnection')}</MenuItem>
          {Object.values(AuthenticationConnections).map((x: any) => (
            <MenuItem key={x} value={x}>{t(`organizationDetails.defaultAuthenticationConnectionOptions.${x}`)}</MenuItem>
          ))}
        </TextField>
      </ListItem>

      <ListItem>
        <PageConfigurationSelect
          filter={{ type: 'NON_INDIVIDUAL' }}
          value={role?.nonIndividualPageConfigurationId ?? ''}
          label={t('roleModal.nonIndividualPageConfiguration')}
          organizationId={role.organizationId ?? ''}
          onChange={(event: any) => setRole((prevRole) => ({ ...prevRole, nonIndividualPageConfigurationId: event.target.value })) }
        />
      </ListItem>
      <ListItem>
        <PageConfigurationSelect
          filter={{ type: 'INDIVIDUAL' }}
          value={role.individualPageConfigurationId ?? ''}
          label={t('roleModal.individualPageConfiguration')}
          organizationId={role.organizationId ?? ''}
          onChange={(event: any) => setRole((prevRole) => ({ ...prevRole, individualPageConfigurationId: event.target.value })) }
        />
      </ListItem>
      <ListItem>
        <PageConfigurationSelect
          filter={{ type: 'GOAL' }}
          value={role.goalPageConfigurationId ?? ''}
          label={t('roleModal.goalPageConfiguration')}
          organizationId={role.organizationId ?? ''}
          onChange={(event: any) => setRole((prevRole) => ({ ...prevRole, goalPageConfigurationId: event.target.value })) }
        />
      </ListItem>
      <ListItem>
        <PageConfigurationSelect
          filter={{ type: 'ACCOUNT' }}
          value={role.accountPageConfigurationId ?? ''}
          label={t('roleModal.accountPageConfiguration')}
          organizationId={role.organizationId ?? ''}
          onChange={(event: any) => setRole((prevRole) => ({ ...prevRole, accountPageConfigurationId: event.target.value })) }
        />
      </ListItem>
      <ListItem>
        <PageConfigurationSelect
          filter={{ type: 'SUB_ACCOUNT' }}
          value={role.subAccountPageConfigurationId ?? ''}
          label={t('roleModal.subAccountPageConfiguration')}
          organizationId={role.organizationId ?? ''}
          onChange={(event: any) => setRole((prevRole) => ({ ...prevRole, subAccountPageConfigurationId: event.target.value })) }
        />
      </ListItem>
      <ListItem>
        <PageConfigurationSelect
          filter={{ type: 'HOUSEHOLD' }}
          value={role.householdPageConfigurationId ?? ''}
          label={t('roleModal.householdPageConfiguration')}
          organizationId={role.organizationId ?? ''}
          onChange={(event: any) => setRole((prevRole) => ({ ...prevRole, householdPageConfigurationId: event.target.value })) }
        />
      </ListItem>

      <ListItem>
        <StaticPageWidgetConfigurationSelect
          filter={{ widgetType: 'INDIVIDUAL_TABLE' }}
          value={role.individualTableStaticPageWidgetConfigurationId ?? ''}
          label={t('roleModal.individualTableStaticPageWidgetConfiguration')}
          organizationId={role.organizationId ?? ''}
          onChange={(event: any) => setRole((prevRole) => ({ ...prevRole, individualTableStaticPageWidgetConfigurationId: event.target.value })) }
        />
      </ListItem>
      <ListItem>
        <StaticPageWidgetConfigurationSelect
          filter={{ widgetType: 'NON_INDIVIDUAL_TABLE' }}
          value={role.nonIndividualTableStaticPageWidgetConfigurationId ?? ''}
          label={t('roleModal.nonIndividualTableStaticPageWidgetConfiguration')}
          organizationId={role.organizationId ?? ''}
          onChange={(event: any) => setRole((prevRole) => ({ ...prevRole, nonIndividualTableStaticPageWidgetConfigurationId: event.target.value })) }
        />
      </ListItem>
      <ListItem>
        <StaticPageWidgetConfigurationSelect
          filter={{ widgetType: 'HOUSEHOLD_TABLE' }}
          value={role.householdTableStaticPageWidgetConfigurationId ?? ''}
          label={t('roleModal.householdTableStaticPageWidgetConfiguration')}
          organizationId={role.organizationId ?? ''}
          onChange={(event: any) => setRole((prevRole) => ({ ...prevRole, householdTableStaticPageWidgetConfigurationId: event.target.value })) }
        />
      </ListItem>

      <ListItem><Typography>{t('roleModal.permissions')}</Typography></ListItem>
      <ListItem>
        <PermissionsSelect permissions={role.permissions} onChange={(event: any) => {
          let permissions = [...role.permissions];
          if (event.target.checked) {
            permissions.push(event.target.value);
          } else {
            permissions = remove((x) => x === event.target.value, permissions);
          }
          setRole({ ...role, permissions });
        }} />
      </ListItem>
    </CreateNewModal>
  );
};

export default NewRole;

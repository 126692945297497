import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../1-primative';
import {
  Dialog, DialogContent, DialogTitle,
} from '../../../../2-component';

const EditApiToken = ({ apiTokenToView, open, handleClose }: { apiTokenToView: any, open: boolean, handleClose: () => void }) => {
  const { t } = useTranslation(['devSettings']);
  const [apiToken, setApiToken] = useState(apiTokenToView);
  const [localOpen, setLocalOpen] = useState(open);

  useEffect(() => {
    setLocalOpen(open);
    setApiToken(apiTokenToView);
  }, [apiTokenToView, open]);

  if (!apiToken) {
    return <></>;
  }

  return (
    <Dialog open={localOpen} onClose={handleClose}>
      <DialogTitle onClose={handleClose}>{t('viewApiToken')}</DialogTitle>
      <DialogContent>
        <Typography sx={{ overflowWrap: 'break-word' }}><b>Authorization-Api:</b> Bearer {apiToken.jwtToken}</Typography>
      </DialogContent>
    </Dialog>
  );
};

export default EditApiToken;

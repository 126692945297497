import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import { pick } from 'lodash/fp';
import { Box } from '../../../1-primative';
import {
  Button, ColorPickerField, Form, SettingsSwitch,
  TranslatableTextField,
} from '../../../2-component';
import { SettingsCard } from '../../../3-pattern';
import { UserContext } from '../../../../providers/userContextProvider';
import { useGlobalToast } from '../../../../providers/globalToastProvider';

const FETCH_ORGANIZATION = gql`
  query fetchOrganization($organizationId: String!) {
    fetchOrganization(organizationId: $organizationId) {
      organization {
        id
        name
        errorPageMessage {
          en
          fr
        }
        errorPageDescription {
          en
          fr
        }
        errorPageButtonText {
          en
          fr
        }
        errorPageBackgroundColor
        errorPageShowNavbar
      }
    }
  }
`;

const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        id
      }
    }
  }
`;

export const ErrorPageDesignerSettings = () => {
  const { activeOrganization } = useContext(UserContext);
  const { showToast } = useGlobalToast();
  const { t } = useTranslation(['settings', 'shared']);
  const [organization, setOrganization] = useState<any>(null);

  const { data, loading } = useQuery(FETCH_ORGANIZATION, {
    variables: { organizationId: activeOrganization?.id },
  });

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION, {
    variables: {
      input: {
        organizationId: activeOrganization?.id,
        errorPageMessage: pick(['en', 'fr'], organization?.errorPageMessage),
        errorPageDescription: pick(['en', 'fr'], organization?.errorPageDescription),
        errorPageButtonText: pick(['en', 'fr'], organization?.errorPageButtonText),
        errorPageBackgroundColor: organization?.errorPageBackgroundColor,
        errorPageShowNavbar: organization?.errorPageShowNavbar,
      },
    },
    onCompleted: async () => {
      showToast({
        message: t('shared:successfullySaved'),
        severity: 'success',
      });
    },
  });

  useEffect(() => {
    if (data?.fetchOrganization?.organization) {
      setOrganization(data?.fetchOrganization?.organization);
    }
  }, [data]);

  return (
    <SettingsCard
      title={t('errorPageDesigner.title')}
      description={t('errorPageDesigner.description')}
    >
      <Form onSubmit={updateOrganization}>
        <Box display='flex' flexDirection='column' gap={2}>
          <TranslatableTextField
            label={t('errorPageDesigner.errorPageMessage')}
            value={organization?.errorPageMessage ?? { en: '', fr: '' }}
            onChange={(e: any) => setOrganization({ ...organization, errorPageMessage: e })}
            fullWidth
            testId='error-designer-message'
          />
          <TranslatableTextField
            label={t('errorPageDesigner.errorPageDescription')}
            value={organization?.errorPageDescription ?? { en: '', fr: '' }}
            onChange={(e: any) => setOrganization({ ...organization, errorPageDescription: e })}
            fullWidth
            testId='error-designer-description'
          />
          <TranslatableTextField
            label={t('errorPageDesigner.errorPageButtonText')}
            value={organization?.errorPageButtonText ?? { en: '', fr: '' }}
            onChange={(e: any) => setOrganization({ ...organization, errorPageButtonText: e })}
            fullWidth
            testId='error-designer-button-label'
          />
          <ColorPickerField
            fullWidth
            label={t('errorPageDesigner.errorPageBackgroundColor')}
            value={organization?.errorPageBackgroundColor ?? '#000000'}
            testId='error-designer-page-background-color'
            onChange={(e) => setOrganization(
              {
                ...organization,
                errorPageBackgroundColor: e,
              },
            )}
          />
          <SettingsSwitch
            label={t('errorPageDesigner.errorPageShowNavbar')}
            checked={organization?.errorPageShowNavbar ?? true}
            onChange={async (event: any) => setOrganization({ ...organization, errorPageShowNavbar: event })}
            testId='error-designer-nav-bar-switch'
          />
          <Box display='flex' justifyContent='flex-end'>
            <Button type='submit' disabled={loading} label={t('shared:save')} dataTestId='error-designer-submit-button'/>
          </Box>
        </Box>
      </Form>
    </SettingsCard>
  );
};

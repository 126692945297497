import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useState, useContext } from 'react';
import { Add, Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Skeleton } from '../../../1-primative';
import {
  Table, TableHeadCell, TableRow, TableCell, TableBody, Pagination, Dialog, DialogContent, DialogTitle, IconButton, Button,
  CardContent, Card,
} from '../../../2-component';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import { usePageState } from '../../../../util/usePageState';
import { UpdateOrganizationSwitch } from '../../../4-module/updateOrganizationSwitch/updateOrganizationSwitch';
import { SettingsTitle } from '../../../3-pattern';
import StaticPageWidgetConfigurationForm from '../../../4-module/staticPageWidgetConfigurationForm/staticPageWidgetConfigurationForm';

const FETCH_STATIC_PAGE_WIDGET_CONFIGURATIONS = gql`
  query fetchStaticPageWidgetConfigurations($input: FetchStaticPageWidgetConfigurationsInput!) {
    fetchStaticPageWidgetConfigurations(input: $input) {
      staticPageWidgetConfigurations {
        id
        name
        createdAt
        widget { id type options }
        organization { name }
      }
      totalCount
    }
  }
`;

const PAGE_SIZE = 50;

export const StaticPageWidgetConfigurationsSettings = () => {
  const navigate = useNavigate();
  const { permissions } = usePermissions();
  const { t } = useTranslation(['orgSettings', 'shared', 'settings']);
  const { activeOrganization } = useContext(UserContext);
  const [page, setPage] = usePageState(1, 'page');
  const [open, setOpen] = useState(false);

  const {
    loading, data, refetch, previousData,
  } = useQuery(FETCH_STATIC_PAGE_WIDGET_CONFIGURATIONS, {
    variables: {
      input: {
        filter: { organizationId: activeOrganization.id },
        pagination: {
          sortField: 'createdAt', sortDesc: true, perPage: PAGE_SIZE, offSet: (page - 1) * PAGE_SIZE,
        },
      },
    },
  });

  return (
    <>
      <SettingsTitle title={t('settings:staticPageWidgetConfigurations.title')} description={t('settings:staticPageWidgetConfigurations.description')} />
      <Card loading={loading}>
        {permissions.includes('write:page_configurations') && (
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
                {permissions.includes('write:advanced_organization_settings') && (
                  <UpdateOrganizationSwitch
                    field='useParentStaticPageWidgetConfigurations'
                    label={t('settings:staticPageWidgetConfigurations.useParentStaticPageWidgetConfigurations')}
                    organizationId={activeOrganization.id}
                    refetchQueries={[FETCH_STATIC_PAGE_WIDGET_CONFIGURATIONS]}
                  />
                )}
              </Box>
              <Button label={t('staticPageWidgetConfigurationTable.add')} leadingIcon={Add} onClick={() => setOpen(true)} />
            </Box>
          </CardContent>
        )}
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableHeadCell>{t('staticPageWidgetConfigurationTable.name')}</TableHeadCell>
              <TableHeadCell>{t('staticPageWidgetConfigurationTable.widgetType')}</TableHeadCell>
              <TableHeadCell align="right">{t('staticPageWidgetConfigurationTable.organization')}</TableHeadCell>
            </TableRow>

            {loading && !previousData && [...Array(15)].map((_x: any, i: number) => (
              <TableRow key={i}>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
              </TableRow>
            ))}

            {data?.fetchStaticPageWidgetConfigurations?.staticPageWidgetConfigurations?.map((staticPageWidgetConfiguration: any) => (
              <TableRow hover pointer
                key={staticPageWidgetConfiguration.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                data-testid={`pageconfiguration-${staticPageWidgetConfiguration.id}`}
                onClick={() => navigate(`${staticPageWidgetConfiguration.id}`)}
              >
                <TableCell component="th" scope="row">{staticPageWidgetConfiguration.name}</TableCell>
                <TableCell>{staticPageWidgetConfiguration.widget.type}</TableCell>
                <TableCell align="right">{staticPageWidgetConfiguration.organization.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={Math.ceil(((data || previousData)?.fetchStaticPageWidgetConfigurations?.totalCount ?? 0) / PAGE_SIZE)}
          page={page}
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{ p: 1, textAlign: 'right', '.MuiPagination-ul': { justifyContent: 'end' } }}
        />

        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='xs'>
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant='headingSmall'>{t('staticPageWidgetConfigurationModal.title')}</Typography>
              <IconButton onClick={() => setOpen(false)}><Close /></IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <StaticPageWidgetConfigurationForm defaultOrg={activeOrganization.id} afterSubmit={() => {
              refetch();
              setOpen(false);
            }} />
          </DialogContent>
        </Dialog>
      </Card>
    </>
  );
};

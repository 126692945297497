import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import { isEmpty } from 'lodash/fp';
import { Box } from '../../../1-primative';
import {
  Button, Form, TextField,
} from '../../../2-component';
import { OrganizationSelect, SettingsCard } from '../../../3-pattern';
import { UserContext } from '../../../../providers/userContextProvider';
import { useGlobalToast } from '../../../../providers/globalToastProvider';
import { FeatureFlag, useFeatureFlags } from '../../../4-module/featureFlag/featureFlag';

const FETCH_ORGANIZATION = gql`
  query fetchOrganization($organizationId: String!) {
    fetchOrganization(organizationId: $organizationId) {
      organization {
        id
        name
        entityName
        parent {
          id
          name
        }
        supportUrl
        helpCentreUrl
      }
    }
  }
`;

const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        id
      }
    }
  }
`;

export const GeneralSettings = () => {
  const { activeOrganization, userContext } = useContext(UserContext);
  const { showToast } = useGlobalToast();
  const { t } = useTranslation(['settings', 'shared']);
  const [organization, setOrganization] = useState<any>(null);
  const [changedParent, setChangedParent] = useState<boolean>(false);
  const {
    featureFlags, updateFeatureFlag, isFeatureFlagEnabled, saveFeatureFlags,
  } = useFeatureFlags();

  const { data, loading } = useQuery(FETCH_ORGANIZATION, {
    variables: { organizationId: activeOrganization?.id },
  });

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION, {
    variables: {
      input: {
        organizationId: activeOrganization?.id,
        name: organization?.name,
        entityName: organization?.entityName,
        parentId: changedParent ? organization?.parent?.id : undefined,
        supportUrl: isEmpty(organization?.supportUrl) ? undefined : organization?.supportUrl,
        helpCentreUrl: isEmpty(organization?.helpCentreUrl) ? undefined : organization?.helpCentreUrl,
      },
    },
    onCompleted: async () => {
      await saveFeatureFlags();
      showToast({
        message: t('shared:successfullySaved'),
        severity: 'success',
      });
    },
  });

  useEffect(() => {
    if (data?.fetchOrganization?.organization) {
      setOrganization(data?.fetchOrganization?.organization);
    }
  }, [data]);

  return (
    <SettingsCard
      title={t('general.title')}
      description={t('general.description')}
    >
      <Form onSubmit={updateOrganization}>
        <Box display='flex' flexDirection='column' gap={2}>
          <TextField
            label={t('general.organizationName')}
            value={organization?.name}
            onChange={(e: any) => setOrganization({ ...organization, name: e.target.value })}
            fullWidth
            testId='settings-general-organization-name'
          />
          <OrganizationSelect value={organization?.parent?.id} onChange={(e: any) => {
            setOrganization({ ...organization, parent: { id: e } });
            setChangedParent(true);
          } } label={t('general.parent')} organizationId={userContext?.organization?.id} />
          <TextField
            label={t('general.entityName')}
            value={organization?.entityName}
            onChange={(e: any) => setOrganization({ ...organization, entityName: e.target.value })}
            fullWidth
            testId='settings-general-entity-name'
          />
          <TextField
            label={t('general.supportUrl')}
            value={organization?.supportUrl}
            onChange={(e: any) => setOrganization({ ...organization, supportUrl: e.target.value })}
            fullWidth
            testId='settings-general-support-url'
          />
          <FeatureFlag featureFlag={featureFlags.find((ff) => ff.key === 'VIEW_HELP_CENTER')} onChange={updateFeatureFlag} testId='settings-general-help-center-toggle'/>
          { isFeatureFlagEnabled('VIEW_HELP_CENTER') && (
            <TextField
              label={t('general.helpCentreUrl')}
              value={organization?.helpCentreUrl}
              onChange={(e: any) => setOrganization({ ...organization, helpCentreUrl: e.target.value })}
              fullWidth
              testId='settings-general-help-centre-url'
            />
          )}
          <Box display='flex' justifyContent='flex-end'>
            <Button type='submit' disabled={loading} label={t('shared:save')} dataTestId='settings-general-submit-button'/>
          </Box>
        </Box>
      </Form>
    </SettingsCard>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect, useState } from 'react';
import {
  gql,
  useMutation,
  useQuery,
} from '@apollo/client';
import { isEmpty } from 'lodash/fp';
import { UserContext, usePermissions } from 'providers/userContextProvider';
import { SubStepTypes } from 'interfaces/workflow';
import { CustomField, FETCH_CUSTOM_FIELDS, modifyUpdatingCustomFields } from 'interfaces/customField';
import { CountryCodes } from '@onevesthq/ov-enums';
import { PersonalInformationVisual } from './personalInformation.visual';
import { EnabledJurisdictions } from '../../../../../interfaces';
import { WorkflowContext } from '../../workflowCompletion';
import { getEnabledCustomFields } from '../utils';
import { Skeleton } from '../../../../1-primative';

const FETCH_USER_PERSONAL_INFO = (derivedCustomFields?: string[], enableCustodianCustomFields?: boolean) => gql`
  query fetchUserPersonalInfo($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        firstName
        middleName
        lastName
        primaryEmail
        phone
        dateOfBirth
        language
        preferredMethodOfCommunication
        inProvinceSince
        gender
        physicalAddress { city jurisdiction streetName postal unitNumber houseNumber neighborhood country }
        ${derivedCustomFields ? `customFields(keys: ${JSON.stringify(derivedCustomFields)}) {
          key
          value
          customField { id translatedName { en fr } translatedDescription{ en fr} type format}
          selectedOptions { value displayText { en fr } }
        }` : ''}
        ${enableCustodianCustomFields ? 'custodianCustomFields { key value }' : ''}
        organization {
          jurisdictions { all only }
          applicableLocalization { countries }
        }
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;

const UPDATE_CUSTODIAN_CUSTOM_FIELDS = gql`
mutation UpdateCustodianCustomFields($input: UpdateCustodianCustomFieldsInput!) {
  updateCustodianCustomFields(input: $input) {
    custodianCustomFields {
      key
      value
    }
  }
}
`;

export const PersonalInformation = ({
  options, userId, onNext, stepLoading, grid = false, updateMode = false, workflowCompletion,
}: {
  options: any, userId: string, onNext: () => void, stepLoading: boolean, grid?: boolean, updateMode?: boolean,
  workflowCompletion?: any,
}) => {
  const { activeOrganization } = useContext(UserContext);
  const { workflowData, setWorkflowData } = useContext(WorkflowContext);
  const [derivedCustomFields, setDerivedCustomFields] = useState<CustomField[]>();
  const [derivedCustomFieldsKeys, setDerivedCustomFieldsKeys] = useState<string[]>();
  const { permissions } = usePermissions();
  const enabledCustomFields = getEnabledCustomFields(options);
  const { custodianConnection } = useContext(UserContext);
  const enableCustodianCustomFields = custodianConnection?.enableFetchCustodianCustomFields;
  const [userData, setUserData] = useState<any>({
    firstName: '',
    middleName: '',
    lastName: '',
    primaryEmail: '',
    phone: '',
    dateOfBirth: '',
    language: '',
    preferredMethodOfCommunication: '',
    inProvinceSince: '',
    gender: '',
    physicalAddress: {
      city: '',
      Jurisdiction: '',
      streetName: '',
      postal: '',
      unitNumber: '',
      houseNumber: '',
      neighborhood: '',
      country: '',
    },
    customFields: [],
  });
  const [applicableJurisdictions, setApplicableJurisdictions] = useState<EnabledJurisdictions>({ all: true });
  const [applicableCountries, setApplicableCountries] = useState<CountryCodes[]>([]);

  const customFieldsPermitted = permissions.includes('read:custom_fields') && enabledCustomFields.length !== 0;

  // loading 1 - custom fields
  const { loading: customFieldsLoading } = useQuery(FETCH_CUSTOM_FIELDS, {
    variables: {
      input: {
        filter: {
          workflowStep: SubStepTypes.PERSONAL_INFORMATION,
          organizationId: activeOrganization?.id ?? undefined,
          keys: !isEmpty(enabledCustomFields) ? enabledCustomFields : undefined,
        },
        pagination: {
          perPage: 100,
        },
      },
    },
    skip: !customFieldsPermitted,
    onCompleted: async (res: any) => {
      setDerivedCustomFields(res?.fetchCustomFields?.customFields);
      const customFieldKeys = res?.fetchCustomFields?.customFields?.map((a: any) => (a.key));
      setDerivedCustomFieldsKeys(customFieldKeys);
    },
  });

  // loading 2 - user's personal info
  const { data, loading } = useQuery(FETCH_USER_PERSONAL_INFO(derivedCustomFieldsKeys, enableCustodianCustomFields), {
    variables: { userId },
    fetchPolicy: 'no-cache',
    skip: customFieldsLoading,
  });

  useEffect(() => {
    if (userId && !workflowData.currentUserId) {
      setWorkflowData({
        ...workflowData,
        currentUserId: userId,
      });
    }
  }, [userId, workflowData]);

  const [updateCustodianCustomFieldsMutation, { loading: updateCustodianCustomFieldsLoading }] = useMutation(UPDATE_CUSTODIAN_CUSTOM_FIELDS);

  const [updateUserMutation, { loading: updateUserLoading }] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      onNext();
    },
    onError: () => {},
  });

  const updateUser = async () => {
    if (enableCustodianCustomFields) {
      await updateCustodianCustomFieldsMutation({
        variables: {
          input: {
            filter: {
              userId,
              custodianCustomFields: modifyUpdatingCustomFields(
                userData?.customFields,
                derivedCustomFields ?? [],
                derivedCustomFieldsKeys,
              ),
            },
          },
          skipErrorHandler: true,
        },
      });
    }

    await updateUserMutation({
      variables: {
        input: {
          ...userData,
          physicalAddress: isEmpty(userData.physicalAddress) ? undefined : userData.physicalAddress,
          customFields: enableCustodianCustomFields
            ? []
            : modifyUpdatingCustomFields(userData?.customFields, derivedCustomFields ?? [], derivedCustomFieldsKeys),
          userId,
        },
        skipErrorHandler: true,
      },
    });
  };

  useEffect(() => {
    if (data) {
      const combinedCustomFields = data.fetchUser?.user?.customFields || [];
      if (data.fetchUser?.user?.custodianCustomFields) {
        combinedCustomFields.push(...(data.fetchUser?.user?.custodianCustomFields || []));
      }

      setUserData({
        firstName: data.fetchUser.user.firstName || undefined,
        middleName: data.fetchUser.user.middleName || undefined,
        lastName: data.fetchUser.user.lastName || undefined,
        primaryEmail: data.fetchUser.user.primaryEmail || undefined,
        phone: data.fetchUser.user.phone || undefined,
        dateOfBirth: data.fetchUser.user.dateOfBirth || undefined,
        language: data.fetchUser.user.language || undefined,
        preferredMethodOfCommunication: data.fetchUser.user.preferredMethodOfCommunication || undefined,
        inProvinceSince: data.fetchUser.user.inProvinceSince || undefined,
        gender: data.fetchUser.user.gender || undefined,
        physicalAddress: data.fetchUser.user.physicalAddress ? {
          city: data.fetchUser.user.physicalAddress?.city || undefined,
          jurisdiction: data.fetchUser.user.physicalAddress?.jurisdiction || undefined,
          streetName: data.fetchUser.user.physicalAddress?.streetName || undefined,
          postal: data.fetchUser.user.physicalAddress?.postal || undefined,
          unitNumber: data.fetchUser.user.physicalAddress?.unitNumber || undefined,
          houseNumber: data.fetchUser.user.physicalAddress?.houseNumber || undefined,
          neighborhood: data.fetchUser.user.physicalAddress?.neighborhood || undefined,
          country: data.fetchUser.user.physicalAddress?.country || undefined,
        } : {},
        customFields: combinedCustomFields,
      });
      setApplicableJurisdictions(data.fetchUser?.user?.organization?.jurisdictions);
      setApplicableCountries(data.fetchUser?.user?.organization?.applicableLocalization?.countries);
    }
  }, [data]);

  return (
    <>
      {customFieldsLoading || loading || updateUserLoading || stepLoading || updateCustodianCustomFieldsLoading ? (
        <>
          <Skeleton width="200px" height="33px" variant="text" data-testid="custom-field-skeleton" />
          <Skeleton width="100px" height="19px" variant="text" data-testid="custom-field-skeleton" />
        </>
      ) : (
        <PersonalInformationVisual
          options={options}
          userData={userData}
          updateUser={setUserData}
          continueFunc={updateUser}
          loading={customFieldsLoading || loading || updateUserLoading || stepLoading}
          grid={grid}
          updateMode={updateMode}
          activeCustomFields={derivedCustomFieldsKeys}
          applicableJurisdictions={applicableJurisdictions}
          applicableCountries={applicableCountries}
          workflowCompletion={workflowCompletion}
        />
      )}
    </>
  );
};

export default PersonalInformation;

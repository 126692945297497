import { useTranslation } from 'react-i18next';
import { CountryCodes } from '@onevesthq/ov-enums';
import { eligibleTaxIdTypes, taxIdFormattingMask } from '../../../../../interfaces';
import { Typography, Box } from '../../../../1-primative';
import {
  Card, Table, TableCell, TableHeadCell, TableRow,
} from '../../../../2-component';
import { getCountryName } from '../../../../resources';

export const TaxIdDetails = ({ applicableCountries }: { applicableCountries: CountryCodes[] }) => {
  const { t } = useTranslation(['orgSettings']);

  return (
    <Box>
      <Typography variant='labelSmall' colorVariant='variant' mb={0.5}>{t('organizationDetails.taxIdDetails')}</Typography>
      <Card variant='outlined'>
        <Table>
          <TableRow>
            <TableHeadCell>{t('shared:country')}</TableHeadCell>
            <TableHeadCell>{t('organizationDetails.clientType')}</TableHeadCell>
            <TableHeadCell>{t('shared:type')}</TableHeadCell>
            <TableHeadCell>{t('organizationDetails.formatting')}</TableHeadCell>
          </TableRow>
          {applicableCountries?.map((country) => {
            const typesIndividual = eligibleTaxIdTypes(country, true);
            const typesNonIndividual = eligibleTaxIdTypes(country, false);
            const count = typesIndividual.length + typesNonIndividual.length;

            return (count > 0)
              ? <>
                {typesIndividual?.map((type, index) => <TableRow key={index}>
                  {index === 0 && <TableCell rowSpan={count}>{getCountryName(country)}</TableCell>}
                  {index === 0 && <TableCell rowSpan={typesIndividual.length}>{t('entityTypes:INDIVIDUAL')}</TableCell>}
                  <TableCell>{t(`taxId:${type}`)} ({t(`taxId:abbreviated.${type}`)})</TableCell>
                  <TableCell>{taxIdFormattingMask(type)}</TableCell>
                </TableRow>)}
                {typesNonIndividual?.map((type, index) => <TableRow key={index}>
                  {index === 0 && <TableCell rowSpan={typesNonIndividual.length}>{t('entityTypes:NON_INDIVIDUAL')}</TableCell>}
                  <TableCell>{t(`taxId:${type}`)} ({t(`taxId:abbreviated.${type}`)})</TableCell>
                  <TableCell>{taxIdFormattingMask(type)}</TableCell>
                </TableRow>)}
              </>
              : <>
                <TableRow>
                  <TableCell>{getCountryName(country)}</TableCell>
                  <TableCell colSpan={3} sx={{ textAlign: 'center' }} align='center'><i>{t('organizationDetails.notImplemented')}</i></TableCell>
                </TableRow>
              </>;
          })}
        </Table>
      </Card>
    </Box>
  );
};

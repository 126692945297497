import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Box } from '../../../1-primative';
import {
  Button, Form, SettingsSwitch,
} from '../../../2-component';
import { SettingsCard } from '../../../3-pattern';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import { useGlobalToast } from '../../../../providers/globalToastProvider';
import { FeatureFlag, useFeatureFlags } from '../../../4-module/featureFlag/featureFlag';

const FETCH_ORGANIZATION = gql`
  query fetchOrganization($organizationId: String!) {
    fetchOrganization(organizationId: $organizationId) {
      organization {
        id
        name
        useParentProductShelf
        useParentModelPortfolios
        useParentThemes
        allowPortfolioPerGoal
        allowPendingTransactions
        disableStatsUpdateBasedOnTransactions
      }
    }
  }
`;

const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        id
      }
    }
  }
`;

export const PortfolioManagementSettings = () => {
  const { activeOrganization } = useContext(UserContext);
  const { showToast } = useGlobalToast();
  const { permissions } = usePermissions();
  const { t } = useTranslation(['settings', 'shared']);
  const [organization, setOrganization] = useState<any>(null);
  const {
    featureFlags, updateFeatureFlag, saveFeatureFlags,
  } = useFeatureFlags();

  const { data, loading } = useQuery(FETCH_ORGANIZATION, {
    variables: { organizationId: activeOrganization?.id },
  });

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION, {
    variables: {
      input: {
        organizationId: activeOrganization?.id,
        useParentProductShelf: organization?.useParentProductShelf,
        useParentModelPortfolios: organization?.useParentModelPortfolios,
        useParentThemes: organization?.useParentThemes,
        allowPortfolioPerGoal: organization?.allowPortfolioPerGoal,
        allowPendingTransactions: organization?.allowPendingTransactions,
        disableStatsUpdateBasedOnTransactions: organization?.disableStatsUpdateBasedOnTransactions,
      },
    },
    onCompleted: async () => {
      await saveFeatureFlags();
      showToast({
        message: t('shared:successfullySaved'),
        severity: 'success',
      });
    },
  });

  useEffect(() => {
    if (data?.fetchOrganization?.organization) {
      setOrganization(data?.fetchOrganization?.organization);
    }
  }, [data]);

  return (
    <SettingsCard
      title={t('portfolioManagementSettings.title')}
      description={t('portfolioManagementSettings.description')}
    >
      <Form onSubmit={updateOrganization}>
        <Box display='flex' flexDirection='column' gap={2}>
          <SettingsSwitch
            label={t('portfolioManagementSettings.useParentProductShelf')}
            checked={organization?.useParentProductShelf}
            onChange={(e: any) => setOrganization({ ...organization, useParentProductShelf: e })}
            testId='settings-pms-useParentProductShelf'
            underline
          />
          <SettingsSwitch
            label={t('portfolioManagementSettings.useParentModelPortfolios')}
            checked={organization?.useParentModelPortfolios}
            onChange={(e: any) => setOrganization({ ...organization, useParentModelPortfolios: e })}
            testId='settings-pms-useParentModelPortfolios'
            underline
          />
          <SettingsSwitch
            label={t('portfolioManagementSettings.useParentThemes')}
            checked={organization?.useParentThemes}
            onChange={(e: any) => setOrganization({ ...organization, useParentThemes: e })}
            testId='settings-pms-useParentThemes'
            underline
          />
          <SettingsSwitch
            label={t('portfolioManagementSettings.allowPortfolioPerGoal')}
            checked={organization?.allowPortfolioPerGoal}
            onChange={(e: any) => setOrganization({ ...organization, allowPortfolioPerGoal: e })}
            testId='settings-pms-allowPortfolioPerGoal'
            underline
          />
          <SettingsSwitch
            label={t('portfolioManagementSettings.allowPendingTransactions')}
            checked={organization?.allowPendingTransactions}
            onChange={(e: any) => setOrganization({ ...organization, allowPendingTransactions: e })}
            testId='settings-pms-allowPendingTransactions'
            underline
          />
          <SettingsSwitch
            label={t('portfolioManagementSettings.disableStatsUpdateBasedOnTransactions')}
            checked={organization?.disableStatsUpdateBasedOnTransactions}
            onChange={(e: any) => setOrganization({ ...organization, disableStatsUpdateBasedOnTransactions: e })}
            testId='settings-pms-disableStatsUpdateBasedOnTransactions'
            underline
          />
          { permissions.includes('write:advanced_organization_settings') && (
            <>
              <FeatureFlag
                featureFlag={featureFlags.find((ff) => ff.key === 'FIX_TRADING')}
                onChange={updateFeatureFlag}
                testId='settings-general-help-center-toggle'
                underline
              />
              <FeatureFlag
                featureFlag={featureFlags.find((ff) => ff.key === 'TAX_LOSS_HARVESTING')}
                onChange={updateFeatureFlag}
                testId='settings-general-help-center-toggle'
              />
            </>
          )}
          <Box display='flex' justifyContent='flex-end'>
            <Button type='submit' disabled={loading} label={t('shared:save')} dataTestId='settings-general-submit-button'/>
          </Box>
        </Box>
      </Form>
    </SettingsCard>
  );
};

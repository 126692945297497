import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isUndefined } from 'lodash/fp';
import { TabPanel } from '../../../../components/tabs/ovTabs';
import { usePageState } from '../../../../util/usePageState';
import { Box, Typography } from '../../../1-primative';
import {
  Tabs, Tab, CardContent, Card, Button,
  TextField,
} from '../../../2-component';
import { DocumentList } from './components/documentList';
import { Agreements } from './components/agreements';
import { UploadedDocuments } from './components/uploadedDocuments';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';
import { PageObjectType } from '../../../5-page';
import { translateBackend } from '../../../../assets/i18n/config';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import { UploadDocument } from './components/uploadDocument';
import { ClientReports } from './components/clientReports';
import { DocumentFilter, DocumentFilterOptions } from './components/documentFilter';

const accountStatementsTypes = [
  'MANAGEMENT_FEES_ANNUAL_REPORT',
  'ADMINISTRATIVE_FEES_ANNUAL_REPORT',
  'ANNUAL_PERFORMANCE_REPORT',
  'OV_MONTHLY_ACCOUNT_STATEMENTS',
  'MONTHLY_ACCOUNT_STATEMENTS',
  'ANNUAL_ACCOUNT_STATEMENTS',
];

const taxStatementsTypes = [
  'TAX_SLIPS_LAST_10_MONTHS',
  'TAX_SLIPS_FIRST_60_DAYS',
  'TAX_SLIPS_T4RSP_RL2',
  'TAX_SLIPS_T4RIF_RL2',
  'TAX_SLIPS_T4A_RL1',
  'TAX_SLIPS_NR4',
  'TAX_SLIPS_T5008',
  'TAX_SLIPS_TRADING_SUMMARY',
  'TAX_SLIPS_T5_RL3',
  'TAX_SLIPS_T3_RL16',
  'TAX_SLIPS_T3_BREAKDOWN',
  'TAX_SLIPS_T5013_RL15',
  'TAX_SLIP_GENERAL',
  'TAX_SLIPS_T4_FHSA',
];

const TRADE_CONFIRMATION_TYPES = ['TRADE_CONFIRMATION'];
const UPLOADED_DOCUMENTS_TYPES = ['OTHER', 'BANKING', 'ID_VERIFICATION'];

export const Documents = ({
  objectId, objectType, options = {}, userId,
}: { objectId: string; objectType: PageObjectType; options?: any; userId?: string }) => {
  const { t } = useTranslation(['document']);
  const { permissions } = usePermissions();
  const { sys } = useThemeTokens();
  const { userId: contextUserId, activeOrganization } = useContext(UserContext);
  const [tab, setTab] = usePageState('statements', 'documentsTab');
  const [open, setOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [dateStart, setDateStart] = useState<string>();
  const [dateEnd, setDateEnd] = useState<string>();

  const isDocumentPage = [PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL, PageObjectType.ACCOUNT, PageObjectType.HOUSEHOLD].includes(objectType);
  const clientReportFeatureFlag = activeOrganization.availableFeatureFlags?.includes('CONFIGURABLE_CLIENT_REPORTS');
  const isDeleteble = permissions.includes('write:files') && options.showDeleteDocument;

  return (
    <Box width='100%'>
      <Typography mb={2} variant='headingSmall'>{options.customTitle ? translateBackend(options.customTitle) : t('document:header')}</Typography>
      <Card>
        <Box display='flex' justifyContent='space-between' alignItems='end' pt={1} px={3} borderBottom={`1px solid ${sys.color.outline}`}>
          <Tabs scrollButtons='auto' value={tab} sx={{ overflow: 'auto' }} onChange={(_e: any, newValue: any) => setTab(newValue)}>
            <Tab label={t('document:tabs.statementsTab.title')} value='statements' />
            <Tab label={t('document:tabs.taxDocumentsTab.title')} value='taxDocuments' />
            {(isUndefined(options.showAgreements) || options.showAgreements) && <Tab label={t('document:tabs.agreementsTab.title')} value='agreements' />}
            {(isUndefined(options.showTradeConfirmations) || options.showTradeConfirmations) && <Tab label={t('document:tabs.tradeConfirmationsTab.title')} value='tradeConfirmations' />}
            {isDocumentPage && (isUndefined(options.showUploadedDocuments) || options.showUploadedDocuments) && (
              <Tab label={t('document:tabs.uploadedDocuments.title')} value='uploadedDocuments' />
            )}
            {(options.showClientReports && clientReportFeatureFlag) && <Tab label={t('document:tabs.clientReportsTab.title')} value='clientReports' />}
          </Tabs>

          {(options.showFilters) && (
            <Box display='flex' justifyContent='flex-end' flexWrap='wrap' alignItems='center' marginLeft='auto'>
              <TextField
                value={searchText}
                onChange={(event: any) => {
                  setSearchText(event.target.value);
                }}
                leadingIcon='search'
                size='small'
              />
              <Box display='flex'>
                <DocumentFilter
                  filter={{ dateStart, dateEnd }}
                  setFilter={(filter: DocumentFilterOptions) => {
                    setDateStart(filter.dateStart);
                    setDateEnd(filter.dateEnd);
                  }}
                />
              </Box>
          </Box>
          )}

          {isDocumentPage && (isUndefined(options.allowToUploadDocuments) || options.allowToUploadDocuments) && (
            <Button size='sm' label={t('document:button.uploadDocumentsMobile')} sx={{ mb: 1, mt: { xs: 1, sm: 0 } }} onClick={() => {
              if (isUndefined(options.showUploadedDocuments) || options.showUploadedDocuments) setTab('uploadedDocuments');
              setOpen(true);
            }} />
          )}
        </Box>

        <CardContent>
          <TabPanel value={tab} index='statements'>
            <DocumentList objectId={objectId} objectType={objectType} documentTypes={accountStatementsTypes} options={options} filters={{ searchText, dateStart, dateEnd }} />
          </TabPanel>
          <TabPanel value={tab} index='taxDocuments'>
            <DocumentList objectId={objectId} objectType={objectType} documentTypes={taxStatementsTypes} options={options} filters={{ searchText, dateStart, dateEnd }} />
          </TabPanel>
          {(isUndefined(options.showAgreements) || options.showAgreements) && (
            <TabPanel value={tab} index='agreements'>
              <Agreements objectId={objectId} objectType={objectType} documentTypes={[]} filters={{ searchText, dateStart, dateEnd }} />
            </TabPanel>
          )}
          {(isUndefined(options.showTradeConfirmations) || options.showTradeConfirmations) && (
            <TabPanel value={tab} index='tradeConfirmations'>
              <DocumentList objectId={objectId} objectType={objectType} documentTypes={TRADE_CONFIRMATION_TYPES} options={options} filters={{ searchText, dateStart, dateEnd }} />
            </TabPanel>
          )}
          {isDocumentPage && (isUndefined(options.showUploadedDocuments) || options.showUploadedDocuments) && (
            <TabPanel value={tab} index='uploadedDocuments'>
              <UploadedDocuments
                isDeletable={isDeleteble}
                objectId={objectId}
                documentTypes={UPLOADED_DOCUMENTS_TYPES}
                open={open} setOpen={setOpen}
                objectType={objectType}
                userId={userId || contextUserId}
                filters={{ searchText, dateStart, dateEnd }}
                options={options}
              />
            </TabPanel>
          )}
          {(options.showClientReports) && (
            <TabPanel value={tab} index='clientReports'>
              <ClientReports objectId={objectId} objectType={objectType} />
            </TabPanel>
          )}
        </CardContent>
      </Card>
      {(isUndefined(options.allowToUploadDocuments) || options.allowToUploadDocuments) && !(isUndefined(options.showUploadedDocuments) || options.showUploadedDocuments) && (
        <UploadDocument open={open} setOpen={setOpen} refetch={() => {}} objectId={objectId} objectType={objectType} userId={userId} options={options} />
      )}
    </Box>
  );
};

import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '../../../1-primative';
import {
  Button, Dialog, DialogContent, DialogFooter, DialogTitle, MenuItem, SegmentedControl,
  SelectField,
  TextField,
} from '../../../2-component';
import ColumnsSelect from '../../../4-module/reportBuilder/components/columnsSelect';
import EditFilters from '../../../4-module/reportBuilder/components/editFilters';

const FETCH_REPORT_OPTIONS = gql`
  query fetchReportOptions($input: FetchReportOptionsInput!) {
    fetchReportOptions(input: $input) {
      filters {
        field
        comparisons {
          comparison
          valueTypes
          options
        }
      }
      columns
    }
  }
`;

export const WidgetModal = ({
  open, setOpen, activeWidget, updateWidget, removeWidget,
}: {
  open: boolean, setOpen: (open: boolean) => void, activeWidget: any, updateWidget: (widget: any) => void, removeWidget: () => void,
}) => {
  const [widget, setWidget] = useState(activeWidget);
  const [tab, setTab] = useState('chart');
  const { t } = useTranslation(['customReports', 'shared']);

  const { loading: optionsLoading, data: optionsData } = useQuery(FETCH_REPORT_OPTIONS, {
    variables: {
      input: {
        type: widget?.reportType,
      },
    },
    skip: !widget?.reportType,
  });

  useEffect(() => {
    setWidget(activeWidget);
  }, [activeWidget]);

  if (!widget) {
    return null;
  }

  const mapGroupingType = (type: string) => {
    switch (type) {
      case 'USER':
      case 'ACCOUNT':
      case 'SUB_ACCOUNT':
      case 'GOAL':
      case 'CLIENT_GROUP':
      case 'DAILY_STATS':
        return 'marketValueCents';
      case 'TRANSFER':
        return 'amountCents';
      case 'TRANSACTION':
        return 'valueCents';
      default:
        return 'valueCents';
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth='sm'
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle onClose={() => setOpen(false)}>
          {t('editReport')}
      </DialogTitle>
      <DialogContent>
        <SegmentedControl
          sx={{ mb: 2 }}
          value={tab}
          segments={[{ label: t('chart'), value: 'chart' }, { label: t('filters'), value: 'filters' }, { label: t('columns'), value: 'columns' }]}
          onChange={(val: any) => setTab(val)}
        />
        <Box display={ tab === 'chart' ? 'block' : 'none' }>
          <TextField fullWidth value={widget.title} onChange={(e: any) => setWidget({ ...widget, title: e.target.value })} label={t('title')} />
          <SelectField
            sx={{ mt: 2 }}
            fullWidth
            label={t('reportType')}
            value={widget.reportType}
            onChange={(e: any) => setWidget({
              ...widget, filters: [], columns: [], reportType: e.target.value, grouping: { valueField: mapGroupingType(e.target.value) },
            })}
          >
            <MenuItem value='USER'>{t('reportTypes.USER')}</MenuItem>
            <MenuItem value='ACCOUNT'>{t('reportTypes.ACCOUNT')}</MenuItem>
            <MenuItem value='SUB_ACCOUNT'>{t('reportTypes.SUB_ACCOUNT')}</MenuItem>
            <MenuItem value='TRANSFER'>{t('reportTypes.TRANSFER')}</MenuItem>
            <MenuItem value='TRANSACTION'>{t('reportTypes.TRANSACTION')}</MenuItem>
            <MenuItem value='GOAL'>{t('reportTypes.GOAL')}</MenuItem>
            <MenuItem value='CLIENT_GROUP'>{t('reportTypes.CLIENT_GROUP')}</MenuItem>
            <MenuItem value='DAILY_STATS'>{t('reportTypes.DAILY_STATS')}</MenuItem>
          </SelectField>
          <SelectField
            sx={{ mt: 2 }}
            fullWidth
            label={t('chartType')}
            value={widget.type}
            onChange={(e: any) => setWidget({ ...widget, type: e.target.value })}
          >
            <MenuItem value='BAR'>{t('chartTypes.BAR')}</MenuItem>
            <MenuItem value='LINE'>{t('chartTypes.LINE')}</MenuItem>
            <MenuItem value='NUMBER'>{t('chartTypes.NUMBER')}</MenuItem>
            <MenuItem value='PIE'>{t('chartTypes.PIE')}</MenuItem>
          </SelectField>
          { widget.type !== 'NUMBER' && (
            <SelectField
              sx={{ mt: 2 }}
              fullWidth
              label={t('groupOn')}
              value={widget.grouping.field}
              onChange={(e: any) => setWidget({ ...widget, grouping: { ...widget.grouping, field: e.target.value } })}
            >
              {optionsData?.fetchReportOptions?.columns?.map((column: any) => (
                <MenuItem key={column} value={column}>{t(`column.${column}`)}</MenuItem>
              ))}
            </SelectField>
          )}
          <SelectField
            sx={{ mt: 2 }}
            fullWidth
            label={t('valueAggregation')}
            value={widget.grouping.valueAggregation}
            onChange={(e: any) => setWidget({ ...widget, grouping: { ...widget.grouping, valueAggregation: e.target.value } })}
          >
            <MenuItem value='AVG'>{t('aggregationTypes.AVG')}</MenuItem>
            <MenuItem value='SUM'>{t('aggregationTypes.SUM')}</MenuItem>
            <MenuItem value='VALUE_COUNT'>{t('aggregationTypes.VALUE_COUNT')}</MenuItem>
          </SelectField>
          <SelectField
            sx={{ mt: 2 }}
            fullWidth
            label={t('groupingType')}
            value={widget.grouping.type}
            onChange={(e: any) => setWidget({ ...widget, grouping: { ...widget.grouping, type: e.target.value } })}
          >
            <MenuItem value='DATE'>{t('groupingTypes.DATE')}</MenuItem>
            <MenuItem value='FIRST_DATE_IN_INTERVAL'>{t('groupingTypes.FIRST_DATE_IN_INTERVAL')}</MenuItem>
            <MenuItem value='GROUPING'>{t('groupingTypes.GROUPING')}</MenuItem>
            <MenuItem value='SINGLE_VALUE'>{t('groupingTypes.SINGLE_VALUE')}</MenuItem>
          </SelectField>
          { ['DATE', 'FIRST_DATE_IN_INTERVAL'].includes(widget.grouping?.type) && (
            <SelectField
              sx={{ mt: 2 }}
              fullWidth
              label={t('interval')}
              value={widget.grouping.interval}
              onChange={(e: any) => setWidget({ ...widget, grouping: { ...widget.grouping, interval: e.target.value } })}
            >
              <MenuItem value='DAY'>{t('intervals.DAY')}</MenuItem>
              <MenuItem value='MONTH'>{t('intervals.MONTH')}</MenuItem>
              <MenuItem value='WEEK'>{t('intervals.WEEK')}</MenuItem>
              <MenuItem value='YEAR'>{t('intervals.YEAR')}</MenuItem>
            </SelectField>
          )}
          <SelectField
            sx={{ mt: 2 }}
            fullWidth
            label={t('formatTitle')}
            value={widget.format}
            onChange={(e: any) => setWidget({ ...widget, format: e.target.value })}
          >
            <MenuItem value='CENTS'>{t('format.CENTS')}</MenuItem>
            <MenuItem value='FLOAT'>{t('format.FLOAT')}</MenuItem>
          </SelectField>
        </Box>
        <Box display={ tab === 'filters' ? 'block' : 'none' }>
          <EditFilters
            type={widget.reportType}
            filters={widget.filters}
            setFilters={(filters) => setWidget({ ...widget, filters })}
            availableFilters={optionsData?.fetchReportOptions?.filters || []}
          />
        </Box>
        <Box display={ tab === 'columns' ? 'block' : 'none' }>
          <ColumnsSelect
            columns={widget.columns}
            setColumns={(columns) => setWidget({ ...widget, columns })}
            availableColumns={optionsData?.fetchReportOptions?.columns || []}
            onChange={(e) => setWidget({ ...widget, columns: e.target.value })}
          />
        </Box>
      </DialogContent>
      <DialogFooter>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button color='destructive' variant='tonal' onClick={() => {
            removeWidget();
            setOpen(false);
          }} label={t('shared:delete')} />
          <Button onClick={() => {
            setOpen(false);
            updateWidget(widget);
          }} label={t('shared:done')} disabled={optionsLoading} />
        </Box>
      </DialogFooter>
    </Dialog>
  );
};

import { gql, useMutation } from '@apollo/client';
import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalToast } from '../../../../providers/globalToastProvider';
import { UserContext } from '../../../../providers/userContextProvider';
import { Box } from '../../../1-primative';
import { CreateNewModal } from '../../../3-pattern';
import { Switch } from '../../../2-component';

const RUN_RECONCILIATION = gql`
mutation RunReconciliation($input: RunReconciliationInput) {
  runReconciliation(input: $input)
}
`;

const mapReconciliationStages = (flags: Record<string, boolean>): string[] => {
  const stepMap: Record<string, string> = {
    reconActivities: 'RECON_ACTIVITIES',
    updateCashAndHoldings: 'UPDATE_CASH_AND_HOLDINGS',
    reconCashAndHoldings: 'RECON_CASH_AND_HOLDINGS',
    publishSnapshots: 'PUBLISH_SNAPSHOTS',
  };

  return Object.entries(flags)
    .filter(([, value]) => value)
    .map(([key]) => stepMap[key]) || [];
};

const RunReconciliationModel = ({ afterCreate }: { afterCreate: () => void }) => {
  const { showToast } = useGlobalToast();
  const { t } = useTranslation(['reconciliation']);
  const { activeOrganization } = useContext(UserContext);
  const emptyReconciliationRun:{
    reconActivities: boolean,
    updateCashAndHoldings: boolean,
    reconCashAndHoldings: boolean,
    publishSnapshots: boolean,
    organizationId?: string,
  } = {
    reconActivities: false,
    updateCashAndHoldings: false,
    reconCashAndHoldings: false,
    publishSnapshots: false,
  };
  const [reconciliation, setReconciliationRun] = useState(emptyReconciliationRun);
  const [initialState] = useState({
    reconActivities: false,
    updateCashAndHoldings: false,
    reconCashAndHoldings: false,
    publishSnapshots: false,
  });

  const onClose = () => {
    setReconciliationRun(emptyReconciliationRun);
  };

  const [submitReconciliation, { loading }] = useMutation(RUN_RECONCILIATION, {
    variables: {
      input: {
        organizationIds: [reconciliation.organizationId],
        reconcileStages: mapReconciliationStages({
          reconActivities: reconciliation.reconActivities,
          updateCashAndHoldings: reconciliation.updateCashAndHoldings,
          reconCashAndHoldings: reconciliation.reconCashAndHoldings,
          publishSnapshots: reconciliation.publishSnapshots,
        }),
      },
    },
  });

  useEffect(() => {
    if (!activeOrganization) return;

    setReconciliationRun((prevReconciliation) => ({
      ...prevReconciliation,
      organizationId: activeOrganization.id,
    }));
  }, [activeOrganization]);

  const create = async (event: any) => {
    if (activeOrganization.id) {
      const response = await submitReconciliation();
      if (response?.data) {
        showToast({ severity: 'success', message: t('runReconciliation.runReconcileToastMessage') });
        setReconciliationRun({ ...emptyReconciliationRun });
      }
      afterCreate();
    }
  };
  const disabled = false;

  return (
    <CreateNewModal
      initialState={initialState}
      state={reconciliation}
      modalButton={t('runReconciliation.title')}
      onClose={onClose}
      icon={''}
      loading={loading} disabled={disabled} title={t('runReconciliation.title')} onSubmit={create} fullWidth
      buttonText={'Confirm'}
      >
      <Box display='flex' flexDirection='column' gap={2}>

        <Switch
          label={t('runReconciliation.reconcileActivities')}
          checked={reconciliation.reconActivities}
          onChange={async (checked) => setReconciliationRun({ ...reconciliation, reconActivities: checked })}
        />

        <Switch
          label={t('runReconciliation.updateCashAndHoldings')}
          checked={reconciliation.updateCashAndHoldings}
          onChange={async (checked) => setReconciliationRun({ ...reconciliation, updateCashAndHoldings: checked })}
        />
        <Switch
          checked={reconciliation.reconCashAndHoldings}
          onChange={async (checked) => setReconciliationRun({ ...reconciliation, reconCashAndHoldings: checked })}
          label={t('runReconciliation.reconCashAndHoldings')}
        />
        <Switch
          checked={reconciliation.publishSnapshots}
          onChange={async (checked) => setReconciliationRun({ ...reconciliation, publishSnapshots: checked })}
          label={t('runReconciliation.publishSnapshots')}
        />

      </Box>
    </CreateNewModal>
  );
};

export default RunReconciliationModel;

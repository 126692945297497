import { gql, useQuery } from '@apollo/client';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

const FETCH_PORTFOLIO_MODEL_NAME = gql`
  query($id: ObjectID!) {
    fetchModelPortfolio(input:{modelPortfolioId: $id}) {
      modelPortfolio { name }
    }
  }
`;

export function PortfolioModelBreadcrumb() {
  const { data } = useQuery(FETCH_PORTFOLIO_MODEL_NAME, { variables: { id: useParams().id } });
  const location = useLocation();
  const navigate = useNavigate();

  // Get the current search params to preserve them
  const searchParams = new URLSearchParams(location.search);
  const modelPortfoliosPath = `/modelPortfolios${searchParams.toString() ? `?${searchParams.toString()}` : ''}`;

  // Return just the model name, the breadcrumb system will handle the navigation
  const modelName = data ? data.fetchModelPortfolio?.modelPortfolio.name : 'Model';

  // Handle click to preserve search params
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(modelPortfoliosPath);
  };

  return (
    <span onClick={handleClick} style={{ cursor: 'pointer' }}>
      {modelName}
    </span>
  );
}

import { gql, useQuery } from '@apollo/client';
import { MenuItem } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectField } from '../../2-component';
import { AccountTypes, getSubAccountName, SubAccount } from '../../../interfaces';
import { STATE_CHANGES_FIELD } from '../../../util/reusableGraphQLFields';
import { useScreenWidth } from '../../../util/mediaQueryUtils';
import { UserContext } from '../../../providers/userContextProvider';

const SUB_ACCOUNT_FIELDS = gql`
  fragment SubAccountFields on SubAccount {
    state
    ${STATE_CHANGES_FIELD}
    financialProduct {
      translatedName { en fr }
    }
    goal {
      name
      type
    }
    account {
      id
      type
      user {
        id
      }
    }
    id
    createdAt
    completedAt
    availableFinancialProducts {
      id
    }
    statistics {
      marketValueCents
      availableFundsToWithdrawCents
    }
  }
`;

const FETCH_SUB_ACCOUNTS = gql`
  query fetchSubAccounts($input: FetchSubAccountsInput!) {
    fetchSubAccounts(input: $input) {
      subAccounts {
        ...SubAccountFields
      }
      totalCount
    }
  }
  ${SUB_ACCOUNT_FIELDS}
`;

const FETCH_SUB_ACCOUNT = gql`
  query fetchSubAccount($id: ObjectID!) {
    fetchSubAccount(subAccountId: $id) {
      subAccount {
        ...SubAccountFields
      }
    }
  }
  ${SUB_ACCOUNT_FIELDS}
`;

interface SubAccountSelectProp {
  userId?: string;
  label: string;
  onSubAccountSelect: (subAccount: SubAccount | undefined) => void;
  selectedSubAccount?: any | undefined,
  accountToExclude?: AccountTypes[];
  subAccountsToExclude?: string[];
  sx?: any;
  size?: 'small' | 'medium';
  showValue?: boolean;
  filter?: { userId?: string; subAccountId?: string; accountId?: string; goalId?: string; clientGroupId?: string };
  onBlur?: () => void;
  error?: boolean;
  filterNotAvailableWithdraw?: boolean;
  excludedIds?: string[];
  dataTestId?: string;
  omitAny?: boolean;
}

export const SubAccountSelect = ({
  userId,
  label,
  onSubAccountSelect,
  selectedSubAccount,
  sx = {},
  size,
  filter,
  onBlur,
  error,
  filterNotAvailableWithdraw = false,
  accountToExclude,
  subAccountsToExclude,
  dataTestId = 'sub-account-select',
  omitAny = false,
}: SubAccountSelectProp) => {
  const { t } = useTranslation(['components']);
  const { activeOrganization } = useContext(UserContext);
  const onBoardingWithoutGoals = activeOrganization.availableFeatureFlags?.includes('ONBOARDING_WITHOUT_GOALS');
  const screenWidth = useScreenWidth();
  const shouldUseSingleQuery = !!filter?.subAccountId;

  const { data: singleData, loading: singleLoading } = useQuery(FETCH_SUB_ACCOUNT, {
    variables: { id: filter?.subAccountId },
    skip: !shouldUseSingleQuery,
    fetchPolicy: 'cache-and-network',
  });

  const { data: multipleData, loading: multipleLoading } = useQuery(FETCH_SUB_ACCOUNTS, {
    variables: {
      input: {
        filter: filter || { userId },
        pagination: { perPage: 100 },
      },
    },
    skip: shouldUseSingleQuery,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const singleSubAccount = singleData?.fetchSubAccount?.subAccount;
    if (shouldUseSingleQuery && singleSubAccount) {
      onSubAccountSelect(singleSubAccount);
    }
  }, [shouldUseSingleQuery, singleData, onSubAccountSelect]);

  const subAccounts = shouldUseSingleQuery
    ? singleData?.fetchSubAccount?.subAccount ? [singleData.fetchSubAccount.subAccount] : []
    : multipleData?.fetchSubAccounts?.subAccounts ?? [];

  const filteredSubAccounts = subAccounts.filter((subAccount: SubAccount) => {
    if (subAccount.state === 'INACTIVE') return false;
    if (accountToExclude?.includes(subAccount.account.type)) return false;
    if (subAccountsToExclude?.includes(subAccount.id)) return false;
    if (filterNotAvailableWithdraw && !subAccount.statistics?.availableFundsToWithdrawCents) return false;
    return true;
  });

  const handleSubAccountSelect = (event: any) => {
    if (!event.target.value || event.target.value === 'ANY') {
      onSubAccountSelect(undefined);
      return;
    }

    const selectedAccount = filteredSubAccounts.find(
      (item: SubAccount) => item.id === event.target.value,
    );
    if (selectedAccount) {
      onSubAccountSelect(selectedAccount);
    }
  };
  return (
    <SelectField
      key={screenWidth}
      onChange={handleSubAccountSelect}
      label={label}
      testId={dataTestId}
      fullWidth
      sx={sx}
      size={size}
      value={selectedSubAccount?.id ?? (omitAny ? '' : 'ANY')}
      onBlur={onBlur}
      error={error}
      disabled={singleLoading || multipleLoading || shouldUseSingleQuery}
    >
      {!omitAny && (
        <MenuItem
          value="ANY"
          data-testid="sub-account-item-any"
        >
          {t('components:any')}
        </MenuItem>
      )}
      {filteredSubAccounts.map((subAccount: SubAccount) => (
        <MenuItem
          key={subAccount.id}
          data-testid={`sub-account-item-${getSubAccountName(subAccount)}`}
          value={subAccount.id}
          style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
        >
          {getSubAccountName(subAccount as SubAccount, false, !onBoardingWithoutGoals)}
        </MenuItem>
      ))}
    </SelectField>
  );
};

import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { getBackendLanguage } from 'assets/i18n/config';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useGlobalToast } from 'providers/globalToastProvider';
import { ClientReportTemplate as ClientReportTemplateType, ReportVariableMeta } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { conditionGraphql } from 'ovComponents/3-pattern/ruleBuilder/ruleBuilder';
import { ClientReportTemplateVisual } from './clientReportTemplate.visual';
import ClientReportContextProvider from '../../../providers/clientReportContextProvider';

const FETCH_CLIENT_REPORT_TEMPLATE = gql`
  query fetchClientReportTemplate($id: ObjectID!) {
    fetchClientReportTemplate(clientReportTemplateId: $id) {
      clientReportTemplate {
        id
        translatedName { ${getBackendLanguage()} }
        translatedDescription { ${getBackendLanguage()} }
        reportType
        state
        type
        objectType
        s3key { ${getBackendLanguage()} }
        scheduler {
          frequency
          dayOfMonthOrWeek
          oneTimeDateStart
          oneTimeDateEnd
          triggerRules {
            field comparison value
          }
        }
        clientReportTemplatePageConfiguration {
          id
          type
          widgets {
            id
            type
            options
          }
          options
        }
        ${conditionGraphql}
        organization { id }
      }
    }
  }
`;

const FETCH_TEMPLATE_FILE_DOCUMENT_DOWNLOAD_URL = gql`
  query fetchTemplateFileDocumentDownloadUrl($clientReportTemplateId: ObjectID!) {
    fetchFileDocuments(
      input: {
        filter: {
          types: [CLIENT_REPORT_TEMPLATE]
          sourceIds:[$clientReportTemplateId]
        }
      }
    ) {
      fileDocuments {
        downloadUrl
      }
    }
  }
`;

const FETCH_AVAILABLE_VARIABLES = gql`
  query fetchAvailableReportVariables($objectType:ClientReportTemplateObjectTypes!) {
    fetchAvailableReportVariables(input:{objectType: $objectType}) {
      availableVariables {
        name type description
      }
    }
  }
`;

const FETCH_TEMPLATE_FILE_UPLOAD_URL = gql`
  query fetchTemplateFileUploadUrl($input:FetchFileUploadUrlInput!) {
    fetchFileUploadUrl(input: $input) {
      temporarySignedURL
      s3Path
    }
  }
`;

export const ClientReportTemplate = () => {
  const { id } = useParams();

  const [clientReportTemplate, setClientReportTemplate] = useState<ClientReportTemplateType>();
  const [availableVariables, setAvailableVariables] = useState<ReportVariableMeta[]>();

  const validateObjectType = (objectType: string | undefined) => {
    if (objectType === 'INDIVIDUAL' || objectType === 'NON_INDIVIDUAL') {
      return 'USER';
    }
    return objectType;
  };

  const { refetch } = useQuery(FETCH_CLIENT_REPORT_TEMPLATE, {
    variables: { id },
    onCompleted: (data) => {
      setClientReportTemplate(data?.fetchClientReportTemplate?.clientReportTemplate);
    },
  });

  useQuery(FETCH_AVAILABLE_VARIABLES, {
    skip: !clientReportTemplate || clientReportTemplate?.reportType === 'PAGE_CONFIGURATION',
    variables: { objectType: validateObjectType(clientReportTemplate?.objectType) },
    onCompleted: (data) => {
      setAvailableVariables(data?.fetchAvailableReportVariables.availableVariables);
    },
  });

  useQuery(FETCH_TEMPLATE_FILE_DOCUMENT_DOWNLOAD_URL, {
    variables: { clientReportTemplateId: id },
    fetchPolicy: 'no-cache', /* because downloadUrl expires in 60 seconds */
    onCompleted: (data) => {
      loadTemplateHtml(data.fetchFileDocuments.fileDocuments[0].downloadUrl);
    },
  });

  const [fetchTemplateFileUploadUrl] = useLazyQuery(FETCH_TEMPLATE_FILE_UPLOAD_URL, {
    fetchPolicy: 'no-cache',
  });

  const [templateHtml, setTemplateHtml] = useState<string | undefined>(undefined);
  const [saving, setSaving] = useState(false);

  const { showToast } = useGlobalToast();
  const { t } = useTranslation('clientReportTemplate');

  const loadTemplateHtml = async (downloadUrl?: string) => {
    if (!downloadUrl) {
      showToast({ severity: 'error', message: 'Template HTML download failed (No downloadUrl)' });
      return;
    }
    const r: Response = await fetch(new Request(downloadUrl, { method: 'GET' }));
    if (!r.ok) {
      showToast({ severity: 'error', message: `Template HTML download failed (${r.status} ${r.statusText})` });
      return;
    }
    setTemplateHtml(await r.text());
  };

  const save = async () => {
    if (!clientReportTemplate) return;

    setSaving(true);

    /* (1) fetch the S3 upload URL from backend */
    const result = await fetchTemplateFileUploadUrl({
      variables: {
        input: {
          objectType: 'ORGANIZATION',
          objectId: clientReportTemplate.organization.id,
          type: 'CLIENT_REPORT_TEMPLATE',
          sourceId: clientReportTemplate.id,
          fileName: clientReportTemplate.type,
          generateUniqueFileName: true,
        },
      },
    });

    // If we ever decide to call CreateFileDocument after uploading the file (like the other upload flows)
    // We need to pass the s3Path that was used on this step
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { temporarySignedURL, s3Path } = result.data.fetchFileUploadUrl;

    /* (2) do the upload */
    try {
      const uploaded: Response = await fetch(new Request(temporarySignedURL, { method: 'PUT', body: templateHtml }));
      if (!uploaded.ok) throw (new Error(`${uploaded.status} ${uploaded.statusText}`));
    } catch (e: any) {
      setSaving(false);
    }

    showToast({ severity: 'success', message: t('savedSuccessfully') });

    setSaving(false);
  };

  return <>
  <ClientReportContextProvider>
    <ClientReportTemplateVisual
      template={clientReportTemplate}
      setTemplate={setClientReportTemplate}
      templateHtml={templateHtml}
      setTemplateHtml={setTemplateHtml}
      availableVariables={availableVariables}
      save={save}
      saving={saving}
      refetch={refetch}
    />
  </ClientReportContextProvider>
  </>;
};

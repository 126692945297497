import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { Box, Grid } from '@mui/material';

import {
  Button, Form, Typography,
} from 'ovComponents';
import { additionalInfo } from 'ovComponents/4-module/configurableOptionFields';
import InfoDialog from 'ovComponents/2-component/infoDialog/infoDialog';
import { getBackendLanguage, translateBackend } from 'assets/i18n/config';
import SecuritiesSelectionTable from 'ovComponents/3-pattern/securitiesSelectionTable/securitiesSelectionTable';
import { PieChartSecurities } from 'ovComponents/3-pattern/pieChartSecurities/pieChartSecurities';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';

export const AddModelPortfolioSecuritiesVisual = ({
  options,
  workflowCompletion,
  securities = [],
  continueFunc,
  onAddSecurity,
  onUpdateSecurity,
  onUpdateTaxRank,
  onUpdateTargetPercentage,
  onRemoveSecurity,
  onSaveForLater,
  onImportFromCsv,
  onReorderSecurities,
  modelPortfolioDetails,
}: {
  options: any;
  workflowCompletion?: any;
  securities: any[];
  continueFunc: () => void;
  onAddSecurity: () => void;
  onUpdateSecurity: (index: number, security: any) => void;
  onRemoveSecurity: (index: number) => void;
  onUpdateTaxRank: (index: number, value: number) => void;
  onUpdateTargetPercentage: (index: number, value: number) => void;
  onSaveForLater: () => void;
  onImportFromCsv: () => void;
  onReorderSecurities: (securities: any[]) => void;
  modelPortfolioDetails: any;
}) => {
  const { t } = useTranslation(['modelPortfolios', 'shared']);
  const { sendAnalytic } = useContext(AnalyticsContext);

  const validate = () => {
    const fields = [];
    if (options?.showSecurityAllocationTable?.required === 'ERROR' && securities.length === 0) {
      fields.push('securities');
    }
    return fields.length === 0;
  };

  const submit = () => {
    if (validate()) {
      sendAnalytic(ovAnalyticsEvents.workflowsAddModelPortfolioDetailsContinueButtonSelect, {
        workflowStepTitle: options?.title,
        workflowId: workflowCompletion?.workflow?.id,
        workflowName: workflowCompletion?.workflow?.name,
        activeWorkflowCompletionId: workflowCompletion?.id,
        objectId: workflowCompletion?.objectId,
        objectType: workflowCompletion?.objectType,
      });
      continueFunc();
    }
  };

  return (
    <Box sx={{ ml: -20 }}>
      <Form onSubmit={submit}>
        {options?.title && (
          <Typography variant="displayLarge" sx={{ mt: 1, mb: 2 }}>
            {translateBackend(options?.title)}
            {additionalInfo(options?.title?.additionalInfo) && (
              <InfoDialog information={additionalInfo(options?.title?.additionalInfo) ?? ''} />
            )}
          </Typography>
        )}

        <Grid container spacing={2}>
          {options?.securityAllocationChart?.enabled && (
            <Grid item xs={12} md={5}>
              <Box sx={{
                border: '1px solid', borderColor: 'divider', borderRadius: 1,
              }}>
                <PieChartSecurities securities={securities} modelPortfolioName={modelPortfolioDetails?.translatedName[getBackendLanguage()] ?? ''} />
              </Box>
            </Grid>
          )}

          <Grid item xs={12} md={7}>
            {options?.showSecurityAllocationTable?.enabled && (
              <>
                <SecuritiesSelectionTable
                  securities={securities}
                  options={options}
                  onRemoveSecurity={(index: number) => onRemoveSecurity(index)}
                  onUpdateSecurity={(index: number, security: any) => onUpdateSecurity(index, security)}
                  onAddSecurity={onAddSecurity}
                  onUpdateTaxRank={(index: number, value: number) => onUpdateTaxRank(index, value)}
                  onUpdateTargetPercentage={(index: number, value: number) => onUpdateTargetPercentage(index, value)}
                  onReorderSecurities={(data: any[]) => onReorderSecurities(data)}
                />
                <Box display="flex" justifyContent="right" sx={{ mt: 3, mr: -30 }}>
                  {options?.buttonSaveForLater?.enabled && (
                    <Button
                      data-testid="add-model-portfolio-securities-save-for-later-button"
                      variant="outlined"
                      onClick={onSaveForLater}
                      label={translateBackend(options?.buttonSaveForLater?.label)}
                    />
                  )}
                  <Button
                    sx={{ ml: 2 }}
                    data-testid="add-model-portfolio-securities-continue-button"
                    label={t('shared:continue')}
                    type="submit"
                  />
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Form>
    </Box>
  );
};

export default AddModelPortfolioSecuritiesVisual;

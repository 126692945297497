import { isNil, kebabCase } from 'lodash';
import { translateBackend } from '../../../../../../assets/i18n/config';
import { Grid } from '../../../../../1-primative';
import { Radio, RadioGroup, TextField } from '../../../../../2-component';
import { additionalInfo } from '../../../../configurableOptionFields';
import { UserWithCustomFields } from '../personsOfInterest.visual';

interface PersonsOfInterestFieldBaseType {
  update: (newValue: any) => void;
  userData: any;
  loading: boolean;
  options: any;
  setFocused: React.Dispatch<React.SetStateAction<string[]>>;
  focused: string[];
}

interface ReusablePersonOfInterestFieldType {
  conditionToRender: boolean;
  field: keyof UserWithCustomFields;
  testId: string;
  focusValue: string;
}

interface PersonsOfInterestFieldType extends PersonsOfInterestFieldBaseType, ReusablePersonOfInterestFieldType {}
/**
 * @param conditionToClear
 * The condition that is used for clearing the state of nested elements. Will reset the value if the current selection and all of its conditions are false
 */

export const FieldComponent = ({
  field,
  children,
  noGrid = false,
  options,
  grid,
  userData,
  loading,
  update,
  t,
  nestedElements,
  conditionToClear = true,
  defaultOptionsToUndefined = false,
}: {
  field: keyof UserWithCustomFields;
  children?: any;
  noGrid?: boolean;
  options: any;
  grid: boolean;
  userData: any;
  loading: boolean;
  update: (newValue: any) => void;
  t: any;
  nestedElements?: string[];
  conditionToClear?: boolean;
  defaultOptionsToUndefined?: boolean;
}) => {
  if (!options?.[field]?.enabled) return <></>;

  const additionalInfoOpts = options?.[field]?.additionalInfo;
  const nestedBooleanFields = ['isRegisteredWithCRA', 'receivesDonationsFromPublic'];

  const value = (defaultOptionsToUndefined && isNil(userData?.[field])) ? undefined : (userData?.[field] ? 'yes' : 'no');

  return (
    <Grid item xs={12} md={grid && !noGrid ? 4 : 12}>
      <RadioGroup
        testId={kebabCase(field)}
        value={value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          /* Update parent with the field's new value. If 'no', and conditionToClear with nestedElements exist, reset nestedElements to false or ''. */
          const newValue = event.target.value === 'yes';
          const updatedData = {
            ...userData,
            [field]: newValue,
            ...(event.target.value === 'no' && conditionToClear
              && nestedElements?.reduce((acc, element) => ({ ...acc, [element]: nestedBooleanFields.includes(element) ? false : '' }), {})),
          };
          update(updatedData);
        }}
        disabled={loading}
        label={translateBackend(options?.[field]?.label)}
        infoTooltip={additionalInfo(additionalInfoOpts)}
      >
        <Radio testId={`${kebabCase(field)}-option-no`} value={'no'} label={t('shared:no')} />
        <Radio testId={`${kebabCase(field)}-option-yes`} value={'yes'} label={t('shared:yes')} />
      </RadioGroup>
      {children}
    </Grid>
  );
};

export const SSSymbolsField = ({
  update, userData, loading, options, setFocused, focused,
}: PersonsOfInterestFieldBaseType) => (
  <TextField
    size='small'
    testId='ss-symbols'
    onChange={(e: any) => update({ ...userData, ssSymbols: [e.target.value] })}
    disabled={loading}
    label={translateBackend(options?.ssSymbols?.label)}
    infoTooltip={additionalInfo(options?.ssSymbols?.additionalInfo)}
    fullWidth
    value={userData.ssSymbols?.[0] ?? ''}
    onBlur={() => setFocused([...focused, 'ssSymbols'])}
    error={(userData.ssSymbols.length === 0 || userData.ssSymbols.includes('')) && focused.includes('ssSymbols') && options?.ssSymbols?.required !== 'NOT_REQUIRED'}
  />
);

export const RegulatedEntityDetails = ({
  update, userData, loading, options, setFocused, focused,
}: PersonsOfInterestFieldBaseType) => (userData.isEntityRegulated ? (
    <Grid item container xs={12} columnSpacing={2} rowSpacing={{ xs: 2, md: 0 }}>
      <Grid item flex={1}>
        {options?.regulatorName?.enabled && userData.isEntityRegulated && (
          <TextField
            size='small'
            testId='is-entity-regulated'
            onChange={(e: any) => update({ ...userData, regulatorName: e.target.value })}
            disabled={loading}
            label={translateBackend(options?.regulatorName?.label)}
            infoTooltip={additionalInfo(options?.regulatorName?.additionalInfo)}
            fullWidth
            value={userData.regulatorName ?? ''}
            onBlur={() => setFocused([...focused, 'regulatorName'])}
            error={!userData.regulatorName && focused.includes('regulatorName') && options?.regulatorName?.required !== 'NOT_REQUIRED'}
          />
        )}
      </Grid>
      {options?.firmNumber?.enabled && userData.isEntityRegulated && (
        <Grid item flex={1}>
          <TextField
            size='small'
            testId='firm-number'
            onChange={(e: any) => update({ ...userData, firmNumber: e.target.value })}
            disabled={loading}
            label={translateBackend(options?.firmNumber?.label)}
            infoTooltip={additionalInfo(options?.firmNumber?.additionalInfo)}
            fullWidth
            value={userData.firmNumber ?? ''}
            onBlur={() => setFocused([...focused, 'firmNumber'])}
            error={!userData.firmNumber && focused.includes('firmNumber') && options?.firmNumber?.required !== 'NOT_REQUIRED'}
          />
        </Grid>
      )}
    </Grid>
) : null);

export const ReportingIssuerDetails = ({
  update, userData, loading, options, setFocused, focused,
}: PersonsOfInterestFieldBaseType) => (userData.isReportingIssuer ? (
    <Grid item container xs={12} columnSpacing={2} rowSpacing={{ xs: 2, md: 0 }}>
      {options?.issuerName?.enabled && userData.isReportingIssuer && (
        <Grid item flex={1}>
          <TextField
            size='small'
            testId='issuer-name'
            onChange={(e: any) => update({ ...userData, issuerName: e.target.value })}
            disabled={loading}
            label={translateBackend(options?.issuerName?.label)}
            infoTooltip={additionalInfo(options?.issuerName?.additionalInfo)}
            fullWidth
            value={userData.issuerName ?? ''}
            onBlur={() => setFocused([...focused, 'issuerName'])}
            error={!userData.issuerName && focused.includes('issuerName') && options?.issuerName?.required !== 'NOT_REQUIRED'}
          />
        </Grid>
      )}
      {options?.issuerExchange?.enabled && userData.isReportingIssuer && (
        <Grid item flex={1}>
          <TextField
            size='small'
            testId='issuer-exchange'
            onChange={(e: any) => update({ ...userData, issuerExchange: e.target.value })}
            disabled={loading}
            label={translateBackend(options?.issuerExchange?.label)}
            infoTooltip={additionalInfo(options?.issuerExchange?.additionalInfo)}
            fullWidth
            value={userData.issuerExchange ?? ''}
            onBlur={() => setFocused([...focused, 'issuerExchange'])}
            error={!userData.issuerExchange && focused.includes('issuerExchange') && options?.issuerExchange?.required !== 'NOT_REQUIRED'}
          />
        </Grid>
      )}
      {options?.issuerTicker?.enabled && userData.isReportingIssuer && (
        <Grid item flex={1}>
          <TextField
            size='small'
            testId='issuer-ticker'
            onChange={(e: any) => update({ ...userData, issuerTicker: e.target.value })}
            disabled={loading}
            label={translateBackend(options?.issuerTicker?.label)}
            infoTooltip={additionalInfo(options?.issuerTicker?.additionalInfo)}
            fullWidth
            value={userData.issuerTicker ?? ''}
            onBlur={() => setFocused([...focused, 'issuerTicker'])}
            error={!userData.issuerTicker && focused.includes('issuerTicker') && options?.issuerTicker?.required !== 'NOT_REQUIRED'}
          />
        </Grid>
      )}
    </Grid>
) : null);

export const CharityRegistrationNumber = ({
  update, userData, loading, options, setFocused, focused,
}: PersonsOfInterestFieldBaseType) => (options?.charityRegistrationNumber?.enabled && userData.isRegisteredWithCRA ? (
    <Grid item xs={12} mb={2}>
      <TextField
        size='small'
        testId='charity-registration-number'
        onChange={(e: any) => update({ ...userData, charityRegistrationNumber: e.target.value })}
        disabled={loading}
        label={translateBackend(options?.charityRegistrationNumber?.label)}
        infoTooltip={additionalInfo(options?.charityRegistrationNumber?.additionalInfo)}
        fullWidth
        value={userData.charityRegistrationNumber ?? ''}
        onBlur={() => setFocused([...focused, 'charityRegistrationNumber'])}
        error={!userData.charityRegistrationNumber && focused.includes('charityRegistrationNumber') && options?.charityRegistrationNumber?.required !== 'NOT_REQUIRED'}
      />
    </Grid>
) : null);

export const InsiderSymbols = ({
  update, userData, loading, options, setFocused, focused,
}: PersonsOfInterestFieldBaseType) => (options?.insiderSymbols?.enabled && userData.isMemberOfIiroc ? (
    <Grid item xs={12}>
      <TextField
        size='small'
        testId='insider-symbol'
        onChange={(e: any) => update({ ...userData, insiderSymbols: [e.target.value] })}
        disabled={loading}
        label={translateBackend(options?.insiderSymbols?.label)}
        infoTooltip={additionalInfo(options?.insiderSymbols?.additionalInfo)}
        fullWidth
        value={userData.insiderSymbols ?? ''}
        onBlur={() => setFocused([...focused, 'insiderSymbols'])}
        error={userData.insiderSymbols.length === 0 && focused.includes('insiderSymbols') && options?.insiderSymbols?.required !== 'NOT_REQUIRED'}
      />
    </Grid>
) : null);

/**
 *
 * @param conditionToRender
 * The conditions required for the field to render - the field should always be set to show on the first true instance
 */
export const PoiUserField = ({
  update, userData, loading, options, setFocused, focused, conditionToRender, field, testId, focusValue,
}: PersonsOfInterestFieldType) => (conditionToRender ? (
    <Grid item xs={12}>
      <TextField
        size='small'
        testId={testId}
        onChange={(e: any) => update({ ...userData, [field]: e.target.value })}
        disabled={loading}
        label={translateBackend(options?.[field]?.label)}
        infoTooltip={additionalInfo(options?.[field]?.additionalInfo)}
        fullWidth
        value={userData[field] ?? ''}
        onBlur={() => setFocused([...focused, focusValue])}
        error={!userData[field] && focused.includes(focusValue) && options?.insiderSymbols?.required !== 'NOT_REQUIRED'}
      />
    </Grid>
) : null);

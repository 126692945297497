import { useContext } from 'react';
import { isNull } from 'lodash/fp';
import { gql, useQuery } from '@apollo/client';
import { kebabCase } from 'lodash';
import {
  SelectField, MenuItem,
} from '../../2-component';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';

export const FETCH_REP_CODES_SELECT = gql`
  query fetchRepCodes($input: FetchRepCodesInput!) {
    fetchRepCodes(input: $input) {
      repCodes {
        code
        name
      }
      totalCount
    }
  }
`;

export const RepCodeSelect = ({
  setRepCode,
  value,
  label,
  size,
  disabledWith = null,
  inputError,
  locked,
  lockMessage,
  sx,
}: {
  setRepCode: (event: any) => void;
  value: string;
  label: string;
  size?: 'small' | 'medium' | undefined;
  disabledWith?: string | null;
  inputError?: boolean;
  locked?: boolean;
  lockMessage?: string;
  sx?: any;
}) => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const filter: any = { organizationId: activeOrganization.id };
  const { loading, error, data } = useQuery(FETCH_REP_CODES_SELECT, {
    skip: !activeOrganization.id || !permissions.includes('read:rep_code'),
    variables: {
      input: {
        filter,
        pagination: { perPage: 300, sortField: 'code' },
      },
    },
  });

  if (error) (<>Error</>);
  const filteredList = data?.fetchRepCodes?.repCodes || [];
  return (
    <>
      {permissions.includes('read:rep_code') ? (
        <SelectField
          testId='rep-code-select'
          value={data ? value : ''}
          label={label}
          fullWidth
          sx={sx}
          error={inputError}
          onChange={(e: any) => {
            const val = e.target.value;

            setRepCode(val);
          }}
          size={size}
          disabled={!isNull(disabledWith)}
          locked={locked}
          lockMessage={lockMessage}
        >
          {loading && (
            <MenuItem disabled>
              <em>Loading...</em>
            </MenuItem>
          )}

          {!loading && !disabledWith && filteredList.map((item: any) => (
            <MenuItem key={item.code} value={item.code} data-testid={`rep-code-select-${kebabCase(item.name)}`}>
              {item.code} {item.name && `- ${item.name}`}
            </MenuItem>
          ))}
        </SelectField>
      ) : (<></>)}
    </>
  );
};

export default RepCodeSelect;

import { useContext, useEffect, useState } from 'react';
import {
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { CustomField, FETCH_CUSTOM_FIELDS, modifyUpdatingCustomFields } from 'interfaces/customField';
import { SubStepTypes } from 'interfaces';
import { isEmpty } from 'lodash/fp';
import { UserContext, usePermissions } from 'providers/userContextProvider';
import { PersonsOfInterestVisual } from './personsOfInterest.visual';
import { getEnabledCustomFields } from '../utils';

const FETCH_USER = (derivedCustomFields?: string[]) => gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        isOfficerOfPublicCompany
        isOwnerOfPublicCompany
        isMemberOfIiroc
        politicallyExposedForeignPerson
        politicallyExposedDomesticPerson
        closeAssociateOfPEP
        insiderSymbols
        ssSymbols
        isEntityRegulated
        regulatorName
        firmNumber
        isReportingIssuer
        issuerName
        issuerExchange
        issuerTicker
        isRegisteredWithCRA
        isNonProfit
        isForThirdParty
        charityRegistrationNumber
        receivesDonationsFromPublic
        isAFinancialEntity
        isAffiliateOfFinancialEntity
        instructionsFromFinancialEntity
        isPublicBody
        isLargeCorporation
        powerOfAttorneyGranted
        headOfInternationalOrganization
        hasGuarantor
        otherPersonGuaranteeNames
        tradingAuthorityOverAccountGranted
        tradingAuthorityName
        discretionOverAccountGranted
        advisorInterest
        advisorInterestDetail
        investmentFirmTrade
        investmentFirmTradeName
        useBorrowedMoney
        thirdPartyName
        pepReason
        ${derivedCustomFields ? `customFields(keys: ${JSON.stringify(derivedCustomFields)}) {
          key
          value
          customField { id translatedName { en fr } translatedDescription{ en fr} type format}
          selectedOptions { value displayText { en fr } }
        }` : ''}
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;

export const PersonsOfInterest = ({
  options, userId, onNext, stepLoading, workflowCompletion, grid = false, updateMode = false, defaultOptionsToUndefined = false,
}: {
  options: any, userId: string, onNext: () => void, stepLoading: boolean, workflowCompletion?: any, grid?: boolean, updateMode?: boolean, defaultOptionsToUndefined?: boolean,
}) => {
  const { activeOrganization } = useContext(UserContext);
  const { permissions } = usePermissions();
  const [derivedCustomFields, setDerivedCustomFields] = useState<CustomField[]>();
  const [derivedCustomFieldsKeys, setDerivedCustomFieldsKeys] = useState<string[]>();
  const enabledCustomFields = getEnabledCustomFields(options);
  const [userData, setUserData] = useState<any>({
    isOfficerOfPublicCompany: null,
    isOwnerOfPublicCompany: null,
    isMemberOfIiroc: null,
    politicallyExposedForeignPerson: null,
    politicallyExposedDomesticPerson: null,
    closeAssociateOfPEP: null,
    insiderSymbols: null,
    ssSymbols: null,
    isEntityRegulated: null,
    regulatorName: null,
    firmNumber: null,
    isReportingIssuer: null,
    issuerName: null,
    issuerExchange: null,
    issuerTicker: null,
    isNonProfit: null,
    isRegisteredWithCRA: null,
    charityRegistrationNumber: null,
    receivesDonationsFromPublic: null,
    isForThirdParty: null,
    isAFinancialEntity: null,
    isAffiliateOfFinancialEntity: null,
    instructionsFromFinancialEntity: null,
    isPublicBody: null,
    isLargeCorporation: null,
    powerOfAttorneyGranted: null,
    headOfInternationalOrganization: null,
    hasGuarantor: null,
    otherPersonGuaranteeNames: null,
    tradingAuthorityOverAccountGranted: null,
    tradingAuthorityName: null,
    discretionOverAccountGranted: null,
    advisorInterest: null,
    advisorInterestDetail: null,
    investmentFirmTrade: null,
    investmentFirmTradeName: null,
    useBorrowedMoney: null,
    thirdPartyName: null,
    pepReason: null,
    customFields: [],
  });

  const { loading: customFieldLoading } = useQuery(FETCH_CUSTOM_FIELDS, {
    variables: {
      input: {
        filter: {
          workflowStep: SubStepTypes.PERSONS_OF_INTEREST,
          organizationId: activeOrganization?.id ?? undefined,
          keys: !isEmpty(enabledCustomFields) ? enabledCustomFields : undefined,
        },
        pagination: {
          perPage: 100,
        },
      },
    },
    fetchPolicy: 'no-cache',
    skip: !permissions.includes('read:custom_fields') || enabledCustomFields.length === 0,
    onCompleted: async (res: any) => {
      const customFieldKeys = res?.fetchCustomFields?.customFields?.map((a: any) => (a.key));
      setDerivedCustomFields(res?.fetchCustomFields?.customFields);
      await fetchUser({
        variables: {
          userId,
        },
        query: customFieldKeys ? FETCH_USER(customFieldKeys) : undefined,
      });
    },
  });

  const [fetchUser, { data, loading }] = useLazyQuery(FETCH_USER(), {
    variables: { userId },
    fetchPolicy: 'no-cache',
  });

  const { data: defaultdata } = useQuery(FETCH_USER(), {
    variables: { userId },
    fetchPolicy: 'no-cache',
    skip: permissions.includes('read:custom_fields') && enabledCustomFields.length !== 0,
  });

  useEffect(() => {
    if (derivedCustomFields) {
      const fields = derivedCustomFields.map((a) => a.key);
      setDerivedCustomFieldsKeys(fields);
    }
  }, [derivedCustomFields]);

  const [updateUser, { loading: updateUserLoading }] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      onNext();
    },
    variables: {
      input: {
        ...userData,
        ssSymbols: !userData.isOfficerOfPublicCompany && !userData.isOwnerOfPublicCompany ? [] : userData.ssSymbols,
        insiderSymbols: !userData.isMemberOfIiroc ? [] : userData.insiderSymbols,
        customFields: modifyUpdatingCustomFields(userData?.customFields, derivedCustomFields ?? [], derivedCustomFieldsKeys),
        userId,
      },
    },
  });

  useEffect(() => {
    const fetchedUserData = defaultdata || data;
    if (fetchedUserData) {
      setUserData({
        isOfficerOfPublicCompany: fetchedUserData.fetchUser.user.isOfficerOfPublicCompany ?? (defaultOptionsToUndefined ? undefined : false),
        isOwnerOfPublicCompany: fetchedUserData.fetchUser.user.isOwnerOfPublicCompany ?? (defaultOptionsToUndefined ? undefined : false),
        isMemberOfIiroc: fetchedUserData.fetchUser.user.isMemberOfIiroc ?? (defaultOptionsToUndefined ? undefined : false),
        politicallyExposedForeignPerson: fetchedUserData.fetchUser.user.politicallyExposedForeignPerson ?? (defaultOptionsToUndefined ? undefined : false),
        politicallyExposedDomesticPerson: fetchedUserData.fetchUser.user.politicallyExposedDomesticPerson ?? (defaultOptionsToUndefined ? undefined : false),
        closeAssociateOfPEP: fetchedUserData.fetchUser.user.closeAssociateOfPEP ?? (defaultOptionsToUndefined ? undefined : false),
        insiderSymbols: fetchedUserData.fetchUser.user.insiderSymbols || undefined,
        ssSymbols: fetchedUserData.fetchUser.user.ssSymbols || undefined,
        isEntityRegulated: fetchedUserData.fetchUser.user.isEntityRegulated ?? (defaultOptionsToUndefined ? undefined : false),
        regulatorName: fetchedUserData.fetchUser.user.regulatorName || undefined,
        firmNumber: fetchedUserData.fetchUser.user.firmNumber || undefined,
        isReportingIssuer: fetchedUserData.fetchUser.user.isReportingIssuer ?? (defaultOptionsToUndefined ? undefined : false),
        issuerName: fetchedUserData.fetchUser.user.issuerName || undefined,
        issuerExchange: fetchedUserData.fetchUser.user.issuerExchange || undefined,
        issuerTicker: fetchedUserData.fetchUser.user.issuerTicker || undefined,
        isNonProfit: fetchedUserData.fetchUser.user.isNonProfit ?? (defaultOptionsToUndefined ? undefined : false),
        isRegisteredWithCRA: fetchedUserData.fetchUser.user.isRegisteredWithCRA ?? (defaultOptionsToUndefined ? undefined : false),
        charityRegistrationNumber: fetchedUserData.fetchUser.user.charityRegistrationNumber || undefined,
        receivesDonationsFromPublic: fetchedUserData.fetchUser.user.receivesDonationsFromPublic ?? (defaultOptionsToUndefined ? undefined : false),
        isForThirdParty: fetchedUserData.fetchUser.user.isForThirdParty ?? (defaultOptionsToUndefined ? undefined : false),
        isAFinancialEntity: fetchedUserData.fetchUser.user.isAFinancialEntity ?? (defaultOptionsToUndefined ? undefined : false),
        isAffiliateOfFinancialEntity: fetchedUserData.fetchUser.user.isAffiliateOfFinancialEntity ?? (defaultOptionsToUndefined ? undefined : false),
        instructionsFromFinancialEntity: fetchedUserData.fetchUser.user.instructionsFromFinancialEntity ?? (defaultOptionsToUndefined ? undefined : false),
        isPublicBody: fetchedUserData.fetchUser.user.isPublicBody ?? (defaultOptionsToUndefined ? undefined : false),
        isLargeCorporation: fetchedUserData.fetchUser.user.isLargeCorporation ?? (defaultOptionsToUndefined ? undefined : false),
        powerOfAttorneyGranted: fetchedUserData.fetchUser.user.powerOfAttorneyGranted ?? (defaultOptionsToUndefined ? undefined : false),
        headOfInternationalOrganization: fetchedUserData.fetchUser.user.headOfInternationalOrganization ?? (defaultOptionsToUndefined ? undefined : false),
        hasGuarantor: fetchedUserData.fetchUser.user.hasGuarantor ?? (defaultOptionsToUndefined ? undefined : false),
        otherPersonGuaranteeNames: fetchedUserData.fetchUser.user.otherPersonGuaranteeNames || undefined,
        tradingAuthorityOverAccountGranted: fetchedUserData.fetchUser.user.tradingAuthorityOverAccountGranted ?? (defaultOptionsToUndefined ? undefined : false),
        tradingAuthorityName: fetchedUserData.fetchUser.user.tradingAuthorityName || undefined,
        discretionOverAccountGranted: fetchedUserData.fetchUser.user.discretionOverAccountGranted ?? (defaultOptionsToUndefined ? undefined : false),
        advisorInterest: fetchedUserData.fetchUser.user.advisorInterest ?? (defaultOptionsToUndefined ? undefined : false),
        advisorInterestDetail: fetchedUserData.fetchUser.user.advisorInterestDetail || undefined,
        investmentFirmTrade: fetchedUserData.fetchUser.user.investmentFirmTrade ?? (defaultOptionsToUndefined ? undefined : false),
        investmentFirmTradeName: fetchedUserData.fetchUser.user.investmentFirmTradeName || undefined,
        useBorrowedMoney: fetchedUserData.fetchUser.user.useBorrowedMoney ?? (defaultOptionsToUndefined ? undefined : false),
        thirdPartyName: fetchedUserData.fetchUser.user.thirdPartyName || undefined,
        pepReason: fetchedUserData.fetchUser.user.pepReason || undefined,
        customFields: fetchedUserData.fetchUser?.user?.customFields || [],
      });
    }
  }, [data, defaultdata, defaultOptionsToUndefined]);

  return (
    <PersonsOfInterestVisual
      options={options}
      userData={userData}
      updateUser={setUserData}
      continueFunc={updateUser}
      loading={loading || updateUserLoading || stepLoading || customFieldLoading}
      activeCustomFields={derivedCustomFieldsKeys}
      grid={grid}
      updateMode={updateMode}
      workflowCompletion={workflowCompletion}
      defaultOptionsToUndefined={defaultOptionsToUndefined}
    />
  );
};

export default PersonsOfInterest;

import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import NewIndividual from './components/newIndividual';
import { FilterIndividuals } from './components/filterIndividuals';
import { Box, Skeleton } from '../../1-primative';
import {
  Table, TableRow, TableCell, TableBody, Pagination, TextField, Badge, CardContent, TableHeadCell, Card,
} from '../../2-component';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { User } from '../../../interfaces';
import { formatMoneyValue, generateClientNameString } from '../../../util';
import { usePageState } from '../../../util/usePageState';
import { useLocalization } from '../../../util/useLocalization';
import { translateBackend } from '../../../assets/i18n/config';

const FETCH_USERS = (permissions: string[]) => gql`
  query searchUsers($input: UserSearchInput!, $currency: StatisticsCurrencyTypes) {
    searchUsers(input: $input) {
      users {
        id
        ${permissions.includes('read:client_low_risk_pii') ? 'firstName lastName primaryEmail' : ''}
        ${permissions.includes('read:client_high_risk_pii') ? 'dateOfBirth' : ''}
        ${permissions.includes('read:organizations') ? 'organization { name }' : ''}
        statistics(input: { currency: $currency }) { marketValueCents }
        incompleteFields
        completedAt
      }
      totalCount
    }
  }
`;

const DEFAULT_INDIVIDUAL_TABLE = [
  { label: { en: 'Name', fr: 'Nom' }, type: 'name' },
  { label: { en: 'Email', fr: 'Courriel' }, type: 'email' },
  { label: { en: 'Date of Birth', fr: 'Date de naissance' }, type: 'dateOfBirth' },
  { label: { en: 'Profile Completed', fr: 'Terminé à' }, type: 'profileCompleted' },
  { label: { en: 'Total Assets', fr: 'Total des actifs' }, type: 'totalAssets' },
  { label: { en: 'Organization', fr: 'Organisation' }, type: 'organization' },
];

type TableOption = typeof DEFAULT_INDIVIDUAL_TABLE[number];

const INDIVIDUAL_TABLE_SORT_MAP: Record<string, string> = {
  name: 'firstName',
  email: 'email',
  dateOfBirth: 'dateOfBirth',
  profileCompleted: 'completedAt',
  totalAssets: 'marketValueCents',
  organization: 'organizationName',
};

const PERMISSIONS_TO_CREATE_CLIENT = ['write:client_basic', 'write:client_low_risk_pii', 'write:client_high_risk_pii', 'write:client_suitability'];
const RIGHT_ALIGNED_COLS = ['totalAssets', 'organization'];

export const Individuals = ({ options = {} }: { options?: any }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['clients']);
  const { permissions } = usePermissions();
  const { activeOrganization, activeCurrency, userContext } = useContext(UserContext);

  const [page, setPage] = usePageState(1, 'page');
  const [searchText, setSearchText] = usePageState('', 'q');
  const [completedUsersOnly, setCompletedUsersOnly] = usePageState(false, 'co');
  const [sortField, setSortField] = usePageState('createdAt', 'sf');
  const [sortDesc, setSortDesc] = usePageState(true, 'sd');
  const [pageSize, setPageSize] = usePageState(15, 'ps');

  const tableOptions = options.table || userContext.role?.individualTableStaticPageWidgetConfiguration?.widget?.options?.table || DEFAULT_INDIVIDUAL_TABLE;

  const {
    loading, data, refetch, previousData,
  } = useQuery(FETCH_USERS(permissions), {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filter: {
          query: searchText,
          organizationId: activeOrganization.id,
          ...completedUsersOnly && { isComplete: true },
          affiliateUsersOnly: false,
          types: ['INDIVIDUAL'],
        },
        pagination: {
          sortField, sortDesc, perPage: pageSize, page,
        },
      },
      currency: activeCurrency || 'CAD',
    },
  });

  const onSort = (field: string) => {
    if (sortField === field) setSortDesc(!sortDesc);
    setSortField(field);
  };

  const sortDirection = (field: string) => (sortField === field ? sortDesc ? 'desc' : 'asc' : undefined);

  return (
    <Card loading={loading}>
      <Box>
        <CardContent>
          <Box display='flex' justifyContent='space-between' flexWrap='wrap'>
            <TextField value={searchText} leadingIcon='search' onChange={(event: any) => {
              setSearchText(event.target.value);
              setPage(1);
            }} />
            <Box display='flex'>
              <FilterIndividuals completedUsersOnly={completedUsersOnly} setCompletedUsersOnly={setCompletedUsersOnly} />
              {permissions.some((permission) => PERMISSIONS_TO_CREATE_CLIENT.includes(permission)) && <NewIndividual afterCreate={refetch} />}
            </Box>
          </Box>
        </CardContent>
        <Box sx={{ overflowX: 'auto' }}>
          <Table sx={{ minWidth: 650 }} aria-label="table">
            <TableBody>
              <TableRow>
                {!permissions.includes('read:client_low_risk_pii') && <TableHeadCell>{t('table.id')}</TableHeadCell>}
                {tableOptions.map((opt: TableOption) => {
                  const right = RIGHT_ALIGNED_COLS.includes(opt.type);
                  const sortValue = INDIVIDUAL_TABLE_SORT_MAP[opt.type];

                  if (['name', 'email'].includes(opt.type) && !permissions.includes('read:client_low_risk_pii')) return null;
                  if (opt.type === 'dateOfBirth' && !permissions.includes('read:client_high_risk_pii')) return null;
                  return <TableHeadCell right={right} isSortable onClick={() => onSort(sortValue)} sortDirection={sortDirection(sortValue)}>{translateBackend(opt.label)}</TableHeadCell>;
                })}
              </TableRow>

              {loading && !previousData && [...Array(15)].map((x: any, i: number) => (
                <TableRow key={i}>
                  {!permissions.includes('read:client_low_risk_pii') && <TableCell><Skeleton width='100%' /></TableCell>}
                  {tableOptions.map((opt: TableOption) => {
                    if (['name', 'email'].includes(opt.type) && !permissions.includes('read:client_low_risk_pii')) return null;
                    if (opt.type === 'dateOfBirth' && !permissions.includes('read:client_high_risk_pii')) return null;
                    return <TableCell><Skeleton width='100%' /></TableCell>;
                  })}
                </TableRow>
              ))}

              {(data || previousData)?.searchUsers?.users?.map((user: User) => (
                <TableRow hover key={user.id} sx={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => navigate(`/clients/${user.id}`)}>
                {!permissions.includes('read:client_low_risk_pii') && <TableCell>{user.id}</TableCell>}
                {tableOptions.map((opt: TableOption) => <IndividualsCell user={user} type={opt.type} />)}
              </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Pagination
          count={Math.ceil(((data || previousData)?.searchUsers?.totalCount ?? 0) / pageSize)}
          page={page}
          perPage={pageSize}
          onChangePerPage={(newPageSize) => setPageSize(newPageSize)}
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{ p: 1, textAlign: 'right', '.MuiPagination-ul': { justifyContent: 'end' } }}
        />
      </Box >
    </Card>
  );
};

export default Individuals;

const IndividualsCell = ({ user, type }: { user: User, type: string }) => {
  const { t } = useTranslation(['clients']);
  const { permissions } = usePermissions();
  const { localizedDateTime, localizedDate } = useLocalization();

  switch (type) {
    case 'name':
      return permissions.includes('read:client_low_risk_pii') ? <TableCell>{generateClientNameString(user)}</TableCell> : null;
    case 'email':
      return permissions.includes('read:client_low_risk_pii') ? <TableCell>{user.primaryEmail}</TableCell> : null;
    case 'dateOfBirth':
      return permissions.includes('read:client_high_risk_pii') ? <TableCell>{localizedDate(user.dateOfBirth)}</TableCell> : null;
    case 'profileCompleted':
      return <TableCell>{user.completedAt ? localizedDateTime(user.completedAt) : <Badge label={t('table.notCompleted')} color='negative' />}</TableCell>;
    case 'totalAssets':
      return <TableCell right>{formatMoneyValue(user.statistics?.marketValueCents)}</TableCell>;
    case 'organization':
      return <TableCell right>{user.organization?.name}</TableCell>;
    default:
      return null;
  }
};

import { useMutation, gql } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import { CurrentWebHookType, EventObject } from '../../../../../interfaces/webHook';
import { FETCH_WEBHOOKS } from '../webhooks';
import { WebHookType, Attributes } from '../../../../../interfaces';
import { ValidateRule } from '../../../../../interfaces/validateRule';
import { validateForm } from '../../../../../util';
import { Box } from '../../../../1-primative';
import { SelectField, TextField, MenuItem } from '../../../../2-component';
import { ConfirmationModal, FormModal } from '../../../../3-pattern';
import Attribute from './attribute';

export const UPDATE_WEBHOOK = gql`
    mutation updateWebhook($input: UpdateWebhookInput!) {
        updateWebhook(input: $input) {
            webhook{
                id
            }
        }
    }
`;

const DELETE_WEBHOOK = gql`
  mutation deleteWebhook($webhookId: String!) {
    deleteWebhook(webhookId: $webhookId) {
      webhook{
        id
      }
    }
  }
`;

interface EditWebHookProps {
  currentWebHook: CurrentWebHookType;
  open: boolean;
  handleEditClose: () => void;
}

export const EditWebhook = ({ currentWebHook, open, handleEditClose }: EditWebHookProps) => {
  const { t } = useTranslation(['devSettings']);
  const { showToast } = useGlobalToast();
  const [openDeleteWebHookModal, setOpenDeleteWebHookModal] = useState(false);
  const [editWebHook, setEditWebHook] = useState({
    id: currentWebHook.id,
    type: currentWebHook.type,
    objectType: currentWebHook.objectType,
    customHeaders: currentWebHook.customHeaders,
    customHeadersObject: {} as Attributes,
    eventType: currentWebHook.eventType,
    gql: currentWebHook.gql || '',
    url: currentWebHook.url || '',
    customPayloadAttributes: currentWebHook.customPayloadAttributes,
    customPayloadAttributesObject: {} as Attributes,
    signedJwtHeader: currentWebHook.signedJwtHeader,
    mutationName: currentWebHook.mutationName || '',
    mutationInputName: currentWebHook.mutationInputName || '',
  });

  const formRules: ValidateRule[] = [
    {
      key: 'type',
      rule: 'SHOULD_EXIST',
    },
    {
      key: 'objectType',
      rule: 'SHOULD_EXIST',
    },
    {
      key: 'gql',
      rule: 'SHOULD_EXIST',
    },
    {
      key: 'url',
      rule: 'SHOULD_EXIST',
    },
  ];

  if (currentWebHook.type === WebHookType.GRAPHQL) {
    formRules.push({
      key: 'mutationName',
      rule: 'SHOULD_EXIST',
    }, {
      key: 'mutationInputName',
      rule: 'SHOULD_EXIST',
    });
  }

  const normalizeAttributes = (attributes: Attributes) => {
    const normalizedAttributes: Record<string, string> = {};
    for (const key in attributes) {
      if (Object.prototype.hasOwnProperty.call(attributes, key)) {
        let normalizedKey = key;
        normalizedKey = normalizedKey.replace(/^\d+-/, '');

        if (normalizedAttributes[normalizedKey]) {
          normalizedAttributes[normalizedKey] += `, ${attributes[key]}`;
        } else {
          normalizedAttributes[normalizedKey] = attributes[key];
        }
      }
    }
    return normalizedAttributes;
  };
  const [updateWebHook, { loading }] = useMutation(UPDATE_WEBHOOK, {
    variables: {
      input: {
        webhookId: currentWebHook.id,
        url: editWebHook.url,
        signedJwtHeader: editWebHook.signedJwtHeader,
        objectType: editWebHook.objectType,
        mutationName: editWebHook.mutationName,
        mutationInputName: editWebHook.mutationInputName,
        gql: editWebHook.gql,
        eventType: editWebHook.eventType,
        customHeaders: normalizeAttributes(editWebHook.customHeadersObject),
        customPayloadAttributes: normalizeAttributes(editWebHook.customPayloadAttributesObject),
      },
    },
    refetchQueries: [FETCH_WEBHOOKS],
  });

  useEffect(() => {
    const customHeadersObject = currentWebHook.customHeaders || {};
    const customPayloadAttributesObject = currentWebHook.customPayloadAttributes || {};

    setEditWebHook((prevEditWebHook) => ({
      ...prevEditWebHook,
      type: currentWebHook.type,
      id: currentWebHook.id,
      gql: currentWebHook.gql,
      url: currentWebHook.url,
      signedJwtHeader: currentWebHook.signedJwtHeader,
      mutationInputName: currentWebHook.mutationInputName,
      mutationName: currentWebHook.mutationName,
      objectType: currentWebHook.objectType,
      eventType: currentWebHook.eventType,
      customHeaders: currentWebHook.customHeaders,
      customPayloadAttributes: currentWebHook.customPayloadAttributes,
      customHeadersObject: customHeadersObject as Record<string, string>,
      customPayloadAttributesObject: customPayloadAttributesObject as Record<string, string>,
    }));
  }, [currentWebHook]);

  const update = async () => {
    const response = await updateWebHook();
    if (response?.data) {
      showToast({ severity: 'success', message: t('WebHookModal.successToastMessage') });
      handleEditClose();
    }
  };

  const deleteWebHook = async () => {
    setOpenDeleteWebHookModal(true);
  };

  const menuItems: any[] | undefined = [];

  menuItems.push(
      <MenuItem key='delete' onClick={async () => deleteWebHook()}>
        {t('WebHookModal.delete')}
      </MenuItem>,
  );

  const [deleteWebHookMutation] = useMutation(DELETE_WEBHOOK, {
    variables: { webhookId: currentWebHook.id },
    refetchQueries: [FETCH_WEBHOOKS],
  });

  const confirmWebHookDeletion = async () => {
    const response = await deleteWebHookMutation();
    if (response?.data) {
      showToast({ severity: 'success', message: t('WebHookModal.deleteSuccessToastMessage') });
      setOpenDeleteWebHookModal(false);
      handleEditClose();
    }
  };

  const handleCustomHeaderChange = (attributes: { key: string; value: string }[]) => {
    const customHeadersObject: Record<string, string> = {};
    attributes.forEach((attribute) => {
      customHeadersObject[attribute.key] = attribute.value;
    });
    setEditWebHook((prevEditWebHook) => ({
      ...prevEditWebHook,
      customHeadersObject,
    }));
  };

  const handleCustomPayloadAttributeChange = (attributes: { key: string; value: string }[]) => {
    const customPayloadAttributesObject: Record<string, string> = {};
    attributes.forEach((attribute) => {
      customPayloadAttributesObject[attribute.key] = attribute.value;
    });
    setEditWebHook((prevEditWebHook) => ({
      ...prevEditWebHook,
      customPayloadAttributesObject,
    }));
  };

  const { customPayloadAttributesObject } = editWebHook;
  const customPayloadAttributeList = Object.keys(customPayloadAttributesObject).map((key) => ({
    key,
    value: customPayloadAttributesObject[key],
  }));

  const { customHeadersObject } = editWebHook;
  const customHeadersList = Object.keys(customHeadersObject).map((key) => ({
    key,
    value: customHeadersObject[key],
  }));

  return (
    <FormModal
      disabled={!validateForm(formRules, editWebHook)}
      state={editWebHook}
      loading={loading}
      title={t('WebHookModal.editTitle')}
      onSubmit={update}
      open={open}
      menuItems={menuItems}
      handleClose={handleEditClose}
      formButton={t('shared:update')}
    >
      <Box display='flex' flexDirection='column' gap={2}>
        <SelectField
          required
          disabled={true}
          value={editWebHook.type}
          label={t('WebHookModal.type')}
          fullWidth
          onChange={(e: any) => {
            setEditWebHook({ ...editWebHook, type: e.target.value });
          }}
        >
          {Object.keys(WebHookType).map((x: any) => (
            <MenuItem key={x.toString()} value={x}>
              {t(`webHooksTableOptions.type.${x}`)}
            </MenuItem>
          ))}
        </SelectField>
        <SelectField
          required
          value={editWebHook.objectType}
          label={t('WebHookModal.objectType')}
          fullWidth
          onChange={(e: any) => {
            setEditWebHook({ ...editWebHook, objectType: e.target.value });
          }}
        >
          {Object.keys(EventObject).map((x: any) => (
            <MenuItem key={x.toString()} value={x}>
              {t(`webHooksTableOptions.objectType.${x}`)}
            </MenuItem>
          ))}
        </SelectField>
        <Attribute
          attributesList={customHeadersList}
          onAttributeChange={handleCustomHeaderChange}
          attributeKeyLabel={t('WebHookModal.customHeaderKey')}
          attributeValueLabel={t('WebHookModal.customHeaderValue')}
        />
        <TextField
          fullWidth
          label={t('WebHookModal.eventType')}
          value={editWebHook.eventType}
          error={!editWebHook?.eventType || editWebHook?.eventType === ''}
          onChange={(event: any) => setEditWebHook({ ...editWebHook, eventType: event.target.value })}
        />
        <TextField
          fullWidth
          label={t('WebHookModal.graphQL')}
          value={editWebHook.gql}
          error={!editWebHook?.gql || editWebHook?.gql === ''}
          onChange={(event: any) => setEditWebHook({ ...editWebHook, gql: event.target.value })}
        />
        <TextField
          fullWidth
          label={t('WebHookModal.url')}
          value={editWebHook.url}
          onChange={(event: any) => setEditWebHook({ ...editWebHook, url: event.target.value })}
        />
        {editWebHook.type === WebHookType.REST ? (
          <>
            <Attribute
              attributesList={customPayloadAttributeList}
              onAttributeChange={handleCustomPayloadAttributeChange}
              attributeKeyLabel={t('WebHookModal.payloadAttributeKey')}
              attributeValueLabel={t('WebHookModal.payloadAttributeValue')}
            />
              <TextField
                fullWidth
                label={t('WebHookModal.signedJWTHeader')}
                value={editWebHook.signedJwtHeader}
                onChange={(event: any) => setEditWebHook({ ...editWebHook, signedJwtHeader: event.target.value })}
              />
          </>
        ) : (
          <>
              <TextField
                required
                fullWidth
                error={!editWebHook?.mutationName || editWebHook?.mutationName === ''}
                label={t('WebHookModal.mutationName')}
                value={editWebHook.mutationName}
                onChange={(event: any) => setEditWebHook({ ...editWebHook, mutationName: event.target.value })}
              />
              <TextField
                required
                fullWidth
                error={!editWebHook?.mutationInputName || editWebHook?.mutationInputName === ''}
                label={t('WebHookModal.mutationInputName')}
                value={editWebHook.mutationInputName}
                onChange={(event: any) => setEditWebHook({ ...editWebHook, mutationInputName: event.target.value })}
              />
          </>
        )}
        <ConfirmationModal
          open={openDeleteWebHookModal}
          onCancel={() => setOpenDeleteWebHookModal(false)}
          onConfirm={confirmWebHookDeletion}
          title={t('WebHookModal.deleteWebHookTitle')}
          bodyText={t('WebHookModal.deleteWebHookBody')}
        />
      </Box>
    </FormModal>
  );
};

import { useTranslation } from 'react-i18next';
import RepCodes from '../../repCodes';
import { SettingsTitle } from '../../../3-pattern';

export const RepCodesSettings = () => {
  const { t } = useTranslation(['settings']);
  return (
    <>
      <SettingsTitle title={t('settings:repCodes.title')} description={t('settings:repCodes.description')} />
      <RepCodes />
    </>
  );
};

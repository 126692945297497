import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { omit } from 'lodash';
import { BareLocalization, Localization } from '../../../../../interfaces';
import { Badge, Tooltip } from '../../../../2-component';
import { FormModal } from '../../../../3-pattern';
import { LocalizationForm } from './localizationForm';
import { FETCH_LOCALIZATIONS } from '../localizationConfiguration';

const UPDATE_LOCALIZATION = gql`
mutation updateLocalization($localizationId: ObjectID!, $localization: LocalizationInput!) {
  updateLocalization(localizationId: $localizationId, localization: $localization) {
    localization {
      id
    }
  }
}
`;

export const EditLocalization = ({
  localization, handleClose, inherited,
}: { localization: Localization, handleClose: () => void, inherited?: boolean }) => {
  const { t } = useTranslation(['orgSettings', 'shared']);

  const [editingLocalization, setEditingLocalization] = useState<BareLocalization>(
    omit(localization, ['id', 'organization', '__typename']) as BareLocalization,
  );

  const [updateLocalization, { loading }] = useMutation(UPDATE_LOCALIZATION, {
    variables: {
      localizationId: localization.id,
      localization: editingLocalization,
    },
    refetchQueries: [FETCH_LOCALIZATIONS],
    onCompleted: handleClose,
  });

  const allFieldsValid = editingLocalization.name
    && editingLocalization.countries.length > 0
    && editingLocalization.defaultCurrency
    && editingLocalization.languages.length > 0
    && editingLocalization.defaultLanguage
    && editingLocalization.dateFormat;

  return (
    <FormModal
      open
      disabled={inherited || !allFieldsValid}
      loading={loading}
      title={t('localizationModal.editTitle')}
      formButton={t('shared:update')}
      onSubmit={updateLocalization}
      handleClose={handleClose}
    >
      {inherited && (<Tooltip title={t('shared:inheritedTooltip', { model: t('tabs.localizationSettings'), organization: localization.organization?.name })}>
        <Badge label={t('shared:inheritedFlag')} sx={{ mb: 1 }} />
      </Tooltip>)}
      <LocalizationForm
        localization={editingLocalization}
        setLocalization={setEditingLocalization}
        readonly={inherited}
      />
    </FormModal>
  );
};

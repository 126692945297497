import { NotificationChannel, NotificationGroup } from './notificationGroup';
import { Organization } from './organization';
import { RoleProfile } from './roleProfile';
import { TranslatedString } from './shared';

export enum TriggerObjectTypes {
  ACCOUNT = 'Account',
  GOAL = 'Goal',
  SUB_ACCOUNT = 'SubAccount',
  TRANSFER = 'Transfer',
  USER = 'User',
  SCHEDULED_TRANSFER = 'ScheduledTransfer',
  ACCOUNT_STATEMENT = 'AccountStatement',
}

export enum TriggerEventTypes {
  RECONCILED = 'RECONCILED',
  CREATED = 'CREATED',
  CHECK_ACCOUNT_MARKET_VALUE = 'CHECK_ACCOUNT_MARKET_VALUE',
  AWAITING_REVIEW = 'AWAITING_REVIEW',
  SIGNED = 'SIGNED',
  RESIGNED = 'RESIGNED',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  REQUESTED = 'REQUESTED',
  PROCESSING = 'PROCESSING',
  INITIATED = 'INITIATED',
  READY = 'READY',
  FAILED = 'FAILED',
  PERSONAL_ACCOUNT_INFORMATION_UPDATED = 'PERSONAL_ACCOUNT_INFORMATION_UPDATED',
  FROZEN = 'FROZEN',
  PERSONAL_INFORMATION_UPDATED = 'PERSONAL_INFORMATION_UPDATED',
  LOGIN_CREDENTIALS_UPDATED = 'LOGIN_CREDENTIALS_UPDATED',
  USER_ID_CHECK_UPDATED = 'USER_ID_CHECK_UPDATED',
  SUITABILITY_SCORE_UPDATED = 'SUITABILITY_SCORE_UPDATED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SUITABILITY_REVIEW_DUE = 'SUITABILITY_REVIEW_DUE',
  INTEGRATION_FORM_CREATED = 'INTEGRATION_FORM_CREATED',
}

export interface NotificationDefinition {
  id: string,
  name: string,
  titleTemplate: TranslatedString,
  bodyTemplate: TranslatedString,
  linkTemplate?: string,
  linkText?: TranslatedString,
  triggerObjectType?: TriggerObjectTypes,
  triggerEventType?: TriggerEventTypes,
  notificationGroup: NotificationGroup,
  organization: Organization,
  notificationChannels: NotificationChannel[],
  roleProfiles: RoleProfile[],
}

import { useEffect, useState } from 'react';
import {
  gql,
  useLazyQuery,
  useMutation,
} from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '../../../1-primative';
import {
  Button, Dialog, DialogContent, DialogTitle, TextField,
  AddressField, Badge, DialogFooter,
} from '../../../2-component';
import { ConfirmationModal } from '../../../3-pattern';
import { FETCH_AWAITING_INSTIUTIONS_COUNT } from '../../../../providers/globalStatsHooks';
import { usePermissions } from '../../../../providers/userContextProvider';
import { FETCH_INSTITUTIONS, stateInstitutionStateColor } from '../institutionsToReview';
import { generateAddressString } from '../../../../util';
import { useLocalization } from '../../../../util/useLocalization';
import { STATE_CHANGES_FIELD } from '../../../../util/reusableGraphQLFields';

export const TRANSITION_INSTITUTION = gql`
  mutation transitionInstitution($input: TransitionInstitutionInput!) {
    transitionInstitution(input: $input) {
      institution {
        id
      }
    }
  }
`;

export const UPDATE_INSTITUTION = gql`
  mutation updateInstitution($input: UpdateInstitutionInput!) {
    updateInstitution(input: $input) {
      institution {
        id
      }
    }
  }
`;

export const FETCH_INSTITUTION = gql`
  query fetchInstitution($id: ID!) {
    fetchInstitution(id: $id) {
      institution {
        id
        name
        physicalAddress{
          streetName
          city
          houseNumber
          neighborhood
          postal
          province
          unitNumber
        }
        isDraft
        state
        ${STATE_CHANGES_FIELD}
        createdAt
        updatedAt
      }
    }
  }
`;
enum InstitutionStates {
  AWAITING_REVIEW = 'AWAITING_REVIEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}
const transitions = [
  {
    name: 'approve',
    from: [InstitutionStates.AWAITING_REVIEW],
  },
  {
    name: 'reject',
    from: [InstitutionStates.AWAITING_REVIEW, InstitutionStates.APPROVED],
  },
];

const InstitutionViewer = ({
  institution, open, handleClose,
}: { institution: any, open: boolean, handleClose: () => void }) => {
  const { t } = useTranslation(['components', 'institutionsReview']);
  const { localizedDate } = useLocalization();
  const { permissions } = usePermissions();
  const [transitionInstitution] = useMutation(TRANSITION_INSTITUTION, {
    refetchQueries: [FETCH_INSTITUTIONS, FETCH_AWAITING_INSTIUTIONS_COUNT],
  });
  const [institutionName, setInstitutionName] = useState(institution.name || '');
  const [updatedInstitutionObject, setUpdatedInstitutionObject] = useState<any>();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [currentTransition, setCurrentTransition] = useState('');
  const [fetchInstitution, { data: institutionResponse, error }] = useLazyQuery(FETCH_INSTITUTION);
  const [updateInstitution] = useMutation(UPDATE_INSTITUTION, {
    refetchQueries: [FETCH_INSTITUTION, FETCH_INSTITUTIONS, FETCH_AWAITING_INSTIUTIONS_COUNT],
  });
  useEffect(() => {
    if (institution.id) {
      fetchInstitution({
        variables: { id: institution.id },
        fetchPolicy: 'no-cache',
      });
    }
  }, [institution.id, fetchInstitution]);

  if (error) (<Typography>Error</Typography>);

  const confirmTransition = async () => {
    await transitionInstitution({ variables: { input: { institutionId: institution.id, transition: currentTransition } } });
    setOpenConfirmationModal(false);
    handleClose();
  };

  const institutionData = institutionResponse?.fetchInstitution?.institution || institution;

  useEffect(() => {
    if (institutionData) {
      setInstitutionName(institutionData.name || '');
      setUpdatedInstitutionObject(institutionData);
    }
  }, [institutionData]);

  if (!institution) return <></>;

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle onClose={handleClose}>
        {`${t('institutionsReview:institutionPrefix')} ${institutionName}`}
      </DialogTitle>
      <DialogContent>
        <Box display='flex' justifyContent='space-between'>
          <Box display='flex' flexDirection='column'>
            <Typography variant='labelSmall'>
              {t('institutionsReview:filters.state')}
            </Typography>
            <Badge label={t(`institutionsReview:filters.stateOptions.${institutionData.state}`)} color={stateInstitutionStateColor(institutionData.state)} />
          </Box>
          <Box display='flex' flexDirection='column'>
            {institutionData.state === 'APPROVED' && (<>
              <Typography variant='labelSmall'>{t('institutionsReview:filters.approvedDate')} </Typography>
              <Typography variant='bodyLarge'>{localizedDate(institutionData.updatedAt)}</Typography>
            </>)}
          </Box>
          <Box display='flex' flexDirection='column'>
            <Typography variant='labelSmall'>{t('institutionsReview:filters.requestedDate')} </Typography>
            <Typography variant='bodyLarge'>{localizedDate(institutionData.createdAt)}</Typography>
          </Box>
        </Box>
        <Box display='flex' flexDirection='column' gap={1} mt={2}>
          {permissions.includes('write:institution_basic')
            && (<TextField label={t('institutionsReview:filters.name')} fullWidth value={institutionName} onChange={(e: any) => {
              setInstitutionName(e.target.value);
            }} />)}
          {permissions.includes('write:institution_basic') ? (<AddressField address={institution.physicalAddress} label="Address" onChange={(address) => {
            setUpdatedInstitutionObject((prev: any) => ({ ...prev, physicalAddress: address }));
          }} manualAddressEntry />) : (
            <>
              <Typography fontSize='small'>{t('institutionsReview:filters.address')}:&nbsp; </Typography>
              <Typography fontSize='small'>{generateAddressString(institution.physicalAddress)}</Typography>
            </>
          )}
        </Box>
      </DialogContent>
      <DialogFooter>
        <Box display='flex' justifyContent='space-between' width='100%'>
          {permissions.includes('write:institution_basic') && (
            <Button
              key='update-institution'
              type='button'
              variant='tonal'
              onClick={() => {
                const payload = {
                  id: institution.id,
                  physicalAddress: {
                    city: updatedInstitutionObject.physicalAddress.city,
                    houseNumber: updatedInstitutionObject.physicalAddress.houseNumber,
                    neighborhood: updatedInstitutionObject.physicalAddress.neighborhood,
                    postal: updatedInstitutionObject.physicalAddress.postal,
                    province: updatedInstitutionObject.physicalAddress.province,
                    streetName: updatedInstitutionObject.physicalAddress.streetName,
                    unitNumber: updatedInstitutionObject.physicalAddress.unitNumber,
                  },
                  name: institutionName,
                };
                updateInstitution({
                  variables: {
                    input: payload,
                  },
                }).then();
              }}
              label={t('institutionsReview:update')}
            />
          )}
          <Box display={'flex'} gap={1}>
            {permissions.includes('transition:institution_basic') && transitions.map((transition: any) => (
              transition.from.includes(institutionData.state) && (
                <Button key={transition.name} type='button' color={transition.name === 'reject' ? 'destructive' : 'primary'} variant='tonal' onClick={async () => {
                  setCurrentTransition(transition.name);
                  setOpenConfirmationModal(true);
                }} label={t(`institutionsReview:institutionTransitions.${transition.name}`)} />
              )
            ))}
          </Box>
        </Box>
      </DialogFooter>
      <ConfirmationModal
        open={openConfirmationModal}
        title={t('institutionsReview:moderateInstitution')}
        bodyText={t('institutionsReview:confirmationDialogTitle', { transition: currentTransition })}
        onCancel={() => setOpenConfirmationModal(false)}
        onConfirm={() => confirmTransition()}
      />
    </Dialog>
  );
};

export default InstitutionViewer;

import { useTranslation } from 'react-i18next';
import { Box, Switch } from '../../../..';
import { NotificationChannel } from '../../../../../interfaces';

const NotificationChannelsDefinitions = ({
  notificationObject, notificationChannel, index, setNotificationObject,
}: { notificationObject: any, notificationChannel: NotificationChannel, index: number, setNotificationObject: (e: any) => void }) => {
  const { t } = useTranslation(['orgSettings']);

  const onChangeAttribute = (event: boolean, attribute: 'value' | 'setDefaultState' | 'allowEditByClient') => {
    const newNotificationChannels = [...notificationObject.notificationChannels];
    newNotificationChannels.splice(index, 1, { ...notificationObject.notificationChannels[index], [attribute]: event });
    const newNotificationObject = {
      ...notificationObject,
      notificationChannels: newNotificationChannels,
    };
    setNotificationObject({ ...newNotificationObject });
  };

  return (
    <Box display='flex' flexDirection='column' alignItems='flex-start'>
      <Switch
        checked={!!notificationChannel.value}
        label={t(`notificationChannelsOptions.enableTypes.${notificationChannel.type}`)}
        onChange={(e) => onChangeAttribute(e, 'value')}
      />
      {notificationChannel.value && (
        <Box
          sx={{
            display: 'flex', gap: 1.5, flexDirection: 'column', mt: 1.5, ml: 4,
          }}
        >
          <Switch
            checked={!!notificationChannel.setDefaultState}
            label={t('notificationChannelsOptions.setDefaultState')}
            onChange={(e) => onChangeAttribute(e, 'setDefaultState')}
          />
          <Switch
            checked={!!notificationChannel.allowEditByClient}
            label={t('notificationChannelsOptions.allowEditByClient')}
            onChange={(e) => onChangeAttribute(e, 'allowEditByClient')}
          />
        </Box>
      )}
    </Box>
  );
};

export default NotificationChannelsDefinitions;

import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import {
  CurrentIntegrationType, IntegrationState,
  IntegrationTransitionState, IntegrationType,
} from '../../../../interfaces/integrations';
import EditIntegration from './components/editIntegration';
import NewIntegration from './components/newIntegration';
import { Typography, Box, Skeleton } from '../../../1-primative';
import useCopyToClipboard from '../../../../util/useCopyToClipboard';
import {
  Table, TableHeadCell, TableRow, TableCell, TableBody, Menu, MenuItem, IconButton, Badge, Tooltip,
  Card,
  CardContent,
} from '../../../2-component';
import ConfirmationDialog from '../../../3-pattern/confirmationDialog/confirmationDialog';
import { UpdateOrganizationSwitch } from '../../../4-module/updateOrganizationSwitch/updateOrganizationSwitch';
import { SettingsTitle } from '../../../3-pattern';

export const FETCH_INTEGRATIONS = gql`
  query fetchIntegrations($input: FetchIntegrationsInput!) {
    fetchIntegrations(input: $input) {
      integrations {
        id
        configuration
        provider
        type
        state
        scope
        organization {
          id
          name
          parent {
            id
            name
          }
        }
      }
      totalCount
    }
  }
`;

const TRANSITION_INTEGRATION = gql`
  mutation transitionIntegration($input: TransitionIntegrationInput!) {
    transitionIntegration(input: $input) {
      integration {
        id
      }
    }
  }
`;
export const IntegrationSettings = () => {
  const { t } = useTranslation(['devSettings', 'settings']);
  const { permissions } = usePermissions();
  const navigate = useNavigate();
  const [copy] = useCopyToClipboard();
  const { activeOrganization } = useContext(UserContext);
  const [selectedIntegration, setSelectedIntegration] = useState<CurrentIntegrationType>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClose = () => {
    setOpenEditModal(false);
  };

  const {
    loading, error, data, previousData,
  } = useQuery(FETCH_INTEGRATIONS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
      },
    },
    fetchPolicy: 'no-cache',
  });
  const integrationsList = data?.fetchIntegrations?.integrations;

  const [transitionIntegration] = useMutation(TRANSITION_INTEGRATION, { refetchQueries: [FETCH_INTEGRATIONS] });

  const handleTransition = async ({ integrationId, transition }: { integrationId: string; transition: IntegrationTransitionState }) => {
    await transitionIntegration({
      variables: {
        input: {
          integrationId,
          transition,
        },
      },
    });
  };

  const handleDelete = async ({ integrationId, transition }: { integrationId: string; transition: IntegrationTransitionState }) => {
    await handleTransition({ integrationId, transition });
    setOpenDeleteDialog(false);
  };

  if (error) <Typography>Error</Typography>;

  return (
    <>
      <SettingsTitle title={t('settings:integrations.title')} description={t('settings:integrations.description')} />
      <Card loading={loading}>
        {(permissions.includes('write:integrations') || permissions.includes('write:advanced_organization_settings')) && (
          <CardContent>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              {permissions.includes('write:advanced_organization_settings') ? (
                <UpdateOrganizationSwitch
                  field='useParentIntegrations'
                  label={t('settings:integrations.useParentIntegrations')}
                  organizationId={activeOrganization.id}
                  refetchQueries={[FETCH_INTEGRATIONS]}
                />
              ) : <Box />}
              {permissions.includes('write:integrations') && (
                <NewIntegration defaultOrg={activeOrganization.id ?? ''} integrationsList={integrationsList} />
              )}
            </Box>
          </CardContent>
        )}
        <Table>
          <TableBody>
            <TableRow>
              <TableHeadCell>{t('integrationsTable.type')}</TableHeadCell>
              <TableHeadCell>{t('integrationsTable.name')}</TableHeadCell>
              <TableHeadCell>{t('integrationsTable.organization')}</TableHeadCell>
              <TableHeadCell>{t('integrationsTable.state')}</TableHeadCell>
              <TableHeadCell right>{t('integrationsTable.organizationSetting')}</TableHeadCell>
            </TableRow>
            { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
              <TableRow key={i}>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
              </TableRow>
            ))}
            {(data || previousData)?.fetchIntegrations?.integrations?.map((integration: any) => (
              <TableRow
                hover
                sx={{ cursor: 'pointer' }}
                key={integration.id}
                onClick={() => {
                  setSelectedIntegration(integration);
                  if (integration.organization.id === activeOrganization.id && permissions.includes('write:integrations')) {
                    setOpenEditModal(true);
                  }
                }}
              >
                <TableCell>
                  <Typography>{t(`integrationsTableOptions.type.${integration.type}`)}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t(`integrationsTableOptions.provider.${integration.provider}`)}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{integration.organization.name}</Typography>
                </TableCell>
                <TableCell>
                  <Badge
                    label={integration.state === IntegrationState.ACTIVE ? t('integrationsTable.active') : t('integrationsTable.inactive')}
                    color={integration.state === IntegrationState.ACTIVE ? 'positive' : 'negative'}
                  />
                </TableCell>
                <TableCell right>
                  {integration.organization.id !== activeOrganization.id ? (
                    <Tooltip title={t('shared:inheritedTooltip', { model: 'Integration', organization: integration.organization?.name })}>
                      <Badge label={t('integrationsTableOptions.organizationSetting.inherited')} />
                    </Tooltip>
                  ) : (
                    <IconButton
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setAnchorEl(e.currentTarget);
                        setSelectedIntegration(integration);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {/* Context menu   */}
        {selectedIntegration && (
          <>
            <Menu
              anchorEl={anchorEl}
              id='integrations-menu'
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {selectedIntegration.state === IntegrationState.ACTIVE ? (
                <MenuItem onClick={() => handleTransition({ integrationId: selectedIntegration.id, transition: IntegrationTransitionState.DEACTIVATE })}>{t('shared:deactivate')}</MenuItem>
              ) : (
                <MenuItem onClick={() => handleTransition({ integrationId: selectedIntegration.id, transition: IntegrationTransitionState.ACTIVATE })}>{t('shared:activate')}</MenuItem>
              )}
              <MenuItem onClick={() => setOpenDeleteDialog(true)}>{t('shared:delete')}</MenuItem>
              {selectedIntegration.type === IntegrationType.DATA_INGESTION && permissions.includes('read:bulk_upsert_request') && (
                <MenuItem onClick={() => navigate(`bulkUpsertRequests#int:${selectedIntegration.id}`)}>{t('integrationsTableOptions.viewDataIngestionRequests')}</MenuItem>
              )}
              <MenuItem
                style={{ display: 'flex', justifyContent: 'right' }}
                onClick={() => { copy(selectedIntegration.id); handleClose(); }}>
                  <ContentCopyRoundedIcon style={{ marginRight: '10px' }} />
                  <Typography>{selectedIntegration.id}</Typography>
              </MenuItem>
            </Menu>
          </>
        )}

        {/* Edit - Editing Dialog */}
        {selectedIntegration && <EditIntegration currentIntegration={selectedIntegration} open={openEditModal} handleEditClose={handleEditClose} />}

        {/* Delete - Deletion Confirmation */}
        {selectedIntegration && (
          <ConfirmationDialog
            onConfirm={async () => handleDelete({ integrationId: selectedIntegration.id, transition: IntegrationTransitionState.ARCHIVE })}
            onCancel={() => {
              setOpenDeleteDialog(false);
            }}
            title={t('integrationsDeleteModal.title')}
            open={openDeleteDialog}
          >
            <Typography variant='bodyLarge'>{t('integrationsDeleteModal.body')}</Typography>
          </ConfirmationDialog>
        )}
      </Card>
    </>
  );
};

import { gql, useQuery } from '@apollo/client';
import {
  useContext, useState,
} from 'react';
import { MenuItem } from '@mui/material';
import { SelectField } from 'ovComponents/2-component';
import { UserContext } from '../../../providers/userContextProvider';
import { Holding } from '../../../interfaces';
import { translateBackend } from '../../../assets/i18n/config';

export const ACCOUNT_STATS = gql`
  query fetchAccount($accountId: ObjectID!, $includeCustodianStatistics: Boolean!) {
    fetchAccount(accountId: $accountId) {
      account {
        id
        type
        custodianStatistics @include(if: $includeCustodianStatistics) {
          marketValueCents
          holdings {
            financialProduct {
              cusip
              isCash
              translatedName {
                en
              }
              translatedNickName {
                en
              }
            }
          }
        }
      }
    }
  }
`;

interface HoldingSelectFieldProp {
  userId: string,
  setAccountHolding: (value: Holding | null) => void,
  selectedSubAccount?: any | undefined
  selectedHolding?: any | undefined
  sx?: any,
  onBlur?: () => void,
  error?: any,
  label?: any,
  disabled?: boolean,
  options?: any
}

export const HoldingSelectField = ({
  userId, selectedSubAccount, selectedHolding, sx, error, disabled, label, onBlur, setAccountHolding, options,
}: HoldingSelectFieldProp) => {
  const [accountHoldings, setAccountHoldings] = useState< Holding[]>([]);
  const { custodianConnection } = useContext(UserContext);

  const useCustodianStatistics = custodianConnection?.enableFetchCustodianStatistics ? custodianConnection?.enableFetchCustodianStatistics : false;

  const { loading } = useQuery(ACCOUNT_STATS, {
    skip: !selectedSubAccount?.account?.id,
    variables: {
      accountId: selectedSubAccount?.account?.id,
      includeCustodianStatistics: useCustodianStatistics,
    },
    onCompleted: (data) => {
      if (useCustodianStatistics) {
        const updatedHoldings = data?.fetchAccount?.account?.custodianStatistics?.holdings?.filter(
          (holding: Holding) => holding?.financialProduct?.isCash !== true,
        );
        setAccountHoldings(updatedHoldings);
      }
    },
  });

  const setHolding = (cusip: string) => {
    const found = accountHoldings.find((holding: any) => holding?.financialProduct?.cusip === cusip);
    if (found) setAccountHolding(found);
  };

  const handleHoldingsSelect = (value: string) => { setHolding(value); };

  return (
    <>
      <SelectField
          fullWidth
          sx={sx}
          testId="select-holding"
          onChange={(e: any) => handleHoldingsSelect(e.target.value)}
          label={label}
          onBlur={onBlur}
          value={selectedHolding?.financialProduct?.cusip ?? ''}
          disabled={disabled || loading}
          error={error}
      >
        {accountHoldings.map((holding: Holding) => (
          <MenuItem
            key={holding?.financialProduct?.cusip}
            data-testid={`holding-${holding?.financialProduct?.cusip}`}
            value={holding?.financialProduct?.cusip ?? ''}
            style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
          >
            {translateBackend(holding?.financialProduct?.translatedNickName ?? holding?.financialProduct?.translatedName)}
          </MenuItem>
        ))}
      </SelectField>
    </>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { TextField } from '../../2-component';
import { encryptedTaxIdPlaceholder, TaxIdTypes } from '../../../interfaces';

type TextFieldProps = Parameters<typeof TextField>[0];

interface MaskedTextFieldProps extends TextFieldProps {
  taxIdType?: TaxIdTypes,
  value?: string,
  disclosedValue?: string,
  setValue: (newValue: string) => void,
}

export const MaskedTaxIdField = ({
  taxIdType,
  value,
  disclosedValue,
  setValue,
  ...restProps
}: MaskedTextFieldProps) => {
  const [lastValue, setLastValue] = useState<string>();
  const [renderCounter, setRenderCounter] = useState(0);
  const [dirty, setDirty] = useState(false);
  const [revealed, setRevealed] = useState(false);

  // render the masked value (*********) as a placeholder (rather than actual value)
  const showPlaceholder = (value && value.match(/^\*+$/))
    ? taxIdType ? encryptedTaxIdPlaceholder(taxIdType) : value
    : undefined;

  // render the visibility toggle 👁️ when there's a disclosed value to show
  const renderVisibilityToggle = !!disclosedValue && disclosedValue !== value && !dirty;

  const renderValue = revealed
    ? (dirty ? value : disclosedValue)
    : (showPlaceholder ? '' : value);

  /* After submit, when value changes from numeric (123456789) to masked (*********)
     we want to give up focus on this field */
  useEffect(() => {
    if (showPlaceholder && lastValue && lastValue.length > 0) {
      setRenderCounter(renderCounter + 1); // trigger re-render -> loose focus
      setDirty(false);
      setRevealed(false);
    }
  }, [lastValue, showPlaceholder]);

  return (
    <TextField
      key={`masked-tax-id-field-${renderCounter}`}
      {...restProps}
      placeholder={showPlaceholder}
      value={renderValue}
      onChange={(e: any) => {
        if (showPlaceholder && e.target.value === '') return;
        if (e.target.value !== disclosedValue) {
          setLastValue(e.target.value);
          setValue(e.target.value);
          setDirty(true);
        }
      }}
      visibilityToggle={renderVisibilityToggle}
      onVisibilityChange={setRevealed}
    />
  );
};

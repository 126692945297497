import React, { useContext } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { gql, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ClearIcon from '@mui/icons-material/Clear';
import { isUndefined } from 'lodash';
import { usePageState } from '../../../util/usePageState';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import {
  Typography, Box,
  Skeleton,
} from '../../1-primative';
import {
  Table, TableRow, TableCell, TableBody, Pagination,
  Card, CardContent, TableHeadCell,
  Badge,
  TextField,
  MenuItem,
  IconButton, DateField,
  Tooltip,
} from '../../2-component';
import { FilterModal, BillingScheduleSelect } from '../../3-pattern';
import DownloadBillingCycle from '../feeReport/components/downloadBillingCycle';
import { BillingCycle, BillingCycleStates } from '../../../interfaces/billingCycle';
import { formatMoneyValue } from '../../../util';
import { textColumnStyles } from '../../../util/styles';
import { useLocalization } from '../../../util/useLocalization';

dayjs.extend(utc);

export const FETCH_BILLING_SCHEDULES_QUERY = `
query fetchBillingCycles($input: FetchBillingCyclesInput!) {
    fetchBillingCycles(input: $input) {
      totalCount
      billingCycles{
        id
        organization { id name }
        startDate
        endDate
        calculationDate
        billingDate
        billingSchedule{
          id
          frequency
        }
        marketValueCentsOnLastDay
        chargeableMarketValueCentsOnLastDay
        feeCents
        salesTaxCents
        projectedFeeAndTaxCents
        reconciledFees { feeCents salesTaxCents }
        calculatedAt
        state
      }
    }
  }
`;
export const FETCH_BILLING_CYCLES = gql`${FETCH_BILLING_SCHEDULES_QUERY}`;

export const getStateBadgeColor = (state?: BillingCycleStates) => {
  if (state && [BillingCycleStates.APPROVED, BillingCycleStates.PROCESSED, BillingCycleStates.CYCLE_COMPLETED].includes(state)) return 'positive';
  if (state && [BillingCycleStates.CYCLE_IN_PROGRESS].includes(state)) {
    return 'warning';
  }
  return 'negative';
};

const isFilterActive = ({
  billingScheduleId,
  state,
  dateAfter,
  dateBefore,
}: {
  billingScheduleId: string,
  state: string
  dateAfter: string,
  dateBefore: string,
}) => {
  if (!isUndefined(billingScheduleId) && billingScheduleId !== '') return true;
  if (!isUndefined(state) && state !== '') return true;
  if (!isUndefined(dateAfter) && dateAfter !== '') return true;
  if (!isUndefined(dateBefore) && dateBefore !== '') return true;
  return false;
};

const LoadingRows = React.memo(({ count }: { count: number }) => (
  <>
    {[...Array(count)].map((_, i) => (
      <TableRow key={i}>
        <TableCell><Skeleton width='100%' /></TableCell>
        <TableCell><Skeleton width='100%' /></TableCell>
        <TableCell><Skeleton width='100%' /></TableCell>
        <TableCell><Skeleton width='100%' /></TableCell>
        <TableCell><Skeleton width='100%' /></TableCell>
        <TableCell><Skeleton width='100%' /></TableCell>
        <TableCell><Skeleton width='100%' /></TableCell>
        <TableCell><Skeleton width='100%' /></TableCell>
        <TableCell><Skeleton width='100%' /></TableCell>
        <TableCell><Skeleton width='100%' /></TableCell>
        <TableCell><Skeleton width='100%' /></TableCell>
        <TableCell><Skeleton width='100%' /></TableCell>
        <TableCell><Skeleton width='100%' /></TableCell>
        <TableCell><Skeleton width='100%' /></TableCell>
      </TableRow>
    ))}
  </>
));

const BillingManagement = () => {
  const navigate = useNavigate();
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['feeAndBilling', 'shared']);
  const [billingScheduleId, setBillingScheduleId] = usePageState('', 'billingSchedule');
  const [dateAfter, setDateAfter] = usePageState('', 'dateAfter');
  const [dateBefore, setDateBefore] = usePageState('', 'dateBefore');
  const [state, setState] = usePageState('', 'state');
  const [page, setPage] = usePageState(1, 'page');
  const { localizedDate } = useLocalization();
  const pageSize = 15;
  const organizationId = activeOrganization.id;

  const {
    loading, error, data, previousData,
  } = useQuery(FETCH_BILLING_CYCLES, {
    skip: !activeOrganization.id,
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          billingScheduleIds: billingScheduleId || undefined,
          billingDateAfter: dateAfter || undefined,
          billingDateBefore: dateBefore || undefined,
          states: state || undefined,
        },
        pagination: {
          sortField: 'billingDate', sortDesc: true, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  if (error) (<Typography>Error</Typography>);

  return (
    <Card loading={loading}>
      <CardContent>
        <Box display='flex' justifyContent='end' alignItems='center'>
          <FilterModal filterExists={isFilterActive({
            billingScheduleId,
            state,
            dateAfter,
            dateBefore,
          })}>
            <Box display='flex' flexDirection='column' gap={2}>
              <BillingScheduleSelect
                label={t('feeAndBilling:feeReport.table.billingSchedule')}
                setValue={(newValue) => setBillingScheduleId(newValue)}
                value={billingScheduleId}
                organizationId={organizationId}
                includeAnyOption
              />

              <TextField
                select
                value={state || 'any'}
                label={t('feeAndBilling:billingCycle.state')}
                fullWidth
                onChange={(e: any) => setState(e.target.value !== 'any' ? e.target.value : '')}
              >
                <MenuItem key='any' value='any'>{t('components:any')}</MenuItem>
                {Object.keys(BillingCycleStates).map((s) => (
                  <MenuItem key={s} value={s}>{t(`feeAndBilling:billingCycle.${s}`)}</MenuItem>
                ))}
              </TextField>

              <DateField
                value={dateAfter || null}
                onChange={(date: Date) => setDateAfter(dayjs(date).format('YYYY-MM-DD'))}
                onAccept={(date: Date) => setDateAfter(dayjs(date).format('YYYY-MM-DD'))}
                onClose={() => { setDateAfter(''); }}
                label={t('feeAndBilling:feeReport.filters.billingDateFrom')}
                fullWidth
                InputProps={{ endAdornment: dateAfter && (<IconButton onClick={() => setDateAfter('')}><ClearIcon /></IconButton>) }}
              />

              <DateField
                value={dateBefore || null}
                onChange={(date: Date) => setDateBefore(dayjs(date).format('YYYY-MM-DD'))}
                onAccept={(date: Date) => setDateBefore(dayjs(date).format('YYYY-MM-DD'))}
                onClose={() => { setDateBefore(''); }}
                label={t('feeAndBilling:feeReport.filters.billingDateTo')}
                fullWidth
                InputProps={{ endAdornment: dateBefore && (<IconButton onClick={() => setDateAfter('')}><ClearIcon /></IconButton>) }}
              />
            </Box>
            <Box display='flex' flexDirection='column' gap={2}>

            </Box>
          </FilterModal>
          <Box display='flex'>
            {permissions.includes('read:api_exports') && (
              <DownloadBillingCycle
                filter={{ organizationId }}
                queryFilter={{
                  organizationId,
                  ...((billingScheduleId && billingScheduleId !== '') ? { billingScheduleIds: billingScheduleId } : []),
                  ...((dateAfter && dateAfter !== '') ? { dateAfter } : {}),
                  ...((dateBefore && dateBefore !== '') ? { dateBefore } : {}),
                }}
              />
            )}
          </Box>
        </Box>
      </CardContent>
      <Box sx={{ overflowX: 'auto' }}>
        <Table aria-label="table">
          <TableBody>
            <TableRow>
              <TableHeadCell>{t('feeAndBilling:billingCycle.organization')}</TableHeadCell>
              <TableHeadCell>{t('feeAndBilling:billingCycle.calculationDate')}</TableHeadCell>
              <TableHeadCell>{t('feeAndBilling:billingCycle.billingDate')}</TableHeadCell>
              <TableHeadCell>{t('feeAndBilling:billingSchedule.table.frequency')}</TableHeadCell>
              <TableHeadCell>{t('feeAndBilling:billingCycle.startDate')}</TableHeadCell>
              <TableHeadCell>{t('feeAndBilling:billingCycle.endDate')}</TableHeadCell>
              <TableHeadCell right>{t('feeAndBilling:billingCycle.billableAumLastDay')}</TableHeadCell>
              <TableHeadCell right>{t('feeAndBilling:billingCycle.billableAumWithReductionsApplied')}</TableHeadCell>
              <TableHeadCell right>{t('feeAndBilling:billingCycle.accruedFees')}</TableHeadCell>
              <TableHeadCell right>{t('feeAndBilling:billingCycle.salesTax')}</TableHeadCell>
              <TableHeadCell right>{t('feeAndBilling:billingCycle.totalFeesAndTax')}</TableHeadCell>
              <TableHeadCell right>{t('feeAndBilling:billingCycle.collectedFees')}</TableHeadCell>
              <TableHeadCell right>{t('feeAndBilling:billingCycle.projectedFeeAndTax')}</TableHeadCell>
              <TableHeadCell center>{t('feeAndBilling:billingCycle.state')}</TableHeadCell>
            </TableRow>
            {loading && !previousData && (
              <LoadingRows count={15} />
            )}
            {(data || previousData)?.fetchBillingCycles?.billingCycles?.map((billingCycle: BillingCycle) => (
              <TableRow
                hover
                onClick={() => {
                  navigate(`/billingManagement/${billingCycle.id}`);
                }}
                data-testid={billingCycle.id}
                key={billingCycle.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
              >
                <TableCell>
                  <Tooltip title={billingCycle.organization?.name} placement='bottom'>
                    <Typography sx={textColumnStyles}>{billingCycle.organization?.name}</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell> {localizedDate(billingCycle.calculationDate ?? billingCycle.calculatedAt)} </TableCell>
                <TableCell> {localizedDate(billingCycle.billingDate)}</TableCell>
                <TableCell> <Typography>{t(`feeAndBilling:billingSchedule.frequencies.${billingCycle.billingSchedule?.frequency}`)}</Typography> </TableCell>
                <TableCell> {localizedDate(billingCycle.startDate)}</TableCell>
                <TableCell> {localizedDate(dayjs.utc(billingCycle.endDate).startOf('day').subtract(1, 'day'))}</TableCell>
                <TableCell right>{formatMoneyValue(billingCycle.marketValueCentsOnLastDay ?? 0)}</TableCell> <TableCell right>
                  {formatMoneyValue((billingCycle.marketValueCentsOnLastDay ?? 0) - (billingCycle.chargeableMarketValueCentsOnLastDay ?? 0))}
                </TableCell>
                <TableCell right>{formatMoneyValue(billingCycle.feeCents ?? 0)}</TableCell>
                <TableCell right>{formatMoneyValue(billingCycle.salesTaxCents ?? 0)}</TableCell>
                <TableCell right>{formatMoneyValue((billingCycle.feeCents ?? 0) + (billingCycle.salesTaxCents ?? 0))}</TableCell>
                <TableCell right>{formatMoneyValue((billingCycle.reconciledFees?.feeCents ?? 0) + (billingCycle.reconciledFees?.salesTaxCents ?? 0))}</TableCell>
                <TableCell right>{formatMoneyValue(billingCycle.projectedFeeAndTaxCents ?? 0)}</TableCell>
                <TableCell center>
                  <Badge
                    label={t(`feeAndBilling:billingCycle.${billingCycle.state}`)}
                    color={getStateBadgeColor(billingCycle?.state)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Pagination
        count={Math.ceil(((data || previousData)?.fetchBillingCycles?.totalCount ?? 0) / pageSize)}
        page={page}
        onChange={(_e, newPage) => setPage(newPage)}
        sx={{
          p: 1,
          textAlign: 'right',
          '.MuiPagination-ul': {
            justifyContent: 'end',
          },
        }}
      />
    </Card>
  );
};

export default BillingManagement;

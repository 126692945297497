import { List, ListItem } from '@mui/material';
import { Box, Typography } from '../../1-primative';
import { Button } from '../button/button';

interface IAccountAgreements {
  id: string;
  url: string;
}

export const AccountAgreements = ({ label, value }: { label: string; value: any; key?: string }) => (
  <Box>
    <Typography variant="bodyLarge">{label}</Typography>
    <List>
      {(value.agreements ?? []).map(({ id, url }: IAccountAgreements) => (
        <ListItem key={id}>
          <Button label={url.split('/').pop() ?? ''} variant='outlined' size='sm' href={url} target='_blank' />
        </ListItem>
      ))}
    </List>
  </Box>
);

/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatMoneyValue } from '../../../../util';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import { usePageState } from '../../../../util/usePageState';
import NewTrade from './components/newTrade';
import DownloadTrades from './components/downloadTrades';
import { FETCH_SUB_TRADES, FETCH_TRADES } from './tradeOrders.queries';
import { useLocalization } from '../../../../util/useLocalization';
import {
  Box, Link as MuiLink,
  Skeleton,
  Typography,
} from '../../../1-primative';
import {
  Table, TableRow, TableCell, TableBody, Pagination, MenuItem,
  TableHeadCell, Card, CardContent, SelectField, Badge,
} from '../../../2-component';
import { FilterModal, SecuritySelect } from '../../../3-pattern';
import { PageObjectType } from '../../../5-page';
import { translateBackend } from '../../../../assets/i18n/config';
import { TradeModal } from './components/tradeModal';

export const TradeOrders = ({
  objectType, objectId, options,
}: { objectType: PageObjectType, objectId: string, options: any }) => {
  const { t } = useTranslation(['components', 'transfer']);
  const { localizedDateTime } = useLocalization();
  const { permissions } = usePermissions();
  const [page, setPage] = usePageState(1, 'tra/page');
  const [state, setState] = usePageState('any', 'tra/state');
  const [activeTrade, setActiveTrade] = useState<any>({});
  const [modalOpen, setModalOpen] = useState(false);
  const { activeOrganization } = useContext(UserContext);
  const [trades, setTrades] = useState<{ trades: any[], totalCount: number }>();
  const pageSize = 15;
  const canWrite = (): boolean => permissions.includes('write:trade_basic');
  const [financialProductId, setFinancialProductId] = useState<string>('ANY');

  const isTrades = ![PageObjectType.GOAL, PageObjectType.SUB_ACCOUNT].includes(objectType);

  const queryFilter = {
    organizationId: activeOrganization.id,
    [isTrades ? 'tradeState' : 'subTradeState']: state === 'any' ? undefined : state,
    financialProductId: financialProductId === 'ANY' ? undefined : financialProductId,
  };

  let filter: any = {};

  switch (objectType) {
    case PageObjectType.INDIVIDUAL:
    case PageObjectType.NON_INDIVIDUAL:
      filter = { userId: objectId };
      break;
    case PageObjectType.HOUSEHOLD:
      filter = { clientGroupId: objectId };
      break;
    case PageObjectType.ACCOUNT:
      filter = { accountId: objectId };
      break;
    case PageObjectType.SUB_ACCOUNT:
      filter = { subAccountId: objectId };
      break;
    default:
      break;
  }

  const showExpected = false;

  const {
    loading,
    previousData,
    refetch,
  } = useQuery(isTrades ? FETCH_TRADES(permissions) : FETCH_SUB_TRADES(permissions), {
    notifyOnNetworkStatusChange: true,
    skip: !permissions.includes('read:trade_basic'),
    variables: {
      input: {
        filter: {
          ...filter,
          ...queryFilter,
        },
        pagination: {
          sortField: 'createdAt', sortDesc: false, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
    onCompleted(data) {
      if (isTrades) {
        setTrades({ trades: data.fetchTrades?.trades, totalCount: data.fetchTrades?.totalCount });
      } else {
        setTrades({ trades: data.fetchSubTrades?.subTrades, totalCount: data.fetchSubTrades?.totalCount });
      }

      if (activeTrade?.id) {
        setActiveTrade(data.fetchTrades.trades.find((x: any) => x.id === activeTrade.id));
      }
    },
  });

  const stateColor = (forState: any) => (forState === 'RECONCILED' ? 'positive' : ['FAILED', 'CANCELED'].includes(forState) ? 'negative' : 'neutral');

  const tradeStates = [
    'INITIATED',
    'PROCESSING',
    'RECONCILED',
    'FAILED',
    'CANCELED',
  ];

  useEffect(() => function cleanupOnUnmount() {
    setState('any');
    setPage(1);
  }, []);

  const onTradeChange = () => {
    refetch();
  };

  if (!permissions.includes('read:trade_basic')) return <></>;

  const getAccount = (trade: any) => (isTrades ? trade?.account : trade?.subAccount?.account);

  return (
    <Box>
      {options.customTitle && (
        <Typography variant='headingSmall' sx={{ mb: 2 }}>{translateBackend(options.customTitle)}</Typography>)}
      <Card>
        <CardContent>
          {!showExpected && (
            <Box display='flex' justifyContent='space-between' alignItems='end'>
              <SelectField
                value={state}
                label={t('tradeTable.state')}
                onChange={(e: any) => setState(e.target.value)}
                size='small'
                fullWidth
                sx={{ width: '250px' }}
                placeholder={t('any')}
              >
                <MenuItem value={'any'}>{t('any')}</MenuItem>
                {tradeStates.map((x: any) => (
                  <MenuItem value={x} key={x}>{t(`transfer:states.${x}`)}</MenuItem>
                ))}
              </SelectField>
              <Box display='flex'>
                {permissions.includes('read:api_exports') && (
                  <DownloadTrades filter={filter} queryFilter={queryFilter} isTrades={isTrades}/>)}

                <FilterModal sx={{ mr: 1, ml: 1 }}>
                  <SecuritySelect
                    value={financialProductId ?? ''}
                    setSecurity={(e: any) => setFinancialProductId(e?.id ?? e)}
                    label={t('components:security')}
                    withAny
                  />
                </FilterModal>
                {!loading && canWrite() && (
                  <NewTrade afterCreate={onTradeChange}
                            userId={filter.userId} userIds={filter.userIds}
                            {...filter.userId && { forObject: 'USER', forId: filter.userId }}
                            {...filter.clientGroupId && { forObject: 'CLIENT_GROUP', forId: filter.clientGroupId }}
                            {...filter.accountId && { forObject: 'ACCOUNT', forId: filter.accountId }}
                  />
                )}
              </Box>
            </Box>
          )}
        </CardContent>
        <Table aria-label="table">
          <TableBody>
            <TableRow>
              {objectType === PageObjectType.HOUSEHOLD && (
                <TableHeadCell>{t('tradeTable.client')}</TableHeadCell>
              )}
              <TableHeadCell>{t('tradeTable.account')}</TableHeadCell>
              <TableHeadCell>{t('tradeTable.security')}</TableHeadCell>
              <TableHeadCell>{t('tradeTable.type')}</TableHeadCell>
              {showExpected ? (
                <>
                  <TableHeadCell right>{t('tradeTable.expectedQuantity')}</TableHeadCell>
                  <TableHeadCell right>{t('tradeTable.expectedPrice')}</TableHeadCell>
                  <TableHeadCell right>{t('tradeTable.expectedTotalValue')}</TableHeadCell>
                </>
              ) : (
                <>
                  <TableHeadCell right>{t('tradeTable.quantity')}</TableHeadCell>
                  <TableHeadCell right>{t('tradeTable.price')}</TableHeadCell>
                  <TableHeadCell right>{t('tradeTable.totalValue')}</TableHeadCell>
                </>
              )}
              <TableHeadCell>{t('tradeTable.state')}</TableHeadCell>
              <TableHeadCell>{t('tradeTable.createdAt')}</TableHeadCell>
              {!showExpected && (<TableHeadCell>{t('tradeTable.reconciledAt')}</TableHeadCell>)}
            </TableRow>
            {loading && !previousData && [...Array(15)].map((x: any, i: number) => (
              <TableRow key={i}>
                <TableCell dense><Skeleton/></TableCell>
                <TableCell dense><Skeleton/></TableCell>
                <TableCell dense><Skeleton/></TableCell>
                <TableCell dense><Skeleton/></TableCell>
                <TableCell dense><Skeleton/></TableCell>
                <TableCell dense><Skeleton/></TableCell>
                <TableCell dense><Skeleton/></TableCell>
                <TableCell dense><Skeleton/></TableCell>
                {!showExpected && (
                  <TableCell dense><Skeleton/></TableCell>
                )}
                {objectType === PageObjectType.HOUSEHOLD && (
                  <TableCell dense><Skeleton/></TableCell>
                )}
              </TableRow>
            ))}
            {trades?.trades.map((trade: any) => (
              <TableRow
                hover
                onClick={() => {
                  setActiveTrade(trade);
                  setModalOpen(true);
                }}
                key={trade.id}
                sx={{ textDecoration: 'none', cursor: 'pointer' }}
              >
                {objectType === PageObjectType.HOUSEHOLD && (
                  <TableCell dense>
                    <MuiLink component={Link}
                             href={`/clients/${getAccount(trade).user.id}`}>{getAccount(trade).user.firstName} {getAccount(trade).user.lastName}</MuiLink>
                  </TableCell>
                )}
                <TableCell dense>{t(`accountTypes:${getAccount(trade).type}`)}</TableCell>
                <TableCell dense><b>{trade.financialProduct.ticker}</b></TableCell>
                <TableCell dense>{t(`transaction.types.${trade.type}`)}</TableCell>
                {showExpected || trade.state === 'INITIATED' ? (
                  <>
                    <TableCell number
                               dense>{isTrades ? trade?.expectedQuantity : trade?.trade?.expectedQuantity}</TableCell>
                    <TableCell number
                               dense>{formatMoneyValue(isTrades ? trade?.expectedTradePriceCents : trade?.trade?.expectedTradePriceCents)}</TableCell>
                    <TableCell number dense>{formatMoneyValue(trade.moneyAllocatedCents)}</TableCell>
                  </>
                ) : (
                  <>
                    <TableCell number dense>{trade.actualQuantity}</TableCell>
                    <TableCell number dense>{formatMoneyValue(trade.actualTradePriceCents)}</TableCell>
                    <TableCell number
                               dense>{formatMoneyValue(trade.actualTradePriceCents * trade.actualQuantity)}</TableCell>
                  </>
                )}

                <TableCell dense>
                  <Badge
                    label={t(`transfer:states.${trade.state}`)}
                    color={stateColor(trade.state)}
                  />
                </TableCell>
                <TableCell dense>{localizedDateTime(trade.createdAt)}</TableCell>
                {!showExpected && (
                  <TableCell dense>{trade.reconciledAt ? localizedDateTime(trade.reconciledAt) : '-'}</TableCell>)}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={Math.ceil((trades?.totalCount ?? 0) / pageSize)}
          page={page}
          size='small'
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />
        <TradeModal trade={activeTrade} open={modalOpen} setOpen={setModalOpen} onTransition={refetch}/>
      </Card>
    </Box>
  );
};

export default TradeOrders;

import { gql, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  Button,
  Card, CardContent, DateField, Form, MenuItem, SelectField,
  TextField,
} from '../../../2-component';
import { Box, Grid, Typography } from '../../../1-primative';
import { translateBackend } from '../../../../assets/i18n/config';

const FETCH_USER = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        complianceState
        complianceIssueSource
        readyToSignAgreement
        lastSuitabilityReviewAt
        suitabilityReviewOverdue
        fraudRisk {
          id
          notes
          score
          lastUpdatedBy
          riskReasons {
            translatedDescription { en }
          }
        }
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user { id }
    }
  }
`;

export const UPDATE_FRAUD_RISK = gql`
  mutation updateFraudRisk($input: UpdateFraudRiskInput!) {
    updateFraudRisk(input: $input) {
      fraudRisk { id }
    }
  }
`;

export const Compliance = ({ objectId, options }: { objectId: string, options: any }) => {
  const { t } = useTranslation(['client', 'shared']);
  const [user, setUser] = useState<any>({});
  const [edited, setEdited] = useState(false);
  const [fraudEdited, setFraudEdited] = useState(false);

  const { data, refetch } = useQuery(FETCH_USER, {
    variables: { userId: objectId },
    fetchPolicy: 'no-cache',
  });

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: () => setEdited(false),
    variables: {
      input: {
        userId: objectId,
        complianceState: user.complianceState,
        complianceIssueSource: user.complianceIssueSource,
        readyToSignAgreement: user.readyToSignAgreement === 'true',
        lastSuitabilityReviewAt: user.lastSuitabilityReviewAt ? dayjs(user.lastSuitabilityReviewAt).format('YYYY-MM-DD') : null,
      },
    },
  });

  const [updateFraudRisk] = useMutation(UPDATE_FRAUD_RISK, {
    onCompleted: () => {
      setFraudEdited(false);
      refetch();
    },
    variables: {
      input: {
        fraudRiskId: user?.fraudRisk?.id,
        score: Number(user.fraudRisk?.score),
        notes: user.fraudRisk?.notes ?? undefined,
      },
    },
  });

  const submit = () => {
    if (edited) {
      updateUser();
    }
    if (fraudEdited) {
      updateFraudRisk();
    }
  };

  useEffect(() => {
    if (data) {
      setUser(data.fetchUser.user);
    }
  }, [data]);

  const update = (value: string, field: string) => {
    setEdited(true);
    setUser((prev: any) => ({ ...prev, [field]: value }));
  };

  const updateFraud = (value: string, field: string) => {
    setFraudEdited(true);
    setUser((prev: any) => ({ ...prev, fraudRisk: { ...prev.fraudRisk, [field]: value } }));
  };

  const lastSuitabilityReviewMonthsAgo = dayjs().diff(user.lastSuitabilityReviewAt, 'month');

  return (
    <>
      <Typography variant='headingSmall' mb={2}>{options.customTitle ? translateBackend(options.customTitle) : t('suitability.title')}</Typography>
      <Card>
        <CardContent>
          <Form onSubmit={submit}>
            <Grid container spacing={2}>
              { options.complianceState.enabled && (
                <Grid item xs={12} md={4}>
                  <SelectField
                    label={translateBackend(options.complianceState.label)}
                    value={user.complianceState || ''}
                    error={user.complianceState === 'NEEDS_REVIEW'}
                    errorText={t('details.complianceStateNeedsReview')}
                    onChange={(event: any) => update(event.target.value, 'complianceState')}
                    fullWidth
                  >
                    <MenuItem value='APPROVED'>{t('edit.complianceStateOptions.APPROVED')}</MenuItem>
                    <MenuItem value='NEEDS_REVIEW'>{t('edit.complianceStateOptions.NEEDS_REVIEW')}</MenuItem>
                    <MenuItem value='REJECTED'>{t('edit.complianceStateOptions.REJECTED')}</MenuItem>
                  </SelectField>
                </Grid>
              )}
              { options.complianceIssueSource.enabled && (
                <Grid item xs={12} md={4}>
                  <TextField
                    label={translateBackend(options.complianceIssueSource.label)}
                    value={user.complianceIssueSource || ''}
                    onChange={(event: any) => update(event.target.value, 'complianceIssueSource')}
                    fullWidth
                  />
                </Grid>
              )}
              { options.lastSuitabilityReviewAt.enabled && (
                <Grid item xs={12} md={4}>
                  <DateField
                    label={translateBackend(options.lastSuitabilityReviewAt.label)}
                    value={user.lastSuitabilityReviewAt || ''}
                    onChange={(value: any) => update(value, 'lastSuitabilityReviewAt')}
                    error={user.suitabilityReviewOverdue}
                    errorText={t('details.suitabilityReviewOverdue', { months: lastSuitabilityReviewMonthsAgo })}
                    fullWidth
                  />
                </Grid>
              )}
              { options.readyToSignAgreement.enabled && (
                <Grid item xs={12} md={4}>
                  <SelectField
                    label={translateBackend(options.readyToSignAgreement.label)}
                    value={(user.readyToSignAgreement || false).toString() || ''}
                    onChange={(event: any) => update(event.target.value, 'readyToSignAgreement')}
                    fullWidth
                  >
                    <MenuItem value='true'>{t('shared:yes')}</MenuItem>
                    <MenuItem value='false'>{t('shared:no')}</MenuItem>
                  </SelectField>
                </Grid>
              )}
              { options.riskLevel.enabled && (
                <Grid item xs={12} md={4}>
                  <SelectField
                    label={translateBackend(options.riskLevel.label)}
                    value={user.fraudRisk?.score ?? ''}
                    onChange={(event: any) => updateFraud(event.target.value, 'score')}
                    fullWidth
                  >
                    <MenuItem value={1}>{t('details.riskLevelBox.riskLevel.1')}</MenuItem>
                    <MenuItem value={2}>{t('details.riskLevelBox.riskLevel.2')}</MenuItem>
                    <MenuItem value={3}>{t('details.riskLevelBox.riskLevel.3')}</MenuItem>
                    <MenuItem value={0}>{t('details.riskLevelBox.riskLevel.0')}</MenuItem>
                  </SelectField>
                </Grid>
              )}
              { options?.previousChangeQuestion && (
                <Grid item xs={12} md={4}>
                  <TextField
                    label={t('details.riskLevelBox.previousChangeQuestion')}
                    value={user?.fraudRisk?.lastUpdatedBy === 'System' ? 'No' : 'Yes'}
                    fullWidth
                    disabled
                  />
                </Grid>
              )}
              { options?.riskReasons && !!user.fraudRisk?.riskReasons.length && (
                <Grid item xs={12}>
                  <TextField
                    label={t('details.riskLevelBox.reason')}
                    value={user.fraudRisk?.riskReasons !== null
                      && user?.fraudRisk?.riskReasons?.map((risk : any) => risk.translatedDescription?.en).join('\n')
                    }
                    fullWidth
                    disabled
                    multiline
                    minRows={3}
                  />
                </Grid>
              )}
              { options.riskNotes.enabled && (
                <Grid item xs={12}>
                  <TextField
                    label={translateBackend(options.riskNotes.label)}
                    value={user.fraudRisk?.notes || ''}
                    onChange={(event: any) => updateFraud(event.target.value, 'notes')}
                    fullWidth
                    multiline
                    minRows={3}
                  />
                </Grid>
              )}
            </Grid>
            <Box display='flex' justifyContent='flex-end' alignItems='center' mt={2}>
              <Button type='submit' label={t('shared:update')} disabled={!edited && !fraudEdited}/>
            </Box>
          </Form>
        </CardContent>
      </Card>
    </>
  );
};

/* eslint-disable operator-linebreak */
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import EditRepCode from './components/editRepCode';
import NewRepCode from './components/newRepCode';
import {
  Typography, Box,
  Skeleton,
} from '../../1-primative';
import {
  Card, CardContent,
  Pagination, Table,
  TableBody, TableCell, TableHeadCell,
  TableRow,
} from '../../2-component';
import { usePageState } from '../../../util/usePageState';
import { RepCode } from '../../../interfaces/repCode';

export const FETCH_REP_CODES = gql`
  query fetchRepCodes($input: FetchRepCodesInput!) {
    fetchRepCodes(input: $input) {
      totalCount
      repCodes {
        code
        name
        organization {
          name
          id
        }
      }
    }
  }
`;

const RepCodes = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const { t } = useTranslation(['orgSettings', 'shared']);
  const [selectedRepCode, setSelectedRepCode] = useState<RepCode>();
  const [page, setPage] = usePageState(1, 'page');
  const [pageSize, setPageSize] = usePageState(15, 'ps');

  const {
    loading,
    error, data, refetch,
    previousData,
  } = useQuery(FETCH_REP_CODES, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
        pagination: {
          sortField: 'code', perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
    fetchPolicy: 'no-cache',
  });

  if (error) <Typography>Error</Typography>;

  return (
    <>
      <Card loading={loading}>
        <CardContent>
          <Box display='flex' justifyContent='flex-end'>
            <Box display='flex'>{permissions.includes('write:rep_code') && <NewRepCode afterCreate={refetch} defaultOrg={activeOrganization.id} />}</Box>
          </Box>
        </CardContent>
        <Box sx={{ overflowX: 'auto' }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableHeadCell>{t('repCodeTable.code')}</TableHeadCell>
                <TableHeadCell>{t('repCodeTable.name')}</TableHeadCell>
                <TableHeadCell>{t('repCodeTable.organization')}</TableHeadCell>
              </TableRow>
              {loading &&
                !previousData &&
                [...Array(10)].map((x: any, i: number) => (
                  <TableRow key={i}>
                    <TableCell>
                      <Skeleton width='100%' />
                    </TableCell>
                    <TableCell>
                      <Skeleton width='100%' />
                    </TableCell>
                    <TableCell>
                      <Skeleton width='100%' />
                    </TableCell>
                  </TableRow>
                ))}
              {data?.fetchRepCodes?.repCodes?.map((repCode: RepCode) => (
                <TableRow
                  hover
                  key={repCode.code}
                  pointer
                  onClick={() => {
                    setSelectedRepCode(repCode);
                    setUpdateDialogOpen(true);
                  }}
                >
                  <TableCell component='th' scope='row'>{repCode.code}</TableCell>
                  <TableCell>{repCode.name}</TableCell>
                  <TableCell>{repCode.organization?.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Pagination
          count={Math.ceil(((data || previousData)?.fetchRepCodes?.totalCount ?? 0) / pageSize)}
          page={page}
          perPage={pageSize}
          onChangePerPage={(newPageSize) => setPageSize(newPageSize)}
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />
      </Card>
      {selectedRepCode && (
        <EditRepCode
          open={updateDialogOpen}
          repCodeToUpdate={selectedRepCode}
          afterUpdate={() => {
            setUpdateDialogOpen(false);
            setSelectedRepCode(undefined);
            refetch();
          }}
          handleClose={() => {
            setUpdateDialogOpen(false);
            setSelectedRepCode(undefined);
          }}
        />
      )}
    </>
  );
};

export default RepCodes;

import { useTranslation } from 'react-i18next';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Skeleton, Typography } from '../../../../1-primative';
import { PageObjectType } from '../../../../5-page';
import { translateBackend } from '../../../../../assets/i18n/config';
import { chooseQuery, objectPath, FETCH_CLIENT_REPORT_TEMPLATES } from '../clientReport.queries';
import { UserContext } from '../../../../../providers/userContextProvider';
import { Badge, EmptyStateHeaderTitle } from '../../../../2-component';
import { generateClientNameString } from '../../../../../util';
import { getGoalName, getSubAccountName } from '../../../../../interfaces';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';

export const ClientReports = ({ objectType, objectId }: { objectType: PageObjectType; objectId: string }) => {
  const { t } = useTranslation(['client']);
  const { sys } = useThemeTokens();
  const navigate = useNavigate();
  const { activeOrganization } = useContext(UserContext);

  const boxSx = useMemo(
    () => ({
      borderRadius: sys.borderRadius.md,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: sys.color.surfaceContainerVariant,
      },
    }),
    [sys.borderRadius.md, sys.color.surfaceContainerVariant],
  );

  const templateTypes = useMemo(
    () => ({
      households: 'CLIENT_GROUP',
      individuals: 'INDIVIDUAL',
      nonIndividuals: 'NON_INDIVIDUAL',
      accounts: 'ACCOUNT',
      goals: 'GOAL',
      subAccounts: 'SUB_ACCOUNT',
    }),
    [],
  );

  const getObjectName = (object: any, type: string) => {
    switch (type) {
      case 'INDIVIDUAL':
      case 'NON_INDIVIDUAL':
        return generateClientNameString(object);
      case 'CLIENT_GROUP':
        return `${object?.name}`;
      case 'ACCOUNT':
        return `${t(`accountTypes:${object?.type}`)} - ${object?.custodianAccountNumber ? `#${object?.custodianAccountNumber}` : t('pending')}`;
      case 'GOAL':
        return getGoalName(object);
      case 'SUB_ACCOUNT':
        return getSubAccountName(object);
      default:
        return '';
    }
  };

  const chooseObjectTypes = () => {
    switch (objectType) {
      case PageObjectType.INDIVIDUAL:
        return ['INDIVIDUAL', 'ACCOUNT', 'GOAL', 'SUB_ACCOUNT'];
      case PageObjectType.NON_INDIVIDUAL:
        return ['NON_INDIVIDUAL', 'ACCOUNT', 'GOAL', 'SUB_ACCOUNT'];
      case PageObjectType.HOUSEHOLD:
        return ['CLIENT_GROUP', 'INDIVIDUAL', 'NON_INDIVIDUAL', 'ACCOUNT', 'GOAL', 'SUB_ACCOUNT'];
      case PageObjectType.ACCOUNT:
        return ['ACCOUNT', 'SUB_ACCOUNT'];
      case PageObjectType.GOAL:
        return ['GOAL', 'SUB_ACCOUNT'];
      case PageObjectType.SUB_ACCOUNT:
        return ['SUB_ACCOUNT'];
      default:
        return [];
    }
  };

  const { data: templatesData, loading: templatesLoading } = useQuery(FETCH_CLIENT_REPORT_TEMPLATES, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          objectTypes: chooseObjectTypes(),
          reportTypes: ['PAGE_CONFIGURATION'],
          states: ['ACTIVE'],
        },
      },
    },
  });

  // Returns an object that sorts each report type into the keys in templateTypes
  const templates = (Object.keys(templateTypes) as Array<keyof typeof templateTypes>).reduce((acc: any, currentValue: keyof typeof templateTypes) => {
    acc[currentValue] = templatesData?.fetchClientReportTemplates?.clientReportTemplates.filter((template: any) => template?.objectType === templateTypes[currentValue]);
    return acc;
  }, {});

  const templatesArray = Object.entries(templates)
    .filter(([key, value]) => (value as any[])?.length > 0)
    .map(([key, value]) => ({ type: key, templates: value }));

  const [fetchObjectType, { data: objectTypeData, loading: objectTypeLoading }] = useLazyQuery(chooseQuery({ objectType, templates }));

  useEffect(() => {
    fetchObjectType({
      variables: {
        objectId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templatesData, objectId]);

  if (templatesLoading || objectTypeLoading) {
    return (
      <>
        <Box display={'flex'} gap={2} pt={2}>
          <Skeleton width='80%' height='24px' />
          <Skeleton width='20%' height='24px' />
        </Box>
        <Box display={'flex'} gap={2} pt={2}>
          <Skeleton width='80%' height='24px' />
          <Skeleton width='20%' height='24px' />
        </Box>
        <Box display={'flex'} gap={2} pt={2}>
          <Skeleton width='80%' height='24px' />
          <Skeleton width='20%' height='24px' />
        </Box>
      </>
    );
  }

  const objectHasTemplate = () => templatesArray.some((templateItem: any) => (
    templateItem.templates?.[0].objectType === objectType
  ));

  const objectsHaveTemplate = () => templatesArray.some((templateItem:any) => {
    let objects = objectPath({ objectType, data: objectTypeData })?.[templateItem.type]?.filter((object: any) => object.state === 'ACTIVE');

    if (templateItem.type === 'household') {
      objects = objectPath({ objectType, data: objectTypeData })?.relationships?.filter((object: any) => object.state === 'ACTIVE');
    }

    return (
      objects?.length > 0 || objectHasTemplate()
    );
  });

  if (!templatesLoading && !objectTypeLoading && templatesArray.length === 0 && !objectsHaveTemplate()) {
    return (
      <EmptyStateHeaderTitle sx={{ mt: 2 }} title={t('clientReportSummary.noReports')} />
    );
  }

  const ClientReportItem = ({ template, onClick, object }: { template: any; object: any; onClick: () => void }) => (
    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} px={2} py={1} sx={boxSx} onClick={onClick}>
      <Box display={'flex'} alignItems={'center'}>
        <Typography variant={'bodyMedium'}>{`${translateBackend(template.translatedName)} - ${getObjectName(object, template?.objectType)}`}</Typography>
      </Box>
      <Box display={'flex'} justifyContent={'flex-end'}>
        <Badge label={t(`orgSettings:clientReportTemplates.frequencies.${template.scheduler.frequency}`)} />
      </Box>
    </Box>
  );

  return (
    <Box>
      {templatesArray?.map((templateItem: any) => (
        <Box>
          {templateItem?.templates?.map((template: any) => (
            <Box>
              {template?.objectType === objectType ? (
                <ClientReportItem
                  template={template}
                  object={objectPath({ objectType: template?.objectType, data: objectTypeData })}
                  onClick={() => navigate(`/viewClientReport/${template.id}/${objectPath({ objectType: template?.objectType, data: objectTypeData }).id}`)}
                />
              ) : objectType === PageObjectType.HOUSEHOLD && (template?.objectType === 'INDIVIDUAL' || template.objectType === 'NON_INDIVIDUAL') ? (
                objectPath({ objectType, data: objectTypeData })?.relationships?.map((object: any) => (
                  <ClientReportItem key={object.id} template={template} object={object.user} onClick={() => navigate(`/viewClientReport/${template.id}/${object.user.id}`)} />
                ))
              ) : (
                objectPath({ objectType, data: objectTypeData })
                  ?.[templateItem.type]?.filter((activeObject: any) => activeObject.state === 'ACTIVE')
                  .map((object: any) => <ClientReportItem key={object.id} template={template} object={object} onClick={() => navigate(`/viewClientReport/${template.id}/${object.id}`)} />)
              )}
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from '../confirmationDialog/confirmationDialog';
import { DELETE_FILE_DOCUMENT } from '../../5-page/manageNewsAndInsights/components/queries';

export const DeleteFileDocumentModal = ({
  handleClose, open, fileDocumentId, refetch,
}: { fileDocumentId: string, handleClose: () => void, open: boolean, refetch: () => void }) => {
  const { t } = useTranslation(['client']);
  const [deleteFileDocument] = useMutation(DELETE_FILE_DOCUMENT, {
    variables: {
      fileDocumentId,
    },
    onCompleted: () => {
      refetch();
      handleClose();
    },
  });

  return (
    <ConfirmationModal
      title={t('documents.deleteConfimationTitile')}
      bodyText={t('documents.deleteConfirmationContent')}
      cancelButtonLabel={t('documents.cancelDelete')}
      confirmButtonLabel={t('documents.proceedDelete')}
      open={open}
      maxWidth='sm'
      onConfirm={() => deleteFileDocument()}
      onCancel={handleClose}
    />
  );
};

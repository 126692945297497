import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { FeeGrid } from '../../../../../interfaces';
import { Typography, Grid, Box } from '../../../../1-primative';
import { AmountField, NumberField } from '../../../../3-pattern';
import { IconButton } from '../../../../2-component';

const BuildGrid = ({
  grid, setGrid, disabled = false,
} : {
  grid: FeeGrid[], setGrid: (grid: FeeGrid[]) => void, disabled?: boolean,
}) => {
  const { t } = useTranslation(['feeAndBilling']);
  const mappedGrid = grid.map((g, i, list) => ({
    ...g,
    minRangeAmount: (g.minMarketValueCents ?? 0) + (i > 0 ? 1 : 0),
    maxRangeAmount: (list.length - 1 > i) ? list[i + 1].minMarketValueCents : undefined,
  }));
  return (
    <>
      {mappedGrid.map((g, i) => (
        <Box key={i}>
          <Grid container spacing={1}>
            <Grid item xs={2.5}>
              <AmountField
                label={t('feeAndBilling:feeGrid.edit.grid.minAmount')}
                amount={((g.minRangeAmount ?? 0) / 100).toString()}
                setAmount={() => {}}
                fullWidth
                disabled
                data-testid={`${i}-minRange`}
              />
            </Grid>
            <Grid item xs={0.5} sx={{ alignSelf: 'center' }}>
              <Typography>{t('feeAndBilling:feeGrid.edit.grid.to')}</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <AmountField
                label={t('feeAndBilling:feeGrid.edit.grid.maxAmount')}
                amount={((g.maxRangeAmount ?? 0) / 100)?.toString() ?? '0'}
                fullWidth
                disabled={disabled || i >= 9}
                data-testid={`${i}-maxRange`}
                placeholder={t('feeAndBilling:feeGrid.edit.grid.above')}
                setAmount={(e: any) => {
                  const v = parseInt(e, 10) * 100;
                  if (i >= 9) return;
                  if (mappedGrid.length === i + 1 && v) {
                    mappedGrid.push({ minRangeAmount: (v + 1), maxRangeAmount: undefined });
                  }
                  if (mappedGrid[i + 1] && v) {
                    mappedGrid[i + 1].minMarketValueCents = v;
                    setGrid(mappedGrid);
                  }
                }}
              />
            </Grid>
            <Grid item xs={0.5} sx={{ alignSelf: 'center' }}>
              <Typography>=</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <NumberField
                label={t('feeAndBilling:feeGrid.edit.grid.annualFeeBps')}
                disabled={disabled}
                data-testid={`${i}-annualFeeBps`}
                decimalPlaces={2}
                number={g.annualFeeBps?.toString() ?? ''}
                setNumber={(value) => {
                  if (mappedGrid[i]) {
                    mappedGrid[i].annualFeeBps = parseInt(value, 10);
                    setGrid(mappedGrid);
                  }
                }}
              />
            </Grid>
            <Grid item xs={0.5} sx={{ alignSelf: 'center' }}>
              <Typography>+</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <AmountField
                label={t('feeAndBilling:feeGrid.edit.grid.annualFeeCents')}
                amount={((g?.annualFeeCents ?? 0) / 100).toString()}
                fullWidth
                disabled={disabled}
                data-testid={`${i}-annualFeeCents`}
                setAmount={(e: any) => {
                  if (mappedGrid[i]) {
                    mappedGrid[i].annualFeeCents = e * 100;
                    setGrid(mappedGrid);
                  }
                }}
              />
            </Grid>
            <Grid item xs={0.5} sx={{ alignSelf: 'center' }}>
              {grid && i > 0 && !disabled && (
                <IconButton onClick={() => setGrid(grid.filter((f, index) => index !== i))}><CloseIcon/></IconButton>
              )}
            </Grid>
          </Grid>
        </Box>
      ))}
    </>
  );
};

export default BuildGrid;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import PreviewIcon from '@mui/icons-material/Preview';
import DraftsIcon from '@mui/icons-material/Drafts';
import EditIcon from '@mui/icons-material/Edit';
import { gql, useMutation, useQuery } from '@apollo/client';
import { FormTemplate } from '../../../interfaces';
import {
  Badge, Button, Card, IconButton,
} from '../../2-component';
import { Box, Skeleton, Typography } from '../../1-primative';
import { translateBackend } from '../../../assets/i18n/config';
import TemplateViewerModal from './components/templateViewerModal';
import TitleEdit from './components/titleEdit';
import RichTextEditor from '../../3-pattern/richTextEditor/richTextEditor';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import { BetaBadge } from '../../3-pattern';

const FETCH_FORM_TEMPLATE = gql`
  query fetchFormTemplate($input: FetchFormTemplateInput!) {
    fetchFormTemplate(input: $input) {
      id
      organization {
        id
        name
      }
      state
      type
      templateUrl
      minVersion
      translatedHtml {
        en
        fr
      }
      updatedAt
      translatedDisplayName {
        en
        fr
      }
    }
  }
`;

const UPDATE_FORM_TEMPLATE = gql`
  mutation updateFormTemplate($input: UpdateFormTemplateInput!) {
    updateFormTemplate(input: $input) {
      formTemplate {
        id
      }
    }
  }
`;

const encodeBase64 = (value: string) => btoa(new TextEncoder().encode(value).reduce((data, byte) => data + String.fromCharCode(byte), ''));

const EditFormTemplate = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation(['shared', 'client', 'orgSettings', 'components']);
  const { showToast } = useGlobalToast();
  const [viewRecord, setViewRecord] = useState<FormTemplate | null | undefined>(null);
  const [openTitleEdit, setOpenTitleEdit] = useState(false);
  const [templateToUpdate, setTemplateToUpdate] = useState({
    id: '',
    title: {
      en: '',
      fr: '',
    },
    template: {
      en: '',
      fr: '',
    },
  });
  const { data, loading, refetch } = useQuery(FETCH_FORM_TEMPLATE, {
    variables: {
      input: {
        formTemplateId: id,
      },
    },
    fetchPolicy: 'no-cache',
    onCompleted: (res: any) => {
      setTemplateToUpdate({
        id: res.fetchFormTemplate.id ?? '',
        title: {
          en: res?.fetchFormTemplate?.translatedDisplayName?.en ?? '',
          fr: res?.fetchFormTemplate.translatedDisplayName?.fr ?? '',
        },
        template: {
          en: res?.fetchFormTemplate?.translatedHtml?.en ?? '',
          fr: res?.fetchFormTemplate.translatedHtml?.fr ?? '',
        },
      });
    },
  });

  const [updateFormTemplate] = useMutation(UPDATE_FORM_TEMPLATE, {
    variables: {
      input: {
        formTemplateId: templateToUpdate.id,
        translatedHtml: {
          en: encodeBase64(templateToUpdate.template.en),
          fr: encodeBase64(templateToUpdate.template.fr),
        },
        translatedDisplayName: {
          en: templateToUpdate.title.en,
          fr: templateToUpdate.title.fr,
        },
      },
    },
    onCompleted: () => {
      showToast({ message: t('orgSettings:documentManagement.toastMessage.success'), severity: 'success' });
    },
  });

  const onPublish = async () => {
    await updateFormTemplate();
    await refetch();
  };

  return (
    <>
      {loading ? (
        <Box m={1}>
          <Skeleton width='100%' height='34px' variant='rectangular' animation='wave' />
        </Box>
      ) : (
        <>
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <Card sx={{ p: 2 }}>
              <Box display='flex' justifyContent='space-between'>
                <Box display={'flex'} alignItems={'center'} gap={2}>
                  <Typography variant='headingLarge'>
                    {data?.fetchFormTemplate?.translatedDisplayName ? translateBackend(data?.fetchFormTemplate?.translatedDisplayName) : t('orgSettings:documentManagement.noTitle')}
                  </Typography>
                  <Badge label={t(`orgSettings:documentManagement.templateState.${data?.fetchFormTemplate?.state}`)} color={data?.fetchFormTemplate?.state === 'ACTIVE' ? 'positive' : 'neutral'} />
                  <BetaBadge variant='small'/>
                </Box>
                <IconButton
                  onClick={() => {
                    setOpenTitleEdit(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Box>
              <Box>
                <Typography variant='headingXSmall'>
                  {t('orgSettings:documentManagement.minVersionLable')} {data?.fetchFormTemplate?.minVersion}
                </Typography>
              </Box>
            </Card>
          </Box>
          <Box mt={2} display={'flex'} flexDirection={'column'} gap={2}>
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              <Typography sx={{ pl: 2 }} variant='headingMedium'>
                {t('orgSettings:documentManagement.language.ENGLISH')}
              </Typography>
              <RichTextEditor
                id='editFormTemplateMceEditor'
                value={templateToUpdate.template.en}
                setValue={(newValue) => setTemplateToUpdate((prev) => ({
                  ...prev,
                  template: {
                    ...prev.template,
                    en: newValue,
                  },
                }))
                }
              />
            </Box>
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              <Typography sx={{ pl: 2 }} variant='headingMedium'>
                {t('orgSettings:documentManagement.language.FRENCH')}
              </Typography>
              <RichTextEditor
                id='editFormTemplateMceEditor'
                value={templateToUpdate.template.fr}
                setValue={(newValue) => setTemplateToUpdate((prev) => ({
                  ...prev,
                  template: {
                    ...prev.template,
                    fr: newValue,
                  },
                }))
                }
              />
            </Box>
          </Box>
          <Box
            bottom={0}
            height='80px'
            display='flex'
            justifyContent='end'
            sx={{
              padding: '24px 8px 24px 20px',
            }}
          >
            <Button
              disabled
              sx={{ ml: 1, mr: 1 }}
              onClick={() => {
                // save draft
              }}
              type='button'
              variant='outlined'
              leadingIcon={DraftsIcon}
              label={''}
            >
              {t('orgSettings:documentManagement.action.draft')}
            </Button>
            <Button
              disabled
              sx={{ ml: 1, mr: 1 }}
              onClick={() => {
                setViewRecord(data?.fetchFormTemplate);
              }}
              type='button'
              variant='tonal'
              leadingIcon={RemoveRedEyeIcon}
              label={''}
            >
              {t('orgSettings:documentManagement.action.preview')}
            </Button>
            <Button sx={{ ml: 1 }} onClick={onPublish} type='button' variant='filled' leadingIcon={PreviewIcon} label={''}>
              {t('orgSettings:documentManagement.action.publish')}
            </Button>
          </Box>
        </>
      )}
      {viewRecord && (
        <TemplateViewerModal
          formTemplate={viewRecord}
          origin='EDITOR'
          onModalClose={() => {
            setViewRecord(null);
          }}
        />
      )}
      <TitleEdit
        formTemplate={data?.fetchFormTemplate}
        openDialog={openTitleEdit}
        setOpenDialog={setOpenTitleEdit}
        value={templateToUpdate.title ?? {}}
        onChange={(newValue) => setTemplateToUpdate((prev) => ({
          ...prev,
          title: { ...prev.title, ...newValue },
        }))
        }
      />
    </>
  );
};
export default EditFormTemplate;

import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '../../../../1-primative';
import { MenuItem, SelectField, TextField } from '../../../../2-component';
import { CreateNewModal, OrganizationSelect } from '../../../../3-pattern';
import { EventObject, WebHookType } from '../../../../../interfaces';
import { ValidateRule } from '../../../../../interfaces/validateRule';
import { validateForm } from '../../../../../util';
import Attribute from './attribute';
import { FETCH_WEBHOOKS } from '../webhooks';

const CREATE_WEB_HOOK = gql`
  mutation createWebhook($input: CreateWebhookInput!) {
    createWebhook(input: $input) {
      webhook{
        id
      }
    }
  }
`;

const CREATE_REST_WEBHOOK = gql`
  mutation createRestWebhook($input: CreateRestWebhookInput!) {
    createRestWebhook(input: $input) {
      webhook{
        id
      }
    }
  }
`;

const DEFAULT_TYPE: string = WebHookType.REST;

export const NewWebhook = ({ afterCreate, defaultOrg }: { defaultOrg: string; afterCreate: () => void }) => {
  const { t } = useTranslation(['devSettings']);

  const [webHook, setWebHook] = useState({
    type: DEFAULT_TYPE,
    organizationId: defaultOrg,
    objectType: '',
    customHeaders: [] as { key: string; value: string }[],
    eventType: '',
    gql: '',
    url: '',
    customPayloadAttributes: [] as { key: string; value: string }[],
    signedJwtHeader: '',
    mutationName: '',
    mutationInputName: '',
  });
  const [initialState] = useState({
    type: DEFAULT_TYPE,
    organizationId: defaultOrg,
    objectType: '',
    customHeaders: [] as { key: string; value: string }[],
    eventType: '',
    gql: '',
    url: '',
    customPayloadAttributes: [] as { key: string; value: string }[],
    signedJwtHeader: '',
    mutationName: '',
    mutationInputName: '',
  });

  const formRules: ValidateRule[] = [
    {
      key: 'type',
      rule: 'SHOULD_EXIST',
    },
    {
      key: 'objectType',
      rule: 'SHOULD_EXIST',
    },
    {
      key: 'gql',
      rule: 'SHOULD_EXIST',
    },
    {
      key: 'url',
      rule: 'SHOULD_EXIST',
    },
  ];

  if (webHook.type === WebHookType.GRAPHQL) {
    formRules.push({
      key: 'mutationName',
      rule: 'SHOULD_EXIST',
    }, {
      key: 'mutationInputName',
      rule: 'SHOULD_EXIST',
    });
  }

  const normalizeAttributes = (attributes: { key: string; value: string }[]) => {
    const normalizedAttributes: Record<string, string> = {};
    attributes.forEach(({ key, value }) => {
      let normalizedKey = key;
      // Remove timestamp and hyphen if present
      normalizedKey = normalizedKey.replace(/^\d+-/, '');

      if (normalizedAttributes[normalizedKey]) {
        normalizedAttributes[normalizedKey] += `, ${value}`;
      } else {
        normalizedAttributes[normalizedKey] = value;
      }
    });
    return normalizedAttributes;
  };

  function conditionalMutation(type: any) {
    const normalizedWebHook = {
      ...webHook,
      customHeaders: normalizeAttributes(webHook.customHeaders),
      customPayloadAttributes: normalizeAttributes(webHook.customPayloadAttributes),
    };

    if (type === WebHookType.GRAPHQL) {
      const {
        type: excludedType, customPayloadAttributes, signedJwtHeader, ...newWebHook
      } = normalizedWebHook;
      return createWebHook({
        variables: {
          input: newWebHook,
        },
        refetchQueries: [FETCH_WEBHOOKS],
        onCompleted: () => {
          setWebHook(initialState);
        },
      });
    }

    const {
      type: excludedType, mutationInputName, mutationName, ...newWebHook
    } = normalizedWebHook;
    return createRestWebHook({
      variables: {
        input: newWebHook,
      },
      refetchQueries: [FETCH_WEBHOOKS],
      onCompleted: () => {
        setWebHook(initialState);
      },
    });
  }

  const [createWebHook, { loading: loading1 }] = useMutation(CREATE_WEB_HOOK);
  const [createRestWebHook, { loading: loading2 }] = useMutation(CREATE_REST_WEBHOOK);

  const create = async () => {
    await conditionalMutation(webHook.type);
  };

  const handleCustomHeaderChange = (headers: { key: string; value: string }[]) => {
    setWebHook((prevEditWebHook) => ({
      ...prevEditWebHook,
      customHeaders: headers,
    }));
  };

  const handleCustomPayloadAttributeChange = (headers: { key: string; value: string }[]) => {
    setWebHook((prevEditWebHook) => ({
      ...prevEditWebHook,
      customPayloadAttributes: headers,
    }));
  };

  return (
    <CreateNewModal
      disabled={!validateForm(formRules, webHook)}
      initialState={initialState}
      state={webHook}
      loading={loading1 || loading2}
      title={t('WebHookModal.title')}
      onSubmit={create}
      sx={{ m: 1 }}
      onClose={() => setWebHook(initialState)}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <SelectField
          value={webHook.type}
          label={t('WebHookModal.type')}
          fullWidth
          onChange={(e: any) => {
            setWebHook({ ...webHook, type: e.target.value });
          }}
        >
          {Object.keys(WebHookType).map((x: any) => (
            <MenuItem key={x.toString()} value={x}>
              {t(`webHooksTableOptions.type.${x}`)}
            </MenuItem>
          ))}
        </SelectField>
        <OrganizationSelect
        value={webHook.organizationId}
        label={t('WebHookModal.organization')}
        onChange={(event: any) => setWebHook({ ...webHook, organizationId: event.target.value as string })}
        />
        <SelectField
          value={webHook.objectType}
          label={t('WebHookModal.objectType')}
          fullWidth
          onChange={(e: any) => {
            setWebHook({ ...webHook, objectType: e.target.value });
          }}
        >
          {Object.keys(EventObject).map((x: any) => (
            <MenuItem key={x.toString()} value={x}>
              {t(`webHooksTableOptions.objectType.${x}`)}
            </MenuItem>
          ))}
        </SelectField>
        <Attribute
          attributesList={webHook.customHeaders}
          onAttributeChange={handleCustomHeaderChange}
          attributeKeyLabel={t('WebHookModal.customHeaderKey')}
          attributeValueLabel={t('WebHookModal.customHeaderValue')}
        />
        <TextField
          required
          fullWidth
          label={t('WebHookModal.eventType')}
          value={webHook.eventType}
          onChange={(event: any) => setWebHook({ ...webHook, eventType: event.target.value })}
        />
        <TextField
          required
          fullWidth
          label={t('WebHookModal.graphQL')}
          value={webHook.gql}
          onChange={(event: any) => setWebHook({ ...webHook, gql: event.target.value })}
        />
        <TextField
          required
          fullWidth
          label={t('WebHookModal.url')}
          value={webHook.url}
          onChange={(event: any) => setWebHook({ ...webHook, url: event.target.value })}
        />
        {webHook.type === WebHookType.REST ? (
          <>
            <Attribute
              attributesList={webHook.customPayloadAttributes}
              onAttributeChange={handleCustomPayloadAttributeChange}
              attributeKeyLabel={t('WebHookModal.payloadAttributeKey')}
              attributeValueLabel={t('WebHookModal.payloadAttributeValue')}
            />
            <TextField
              fullWidth
              label={t('WebHookModal.signedJWTHeader')}
              value={webHook.signedJwtHeader}
              onChange={(event: any) => setWebHook({ ...webHook, signedJwtHeader: event.target.value })}
            />
          </>
        ) : (
          <>
            <TextField
              required
              fullWidth
              label={t('WebHookModal.mutationName')}
              value={webHook.mutationName}
              onChange={(event: any) => setWebHook({ ...webHook, mutationName: event.target.value })}
            />
            <TextField
              required
              fullWidth
              label={t('WebHookModal.mutationInputName')}
              value={webHook.mutationInputName}
              onChange={(event: any) => setWebHook({ ...webHook, mutationInputName: event.target.value })}
            />
          </>
        )}
      </Box>
    </CreateNewModal>
  );
};

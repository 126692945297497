import { gql, useMutation, useQuery } from '@apollo/client';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import { usePageState } from '../../../../util/usePageState';
import { BillingSchedule, BillingScheduleFrequencies, BillingScheduleStates } from '../../../../interfaces';
import NewBillingSchedule from './components/newBillingSchedule';
import EditBillingSchedule from './components/editBillingSchedule';
import { Typography, Box, Skeleton } from '../../../1-primative';
import {
  Table, TableHeadCell,
  TableRow, TableCell, TableBody, Pagination, Tooltip,
  IconButton, Menu, MenuItem, CardContent, Card, Badge,
  SelectField,
} from '../../../2-component';
import { ConfirmationModal, SettingsTitle } from '../../../3-pattern';
import { UpdateOrganizationSwitch } from '../../../4-module/updateOrganizationSwitch/updateOrganizationSwitch';
import { useGlobalToast } from '../../../../providers/globalToastProvider';
import { useLocalization } from '../../../../util/useLocalization';
import { DefaultOrgBillingScheduleBox } from './components/defaultOrgBillingScheduleBox';

export const FETCH_BILLING_SCHEDULES = gql`
  query fetchBillingSchedules($input: FetchBillingSchedulesInput!) {
    fetchBillingSchedules(input: $input) {
      totalCount
      billingSchedules {
        id
        state
        default
        frequency
        nextCalculationDate
        nextBillingDate
        startDate
        endDate
        organization { id name }
      }
    }
  }
`;

const TRANSITION_BILLING_SCHEDULE = gql`
  mutation transitionBillingSchedule($input: TransitionBillingScheduleInput!) {
    transitionBillingSchedule(input: $input) {
      billingSchedule {
        id
      }
    }
  }
`;

const findBillingPeriod = ({
  frequency = BillingScheduleFrequencies.MONTHLY,
  nextCalculationDate,
}: {
  frequency?: BillingScheduleFrequencies,
  nextCalculationDate?: string,
}) => {
  const endDate = dayjs(nextCalculationDate);
  let startDate: Dayjs;
  if (frequency === BillingScheduleFrequencies.MONTHLY) {
    startDate = dayjs(nextCalculationDate).subtract(1, 'month');
  } else if (frequency === BillingScheduleFrequencies.QUARTERLY) {
    startDate = dayjs(nextCalculationDate).subtract(3, 'month');
  } else if (frequency === BillingScheduleFrequencies.SEMESTRAL) {
    startDate = dayjs(nextCalculationDate).subtract(6, 'month');
  } else if (frequency === BillingScheduleFrequencies.ANNUAL) {
    startDate = dayjs(nextCalculationDate).subtract(1, 'year');
  } else {
    startDate = endDate;
  }

  return {
    startDate: startDate.startOf('month').format('YYYY-MM-DD'),
    endDate: endDate.startOf('month').subtract(1, 'day').format('YYYY-MM-DD'),
  };
};

export const BillingSchedulesSettings = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { showToast } = useGlobalToast();
  const { t } = useTranslation(['feeAndBilling', 'shared']);
  const { localizedDate } = useLocalization();
  const [billingScheduleOpen, setBillingScheduleOpen] = useState(false);
  const [selectedBillingSchedule, setSelectedBillingSchedule] = useState<BillingSchedule | null>(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 15;
  const [filterOptions, setFilterOptions] = useState({
    frequency: 'ANY',
  });

  const {
    loading, error, data, previousData,
  } = useQuery(FETCH_BILLING_SCHEDULES, {
    skip: !activeOrganization.id,
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          frequencies: filterOptions.frequency === 'ANY' ? undefined : filterOptions.frequency,
          states: [BillingScheduleStates.ACTIVE, BillingScheduleStates.INACTIVE],
        },
        pagination: {
          sortField: 'nextBillingDate', sortDesc: false, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState<null | HTMLElement>(null);
  const contextMenuOpen = Boolean(contextMenuAnchorEl);

  const openContextMenu = (onElement: HTMLElement, billingSchedule: BillingSchedule) => {
    setSelectedBillingSchedule(billingSchedule);
    setContextMenuAnchorEl(onElement);
  };
  const closeContextMenu = () => setContextMenuAnchorEl(null);

  const [transitionBillingSchedule, { loading: transitionLoading }] = useMutation(TRANSITION_BILLING_SCHEDULE, {
    refetchQueries: [FETCH_BILLING_SCHEDULES],
  });

  const toggleBillingScheduleState = async (billingSchedule: BillingSchedule) => {
    const response = await transitionBillingSchedule({
      variables: {
        input: {
          billingScheduleId: billingSchedule.id,
          transition: billingSchedule.state === BillingScheduleStates.ACTIVE ? 'deactivate' : 'activate',
        },
      },
    });
    if (response?.data) {
      showToast({ severity: 'success', message: t('feeAndBilling:billingSchedule.edit.updatedToastMessage') });
    }
  };

  const deleteBillingSchedule = async (billingSchedule: BillingSchedule) => {
    const response = await transitionBillingSchedule({
      variables: {
        input: {
          billingScheduleId: billingSchedule.id,
          transition: 'archive',
        },
      },
    });
    if (response?.data) {
      showToast({ severity: 'success', message: t('feeAndBilling:billingSchedule.edit.deletedToastMessage') });
    }
    setDeleteConfirmationOpen(false);
  };

  const canWrite = permissions.includes('write:billing_schedule') && permissions.includes('transition:billing_schedule');
  const isInheirited = (billingSchedule: BillingSchedule) => billingSchedule.organization?.id !== activeOrganization.id;

  if (error) (<Typography>Error</Typography>);

  return (
    <>
      <SettingsTitle title={t('settings:billingSchedules.title')} description={t('settings:billingSchedules.description')} />
      <Box display='flex' flexDirection='column' gap={2}>
        <DefaultOrgBillingScheduleBox />
        <Card loading={loading}>
          <CardContent>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Box display='flex' gap={1} alignItems='center'>
                <SelectField
                  value={filterOptions.frequency}
                  label=''
                  onChange={(e: any) => setFilterOptions((prev) => ({ ...prev, frequency: e.target.value }))}
                  fullWidth
                  placeholder={t('shared:any')}
                >
                  <MenuItem value="ANY">{t('shared:any')}</MenuItem>
                  {[
                    BillingScheduleFrequencies.MONTHLY,
                    BillingScheduleFrequencies.QUARTERLY,
                  ].map((x: BillingScheduleFrequencies) => (
                    <MenuItem value={x} key={x}>{t(`feeAndBilling:billingSchedule.frequencies.${x}`)}</MenuItem>
                  ))}
                </SelectField>
                {permissions.includes('write:advanced_organization_settings') ? (
                  <UpdateOrganizationSwitch
                    field='useParentBillingSchedules'
                    label={t('settings:billingSchedules.useParentBillingSchedules')}
                    organizationId={activeOrganization.id}
                    refetchQueries={[FETCH_BILLING_SCHEDULES]}
                  />
                ) : <Box/>}
              </Box>
              {canWrite && (
                <NewBillingSchedule />
              )}
            </Box>
          </CardContent>
          <Table sx={{ minWidth: 650 }} aria-label="table">
            <TableBody>
              <TableRow>
                <TableHeadCell>{t('feeAndBilling:billingSchedule.table.frequency')}</TableHeadCell>
                <TableHeadCell>{t('feeAndBilling:billingSchedule.table.nextCycle')}</TableHeadCell>
                <TableHeadCell>{t('feeAndBilling:billingSchedule.table.nextCalculationDate')}</TableHeadCell>
                <TableHeadCell>{t('feeAndBilling:billingSchedule.table.nextBillingDate')}</TableHeadCell>
                <TableHeadCell>{t('feeAndBilling:billingSchedule.table.state')}</TableHeadCell>
                {canWrite && (<TableHeadCell></TableHeadCell>)}
              </TableRow>
              { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
                <TableRow key={i}>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                </TableRow>
              ))}
              {(data || previousData)?.fetchBillingSchedules?.billingSchedules?.map((billingSchedule: BillingSchedule) => (
                <TableRow
                  hover
                  onClick={() => {
                    setSelectedBillingSchedule(billingSchedule);
                    setBillingScheduleOpen(true);
                  }}
                  key={billingSchedule.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
                >
                  <TableCell><Typography>{t(`feeAndBilling:billingSchedule.frequencies.${billingSchedule.frequency}`)}</Typography></TableCell>
                  <TableCell>
                    <Typography>
                      {billingSchedule?.startDate ? localizedDate(billingSchedule.startDate) : localizedDate(findBillingPeriod(billingSchedule).startDate)}
                      &nbsp; — &nbsp;
                      {billingSchedule?.endDate ? localizedDate(billingSchedule.endDate) : localizedDate(findBillingPeriod(billingSchedule).endDate)}
                    </Typography>
                  </TableCell>
                  <TableCell>{localizedDate(billingSchedule.nextCalculationDate)}</TableCell>
                  <TableCell>{localizedDate(billingSchedule.nextBillingDate)}</TableCell>
                  <TableCell>
                    <Badge
                      label={billingSchedule.state === BillingScheduleStates.ACTIVE
                        ? t('feeAndBilling:billingSchedule.table.active')
                        : t('feeAndBilling:billingSchedule.table.inactive')} color={billingSchedule.state === BillingScheduleStates.ACTIVE ? 'positive' : 'negative'}
                    />
                  </TableCell>
                  {canWrite && (
                    <TableCell align="right">
                      {!isInheirited(billingSchedule) ? (
                        <IconButton
                          size='small'
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.preventDefault();
                            event.stopPropagation();
                            openContextMenu(event.currentTarget, billingSchedule);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      ) : (
                        <Tooltip title={t('shared:inheritedTooltip', { model: 'Billing Schedule', organization: billingSchedule.organization?.name })}>
                          <Badge label={t('shared:inheritedFlag')} />
                        </Tooltip>)}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchBillingSchedules?.totalCount ?? 0) / pageSize)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />
          {selectedBillingSchedule && billingScheduleOpen && (
            <EditBillingSchedule
              billingScheduleToUpdate={selectedBillingSchedule}
              handleClose={() => {
                setBillingScheduleOpen(false);
                setSelectedBillingSchedule(null);
              }}
              canWrite={canWrite}
              isInheirited={isInheirited(selectedBillingSchedule)}
              organizationId={activeOrganization?.id}
            />
          )}
          {selectedBillingSchedule && canWrite && (
            <>
              <Menu
                anchorEl={contextMenuAnchorEl}
                open={contextMenuOpen}
                onClose={closeContextMenu}
                onClick={closeContextMenu}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{ cursor: 'pointer' }}
              >
                <MenuItem onClick={() => toggleBillingScheduleState(selectedBillingSchedule)}>
                  {selectedBillingSchedule.state === BillingScheduleStates.ACTIVE ? t('shared:deactivate') : t('shared:activate')}
                </MenuItem>
                <MenuItem onClick={() => setDeleteConfirmationOpen(true)}>{t('shared:delete')}</MenuItem>
              </Menu>
              <ConfirmationModal
                open={deleteConfirmationOpen}
                loading={transitionLoading}
                title={t('feeAndBilling:billingSchedule.delete.confirmationTitle')}
                bodyText={t('feeAndBilling:billingSchedule.delete.confirmationMessage')}
                onConfirm={() => deleteBillingSchedule(selectedBillingSchedule)}
                onCancel={() => setDeleteConfirmationOpen(false)}
              />
            </>
          )}
        </Card>
      </Box>
    </>
  );
};

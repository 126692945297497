import { useContext, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { validateUrl } from '../../../../util';
import {
  Button, Dialog, DialogContent, DialogFooter, DialogTitle, IconButton,
} from '../../../2-component';
import { CREATE_FILE_DOCUMENT, FETCH_DOCUMENTS, FETCH_FILE_UPLOAD_URL } from '../../../3-pattern/addBankAccount/addBankAccountManually/addBankAccountManually';
import { UserContext } from '../../../../providers/userContextProvider';
import { CreateAndEditNewsAndInsightsForm, type Article } from './createAndEditNewsAndInsightsForm';
import { CREATE_ARTICLE } from './queries';

const DEFAULT_ARTICLE_STATE: Article = {
  id: '',
  articleUrl: '',
  translatedName: { en: '', fr: '' },
  translatedDescription: { en: '', fr: '' },
};

export const CreateArticle = ({
  open, setOpen, refetch, loading, transitionArticle,
}: {
  open: boolean, setOpen: (open: boolean) => void, refetch: () => void, loading: boolean, transitionArticle: (input: any) => Promise<any>,
}) => {
  const { t } = useTranslation('newsAndInsight');
  const { activeOrganization, userContext } = useContext(UserContext);

  const [newArticle, setNewArticle] = useState<Article>(DEFAULT_ARTICLE_STATE);
  const [articleImageFile, setArticleImageFile] = useState<File>();
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [createArticleLoading, setCreateArticleLoading] = useState(false);

  const [fetchFileUploadUrl] = useLazyQuery(FETCH_FILE_UPLOAD_URL, { fetchPolicy: 'no-cache' });
  const [createFileDocument] = useMutation(CREATE_FILE_DOCUMENT);

  const [createArticleMutation] = useMutation(CREATE_ARTICLE, {
    variables: {
      input: {
        articleUrl: newArticle.articleUrl,
        organizationId: activeOrganization.id,
        translatedName: { en: newArticle.translatedName.en, fr: newArticle.translatedName.fr },
        translatedDescription: { en: newArticle.translatedDescription.en, fr: newArticle.translatedDescription.fr },
      },
    },
  });

  const doUpload = async (uploadingFile: File, articleId: string) => {
    const createFileInput: any = {
      objectType: 'ORGANIZATION',
      objectId: activeOrganization.id,
      fileName: uploadingFile?.name,
      type: 'ARTICLE',
    };

    /* (1) fetch the S3 upload URL from backend */
    const queryResult = await fetchFileUploadUrl({ variables: { input: { ...createFileInput, userId: userContext.id, generateUniqueFileName: true } } });
    const uploadUrl = queryResult?.data?.fetchFileUploadUrl.temporarySignedURL;
    const s3Path = queryResult?.data?.fetchFileUploadUrl.s3Path;
    if (!uploadUrl || queryResult?.error) {
      // showToast({ severity: 'error', message: 'File upload failed' });
      return;
    }
    /* (2) do the upload */
    try {
      // setUploadPending(true);
      const uploaded: Response = await fetch(new Request(uploadUrl, { method: 'PUT', body: uploadingFile }));
      if (!uploaded.ok) throw (new Error(`${uploaded.status} ${uploaded.statusText}`));
    } catch (e: any) {
      // showToast({ severity: 'error', message: 'File upload failed' });
      return;
    } finally {
      // setUploadPending(false);
    }

    /* (3) create the fileDocument within backend */
    createFileInput.name = uploadingFile.name;
    createFileInput.sourceId = articleId;
    createFileInput.mediaType = uploadingFile.type;
    createFileInput.permissionType = 'PUBLIC';
    createFileInput.sourceType = 'ARTICLE';
    createFileInput.s3Path = s3Path;

    try {
      await createFileDocument({ variables: { input: createFileInput }, refetchQueries: [FETCH_DOCUMENTS] });
    } catch (error) {
      // showToast({ severity: 'error', message: 'File upload failed' });
    }
    // showToast({ severity: 'success', message: `Document uploaded successfully: ${uploadingFile.name}` });
  };

  const handleCreateArticleDialogClose = () => {
    setErrorFields([]);
    resetFileImageAndSrc();
    setOpen(false);
    setNewArticle(DEFAULT_ARTICLE_STATE);
  };

  const handleFileInputChange = (file: File) => {
    setImageSrc(URL.createObjectURL(file));
    setArticleImageFile(file);
  };

  const resetFileImageAndSrc = () => {
    setArticleImageFile(undefined);
    setImageSrc(null);
  };

  const handleValidationClear = (value: string, fieldName: string) => {
    if (value.length > 0 && errorFields.includes(fieldName)) {
      const removedField = errorFields.filter((field) => field !== fieldName);
      setErrorFields([...removedField]);
    }
  };

  const hasInValidFields = (): boolean => {
    const fieldsWithError: string[] = [];
    if (!newArticle.translatedName.en) fieldsWithError.push('translatedName');
    if (!validateUrl(newArticle.articleUrl)) fieldsWithError.push('articleUrl');
    if (!newArticle.translatedDescription.en) fieldsWithError.push('translatedDescription');

    setErrorFields((prevState) => [...prevState, ...fieldsWithError]);
    return fieldsWithError.length > 0;
  };

  const createArticle = async (type: 'draft' | 'publish') => {
    if (hasInValidFields()) return;

    try {
      setCreateArticleLoading(true);
      const response = await createArticleMutation();
      if (articleImageFile && response.data?.createArticle) {
        await doUpload(articleImageFile, response.data.createArticle.article.id);
      }
      if (type === 'publish' && response.data?.createArticle) {
        await transitionArticle({ variables: { input: { articleId: response.data.createArticle.article.id, transition: 'activate' } } });
      }

      refetch();
      handleCreateArticleDialogClose();
    } catch {
      // empty
    } finally {
      setCreateArticleLoading(false);
    }
  };

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={handleCreateArticleDialogClose}>
      <DialogTitle display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        {t('newsAndInsight:articleConfiguration')}
        <IconButton onClick={handleCreateArticleDialogClose}><CloseRoundedIcon /></IconButton>
      </DialogTitle>
      <DialogContent>
        <CreateAndEditNewsAndInsightsForm
          imageSrc={imageSrc}
          resetFileImageAndSrc={resetFileImageAndSrc}
          handleFileInputChange={handleFileInputChange}
          article={newArticle}
          setArticleToUpdate={setNewArticle}
          errorFields={errorFields}
          handleValidationClear={handleValidationClear}
          disabled={loading || createArticleLoading}
        />
      </DialogContent>
      <DialogFooter>
        <Button variant='tonal' disabled={loading || createArticleLoading} label={t('shared:save')} onClick={() => createArticle('draft')} dataTestId='create-article-save-button' />
        <Button disabled={loading || createArticleLoading} label={t('shared:publish')} onClick={() => createArticle('publish')} dataTestId='create-article-publish-button' />
      </DialogFooter>
    </Dialog>
  );
};

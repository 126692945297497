import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import { kebabCase } from 'lodash';
import { Box } from '../../../1-primative';
import {
  Button, Chip, Form, MenuItem, SelectField, SettingsSwitch, TextField,
} from '../../../2-component';
import { SettingsCard } from '../../../3-pattern';
import { UserContext } from '../../../../providers/userContextProvider';
import { useGlobalToast } from '../../../../providers/globalToastProvider';
import { AuthenticationConnections } from '../../../../interfaces';
import AuthenticationDomainsTable from './components/authenticationDomainsTable';

const FETCH_ORGANIZATION = gql`
  query fetchOrganization($organizationId: String!) {
    fetchOrganization(organizationId: $organizationId) {
      organization {
        id
        name
        enableMultiFactorAuthentication
        multiFactorAuthenticationOptions
        logoutRedirectUrl
        defaultAuthenticationConnection
        customUrl
        authenticationDomains { url authenticationType enterpriseConnectionName useHostedLogin }
      }
    }
  }
`;

const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization { id }
    }
  }
`;

const MULTI_FACTOR_AUTHENTICATION_OPTIONS = [
  'SMS',
  'EMAIL',
  'AUTHENTICATOR',
];

export const AuthenticationSettings = () => {
  const { activeOrganization } = useContext(UserContext);
  const { showToast } = useGlobalToast();
  const { t } = useTranslation(['settings', 'shared']);
  const [organization, setOrganization] = useState<any>(null);

  const { data, loading } = useQuery(FETCH_ORGANIZATION, {
    variables: { organizationId: activeOrganization?.id },
  });

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION, {
    variables: {
      input: {
        organizationId: activeOrganization?.id,
        enableMultiFactorAuthentication: organization?.enableMultiFactorAuthentication,
        logoutRedirectUrl: organization?.logoutRedirectUrl,
        defaultAuthenticationConnection: organization?.defaultAuthenticationConnection,
        customUrl: organization?.customUrl,
        authenticationDomains: organization?.authenticationDomains,
        multiFactorAuthenticationOptions: organization?.multiFactorAuthenticationOptions,
      },
    },
    onCompleted: async () => {
      showToast({
        message: t('shared:successfullySaved'),
        severity: 'success',
      });
    },
  });

  useEffect(() => {
    if (data?.fetchOrganization?.organization) {
      setOrganization(data?.fetchOrganization?.organization);
    }
  }, [data]);

  return (
    <SettingsCard
      title={t('authentication.title')}
      description={t('authentication.description')}
    >
      <Form onSubmit={updateOrganization}>
        <Box display='flex' flexDirection='column' gap={2}>
          {organization && (
            <AuthenticationDomainsTable organization={organization} setOrganization={setOrganization} />
          )}
          <TextField
            label={t('authentication.customUrl')}
            value={organization?.customUrl}
            onChange={(e: any) => setOrganization({ ...organization, customUrl: e.target.value })}
            fullWidth
            testId='authentication-custom-url'
          />
          <TextField
            label={t('authentication.logoutRedirectUrl')}
            value={organization?.logoutRedirectUrl}
            onChange={(e: any) => setOrganization({ ...organization, logoutRedirectUrl: e.target.value })}
            fullWidth
            testId='authentication-logout-redirect-url'
          />
          <SelectField
            fullWidth
            value={organization?.defaultAuthenticationConnection ?? AuthenticationConnections.EMAIL_AND_PASSWORD}
            label={t('authentication.defaultAuthenticationConnection')}
            onChange={(e: any) => setOrganization({ ...organization, defaultAuthenticationConnection: e.target.value as AuthenticationConnections })}
             testId='authentication-default-authentication-connection'
          >
            {Object.values(AuthenticationConnections).map((x: any) => (
              <MenuItem data-testId={`authentication-default-authentication-connection-${kebabCase(x)}`} key={x} value={x}>
                {t(`authentication.defaultAuthenticationConnectionOptions.${x}`)}
              </MenuItem>
            ))}
          </SelectField>
          <SettingsSwitch
            label={t('authentication.enableMultiFactorAuthentication')}
            checked={organization?.enableMultiFactorAuthentication}
            onChange={(e: any) => setOrganization({ ...organization, enableMultiFactorAuthentication: e })}
            testId='authentication-enable-multi-factor-authentication'
            sx={{ paddingBottom: 1 }}
          />
          {organization?.enableMultiFactorAuthentication && (
            <SelectField
              fullWidth
              label={t('authentication.multiFactorAuthenticationTitle')}
              value={organization?.multiFactorAuthenticationOptions.length ? organization?.multiFactorAuthenticationOptions : ['SMS']}
              onChange={(e: any) => {
                setOrganization({ ...organization, multiFactorAuthenticationOptions: e.target.value });
              }}
              multiple
              renderValue={(selected: any) => (
                <Box sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 0.5,
                }}>
                  {selected.map((value: string) => (
                    <Chip
                      key={value}
                      text={t(`authentication.multiFactorAuthenticationOptions.${value}`)}
                    />
                  ))}
                </Box>
              )}
            >
              {MULTI_FACTOR_AUTHENTICATION_OPTIONS.map((mfaOption: any) => (
                <MenuItem
                  key={mfaOption}
                  value={mfaOption}
                  disabled={organization?.multiFactorAuthenticationOptions?.length === 1
                    && organization?.multiFactorAuthenticationOptions?.includes(mfaOption)}
                >
                  {t(`authentication.multiFactorAuthenticationOptions.${mfaOption}`)}
                </MenuItem>
              ))}
            </SelectField>
          )}
          <Box display='flex' justifyContent='flex-end'>
            <Button type='submit' disabled={loading} label={t('shared:save')} dataTestId='authentication-submit-button'/>
          </Box>
        </Box>
      </Form>
    </SettingsCard>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import {
  useEffect, useContext, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { translateBackend } from 'assets/i18n/config';
import CustomFieldRenderer from 'ovComponents/3-pattern/customField/customFieldRenderer';
import InfoDialog from 'ovComponents/2-component/infoDialog/infoDialog';
import { additionalInfo } from 'ovComponents/4-module/configurableOptionFields';
import { Alert } from '@mui/material';
import { CountryCodes, Jurisdictions } from '@onevesthq/ov-enums';
import { kebabCase, some } from 'lodash/fp';
import { gql, useLazyQuery } from '@apollo/client';
import { invalidFields, validEmail } from '../utils';
import {
  Button, TextField, Typography, DateField, AddressField, RadioGroup, Radio, Form, Box, Grid, Autocomplete,
} from '../../../..';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';
import { EnabledJurisdictions } from '../../../../../interfaces';
import { phoneValidator } from '../../../../../pages/client';

const VALIDATE_AGE_OF_MAJORITY = gql`query validateAgeOfMajority($dateOfBirth: String!, $country: AddressCountries!, $jurisdiction: AddressJurisdiction) {
  validateAgeOfMajority(dateOfBirth:$dateOfBirth, country:$country, jurisdiction:$jurisdiction)
  {valid ageOfMajority}
}`;

export const PersonalInformationVisual = ({
  options, userData, loading, updateUser, continueFunc, grid = false, updateMode = false, activeCustomFields, applicableJurisdictions,
  applicableCountries, workflowCompletion, ignoreCustomFieldTriggerRules,
}: {
  options: any, userData: any, loading: boolean, updateUser: any, continueFunc: any, grid?: boolean, updateMode?: boolean,
  activeCustomFields?: string[], applicableJurisdictions?: EnabledJurisdictions, applicableCountries: CountryCodes[], workflowCompletion?: any,
  ignoreCustomFieldTriggerRules?: boolean,
}) => {
  const { sendAnalytic } = useContext(AnalyticsContext);
  const { t } = useTranslation('client');
  const [focused, setFocused] = useState<string[]>([]);
  const [invalidFieldsList, setInvalidFieldsList] = useState<string[]>([]);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [ageOfMajority, setAgeOfMajority] = useState(0);
  const [isUnderAgeOfMajority, setIsUnderAgeOfMajority] = useState(false);
  const [updated, setUpdated] = useState<boolean>(false);
  const [customOptionSelected, setCustomOptionSelected] = useState<any[]>([]);
  const [validateAgeOfMajority] = useLazyQuery(VALIDATE_AGE_OF_MAJORITY);

  const submit = () => {
    const fields = invalidFields({
      ...options,
      inProvinceSince: {
        ...options.inProvinceSince,
        requiredIf: (data: any) => data?.physicalAddress?.province === 'QC',
      },
    }, { ...userData, jurisdiction: userData.physicalAddress?.jurisdiction });

    if (physicalAddressInvalid) fields.push('physicalAddress');

    if (userData.primaryEmail && !validEmail(userData.primaryEmail)) {
      fields.push('primaryEmail');
      setInvalidEmail(true);
    } else {
      setInvalidEmail(false);
    }

    if (userData.phone && !phoneValidator(userData.phone)) {
      fields.push('phone');
      setInvalidPhone(true);
    } else {
      setInvalidPhone(false);
    }

    setFocused(fields);
    setInvalidFieldsList(fields);

    if (fields.length === 0 && !isUnderAgeOfMajority) {
      sendAnalytic(ovAnalyticsEvents.workflowsPersonalInformationContinueButtonSelect, {
        workflowStepTitle: options?.title,
        workflowId: workflowCompletion?.workflow?.id,
        workflowName: workflowCompletion?.workflow?.name,
        activeWorkflowCompletionId: workflowCompletion?.id,
        objectId: workflowCompletion?.objectId,
        objectType: workflowCompletion?.objectType,
      });
      continueFunc();
      setUpdated(false);
    }
  };

  const update = (newValue: any) => {
    setUpdated(true);
    updateUser(newValue);
  };

  useEffect(() => {
    const customOptions: any[] = [];
    Object.keys(options).forEach((key: string) => {
      if (options[key]?.customField && options[key].enabled && activeCustomFields?.includes(key)) {
        customOptions.push(options[key]);
      }
    });
    setCustomOptionSelected(customOptions);
  }, [options, activeCustomFields]);

  const error = (key: string): boolean => (
    ((!userData?.[key] && focused.includes(key)) || invalidFieldsList.includes(key)) && options?.[key]?.required !== 'NOT_REQUIRED'
  );

  const allJurisdictionsOfApplicableCountries = useMemo(
    () => Object.keys(Jurisdictions).filter((j) => some((c: any) => j.startsWith(c), applicableCountries)),
    [applicableCountries],
  );

  const getJurisdictionOptionLabel = (code: any): string => {
    const alsoShowCountry = applicableCountries.length > 1;
    return (alsoShowCountry && code ? `${code.substring(0, 2)} - ` : '') + t(`geoNames:${code}`);
  };

  const physicalAddressOutOfJurisdiction: false | string = useMemo(() => {
    if (!options?.physicalAddress?.enabled && !options?.jurisdiction?.enabled) return false;
    if (!userData) return false;
    if (!userData.physicalAddress?.jurisdiction) return false;

    if (!applicableJurisdictions) return false;

    if (applicableJurisdictions.all === true) return false;
    if (!applicableJurisdictions.only || applicableJurisdictions.only.length === 0) return false;

    if (applicableJurisdictions.only.includes(userData.physicalAddress?.jurisdiction)) return false;

    const errMessage = translateBackend(options?.physicalAddress.addressOutOfJurisdictionMessage)
      || t('client:profile.fieldValidations.addressOutOfJurisdictionMessage');
    return errMessage.replace(
      '{{ALLOWED_JURISDICTIONS}}',
      (applicableJurisdictions?.only ?? [])?.map((j) => j.replace(/^(CA|US)_/, '')).join(', '),
    );
  }, [options.physicalAddress, userData, applicableJurisdictions]);

  const physicalAddressInvalid = physicalAddressOutOfJurisdiction !== false && options?.physicalAddress?.required === 'ERROR';

  useEffect(() => {
    if (!options?.dateOfBirth?.enabled) return;
    if (!focused.includes('dateOfBirth')) return;
    if (!userData.dateOfBirth || !userData.physicalAddress?.jurisdiction) return;

    validateAgeOfMajority({
      variables: { dateOfBirth: userData.dateOfBirth, country: userData.physicalAddress?.country, jurisdiction: userData.physicalAddress?.jurisdiction },
      onCompleted: (data) => {
        setIsUnderAgeOfMajority(!data.validateAgeOfMajority.valid);
        setAgeOfMajority(data.validateAgeOfMajority.ageOfMajority);
      },
    });
  }, [userData, options, focused]);

  const updateJurisdiction = (_e: any, newValue: string) => {
    const physicalAddress = newValue ? {
      ...userData.physicalAddress,
      jurisdiction: newValue,
      country: newValue?.substring(0, 2),
    } : undefined;
    update({ ...userData, physicalAddress });
  };

  useEffect(() => {
    setInvalidFieldsList((prev) => {
      const without = prev.filter((key) => key !== 'physicalAddress');
      if (physicalAddressOutOfJurisdiction !== false) without.push('physicalAddress');
      return without;
    });
  }, [physicalAddressOutOfJurisdiction]);

  const underAgeOfMajorityMessage = options?.dateOfBirth?.underAgeOfMajorityMessage
    ? translateBackend(options.dateOfBirth.underAgeOfMajorityMessage)
    : t('workflowCompletions:personalInformation.underAgeOfMajorityMessage');

  const lockMessage = () => (options.customLockedMessage ? translateBackend(options.customLockedMessage) : t('pageConfiguration:notEditableMessage'));

  return (
    <Form onSubmit={submit} testId='personal-information-form'>
      {options?.title && (
        <Typography variant='displayLarge' sx={{ mt: 1 }}>
          {translateBackend(options?.title)}
          {additionalInfo(options?.title?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.title?.additionalInfo) ?? ''} />)}
        </Typography>
      )}
      {options?.subtitle && (
        <Typography display='inline-flex' alignItems='end' variant='bodyLarge' component={'div'} sx={{ mb: 3, table: { width: '100%' } }}>
          <Box display='inline-block'>
            <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.subtitle)}</ReactMarkdown>
          </Box>
          {additionalInfo(options?.subtitle?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.subtitle?.additionalInfo) ?? ''} />)}
        </Typography>
      )}

      <Grid container spacing={2}>
        {options?.firstName?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              testId='legal-first-name'
              onChange={(e: any) => update({ ...userData, firstName: e.target.value })}
              disabled={loading || options?.firstName?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.firstName?.label)}
              fullWidth
              value={userData.firstName ?? ''}
              onBlur={() => setFocused([...focused, 'firstName'])}
              error={error('firstName')}
              locked={options?.firstName?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              infoTooltip={additionalInfo(options?.firstName?.additionalInfo)}
            />
          </Grid>
        )}
        {options?.middleName?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              testId='legal-middle-name'
              onChange={(e: any) => update({ ...userData, middleName: e.target.value })}
              disabled={loading || options?.middleName?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.middleName?.label)}
              fullWidth
              value={userData.middleName ?? ''}
              onBlur={() => setFocused([...focused, 'middleName'])}
              error={error('middleName')}
              locked={options?.middleName?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              infoTooltip={additionalInfo(options?.middleName?.additionalInfo)}
            />
          </Grid>
        )}
        {options?.lastName?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              testId='legal-last-name'
              onChange={(e: any) => update({ ...userData, lastName: e.target.value })}
              disabled={loading || options?.lastName?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.lastName?.label)}
              fullWidth
              value={userData.lastName ?? ''}
              onBlur={() => setFocused([...focused, 'lastName'])}
              error={error('lastName')}
              locked={options?.lastName?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              infoTooltip={additionalInfo(options?.lastName?.additionalInfo)}
            />
          </Grid>
        )}
        {options?.jurisdiction?.enabled && (
          <Grid item xs={12}>
            <Autocomplete
              testId='jurisdiction-field'
              label={translateBackend(options?.jurisdiction?.label)}
              fullWidth
              options={allJurisdictionsOfApplicableCountries}
              getOptionLabel={getJurisdictionOptionLabel}
              value={userData.physicalAddress?.jurisdiction ?? ''}
              onChange={updateJurisdiction}
              error={(!userData.physicalAddress?.jurisdiction && focused.includes('jurisdiction') && options.jurisdiction.required !== 'NOT_REQUIRED')}
              locked={options?.jurisdiction?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              infoTooltip={additionalInfo(options?.jurisdiction?.additionalInfo)}
              onBlur={() => setFocused([...focused, 'jurisdiction'])}
            />
          </Grid>
        )}
        {options?.physicalAddress?.enabled && (
          <Grid item xs={12}>
            <AddressField
              testId='physical-address'
              address={userData.physicalAddress}
              onChange={(e: any) => update({ ...userData, physicalAddress: e })}
              disabled={loading || options?.physicalAddress?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.physicalAddress?.label)}
              fullWidth
              lockMessage={lockMessage()}
              defaultAddressProvider={options?.physicalAddress?.defaultAddressProvider}
              manualAddressEntry={options?.physicalAddress?.manualAddressEntry}
              infoTooltip={additionalInfo(options?.physicalAddress?.additionalInfo)}
              onFocus={() => setFocused([...focused, 'physicalAddress'])}
              error={error('physicalAddress')}
            />
          </Grid>
        )}
        {physicalAddressOutOfJurisdiction !== false && (
          <Grid item xs={12}>
            <Alert
              severity={options?.physicalAddress?.required === 'ERROR' ? 'error' : 'info'}
              sx={{ my: 2 }}>
              {physicalAddressOutOfJurisdiction}
            </Alert>
          </Grid>
        )}
        {options?.inProvinceSince?.enabled && userData.physicalAddress?.province === 'QC' && (
          <Grid item xs={12} md={grid ? 6 : 12}>
            <DateField
              testId='in-province-since'
              onChange={(date: any) => update({ ...userData, inProvinceSince: dayjs(date?.toString()).format('YYYY-MM-DD') })}
              disabled={loading || options?.inProvinceSince?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.inProvinceSince?.label)}
              lockMessage={lockMessage()}
              fullWidth
              value={userData.inProvinceSince}
              onBlur={() => setFocused([...focused, 'inProvinceSince'])}
              infoTooltip={additionalInfo(options?.inProvinceSince?.additionalInfo)}
              error={error('inProvinceSince')}
            />
          </Grid>
        )}
        {options?.dateOfBirth?.enabled && (
          <Grid item xs={12} md={grid ? 6 : 12}>
            <DateField
              dataTestId='date-of-birth'
              onChange={(date: any) => update({ ...userData, dateOfBirth: dayjs(date?.toString()).format('YYYY-MM-DD') })}
              disabled={loading || options?.dateOfBirth?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.dateOfBirth?.label)}
              lockMessage={lockMessage()}
              fullWidth
              value={userData.dateOfBirth}
              onBlur={() => setFocused([...focused, 'dateOfBirth'])}
              infoTooltip={additionalInfo(options?.dateOfBirth?.additionalInfo)}
              error={error('dateOfBirth') || isUnderAgeOfMajority}
            />
            {isUnderAgeOfMajority && (
              <Alert
                severity={options?.dateOfBirth?.required === 'ERROR' ? 'error' : 'info'}
                sx={{ my: 2 }}>
                {underAgeOfMajorityMessage.replace('{{MINUMUM_AGE}}', ageOfMajority.toString())}
              </Alert>
            )}

          </Grid>
        )}
        {options?.primaryEmail?.enabled && (
          <Grid item xs={12} md={grid ? 6 : 12}>
            <TextField
              testId='primary-email'
              onChange={(e: any) => update({ ...userData, primaryEmail: e.target.value })}
              disabled={loading || options?.primaryEmail?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.primaryEmail?.label)}
              fullWidth
              value={userData.primaryEmail ?? ''}
              onBlur={() => setFocused([...focused, 'primaryEmail'])}
              error={error('primaryEmail')}
              errorText={invalidEmail ? t('components:fieldErrors.email') : undefined}
              locked={options?.primaryEmail?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              infoTooltip={additionalInfo(options?.primaryEmail?.additionalInfo)}
            />
          </Grid>
        )}
        {options?.phone?.enabled && (
          <Grid item xs={12} md={grid ? 6 : 12}>
            <TextField
              testId='phone'
              type='phone'
              onChange={(e: any) => update({ ...userData, phone: e.target.value })}
              disabled={loading || options?.phone?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.phone?.label)}
              fullWidth
              value={userData.phone ?? ''}
              onBlur={() => setFocused([...focused, 'phone'])}
              error={error('phone')}
              errorText={invalidPhone ? t('components:fieldErrors.phone') : undefined}
              locked={options?.phone?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              infoTooltip={additionalInfo(options?.phone?.additionalInfo)}
            />
          </Grid>
        )}
        {options?.preferredMethodOfCommunication?.enabled && (
          <Grid item xs={12}>
            <RadioGroup
              testId='preferred-method-of-communication'
              onChange={(e: any) => {
                update({ ...userData, preferredMethodOfCommunication: e.target.value });
                setFocused([...focused, 'preferredMethodOfCommunication']);
              }}
              disabled={loading || options?.preferredMethodOfCommunication?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.preferredMethodOfCommunication?.label)}
              infoTooltip={additionalInfo(options?.preferredMethodOfCommunication?.additionalInfo)}
              fullWidth
              value={userData.preferredMethodOfCommunication}
              onFocus={() => setFocused([...focused, 'preferredMethodOfCommunication'])}
              error={error('preferredMethodOfCommunication')}
            >
              {['EMAIL', 'PHONE', 'TEXT'].map((comm) => (
                <Radio
                  testId={`preferred-method-of-communication-${comm.toLowerCase()}`}
                  value={comm}
                  disabled={loading || options?.preferredMethodOfCommunication?.required === 'NOT_EDITABLE'}
                  label={t(`edit.preferredMethodOfCommunicationOptions.${comm}`)}
                />
              ))}
            </RadioGroup>
          </Grid>
        )}
        {options?.gender?.enabled && (
          <Grid item xs={12} md={grid ? 6 : 12}>
            <RadioGroup
              testId='gender-select'
              onChange={(e: any) => {
                update({ ...userData, gender: e.target.value });
                setFocused([...focused, 'gender']);
              }}
              disabled={loading || options?.gender?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.gender?.label)}
              infoTooltip={additionalInfo(options?.gender?.additionalInfo)}
              fullWidth
              value={userData.gender}
              error={error('gender')}
            >
              {['MALE', 'FEMALE'].map((gender) => (
                <Radio
                  testId={`gender-select-${gender.toLowerCase()}`}
                  value={gender}
                  disabled={loading || options?.gender?.required === 'NOT_EDITABLE'}
                  label={t(`edit.genderOptions.${gender}`)}
                />
              ))}
            </RadioGroup>
          </Grid>
        )}
        {options?.language?.enabled && (
          <Grid item xs={12} md={grid ? 6 : 12}>
            <RadioGroup
              testId='language-select'
              onChange={(e: any) => {
                update({ ...userData, language: e.target.value });
                setFocused([...focused, 'language']);
              }}
              disabled={loading || options?.language?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.language?.label)}
              infoTooltip={additionalInfo(options?.language?.additionalInfo)}
              fullWidth
              value={userData.language}
              onFocus={() => setFocused([...focused, 'language'])}
              error={error('language')}
            >
              {['ENGLISH', 'FRENCH'].map((language) => (
                <Radio
                  testId={`language-select-${language.toLowerCase()}`}
                  value={language}
                  disabled={loading || options?.language?.required === 'NOT_EDITABLE'}
                  label={t(`edit.languageOptions.${language}`)}
                />
              ))}
            </RadioGroup>
          </Grid>
        )}
        <Grid item xs={12}>
          <CustomFieldRenderer
            customOptions={customOptionSelected}
            customData={userData}
            update={update}
            grid={grid}
            focused={focused}
            setFocused={setFocused}
            loading={loading}
            ignoreCustomFieldTriggerRules={ignoreCustomFieldTriggerRules}
          />
        </Grid>
      </Grid>

      {options?.termsAndConditionsText?.en?.trim() && (
        <Typography data-testid='terms-and-condition' variant='bodyLarge' component='div' sx={{
          mb: 3, mt: 3, table: { width: '100%' }, display: 'flex', alignItems: 'center',
        }}>
          <ReactMarkdown remarkPlugins={[remarkGfm]}>{options?.termsAndConditionsText?.en}</ReactMarkdown>
          {additionalInfo(options?.termsAndConditionsText?.additionalInfo) && (
            <InfoDialog information={additionalInfo(options?.termsAndConditionsText?.additionalInfo) ?? ''} />
          )}
        </Typography>
      )}

      <Box display='flex' justifyContent='end'>
        {(!updateMode || options.displayUpdateButton) && (
          <Button
            dataTestId={`personal-information-${kebabCase(translateBackend(options.title))}-continue-button`}
            label={t(updateMode ? 'update' : 'continue')}
            disabled={loading || (!updated && updateMode) || physicalAddressInvalid || isUnderAgeOfMajority}
            sx={{ mt: 3, textAlign: 'center' }}
            type='submit'
          />
        )}
      </Box>
    </Form>
  );
};

export default PersonalInformationVisual;

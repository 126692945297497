// setting up browserTabTitle and faviconLink based on refresh, and orgSwitching
export const setOrgPublicSettings = (orgPublicSettings: any, setFaviconLink: any, setBrowserTabTitle: any) => {
  if (orgPublicSettings?.faviconLink) {
    window.localStorage.setItem('faviconLink', orgPublicSettings?.faviconLink);
    setFaviconLink(orgPublicSettings?.faviconLink);
  } else {
    window.localStorage.setItem('faviconLink', `${process.env.PUBLIC_URL}/favicon.ico`);
    setFaviconLink(`${process.env.PUBLIC_URL}/favicon.ico`);
  }
  if (orgPublicSettings?.browserTabTitle) {
    window.localStorage.setItem('browserTabTitle', orgPublicSettings?.browserTabTitle);
    setBrowserTabTitle(orgPublicSettings?.browserTabTitle);
  } else {
    window.localStorage.setItem('browserTabTitle', 'OneHub');
    setBrowserTabTitle('OneHub');
  }
};

export const modifyTabTitle = (browserTabTitle?: string) => {
  if (browserTabTitle) {
    const tabTitle = document.getElementById('org-title');
    if (tabTitle) {
      tabTitle.textContent = browserTabTitle;
    }
  } else {
    const tabTitle = document.getElementById('org-title');
    if (tabTitle) {
      tabTitle.textContent = 'OneHub';
    }
  }
};

export const modifyFavIcon = (faviconLink?: string) => {
  if (faviconLink) {
    let link: any = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = faviconLink;
    // controlling IOS icons
    let appleLink: any = document.querySelector("link[rel~='apple-touch-icon']");
    if (!appleLink) {
      appleLink = document.createElement('link');
      appleLink.rel = 'apple-touch-icon';
      document.getElementsByTagName('head')[0].appendChild(appleLink);
    }
    appleLink.href = faviconLink;
  } else {
    let link: any = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = `${process.env.PUBLIC_URL}/favicon.ico`;

    // controlling IOS icons
    let appleLink: any = document.querySelector("link[rel~='apple-touch-icon']");
    if (!appleLink) {
      appleLink = document.createElement('link');
      appleLink.rel = 'apple-touch-icon';
      document.getElementsByTagName('head')[0].appendChild(appleLink);
    }
    appleLink.href = `${process.env.PUBLIC_URL}/logo.png`;
  }
};

import { gql, useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { remove } from 'lodash/fp';
import { Box } from '../../../../1-primative';
import { TextField } from '../../../../2-component';
import { CreateNewModal, OrganizationSelect, PermissionsSelect } from '../../../../3-pattern';
import { UserContext } from '../../../../../providers/userContextProvider';

const CREATE_ROLE = gql`
  mutation createApiToken($input: CreateApiTokenInput!) {
    createApiToken(input: $input) {
      apiToken {
        id
      }
    }
  }
`;

const NewApiToken = ({ afterCreate, defaultOrg }: { afterCreate: () => void, defaultOrg: string }) => {
  const { t } = useTranslation(['devSettings']);
  const { userContext } = useContext(UserContext);
  const [apiToken, setApiToken] = useState({
    name: '',
    organizationId: defaultOrg,
    permissions: [],
  });
  const [initialState] = useState({
    name: '',
    organizationId: defaultOrg,
    permissions: [],
  });
  const [createApiToken, { loading }] = useMutation(CREATE_ROLE, {
    variables: {
      input: apiToken,
    },
  });

  const create = async (event: any) => {
    await createApiToken();
    afterCreate();
  };

  return (
    <CreateNewModal
      initialState={initialState}
      state={apiToken}
      loading={loading} title={t('apiTokenModal.title')} onSubmit={create}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          fullWidth
          label={t('apiTokenModal.name')}
          value={apiToken.name}
          onChange={(event: any) => setApiToken({ ...apiToken, name: event.target.value })} />
        <OrganizationSelect
          value={apiToken.organizationId}
          label={t('apiTokenModal.organization')}
          onChange={(event: any) => setApiToken({ ...apiToken, organizationId: event.target.value as string })}
        />
        <PermissionsSelect
          permissions={apiToken.permissions}
          scopedPermissions={userContext.role?.permissions}
          onChange={(permission: string, checked: boolean) => {
            let permissions: any = [...apiToken.permissions];
            if (checked) {
              permissions.push(permission);
            } else {
              permissions = remove((x) => x === permission, permissions);
            }
            setApiToken({ ...apiToken, permissions });
          }}
        />
      </Box>
    </CreateNewModal>
  );
};

export default NewApiToken;

import DOMPurify from 'dompurify';

export const downloadHtmlAsPdf = (html: string, filename: string) => {
  let safeHTML: any = `<div style="width:1060px;font-family:Arial,sans-serif;">${html}</div>`;
  if (window.trustedTypes && window.trustedTypes.createPolicy) {
    const escapedHTML = window.trustedTypes.createPolicy('htmlPdf', {
      createHTML: (string) => DOMPurify.sanitize(string, { RETURN_TRUSTED_TYPE: true }).toString(),
    });
    safeHTML = escapedHTML.createHTML(safeHTML);
  }

  const winPrint = window.open('blank', '', 'left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0');
  winPrint?.document.write(safeHTML);
  winPrint?.document.close();
  winPrint?.focus();
  winPrint?.print();
  winPrint?.close();
};

import InfoIcon from '@mui/icons-material/Info';
import {
  Dispatch, SetStateAction, useState,
} from 'react';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '../../../../1-primative';
import {
  TextField, Switch, Tooltip,
  SegmentedControl,
} from '../../../../2-component';
import { NumberField } from '../../../../3-pattern';
import { FeeGrid, FeeTier, FeeTierStates } from '../../../../../interfaces';
import BuildGrid from './buildGrid';

export const FETCH_FEE_TIERS = gql`
  query fetchFeeTiers($input: FetchFeeTiersInput!) {
    fetchFeeTiers(input: $input) {
      feeTiers {
        id
        default
        organization { id }
      }
    }
  }
`;

const FeeGridForm = ({
  feeTier,
  setFeeTier,
  type,
  setType,
  disabled = false,
  organizationId,
}: {
  feeTier: FeeTier,
  setFeeTier: Dispatch<SetStateAction<FeeTier>>,
  type: 'FLAT' | 'TIERED',
  setType: (newType: 'FLAT' | 'TIERED') => void,
  disabled?: boolean,
  organizationId?: string,
}) => {
  const { t } = useTranslation(['feeAndBilling']);
  const setGrid = (grid: FeeGrid[]) => setFeeTier((prev) => ({ ...prev, grid }));
  const defaultedFeeTier = (feeGrid: FeeTier) => feeGrid.default && (feeGrid.default === true);
  const [defaultExists, setDefaultExists] = useState<boolean>(false);

  useQuery(FETCH_FEE_TIERS, {
    variables: {
      input: {
        filter: {
          organizationId,
          default: true,
          states: [FeeTierStates.ACTIVE],
        },
      },
    },
    onCompleted: (res: any) => {
      const feeTiers = res?.fetchFeeTiers?.feeTiers;
      if (feeTiers && organizationId) {
        const nonInheritedFeetiers = feeTiers.filter((item: any) => item.organization?.id === organizationId);
        setDefaultExists((nonInheritedFeetiers.length > 0));
      }
    },
  });

  return (
    <Box display='flex' flexDirection='column' gap={2}>
      <TextField
        label={t('feeAndBilling:feeGrid.edit.name')}
        fullWidth
        disabled={disabled}
        value={feeTier.name ?? ''}
        onChange={(e: any) => setFeeTier((prev) => ({ ...prev, name: e.target.value }))}
      />
      <Box display='flex' alignItems='center' gap={0.5}>
        <Typography>{t('feeAndBilling:feeGrid.edit.type.label')}</Typography>
        <Tooltip title={
          <div style={{ whiteSpace: 'pre-line' }}>{t('feeAndBilling:feeGrid.edit.type.tooltip')}</div>
        }>
          <InfoIcon sx={{ fontSize: '16px', verticalAlign: 'middle' }} />
        </Tooltip>
      </Box>
      <SegmentedControl
        testId='feature-flag-type'
        value={type}
        onChange={(v) => setType(v as 'FLAT' | 'TIERED')}
        segments={[
          { label: t('feeAndBilling:feeGrid.edit.type.flat'), value: 'FLAT' },
          { label: t('feeAndBilling:feeGrid.edit.type.tiered'), value: 'TIERED' },
        ]}
      />
      {type === 'FLAT' && (
        <NumberField
          label={t('feeAndBilling:feeGrid.edit.flatAnnualFeeBpsLabel')}
          disabled={disabled}
          decimalPlaces={2}
          number={feeTier.flatAnnualFeeBps?.toString() ?? ''}
          setNumber={(value) => setFeeTier((prev) => ({ ...prev, flatAnnualFeeBps: parseFloat(value) }))}
        />
      )}
      {type === 'TIERED' && (
        <>
          <BuildGrid grid={feeTier.grid?.length ? feeTier.grid : [{ minMarketValueCents: 0 }]} setGrid={setGrid} disabled={disabled} />
          <Box display='flex' alignItems='center' gap={0.5}>
            <Typography>{t('feeAndBilling:feeGrid.edit.grid.type.label')}</Typography>
            <Tooltip title={
              <div style={{ whiteSpace: 'pre-line' }}>{t('feeAndBilling:feeGrid.edit.grid.type.tooltip')}</div>
            }>
              <InfoIcon sx={{ fontSize: '16px', verticalAlign: 'middle' }} />
            </Tooltip>
          </Box>
          <SegmentedControl
            testId='feature-flag-type'
            value={(feeTier.blendedGrid ?? true) ? 'BLENDED' : 'VOLUME'}
            onChange={(v) => setFeeTier((prev) => ({ ...prev, blendedGrid: v === 'BLENDED' }))}
            segments={[
              { label: t('feeAndBilling:feeGrid.edit.grid.type.blended'), value: 'BLENDED' },
              { label: t('feeAndBilling:feeGrid.edit.grid.type.volume'), value: 'VOLUME' },
            ]}
          />
        </>
      )}
      <Box display='flex' alignItems='center' gap={1}>
        <Switch
          disabled={disabled}
          checked={feeTier.chargeForCash ?? true}
          onChange={async (event) => setFeeTier((prev) => ({ ...prev, chargeForCash: event }))}
          label={t('feeAndBilling:feeGrid.edit.chargeForCash.label')}
        />
        <Tooltip title={t('feeAndBilling:feeGrid.edit.chargeForCash.tooltip')}><InfoIcon color='secondary' /></Tooltip>
      </Box>
      <Box display='flex' alignItems='center' gap={1}>
        <Switch
          disabled={disabled}
          checked={feeTier.chargeForCashOnHold ?? true}
          onChange={async (event) => setFeeTier((prev) => ({ ...prev, chargeForCashOnHold: event }))}
          label={t('feeAndBilling:feeGrid.edit.chargeForCashOnHold.label')}
        />
        <Tooltip title={t('feeAndBilling:feeGrid.edit.chargeForCashOnHold.tooltip')}><InfoIcon color='secondary' /></Tooltip>
      </Box>
      <Box display='flex' alignItems='center' gap={1}>
        <Switch
          disabled={disabled}
          checked={feeTier.billableAUM ?? false}
          onChange={async (event) => setFeeTier((prev) => ({ ...prev, billableAUM: event }))}
          label={t('feeAndBilling:feeGrid.edit.billable.label')}
        />
        <Tooltip title={t('feeAndBilling:feeGrid.edit.billable.tooltip')}><InfoIcon color='secondary' /></Tooltip>
      </Box>
      <Box display='flex' alignItems='center' gap={1}>
        <Tooltip
          title={
            (defaultExists && !defaultedFeeTier(feeTier)) ? t('feeAndBilling:feeGrid.edit.default.defaultFlagTooltip') : ''
          }
        >
          <Switch
            disabled={disabled || (feeTier.default ? false : defaultExists)}
            checked={feeTier.default}
            onChange={async (event) => {
              setFeeTier((prev) => ({ ...prev, default: event }));
            }}
            label={t('feeAndBilling:feeGrid.edit.default.label')}
          />
        </Tooltip>
        <Tooltip title={t('feeAndBilling:feeGrid.edit.default.tooltip')}><InfoIcon color='secondary' /></Tooltip>
      </Box>
    </Box>
  );
};

export default FeeGridForm;

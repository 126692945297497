import { CurrencyCodes } from '@onevesthq/ov-enums';
import { round } from 'lodash/fp';
import { formatMoneyValue, formatPercentValue } from '../../../../../util';
import { translateBackend } from '../../../../../assets/i18n/config';
import { TableCell } from '../../../../2-component';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { currencySymbol } from '../../../../../util/currency';

export const AssetClassHoldingCell = ({
  group, type, totalMarketValue, isFirst, dataTestId = 'asset-class-holding-cell',
}: {
  group: any, type: string, totalMarketValue: number, isFirst?: boolean, dataTestId?: string,
}) => {
  const { sys } = useThemeTokens();

  const unrealizedGainLoss = () => round(group.gainLossCents);
  const oneDayTotal = () => round(group.totalCents - group.oneDayCents);
  const threeDayTotal = () => round(group.totalCents - group.threeDayCents);
  const oneWeekTotal = () => round(group.totalCents - group.oneWeekCents);
  const oneMonthTotal = () => round(group.totalCents - group.oneMonthCents);

  switch (type) {
    case 'name':
      return (
        <TableCell testId={dataTestId} maxWidth='250px' isFirst={isFirst}>
          {translateBackend(group.translatedName)}
        </TableCell>
      );
    case 'unrealizedGainLoss':
      return (
        <TableCell testId={dataTestId} right isFirst={isFirst} number sx={{
          textAlign: 'right',
          color: unrealizedGainLoss() === 0 ? sys.color.onSurface : unrealizedGainLoss() >= 0 ? sys.color.positive : sys.color.negative,
          whiteSpace: 'nowrap',
        }}>
          {group.originalCents === 0 ? (
            '-'
          ) : (
            <>
              {`${formatMoneyValue(unrealizedGainLoss(), currencySymbol[group.currency as CurrencyCodes ?? CurrencyCodes.CAD], 2, false)} `}
              ({formatPercentValue(unrealizedGainLoss() / (group.originalCents > 0 ? group.originalCents : 1))})
            </>
          )}
        </TableCell>
      );
    case 'oneDayChange':
      return (
        <TableCell testId={dataTestId} right number isFirst={isFirst} sx={{
          textAlign: 'right',
          color: oneDayTotal() === 0 ? sys.color.onSurface : oneDayTotal() >= 0 ? sys.color.positive : sys.color.negative,
          whiteSpace: 'nowrap',
        }}>
          {`${formatMoneyValue(oneDayTotal(), currencySymbol[group.currency as CurrencyCodes ?? CurrencyCodes.CAD], 2, false)} `}
          ({formatPercentValue(oneDayTotal() / (group.oneDayCents > 0 ? group.oneDayCents : 1))})
        </TableCell>
      );
    case 'threeDayChange':
      return (
        <TableCell testId={dataTestId} right number isFirst={isFirst} sx={{
          textAlign: 'right',
          color: threeDayTotal() === 0 ? sys.color.onSurface : threeDayTotal() >= 0 ? sys.color.positive : sys.color.negative,
          whiteSpace: 'nowrap',
        }}>
          {`${formatMoneyValue(threeDayTotal(), currencySymbol[group.currency as CurrencyCodes ?? CurrencyCodes.CAD], 2, false)} `}
          ({formatPercentValue(threeDayTotal() / (group.threeDayCents > 0 ? group.threeDayCents : 1))})
        </TableCell>
      );
    case 'oneWeekChange':
      return (
        <TableCell testId={dataTestId} right number isFirst={isFirst} sx={{
          textAlign: 'right',
          color: oneWeekTotal() === 0 ? sys.color.onSurface : oneWeekTotal() >= 0 ? sys.color.positive : sys.color.negative,
          whiteSpace: 'nowrap',
        }}>
          {`${formatMoneyValue(oneWeekTotal(), currencySymbol[group.currency as CurrencyCodes ?? CurrencyCodes.CAD], 2, false)} `}
          ({formatPercentValue(oneWeekTotal() / (group.oneWeekCents > 0 ? group.oneWeekCents : 1))})
        </TableCell>
      );
    case 'oneMonthChange':
      return (
        <TableCell testId={dataTestId} right number isFirst={isFirst} sx={{
          textAlign: 'right',
          color: oneMonthTotal() === 0 ? sys.color.onSurface : oneMonthTotal() >= 0 ? sys.color.positive : sys.color.negative,
          whiteSpace: 'nowrap',
        }}>
          {`${formatMoneyValue(oneMonthTotal(), currencySymbol[group.currency as CurrencyCodes ?? CurrencyCodes.CAD], 2, false)} `}
          ({formatPercentValue(oneMonthTotal() / (group.oneMonthCents > 0 ? group.oneMonthCents : 1))})
        </TableCell>
      );
    case 'percentOfTotal':
      return (
        <TableCell testId={dataTestId} right number isFirst={isFirst}>{(group?.totalCents ?? 0) >= 0 && formatPercentValue(group.totalCents / totalMarketValue)}</TableCell>
      );
    case 'currentValue':
    case 'marketValueNative':
      return (
        <TableCell testId={dataTestId} right number isFirst={isFirst}>
          {formatMoneyValue(group.totalCentsNative, currencySymbol[group.nativeCurrency as CurrencyCodes ?? CurrencyCodes.CAD], 2, false)}
        </TableCell>
      );
    case 'marketValue':
      return (
        <TableCell testId={dataTestId} right number isFirst={isFirst}>
          {group.hasFxRate ? formatMoneyValue(group.totalCents, currencySymbol[group.currency as CurrencyCodes ?? CurrencyCodes.CAD], 2, false) : '-'}
        </TableCell>
      );
    default:
      return (
        <TableCell testId={dataTestId}>
          {type}
        </TableCell>
      );
  }
};

import { useTranslation } from 'react-i18next';
import { omit } from 'lodash/fp';
import CloseIcon from '@mui/icons-material/Close';
import { useContext, useEffect, useState } from 'react';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import {
  Dialog, DialogContent, DialogTitle, IconButton,
} from '../../2-component';
import { Box, Typography } from '../../1-primative';
import { UserContext } from '../../../providers/userContextProvider';
import { NotificationPreferencesSettings } from './notificationPreferencesSettings';
import { UserNotificationPreference } from '../../../interfaces';

export const NotificationPreferencesModal = ({
  open, setOpen,
}: {
  open: boolean, setOpen: (open: boolean) => void,
}) => {
  const { t } = useTranslation('components');
  const { sys } = useThemeTokens();
  const { userContext, refetch } = useContext(UserContext);
  const [notificationPreferences, setNotificationPreferences] = useState<UserNotificationPreference[]>([]);

  useEffect(() => setNotificationPreferences([
    ...(userContext.notificationPreferences ?? []).map((elem) => ({
      notificationGroup: {
        id: elem.notificationGroup.id,
        name: { en: elem.notificationGroup.name.en, fr: elem.notificationGroup.name.fr },
        description: elem.notificationGroup.description ? { en: elem.notificationGroup.description.en, fr: elem.notificationGroup.description.fr } : {},
        notificationChannels: (elem.notificationGroup?.notificationChannels ?? []).map((nc) => omit(['__typename'], nc)),
      },
      preferences: omit(['__typename'], elem.preferences),
    })),
  ] as UserNotificationPreference[]), [userContext.notificationPreferences]);

  const handleClose = () => {
    refetch();
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='sm'
      sx={{
        '.MuiDialog-paper': {
          minHeight: 400,
          borderRadius: sys.borderRadius.xxl,
        },
      }}
      fullWidth
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingY: 2.5,
          borderBottom: `1px ${sys.color.primaryContainer} solid`,
        }}
      >
        <Typography variant='headingLarge'>{t('notificationSettings.title')}</Typography>
        <IconButton size='small' onClick={() => setOpen(false)}>
          <CloseIcon sx={{ color: sys.color.onSurface }} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingX: 2.5 }}>
        <Box display='flex' flexDirection='column' gap={3} mb={1} mt={0.5}>
          {notificationPreferences.map((elem) => (
            <NotificationPreferencesSettings
              key={elem.notificationGroup.id}
              notificationPreference={elem}
              notificationPreferences={notificationPreferences}
              setNotificationPreferences={setNotificationPreferences}
            />
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

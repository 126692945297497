/* eslint-disable no-param-reassign */
/* eslint-disable no-await-in-loop */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  useContext, useEffect, useState,
} from 'react';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import Close from '@mui/icons-material/Close';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { Box, Skeleton, Stack } from '../../1-primative';
import {
  Button, Dialog, DialogContent, DialogFooter, DialogTitle, IconButton,
  Radio,
  RadioGroup,
  TextField,
} from '../../2-component';
import useTextDebounce from '../../../util/useTextDebounce';
import { NewOrganization } from './newOrganization';
import { modifyFavIcon, modifyTabTitle, setOrgPublicSettings } from '../../../util/browserTabUpdates';

const SEARCH_ORGANIZATIONS = gql`
  query searchOrganizations($input: FetchOrganizationsInput!) {
    fetchOrganizations(input: $input) {
      organizations {
        id
        name
        parents {
          id
          name
        }
      }
    }
  }
`;

const FETCH_INITIAL_ORGANIZATIONS = gql`
  query fetchInitialOrganizations($organizationId: String!) {
    fetchOrganization(organizationId: $organizationId) {
      organization {
        id
        name
        faviconLink
        browserTabTitle
        parents {
          id
          name
          parent {
            id
          }
          children {
            id
            name
            children {
              id
            }
          }
        }
      }
    }
  }
`;

const FETCH_CHILD_ORGANIZATIONS = gql`
  query fetchChildOrganizations($organizationId: String!) {
    fetchOrganization(organizationId: $organizationId) {
      organization {
        id
        children {
          id
          name
          children {
            id
          }
        }
      }
    }
  }

`;

export const OrganizationSwitcher = ({
  open, setOpen,
}: {
  open: boolean, setOpen: (e: boolean) => void,
}) => {
  const { activeOrganization, setActiveOrganizationId, userContext } = useContext(UserContext);
  const accessiblePages = userContext.role?.accessiblePages ?? [];
  const { permissions } = usePermissions();
  const [searchText, setSearchText] = useState('');
  const [localOrgId, setLocalOrgId] = useState(activeOrganization.id);
  const [tree, setTree] = useState<any>({});
  const { sys } = useThemeTokens();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation('orgSettings');
  const debouncedSearchText = useTextDebounce({ value: searchText, delay: 500 });
  const localStorageFavIcon = window.localStorage.getItem('faviconLink') || '';
  const localStorageBrowserTitle = window.localStorage.getItem('browserTabTitle') || '';
  const [faviconLink, setFaviconLink] = useState(localStorageFavIcon);
  const [browserTabTitle, setBrowserTabTitle] = useState(localStorageBrowserTitle);

  const { data, loading } = useQuery(SEARCH_ORGANIZATIONS, {
    variables: {
      input: {
        filter: {
          searchText: debouncedSearchText,
        },
        pagination: {
          perPage: 1000,
          sortField: 'name',
        },
      },
    },
    skip: !debouncedSearchText,
  });

  const { data: initialData } = useQuery(FETCH_INITIAL_ORGANIZATIONS, {
    variables: {
      organizationId: activeOrganization.id,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    modifyFavIcon(faviconLink);
    modifyTabTitle(browserTabTitle);
  }, [faviconLink, browserTabTitle]);

  const [fetchChildOrganizations] = useLazyQuery(FETCH_CHILD_ORGANIZATIONS);

  const scrollToActive = () => {
    const element = document.getElementById(activeOrganization.id || '');
    if (element) {
      document.getElementById('orgSelector')?.scrollTo(0, element.offsetTop + 60);
    }
  };

  useEffect(() => {
    if (initialData?.fetchOrganization?.organization) {
      setOrgPublicSettings(initialData?.fetchOrganization?.organization, setFaviconLink, setBrowserTabTitle);
      const { parents }: { parents: any[] } = initialData.fetchOrganization.organization;
      let tempParent: any = null;
      parents.forEach((parent: any) => {
        if (tempParent) {
          const newParent = {
            id: parent.id,
            name: parent.name,
            open: true,
            children: [...parent.children].sort((a: any, b: any) => (a.name < b.name ? -1 : 1)).map((child: any) => {
              if (child.id === tempParent.id) {
                return tempParent;
              }
              return {
                id: child.id,
                name: child.name,
                open: false,
                children: [...child.children].sort((a: any, b: any) => (a.name < b.name ? -1 : 1)).map((grandChild: any) => ({
                  id: grandChild.id,
                  name: grandChild.name,
                  open: false,
                })),
              };
            }),
          };
          tempParent = newParent;
        } else {
          tempParent = {
            id: parent.id,
            name: parent.name,
            open: true,
            children: [...parent.children].sort((a: any, b: any) => (a.name < b.name ? -1 : 1)).map((child: any) => ({
              id: child.id,
              name: child.name,
              children: [...child.children].sort((a: any, b: any) => (a.name < b.name ? -1 : 1)).map((grandChild: any) => ({
                id: grandChild.id,
                name: grandChild.name,
              })),
              open: false,
            })),
          };
        }
      });
      setTree(tempParent);
    }
    setTimeout(scrollToActive, 200);
  }, [initialData]);

  useEffect(() => {
    setTimeout(scrollToActive, 200);
  }, [open]);

  const updateTreeOpen = async (id: string) => {
    const updateBranch = async (branch: any) => {
      if (branch.id === id) {
        branch.open = !branch.open;
        if (branch.open) {
          const response = await fetchChildOrganizations({
            variables: {
              organizationId: branch.id,
            },
          });
          const children = [...response.data.fetchOrganization.organization.children].sort((a: any, b: any) => (a.name < b.name ? -1 : 1)).map((child: any) => ({
            id: child.id,
            name: child.name,
            open: false,
            children: [...child.children].sort((a: any, b: any) => (a.name < b.name ? -1 : 1)).map((grandChild: any) => ({
              id: grandChild.id,
              open: false,
            })),
          }));
          branch.children = children;
        }
      } else {
        for (const child of (branch.children || [])) {
          await updateBranch(child);
        }
      }
    };

    const updatedTree = { ...tree };
    await updateBranch(updatedTree);
    setTree(updatedTree);
  };

  const showTreeBranch = (branch: any) => (
    <Box pl={2} key={branch?.id}>
      { branch?.name ? (
        <Box id={branch.id} display='flex' justifyContent='space-between' alignItems='center' pl={2} pr={2} sx={{
          '&:hover': {
            background: sys.color.background,
          },
          borderRadius: sys.borderRadius.md,
        }}>
          <Radio value={branch.id} label={branch.name} onChange={() => setLocalOrgId(branch.id)}/>
          {
            branch.children?.length > 0 && (
              <IconButton sx={{ color: sys.color.onSurface }} onClick={() => {
                updateTreeOpen(branch.id);
              }}>
                {branch.open ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            )
          }
        </Box>
      ) : (
        <Box pt={1} pr={3} pl={2}>
          <Skeleton width='100%' height='34px' variant='rectangular' />
        </Box>
      )}
      {
        branch?.open && branch?.children.map((child: any) => showTreeBranch(child))
      }
    </Box>
  );

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          {activeOrganization.name}
          <IconButton onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ paddingTop: '16px !important', pb: 2 }} id='orgSelector'>
        <TextField leadingIcon='search' value={searchText} onChange={(e: any) => setSearchText(e.target.value)} fullWidth />
        { searchText.length === 0 ? (
          <>
            <RadioGroup value={localOrgId} fullWidth>
              <Stack sx={{ pr: 2, pt: 2, width: '100%' }}>
                { showTreeBranch(tree) }
              </Stack>
            </RadioGroup>
          </>
        ) : (
          <>
            {
              loading ? (
                <Box sx={{ p: 2 }}>
                  <Skeleton width='100%' height='54px' sx={{ mb: 1, borderRadius: sys.borderRadius.md }} variant='rectangular' />
                  <Skeleton width='100%' height='54px' sx={{ mb: 1, borderRadius: sys.borderRadius.md }} variant='rectangular' />
                  <Skeleton width='100%' height='54px' sx={{ mb: 1, borderRadius: sys.borderRadius.md }} variant='rectangular' />
                </Box>
              ) : (
                <RadioGroup value={localOrgId} fullWidth>
                  <Stack pt={2} width='100%'>
                    {
                      data?.fetchOrganizations?.organizations.map((org: any) => (
                        <Box pl={2} pr={2} key={org.id} sx={{
                          '&:hover': {
                            background: sys.color.background,
                          },
                          borderRadius: sys.borderRadius.md,
                        }}>
                          <Radio
                            key={org.id}
                            value={org.id}
                            label={org.name}
                            onChange={() => setLocalOrgId(org.id)} subLabel={[...org.parents].filter((x: any) => x.id !== org.id).reverse().map((x: any) => x.name)?.join(' > ')}
                          />
                        </Box>
                      ))
                    }
                  </Stack>
                </RadioGroup>
              )
            }
          </>
        )}
      </DialogContent>
      <DialogFooter>
        <Box display='flex' justifyContent='space-between' width='100%'>
          { (location.pathname.includes('/settings') && permissions.includes('write:organizations')) ? (
            <NewOrganization afterCreate={(id: string) => {
              setActiveOrganizationId(id);
              setOpen(false);
              navigate('/settings/general');
            }}/>
          ) : <Box />}
          <Button onClick={() => {
            setActiveOrganizationId(localOrgId);
            setOpen(false);
            if (!location.pathname.includes('settings/')) {
              const firstAccessiblePath = accessiblePages[0].toLocaleLowerCase();
              navigate(`/${firstAccessiblePath}`);
            }
          }} label={t('changeOrganization')} />
        </Box>
      </DialogFooter>
    </Dialog>
  );
};

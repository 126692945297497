import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import AutoRenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { colors } from 'theme/colors';
import { AccountTypes as AccountTypesLocal, CountryCodes, CurrencyCodes } from '@onevesthq/ov-enums';
import i18n from '../assets/i18n/config';
import { Affiliation } from './affiliation';
import { BillingSchedule } from './billingSchedule';
import { FeeTier } from './feeTier';
import { Goal } from './goal';
import { Statistics } from './stats';
import { SubAccount } from './subAccount';
import { User, EntityTypes } from './user';
import { ClientGroup } from './clientGroup';
import { CustomFieldValue } from './customField';
import { TranslatedString } from './shared';
import { StateMachineChanges } from '../util/reusableGraphQLFields';
import { RepCode } from './repCode';

export const AccountTypes = { ...AccountTypesLocal };
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AccountTypes = AccountTypesLocal;

export const CORPORATE_ACCOUNTS_PRE_FIX = 'CORPORATE_';

const allTypes = Object.values(AccountTypes);
const accountTypesCA = Object.values(AccountTypes).filter((t) => !t.startsWith('USA_') && !t.startsWith('UK_'));
const accountTypesUSA = Object.values(AccountTypes).filter((t) => t.startsWith('USA_'));
const accountTypesUK = Object.values(AccountTypes).filter((t) => t.startsWith('UK_'));

export const getAccountTypes = (
  entityType: EntityTypes | string,
  entityFeatureFlags?: string[],
  countries?: CountryCodes[],
): AccountTypes[] => {
  let activeTypes: AccountTypes[];

  // Only allow country-specific types when country(ies) is specified in localization
  if (countries && countries.length > 0) {
    activeTypes = [
      ...(countries.includes(CountryCodes.CA) ? accountTypesCA : []),
      ...(countries.includes(CountryCodes.US) ? accountTypesUSA : []),
      ...(countries.includes(CountryCodes.GB) ? accountTypesUK : []),
    ];
  } else {
    activeTypes = allTypes;
  }

  let candidateAccountTypes: AccountTypes[];
  switch (entityType) {
    case EntityTypes.INDIVIDUAL:
      candidateAccountTypes = activeTypes.filter((accountType) => !accountType.startsWith(CORPORATE_ACCOUNTS_PRE_FIX)
        && ![AccountTypes.RESP].includes(accountType));
      break;
    case EntityTypes.TRUST:
    case EntityTypes.INVESTMENT_FUND:
    case EntityTypes.PARTNERSHIP:
    case EntityTypes.PRIVATE_COMPANY:
    case EntityTypes.PUBLICLY_LISTED_ENTITY:
    case EntityTypes.REGULATED_ENTITY:
    case EntityTypes.SOLE_PROPRIETORSHIP:
    case EntityTypes.OTHER:
      candidateAccountTypes = [
        AccountTypes.CORPORATE_CASH,
      ];
      break;
    default:
      candidateAccountTypes = activeTypes.filter((accountType) => ![AccountTypes.RESP].includes(accountType));
      break;
  }

  // Compare against required feature flags only if entity's feature flags are provided
  if (entityFeatureFlags !== undefined) {
    candidateAccountTypes = candidateAccountTypes.filter((accountType) => { // contains all the account types
      const requiredFeatureFlag = accountTypeToFeatureFlag[accountType];
      return !requiredFeatureFlag || entityFeatureFlags.includes(requiredFeatureFlag);
    });
  }

  return candidateAccountTypes.sort();
};

export const featureFlagtoAccountType = {
  PERSONAL: [AccountTypes.PERSONAL],
  CASH_JOINT: [AccountTypes.CASH_JOINT],
  CORPORATE_ACCOUNTS: [
    AccountTypes.CORPORATE_CASH,
    AccountTypes.CORPORATE_CHARITY,
    AccountTypes.CORPORATE_ESTATE,
    AccountTypes.CORPORATE_TRUST,
  ],
  GRSP: [AccountTypes.GRSP],
  LIF: [AccountTypes.LIF],
  LIRA: [AccountTypes.LIRA],
  LRIF: [AccountTypes.LRIF],
  LRSP: [AccountTypes.LRSP],
  PRIF: [AccountTypes.PRIF],
  RDSP: [AccountTypes.RDSP],
  RESP_SINGLE: [AccountTypes.RESP_SINGLE],
  RESP_FAMILY: [AccountTypes.RESP_FAMILY],
  RESP_ADULT: [AccountTypes.RESP_ADULT],
  RESP_FAMILY_JOINT: [AccountTypes.RESP_FAMILY_JOINT],
  RESP_SINGLE_JOINT: [AccountTypes.RESP_SINGLE_JOINT],
  RIF_SPOUSAL: [AccountTypes.RIF_SPOUSAL],
  RLIF: [AccountTypes.RLIF],
  RLSP: [AccountTypes.RLSP],
  RRIF: [AccountTypes.RRIF],
  RRSP_SPOUSAL: [AccountTypes.RRSP_SPOUSAL],
  RRSP: [AccountTypes.RRSP],
  TFSA: [AccountTypes.TFSA],
  FHSA: [AccountTypes.FHSA],

  USA_BROKERAGE: [AccountTypes.USA_BROKERAGE],
  USA_IRA: [AccountTypes.USA_IRA],
  USA_RO_IRA: [AccountTypes.USA_RO_IRA],
  USA_INH_RT_IRA: [AccountTypes.USA_INH_RT_IRA],
  USA_RT_IRA: [AccountTypes.USA_RT_IRA],
  USA_INH_IRA: [AccountTypes.USA_INH_IRA],
  USA_JT_WROS: [AccountTypes.USA_JT_WROS],
  USA_JT_TIC: [AccountTypes.USA_JT_TIC],
  USA_JT_CP: [AccountTypes.USA_JT_CP],
  USA_JT_JTBE: [AccountTypes.USA_JT_JTBE],
  USA_SEP_IRA: [AccountTypes.USA_SEP_IRA],
  USA_401K: [AccountTypes.USA_401K],
  USA_SOLO_401K: [AccountTypes.USA_SOLO_401K],
  USA_TRUST: [AccountTypes.USA_TRUST],
  USA_CORPORATE: [AccountTypes.USA_CORPORATE],
  USA_ESA: [AccountTypes.USA_ESA],
  USA_529: [AccountTypes.USA_529],
  USA_TOD: [AccountTypes.USA_TOD],
  USA_DAF: [AccountTypes.USA_DAF],
  USA_SIMPLE_IRA: [AccountTypes.USA_SIMPLE_IRA],
  USA_KEOGH: [AccountTypes.USA_KEOGH],
  USA_CUSTODIAL: [AccountTypes.USA_CUSTODIAL],
  USA_IRA_CUSTODIAL: [AccountTypes.USA_IRA_CUSTODIAL],
  USA_ESTATE: [AccountTypes.USA_ESTATE],
  USA_DBP: [AccountTypes.USA_DBP],
  USA_CRA: [AccountTypes.USA_CRA],

  UK_BROKERAGE: [AccountTypes.UK_BROKERAGE],
  UK_CASH_ISA: [AccountTypes.UK_CASH_ISA],
  UK_SS_ISA: [AccountTypes.UK_SS_ISA],
  UK_JISA: [AccountTypes.UK_JISA],
  UK_PERSONAL_PENSION: [AccountTypes.UK_PERSONAL_PENSION],

};

// Creates a reverse mapping of {AccountType: FeatureFlag} from featureFlagtoAccountType for quicker lookups.
export const accountTypeToFeatureFlag = Object.fromEntries(
  Object.entries(featureFlagtoAccountType).reduce(
    (accumulator: Array<[AccountTypes, string]>, [flag, accountTypes]: [string, AccountTypes[]]) => {
      const accountTypeFeatureFlagPairs = accountTypes.map((type) => [type, flag] as [AccountTypes, string]);
      return accumulator.concat(accountTypeFeatureFlagPairs);
    },
    [],
  ),
);

export enum AccountStates {
  ACTIVE = 'ACTIVE',
  REQUESTED = 'REQUESTED',
  FROZEN = 'FROZEN',
  FAILED = 'FAILED',
  INITIATED = 'INITIATED',
  READY = 'READY',
  INACTIVE = 'INACTIVE',
  CANCELED = 'CANCELED',
}

export enum ApplyForGovFunds {
  BASIC_CESG = 'BASIC_CESG',
  ADDITIONAL_CESG = 'ADDITIONAL_CESG',
  CLB = 'CLB',
}

export enum Jurisdiction {
  AB = 'AB',
  BC = 'BC',
  FEDERAL = 'FEDERAL',
  MB = 'MB',
  NB = 'NB',
  NL = 'NL',
  NS = 'NS',
  ON = 'ON',
  PE = 'PE',
  QC = 'QC',
  SK = 'SK',
}

export interface Account {
  id: string;
  user: User;
  custodianAccountNumber?: string;
  custodianAccountName?: string;
  /** @deprecated use translatedNickName instead */
  nickName?: string;
  translatedNickName?: TranslatedString;
  custodianSortingOrderPriority?: number;
  availableCurrencies?: CurrencyCodes[];
  baseCurrency?: CurrencyCodes;
  type: AccountTypes;
  state: AccountStates;
  subAccounts?: SubAccount[];
  lastUpdatedBy?: string;
  stats?: Statistics;
  statistics?: Statistics;
  custodianStatistics?: Statistics;
  /** @deprecated use stateMachineChanges */
  stateChanges?: any[];
  stateMachineChanges?: StateMachineChanges[];
  tlhEnabled?: boolean;
  affiliations?: Affiliation[];
  incompleteFormAgreements?: [];
  feePaymentAccount?: Account;
  applicableBillingSchedule?: BillingSchedule;
  applicableFeeTier?: FeeTier;
  applyForGovFunds: ApplyForGovFunds[];
  householdClientGroup?: ClientGroup;
  corporationClientGroup?: ClientGroup;
  feeTier?: FeeTier;
  billingSchedule?: BillingSchedule;
  jurisdiction?: Jurisdiction;
  taxRank?: number;
  defaultTaxRank?: number;
  overrideTaxRank?: number | null;
  customFields?: CustomFieldValue[];
  custodianCustomFields?: CustomFieldValue[];
  forceOpen?: boolean;
  repCode?: RepCode,
}

export interface DraftAccount extends Account {
  goalsLinked?: Goal[],
}

export const getAccountNumber = (account: Partial<Account>): string => account.custodianAccountNumber || `…${account.id?.slice(-8)}`;

export const getAccountName = (account?: Partial<Account>, includeAccountNumber = true): string => {
  if (!account) return '';
  return `${i18n.t(`accountTypes:${account?.type}`)}${includeAccountNumber ? ` #${getAccountNumber(account)}` : ''}`;
};

export const isRESPAccount = (account: AccountTypes) => {
  const respAccounts = ['RESP_SINGLE', 'RESP_FAMILY', 'RESP_ADULT', 'RESP_SINGLE_JOINT', 'RESP_FAMILY_JOINT'];
  return respAccounts.includes(account);
};

export const getIconForState = (state: AccountStates, fontSize = '16px') => {
  if (state === AccountStates.ACTIVE) {
    return (<CheckCircleRoundedIcon style={{ color: colors.success, fontSize }} />);
  }
  if (state === AccountStates.FROZEN || state === AccountStates.FAILED) {
    return (<WarningRoundedIcon style={{ color: colors.error, fontSize }} />);
  }
  if (state === AccountStates.INITIATED || state === AccountStates.REQUESTED || state === AccountStates.READY) {
    return (<AutoRenewRoundedIcon style={{ fontSize }} />);
  }
  return (<CancelRoundedIcon style={{ fontSize }} />);
};

import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NewIndividual from './components/newNonIndividual';
import { FilterNonIndividuals } from './components/filterNonIndividuals';
import { Box, Skeleton } from '../../1-primative';
import {
  Table, TableRow, TableCell, TableBody, Pagination, TextField, CardContent, TableHeadCell, Card,
} from '../../2-component';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import {
  Account, AccountStates, EntityTypes, User,
} from '../../../interfaces';
import { formatMoneyValue } from '../../../util';
import { usePageState } from '../../../util/usePageState';
import { useLocalization } from '../../../util/useLocalization';
import { translateBackend } from '../../../assets/i18n/config';

export const FETCH_USERS = (permissions: string[]) => gql`
  query searchNonIndividualUsers($input: UserSearchInput!, $currency: StatisticsCurrencyTypes) {
    searchUsers(input: $input) {
      users {
        id
        entityName
        type
        ${permissions.includes('read:client_low_risk_pii') ? 'primaryEmail firstName middleName lastName' : ''}
        ${permissions.includes('read:organizations') ? 'organization { name }' : ''}
        statistics(input: { currency: $currency }) { marketValueCents }
        accounts { state }
        completedAt
      }
      totalCount
    }
  }
`;

const ALL_TYPES_EXCEPT_INDIVIDUAL: EntityTypes[] = Object.values(EntityTypes).filter((t) => t !== EntityTypes.INDIVIDUAL);
const TYPES_INCLUDED = [AccountStates.ACTIVE, AccountStates.REQUESTED, AccountStates.INITIATED, AccountStates.READY, AccountStates.FROZEN, AccountStates.FAILED];

const numAccounts = (accounts: Account[]): number => accounts.filter((a) => TYPES_INCLUDED.includes(a.state)).length;

const DEFAULT_NON_INDIVIDUAL_TABLE = [
  { label: { en: 'Name', fr: 'Nom' }, type: 'name' },
  { label: { en: 'Entity Type', fr: "Type d'entité" }, type: 'entityType' },
  { label: { en: 'Primary Contact', fr: 'Contact principal' }, type: 'primaryContact' },
  { label: { en: 'Completed At', fr: 'Terminé à' }, type: 'completedAt' },
  { label: { en: '# of accounts', fr: 'Nombre de comptes' }, type: 'numberOfAccounts' },
  { label: { en: 'Total Assets', fr: 'Total des actifs' }, type: 'totalAssets' },
  { label: { en: 'Organization', fr: 'Organisation' }, type: 'organization' },
];

type TableOption = typeof DEFAULT_NON_INDIVIDUAL_TABLE[number];

const NON_INDIVIDUAL_TABLE_SORT_MAP: Record<string, string> = {
  name: 'entityName',
  entityType: 'type',
  completedAt: 'completedAt',
  totalAssets: 'marketValueCents',
  organization: 'organizationName',
};

const PERMISSIONS_TO_CREATE_CLIENT = ['write:client_basic', 'write:client_low_risk_pii', 'write:client_high_risk_pii', 'write:client_suitability'];
const RIGHT_ALIGNED_COLS = ['totalAssets', 'organization'];
const NOT_SORTABLE_COLS = ['primaryContact', 'numberOfAccounts'];

export const NonIndividuals = ({ options = {} }: { options?: any }) => {
  const navigate = useNavigate();
  const { permissions } = usePermissions();
  const { activeOrganization, activeCurrency, userContext } = useContext(UserContext);
  const [filterByType, setFilterByType] = useState<string[]>(ALL_TYPES_EXCEPT_INDIVIDUAL);

  const [page, setPage] = usePageState(1, 'page');
  const [searchText, setSearchText] = usePageState('', 'q');
  const [sortField, setSortField] = usePageState('createdAt', 'sf');
  const [sortDesc, setSortDesc] = usePageState(true, 'sd');
  const [pageSize, setPageSize] = usePageState(15, 'ps');

  const tableOptions = options.table || userContext.role?.nonIndividualTableStaticPageWidgetConfiguration?.widget?.options?.table || DEFAULT_NON_INDIVIDUAL_TABLE;

  const {
    loading, data, previousData,
  } = useQuery(FETCH_USERS(permissions), {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filter: {
          query: searchText, organizationId: activeOrganization.id, types: filterByType,
        },
        pagination: {
          sortField, sortDesc, perPage: pageSize, page,
        },
      },
      currency: activeCurrency || 'CAD',
    },
  });

  const onSort = (field: string) => {
    if (sortField === field) setSortDesc(!sortDesc);
    setSortField(field);
  };

  const sortDirection = (field: string) => (sortField === field ? sortDesc ? 'desc' : 'asc' : undefined);

  return (
    <Card loading={loading}>
      <CardContent>
        <Box display='flex' justifyContent='space-between'>
          <TextField sx={{ width: '300px' }} value={searchText} leadingIcon='search' onChange={(event: any) => {
            setSearchText(event.target.value);
            setPage(1);
          }} />
          <Box display='flex'>
          <FilterNonIndividuals filterByType={filterByType} setFilterByType={setFilterByType} types={ALL_TYPES_EXCEPT_INDIVIDUAL}/>
          {permissions.some((permission) => PERMISSIONS_TO_CREATE_CLIENT.includes(permission)) && <NewIndividual />}
          </Box>
        </Box>
      </CardContent>
      <Box sx={{ overflowX: 'auto' }}>
        <Table sx={{ minWidth: 650 }} aria-label="table">
          <TableBody>
            <TableRow>
              {tableOptions.map((opt: TableOption) => {
                const right = RIGHT_ALIGNED_COLS.includes(opt.type);
                const isSortable = !NOT_SORTABLE_COLS.includes(opt.type);
                const sortValue = NON_INDIVIDUAL_TABLE_SORT_MAP[opt.type];

                if (opt.type === 'primaryContact' && !permissions.includes('read:client_low_risk_pii')) return null;
                return <TableHeadCell right={right} isSortable={isSortable} onClick={() => onSort(sortValue)} sortDirection={sortDirection(sortValue)}>{translateBackend(opt.label)}</TableHeadCell>;
              })}
            </TableRow>

            {loading && !previousData && [...Array(15)].map((_x: any, i: number) => (
              <TableRow key={i}>
                {tableOptions.map((opt: TableOption) => {
                  if (opt.type === 'primaryContact' && !permissions.includes('read:client_low_risk_pii')) return null;
                  return <TableCell><Skeleton width='100%' /></TableCell>;
                })}
              </TableRow>
            ))}

            {(data || previousData)?.searchUsers?.users?.map((user: User) => (
              <TableRow hover key={user.id} sx={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => navigate(`/nonIndividualClients/${user.id}`)}>
                {tableOptions.map((opt: TableOption) => <NonIndividualsCell user={user} type={opt.type} />)}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box >
      <Pagination
        count={Math.ceil(((data || previousData)?.searchUsers?.totalCount ?? 0) / pageSize)}
        page={page}
        perPage={pageSize}
        onChangePerPage={(newPageSize) => setPageSize(newPageSize)}
        onChange={(_e, newPage) => setPage(newPage)}
        sx={{ p: 1, textAlign: 'right', '.MuiPagination-ul': { justifyContent: 'end' } }}
      />
    </Card>
  );
};

export default NonIndividuals;

const NonIndividualsCell = ({ user, type }: { user: User, type: string }) => {
  const { t } = useTranslation(['clients']);
  const { permissions } = usePermissions();
  const { localizedDateTime } = useLocalization();

  switch (type) {
    case 'name':
      return <TableCell>{user.entityName}</TableCell>;
    case 'entityType':
      return <TableCell>{t(`entityTypes:${user.type}`)}</TableCell>;
    case 'primaryContact':
      return permissions.includes('read:client_low_risk_pii') ? <TableCell>{user.primaryEmail}</TableCell> : null;
    case 'completedAt':
      return <TableCell>{user.completedAt ? localizedDateTime(user.completedAt) : t('table.notCompleted')}</TableCell>;
    case 'numberOfAccounts':
      return <TableCell number>{numAccounts(user.accounts)}</TableCell>;
    case 'totalAssets':
      return <TableCell number>{formatMoneyValue(user.statistics?.marketValueCents)}</TableCell>;
    case 'organization':
      return <TableCell right>{user.organization?.name}</TableCell>;
    default:
      return null;
  }
};

import { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { ForeignTaxInformation } from 'interfaces/foreignTaxInformation';
import { CountryCodes } from '@onevesthq/ov-enums';
import omitDeep from 'omit-deep-lodash';
import { ResidencyInformationVisual } from './residencyInformation.visual';
import { User } from '../../../../../interfaces';
import { usePermissions } from '../../../../../providers/userContextProvider';

const FETCH_USER_FIELDS = (permissions: string[]) => `#graphql
  user {
    id
    type
    citizenships
    foreignTaxInformation {
      id
      foreignTaxCountry
      foreignTaxNumber
      ${permissions.includes('read:client_taxid_disclosed') ? 'foreignTaxNumberDisclosed' : ''}
    }
    countryOfTaxResidence
    yearEnd
    taxNumber
    taxIdType
    taxId
    ${permissions.includes('read:client_taxid_disclosed') ? 'taxIdDisclosed' : ''}
    fatcaStatus
    fatcaStatusOtherDescription
    organization {
      applicableLocalization {
        countries
      }
    }
  }
`;

const FETCH_USER = (permissions: string[]) => gql`
  query fetchUserResidencyInformation($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      ${FETCH_USER_FIELDS(permissions)}
    }
  }
`;

const UPDATE_USER = (permissions: string[]) => gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      ${FETCH_USER_FIELDS(permissions)}
    }
  }
`;

export const ResidencyInformation = ({
  options, userId, onNext, stepLoading, workflowCompletion, grid = false, updateMode = false,
}: {
  options: any, userId: string, onNext: () => void, stepLoading: boolean, workflowCompletion?: any, grid?: boolean, updateMode?: boolean,
}) => {
  const { permissions } = usePermissions();
  const [userData, setUserData] = useState<Partial<User>>({
    citizenships: [],
    sin: '',
    yearEnd: '',
    taxNumber: '',
    countryOfTaxResidence: undefined,
    foreignTaxInformation: [],
  });
  const [applicableCountries, setApplicableCountries] = useState<CountryCodes[] | undefined>();

  const processUserResponse = (user: Partial<User>) => {
    setUserData({
      sin: undefined,
      type: user.type,
      taxId: user.taxId || undefined,
      taxIdDisclosed: user.taxIdDisclosed || undefined,
      taxIdType: user.taxIdType || undefined,
      yearEnd: user.yearEnd || undefined,
      taxNumber: user.taxNumber || undefined,
      countryOfTaxResidence: user.countryOfTaxResidence || undefined,
      citizenships: user.citizenships || [],
      foreignTaxInformation: omitDeep(user.foreignTaxInformation ?? [], '__typename') as ForeignTaxInformation[],
      fatcaStatus: user.fatcaStatus || undefined,
      fatcaStatusOtherDescription: user.fatcaStatusOtherDescription || undefined,
    });
    setApplicableCountries(user?.organization?.applicableLocalization?.countries ?? [CountryCodes.CA]);
  };

  const { loading } = useQuery(FETCH_USER(permissions), {
    variables: { userId },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      processUserResponse(data.fetchUser.user);
    },
  });

  const [updateUser, { loading: updating }] = useMutation(UPDATE_USER(permissions), {
    variables: {
      input: {
        ...omitDeep(userData, ['taxIdDisclosed', 'foreignTaxNumberDisclosed']),
        userId,
      },
    },
    onCompleted: (data) => {
      processUserResponse(data.updateUser.user);
      onNext();
    },
  });

  return (
    <ResidencyInformationVisual
      options={options}
      userData={userData}
      updateUser={setUserData}
      continueFunc={updateUser}
      loading={loading || stepLoading}
      updating={updating}
      applicableCountries={applicableCountries}
      grid={grid}
      updateMode={updateMode}
      workflowCompletion={workflowCompletion}
    />
  );
};

export default ResidencyInformation;

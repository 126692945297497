import { useTranslation } from 'react-i18next';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { Box, Typography } from '../../1-primative';
import { Card, CardContent } from '../../2-component';
import { formatMoneyValue } from '../../../util';
import { Statistics } from '../../../interfaces';

export const Highlights = ({ statistics, options, useCustodianData }: { statistics: Statistics | null, options: any, useCustodianData?: boolean }) => {
  const { t } = useTranslation(['components']);
  const { sys } = useThemeTokens();

  return (
    <Card>
      <CardContent sx={{ px: 0, pb: '0 important', backgroundColor: sys.color.surface }}>
          <Box sx={{ pb: 1.5, pt: 0.5, px: 2 }}>
              <Typography variant='titleMedium'>{t('highlights')}</Typography>
          </Box>
          <Box>
            {options.displayCashOnHoldToWithdraw && !useCustodianData && (
              <Box display='flex' justifyContent='space-between' sx={{ px: 2, py: 1 }}>
                  <Typography variant='bodyMedium' colorVariant='variant' sx={{ mr: 2 }}>{t('cashOnHoldToWithdraw')}</Typography>
                  <Typography variant='bodyMedium' weight='bold'>{formatMoneyValue(statistics?.cashOnHoldToTradeCents ?? 0)}</Typography>
              </Box>
            )}
            {options.displayCashOnHoldToTrade && !useCustodianData && (
              <Box display='flex' justifyContent='space-between' sx={{ px: 2, py: 1 }}>
                  <Typography variant='bodyMedium' colorVariant='variant' sx={{ mr: 2 }}>{t('cashOnHoldToTrade')}</Typography>
                  <Typography variant='bodyMedium' weight='bold'>{formatMoneyValue(statistics?.cashOnHoldToWithdrawCents ?? 0)}</Typography>
              </Box>
            )}
            {options.displayNewContributions && !useCustodianData && (
              <Box display='flex' justifyContent='space-between' sx={{ px: 2, py: 1 }}>
                  <Typography variant='bodyMedium' colorVariant='variant' sx={{ mr: 2 }}>{t('newContribution')}</Typography>
                  <Typography variant='bodyMedium' weight='bold'>{formatMoneyValue(statistics?.newContributionCents ?? 0)}</Typography>
              </Box>
            )}
            {options.displayCashAvailable && !useCustodianData && (
              <Box display='flex' justifyContent='space-between' sx={{ px: 2, py: 1 }}>
                  <Typography variant='bodyMedium' colorVariant='variant' sx={{ mr: 2 }}>{t('moneyAvailable')}</Typography>
                  <Typography variant='bodyMedium' weight='bold'>{formatMoneyValue(statistics?.moneyAvailableCents ?? 0)}</Typography>
              </Box>
            )}
          </Box>
      </CardContent>
    </Card>
  );
};

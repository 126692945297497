import {
  Table as MuiTable, TableProps,
  TableBody as MuiTableBody, TableBodyProps,
  TableRow as MuiTableRow, TableRowProps as MuiTableRowProps,
  TableCell as MuiTableCell, TableCellProps as MuiTableCellProps,
  TableHead as MuiTableHead, TableHeadProps as MuiTableHeadProps,
  SxProps,
  SortDirection,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { omit } from 'lodash';
import {
  FC, ReactNode, useState, forwardRef,
} from 'react';
import { Box, Typography } from '../../1-primative';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import Ascending from '../../../assets/images/sorting/Ascending.svg';
import Descending from '../../../assets/images/sorting/Descending.svg';
import Unsorted from '../../../assets/images/sorting/Unsorted.svg';
import { IconButton } from '../iconButton/iconButton';

interface TableCellProps extends MuiTableCellProps {
  dense?: boolean;
  number?: boolean;
  right?: boolean;
  bold?: boolean;
  maxWidth?: string;
  leadingComponent?: ReactNode;
  isSortable?: boolean;
  isFirst?: boolean;
  testId?: string;
  center?: boolean;
  backgroundColor?: string;
  textColor?: string;
}

interface TableRowProps extends MuiTableRowProps {
  pointer?: boolean;
  backgroundColor?: string;
  testId?: string;
  showHoverElements?: boolean;
}

const extraTableCellProps = ['dense', 'number', 'right', 'bold', 'maxWidth'];

export const Table = (props: TableProps) => (<MuiTable {...props} sx={[{ overflow: 'auto' }, ...(Array.isArray(props.sx) ? props.sx : [props.sx])]} />);

export const TableBody = forwardRef<HTMLTableSectionElement, TableBodyProps>((props, ref) => (
  <MuiTableBody
    {...props}
    ref={ref}
    sx={[{ display: 'table', width: '100%' }, ...(Array.isArray(props.sx) ? props.sx : [props.sx])]}
  />
));

TableBody.displayName = 'TableBody';

export const TableHead = (props: MuiTableHeadProps) => (<MuiTableHead {...props} sx={[{ display: 'table', width: '100%' }, ...(Array.isArray(props.sx) ? props.sx : [props.sx])]} />);

export const TableRow = forwardRef<HTMLTableRowElement, TableRowProps>(({ testId, showHoverElements, ...restProps }, ref) => {
  const { sys } = useThemeTokens();
  return (
    <MuiTableRow
      {...restProps}
      ref={ref}
      data-testid={testId}
      sx={{
        backgroundColor: restProps.backgroundColor || `${sys.color.surface} !important`,
        '&:hover': {
          backgroundColor: restProps.hover ? `${sys.color.surfaceContainerVariant} !important` : undefined,
          cursor: restProps.pointer ? 'pointer' : undefined,
        },
        ...(showHoverElements && {
          '& .hover-element': {
            opacity: 0,
            transition: 'opacity 0.2s ease-in-out',
          },
          '&:hover .hover-element': {
            opacity: 1,
          },
        }),
      }}
    />
  );
});

TableRow.displayName = 'TableRow';

export const TableCell = (props: TableCellProps) => {
  const { sys } = useThemeTokens();
  const {
    sx,
    isFirst,
    testId,
    ...restProps
  } = props;

  const baseSx: SxProps = {
    borderBottom: `1px solid ${sys.color.outlineVariant}`,
    height: !props.dense ? '32px' : 'auto',
    textAlign: props.right || props.number ? 'right' : props.center ? 'center' : 'left',
    color: sys.color.onSurface,
  };

  const composedSx = [baseSx, ...(isFirst ? [{ position: 'sticky', left: 0, backgroundColor: 'inherit' }] : []), ...(Array.isArray(sx) ? sx : [sx])];

  return (
    <MuiTableCell sx={composedSx} {...omit(restProps, extraTableCellProps)} data-testid={testId}>
      <Typography
        variant='bodySmall'
        weight={props.bold ? 'bold' : 'regular'}
        sx={{
          fontVariant: props.number ? 'tabular-nums' : 'normal',
          maxWidth: props.maxWidth,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          color: 'inherit',
          whiteSpace: props.number ? 'nowrap' : undefined,
          ...(props.leadingComponent
            ? {
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              justifyContent: props.right ? 'end' : 'start',
            }
            : {}),
        }}
        data-testid={`${testId}-content`}
      >
        {props.leadingComponent ? (
          <>
            <Box>{props.leadingComponent}</Box>
            <Box>{props.children}</Box>
          </>
        ) : (
          props.children
        )}
      </Typography>
    </MuiTableCell>
  );
};

const SortIcon = ({ direction }: { direction: SortDirection | undefined }) => {
  const icon = direction === 'asc' ? Ascending : direction === 'desc' ? Descending : Unsorted;

  return <img src={icon} alt={direction || 'unsorted'} style={{ marginLeft: '8px' }} />;
};

export const TableHeadCell = (props: TableCellProps) => {
  const { sys } = useThemeTokens();
  const {
    sx,
    testId,
    isFirst,
    center,
    backgroundColor,
    textColor,
    ...restProps
  } = props;

  const baseSx: SxProps = {
    outline: `1px solid ${sys.color.surface}`,
    outlineOffset: '-1px',
    borderBottom: 'none',
    textAlign: props.right || props.number ? 'right' : props.center ? 'center' : 'left',
    verticalAlign: 'bottom',
    background: backgroundColor || sys.color.background,
    padding: '16px 16px',
    cursor: props.isSortable ? 'pointer' : 'default',
    '&:hover': {
      background: props.isSortable ? sys.color.neutralPressed : sys.color.background,
    },
  };

  const composedSx = [baseSx, ...(isFirst ? [{ position: 'sticky', left: 0 }] : []), ...(Array.isArray(sx) ? sx : [sx])];

  return (
    <MuiTableCell sx={composedSx} {...omit(restProps, extraTableCellProps)} data-testid={testId}>
      <Box
        display='flex'
        alignItems='center'
        justifyContent={props.right ? 'end' : props.center ? 'center' : 'start'}
        onClick={(e: any) => {
          props.isSortable && props.onClick && props.onClick(e);
        }}
        data-testid={props.isSortable ? `${testId}-sortable` : ''}
      >
        <Typography variant='labelSmall' weight='bold' sx={{ color: textColor || sys.color.onSurfaceVariant }} data-testid={`${testId}-label`}>
          {props.children}
        </Typography>
        {props.isSortable && <SortIcon direction={props.sortDirection} />}
      </Box>
    </MuiTableCell>
  );
};

export const TableTotalCell = (props: TableCellProps) => {
  const { sys } = useThemeTokens();
  const {
    sx,
    isFirst,
    testId,
    ...restProps
  } = props;

  const baseSx: any = {
    borderTop: `1px solid ${sys.color.outline}`,
    borderBottom: 'none',
    height: !props.dense ? '32px' : 'auto',
    textAlign: props.right || props.number ? 'right' : 'left',
    backgroundColor: sys.color.background,
    '&:last-of-type': {
      borderRadius: `0 0 ${sys.borderRadius.xl} 0`,
    },
    '&:first-of-type': {
      borderRadius: `0 0 0 ${sys.borderRadius.xl}`,
    },
    ...(isFirst ? { position: 'sticky', left: 0 } : {}),
    ...props.sx,
  };

  return (
    <MuiTableCell data-testid={testId} sx={baseSx} {...omit(restProps, extraTableCellProps)}>
      <Typography data-testid={`${testId}-content`} variant='bodySmall' weight={props.bold ? 'bold' : 'regular'} sx={{
        fontVariant: props.number ? 'tabular-nums' : 'normal',
        maxWidth: props.maxWidth,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        color: 'inherit',
      }}>{props.children}</Typography>
    </MuiTableCell>
  );
};

export interface ExpandableRow {
  id: string,
  name: string,
  level: number,
  expandedRows?: ExpandableRow[],
}

interface Props<T extends ExpandableRow> {
  row: T,
  DataComponent: FC<{ row: T }>,
  textColumnStyles?: SxProps,
  tableCellStyles?: SxProps,
  onRowClick?: (row: T) => void,
}

export const ExpandableTableRow = <T extends ExpandableRow>({
  row, DataComponent, textColumnStyles, tableCellStyles = {}, onRowClick, ...tableRowProps
}: Props<T> & TableRowProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const onExpandClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsExpanded(!isExpanded);
  };
  return (
    <>
      <TableRow
        onClick={() => {
          if (onRowClick) onRowClick(row);
        }}
        {...tableRowProps}
      >
        <TableCell sx={{
          ...tableCellStyles, display: 'flex', alignItems: 'center', pl: `${row.level * 20}px`, whiteSpace: 'nowrap',
        }}>
          <Box sx={{ display: 'inline', ml: 1, mr: 1 }}>
            {row.expandedRows && row.expandedRows.length > 0 ? (
              <IconButton onClick={onExpandClick} data-testid='expand-icon'>
                {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            ) : (
              <IconButton disabled><HorizontalRuleIcon /></IconButton>
            )}
          </Box>
          <Typography sx={{ ...textColumnStyles, display: 'inline' }}>{row.name}</Typography>
        </TableCell>
        <DataComponent row={row} />
      </TableRow>
      {isExpanded && row.expandedRows && row.expandedRows.length > 0 && (
        row.expandedRows.map((child) => (
          <ExpandableTableRow
            key={child.id}
            row={child as T}
            DataComponent={DataComponent}
            textColumnStyles={textColumnStyles}
            tableCellStyles={tableCellStyles}
            onRowClick={onRowClick}
            {...tableRowProps}
          />
        ))
      )}
    </>
  );
};

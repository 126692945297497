import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography, Box,
} from '../../../1-primative';
import {
  Table, TableHeadCell, TableRow, TableCell, TableBody, Pagination,
  Card,
  CardContent,
} from '../../../2-component';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import { usePageState } from '../../../../util/usePageState';
import NewWorkflow from './components/newWorkflow';
import { UpdateOrganizationSwitch } from '../../../4-module/updateOrganizationSwitch/updateOrganizationSwitch';
import { SettingsTitle } from '../../../3-pattern';

const FETCH_WORKFLOWS = gql`
  query fetchWorkflows($input: FetchWorkflowsInput!) {
    fetchWorkflows(input: $input) {
      workflows {
        id
        state
        name { en }
        steps {
          id
        }
        organization {
          name
        }
      }
    }
  }
`;

export const WorkflowsSettings = () => {
  const navigate = useNavigate();
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 20;
  const { t } = useTranslation(['orgSettings', 'shared', 'settings']);

  const {
    loading, error, data, refetch, previousData,
  } = useQuery(FETCH_WORKFLOWS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
        pagination: { perPage: 50 },
      },
    },
  });

  if (error) (<Typography>Error</Typography>);

  return (
    <>
      <SettingsTitle title={t('settings:workflows.title')} description={t('settings:workflows.description')} />
      <Card loading={loading}>
        { permissions.includes('write:workflow') && (
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
                {permissions.includes('write:advanced_organization_settings') && (
                  <UpdateOrganizationSwitch
                    field='useParentWorkflows'
                    label={t('settings:workflows.useParentWorkflows')}
                    organizationId={activeOrganization.id}
                    refetchQueries={[FETCH_WORKFLOWS]}
                  />
                )}
              </Box>
              <NewWorkflow afterCreate={refetch} defaultOrg={activeOrganization.id} />
            </Box>
          </CardContent>
        )}
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableHeadCell>{t('workflowTable.name')}</TableHeadCell>
              <TableHeadCell>{t('workflowTable.state')}</TableHeadCell>
              <TableHeadCell>{t('workflowTable.numberOfSteps')}</TableHeadCell>
              <TableHeadCell right>{t('workflowTable.organization')}</TableHeadCell>
            </TableRow>
            {data?.fetchWorkflows?.workflows?.map((workflow: any) => (
              <TableRow
                key={workflow.id}
                hover
                pointer
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                data-testid={`workflow-${workflow.id}`}
                onClick={() => {
                  navigate(`${workflow.id}`);
                }}
              >
                <TableCell component="th" scope="row">
                  {workflow.name.en}
                </TableCell>
                <TableCell>{t(`workflowStates.${workflow.state}`)}</TableCell>
                <TableCell>{workflow.steps.length}</TableCell>
                <TableCell right>{workflow?.organization?.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={Math.ceil(((data || previousData)?.fetchWorkflows?.totalCount ?? 0) / pageSize)}
          page={page}
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />
      </Card>
    </>
  );
};

import { useTranslation } from 'react-i18next';
import CustomFields from '../../customFields';
import { SettingsTitle } from '../../../3-pattern';

export const CustomFieldsSettings = () => {
  const { t } = useTranslation(['settings']);
  return (
    <>
      <SettingsTitle title={t('settings:customFields.title')} description={t('settings:customFields.description')} />
      <CustomFields />
    </>
  );
};

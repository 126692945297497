import { gql } from '@apollo/client';

const PORTFOLIO_REPORT_HOLDING_FIELDS = `
  expectedValueCents
  expectedPercentage
  currency
  taxRank
  isCashEquivalent
  valueCents
  quantity
  pendingQuantity
  financialProduct {
    id
    ticker
    isCash
    currentPriceCents
    name
    taxRank
    currency
    originalCurrency
    translatedName {
      en
      fr
    }
  }
`;

export const PORTFOLIO_REPORT_FIELDS = `
  id
  lastOptimizedBy {
    id
    createdAt
  }
  updatedAt
  refreshState
  subAccounts {
    subAccount {
      id
      state
      account {
        taxRank
        type
        user {
          firstName
          entityName
        }
      }
    }
    cashCents
    expectedCashCents
    cashAvailableForTradeCents
    marketValueCents
    pendingWithdrawCents
    pendingContributionCents
    pendingCashCents
    cashOnHoldToTradeCents
    holdings {
      ${PORTFOLIO_REPORT_HOLDING_FIELDS}
    }
  }
  cashCents
  pendingCashCents
  expectedCashPercentage
  cashAvailableForTradeCents
  expectedCashCents
  driftRebalanceRequired
  transferRebalanceRequired
  liquidateRebalanceRequired
  pendingSubTradeRequests
  unverifiedSubTradeRequests
  bulkTradeRunReasons {
    reason
    total
    unblockedSince
  }
  holdings {
    ${PORTFOLIO_REPORT_HOLDING_FIELDS}
  }
`;

const OPTIMAL_HOLDING_FIELDS = `
  index
  financialProduct
  expectedValueCents
  valueCents
  taxRank
`;

const OPTIMAL_PORTFOLIO_FIELDS = `
  id
  modelPortfolio
  modelPortfolioType
  expectedCashPercentage
  expectedCashCents
  subAccounts {
    index
    subAccount
    account
    user
    goal
    marketValueCents
    expectedCashCents
    cashCentsDeviation
    cashCentsToInvest
    taxable
    taxRank
    optimalHoldings {
      ${OPTIMAL_HOLDING_FIELDS}
    }
    currentHoldingAllocations
    currentHoldingQuantities
    preTradeHoldingAllocations
    preTradeHoldingPurchaseAllocations
  }
  optimalHoldings {
    ${OPTIMAL_HOLDING_FIELDS}
  }
  preTradeHoldings {
    index
    financialProduct
    moneyAllocatedCents
    diffValueCents
    diffPercentage
    expectedPercentage
    taxRank
    moneyAllocatedCentsRedemptions
    moneyAllocatedCentsPurchases
    diffPercentagePurchasesStage1
  }
  lastOptimizedBy
`;

export const SUB_TRADE_REQUEST_FIELDS = `
  id
  financialProduct {
    id
    ticker
    currentPriceCents
    settlementDays
    currency
    originalCurrency
    exchange
    translatedName {
      en
    }
  }
  settlementDate
  state
  isSellAll
  moneyAllocatedCents
  type
  subAccount {
    id
    account {
      id
      type
      custodianAccountNumber
      user {
        id
        firstName
        entityName
      }
    }
  }
  portfolioReport
  preSplitMoneyAllocatedCents
  isProcessedForPair
  pair {
    id
    financialProduct {
      ticker
    }
  }
  bulkTradeRun {
    id
  }
  verifiedAt
  verifiedByOrganizationUser {
    firstName
    lastName
  }
  blockedUntil
  blockedUntilAccountActive
  blockedUntilSubAccountActive
`;

export const SUB_TRADE_REQUEST_SPLIT_FIELDS = `
  splits {
    ${SUB_TRADE_REQUEST_FIELDS}
  }
`;

export const FETCH_PORTFOLIO_REPORTS = gql`
  query fetchPortfolioReports($input: FetchPortfolioReportsInput!) {
    fetchPortfolioReports(input: $input) {
      portfolioReports {
        ${PORTFOLIO_REPORT_FIELDS}
      }
    }
  }
`;

export const REFRESH_PORTFOLIO_REPORT = gql`
  mutation refreshPortfolioReport($input: RefreshPortfolioReportInput!) {
    refreshPortfolioReport(input: $input) {
      portfolioReport {
        ${PORTFOLIO_REPORT_FIELDS}
      }
    }
  }
`;

export const FETCH_BULK_TRADES = gql`
  query fetchBulkTradeRequests($input: FetchBulkTradeRequestsInput!) {
    fetchBulkTradeRequests(input: $input) {
      bulkTradeRequests {
        id
        state
      }
      totalCount
    }
  }
`;

export const FETCH_SUB_TRADE_REQUESTS_QUERY = (isWithSplits = true) => `
  query fetchSubTradeRequests($input: FetchSubTradeRequestsInput!) {
    fetchSubTradeRequests(input: $input) {
      subTradeRequests {
        ${SUB_TRADE_REQUEST_FIELDS}
        ${isWithSplits ? SUB_TRADE_REQUEST_SPLIT_FIELDS : ''}
      }
      totalCount
    }
  }
`;

export const FETCH_SUB_TRADE_REQUESTS = (isWithSplits = true) => gql`
  ${FETCH_SUB_TRADE_REQUESTS_QUERY(isWithSplits)}
`;

export const FETCH_OPTIMAL_PORTFOLIO = gql`
  query fetchOptimalPortfolio($input: FetchOptimalPortfolioInput!) {
    fetchOptimalPortfolio(input: $input) {
      optimalPortfolio {
        ${OPTIMAL_PORTFOLIO_FIELDS}
      }
    }
  }
`;

import { gql, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Typography, Box, Skeleton } from '../../../1-primative';
import {
  Button, Badge, Card, CardContent,
} from '../../../2-component';
import {
  BulkImport, BulkImportStates, ImportFileStates,
} from '../../../../interfaces/bulkImport';
import { getOrganizationUserName } from '../../../../interfaces/organizationUser';
import { useGlobalToast } from '../../../../providers/globalToastProvider';
import { usePermissions } from '../../../../providers/userContextProvider';
import ImportFilesTable, { FETCH_IMPORT_FILES } from './components/importFilesTable';
import { useLocalization } from '../../../../util/useLocalization';

export const FETCH_BULK_IMPORT = gql`
  query fetchBulkImport($bulkImportId: ObjectID!) {
    fetchBulkImport(bulkImportId: $bulkImportId) {
      bulkImport {
        id
        organization { id name }
        organizationUser { id firstName lastName }
        state
        createdAt
      }
    }
  }
`;

export const FETCH_QUEUED_FILES = gql`
  query fetchQueuedImportFiles($bulkImportId: ObjectID!) {
    fetchImportFiles(input: {
      filter:{
        bulkImportId: $bulkImportId,
        states: ${ImportFileStates.QUEUED},
      },
      pagination: { perPage:0 },
    }) {
      totalCount
    }
  }
`;

const TRANSITION_BULK_IMPORT = gql`
  mutation transitionBulkImport($input: TransitionBulkImportInput!){
    transitionBulkImport(input: $input) {
      bulkImport {
        id
        state
      }
    }
  }
`;

export const BulkImportFilesSettings = () => {
  const { t } = useTranslation(['bulkImport', 'shared']);
  const { localizedDateTime } = useLocalization();
  const params = useParams();
  const { bulkImportId } = params;
  const [bulkImport, setBulkImport] = useState<BulkImport>({});
  const [hasQueuedFiles, setHasQueuedFiles] = useState(false);
  const { showToast } = useGlobalToast();
  const { permissions } = usePermissions();

  const {
    data, loading, startPolling, stopPolling, refetch,
  } = useQuery(FETCH_BULK_IMPORT, {
    variables: {
      bulkImportId,
    },
    fetchPolicy: 'no-cache',
  });

  const { data: queuedFilesCountData, refetch: refetchQueuedFilesCount } = useQuery(FETCH_QUEUED_FILES, {
    variables: {
      bulkImportId,
    },
    fetchPolicy: 'no-cache',
  });

  const [transitionBulkImport] = useMutation(TRANSITION_BULK_IMPORT, { refetchQueries: [FETCH_BULK_IMPORT, FETCH_IMPORT_FILES] });

  const processBulkImport = async () => {
    await transitionBulkImport({
      variables: {
        input: {
          bulkImportId,
          transition: 'process',
        },
      },
    });
    showToast({ severity: 'success', message: t('bulkImport:processBulkImportMessage') });
    refetch();
  };

  const disabledProcessButton = !hasQueuedFiles || !bulkImport.state || ![BulkImportStates.FAILED, BulkImportStates.INITIATED].includes(bulkImport.state);

  useEffect(() => {
    if (data) {
      setBulkImport(data.fetchBulkImport.bulkImport);
      if (data.fetchBulkImport.bulkImport.state !== BulkImportStates.PROCESSING) {
        stopPolling();
        refetchQueuedFilesCount();
      } else {
        startPolling(5000);
      }
    }
  }, [data, stopPolling, startPolling, refetchQueuedFilesCount]);

  useEffect(() => {
    setHasQueuedFiles(!!queuedFilesCountData?.fetchImportFiles?.totalCount);
  }, [queuedFilesCountData]);

  const getStatusColor = (state?: string) => {
    if (state === 'COMPLETED' || state === 'PROCESSED') return 'positive';
    if (state === 'FAILED' || state === 'PARTIALLY_FAILED') return 'negative';
    return 'warning';
  };

  return (
    <Box>
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Box display='flex' justifyContent='space-between' alignItems='end'>
            <Box>
              { (!loading && bulkImport) ? (
                <>
                  <Typography>{t('table.organization')}: &nbsp; {bulkImport.organization?.name}</Typography>
                  <Typography>{t('table.createdAt')}: &nbsp; {localizedDateTime(bulkImport.createdAt)}</Typography>
                  <Typography>{t('table.createdBy')}: &nbsp; {getOrganizationUserName(bulkImport.organizationUser)}</Typography>
                  <Badge label={t(`bulkImport:states.${bulkImport.state}`)} color={getStatusColor(bulkImport.state)} sx={{ mt: 1 }} />
                </>
              ) : (
                <>
                  <Skeleton width='200px' />
                  <Skeleton width='200px' />
                  <Skeleton width='200px' />
                  <Skeleton width='200px' />
                </>
              )}
            </Box>
            {permissions.includes('write:bulk_import') && (
              <Button disabled={disabledProcessButton} onClick={processBulkImport} label={t('shared:process')} />
            )}
          </Box>
        </CardContent>
      </Card>
      <ImportFilesTable bulkImport={bulkImport} />
    </Box>
  );
};

export { BulkImportBreadcrumb } from './components/bulkImportBreadcrumb';

import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Box } from '../../../1-primative';
import {
  Button, Form, SettingsSwitch,
} from '../../../2-component';
import { NumberField, SettingsCard } from '../../../3-pattern';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import { useGlobalToast } from '../../../../providers/globalToastProvider';

const FETCH_ORGANIZATION = gql`
  query fetchOrganization($organizationId: String!) {
    fetchOrganization(organizationId: $organizationId) {
      organization {
        id
        name
        allowAccessToSubOrganizations
        allowLogout
        enableInactivityTimeOut
        inactivityTimeoutInMinutes
        autoInviteImportedUsers
      }
    }
  }
`;

const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        id
      }
    }
  }
`;

export const AccessSettings = () => {
  const { activeOrganization } = useContext(UserContext);
  const { showToast } = useGlobalToast();
  const { permissions } = usePermissions();
  const { t } = useTranslation(['settings', 'shared']);
  const [organization, setOrganization] = useState<any>(null);

  const { data, loading } = useQuery(FETCH_ORGANIZATION, {
    variables: { organizationId: activeOrganization?.id },
  });

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION, {
    variables: {
      input: {
        organizationId: activeOrganization?.id,
        allowAccessToSubOrganizations: organization?.allowAccessToSubOrganizations,
        allowLogout: organization?.allowLogout,
        enableInactivityTimeOut: organization?.enableInactivityTimeOut,
        inactivityTimeoutInMinutes: organization?.inactivityTimeoutInMinutes ? parseInt(organization?.inactivityTimeoutInMinutes, 10) : undefined,
        autoInviteImportedUsers: organization?.autoInviteImportedUsers,
      },
    },
    onCompleted: async () => {
      showToast({
        message: t('shared:successfullySaved'),
        severity: 'success',
      });
    },
  });

  useEffect(() => {
    if (data?.fetchOrganization?.organization) {
      setOrganization(data?.fetchOrganization?.organization);
    }
  }, [data]);

  return (
    <SettingsCard
      title={t('accessSettings.title')}
      description={t('accessSettings.description')}
    >
      <Form onSubmit={updateOrganization}>
        <Box display='flex' flexDirection='column' gap={2}>
          {permissions.includes('write:advanced_organization_settings') && (
            <>
              <SettingsSwitch
                label={t('accessSettings.allowAccessToSubOrganizations')}
                checked={organization?.allowAccessToSubOrganizations}
                onChange={(e: any) => setOrganization({ ...organization, allowAccessToSubOrganizations: e })}
                testId='access-settings-allow-access-to-sub-org'
                underline
              />
              <SettingsSwitch
                label={t('accessSettings.allowLogout')}
                checked={organization?.allowLogout}
                onChange={(e: any) => setOrganization({ ...organization, allowLogout: e })}
                testId='access-settings-allow-logout'
                underline
              />
            </>
          )}
          <SettingsSwitch
            label={t('accessSettings.autoInviteImportedUsers')}
            checked={organization?.autoInviteImportedUsers}
            onChange={(e: any) => setOrganization({ ...organization, autoInviteImportedUsers: e })}
            testId='access-settings-auto-invite-imported-users'
            underline
          />
          <SettingsSwitch
            label={t('accessSettings.enableInactivityTimeOut')}
            checked={organization?.enableInactivityTimeOut}
            onChange={(e: any) => setOrganization({ ...organization, enableInactivityTimeOut: e })}
            testId='access-settings-enable-inactivity-time-out'
          />
          { organization?.enableInactivityTimeOut && (
            <NumberField
              label={t('accessSettings.inactivityTimeoutInMinutes')}
              number={organization?.inactivityTimeoutInMinutes}
              decimalPlaces={0}
              setNumber={(e: any) => setOrganization({ ...organization, inactivityTimeoutInMinutes: e })}
              testId='access-settings-inactivity-timeout-in-minutes'
            />
          )}
          <Box display='flex' justifyContent='flex-end'>
            <Button type='submit' disabled={loading} label={t('shared:save')} dataTestId='access-settings-submit-button'/>
          </Box>
        </Box>
      </Form>
    </SettingsCard>
  );
};

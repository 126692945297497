import { MaritalStatus } from 'interfaces';
import { type TFunction, useTranslation } from 'react-i18next';
import type { FieldOptions } from '../utils';
import { AffiliateType } from './components/affiliate';

export enum SectionType {
  AFFILIATE = 'AFFILIATE',
  RRIF_PAYMENT_INSTRUCTIONS = 'RRIF_PAYMENT_INSTRUCTIONS',
  RESP_GRANTS = 'RESP_GRANTS',
  JURISDICTION = 'JURISDICTION',
  SOURCE_OF_FUNDS = 'SOURCE_OF_FUNDS',
  LINK_TO_HOUSEHOLD = 'LINK_TO_HOUSEHOLD',
  CUSTODIAN = 'CUSTODIAN',
  BASE_CURRENCY = 'BASE_CURRENCY',
  MULTI_CURRENCY = 'MULTI_CURRENCY',
}

export enum RelationType {
  CHILD = 'CHILD',
  COMMON_LAW = 'COMMON_LAW',
  GRANDCHILD = 'GRANDCHILD',
  GRANDPARENT = 'GRANDPARENT',
  GUARDIAN = 'GUARDIAN',
  NEPHEW_NIECE = 'NEPHEW_NIECE',
  OTHER = 'OTHER',
  PARENT = 'PARENT',
  PRIMARY_CAREGIVER = 'PRIMARY_CAREGIVER',
  SIBLING = 'SIBLING',
  SPOUSE = 'SPOUSE',
}

const SPOUSE_AND_COMMON_LAW_RELATIONS = [RelationType.SPOUSE, RelationType.COMMON_LAW];
const RESP_FAMILY_RELATIONS = [RelationType.CHILD, RelationType.GRANDCHILD, RelationType.SIBLING];
const RESP_SINGLE_RELATIONS = [...RESP_FAMILY_RELATIONS, RelationType.NEPHEW_NIECE, RelationType.OTHER];
const MINOR_BENEFICIARY_RELATIONS = [RelationType.CHILD, RelationType.GRANDCHILD, RelationType.NEPHEW_NIECE, RelationType.SIBLING, RelationType.OTHER];
export const ALL_RELATIONS = [
  RelationType.CHILD, RelationType.COMMON_LAW, RelationType.GRANDCHILD, RelationType.GRANDPARENT, RelationType.GUARDIAN, RelationType.NEPHEW_NIECE,
  RelationType.OTHER, RelationType.PARENT, RelationType.PRIMARY_CAREGIVER, RelationType.SIBLING, RelationType.SPOUSE,
];

type FieldOption = 'sourceOfFunds' | 'jurisdiction' | 'custodianConnection' | 'baseCurrency';
type PEPOptions = 'isOfficerOfPublicCompany' | 'isOwnerOfPublicCompany' | 'isMemberOfIiroc'
| 'politicallyExposedForeignPerson' | 'politicallyExposedDomesticPerson' | 'closeAssociateOfPEP'
| 'headOfInternationalOrganization';
export type FieldTypeOption =
  | 'fullName' | 'physicalAddress' | 'dateOfBirth' | 'dateOfDeath' | 'taxId' | 'primaryEmail' | 'gender'
  | 'employmentStatus' | 'allocated' | 'allocation' | 'relation' | 'citizenships' | 'maritalStatus' | 'foreignTaxInformation' | PEPOptions;

type SectionOption = { label: string, value: string };
export type FieldType = { type?: FieldTypeOption, options?: SectionOption[] };

interface AccountConfigSection {
  type: SectionType,
  affiliateType?: AffiliateType,
  defaultRelationType?: RelationType,
  multi?: boolean,
  showAllocation?: boolean,
  showRelationship?: boolean,
  excludeProvinces?: string[],
  edittableAfterOpen: boolean,
  fields?: FieldType[],
  optionalFields?: FieldType[],
  title?: string,
  actionTitle?: string;
  excludeMaritalStatus?: string[],
  useAccountHoldersAddress?: boolean,
  titlePrefix?: string,
  required?: boolean,
  hideDelete?: boolean,
  canUseExistingAffiliate?: boolean,
}
export type AccountConfigInterface = Record<string, { sections: AccountConfigSection[], fieldOptions?: FieldOptions }>;

export const AccountConfig = (options?: any): AccountConfigInterface => {
  const { t } = useTranslation('affiliationTypes');
  return ({
    CORPORATE_CASH: {
      sections: [
        corporateCashAuthorizedIndividualAffiliate(t, options),
        corporateCashbeneficialOwnerAffiliate(t, options),
        corporateCashDirectorAffiliate(t, options),
      ],
    },
    PERSONAL: {
      sections: [
        { type: SectionType.LINK_TO_HOUSEHOLD, edittableAfterOpen: true },
        { type: SectionType.CUSTODIAN, edittableAfterOpen: true },
        { type: SectionType.BASE_CURRENCY, edittableAfterOpen: true },
        { type: SectionType.MULTI_CURRENCY, edittableAfterOpen: true },
      ],
      fieldOptions: getFieldOptions(['custodianConnection', 'baseCurrency']),
    },
    CASH_JOINT: {
      sections: [
        jointAffiliate(t, ALL_RELATIONS),
        { type: SectionType.LINK_TO_HOUSEHOLD, edittableAfterOpen: true },
        { type: SectionType.CUSTODIAN, edittableAfterOpen: true },
        { type: SectionType.BASE_CURRENCY, edittableAfterOpen: true },
        { type: SectionType.MULTI_CURRENCY, edittableAfterOpen: true },
      ],
      fieldOptions: getFieldOptions(['custodianConnection', 'baseCurrency']),
    },
    RDSP: {
      sections: [
        { type: SectionType.LINK_TO_HOUSEHOLD, edittableAfterOpen: true },
        { type: SectionType.CUSTODIAN, edittableAfterOpen: true },
        { type: SectionType.BASE_CURRENCY, edittableAfterOpen: true },
        { type: SectionType.MULTI_CURRENCY, edittableAfterOpen: true },
      ],
      fieldOptions: getFieldOptions(['custodianConnection', 'baseCurrency']),
    },
    RRSP: {
      sections: [
        defaultPrimaryBeneficiaryAffiliate(),
        { type: SectionType.LINK_TO_HOUSEHOLD, edittableAfterOpen: true },
        { type: SectionType.CUSTODIAN, edittableAfterOpen: true },
        { type: SectionType.BASE_CURRENCY, edittableAfterOpen: true },
        { type: SectionType.MULTI_CURRENCY, edittableAfterOpen: true },
      ],
      fieldOptions: getFieldOptions(['custodianConnection', 'baseCurrency']),
    },
    TFSA: {
      sections: [
        defaultSuccessorAffiliate(t),
        defaultPrimaryBeneficiaryAffiliate(),
        { type: SectionType.LINK_TO_HOUSEHOLD, edittableAfterOpen: true },
        { type: SectionType.CUSTODIAN, edittableAfterOpen: true },
        { type: SectionType.BASE_CURRENCY, edittableAfterOpen: true },
        { type: SectionType.MULTI_CURRENCY, edittableAfterOpen: true },
      ],
      fieldOptions: getFieldOptions(['custodianConnection', 'baseCurrency']),
    },
    FHSA: {
      sections: [
        defaultSuccessorAffiliate(t),
        defaultPrimaryBeneficiaryAffiliate(),
        { type: SectionType.LINK_TO_HOUSEHOLD, edittableAfterOpen: true },
        { type: SectionType.CUSTODIAN, edittableAfterOpen: true },
        { type: SectionType.BASE_CURRENCY, edittableAfterOpen: true },
        { type: SectionType.MULTI_CURRENCY, edittableAfterOpen: true },
      ],
      fieldOptions: getFieldOptions(['custodianConnection', 'baseCurrency']),
    },
    LRSP: {
      sections: [
        defaultOtherAffiliate(t),
        defaultPrimaryBeneficiaryAffiliate(),
        { type: SectionType.JURISDICTION, edittableAfterOpen: true },
        { type: SectionType.SOURCE_OF_FUNDS, edittableAfterOpen: true },
        { type: SectionType.LINK_TO_HOUSEHOLD, edittableAfterOpen: true },
        { type: SectionType.CUSTODIAN, edittableAfterOpen: true },
        { type: SectionType.BASE_CURRENCY, edittableAfterOpen: true },
        { type: SectionType.MULTI_CURRENCY, edittableAfterOpen: true },
      ],
      fieldOptions: getFieldOptions(['sourceOfFunds', 'jurisdiction', 'custodianConnection', 'baseCurrency']),
    },
    RLSP: {
      sections: [
        defaultOtherAffiliate(t),
        defaultPrimaryBeneficiaryAffiliate(),
        { type: SectionType.JURISDICTION, edittableAfterOpen: true },
        { type: SectionType.SOURCE_OF_FUNDS, edittableAfterOpen: true },
        { type: SectionType.LINK_TO_HOUSEHOLD, edittableAfterOpen: true },
        { type: SectionType.CUSTODIAN, edittableAfterOpen: true },
        { type: SectionType.BASE_CURRENCY, edittableAfterOpen: true },
        { type: SectionType.MULTI_CURRENCY, edittableAfterOpen: true },
      ],
      fieldOptions: getFieldOptions(['sourceOfFunds', 'jurisdiction', 'custodianConnection', 'baseCurrency']),
    },
    LIRA: {
      sections: [
        defaultOtherAffiliate(t),
        defaultPrimaryBeneficiaryAffiliate(),
        { type: SectionType.JURISDICTION, edittableAfterOpen: true },
        { type: SectionType.SOURCE_OF_FUNDS, edittableAfterOpen: true },
        { type: SectionType.LINK_TO_HOUSEHOLD, edittableAfterOpen: true },
        { type: SectionType.CUSTODIAN, edittableAfterOpen: true },
        { type: SectionType.BASE_CURRENCY, edittableAfterOpen: true },
        { type: SectionType.MULTI_CURRENCY, edittableAfterOpen: true },
      ],
      fieldOptions: getFieldOptions(['sourceOfFunds', 'jurisdiction', 'custodianConnection', 'baseCurrency']),
    },
    LRIF: {
      sections: [
        { ...defaultOtherAffiliate(t), required: true },
        rrifPaymentInstructions(t),
        defaultSuccessorAffiliate(t),
        defaultPrimaryBeneficiaryAffiliate(),
        { type: SectionType.JURISDICTION, edittableAfterOpen: true },
        { type: SectionType.SOURCE_OF_FUNDS, edittableAfterOpen: true },
        { type: SectionType.LINK_TO_HOUSEHOLD, edittableAfterOpen: true },
        { type: SectionType.CUSTODIAN, edittableAfterOpen: true },
        { type: SectionType.BASE_CURRENCY, edittableAfterOpen: true },
        { type: SectionType.MULTI_CURRENCY, edittableAfterOpen: true },
      ],
      fieldOptions: getFieldOptions(['sourceOfFunds', 'jurisdiction', 'custodianConnection', 'baseCurrency']),
    },
    PRIF: {
      sections: [
        { ...defaultOtherAffiliate(t), required: true },
        rrifPaymentInstructions(t),
        defaultSuccessorAffiliate(t),
        defaultPrimaryBeneficiaryAffiliate(),
        { type: SectionType.JURISDICTION, edittableAfterOpen: true },
        { type: SectionType.SOURCE_OF_FUNDS, edittableAfterOpen: true },
        { type: SectionType.LINK_TO_HOUSEHOLD, edittableAfterOpen: true },
        { type: SectionType.CUSTODIAN, edittableAfterOpen: true },
        { type: SectionType.BASE_CURRENCY, edittableAfterOpen: true },
        { type: SectionType.MULTI_CURRENCY, edittableAfterOpen: true },
      ],
      fieldOptions: getFieldOptions(['sourceOfFunds', 'jurisdiction', 'custodianConnection', 'baseCurrency']),
    },
    LIF: {
      sections: [
        { ...defaultOtherAffiliate(t), required: true },
        rrifPaymentInstructions(t),
        defaultSuccessorAffiliate(t),
        defaultPrimaryBeneficiaryAffiliate(),
        { type: SectionType.JURISDICTION, edittableAfterOpen: true },
        { type: SectionType.SOURCE_OF_FUNDS, edittableAfterOpen: true },
        { type: SectionType.LINK_TO_HOUSEHOLD, edittableAfterOpen: true },
        { type: SectionType.CUSTODIAN, edittableAfterOpen: true },
        { type: SectionType.BASE_CURRENCY, edittableAfterOpen: true },
        { type: SectionType.MULTI_CURRENCY, edittableAfterOpen: true },
      ],
      fieldOptions: getFieldOptions(['sourceOfFunds', 'jurisdiction', 'custodianConnection', 'baseCurrency']),
    },
    RLIF: {
      sections: [
        { ...defaultOtherAffiliate(t), required: true },
        { ...rrifPaymentInstructions(t), titlePrefix: t('paymentInstruction:lifTitle') },
        defaultSuccessorAffiliate(t),
        defaultPrimaryBeneficiaryAffiliate(),
        { type: SectionType.JURISDICTION, edittableAfterOpen: true },
        { type: SectionType.SOURCE_OF_FUNDS, edittableAfterOpen: true },
        { type: SectionType.LINK_TO_HOUSEHOLD, edittableAfterOpen: true },
        { type: SectionType.CUSTODIAN, edittableAfterOpen: true },
        { type: SectionType.BASE_CURRENCY, edittableAfterOpen: true },
        { type: SectionType.MULTI_CURRENCY, edittableAfterOpen: true },
      ],
      fieldOptions: getFieldOptions(['sourceOfFunds', 'jurisdiction', 'custodianConnection', 'baseCurrency']),
    },
    RRIF: {
      sections: [
        { ...defaultOtherAffiliate(t), required: true },
        rrifPaymentInstructions(t),
        defaultSuccessorAffiliate(t),
        defaultPrimaryBeneficiaryAffiliate(),
        { type: SectionType.LINK_TO_HOUSEHOLD, edittableAfterOpen: true },
        { type: SectionType.CUSTODIAN, edittableAfterOpen: true },
        { type: SectionType.BASE_CURRENCY, edittableAfterOpen: true },
        { type: SectionType.MULTI_CURRENCY, edittableAfterOpen: true },
      ],
      fieldOptions: getFieldOptions(['custodianConnection', 'baseCurrency']),
    },
    RRSP_SPOUSAL: {
      sections: [
        defaultContributorAffiliate(t),
        defaultPrimaryBeneficiaryAffiliate(),
        { type: SectionType.LINK_TO_HOUSEHOLD, edittableAfterOpen: true },
        { type: SectionType.CUSTODIAN, edittableAfterOpen: true },
        { type: SectionType.BASE_CURRENCY, edittableAfterOpen: true },
        { type: SectionType.MULTI_CURRENCY, edittableAfterOpen: true },
      ],
      fieldOptions: getFieldOptions(['custodianConnection', 'baseCurrency']),
    },
    RIF_SPOUSAL: {
      sections: [
        defaultContributorAffiliate(t),
        rrifPaymentInstructions(t),
        defaultSuccessorAffiliate(t),
        defaultPrimaryBeneficiaryAffiliate(),
        { type: SectionType.LINK_TO_HOUSEHOLD, edittableAfterOpen: true },
        { type: SectionType.CUSTODIAN, edittableAfterOpen: true },
        { type: SectionType.BASE_CURRENCY, edittableAfterOpen: true },
        { type: SectionType.MULTI_CURRENCY, edittableAfterOpen: true },
      ],
      fieldOptions: getFieldOptions(['custodianConnection', 'baseCurrency']),
    },
    RESP_FAMILY_JOINT: {
      sections: [
        jointAffiliate(t, SPOUSE_AND_COMMON_LAW_RELATIONS),
        respGuardianAffiliate({ canUseExistingAffiliate: true }),
        respPrimaryCaregiverAffiliate({ canUseExistingAffiliate: true }),
        { ...respPrimaryBeneficiaryAffiliate(t, RESP_FAMILY_RELATIONS), multi: true },
        { type: SectionType.RESP_GRANTS, edittableAfterOpen: false },
        { type: SectionType.LINK_TO_HOUSEHOLD, edittableAfterOpen: true },
        { type: SectionType.CUSTODIAN, edittableAfterOpen: true },
        { type: SectionType.BASE_CURRENCY, edittableAfterOpen: true },
        { type: SectionType.MULTI_CURRENCY, edittableAfterOpen: true },
      ],
      fieldOptions: getFieldOptions(['custodianConnection', 'baseCurrency']),
    },
    RESP_SINGLE_JOINT: {
      sections: [
        jointAffiliate(t, SPOUSE_AND_COMMON_LAW_RELATIONS),
        respGuardianAffiliate({ canUseExistingAffiliate: true }),
        respPrimaryCaregiverAffiliate({ canUseExistingAffiliate: true }),
        respPrimaryBeneficiaryAffiliate(t, RESP_SINGLE_RELATIONS),
        { type: SectionType.RESP_GRANTS, edittableAfterOpen: false },
        { type: SectionType.LINK_TO_HOUSEHOLD, edittableAfterOpen: true },
        { type: SectionType.CUSTODIAN, edittableAfterOpen: true },
        { type: SectionType.BASE_CURRENCY, edittableAfterOpen: true },
        { type: SectionType.MULTI_CURRENCY, edittableAfterOpen: true },
      ],
      fieldOptions: getFieldOptions(['custodianConnection', 'baseCurrency']),
    },
    RESP_FAMILY: {
      sections: [
        respGuardianAffiliate(),
        respPrimaryCaregiverAffiliate(),
        { ...respPrimaryBeneficiaryAffiliate(t, RESP_FAMILY_RELATIONS), multi: true },
        { type: SectionType.RESP_GRANTS, edittableAfterOpen: false },
        { type: SectionType.LINK_TO_HOUSEHOLD, edittableAfterOpen: true },
        { type: SectionType.CUSTODIAN, edittableAfterOpen: true },
        { type: SectionType.BASE_CURRENCY, edittableAfterOpen: true },
        { type: SectionType.MULTI_CURRENCY, edittableAfterOpen: true },
      ],
      fieldOptions: getFieldOptions(['custodianConnection', 'baseCurrency']),
    },
    RESP_SINGLE: {
      sections: [
        respGuardianAffiliate(),
        respPrimaryCaregiverAffiliate(),
        respPrimaryBeneficiaryAffiliate(t, RESP_SINGLE_RELATIONS),
        { type: SectionType.RESP_GRANTS, edittableAfterOpen: false },
        { type: SectionType.LINK_TO_HOUSEHOLD, edittableAfterOpen: true },
        { type: SectionType.CUSTODIAN, edittableAfterOpen: true },
        { type: SectionType.BASE_CURRENCY, edittableAfterOpen: true },
        { type: SectionType.MULTI_CURRENCY, edittableAfterOpen: true },
      ],
      fieldOptions: getFieldOptions(['custodianConnection', 'baseCurrency']),
    },
    RESP_ADULT: {
      sections: [
        {
          ...respPrimaryBeneficiaryAffiliate(t),
          edittableAfterOpen: false,
          fields: (['fullName', 'gender', 'physicalAddress', 'dateOfBirth', 'taxId'] as const).map((type) => ({ type })),
        },
        { type: SectionType.RESP_GRANTS, edittableAfterOpen: false },
        { type: SectionType.LINK_TO_HOUSEHOLD, edittableAfterOpen: true },
        { type: SectionType.CUSTODIAN, edittableAfterOpen: true },
        { type: SectionType.BASE_CURRENCY, edittableAfterOpen: true },
        { type: SectionType.MULTI_CURRENCY, edittableAfterOpen: true },
      ],
      fieldOptions: getFieldOptions(['custodianConnection', 'baseCurrency']),
    },
    USA_BROKERAGE: {
      sections: [
        usaPrimaryBeneficiaryAffiliate(t),
        { type: SectionType.LINK_TO_HOUSEHOLD, edittableAfterOpen: true },
        { type: SectionType.CUSTODIAN, edittableAfterOpen: true },
        { type: SectionType.BASE_CURRENCY, edittableAfterOpen: true },
        { type: SectionType.MULTI_CURRENCY, edittableAfterOpen: true },
      ],
      fieldOptions: getFieldOptions(['custodianConnection', 'baseCurrency']),
    },
    USA_IRA: {
      sections: [
        usaPrimaryBeneficiaryAffiliate(t),
        { type: SectionType.LINK_TO_HOUSEHOLD, edittableAfterOpen: true },
        { type: SectionType.CUSTODIAN, edittableAfterOpen: true },
        { type: SectionType.BASE_CURRENCY, edittableAfterOpen: true },
        { type: SectionType.MULTI_CURRENCY, edittableAfterOpen: true },
      ],
      fieldOptions: getFieldOptions(['custodianConnection', 'baseCurrency']),
    },
    USA_RO_IRA: {
      sections: [
        usaPrimaryBeneficiaryAffiliate(t),
        { type: SectionType.LINK_TO_HOUSEHOLD, edittableAfterOpen: true },
        { type: SectionType.CUSTODIAN, edittableAfterOpen: true },
        { type: SectionType.BASE_CURRENCY, edittableAfterOpen: true },
        { type: SectionType.MULTI_CURRENCY, edittableAfterOpen: true },
      ],
      fieldOptions: getFieldOptions(['custodianConnection', 'baseCurrency']),
    },
    USA_RT_IRA: {
      sections: [
        usaPrimaryBeneficiaryAffiliate(t),
        { type: SectionType.LINK_TO_HOUSEHOLD, edittableAfterOpen: true },
        { type: SectionType.CUSTODIAN, edittableAfterOpen: true },
        { type: SectionType.BASE_CURRENCY, edittableAfterOpen: true },
        { type: SectionType.MULTI_CURRENCY, edittableAfterOpen: true },
      ],
      fieldOptions: getFieldOptions(['custodianConnection', 'baseCurrency']),
    },
    USA_ESA: {
      sections: [
        usaMinorBeneficiaryAffiliate(t),
        { type: SectionType.LINK_TO_HOUSEHOLD, edittableAfterOpen: true },
      ],
    },
    USA_INH_IRA: { sections: [usaDecendantAffiliate(t)] },
    USA_INH_RT_IRA: { sections: [usaDecendantAffiliate(t)] },
  });
};

const getFieldOptions = (options: FieldOption[]) => options.reduce<Record<string, { required: true }>>((acc, option) => {
  acc[option] = { required: true };
  return acc;
}, {});

const defaultPrimaryBeneficiaryAffiliate = (): AccountConfigSection => ({
  type: SectionType.AFFILIATE,
  affiliateType: AffiliateType.PRIMARY_BENEFICIARY,
  multi: true,
  showAllocation: true,
  showRelationship: true,
  excludeProvinces: ['QC'],
  edittableAfterOpen: true,
  fields: (['fullName', 'relation', 'allocation'] as const).map((type) => ({ type })),
});

const defaultOtherAffiliate = (t: TFunction): AccountConfigSection => {
  const relationOptions = SPOUSE_AND_COMMON_LAW_RELATIONS.map((value) => ({ label: t(`affiliationRelationship.${value}`), value }));
  const fieldOptions: Record<string, SectionOption[] | undefined> = { relation: relationOptions };

  return {
    type: SectionType.AFFILIATE,
    affiliateType: AffiliateType.OTHER,
    multi: false,
    showAllocation: false,
    showRelationship: false,
    edittableAfterOpen: false,
    useAccountHoldersAddress: true,
    title: t('affiliationTypes:SPOUSE_OR_COMMON_LAW_PARTNER'),
    actionTitle: t('affiliationTypes:button.SPOUSE_OR_COMMON_LAW_PARTNER'),
    excludeMaritalStatus: [MaritalStatus.SINGLE, MaritalStatus.DIVORCED, MaritalStatus.SEPARATED, MaritalStatus.WIDOWED],
    fields: (['fullName', 'physicalAddress', 'dateOfBirth', 'primaryEmail', 'taxId', 'employmentStatus', 'relation'] as const).map((type) => ({ type, options: fieldOptions[type] })),
  };
};

const defaultContributorAffiliate = (t: TFunction): AccountConfigSection => {
  const relationOptions = SPOUSE_AND_COMMON_LAW_RELATIONS.map((value) => ({ label: t(`affiliationRelationship.${value}`), value }));
  const fieldOptions: Record<string, SectionOption[] | undefined> = { relation: relationOptions };

  return {
    type: SectionType.AFFILIATE,
    affiliateType: AffiliateType.CONTRIBUTOR,
    multi: false,
    required: true,
    showAllocation: false,
    showRelationship: false,
    edittableAfterOpen: false,
    useAccountHoldersAddress: true,
    fields: (['fullName', 'physicalAddress', 'dateOfBirth', 'primaryEmail', 'taxId', 'employmentStatus', 'relation'] as const)
      .map((type) => ({ type, options: fieldOptions[type] })),
  };
};

const defaultSuccessorAffiliate = (t: TFunction): AccountConfigSection => {
  const relationOptions = SPOUSE_AND_COMMON_LAW_RELATIONS.map((value) => ({ label: t(`affiliationRelationship.${value}`), value }));
  const fieldOptions: Record<string, SectionOption[] | undefined> = { relation: relationOptions };

  return {
    type: SectionType.AFFILIATE,
    affiliateType: AffiliateType.SUCCESSOR,
    multi: false,
    showAllocation: false,
    showRelationship: false,
    excludeProvinces: ['QC'],
    edittableAfterOpen: true,
    useAccountHoldersAddress: true,
    fields: (['fullName', 'physicalAddress', 'taxId', 'relation'] as const).map((type) => ({ type, options: fieldOptions[type] })),
  };
};

const rrifPaymentInstructions = (t: TFunction): AccountConfigSection => ({
  type: SectionType.RRIF_PAYMENT_INSTRUCTIONS,
  edittableAfterOpen: true,
  titlePrefix: t('paymentInstruction:rifTitle'),
});

const respPrimaryBeneficiaryAffiliate = (t: TFunction, relations?: RelationType[]): AccountConfigSection => {
  const relationOptions = relations?.map((value) => ({ label: t(`affiliationRelationship.${value}`), value }));
  const fieldOptions: Record<string, SectionOption[] | undefined> = { relation: relationOptions };

  return {
    type: SectionType.AFFILIATE,
    affiliateType: AffiliateType.PRIMARY_BENEFICIARY,
    multi: false,
    required: true,
    showAllocation: true,
    showRelationship: true,
    edittableAfterOpen: true,
    hideDelete: true,
    useAccountHoldersAddress: true,
    fields: (['fullName', 'physicalAddress', 'dateOfBirth', 'taxId', 'gender', 'relation'] as const).map((type) => ({ type, options: fieldOptions[type] })),
  };
};

const respGuardianAffiliate = ({ canUseExistingAffiliate = false }: { canUseExistingAffiliate?: boolean } = {}): AccountConfigSection => ({
  type: SectionType.AFFILIATE,
  affiliateType: AffiliateType.OTHER,
  defaultRelationType: RelationType.GUARDIAN,
  canUseExistingAffiliate,
  multi: true,
  required: true,
  showAllocation: false,
  showRelationship: false,
  edittableAfterOpen: true,
  hideDelete: true,
  useAccountHoldersAddress: true,
  fields: (['fullName', 'physicalAddress', 'primaryEmail'] as const).map((type) => ({ type })),
});

const respPrimaryCaregiverAffiliate = ({ canUseExistingAffiliate = false }: { canUseExistingAffiliate?: boolean } = {}): AccountConfigSection => ({
  type: SectionType.AFFILIATE,
  affiliateType: AffiliateType.OTHER,
  defaultRelationType: RelationType.PRIMARY_CAREGIVER,
  canUseExistingAffiliate,
  multi: false,
  required: true,
  showAllocation: false,
  showRelationship: false,
  edittableAfterOpen: true,
  hideDelete: true,
  useAccountHoldersAddress: true,
  fields: (['fullName', 'physicalAddress', 'primaryEmail', 'taxId'] as const).map((type) => ({ type })),
});

const jointAffiliate = (t: TFunction, relations: RelationType[]): AccountConfigSection => {
  const relationOptions = relations.map((value) => ({ label: t(`affiliationRelationship.${value}`), value }));
  const fieldOptions: Record<string, SectionOption[] | undefined> = { relation: relationOptions };

  return {
    type: SectionType.AFFILIATE,
    affiliateType: AffiliateType.JOINT,
    multi: false,
    required: false,
    showAllocation: false,
    showRelationship: false,
    edittableAfterOpen: true,
    hideDelete: true,
    useAccountHoldersAddress: true,
    fields: (['fullName', 'physicalAddress', 'dateOfBirth', 'primaryEmail', 'relation'] as const).map((type) => ({ type, options: fieldOptions[type] })),
  };
};

const usaPrimaryBeneficiaryAffiliate = (t: TFunction): AccountConfigSection => {
  const relationOptions = ALL_RELATIONS.map((value) => ({ label: t(`affiliationRelationship.${value}`), value }));
  const fieldOptions: Record<string, SectionOption[] | undefined> = { relation: relationOptions };

  return {
    type: SectionType.AFFILIATE,
    affiliateType: AffiliateType.PRIMARY_BENEFICIARY,
    multi: false,
    showAllocation: true,
    showRelationship: true,
    edittableAfterOpen: true,
    useAccountHoldersAddress: false,
    fields: (['fullName', 'dateOfBirth', 'allocated', 'relation'] as const).map((type) => ({ type, options: fieldOptions[type] })),
    optionalFields: (['taxId', 'primaryEmail'] as const).map((type) => ({ type, options: fieldOptions[type] })),
  };
};

const usaMinorBeneficiaryAffiliate = (t: TFunction): AccountConfigSection => {
  const relationOptions = MINOR_BENEFICIARY_RELATIONS.map((value) => ({ label: t(`affiliationRelationship.${value}`), value }));
  const fieldOptions: Record<string, SectionOption[] | undefined> = { relation: relationOptions };

  return {
    type: SectionType.AFFILIATE,
    affiliateType: AffiliateType.PRIMARY_BENEFICIARY,
    multi: false,
    required: true,
    showAllocation: false,
    showRelationship: true,
    edittableAfterOpen: true,
    useAccountHoldersAddress: true,
    fields: (['fullName', 'physicalAddress', 'dateOfBirth', 'taxId', 'relation'] as const).map((type) => ({ type, options: fieldOptions[type] })),
  };
};

const corporateCashbeneficialOwnerAffiliate = (t: TFunction, options?: any): AccountConfigSection => {
  const relationOptions = ALL_RELATIONS.map((value) => ({ label: t(`affiliationRelationship.${value}`), value }));
  const fieldOptions: Record<string, SectionOption[] | undefined> = { relation: relationOptions };

  return {
    type: SectionType.AFFILIATE,
    affiliateType: AffiliateType.BENEFICIAL_OWNER,
    defaultRelationType: RelationType.OTHER,
    multi: !options?.disableAddingNewAffiliates || false,
    showAllocation: true,
    showRelationship: true,
    edittableAfterOpen: true,
    useAccountHoldersAddress: true,

    fields: ([
      'fullName',
      'physicalAddress',
      'primaryEmail',
      'allocated',
      'taxId',
      'dateOfBirth',
      'employmentStatus',
      'citizenships',
      'isOfficerOfPublicCompany',
      'isOwnerOfPublicCompany',
      'isMemberOfIiroc',
      'politicallyExposedForeignPerson',
      'politicallyExposedDomesticPerson',
      'closeAssociateOfPEP',
      'headOfInternationalOrganization',
    ] as const).map((type) => ({ type, options: fieldOptions[type] })),
  };
};

const corporateCashAuthorizedIndividualAffiliate = (t: TFunction, options?: any): AccountConfigSection => {
  const relationOptions = ALL_RELATIONS.map((value) => ({ label: t(`affiliationRelationship.${value}`), value }));
  const fieldOptions: Record<string, SectionOption[] | undefined> = { relation: relationOptions };

  return {
    type: SectionType.AFFILIATE,
    affiliateType: AffiliateType.AUTHORIZED_INDIVIDUAL,
    defaultRelationType: RelationType.OTHER,
    multi: !options?.disableAddingNewAffiliates || false,
    showAllocation: false,
    showRelationship: true,
    edittableAfterOpen: true,
    useAccountHoldersAddress: true,
    fields: ([
      'fullName',
      'physicalAddress',
      'primaryEmail',
      'dateOfBirth',
      'taxId',
      'employmentStatus',
      'maritalStatus',
      'citizenships',
      'foreignTaxInformation',
      'isOfficerOfPublicCompany',
      'isOwnerOfPublicCompany',
      'isMemberOfIiroc',
      'politicallyExposedForeignPerson',
      'politicallyExposedDomesticPerson',
      'closeAssociateOfPEP',
      'headOfInternationalOrganization',
    ] as const).map((type) => ({ type, options: fieldOptions[type] })),
  };
};

const corporateCashDirectorAffiliate = (t: TFunction, options?: any): AccountConfigSection => {
  const relationOptions = ALL_RELATIONS.map((value) => ({ label: t(`affiliationRelationship.${value}`), value }));
  const fieldOptions: Record<string, SectionOption[] | undefined> = { relation: relationOptions };

  return {
    type: SectionType.AFFILIATE,
    affiliateType: AffiliateType.DIRECTOR,
    defaultRelationType: RelationType.OTHER,
    multi: !options?.disableAddingNewAffiliates || false,
    showAllocation: false,
    showRelationship: false,
    edittableAfterOpen: true,
    useAccountHoldersAddress: true,
    fields: ([
      'fullName',
      'physicalAddress',
      'primaryEmail',
      'dateOfBirth',
      'employmentStatus',
      'taxId',
      'citizenships',
      'isOfficerOfPublicCompany',
      'isOwnerOfPublicCompany',
      'isMemberOfIiroc',
      'politicallyExposedForeignPerson',
      'politicallyExposedDomesticPerson',
      'closeAssociateOfPEP',
      'headOfInternationalOrganization',
    ] as const).map((type) => ({ type, options: fieldOptions[type] })),
  };
};

const usaDecendantAffiliate = (t: TFunction): AccountConfigSection => {
  const relationOptions = ALL_RELATIONS.map((value) => ({ label: t(`affiliationRelationship.${value}`), value }));
  const fieldOptions: Record<string, SectionOption[] | undefined> = { relation: relationOptions };

  return {
    type: SectionType.AFFILIATE,
    affiliateType: AffiliateType.DECEDENT,
    multi: false,
    showAllocation: false,
    showRelationship: true,
    edittableAfterOpen: false,
    required: true,
    fields: (['fullName', 'dateOfBirth', 'dateOfDeath', 'taxId', 'relation'] as const).map((type) => ({ type, options: fieldOptions[type] })),
  };
};

import { gql, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { SelectField, MenuItem } from '../../2-component';
import { UserContext } from '../../../providers/userContextProvider';

const FETCH_ORGANIZATION_DASHBOARDS = gql`
  query fetchOrganizationDashboards($input: FetchOrganizationDashboardsInput!) {
    fetchOrganizationDashboards(input: $input) {
      dashboards {
        id
        name
      }
      totalCount
    }
  }
`;

export const DashboardsSelect = (
  { label, selectedDashboards, onChange }:
  { label: string, selectedDashboards: string[], onChange: (event: any) => void },
) => {
  const { activeOrganization } = useContext(UserContext);
  const { data } = useQuery(FETCH_ORGANIZATION_DASHBOARDS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
      },
    },
  });
  const [dashboards, setDashboards] = useState<any[]>([]);

  useEffect(() => {
    if (data?.fetchOrganizationDashboards) setDashboards(data.fetchOrganizationDashboards.dashboards);
  }, [data]);

  return (
    <>
      { data?.fetchOrganizationDashboards && dashboards.length > 0 && (
        <SelectField
          multiple
          fullWidth
          value={selectedDashboards}
          onChange={onChange}
          label={label}
        >
          {
            dashboards.map((x: any) => (
              <MenuItem key={x.id} value={ x.id }>
                {x.name}
              </MenuItem>
            ))
          }
        </SelectField>
      )}
    </>
  );
};

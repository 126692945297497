/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from '@apollo/client';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  ConfirmationModal,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  Form,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from '../../..';
import { FieldOptions, FormErrors, validateFields } from '../../../4-module/workflowCompletion/subSteps/utils';
import { UPSERT_REP_CODE } from './repCode.queries';
import { RepCode } from '../../../../interfaces/repCode';
import { FETCH_REP_CODES } from '..';
import { FETCH_REP_CODES_SELECT } from '../../../3-pattern/repCodeSelect/repCodeSelect';

const EditRepCode = ({
  afterUpdate,
  repCodeToUpdate,
  open,
  handleClose,
}: {
  afterUpdate: () => void;
  repCodeToUpdate: RepCode;
  open: boolean;
  handleClose: () => void;
}) => {
  const { t } = useTranslation(['orgSettings']);
  const [repCode, setRepCode] = useState(repCodeToUpdate);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [errors, setErrors] = useState<FormErrors>(null);
  const [focused, setFocused] = useState<string[]>([]);

  const onArchiveCustomField = () => {
    setDeleteDialogOpen(true);
  };
  const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState<null | HTMLElement>(null);
  const contextMenuOpen = Boolean(contextMenuAnchorEl);
  const openContextMenu = (onElement: HTMLElement) => {
    setContextMenuAnchorEl(onElement);
  };
  const closeContextMenu = () => setContextMenuAnchorEl(null);
  const menuItems = [
    <MenuItem key='menu1' onClick={onArchiveCustomField}>
      {t('shared:archive')}
    </MenuItem>,
  ];

  const [upsertRepCode, { loading }] = useMutation(UPSERT_REP_CODE, {
    variables: {
      input: {
        code: repCode.code,
        name: repCode.name,
        organizationId: repCode.organization?.id,
      },
    },
    refetchQueries: [FETCH_REP_CODES, FETCH_REP_CODES_SELECT],
  });

  const [archiveRepCode] = useMutation(UPSERT_REP_CODE, {
    variables: {
      input: {
        code: repCode.code,
        archived: true,
        organizationId: repCode.organization?.id,
      },
    },
  });

  const update = async (event: any) => {
    const formErrors = validate();
    if (formErrors) {
      setFocused(Object.keys(formErrors));
      return;
    }
    event.preventDefault();
    await upsertRepCode();
    setFocused([]);
    setErrors(null);
    afterUpdate();
  };
  const fieldOptions: FieldOptions = useMemo(
    () => ({
      code: { required: true },
      name: { required: true },
    }),
    [repCode],
  );

  const validate = useCallback(
    (candidateFields?: string[]): FormErrors => {
      const newErrors = validateFields(fieldOptions, repCode, candidateFields);
      setErrors(newErrors);
      return newErrors;
    },
    [repCode, fieldOptions],
  );

  useEffect(() => {
    validate(focused);
  }, [validate, focused]);

  if (!repCode) {
    return <></>;
  }
  return (
    <>
      <Dialog open={open} title={t('repCodeModal.editTitle')} onClose={handleClose} maxWidth='sm' fullWidth>
        <Form>
          <DialogTitle onClose={(!menuItems) ? handleClose : undefined}>
            <Box display='flex' justifyContent='space-between' width='100%' alignItems='center'>
              {t('repCodeModal.editTitle')}
              {menuItems && menuItems.length > 0 && (
                <IconButton
                  size='small'
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    openContextMenu(event.currentTarget);
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              )}
            </Box>
          </DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              overflow: 'hidden',
            }}
          >
            <Box>
              <TextField
                fullWidth
                value={repCode?.code}
                label={t('repCodeModal.code')}
                helpText={t('repCodeModal.codeEditHelp')}
                required={true}
                disabled={true}
              />
            </Box>
            <Box>
              <TextField
                fullWidth
                value={repCode?.name}
                label={t('repCodeModal.name')}
                onChange={(e: any) => {
                  setRepCode({ ...repCode, name: e.target.value });
                }}
                onBlur={() => {
                  setFocused([...focused, 'name']);
                }}
                required={true}
                error={!!errors?.name}
                errorText={errors?.name?.message}
              />
            </Box>
          </DialogContent>
          <DialogFooter>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant='tonal'
                label={t('shared:cancel')}
                onClick={handleClose}
                sx={{ mr: 1 }}
              />
              <Button
                disabled={loading}
                label={t('shared:update')}
                onClick={update}
              />
            </Box>
          </DialogFooter>
          {menuItems && (
            <Menu
              anchorEl={contextMenuAnchorEl}
              open={contextMenuOpen}
              onClose={closeContextMenu}
              onClick={closeContextMenu}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              sx={{ cursor: 'pointer' }}
            >
              {menuItems}
            </Menu>
          )}
        </Form>
      </Dialog>
      {deleteDialogOpen && (
        <ConfirmationModal
          title={t('repCodeModal.archiveTitle')}
          bodyText={t('repCodeModal.archiveBody')}
          open={deleteDialogOpen}
          maxWidth='sm'
          onConfirm={async () => {
            await archiveRepCode();
            setDeleteDialogOpen(false);
            afterUpdate();
          }}
          onCancel={() => {
            setDeleteDialogOpen(false);
          }}
        />
      )}
    </>
  );
};

export default EditRepCode;

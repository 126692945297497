import { gql } from '@apollo/client';

export const CREATE_SUB_ACCOUNT = gql`
  mutation createSubAccount($input: CreateSubAccountInput!) {
    createSubAccount(input: $input) {
      subAccount {
        id
        account {
          id state type affiliations { id relation type }
          user { id primaryEmail type }
        }
      }
    }
  }
`;

export const CREATE_ACCOUNT = gql`
  mutation createAccount($input: CreateAccountInput!) {
    createAccount(input: $input) {
      account { id type }
    }
  }
`;

export const CREATE_GOAL = gql`
  mutation createGoal($input: CreateGoalInput!) {
    createGoal(input: $input) {
      goal { id }
    }
  }
`;

export const UPDATE_ACCOUNT_AFFILIATIONS = gql`
  mutation updateAffiliations($input: UpdateAffiliationsInput!) {
    updateAffiliations(input: $input) {
      account { id }
      incompleteAffiliations { id relation type incompleteFields }
    }
  }
`;

export const FETCH_USER = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        dateOfBirth
        type
        accounts {
          id
          type
          state
          affiliations { id relation type }
        }
        physicalAddress { jurisdiction }
      }
    }
  }
`;

export const CLOSE_ACCOUNT = gql`
  mutation closeAccount($input: CloseAccountInput!) {
    closeAccount(input: $input) {
      account { id }
    }
  }
`;

export const CLOSE_SUB_ACCOUNT = gql`
  mutation closeSubAccount($input: CloseSubAccountInput!) {
    closeSubAccount(input: $input) {
      subAccount { id }
    }
  }
`;

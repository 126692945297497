import { useContext, useEffect, useState } from 'react';
import { sum, kebabCase, isNil } from 'lodash/fp';
import { useLocation, useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { calculatePrimaryAssetClassHoldings } from './holdings.resources';
import { Box, Typography } from '../../../1-primative';
import { historyDailyTypes, ObjectType, useStats } from '../../../../providers/statsHooks';
import { PageObjectType } from '../../../5-page';
import { AccordionHoldings } from './components/accordionHoldings';
import { TableHoldings } from './components/tableHoldings';
import { PieChartHoldings } from './components/pieChartHoldings';
import { translateBackend } from '../../../../assets/i18n/config';
import { UserContext } from '../../../../providers/userContextProvider';
import { Holding } from '../../../../interfaces';
import { getUserIdFromPath } from '../../../../util/getUserIdFromPath';
import { EmptyStateAlt } from '../../../2-component';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';
import { useLocalization } from '../../../../util/useLocalization';
import { determinePageTypeFromRoute } from '../../../../util/routeUtils';

export const objectTypeMapping = (objectType: PageObjectType): ObjectType => {
  const types = {
    [PageObjectType.INDIVIDUAL]: ObjectType.USER,
    [PageObjectType.NON_INDIVIDUAL]: ObjectType.USER,
    [PageObjectType.HOUSEHOLD]: ObjectType.CLIENT_GROUP,
    [PageObjectType.GOAL]: ObjectType.GOAL,
    [PageObjectType.SUB_ACCOUNT]: ObjectType.SUB_ACCOUNT,
    [PageObjectType.ACCOUNT]: ObjectType.ACCOUNT,
  };
  return types[objectType];
};

const FETCH_HOLDINGS_HISTORY = gql`
  query fetchHoldingsHistory($input: FetchStatsHistoryInput!) {
    fetchStatsHistory(input: $input) {
      totalCount
      snapshots {
        date
        holdings { totalCents financialProduct { id } }
      }
    }
  }
`;

export const Holdings = ({
  objectId,
  objectType,
  options = {},
}: {
  objectId: string,
  objectType: PageObjectType,
  options?: any,
}): JSX.Element => {
  const { t } = useTranslation(['client', 'component']);
  const { userId, clientGroupId } = useParams();
  const { pathname: path } = useLocation();
  const { sys } = useThemeTokens();
  const { localizedDateTime } = useLocalization();

  const [assetClassHoldings, setAssetClassHoldings] = useState<any[]>([]);
  const [holdings, setHoldings] = useState<Holding[]>([]);
  const useCustodianData = [PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL, PageObjectType.HOUSEHOLD, PageObjectType.ACCOUNT, PageObjectType.GOAL].includes(objectType)
    && options.useExternalStatistics;
  const { activeCurrency } = useContext(UserContext);

  const userIdFromParams = getUserIdFromPath({ userId, clientGroupId, path });

  const {
    statistics, loading, refetchAll,
  } = useStats(
    {
      type: objectTypeMapping(objectType),
      id: objectId,
      useCustodianData,
      fetchHistory: false,
      currency: activeCurrency,
      onlyUserId: objectType === PageObjectType.GOAL ? userIdFromParams : undefined,
      skipErrorHandler: true,
    },
  );

  const startDate = dayjs().subtract(1, 'month').format('YYYY-MM-DD');
  const { loading: holdingsHistoryLoading, data: historyData } = useQuery(FETCH_HOLDINGS_HISTORY, {
    skip: !!useCustodianData,
    variables: {
      input: {
        type: historyDailyTypes[objectTypeMapping(objectType)],
        id: objectId,
        currency: activeCurrency,
        filter: {
          ...(determinePageTypeFromRoute() === ObjectType.CLIENT_GROUP && { includeHousehold: true }),
          afterDate: startDate,
          userId: objectType === PageObjectType.GOAL ? userIdFromParams : undefined,
        },
      },
    },
  });
  const history = historyData?.fetchStatsHistory?.snapshots || [];

  useEffect(() => {
    if ((statistics?.holdings || statistics?.holdingsMissingRate)) {
      const allHoldings = [
        ...(statistics?.holdings ?? []),
        ...(statistics?.holdingsMissingRate ?? []),
      ];
      const marketValueCents = sum(allHoldings.map((holding: Holding) => (holding?.originalCurrency?.totalCents ?? holding?.totalCents) ?? 0));
      setAssetClassHoldings(calculatePrimaryAssetClassHoldings(allHoldings.filter((x: Holding) => x.totalCents !== 0), marketValueCents));
      setHoldings(allHoldings);
    }
  }, [statistics, useCustodianData]);

  const sortHoldings = () => {
    const sortedHoldings = holdings.filter((x: Holding) => x.quantity !== 0).sort((a: Holding, b: Holding) => {
      const localeComparison = (a.originalCurrency?.currency ?? a.currency ?? '').localeCompare(b.originalCurrency?.currency ?? b.currency ?? '');
      return localeComparison === 0 ? (b.totalCents ?? 0) - (a.totalCents ?? 0) : localeComparison;
    });
    return sortedHoldings;
  };

  return (
    <>
      <Box display="flex" flexDirection='column' justifyContent='center'>
        <Typography data-testid={options.customTitle ? `${kebabCase(translateBackend(options.customTitle))}-title` : 'holdings-title'} variant='headingSmall'>
          {options.customTitle ? translateBackend(options.customTitle) : t('client:holdings')}
        </Typography>
        {options?.showLastUpdatedTimestamp && (
          <Box display="flex" alignItems='center'>
            <Typography data-testid={options.customTitle ? `${kebabCase(translateBackend(options.customTitle))}-last-updated-title` : 'holdings-last-updated-title'} variant='bodySmall'
              sx={{ color: sys.color.outline }}>
              {`${translateBackend(options.lastUpdatedTimestampLabel)}: ${statistics?.lastUpdatedAt ? localizedDateTime(statistics?.lastUpdatedAt) : ''}`}
            </Typography>
          </Box>
        )}
      </Box>
    {!isNil(statistics?.holdings) ? (
      <>
        {holdings.filter((x: Holding) => x.totalCents !== 0).length === 0 && !loading && (
          <EmptyStateAlt
            title={t('components:holdings.noHoldings')}
            sx={{ mt: 2, height: '96px' }}
          />
        )}
        {
          options.display === 'table' && (loading || sortHoldings().length > 0) && (
            <TableHoldings
              data-testid='holdings-table'
              holdings={sortHoldings()}
              loading={loading || holdingsHistoryLoading} history={history}
              options={options} useCustodianData={useCustodianData}
              objectId={objectId}
              objectType={objectType}
              refetch={refetchAll}
            />
          )
        }
        {
          options.display === 'pie' && (loading || holdings.filter((x: Holding) => x.totalCents !== 0).length > 0) && (
            <PieChartHoldings data-testid='holdings-pie-chart'
                              holdings={holdings.filter((x: Holding) => x.totalCents !== 0)} loading={loading}
                              options={options}/>
          )
        }
        {
          options.display === 'accordion' && (loading || assetClassHoldings.length > 0) && (
            <AccordionHoldings data-testid='holdings-accordion' assetClassHoldings={assetClassHoldings} loading={loading}/>
          )
        }
      </>
    ) : (
      !loading && (
        <EmptyStateAlt
          sx={{
            mt: 2,
            textAlign: 'center',
            height: '100px',
            flexDirection: 'column',
          }}
          title={t('components:holdings.errorStateTitleHoldings')}
          subtitle={t('shared:errorStateSubText')}
        />
      )
    )}
    </>
  );
};

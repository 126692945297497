import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '../../../../1-primative';
import {
  MenuItem, SelectField, TextField,
} from '../../../../2-component';
import { CreateNewModal } from '../../../../3-pattern';

const CREATE_WORKFLOW = gql`
  mutation createPageConfiguration($input: CreatePageConfigurationInput!) {
    createPageConfiguration(input: $input) {
      pageConfiguration {
        id
      }
    }
  }
`;

const NewPageConfiguration = ({ afterCreate, defaultOrg }: { afterCreate: () => void, defaultOrg?: string }) => {
  const { t } = useTranslation(['orgSettings']);
  const [pageConfiguration, setPageConfiguration] = useState({
    name: '',
    type: 'INDIVIDUAL',
    organizationId: defaultOrg ?? '',
  });

  const [createPageConfiguration, { loading }] = useMutation(CREATE_WORKFLOW, {
    variables: {
      input: {
        ...pageConfiguration,
        options: {
          ...(pageConfiguration.type === 'GOAL' && { canCloseGoal: true }),
          ...(pageConfiguration.type === 'SUB_ACCOUNT' && { canCloseSubAccount: true }),
        },
      },
    },
  });

  const create = async (event: any) => {
    await createPageConfiguration();
    afterCreate();
  };
  const disabled = !pageConfiguration.name || !pageConfiguration.organizationId || !pageConfiguration.type;
  return (
    <CreateNewModal loading={loading} disabled={disabled} title={t('pageConfigurationModal.title')} onSubmit={create} sx={{ float: 'right' }} maxWidth='xs'>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          fullWidth
          label={t('pageConfigurationModal.name')}
          value={pageConfiguration.name}
          onChange={(e: any) => setPageConfiguration({ ...pageConfiguration, name: e.target.value as string })} />
        <SelectField
          fullWidth
          label={t('pageConfigurationModal.type')}
          value={pageConfiguration.type}
          onChange={(e: any) => setPageConfiguration({ ...pageConfiguration, type: e.target.value as string })}
        >
          <MenuItem value='INDIVIDUAL'>{t('pageConfigurationModal.types.INDIVIDUAL')}</MenuItem>
          <MenuItem value='NON_INDIVIDUAL'>{t('pageConfigurationModal.types.NON_INDIVIDUAL')}</MenuItem>
          <MenuItem value='ACCOUNT'>{t('pageConfigurationModal.types.ACCOUNT')}</MenuItem>
          <MenuItem value='GOAL'>{t('pageConfigurationModal.types.GOAL')}</MenuItem>
          <MenuItem value='SUB_ACCOUNT'>{t('pageConfigurationModal.types.SUB_ACCOUNT')}</MenuItem>
          <MenuItem value='HOUSEHOLD'>{t('pageConfigurationModal.types.HOUSEHOLD')}</MenuItem>
        </SelectField>
      </Box>
    </CreateNewModal>
  );
};

export default NewPageConfiguration;

import {
  Box, Tabs, Tab, Paper,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext, usePermissions } from '../../providers/userContextProvider';
import { usePageState } from '../../util/usePageState';
import EditableTemplate from './components/editableTemplates';
import ClientReportTemplates from './components/clientReportTemplates';
import OrganizationFeatureFlagSettings from './components/organizationFeatureFlagSettings';
import Organizations from './components/organizations';
import OrganizationUsers from './components/organizationUsers';
import Roles from './components/roles';
import Workflows from './components/workflows';
import RoleProfiles from './components/roleProfiles';
import PageConfigurations from './components/pageConfigurations';
import StaticPageWidgetConfigurations from './components/staticPageWidgetConfigurations';
import NotificationGroups from './components/notificationGroups';
import NotificationDefinitions from './components/notificationDefinitions';
import LocalizationSettings from './components/localizationSettings';
import { Dashboards } from '../../ovComponents/5-page/dashboards/dashboards';
import CustomFields from '../../ovComponents/5-page/customFields';
import RepCodes from '../../ovComponents/5-page/repCodes';
import DocumentManagement from '../../ovComponents/5-page/documentManagement';
import DowntimeSchedules from './components/downtimeSchedule';

type TabName = 'ou' | 'rp' | 'r' | 'org' | 'crt' | 'ff' | 'wf' | 'ng' | 'pc' | 'spwc' | 'nd' | 't' | 'cf' | 'ls' | 'ds' | 'dm' | 'dsc' | 'rc';

const TabPanel = (props: { index: TabName, [otherProps: string]: any; }) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const tabProps = (index: TabName) => ({
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`,
  value: index,
});

const OrgSettings = () => {
  const { permissions } = usePermissions();
  const { t } = useTranslation(['orgSettings']);
  const [value, setValue] = usePageState<TabName>('org', 'tab');
  const { activeOrganization } = useContext(UserContext);
  useNavigate();

  const handleChange = (event: any, newValue: TabName) => {
    setValue(newValue);
  };

  return (
    <Paper sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
          {permissions.includes('read:organizations') && (
            <Tab label={t('tabs.organizations')} {...tabProps('org')} data-testid='organizations-tab' />
          )}
          {permissions.includes('read:organization_users') && (
            <Tab label={t('tabs.users')} {...tabProps('ou')} data-testid='users-tab' />
          )}
          {permissions.includes('read:roles') && (
            <Tab label={t('tabs.roleProfiles')} {...tabProps('rp')} data-testid='tole-profiles-tab' />
          )}
          {permissions.includes('read:roles') && (
            <Tab label={t('tabs.roles')} {...tabProps('r')} data-testid='roles-tab' />
          )}
          {activeOrganization.availableFeatureFlags?.includes('CONFIGURABLE_CLIENT_REPORTS') && (
            <Tab label={t('tabs.clientReportTemplates')} {...tabProps('crt')} data-testid='client-report-templates-tab' />
          )}
          {permissions.includes('read:feature_flags') && (
            <Tab label={t('tabs.featureFlags')} {...tabProps('ff')} data-testid='feature-flags-tab' />
          )}
          {permissions.includes('read:workflow') && (
            <Tab label={t('tabs.workflows')} {...tabProps('wf')} data-testid='workflow-tab' />
          )}
          {permissions.includes('read:organizations') && (
            <Tab label={t('tabs.downtimeSchedule')} {...tabProps('dsc')} data-testid='downtime-schedule-tab' />
          )}
          {permissions.includes('read:page_configurations') && (
            <Tab label={t('tabs.pageConfigurations')} {...tabProps('pc')} data-testid='page-configurations-tab' />
          )}
          {permissions.includes('read:page_configurations') && (
            <Tab label={t('tabs.staticPageWidgetConfigurations')} {...tabProps('spwc')} data-testid='static-page-widget-configurations-tab' />
          )}
          {permissions.includes('read:notification_group') && (
            <Tab label={t('tabs.notificationGroups')} {...tabProps('ng')} data-testid='notification-groups-tab' />
          )}
          {permissions.includes('read:notification_definition') && (
            <Tab label={t('tabs.notificationDefinitions')} {...tabProps('nd')} data-testid='notification-definitions-tab' />
          )}
          {/* change permission later */}
          {['staging', 'test', 'development'].includes(process.env.NODE_ENV) && (
            <Tab label={t('tabs.htmlTemplates')} {...tabProps('t')} data-testid='html-templates-tab' />
          )}
          {permissions.includes('read:custom_fields') && (
            <Tab label={t('tabs.customFields')} {...tabProps('cf')} data-testid='custom-fields-tab' />
          )}
          {permissions.includes('read:localizations') && (
            <Tab label={t('tabs.localizationSettings')} {...tabProps('ls')} data-testid='localization-tab' />
          )}
          {permissions.includes('read:rep_code') && (
            <Tab label={t('tabs.repCodes')} {...tabProps('rc')} data-testid='rep-code-tab' />
          )}
          {permissions.includes('read:form_template') && (
            <Tab label={t('tabs.documentManagement')} {...tabProps('dm')} data-testid='form-template-tab' />
          )}
          {permissions.includes('read:dashboards') && permissions.includes('write:dashboards') && (
            <Tab label={t('tabs.dashboards')} {...tabProps('ds')} data-testid='dashboards-tab' />
          )}
        </Tabs>
      </Box>
      <TabPanel value={value} index={'org'}>
        <Organizations />
      </TabPanel>
      <TabPanel value={value} index={'ou'}>
        <OrganizationUsers />
      </TabPanel>
      <TabPanel value={value} index={'rp'}>
        <RoleProfiles />
      </TabPanel>
      <TabPanel value={value} index={'r'}>
        <Roles />
      </TabPanel>
      <TabPanel value={value} index={'crt'}>
        <ClientReportTemplates />
      </TabPanel>
      <TabPanel value={value} index={'ff'}>
        <OrganizationFeatureFlagSettings />
      </TabPanel>
      <TabPanel value={value} index={'wf'}>
        <Workflows />
      </TabPanel>
      <TabPanel value={value} index={'dsc'}>
        <DowntimeSchedules />
      </TabPanel>
      <TabPanel value={value} index={'pc'}>
        <PageConfigurations />
      </TabPanel>
      <TabPanel value={value} index={'spwc'}>
        <StaticPageWidgetConfigurations />
      </TabPanel>
      <TabPanel value={value} index={'ng'}>
        <NotificationGroups />
      </TabPanel>
      <TabPanel value={value} index={'nd'}>
        <NotificationDefinitions />
      </TabPanel>
      <TabPanel value={value} index={'t'}>
        <EditableTemplate />
      </TabPanel>
      <TabPanel value={value} index={'cf'}>
        <CustomFields />
      </TabPanel>
      <TabPanel value={value} index={'ls'}>
        <LocalizationSettings />
      </TabPanel>
      <TabPanel value={value} index={'rc'}>
        <RepCodes />
      </TabPanel>
      <TabPanel value={value} index={'dm'}>
        <DocumentManagement />
      </TabPanel>
      <TabPanel value={value} index={'ds'}>
        <Dashboards />
      </TabPanel>
    </Paper>
  );
};

export default OrgSettings;

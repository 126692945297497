import { gql, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import { Box } from '../../../../1-primative';
import {
  Button, Dialog, DialogContent, DialogFooter, DialogTitle, Form, MenuItem, SelectField,
} from '../../../../2-component';
import { AmountField } from '../../../../3-pattern';
import { Transfer, TransferSources, TransferType } from '../../../../../interfaces';

const UPDATE_TRANSFER = gql`
  mutation updateTransfer($input: UpdateTransferInput!) {
    updateTransfer(input: $input) {
      transfer {
        id
      }
    }
  }
`;

export interface TransactionUpdateInputPros {
  amount?: number,
  type?: TransferType,
  source?: string,
}

const UpdateTransfer = ({
  afterUpdate, transaction, open, handleClose, options,
}: {
  afterUpdate: () => void, transaction: Transfer, open: boolean, handleClose: () => void, options: any,
}) => {
  const { t } = useTranslation(['components', 'shared']);
  const { showToast } = useGlobalToast();
  const [updateInput, setUpdateInput] = useState<TransactionUpdateInputPros>({});

  useEffect(() => {
    setUpdateInput({
      amount: Math.abs(transaction?.amountCents ?? 0) / 100,
      type: transaction?.type,
      source: transaction?.source,
    });
  }, [transaction]);

  const [updateTransaction, { loading }] = useMutation(UPDATE_TRANSFER, {
    variables: {
      input: {
        transferId: transaction?.id,
        source: updateInput.source,
        amountCents: (updateInput?.amount ?? 0) * 100,
        sourceId: transaction?.sourceId,
        type: updateInput.type,
        bankAccountId: transaction?.bankAccount?.id,
      },
    },
  });

  const update = async () => {
    const response = await updateTransaction();
    if (response?.data) {
      showToast({ severity: 'info', message: t('components:updateTransferModal.updatedToastMessage') });
    }
    setUpdateInput({});
    afterUpdate();
    handleClose();
  };

  const disabled = !(updateInput.type && (updateInput.amount !== undefined && updateInput.amount >= 0));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle onClose={handleClose}>
        {t('components:updateTransferModal.title')}
      </DialogTitle>
      <Form onSubmit={update}>
        <DialogContent>
          <SelectField
            value={updateInput.source}
            label={t('transferTable.source')}
            onChange={(e: any) => setUpdateInput((prev) => ({ ...prev, source: e.target.value }))}
            fullWidth
            placeholder={t('any')}
            sx={{ mb: 2 }}
          >
            <MenuItem value={'any'}>{t('any')}</MenuItem>
            {/* only show management fee when there is a source id */}
            {Object.values(TransferSources).filter((source) => (_.isNil(transaction?.sourceId) ? source !== 'FEE' : true)).map((x: any) => (
              <MenuItem value={x} key={x}>{t(`transferSources.${x}`)}</MenuItem>
            ))}
          </SelectField>
          <AmountField
            label={t('components:updateTransferModal.amount')}
            sx={{ mb: 2 }}
            decimalPlaces={6}
            amount={updateInput.amount?.toString() ?? ''}
            fullWidth
            setAmount={(e: any) => setUpdateInput((prev) => ({ ...prev, amount: Number(e) }))}
          />
          <SelectField
            value={updateInput.type}
            label={t('components:updateTransferModal.type')}
            onChange={(e: any) => setUpdateInput((prev) => ({ ...prev, type: e.target.value }))}
            fullWidth
            placeholder={t('any')}
            sx={{ mb: 2 }}
          >
            <MenuItem value={'any'}>{t('any')}</MenuItem>
            <MenuItem value='DEPOSIT'>{t('shared:deposit')}</MenuItem>
            <MenuItem value='WITHDRAW'>{t('shared:withdraw')}</MenuItem>
          </SelectField>
        </DialogContent>
        <DialogFooter>
          <Box display='flex' justifyContent='end' width='100%'>
            <Button type='submit' label={t('shared:update')} disabled={disabled || loading} />
          </Box>
        </DialogFooter>
      </Form>
    </Dialog>
  );
};

export default UpdateTransfer;

import { useContext, useState } from 'react';
import { CreateModelPortfolioVisual } from './createModelPortfolio.visual';
import { UserContext } from '../../../../../providers/userContextProvider';
import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import { WorkflowContext } from '../../workflowCompletion';

export const CreateModelPortfolio = ({
  options, userId, onNext, stepLoading, workflowCompletion, grid = false, updateMode = false,
}: { options: any, userId: string, onNext: () => void, stepLoading: boolean, workflowCompletion?: any, grid?: boolean, updateMode?: boolean }) => {
  const { activeOrganization } = useContext(UserContext);
  const { workflowData, setWorkflowData, updateWorkflowContext } = useContext(WorkflowContext);

  const { showToast } = useGlobalToast();

  const [modelPortfolioData, setModelPortfolioData] = useState({
    translatedName: {
      en: '',
    },
    organizationId: activeOrganization.id,
    creationType: workflowData.modelPortfolioDetails?.creationType,
  });

  const onContinue = async () => {
    setWorkflowData({
      ...workflowData,
      modelPortfolioDetails: {
        ...workflowData.modelPortfolioDetails,
        ...modelPortfolioData,
      },
    });

    if (updateWorkflowContext) {
      await updateWorkflowContext();
    }

    if (modelPortfolioData.creationType === 'FROM_SCRATCH') {
      onNext();
    } else {
      showToast({
        severity: 'info',
        message: 'Templates are not available yet',
      });
    }
  };

  return (
    <CreateModelPortfolioVisual
      options={options}
      modelPortfolioData={modelPortfolioData}
      updateModelPortfolio={setModelPortfolioData}
      continueFunc={onContinue}
      loading={stepLoading}
      workflowCompletion={workflowCompletion}
    />
  );
};

import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { paletteBase } from '../../0-tokens';

export interface EntityMenuTokens {
  entityMenu: {
    avatar: {
      backgroundColor?: string;
      foregroundColor?: string;
    }
    borderRadius?: string;
  },
}

export const useEntityMenuTokens = (): EntityMenuTokens => {
  const baseTokens = useThemeTokens();
  const { sys } = baseTokens;
  const comp: EntityMenuTokens = {
    entityMenu: {
      avatar: {
        backgroundColor: paletteBase.supportOne40,
        foregroundColor: paletteBase.supportOne60,
      },
      borderRadius: sys.borderRadius.lg,
    },
  };

  return comp;
};

import { gql, useQuery } from '@apollo/client';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NewHousehold } from './components/newHousehold';
import { FilterHouseholds } from './components/filterHouseholds';
import { UserContext, usePermissions } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import { ClientGroupTypes } from '../../../interfaces';
import { Box, Skeleton } from '../../1-primative';
import {
  Table, TableHeadCell, TableRow, TableCell, TableBody, Pagination, TextField, CardContent, Card,
} from '../../2-component';
import { formatMoneyValue } from '../../../util';
import { translateBackend } from '../../../assets/i18n/config';

interface Household {
  id: string,
  type: string,
  name: string,
  organization: { name: string },
  primaryUser: { firstName: string, primaryEmail: string }
  relationships: any[],
  statistics: { marketValueCents: number },
}

export const FETCH_HOUSEHOLDS = gql`
  query fetchHouseholds($input: FetchClientGroupsInput!, $currency: StatisticsCurrencyTypes) {
    fetchClientGroups(input: $input) {
      clientGroups{
        id
        name
        type
        primaryUser { firstName primaryEmail }
        organization { name }
        relationships { type user { id } }
        statistics(input: { currency: $currency }) { marketValueCents }
      }
      totalCount
    }
  }
`;

const RIGHT_ALIGNED_COLS = ['numberOfRelationships', 'marketValue', 'organization'];
const DEFAULT_HOUSEHOLD_TABLE = [
  { label: { en: 'Household name', fr: 'Nom du ménage' }, type: 'householdName' },
  { label: { en: 'Primary contact', fr: 'Contact principal' }, type: 'primaryContact' },
  { label: { en: 'Primary contact email', fr: 'Courriel du contact principal' }, type: 'primaryContactEmail' },
  { label: { en: 'Number of Relationships', fr: 'Nombre de relations' }, type: 'numberOfRelationships' },
  { label: { en: 'Market Value', fr: 'Valeur du marché' }, type: 'marketValue' },
  { label: { en: 'Organization', fr: 'Organisation' }, type: 'organization' },
];

type TableOption = typeof DEFAULT_HOUSEHOLD_TABLE[number];

export const Households = ({ options = {} }: { options?: any }) => {
  const navigate = useNavigate();
  const { permissions } = usePermissions();
  const { activeOrganization, activeCurrency, userContext } = useContext(UserContext);
  const [filterByState, setFilterByState] = useState<string>('ACTIVE');

  const [page, setPage] = usePageState(1, 'page');
  const [searchText, setSearchText] = usePageState('', 'q');
  const [sortField, setSortField] = usePageState('name', 'sf');
  const [sortDesc, setSortDesc] = usePageState(true, 'sd');
  const [pageSize, setPageSize] = usePageState(15, 'ps');

  const tableOptions = options.table || userContext.role?.householdTableStaticPageWidgetConfiguration?.widget?.options?.table || DEFAULT_HOUSEHOLD_TABLE;

  const {
    loading, data, refetch, previousData,
  } = useQuery(FETCH_HOUSEHOLDS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id, type: ClientGroupTypes.Household, searchText, states: filterByState,
        },
        pagination: {
          sortField, sortDesc, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
      currency: activeCurrency || 'CAD',
    },
    fetchPolicy: 'no-cache',
  });

  const onSort = (field: string) => {
    if (sortField === field) setSortDesc(!sortDesc);
    setSortField(field);
  };

  const sortDirection = (field: string) => (sortField === field ? sortDesc ? 'desc' : 'asc' : undefined);

  return (
    <Card loading={loading}>
      <CardContent>
        <Box display='flex' justifyContent='space-between'>
          <TextField sx={{ width: '300px' }} value={searchText} leadingIcon='search' onChange={(event: any) => {
            setSearchText(event.target.value);
            setPage(1);
          }} />
          <Box display='flex'>
            <FilterHouseholds state={filterByState} setState={setFilterByState} />
            {permissions.includes('write:client_group') && <NewHousehold afterCreate={refetch} /> }
          </Box>
        </Box>
      </CardContent>
      <Box sx={{ overflowX: 'auto' }}>
        <Table>
          <TableBody>
            <TableRow>
              {tableOptions.map((opt: TableOption) => {
                if (opt.type === 'householdName') {
                  return <TableHeadCell isSortable onClick={() => onSort('name')} sortDirection={sortDirection('name')}>{translateBackend(opt.label)}</TableHeadCell>;
                }
                return <TableHeadCell right={RIGHT_ALIGNED_COLS.includes(opt.type)}>{translateBackend(opt.label)}</TableHeadCell>;
              })}
            </TableRow>

            {loading && !previousData && [...Array(15)].map((_x: any, i: number) => (
              <TableRow key={i}>
                {tableOptions.map(() => <TableCell><Skeleton width='100%' /></TableCell>)}
              </TableRow>
            ))}

            {(data || previousData)?.fetchClientGroups?.clientGroups?.map((houseHoldItem: Household) => (
              <TableRow hover key={houseHoldItem.id} sx={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => navigate(`/households/${houseHoldItem.id}`)}>
                {tableOptions.map((opt: TableOption) => <HouseholdsCell household={houseHoldItem} type={opt.type} />)}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Pagination
        count={Math.ceil(((data || previousData)?.fetchClientGroups?.totalCount ?? 0) / pageSize)}
        page={page}
        perPage={pageSize}
        onChangePerPage={(newPageSize) => setPageSize(newPageSize)}
        onChange={(_e, newPage) => setPage(newPage)}
        sx={{ p: 1, textAlign: 'right', '.MuiPagination-ul': { justifyContent: 'end' } }}
      />
    </Card>
  );
};

export { ClientGroupBreadcrumb } from './clientGroupBreadcrumb';

const HouseholdsCell = ({ household, type }: { household: Household, type: string }) => {
  switch (type) {
    case 'householdName':
      return <TableCell>{household.name}</TableCell>;
    case 'primaryContact':
      return <TableCell>{household.primaryUser.firstName}</TableCell>;
    case 'primaryContactEmail':
      return <TableCell>{household.primaryUser.primaryEmail}</TableCell>;
    case 'numberOfRelationships':
      return <TableCell number>{household.relationships.length}</TableCell>;
    case 'marketValue':
      return <TableCell number>{formatMoneyValue(household.statistics.marketValueCents)}</TableCell>;
    case 'organization':
      return <TableCell right>{household.organization.name}</TableCell>;
    default:
      return <></>;
  }
};

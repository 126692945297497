import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Box } from '../../../1-primative';
import {
  Button, Form, MenuItem, SelectField, SettingsSwitch,
} from '../../../2-component';
import { SettingsCard } from '../../../3-pattern';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import { useGlobalToast } from '../../../../providers/globalToastProvider';
import { FeatureFlag, useFeatureFlags } from '../../../4-module/featureFlag/featureFlag';
import { ORG_TRADING_PROCESS_LIST } from '../../../../interfaces';

const FETCH_ORGANIZATION = gql`
  query fetchOrganization($organizationId: String!) {
    fetchOrganization(organizationId: $organizationId) {
      organization {
        id
        name
        allowPostOptimizationEditing
        defaultTradingProcess
      }
    }
  }
`;

const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        id
      }
    }
  }
`;

export const TradesSettings = () => {
  const { activeOrganization } = useContext(UserContext);
  const { showToast } = useGlobalToast();
  const { permissions } = usePermissions();
  const { t } = useTranslation(['settings', 'shared']);
  const [organization, setOrganization] = useState<any>(null);
  const {
    featureFlags, updateFeatureFlag, saveFeatureFlags,
  } = useFeatureFlags();

  const { data, loading } = useQuery(FETCH_ORGANIZATION, {
    variables: { organizationId: activeOrganization?.id },
  });

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION, {
    variables: {
      input: {
        organizationId: activeOrganization?.id,
        allowPostOptimizationEditing: organization?.allowPostOptimizationEditing,
        defaultTradingProcess: organization?.defaultTradingProcess,
      },
    },
    onCompleted: async () => {
      await saveFeatureFlags();
      showToast({
        message: t('shared:successfullySaved'),
        severity: 'success',
      });
    },
  });

  useEffect(() => {
    if (data?.fetchOrganization?.organization) {
      setOrganization(data?.fetchOrganization?.organization);
    }
  }, [data]);

  return (
    <SettingsCard
      title={t('trades.title')}
      description={t('trades.description')}
    >
      <Form onSubmit={updateOrganization}>
        <Box display='flex' flexDirection='column' gap={2}>
          <FeatureFlag featureFlag={featureFlags.find((ff) => ff.key === 'AUTO_INVESTMENTS')} onChange={updateFeatureFlag} testId='settings-general-help-center-toggle' underline/>
          { permissions.includes('write:advanced_organization_settings') && (
            <>
              <SettingsSwitch
                label={t('trades.allowPostOptimizationEditing')}
                checked={organization?.allowPostOptimizationEditing}
                onChange={(e: any) => setOrganization({ ...organization, allowPostOptimizationEditing: e })}
                testId='settings-trades-support-url'
                underline
              />
              <SelectField
                label={t('trades.defaultTradingProcess')}
                value={organization?.defaultTradingProcess ?? ORG_TRADING_PROCESS_LIST[0].value}
                onChange={(e: any) => setOrganization({ ...organization, defaultTradingProcess: e.target.value })}
                fullWidth
              >
                {ORG_TRADING_PROCESS_LIST.map((x: any) => (
                  <MenuItem key={x.value} value={x.value}>
                    {x.name}
                  </MenuItem>
                ))}
              </SelectField>
            </>
          )}
          <Box display='flex' justifyContent='flex-end'>
            <Button type='submit' disabled={loading} label={t('shared:save')} dataTestId='settings-general-submit-button'/>
          </Box>
        </Box>
      </Form>
    </SettingsCard>
  );
};

import { Avatar, AvatarProps } from '../..';
import { Box } from '../../1-primative';

interface AvatarGroupProps {
  max?: number,
  spacing?: 'small' | 'medium' | number,
  elements: AvatarProps | AvatarProps[]
  maxAvatarProps?: Omit<AvatarProps, 'children' | 'avatar'>
}

const SPACING: { [key in 'small' | 'medium' ]: number } = {
  small: -2,
  medium: -1,
};

export const AvatarGroup = ({
  max = 2, spacing = 'medium', elements, maxAvatarProps,
}: AvatarGroupProps) => {
  const maxAvatars = max < 2 ? 2 : max;
  const avatarsSpacing = typeof spacing === 'number' ? -spacing : SPACING[spacing];
  const avatars = Array.isArray(elements) ? elements : [elements];

  return (
    <Box display='flex' alignItems='center'>
      {avatars.slice(0, maxAvatars).map((elem: AvatarProps, index: number) => (
        <Box ml={index === 0 ? 0 : avatarsSpacing} zIndex={10 - index}>
          <Avatar {...elem} />
        </Box>
      ))}
      {avatars.length > maxAvatars && (
        <Box ml={avatarsSpacing} zIndex={10 - maxAvatars}>
          <Avatar {...maxAvatarProps}>{`+${avatars.length - maxAvatars}`}</Avatar>
        </Box>
      )}
    </Box>
  );
};

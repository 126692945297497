/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNil, uniq, kebabCase } from 'lodash/fp';
import { Box, Skeleton } from '../../../../1-primative';
import {
  Card, Pagination, Table, TableBody, TableCell, TableHeadCell, TableRow,
} from '../../../../2-component';
import { SelectionChip } from '../../../../3-pattern';
import { translateBackend } from '../../../../../assets/i18n/config';
import { SecurityHoldingCell } from './securityHoldingCell';
import { SecurityTotalHoldingCell } from './securityTotalHoldingCell';
import { AssetClassHoldingCell } from './assetClassHoldingCell';
import { UserContext } from '../../../../../providers/userContextProvider';
import { RefinitivSecurityModal } from '../../refinitivSecurityModal/refinitivSecurityModal';
import { Holding } from '../../../../../interfaces';
// import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import { TaxLot, TaxLotDialog } from './taxLotDialog';
import { PageObjectType } from '../../../../5-page';
import { BookValueType } from '../../../../../providers/statsHooks';
import { usePageState } from '../../../../../util/usePageState';

const PAGE_SIZE = 15;

const DEFAULT_SECURITY_TABLE = [
  { label: { en: 'Symbol', fr: 'Symbole' }, type: 'symbol' },
  { label: { en: 'Security Name', fr: '' }, type: 'securityName' },
  { label: { en: 'Quantity', fr: 'Quantité' }, type: 'quantity' },
  { label: { en: 'Book Cost', fr: 'Coût du livre' }, type: 'bookCost' },
  { label: { en: 'Current Price', fr: 'Prix actuel' }, type: 'currentPrice' },
  { label: { en: 'G/L Unrealized', fr: 'G/L non réalisé' }, type: 'unrealizedGainLoss' },
  { label: { en: '1-Day Change', fr: 'Changement de 1 jour' }, type: 'oneDayChange' },
  { label: { en: '% of Total', fr: '% du total' }, type: 'percentOfTotal' },
  { label: { en: 'Market Value (Native)', fr: '' }, type: 'marketValueNative' },
  { label: { en: 'Market Value', fr: 'Valeur du marché' }, type: 'marketValue' },
];

const DEFAULT_ASSET_CLASS_TABLE = [
  { label: { en: 'Name', fr: 'Nom' }, type: 'name' },
  { label: { en: 'G/L Unrealized', fr: 'G/L non réalisé' }, type: 'unrealizedGainLoss' },
  { label: { en: '1-Day Change', fr: 'Changement de 1 jour' }, type: 'oneDayChange' },
  { label: { en: '% of Total', fr: '% du total' }, type: 'percentOfTotal' },
  { label: { en: 'Market Value (Native)', fr: '' }, type: 'marketValueNative' },
  { label: { en: 'Market Value', fr: 'Valeur du marché' }, type: 'marketValue' },
];

export type GroupBy = 'primary' | 'secondary' | 'tertiary' | 'security';

export const find1Day = (hold: Holding, history: any[]) => history[history.length - 1]?.holdings?.find((h: Holding) => h.financialProduct?.id === hold.financialProduct?.id);
export const find3Day = (hold: Holding, history: any[]) => history[history.length - 3]?.holdings?.find((h: Holding) => h.financialProduct?.id === hold.financialProduct?.id);
export const find1Week = (hold: Holding, history: any[]) => history[history.length - 7]?.holdings?.find((h: Holding) => h.financialProduct?.id === hold.financialProduct?.id);
export const find1Month = (hold: Holding, history: any[]) => history[0]?.holdings?.find((h: Holding) => h.financialProduct?.id === hold.financialProduct?.id);

export const bookValueMap = (objectType: PageObjectType): BookValueType => {
  const types = {
    [PageObjectType.INDIVIDUAL as string]: BookValueType.USER,
    [PageObjectType.NON_INDIVIDUAL as string]: BookValueType.USER,
    [PageObjectType.GOAL as string]: BookValueType.GOAL,
    [PageObjectType.SUB_ACCOUNT as string]: BookValueType.SUB_ACCOUNT,
    [PageObjectType.ACCOUNT as string]: BookValueType.ACCOUNT,
    [PageObjectType.HOUSEHOLD as string]: BookValueType.CLIENT_GROUP,
  };
  return types[objectType] as BookValueType;
};

export const TableHoldings = ({
  holdings,
  history,
  loading,
  options = {},
  useCustodianData,
  objectId,
  objectType,
  refetch,
}: {
  holdings: Holding[];
  history: any[];
  loading?: boolean;
  options?: any;
  useCustodianData?: boolean;
  objectId: string;
  objectType?: string;
  refetch: () => void;
}) => {
  const [totalMarketValue, setTotalMarketValue] = useState(0);
  const [groupBy, setGroupBy] = useState<GroupBy>('security');
  const [data, setData] = useState<any[]>([]);
  const [missesFxRate, setMissesFxRate] = useState(false);
  const [page, setPage] = usePageState(1, 'holdingPage');
  const [perPage, setPerPage] = useState(PAGE_SIZE);
  const [hasMultipleCurrencies, setHasMultipleCurrencies] = useState(false);
  const [ricCode, setRicCode] = useState<string>('');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [taxLots, setTaxLots] = useState<TaxLot[]>([]);
  const [taxLotsOpen, setTaxLotsOpen] = useState<boolean>(false);
  const [activeFinancialProduct, setActiveFinancialProduct] = useState<any>(null);

  const { activeCurrency } = useContext(UserContext);
  const { t } = useTranslation(['client', 'accountTypes']);
  // const { showToast } = useGlobalToast();

  const displayHoldingCurrency = isNil(options.displayHoldingCurrency) || options.displayHoldingCurrency;

  const filterCustodianColumns = (columns: any[], isCustodian?: boolean) => {
    if (isCustodian) {
      const removedTypes = ['threeDayChange', 'oneWeekChange', 'oneMonthChange'];
      return columns.filter((col: any) => !removedTypes.includes(col.type));
    }
    return columns;
  };

  useEffect(() => {
    if (!loading) {
      setTotalMarketValue(holdings?.reduce((acc, holding) => acc + (holding.totalCents ?? 0), 0));
      const currencies = uniq((holdings ?? []).map((holding) => holding?.originalCurrency?.currency ?? holding.currency));
      setHasMultipleCurrencies(currencies.length > 1);
    }
  }, [holdings, loading]);

  useEffect(() => {
    if (groupBy === 'primary' && holdings) {
      const d: any = {};
      holdings.forEach((holding) => {
        const originalCents = (holding.quantity ?? 0) * (holding.adjustedCostBaseCents ?? 0);
        if (holding?.financialProduct?.primaryAssetClass?.id) {
          if (d[holding.financialProduct.primaryAssetClass.id]) {
            d[holding.financialProduct.primaryAssetClass.id].totalCents += holding.totalCents;
            d[holding.financialProduct.primaryAssetClass.id].totalCentsNative += holding?.originalCurrency?.totalCents ?? holding.totalCents;
            d[holding.financialProduct.primaryAssetClass.id].originalCents += originalCents;
            d[holding.financialProduct.primaryAssetClass.id].gainLossCents += (!!originalCents && !!holding.totalCents ? holding.totalCents - originalCents : 0);
            if (useCustodianData) {
              d[holding.financialProduct.primaryAssetClass.id].oneDayCents += (holding.eodPriceCents ?? 0) * (holding.quantity ?? 0);
            } else {
              d[holding.financialProduct.primaryAssetClass.id].oneDayCents += find1Day(holding, history)?.totalCents ?? 0;
              d[holding.financialProduct.primaryAssetClass.id].threeDayCents += find3Day(holding, history)?.totalCents ?? 0;
              d[holding.financialProduct.primaryAssetClass.id].oneWeekCents += find1Week(holding, history)?.totalCents ?? 0;
              d[holding.financialProduct.primaryAssetClass.id].oneMonthCents += find1Month(holding, history)?.totalCents ?? 0;
            }
          } else {
            d[holding.financialProduct.primaryAssetClass.id] = {
              originalCents,
              gainLossCents: (!!originalCents && !!holding.totalCents ? holding.totalCents - originalCents : 0),
              ...(useCustodianData
                ? {
                  oneDayCents: (holding.eodPriceCents ?? 0) * (holding.quantity ?? 0),
                }
                : {
                  oneDayCents: find1Day(holding, history)?.totalCents ?? 0,
                  threeDayCents: find3Day(holding, history)?.totalCents ?? 0,
                  oneWeekCents: find1Week(holding, history)?.totalCents ?? 0,
                  oneMonthCents: find1Month(holding, history)?.totalCents ?? 0,
                }),
              totalCents: holding.totalCents,
              totalCentsNative: holding?.originalCurrency?.totalCents ?? holding.totalCents,
              hasFxRate: !!holding?.originalCurrency,
              nativeCurrency: holding?.originalCurrency?.currency ?? holding.currency,
              currency: holding.currency,
              translatedName: holding.financialProduct.primaryAssetClass.translatedName,
              riskLevel: holding.financialProduct?.primaryAssetClass?.riskLevel || 100,
            };
          }
        }
      });
      setData(Object.values(d));
    } else if (groupBy === 'secondary' && holdings) {
      const d: any = {};
      holdings.forEach((holding) => {
        const originalCents = (holding.quantity ?? 0) * (holding.adjustedCostBaseCents ?? 0);
        if (holding?.financialProduct?.secondaryAssetClass?.id) {
          if (d[holding.financialProduct.secondaryAssetClass.id]) {
            d[holding.financialProduct.secondaryAssetClass.id].totalCents += holding.totalCents;
            d[holding.financialProduct.secondaryAssetClass.id].totalCentsNative += holding?.originalCurrency?.totalCents ?? holding.totalCents;
            d[holding.financialProduct.secondaryAssetClass.id].originalCents += originalCents;
            d[holding.financialProduct.secondaryAssetClass.id].gainLossCents += (!!originalCents && !!holding.totalCents ? holding.totalCents - originalCents : 0);
            if (useCustodianData) {
              d[holding.financialProduct.secondaryAssetClass.id].oneDayCents += (holding.eodPriceCents ?? 0) * (holding.quantity ?? 0);
            } else {
              d[holding.financialProduct.secondaryAssetClass.id].oneDayCents += find1Day(holding, history)?.totalCents ?? 0;
              d[holding.financialProduct.secondaryAssetClass.id].threeDayCents += find3Day(holding, history)?.totalCents ?? 0;
              d[holding.financialProduct.secondaryAssetClass.id].oneWeekCents += find1Week(holding, history)?.totalCents ?? 0;
              d[holding.financialProduct.secondaryAssetClass.id].oneMonthCents += find1Month(holding, history)?.totalCents ?? 0;
            }
          } else {
            d[holding.financialProduct.secondaryAssetClass.id] = {
              originalCents,
              gainLossCents: (!!originalCents && !!holding.totalCents ? holding.totalCents - originalCents : 0),
              ...(useCustodianData
                ? {
                  oneDayCents: (holding.eodPriceCents ?? 0) * (holding.quantity ?? 0),
                }
                : {
                  oneDayCents: find1Day(holding, history)?.totalCents ?? 0,
                  threeDayCents: find3Day(holding, history)?.totalCents ?? 0,
                  oneWeekCents: find1Week(holding, history)?.totalCents ?? 0,
                  oneMonthCents: find1Month(holding, history)?.totalCents ?? 0,
                }),
              totalCents: holding.totalCents,
              totalCentsNative: holding?.originalCurrency?.totalCents ?? holding.totalCents,
              hasFxRate: !!holding?.originalCurrency,
              nativeCurrency: holding?.originalCurrency?.currency ?? holding.currency,
              currency: holding.currency,
              translatedName: holding.financialProduct.secondaryAssetClass.translatedName,
              riskLevel: holding.financialProduct?.secondaryAssetClass?.riskLevel || 100,
            };
          }
        }
      });
      setData(Object.values(d));
    } else if (groupBy === 'tertiary' && holdings) {
      const d: any = {};
      holdings.forEach((holding) => {
        const originalCents = (holding.quantity ?? 0) * (holding.adjustedCostBaseCents ?? 0);
        if (holding?.financialProduct?.tertiaryAssetClass?.id) {
          if (holding.financialProduct?.tertiaryAssetClass?.id) {
            if (d[holding.financialProduct.tertiaryAssetClass.id]) {
              d[holding.financialProduct.tertiaryAssetClass.id].totalCents += holding.totalCents;
              d[holding.financialProduct.tertiaryAssetClass.id].totalCentsNative += holding?.originalCurrency?.totalCents ?? holding.totalCents;
              d[holding.financialProduct.tertiaryAssetClass.id].originalCents += originalCents;
              d[holding.financialProduct.tertiaryAssetClass.id].gainLossCents += (!!originalCents && !!holding.totalCents ? holding.totalCents - originalCents : 0);
              if (useCustodianData) {
                d[holding.financialProduct.tertiaryAssetClass.id].oneDayCents += (holding.eodPriceCents ?? 0) * (holding.quantity ?? 0);
              } else {
                d[holding.financialProduct.tertiaryAssetClass.id].oneDayCents += find1Day(holding, history)?.totalCents ?? 0;
                d[holding.financialProduct.tertiaryAssetClass.id].threeDayCents += find3Day(holding, history)?.totalCents ?? 0;
                d[holding.financialProduct.tertiaryAssetClass.id].oneWeekCents += find1Week(holding, history)?.totalCents ?? 0;
                d[holding.financialProduct.tertiaryAssetClass.id].oneMonthCents += find1Month(holding, history)?.totalCents ?? 0;
              }
            } else {
              d[holding.financialProduct.tertiaryAssetClass.id] = {
                originalCents,
                gainLossCents: (!!originalCents && !!holding.totalCents ? holding.totalCents - originalCents : 0),
                ...(useCustodianData
                  ? {
                    oneDayCents: (holding.eodPriceCents ?? 0) * (holding.quantity ?? 0),
                  }
                  : {
                    oneDayCents: find1Day(holding, history)?.totalCents ?? 0,
                    threeDayCents: find3Day(holding, history)?.totalCents ?? 0,
                    oneWeekCents: find1Week(holding, history)?.totalCents ?? 0,
                    oneMonthCents: find1Month(holding, history)?.totalCents ?? 0,
                  }),
                totalCents: holding.totalCents,
                totalCentsNative: holding?.originalCurrency?.totalCents ?? holding.totalCents,
                hasFxRate: !!holding?.originalCurrency,
                nativeCurrency: holding?.originalCurrency?.currency ?? holding.currency,
                currency: holding.currency,
                translatedName: holding.financialProduct.tertiaryAssetClass.translatedName,
                riskLevel: holding.financialProduct?.tertiaryAssetClass?.riskLevel || 100,
              };
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (d.NONE) {
              d.NONE.totalCents += holding.totalCents;
              d.NONE.totalCentsNative += holding?.originalCurrency?.totalCents ?? holding.totalCents;
              d.NONE.originalCents += originalCents;
              d.NONE.gainLossCents += (!!originalCents && !!holding.totalCents ? holding.totalCents - originalCents : 0);
              if (useCustodianData) {
                d.NONE.oneDayCents += (holding.eodPriceCents ?? 0) * (holding.quantity ?? 0);
              } else {
                d.NONE.oneDayCents += find1Day(holding, history)?.totalCents ?? 0;
                d.NONE.threeDayCents += find3Day(holding, history)?.totalCents ?? 0;
                d.NONE.oneWeekCents += find1Week(holding, history)?.totalCents ?? 0;
                d.NONE.oneMonthCents += find1Month(holding, history)?.totalCents ?? 0;
              }
            } else {
              d.NONE = {
                originalCents,
                gainLossCents: (!!originalCents && !!holding.totalCents ? holding.totalCents - originalCents : 0),
                ...(useCustodianData
                  ? {
                    oneDayCents: (holding.eodPriceCents ?? 0) * (holding.quantity ?? 0),
                  }
                  : {
                    oneDayCents: find1Day(holding, history)?.totalCents ?? 0,
                    threeDayCents: find3Day(holding, history)?.totalCents ?? 0,
                    oneWeekCents: find1Week(holding, history)?.totalCents ?? 0,
                    oneMonthCents: find1Month(holding, history)?.totalCents ?? 0,
                  }),
                totalCents: holding.totalCents,
                totalCentsNative: holding?.originalCurrency?.totalCents ?? holding.totalCents,
                hasFxRate: !!holding?.originalCurrency,
                nativeCurrency: holding?.originalCurrency?.currency ?? holding.currency,
                currency: holding.currency,
                translatedName: { en: t('none'), fr: t('none') },
                riskLevel: 100,
              };
            }
          }
        }
      });
      setData(Object.values(d));
    }
  }, [holdings, groupBy]);

  useEffect(() => {
    const hasMissingFxRate = holdings.some((holding: Holding) => isNil(holding.originalCurrency));

    // TODO: Re-enable this toast message after multi-currency feature is fully implemented
    // if (hasMissingFxRate && !missesFxRate) {
    //   showToast({ message: t('client:assetsOverview.missingExchangeRateMessage'), severity: 'error' });
    // }
    setMissesFxRate(hasMissingFxRate);
  }, [holdings]);

  const primaryTitle = () => (options.primaryAssetClassTitle ? translateBackend(options.primaryAssetClassTitle) : t('primaryAssetClass'));
  const secondaryTitle = () => (options.secondaryAssetClassTitle ? translateBackend(options.secondaryAssetClassTitle) : t('secondaryAssetClass'));
  const tertiaryTitle = () => (options.tertiaryAssetClassTitle ? translateBackend(options.tertiaryAssetClassTitle) : t('tertiaryAssetClass'));

  const maxNumberOfDecimalPlaces = () => Math.max(
    ...holdings.map((holding) => {
      const [, decimal] = (holding.quantity ?? 0).toString().split('.');
      return decimal ? decimal.length : 0;
    }),
  );

  const sortedData = () => (
    data.sort((a, b) => {
      if (options.sortAssetClassesBy === 'riskLevel') {
        return (a.riskLevel - b.riskLevel);
      }
      return (b.totalCents - a.totalCents);
    })
  );

  const showPrimaryAssetClasses = (isNil(options.showPrimaryAssetClasses) || options.showPrimaryAssetClasses);
  const showSecondaryAssetClasses = (isNil(options.showSecondaryAssetClasses) || options.showSecondaryAssetClasses);
  const showTertiaryAssetClasses = (isNil(options.showTertiaryAssetClasses) || options.showTertiaryAssetClasses);

  const showSelectionChips = showPrimaryAssetClasses || showSecondaryAssetClasses || showTertiaryAssetClasses;

  useEffect(() => {
    const mapGroupBy: { [key in GroupBy]: boolean } = {
      primary: showPrimaryAssetClasses,
      secondary: showSecondaryAssetClasses,
      tertiary: showTertiaryAssetClasses,
      security: true,
    };
    if (!mapGroupBy[groupBy]) setGroupBy('security');
    setPage(1);
  }, [showPrimaryAssetClasses, showSecondaryAssetClasses, showTertiaryAssetClasses, groupBy]);

  const holdingsPaginated = (groupBy === 'security' ? holdings : sortedData()).slice((page - 1) * perPage, page * perPage);
  const total = groupBy !== 'security' ? sortedData().length : holdings.length;
  const showPagination = total > PAGE_SIZE;

  // TODO: Allow configurable columns, this is set for the Wellington Altus POC
  return (
    <>
      <Card sx={{ mt: 2 }}>
        {showSelectionChips && (
          <Box sx={{ pl: 4, pt: 3, pb: 2 }}>
            <SelectionChip
              onChange={(e: any) => setGroupBy(e as 'primary' | 'secondary' | 'tertiary')}
              value={groupBy}
              options={[
                { value: 'security', label: t('security'), dataTestId: 'holdings-table-selection-chip-security' },
                ...(showPrimaryAssetClasses
                  ? [{ value: 'primary', label: primaryTitle(), dataTestId: 'holdings-table-selection-chip-primary' }]
                  : []),
                ...(showSecondaryAssetClasses
                  ? [{ value: 'secondary', label: secondaryTitle(), dataTestId: 'holdings-table-selection-chip-secondary' }]
                  : []),
                ...(holdings?.some((elem) => elem.financialProduct?.tertiaryAssetClass) && showTertiaryAssetClasses
                  ? [{ value: 'tertiary', label: tertiaryTitle(), dataTestId: 'holdings-table-selection-chip-tertiary' }]
                  : []),
              ]}
            />
          </Box>
        )}
        <Box sx={{ overflowX: 'auto' }}>
          <Table>
            <TableBody data-testid={`table-holdings-${groupBy}-body`}>
              <TableRow testId={`table-holdings-${groupBy}-header`}>
                {groupBy === 'security' ? (
                  <>
                    {filterCustodianColumns(options.securityTable || DEFAULT_SECURITY_TABLE, useCustodianData).map((x: any, idx: number) => (
                      <TableHeadCell testId={`table-holdings-security-header-${x.type}`} key={idx} right={x.type !== 'symbol' && x.type !== 'securityName'} isFirst={idx === 0}>
                        {`${translateBackend(x.label)}${x.type === 'marketValue' ? `(${activeCurrency})` : ''}`}
                      </TableHeadCell>
                    ))}
                  </>
                ) : (
                  <>
                    {filterCustodianColumns(options.assetClassTable || DEFAULT_ASSET_CLASS_TABLE, useCustodianData).map((x: any, idx: number) => (
                      <TableHeadCell testId={`table-holdings-${groupBy}-header-${x.type}`} key={idx} right={x.type !== 'symbol' && x.type !== 'name'} isFirst={idx === 0}>
                        {`${translateBackend(x.label)}${x.type === 'marketValue' ? `(${activeCurrency})` : ''}`}
                      </TableHeadCell>
                    ))}
                  </>
                )}
              </TableRow>
              {loading && (
                <>
                  {[...Array(15)].map((x, i) => (
                    <TableRow key={i}>
                      {groupBy === 'security' ? (
                        <>
                          {filterCustodianColumns(options.securityTable || DEFAULT_SECURITY_TABLE, useCustodianData).map((_: any, idx: number) => (
                            <TableCell dense isFirst={idx === 0} key={idx} >
                              <Skeleton width='100%' height='16px' />
                            </TableCell>
                          ))}
                        </>
                      ) : (
                        <>
                          {filterCustodianColumns(options.assetClassTable || DEFAULT_ASSET_CLASS_TABLE, useCustodianData).map((_: any, idx: number) => (
                            <TableCell dense isFirst={idx === 0} key={idx}>
                              <Skeleton width='100%' height='16px' />
                            </TableCell>
                          ))}
                        </>
                      )}
                    </TableRow>
                  ))}
                </>
              )}
              {!loading
                && (groupBy === 'security' ? (
                  <>
                    {(holdingsPaginated || []).map((holding, index) => (
                      <TableRow
                        testId={`table-holdings-security-${index}`}
                        key={`${holding.financialProduct?.ticker}-${index}`}
                        hover
                        pointer={(holding.financialProduct?.ric && holding.financialProduct.ric !== '') || options.showTaxLots || false}
                        onClick={() => {
                          if (options.showTaxLots) {
                            setActiveFinancialProduct(holding.financialProduct);
                            setTaxLotsOpen(true);
                            setTaxLots(holding.accountTaxLots ?? []);
                          }
                          if (holding.financialProduct?.ric && holding.financialProduct.ric !== '') {
                            setRicCode(holding.financialProduct.ric);
                            setModalOpen(true);
                          }
                        }}
                      >
                        {filterCustodianColumns(options.securityTable || DEFAULT_SECURITY_TABLE, useCustodianData).map((x: any, i: number) => (
                          <SecurityHoldingCell
                            dataTestId={`table-holdings-security-${kebabCase(x.type)}-${index}`}
                            key={i}
                            holding={holding}
                            type={x.type}
                            history={history}
                            totalMarketValue={totalMarketValue}
                            useCustodianData={useCustodianData}
                            displayCurrency={i === 0 && displayHoldingCurrency}
                            missesFxRate={missesFxRate}
                            isFirst={i === 0}
                            maxQuantityDecimalPlaces={maxNumberOfDecimalPlaces()}
                            options={options}
                            bookValueType={bookValueMap(objectType as PageObjectType)}
                          />
                        ))}
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <>
                    {(holdingsPaginated || []).map((group: any, i: number) => (
                      <TableRow testId={`table-holdings-${groupBy}-${i}`} key={group.translatedName?.en ? `${group.translatedName.en}-${i}` : i} hover>
                        {filterCustodianColumns(options.assetClassTable || DEFAULT_ASSET_CLASS_TABLE, useCustodianData).map((x: any, idx: number) => (
                          <AssetClassHoldingCell
                            dataTestId={`table-holdings-${groupBy}-${kebabCase(x.type)}-${i}`}
                            key={idx}
                            group={group}
                            type={x.type}
                            totalMarketValue={totalMarketValue}
                            isFirst={idx === 0} />
                        ))}
                      </TableRow>
                    ))}
                  </>
                ))}
              {holdings && !loading && (
                <TableRow testId={`table-holdings-${groupBy}-footer`}>
                  {groupBy === 'security' ? (
                    <>
                      {filterCustodianColumns(options.securityTable || DEFAULT_SECURITY_TABLE, useCustodianData).map((x: any, index: number) => (
                        <SecurityTotalHoldingCell
                          dataTestId={`table-holdings-security-footer-${x.type}`}
                          key={index}
                          holdings={holdings}
                          type={x.type}
                          history={history}
                          useCustodianData={useCustodianData}
                          hasMultipleCurrencies={hasMultipleCurrencies}
                          isFirstColumn={index === 0}
                          bookValueType={bookValueMap(objectType as PageObjectType)}
                        />
                      ))}
                    </>
                  ) : (
                    <>
                      {filterCustodianColumns(options.assetClassTable || DEFAULT_ASSET_CLASS_TABLE, useCustodianData).map((x: any, index: number) => (
                        <SecurityTotalHoldingCell
                          dataTestId={`table-holdings-${groupBy}-footer-${x.type}`}
                          key={index}
                          holdings={holdings}
                          type={x.type}
                          history={history}
                          useCustodianData={useCustodianData}
                          hasMultipleCurrencies={hasMultipleCurrencies}
                          isFirstColumn={index === 0}
                          bookValueType={bookValueMap(objectType as PageObjectType)}
                        />
                      ))}
                    </>
                  )}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
        {showPagination && (
          <Box sx={{ px: 2, py: 1 }}>
            <Pagination
              size='small'
              count={Math.ceil(total / perPage)}
              page={page}
              total={total}
              perPage={perPage}
              onChangePerPage={(pageSize) => {
                setPerPage(pageSize);
                setPage(1);
              }}
              onChange={(_e, newPage) => {
                setPage(newPage);
              }}
            />
          </Box>
        )}
      </Card>
      { options.showTaxLots && (
        <TaxLotDialog
          open={taxLotsOpen}
          setOpen={setTaxLotsOpen}
          taxLots={taxLots}
          financialProduct={activeFinancialProduct}
          accountId={objectId}
          options={options}
          refetch={refetch}
        />
      )}
      {modalOpen && (
        <RefinitivSecurityModal
          onClose={() => {
            setModalOpen(false);
            setRicCode('');
          }}
          ricCode={ricCode}
        />
      )}
    </>
  );
};

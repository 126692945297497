import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { Add, Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import {
  Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Pagination, Table, TableBody, TableCell, TableHeadCell,
  TableRow, Typography,
} from '../../../ovComponents';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import StaticPageWidgetConfigurationForm from '../../../ovComponents/4-module/staticPageWidgetConfigurationForm/staticPageWidgetConfigurationForm';

const FETCH_STATIC_PAGE_WIDGET_CONFIGURATIONS = gql`
  query fetchStaticPageWidgetConfigurations($input: FetchStaticPageWidgetConfigurationsInput!) {
    fetchStaticPageWidgetConfigurations(input: $input) {
      staticPageWidgetConfigurations {
        id
        name
        createdAt
        widget { id type options }
        organization { name }
      }
      totalCount
    }
}
`;

const PAGE_SIZE = 50;

const StaticPageWidgetConfigurations = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['orgSettings', 'shared']);
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [page, setPage] = usePageState(1, 'page');
  const [open, setOpen] = useState(false);

  const {
    loading, data, refetch, previousData,
  } = useQuery(FETCH_STATIC_PAGE_WIDGET_CONFIGURATIONS, {
    variables: {
      input: {
        filter: { organizationId: activeOrganization.id },
        pagination: {
          sortField: 'createdAt', sortDesc: true, perPage: PAGE_SIZE, offSet: (page - 1) * PAGE_SIZE,
        },
      },
    },
  });

  return (
    <Box sx={{ m: '-24px -24px' }}>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 100 }} />
        </Box>
      ) : (
        <>
          <Box display='flex' justifyContent='end' alignItems='center' my={1} mr={1}>
            {permissions.includes('write:page_configurations') && (
              <Button label={t('staticPageWidgetConfigurationTable.add')} variant='outlined' leadingIcon={Add} onClick={() => setOpen(true)} />
            )}
          </Box>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableHeadCell><Typography variant='bodySmall'>{t('staticPageWidgetConfigurationTable.name').toUpperCase()}</Typography></TableHeadCell>
                <TableHeadCell><Typography variant='bodySmall'>{t('staticPageWidgetConfigurationTable.widgetType').toUpperCase()}</Typography></TableHeadCell>
                <TableHeadCell right><Typography variant='bodySmall'>{t('staticPageWidgetConfigurationTable.organization').toUpperCase()}</Typography></TableHeadCell>
              </TableRow>

              {data?.fetchStaticPageWidgetConfigurations?.staticPageWidgetConfigurations?.map((staticWidgetConfiguration: any) => (
                <TableRow key={staticWidgetConfiguration.id} onClick={() => navigate(`staticPageWidgetConfiguration/${staticWidgetConfiguration.id}`)} sx={{
                  cursor: 'pointer', '&:last-child td, &:last-child th': { border: 0 },
                }}>
                  <TableCell component="th" scope="row">{staticWidgetConfiguration.name}</TableCell>
                  <TableCell>{staticWidgetConfiguration.widget.type}</TableCell>
                  <TableCell right>{staticWidgetConfiguration.organization.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchStaticPageWidgetConfigurations?.totalCount ?? 0) / PAGE_SIZE)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{ p: 1, textAlign: 'right', '.MuiPagination-ul': { justifyContent: 'end' } }}
          />

          <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='xs'>
            <DialogTitle>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='headingSmall'>{t('staticPageWidgetConfigurationModal.title')}</Typography>
                <IconButton onClick={() => setOpen(false)}><Close /></IconButton>
              </Box>
            </DialogTitle>
            <DialogContent>
              <StaticPageWidgetConfigurationForm defaultOrg={activeOrganization.id} afterSubmit={() => {
                refetch();
                setOpen(false);
              }} />
            </DialogContent>
          </Dialog>
        </>
      )}
    </Box>
  );
};

export default StaticPageWidgetConfigurations;

import { useContext, useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { FinancialProjectionVisual } from './financialProjection.visual';
import { WorkflowContext } from '../../workflowCompletion';

const FETCH_GOAL = gql`
  query fetchGoal($goalId: ObjectID!) {
    fetchGoal(goalId: $goalId) {
      goal {
        targetAmountCents
        suggestedFinancialProduct {
          forecastedRateOfReturn
          forecastedStandardDeviation
        }
      }
    }
  }
`;

export const FinancialProjection = ({
  options, onNext, stepLoading, workflowCompletion,
}: { options: any, onNext: () => void, stepLoading: boolean, workflowCompletion?: any, }) => {
  const { workflowData } = useContext(WorkflowContext);
  const [loading, setLoading] = useState(true);
  const [goalData, setGoalData] = useState<any>({
    targetAmountCents: 0,
    suggestedFinancialProduct: {
      forecastedRateOfReturn: 0,
      forecastedStandardDeviation: 0,
    },
  });

  const { data } = useQuery(FETCH_GOAL, {
    variables: { goalId: workflowData.currentGoalId },
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (data) {
      setGoalData({
        targetAmountCents: data.fetchGoal.goal.targetAmountCents,
        suggestedFinancialProduct: {
          forecastedRateOfReturn: data.fetchGoal?.goal?.suggestedFinancialProduct?.forecastedRateOfReturn ?? 0,
          forecastedStandardDeviation: data.fetchGoal?.goal?.suggestedFinancialProduct?.forecastedStandardDeviation ?? 0,
        },
      });
      setLoading(false);
    }
  }, [data]);

  return (
    <FinancialProjectionVisual
      goalData={goalData}
      options={options}
      onNext={onNext}
      stepLoading={false}
      loading={loading}
      workflowCompletion={workflowCompletion}
    />
  );
};

export default FinancialProjection;

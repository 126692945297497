import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import {
  Box, Link, Skeleton, Typography,
} from '../../../../1-primative';
import { calculatePrimaryAssetClassHoldings } from '../../holdings/holdings.resources';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { Accordion, AccordionDetails, AccordionSummary } from '../../../../2-component';
import { translateBackend } from '../../../../../assets/i18n/config';
import { AccordionHoldings } from '../../holdings/components/accordionHoldings';
import { PieChartHoldings } from '../../holdings/components/pieChartHoldings';
import { useAccordionTokens } from '../../../../2-component/accordion/accordian.tokens';
import { Branch, FinancialProduct } from '../../../../../interfaces';

export const AccordionModelPortfolioHoldings = ({
  modelPortfolioHoldings,
  variant = 'elevated',
  loading,
  options,
}: {
  modelPortfolioHoldings: any[],
  variant?: 'elevated' | 'outlined',
  loading: boolean,
  options: any,
}): JSX.Element => {
  const { t } = useTranslation('client');
  const { sys } = useThemeTokens();
  const { comp } = useThemeTokens(useAccordionTokens());

  const getAssetClassHoldings = (financialProduct: FinancialProduct) => (
    calculatePrimaryAssetClassHoldings((financialProduct?.children ?? []).map((x: Branch) => ({ ...x, totalCents: x.percentage })), 100)
  );
  const getHoldings = (financialProduct: FinancialProduct) => (financialProduct?.children ?? []).map((x: Branch) => ({ ...x, totalCents: x.percentage }));

  if (loading) {
    return (
    <Box mt={2}>
      <Skeleton width='100%' height='300px' variant='rectangular'></Skeleton>
    </Box>
    );
  }

  return (
    <Box
      mt={2}
      sx={{
        boxShadow: variant === 'elevated' ? sys.elevation.surface : 'none',
        borderRadius: sys.borderRadius.xl,
        border: variant === 'outlined' ? `1px solid ${sys.color.outlineVariant}` : 'none',
      }}
    >
      {modelPortfolioHoldings.sort((a: any, b: any) => translateBackend(a?.translatedName).localeCompare(translateBackend(b?.translatedName))).map((item) => (
        <Accordion
          key={item?.id}
          variant='elevation'
          multiple
          disableGutters
          fullWidthOnMobile
          sx={{
            boxShadow: 'none !important',
            backgroundColor: sys.color.surface,
            '&:before': {
              backgroundColor: sys.color.surface,
            },
            '.MuiAccordionDetails-root': {
              padding: '8px 0px 0px',
            },
            '.MuiCollapse-root': {
              backgroundColor: sys.color.surface,
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              pl: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              '.MuiAccordionSummary-content': {
                marginY: '16px',
              },
            }}
          >
            <Box display="flex" flexDirection='column'>
              <Typography variant='titleMedium'>{translateBackend(item?.translatedName ?? '')}</Typography>
              <Box display="flex">
                <Typography variant='bodyMedium' colorVariant='variant' sx={{ fontWeight: 400 }}>
                  {`${translateBackend(item?.theme?.translatedShortDescription ?? item?.translatedPortfolioDescription ?? '')} `}
                  {(options.showModelPortfolioUrl && item?.url) && (<Link
                    onClick={(event: React.MouseEvent) => {
                      event.stopPropagation();
                      window.open(item.url, '_blank');
                    }}
                  >
                    {t('viewFundsFacts')}
                  </Link>)}
                </Typography>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {
              options.display === 'pie' ? (
                <PieChartHoldings data-testid='holdings-pie-chart' holdings={getHoldings(item)} loading={loading} options={options} mainVariable='percentage' variant='none' />
              ) : (
                <AccordionHoldings
                  data-testid='holdings-accordion'
                  assetClassHoldings={getAssetClassHoldings(item)}
                  loading={loading}
                  variant='none'
                  showTotal
                  sx={{
                    '&.MuiAccordion-root': {
                      '&:first-of-type': {
                        '.MuiAccordionSummary-root': {
                          borderTop: 'none !important',
                        },
                      },
                      '.MuiAccordionSummary-root': {
                        borderTop: `1px solid ${comp.accordion.multipleBorderColor} !important`,
                      },
                    },
                  }}
                  options={options}
                />
              )
            }
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

import { SxProps } from '@mui/material';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { Box, Typography } from '../../1-primative';
import { useEmptyStateTokens } from './emptyState.tokens';
import { Card } from '../card/card';

export const EmptyState = ({ children }: { children: any }) => {
  const { comp } = useThemeTokens(useEmptyStateTokens());
  return (
    <Box
      p={2}
      sx={{
        backgroundColor: comp.emptyState.backgroundColor,
        borderRadius: comp.emptyState.borderRadius,
        borderColor: comp.emptyState.borderColor,
        borderWidth: '1px',
        borderStyle: 'dashed',
        textAlign: 'center',
      }}
    >
      {children}
    </Box>
  );
};

export const EmptyStateHeaderTitle = ({ title, sx }: { title: string, sx?: SxProps }) => (
  <Typography textAlign="center" sx={{ ...(sx ?? {}) }} variant='titleMedium'>{title}</Typography>
);

export const EmptyStateAlt = ({ title, subtitle, sx }: { title: string, subtitle?: string, sx?: any }) => (
  <Card
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...(sx ?? {}),
    }}
  >
    <EmptyStateHeaderTitle title={title} />
    {subtitle && (<Typography mt={0.5} variant='bodyMedium'>{subtitle}</Typography>)}
  </Card>
);

import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateNewModal from '../../../components/modals/createNewModal';
import { NotificationChannel, TranslatedString } from '../../../interfaces';
import NotificationChannelsDefinitions from './notificationChannelsDefinitions';
import { defaultNotificationChannels } from './notificationGroups';
import { getBackendLanguage } from '../../../assets/i18n/config';
import { Box, TranslatableTextField } from '../../../ovComponents';

const CREATE_WORKFLOW = gql`
  mutation createNotificationGroup($input: CreateNotificationGroupInput!) {
    createNotificationGroup(input: $input) {
      notificationGroup {
        id
      }
    }
  }
`;

export interface NotificationGroupInput {
  name: TranslatedString,
  description?: TranslatedString,
  notificationChannels: NotificationChannel[],
  organizationId: string
}

const NewNotificationGroup = ({ afterCreate, defaultOrg }: { afterCreate: () => void, defaultOrg?: string }) => {
  const baseNotificationGroup: NotificationGroupInput = {
    name: { en: '' },
    description: { en: '' },
    organizationId: defaultOrg ?? '',
    notificationChannels: defaultNotificationChannels,
  };
  const { t } = useTranslation(['orgSettings']);
  const [notificationGroup, setNotificationGroup] = useState<NotificationGroupInput>(baseNotificationGroup);

  const [createNotificationGroup, { loading }] = useMutation(CREATE_WORKFLOW, {
    variables: {
      input: {
        name: notificationGroup.name,
        ...(notificationGroup.description?.en ? { description: notificationGroup.description } : {}),
        organizationId: defaultOrg ?? '',
        notificationChannels: notificationGroup.notificationChannels,
      },
    },
    onCompleted: () => setNotificationGroup(baseNotificationGroup),
  });

  const create = async (event: any) => {
    await createNotificationGroup();
    afterCreate();
  };
  const disabled = !notificationGroup.name.en || !notificationGroup.organizationId;
  return (
    <CreateNewModal loading={loading} disabled={disabled} title={t('notificationGroupModal.title')} onSubmit={create} sx={{ float: 'right', m: 1 }} maxWidth='xs'>
      <Box display='flex' flexDirection='column' gap={2} sx={{ paddingX: 2, pb: 1 }}>
        <TranslatableTextField
          fullWidth
          label={t('notificationGroupModal.name')}
          value={notificationGroup.name}
          onChange={(value) => setNotificationGroup({ ...notificationGroup, name: value })}
          fallbackLanguage={getBackendLanguage()}
          testId='notification-group-name'
        />
        <TranslatableTextField
          fullWidth
          label={t('notificationGroupModal.description')}
          value={notificationGroup.description || { en: '', fr: '' }}
          onChange={(value) => setNotificationGroup({ ...notificationGroup, description: value })}
          fallbackLanguage={getBackendLanguage()}
          testId='notification-group-description'
        />
        {notificationGroup.notificationChannels.map((notificationChannel, index) => (
          <NotificationChannelsDefinitions
            key={notificationChannel.type}
            index={index}
            notificationChannel={notificationChannel}
            notificationObject={notificationGroup}
            setNotificationObject={setNotificationGroup}
          />
        ))}
      </Box>
    </CreateNewModal>
  );
};

export default NewNotificationGroup;

import {
  Box, Chip, FormControlLabel, FormGroup, Grid, ListItem, MenuItem, Paper, Switch, TextField, Typography,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  gql, useApolloClient, useMutation, useQuery,
} from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AmountField from 'components/inputs/amountField';
import { useParams } from 'react-router-dom';
import { inRange } from 'lodash/fp';
import debounce from 'lodash/debounce';
import omitDeep from 'omit-deep-lodash';
import { CountryCodes, CurrencyCodes } from '@onevesthq/ov-enums';
import dayjs from 'dayjs';
import { round } from 'lodash';
import OrganizationSelect from '../../components/inputs/organizationSelect';
import RoleSelect from '../../components/inputs/roleSelect';
import NumberField from '../../components/inputs/numberField';
import ColorPickerField from '../../components/fields/colorPickerField';
import { FileDocumentObjectTypes, FileDocumentTypes } from '../../interfaces/fileDocument';
import { FETCH_LOGO } from '../../components/layout/organizationLogo';
import { fileUploader, FileUploaderProps } from '../../util/fileUploader';
import { useGlobalToast } from '../../providers/globalToastProvider';
import {
  AuthenticationConnections, Localization, ORG_TRADING_PROCESS_LIST,
} from '../../interfaces';
import { eligibleTaxIdTypes, taxIdFormattingMask } from '../../interfaces/user';
import { validateUrl } from '../../util';
import { FETCH_ORGANIZATION, usePermissions } from '../../providers/userContextProvider';
import {
  Button, Stack, Table, TableRow, TableCell, TableHeadCell, Skeleton, CircularProgress,
} from '../../ovComponents';
import { Organization } from '../../interfaces/organization';
import AccountTaxRanks, { AccountTypeTaxRank } from './components/editTaxRanking';
import { FETCH_ORGANIZATIONS } from '../orgSettings/components/organizations';
import EditOrganizationJurisdictions from './components/editOrganizationJurisdictions';
import EditOrganizationLogo from '../orgSettings/components/editOrganizationLogo';
import { FETCH_ORGANIZATION_NAME } from './orgDetailsBreadcrumb';
import { getCountryName } from '../../ovComponents/resources';
import { BetaBadge } from '../../ovComponents/3-pattern';
import AuthenticationDomainsTable from './components/authenticationDomainsTable';
import EditOrganizationErrorPageBackgroundImage, {
  FETCH_ERROR_PAGE_BACKGROUND_IMAGE,
} from '../orgSettings/components/editOrganizationErrorPageBackgroundImage';
import TranslatableString from '../../components/inputs/translatableString';

interface ManualExchangeRate {
  from: CurrencyCodes;
  to: CurrencyCodes;
  rate: number;
}

const FETCH_ORGANIZATION_DETAILS = gql`
  query fetchOrganization($organizationId: String!) {
    fetchOrganization(organizationId: $organizationId) {
      organization {
        id
        name
        entityName
        subdomain
        parent {
          id
        }
        useParentProductShelf
        useParentModelPortfolios
        useParentAssetClasses
        useParentThemes
        useParentSchedules
        useParentFeeTiers
        useParentBillingSchedules
        useParentWorkflows
        useParentIntegrations
        userManagementFeeBps
        revenueShareBps
        revenueShareTaxes
        allowDirectCommunication
        allowInstantInvest
        isReferral
        repCode
        useClientOrgAuth0
        enableEmailFrenchTranslation
        reviewTransactions
        useParentRoleProfiles
        useParentPageConfigurations
        useParentStaticPageWidgetConfigurations
        useParentCustodianConnections
        useParentLocalizations
        useParentFormTemplates
        useParentThemeTokens
        useParentArticles
        requireFeeApproval
        minInitialDepositCents
        minRecurringDepositCents
        profileReviewTimeInMonths
        theme {
          logo
          sideBarColor
          authenticationTheme {
            logo primaryColor pageBackgroundColor
          }
        }
        enableMultiFactorAuthentication
        multiFactorAuthenticationOptions
        enableRemeberBrowser
        defaultAuthenticationConnection
        allowAccessToSubOrganizations
        authenticationDomains { url authenticationType enterpriseConnectionName useHostedLogin }
        blockSuspiciousTransactions
        allowPostOptimizationEditing
        allowInactiveUserRebalancing
        allowInactiveUserFeeCalculation
        allowInactiveUserAdjustments
        allowLogout
        logoutRedirectUrl
        enableInactivityTimeOut
        inactivityTimeoutInMinutes
        allowPortfolioPerGoal
        requireManualAccountApproval
        requireSubTradeRequestApproval
        allowViewSubTradeRequestPairs
        allowPendingTransactions
        excludeAdminFeesFromClients
        useParentCustomFields
        enableContactSupport
        enableResourcesAndArticles
        supportUrl
        helpCentreUrl
        accountTypeTaxRanks {
          accountType
          taxRank
        }
        defaultTradingProcess
        bulkTradingProcess
        cashOnHoldToTradeDays
        cashOnHoldToWithdrawDays
        defaultSignUpRole { id }
        defaultAdvisorRole { id }
        defaultClientRole { id }
        disableStatsUpdateBasedOnTransactions
        displayMenuBar
        displayCurrency
        autoInviteImportedUsers
        localization { id }
        jurisdictions { all only }
        themeTokens
        customUrl
        faviconLink
        browserTabTitle
        minDepositCents
        productShelfCurrencies
        errorPageBackgroundColor
        errorPageDescription { en fr }
        errorPageMessage { en fr }
        errorPageButtonText { en fr }
        errorPageShowNavbar
        enableManualUpdateExchangeRates
        enableAutoUpdateExchangeRates
      }
    }
  }
`;

const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization { id }
    }
  }
`;

export const FETCH_LOCALIZATIONS_BY_ORG = gql`
  query fetchLocalizationsByOrganization($organizationId: ObjectID!) {
    fetchLocalizations(input: { filter: { organizationId: $organizationId } } ) {
      localizations {
        id
        name
        countries
        defaultCurrency
      }
    }
  }
`;

export const FETCH_FOREIGN_EXCHANGE_SNAPSHOTS = gql`
  query fetchForeignExchangeSnapshots($input: FetchForeignExchangeSnapshotsInput!){
    fetchForeignExchangeSnapshots(input: $input) {
      foreignExchangeSnapshots {
        id
        date
        from
        to
        rate
      }
    }
  }
`;

export const SAVE_FOREIGN_EXCHANGE_RATES = gql`
  mutation saveForeignExchangeRates($input: SaveForeignExchangeRatesInput!){
    saveForeignExchangeRates(input: $input) {
      rates {
        id
        date
        from
        to
        rate
      }
    }
  }
`;

const PRODUCT_SHELF_AVAILABLE_CURRENCIES = [
  CurrencyCodes.CAD,
  CurrencyCodes.USD,
];

const MULTI_FACTOR_AUTHENTICATION_OPTIONS = [
  'SMS',
  'EMAIL',
  'AUTHENTICATOR',
];

const DEBOUNCE_DELAY = 1000;

const OrgDetails = () => {
  const graphqlClient = useApolloClient();
  const params = useParams();
  const { permissions } = usePermissions();
  const { showToast } = useGlobalToast();
  const { t } = useTranslation(['orgSettings']);

  const [uploadingLogo, setUploadingLogo] = useState(false);
  const [file, setFile] = useState<File>();
  const [errorBackgroundImage, setErrorBackgroundImage] = useState<File | undefined>();
  const [taxRank, setTaxRank] = useState<AccountTypeTaxRank[]>([]);
  const [organization, setOrganization] = useState<Organization>();
  const [originalOrganization, setOriginalOrganization] = useState<Organization>();
  const [localizationId, setLocalizationId] = useState<string>('none');
  const [manualCurrencySelection, setManualCurrencySelection] = useState(true);
  const [manualExchangeRates, setManualExchangeRates] = useState<Map<CurrencyCodes, ManualExchangeRate>>(new Map());

  const [supportUrlError, setSupportUrlError] = useState<string | null>(null);
  const [helpUrlError, setHelpUrlError] = useState<string | null>(null);
  const [logoUrlError, setLogoUrlError] = useState<string | null>(null);

  const { data: localizationsData } = useQuery(FETCH_LOCALIZATIONS_BY_ORG, {
    variables: { organizationId: params.id },
    fetchPolicy: 'no-cache',
    skip: !params.id,
  });

  const { loading } = useQuery(FETCH_ORGANIZATION_DETAILS, {
    variables: { organizationId: params.id },
    fetchPolicy: 'no-cache',
    skip: !params.id,
    onCompleted: (data) => {
      const orgData = omitDeep(data.fetchOrganization?.organization, '__typename') as Organization;
      if (orgData.jurisdictions?.only === null) orgData.jurisdictions.only = undefined;
      setOrganization(orgData);
      setOriginalOrganization(orgData);
      setLocalizationId(orgData.localization?.id ?? 'none');
      setTaxRank((orgData.accountTypeTaxRanks ?? []).map((x: any) => ({ ...x, __typename: undefined })));
    },
  });

  const selectedLocalization: Localization = (localizationsData?.fetchLocalizations.localizations ?? []).find((l: Localization) => l.id === localizationId);

  useEffect(() => {
    if (!selectedLocalization || !organization || manualCurrencySelection || !originalOrganization) return;

    const currentCurrencies = originalOrganization.productShelfCurrencies;
    const localizationCurrency = selectedLocalization.defaultCurrency as CurrencyCodes;

    // Only update if productShelfCurrencies doesn't already has a value.
    if ((!currentCurrencies || !currentCurrencies.length) && localizationCurrency) {
      setManualCurrencySelection(false);
      setOrganization({
        ...organization,
        productShelfCurrencies: [localizationCurrency],
      });
    }
  }, [selectedLocalization, organization, manualCurrencySelection, originalOrganization]);

  const applicableCountries = selectedLocalization
    ? selectedLocalization.countries
    : [CountryCodes.CA];

  const orgBulkTradingProcessList = Object.keys(
    t('bulkTradingProcessOptions', { returnObjects: true }),
  ).map((key) => ({
    value: key.toUpperCase(),
    name: t(`bulkTradingProcessOptions.${key}`),
  }));

  const [updateOrganization, { loading: updating }] = useMutation(UPDATE_ORGANIZATION, {
    variables: {
      input: {
        organizationId: organization?.id,
        name: organization?.name,
        entityName: organization?.entityName,
        parentId: organization?.parent?.id !== originalOrganization?.parent?.id ? organization?.parent?.id : undefined,
        useParentProductShelf: organization?.useParentProductShelf,
        useParentModelPortfolios: organization?.useParentModelPortfolios,
        useParentAssetClasses: organization?.useParentAssetClasses,
        useParentThemes: organization?.useParentThemes,
        useParentSchedules: organization?.useParentSchedules,
        useParentFeeTiers: organization?.useParentFeeTiers,
        useParentBillingSchedules: organization?.useParentBillingSchedules,
        useParentWorkflows: organization?.useParentWorkflows,
        useParentRoleProfiles: organization?.useParentRoleProfiles,
        useParentPageConfigurations: organization?.useParentPageConfigurations,
        useParentStaticPageWidgetConfigurations: organization?.useParentStaticPageWidgetConfigurations,
        useParentCustodianConnections: organization?.useParentCustodianConnections,
        useParentFormTemplates: organization?.useParentFormTemplates,
        useParentIntegrations: organization?.useParentIntegrations,
        useParentLocalizations: organization?.useParentLocalizations,
        useParentThemeTokens: organization?.useParentThemeTokens,
        useParentArticles: organization?.useParentArticles,
        userManagementFeeBps: organization?.userManagementFeeBps ?? 0,
        minInitialDepositCents: organization?.minInitialDepositCents,
        revenueShareBps: organization?.revenueShareBps ?? 0,
        revenueShareTaxes: organization?.revenueShareTaxes ?? 0,
        allowDirectCommunication: organization?.allowDirectCommunication,
        allowInstantInvest: organization?.allowInstantInvest,
        requireFeeApproval: organization?.requireFeeApproval,
        isReferral: organization?.isReferral,
        useClientOrgAuth0: organization?.useClientOrgAuth0,
        reviewTransactions: organization?.reviewTransactions,
        minRecurringDepositCents: organization?.minRecurringDepositCents,
        profileReviewTimeInMonths: organization?.profileReviewTimeInMonths ?? null,
        theme: {
          logo: organization?.theme?.logo,
          sideBarColor: organization?.theme?.sideBarColor,
          authenticationTheme: {
            logo: organization?.theme?.authenticationTheme?.logo,
            primaryColor: organization?.theme?.authenticationTheme?.primaryColor,
            pageBackgroundColor: organization?.theme?.authenticationTheme?.pageBackgroundColor,
          },
        },
        enableMultiFactorAuthentication: organization?.enableMultiFactorAuthentication,
        multiFactorAuthenticationOptions: organization?.multiFactorAuthenticationOptions,
        enableRemeberBrowser: organization?.enableRemeberBrowser,
        enableEmailFrenchTranslation: organization?.enableEmailFrenchTranslation,
        defaultAuthenticationConnection: organization?.defaultAuthenticationConnection,
        authenticationDomains: organization?.authenticationDomains,
        allowInactiveUserRebalancing: organization?.allowInactiveUserRebalancing,
        allowInactiveUserFeeCalculation: organization?.allowInactiveUserFeeCalculation,
        allowInactiveUserAdjustments: organization?.allowInactiveUserAdjustments,
        allowLogout: organization?.allowLogout,
        logoutRedirectUrl: organization?.logoutRedirectUrl === '' ? null : organization?.logoutRedirectUrl,
        enableInactivityTimeOut: organization?.enableInactivityTimeOut,
        inactivityTimeoutInMinutes: organization?.inactivityTimeoutInMinutes || null,
        allowPortfolioPerGoal: organization?.allowPortfolioPerGoal,
        requireManualAccountApproval: organization?.requireManualAccountApproval,
        requireSubTradeRequestApproval: organization?.requireSubTradeRequestApproval,
        allowViewSubTradeRequestPairs: organization?.allowViewSubTradeRequestPairs,
        allowPendingTransactions: organization?.allowPendingTransactions,
        accountTypeTaxRanks: taxRank,
        supportUrl: organization?.supportUrl === '' ? null : organization?.supportUrl,
        helpCentreUrl: organization?.helpCentreUrl === '' ? null : organization?.helpCentreUrl,
        enableContactSupport: organization?.enableContactSupport,
        enableResourcesAndArticles: organization?.enableResourcesAndArticles,
        defaultTradingProcess: organization?.defaultTradingProcess,
        bulkTradingProcess: organization?.bulkTradingProcess,
        defaultSignUpRoleId: organization?.defaultSignUpRole?.id || null,
        defaultAdvisorRoleId: organization?.defaultAdvisorRole?.id || null,
        defaultClientRoleId: organization?.defaultClientRole?.id || null,
        disableStatsUpdateBasedOnTransactions: organization?.disableStatsUpdateBasedOnTransactions,
        excludeAdminFeesFromClients: organization?.excludeAdminFeesFromClients,
        blockSuspiciousTransactions: organization?.blockSuspiciousTransactions,
        allowPostOptimizationEditing: organization?.allowPostOptimizationEditing,
        cashOnHoldToTradeDays: organization?.cashOnHoldToTradeDays,
        cashOnHoldToWithdrawDays: organization?.cashOnHoldToWithdrawDays,
        allowAccessToSubOrganizations: organization?.allowAccessToSubOrganizations,
        displayMenuBar: organization?.displayMenuBar,
        autoInviteImportedUsers: organization?.autoInviteImportedUsers,
        repCode: organization?.repCode,
        localizationId: localizationId !== 'none' ? localizationId : null,
        jurisdictions: organization?.jurisdictions,
        useParentCustomFields: organization?.useParentCustomFields,
        displayCurrency: organization?.displayCurrency,
        themeTokens: organization?.themeTokens,
        customUrl: organization?.customUrl === '' ? null : organization?.customUrl,
        faviconLink: organization?.faviconLink || null,
        browserTabTitle: organization?.browserTabTitle || null,
        minDepositCents: organization?.minDepositCents,
        errorPageMessage: organization?.errorPageMessage,
        errorPageDescription: organization?.errorPageDescription,
        errorPageButtonText: organization?.errorPageButtonText,
        errorPageBackgroundColor: organization?.errorPageBackgroundColor,
        errorPageShowNavbar: organization?.errorPageShowNavbar,
        productShelfCurrencies: organization?.productShelfCurrencies || [CurrencyCodes.CAD],
        enableManualUpdateExchangeRates: organization?.enableManualUpdateExchangeRates ?? false,
        enableAutoUpdateExchangeRates: organization?.enableAutoUpdateExchangeRates ?? false,
      },
    },
    onCompleted: async () => {
      if (file && organization) {
        setUploadingLogo(true);
        const fileUploadProps: FileUploaderProps = {
          file,
          createFileInput: {
            objectType: FileDocumentObjectTypes.ORGANIZATION,
            objectId: organization.id,
            userId: organization.id,
            fileName: file.name,
            type: FileDocumentTypes.LOGO,
            name: file.name,
            mediaType: file.type,
            permissionType: 'PUBLIC',
            sourceType: FileDocumentTypes.LOGO,
          },
          onSuccess: () => {
            showToast({ severity: 'success', message: `Logo uploaded successfully: ${file.name}` });
            setFile(undefined);
          },
          refetchQueries: [FETCH_LOGO],
          apolloClient: graphqlClient,
        };
        await fileUploader(fileUploadProps);
        setUploadingLogo(false);
      }
      if (errorBackgroundImage && organization) {
        setUploadingLogo(true);
        const fileUploadProps: FileUploaderProps = {
          file: errorBackgroundImage,
          createFileInput: {
            objectType: FileDocumentObjectTypes.ORGANIZATION,
            objectId: organization.id,
            userId: organization.id,
            fileName: errorBackgroundImage.name,
            type: FileDocumentTypes.ERROR_PAGE_BACKGROUND_IMAGE,
            name: errorBackgroundImage.name,
            mediaType: errorBackgroundImage.type,
            permissionType: 'PUBLIC',
            sourceType: FileDocumentTypes.ERROR_PAGE_BACKGROUND_IMAGE,
          },
          onSuccess: () => {
            showToast({ severity: 'success', message: `Logo uploaded successfully: ${errorBackgroundImage.name}` });
            setErrorBackgroundImage(undefined);
          },
          refetchQueries: [FETCH_ERROR_PAGE_BACKGROUND_IMAGE],
          apolloClient: graphqlClient,
        };
        await fileUploader(fileUploadProps);
        setUploadingLogo(false);
      }
      showToast({ severity: 'success', message: t('shared:saved') });
    },
    onError: (error) => showToast({ message: error.graphQLErrors[0].message, severity: 'error' }),
    refetchQueries: [
      FETCH_ORGANIZATION_DETAILS,
      FETCH_ORGANIZATION(params.id as string, permissions),
      FETCH_ORGANIZATIONS,
      FETCH_ORGANIZATION_NAME,
    ],
  });

  const { loading: loadingExchangeRates } = useQuery(FETCH_FOREIGN_EXCHANGE_SNAPSHOTS, {
    variables: {
      input: {
        filter: {
          organizationId: params.id,
          dateAfter: dayjs().utc().startOf('day').format('YYYY-MM-DD'),
          from: CurrencyCodes.CAD,
        },
        pagination: { perPage: 10 },
      },
    },
    fetchPolicy: 'no-cache',
    skip: !params.id || loading || !organization?.enableManualUpdateExchangeRates,
    onCompleted: (data) => {
      const rates = data?.fetchForeignExchangeSnapshots?.foreignExchangeSnapshots ?? null;

      if (!rates) return;

      for (const rate of rates) {
        manualExchangeRates.set(rate.to, {
          from: rate.from,
          to: rate.to,
          rate: round(rate.rate * 100, 4), // the rates are not saved as a valueCents
        });
      }

      setManualExchangeRates(new Map(manualExchangeRates));
    },
    onError: (error) => showToast({ message: error.graphQLErrors[0].message, severity: 'error' }),
  });

  const [saveForeignExchangeRates] = useMutation(SAVE_FOREIGN_EXCHANGE_RATES, {
    variables: {
      input: {
        organizationId: params.id,
        rates: Array.from(manualExchangeRates?.values() ?? []).map((item) => ({
          ...item,
          rate: round(item.rate / 100, 4), // the rates are not saved as a valueCents
        })),
      },
    },
    onError: (error) => showToast({ message: error.graphQLErrors[0].message, severity: 'error' }),
    refetchQueries: [FETCH_FOREIGN_EXCHANGE_SNAPSHOTS],
  });

  const isManualExchangeRateValid = (key: CurrencyCodes) => {
    const rate = manualExchangeRates?.get(key)?.rate ?? 0;

    return rate > 0;
  };

  const areManualExchangeRatesValid = () => {
    if (!organization?.enableManualUpdateExchangeRates) return true;

    const notCADCurrencies = getNotCADCurrencies(organization?.productShelfCurrencies ?? []);

    for (const notCADCurrency of notCADCurrencies) {
      if (!isManualExchangeRateValid(notCADCurrency)) return false;
    }

    return true;
  };

  const getNotCADCurrencies = (array: CurrencyCodes[]): CurrencyCodes[] => array.filter((currency: CurrencyCodes) => currency !== CurrencyCodes.CAD);

  const validateUrlMime = async (url: string, mimetype = 'image/png') => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      const contentType = response.headers.get('Content-Type');
      const isPngFile = contentType?.startsWith(mimetype);

      return Boolean(isPngFile);
    } catch (error) {
      return false;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateLogoUrl = useCallback(
    debounce(async (value: string) => {
      let isLogoUrlValid = true;
      if (value && !value.toLowerCase().endsWith('.png')) {
        isLogoUrlValid = await validateUrlMime(value);
      }
      setLogoUrlError(isLogoUrlValid ? null : t('organizationDetails.logoUrlError'));
    }, DEBOUNCE_DELAY),
    [],
  );

  if (loading) return <Skeleton width='100%' height='calc(100vh - 10em)' variant='rectangular' animation='wave' sx={{ borderRadius: '4px' }} />;
  if (!organization) return <></>;

  const areUrlsValid = async () => {
    let isHelpUrlValid = true;
    let isSupportUrlValid = true;
    let isLogoUrlValid = true;

    const logoUrl = organization?.theme?.authenticationTheme?.logo;

    if (organization?.helpCentreUrl) isHelpUrlValid = validateUrl(organization.helpCentreUrl);
    if (organization?.supportUrl) isSupportUrlValid = validateUrl(organization.supportUrl);
    if (logoUrl && !logoUrl.toLowerCase().endsWith('.png')) isLogoUrlValid = await validateUrlMime(logoUrl);

    if (!isSupportUrlValid) setSupportUrlError(t('organizationDetails.urlError'));
    if (!isHelpUrlValid) setHelpUrlError(t('organizationDetails.urlError'));
    if (!isLogoUrlValid) setLogoUrlError(t('organizationDetails.logoUrlError'));

    if (!(isSupportUrlValid && isHelpUrlValid)) showToast({ message: t('organizationDetails.urlError'), severity: 'error' });
    if (!isLogoUrlValid) showToast({ message: t('organizationDetails.logoUrlError'), severity: 'error' });

    const output = isSupportUrlValid && isHelpUrlValid && isLogoUrlValid;
    return output;
  };

  const update = async (event: any) => {
    event.preventDefault();
    const valid = await areUrlsValid();

    if (valid) {
      await updateOrganization();

      if (organization?.enableManualUpdateExchangeRates && manualExchangeRates.size) {
        saveForeignExchangeRates();
      }
    }
  };

  const validMinInitialDepositCents = organization?.minInitialDepositCents && organization.minInitialDepositCents >= 100;
  const validWithdrawalHoldTime = inRange(0, 365 + 1, organization?.cashOnHoldToWithdrawDays ?? 0);
  const validTradingHoldTime = inRange(0, 365 + 1, organization?.cashOnHoldToTradeDays ?? 0);
  const parentOrgValid = organization?.parent?.id !== organization?.id;
  const validMinRecurringDepositCents = !!organization?.minRecurringDepositCents && organization.minRecurringDepositCents >= 100;
  const validJurisdictions = !organization?.jurisdictions
    || (organization.jurisdictions.all === true && (organization.jurisdictions.only ?? []).length === 0)
    || (organization.jurisdictions.all !== true && (organization.jurisdictions.only ?? []).length > 0);
  const validProfileReviewTimeInMonths = organization?.profileReviewTimeInMonths === null || (organization?.profileReviewTimeInMonths ?? 0) > 0;
  const validMinAmountDeposit = !!organization?.minDepositCents && organization.minDepositCents >= 1;

  const allInputsValid = validMinInitialDepositCents && validWithdrawalHoldTime && validTradingHoldTime && parentOrgValid && validMinRecurringDepositCents && validJurisdictions
    && validProfileReviewTimeInMonths;

  const handleCurrencyChange = (event: any) => {
    const selectedCurrencies: CurrencyCodes[] = event.target.value;

    if (!selectedCurrencies.length) return;

    setManualCurrencySelection(true);

    manualExchangeRates.forEach((_, key) => {
      if (!getNotCADCurrencies(selectedCurrencies).includes(key)) manualExchangeRates.delete(key);
    });

    setManualExchangeRates(new Map(manualExchangeRates));

    setOrganization({
      ...organization,
      productShelfCurrencies: selectedCurrencies,
      ...(!getNotCADCurrencies(selectedCurrencies).length && {
        enableManualUpdateExchangeRates: false,
        enableAutoUpdateExchangeRates: false,
      }),
    });
  };

  const handleMfaOptionsChange = (event: any) => {
    const selectedMfaOption = event.target.value;

    setOrganization({
      ...organization,
      multiFactorAuthenticationOptions: selectedMfaOption,
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Stack spacing={2}>
            <Paper>
              <ListItem>
                <Typography fontWeight={'bold'}>{t('organizationDetails.basics')}</Typography>
              </ListItem>
              <ListItem>
                <TextField label={t('organizationDetails.name')} sx={{ width: '100%' }}
                  value={organization.name} onChange={(e) => setOrganization({ ...organization, name: e.target.value })} id='name'
                />
              </ListItem>
              <ListItem>
                <TextField
                  label={t('organizationDetails.entityName')}
                  fullWidth
                  helperText={
                    <Box alignItems='center' display='flex' mt={0.5}>
                      <HelpOutlineIcon sx={{ mr: '4px', fontSize: '16px' }} />
                      <Typography sx={{ fontSize: '14px' }}>{t('organizationDetails.entityNameHelperText')}</Typography>
                    </Box>
                  }
                  FormHelperTextProps={{ sx: { marginLeft: 0 } }}
                  value={organization.entityName ?? undefined}
                  onChange={(e) => {
                    setOrganization({ ...organization, entityName: e.target.value });
                  }}
                />
              </ListItem>
              <ListItem>
                <TextField
                  label={t('organizationDetails.repCode')}
                  fullWidth
                  value={organization?.repCode ?? ''}
                  onChange={(e) => {
                    setOrganization({ ...organization, repCode: e.target.value });
                  }}
                />
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={<Switch checked={organization?.enableContactSupport} onChange={async (e) => setOrganization({ ...organization, enableContactSupport: e.target.checked })} />}
                    label={t('organizationDetails.enableContactSupport')}
                  />
                </FormGroup>
              </ListItem>
              {organization?.enableContactSupport && (<ListItem>
                <TextField
                  label={t('organizationDetails.supportUrl')}
                  fullWidth
                  error={!!supportUrlError}
                  helperText={supportUrlError}
                  value={organization.supportUrl ?? undefined}
                  onChange={(e) => {
                    setOrganization({ ...organization, supportUrl: e.target.value });
                    if (supportUrlError) {
                      const isSupportUrlValid = e.target.value ? validateUrl(e.target.value) : true;
                      setSupportUrlError(isSupportUrlValid ? null : t('organizationDetails.urlError'));
                    }
                  }}
                />
              </ListItem>)}
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={<Switch checked={organization?.enableResourcesAndArticles} onChange={async (e) => setOrganization({ ...organization, enableResourcesAndArticles: e.target.checked })} />}
                    label={t('organizationDetails.enableResourcesAndArticles')}
                  />
                </FormGroup>
              </ListItem>
              {organization?.enableResourcesAndArticles && (<ListItem>
                <TextField
                  label={t('organizationDetails.helpCentreUrl')}
                  error={!!helpUrlError}
                  helperText={helpUrlError}
                  fullWidth
                  value={organization.helpCentreUrl ?? undefined}
                  onChange={(e) => {
                    setOrganization({ ...organization, helpCentreUrl: e.target.value });
                    if (helpUrlError) {
                      const isHelpUrlValid = e.target.value ? validateUrl(e.target.value) : true;
                      setHelpUrlError(isHelpUrlValid ? null : t('organizationDetails.urlError'));
                    }
                  }}
                />
              </ListItem>)}
              <ListItem>
                <TextField
                  label={t('organizationDetails.customUrl')}
                  fullWidth
                  helperText={
                    <Box alignItems='center' display='flex' mt={0.5}>
                      <HelpOutlineIcon sx={{ mr: '4px', fontSize: '16px' }} />
                      <Typography sx={{ fontSize: '14px' }}>{t('organizationDetails.customUrlHelperText')}</Typography>
                    </Box>
                  }
                  FormHelperTextProps={{ sx: { marginLeft: 0 } }}
                  value={organization.customUrl ?? undefined}
                  onChange={(e) => {
                    setOrganization({ ...organization, customUrl: e.target.value });
                  }}
                />
              </ListItem>
              <ListItem>
                <TextField
                  label={t('organizationDetails.faviconLink')}
                  fullWidth
                  value={organization.faviconLink ?? undefined}
                  onChange={(e) => {
                    setOrganization({ ...organization, faviconLink: e.target.value });
                  }}
                />
              </ListItem>

              <ListItem>
                <TextField
                  label={t('organizationDetails.browserTabTitle')}
                  fullWidth
                  value={organization.browserTabTitle ?? undefined}
                  onChange={(e) => {
                    setOrganization({ ...organization, browserTabTitle: e.target.value });
                  }}
                />
              </ListItem>
            </Paper>
            <Paper>
              <ListItem>
                <Typography fontWeight={'bold'}>{t('organizationDetails.inheritance')}</Typography>
              </ListItem>
              <ListItem>
                <OrganizationSelect
                  value={organization.parent?.id}
                  label={t('organizationDetails.parent')}
                  onChange={(event: any) => {
                    setOrganization({ ...organization, parent: { id: event.target.value as string } });
                  }
                  }
                  error={!parentOrgValid}
                />
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={<Switch checked={organization?.useParentProductShelf} onChange={async (e) => setOrganization({ ...organization, useParentProductShelf: e.target.checked })} />}
                    label={t('organizationDetails.useParentProductShelf')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={<Switch checked={organization?.useParentModelPortfolios} onChange={async (e) => setOrganization({ ...organization, useParentModelPortfolios: e.target.checked })} />}
                    label={t('organizationDetails.useParentModelPortfolios')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={<Switch checked={organization?.useParentAssetClasses} onChange={async (e) => setOrganization({ ...organization, useParentAssetClasses: e.target.checked })} />}
                    label={t('organizationDetails.useParentAssetClasses')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={<Switch checked={organization?.useParentFormTemplates} onChange={async (e) => setOrganization({ ...organization, useParentFormTemplates: e.target.checked })} />}
                    label={t('organizationDetails.useParentFormTemplates')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={<Switch checked={organization?.useParentThemes} onChange={async (e) => setOrganization({ ...organization, useParentThemes: e.target.checked })} />}
                    label={t('organizationDetails.useParentThemes')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={<Switch checked={organization?.useParentSchedules} onChange={async (e) => setOrganization({ ...organization, useParentSchedules: e.target.checked })} />}
                    label={t('organizationDetails.useParentSchedules')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={<Switch checked={organization?.useParentFeeTiers} onChange={async (e) => setOrganization({ ...organization, useParentFeeTiers: e.target.checked })} />}
                    label={t('organizationDetails.useParentFeeTiers')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={<Switch checked={organization?.useParentBillingSchedules} onChange={async (e) => setOrganization({ ...organization, useParentBillingSchedules: e.target.checked })} />}
                    label={t('organizationDetails.useParentBillingSchedules')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={<Switch checked={organization?.useParentWorkflows ?? false} onChange={async (e) => setOrganization({ ...organization, useParentWorkflows: e.target.checked })} />}
                    label={t('organizationDetails.useParentWorkflows')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={organization?.useParentPageConfigurations ?? false}
                        onChange={async (e) => setOrganization({ ...organization, useParentPageConfigurations: e.target.checked })}
                      />
                    }
                    label={t('organizationDetails.useParentPageConfigurations')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel label={t('organizationDetails.useParentStaticPageWidgetConfigurations')} control={
                    <Switch checked={organization?.useParentStaticPageWidgetConfigurations ?? false} onChange={(e) => {
                      setOrganization({ ...organization, useParentStaticPageWidgetConfigurations: e.target.checked });
                    }} />
                  } />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={<Switch checked={organization?.useParentRoleProfiles ?? false} onChange={async (e) => setOrganization({ ...organization, useParentRoleProfiles: e.target.checked })} />}
                    label={t('organizationDetails.useParentRoleProfiles')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={organization?.useParentCustodianConnections ?? false}
                        onChange={async (e) => setOrganization({ ...organization, useParentCustodianConnections: e.target.checked })}
                      />
                    }
                    label={t('organizationDetails.useParentCustodianConnections')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={organization?.useParentCustomFields ?? false}
                        onChange={async (e) => setOrganization({ ...organization, useParentCustomFields: e.target.checked })}
                      />
                    }
                    label={t('organizationDetails.useParentCustomFields')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={<Switch checked={organization?.useParentIntegrations ?? false} onChange={async (e) => setOrganization({ ...organization, useParentIntegrations: e.target.checked })} />}
                    label={t('organizationDetails.useParentIntegrations')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={<Switch checked={organization?.useParentLocalizations ?? false} onChange={async (e) => setOrganization({ ...organization, useParentLocalizations: e.target.checked })} />}
                    label={t('organizationDetails.useParentLocalizations')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={<Switch checked={organization?.useParentThemeTokens ?? false} onChange={async (e) => setOrganization({ ...organization, useParentThemeTokens: e.target.checked })} />}
                    label={t('organizationDetails.useParentThemeTokens')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={organization?.useParentArticles ?? false}
                        onChange={async (event) => setOrganization({ ...organization, useParentArticles: event.target.checked })}
                      />
                    }
                    label={t('organizationDetails.useParentArticles')}
                  />
                </FormGroup>
              </ListItem>
            </Paper>
            <Paper>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={<Switch checked={organization?.isReferral ?? false} onChange={async (e) => setOrganization({ ...organization, isReferral: e.target.checked })} />}
                    label={t('organizationDetails.isReferral')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={<Switch checked={organization?.useClientOrgAuth0 ?? false} onChange={async (e) => setOrganization({ ...organization, useClientOrgAuth0: e.target.checked })} />}
                    label={t('organizationDetails.useClientOrgAuth0')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={
                      <Switch checked={organization?.enableEmailFrenchTranslation ?? false} onChange={async (e) => setOrganization({ ...organization, enableEmailFrenchTranslation: e.target.checked })}
                      />}
                    label={t('organizationDetails.enableEmailFrenchTranslation')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={
                      <Switch checked={organization?.allowDirectCommunication ?? false} onChange={async (e) => setOrganization({ ...organization, allowDirectCommunication: e.target.checked })} />
                    }
                    label={t('organizationDetails.allowDirectCommunication')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={<Switch checked={organization?.reviewTransactions ?? false} onChange={async (event) => setOrganization({ ...organization, reviewTransactions: event.target.checked })} />}
                    label={t('organizationDetails.reviewTransactions')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={organization?.blockSuspiciousTransactions ?? false}
                        onChange={async (event) => setOrganization({ ...organization, blockSuspiciousTransactions: event.target.checked })}
                      />
                    }
                    label={t('organizationDetails.blockSuspiciousTransactions')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={organization?.allowPostOptimizationEditing ?? false}
                        onChange={async (event) => setOrganization({ ...organization, allowPostOptimizationEditing: event.target.checked })}
                      />
                    }
                    label={t('organizationDetails.allowPostOptimizationEditing')}
                  />
                </FormGroup>
              </ListItem>
            </Paper>
            <Paper>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={organization?.allowInactiveUserAdjustments ?? false}
                        onChange={async (event) => setOrganization({ ...organization, allowInactiveUserAdjustments: event.target.checked })}
                      />
                    }
                    label={t('organizationDetails.allowInactiveUserAdjustments')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={organization?.allowInactiveUserFeeCalculation ?? false}
                        onChange={async (event) => setOrganization({ ...organization, allowInactiveUserFeeCalculation: event.target.checked })}
                      />
                    }
                    label={t('organizationDetails.allowInactiveUserFeeCalculation')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={organization?.allowInactiveUserRebalancing ?? false}
                        onChange={async (event) => setOrganization({ ...organization, allowInactiveUserRebalancing: event.target.checked })}
                      />
                    }
                    label={t('organizationDetails.allowInactiveUserRebalancing')}
                  />
                </FormGroup>
              </ListItem>
            </Paper>
            <Paper>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={<Switch checked={organization?.allowInstantInvest ?? false} onChange={async (e) => setOrganization({ ...organization, allowInstantInvest: e.target.checked })} />}
                    label={t('organizationDetails.allowInstantInvest')}
                  />
                </FormGroup>
              </ListItem>

              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={<Switch checked={organization?.allowPortfolioPerGoal ?? false} onChange={async (e) => setOrganization({ ...organization, allowPortfolioPerGoal: e.target.checked })} />}
                    label={t('organizationDetails.allowPortfolioPerGoal')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={organization?.requireManualAccountApproval ?? false}
                        onChange={async (event) => setOrganization({ ...organization, requireManualAccountApproval: event.target.checked })}
                      />
                    }
                    label={t('organizationDetails.requireManualAccountApproval')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={organization?.requireSubTradeRequestApproval ?? false}
                        onChange={async (event) => setOrganization({ ...organization, requireSubTradeRequestApproval: event.target.checked })}
                      />
                    }
                    label={t('organizationDetails.requireSubTradeRequestApproval')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={organization?.allowViewSubTradeRequestPairs ?? false}
                        onChange={async (event) => setOrganization({ ...organization, allowViewSubTradeRequestPairs: event.target.checked })}
                      />
                    }
                    label={t('organizationDetails.allowViewSubTradeRequestPairs')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={organization?.allowPendingTransactions ?? false}
                        onChange={async (event) => setOrganization({ ...organization, allowPendingTransactions: event.target.checked })}
                      />
                    }
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {t('organizationDetails.allowPendingTransactions')}
                        <BetaBadge variant='small' sx={{ ml: 1 }} />
                      </Box>
                    }
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={organization?.disableStatsUpdateBasedOnTransactions ?? false}
                        onChange={async (event) => setOrganization({ ...organization, disableStatsUpdateBasedOnTransactions: event.target.checked })}
                      />
                    }
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {t('organizationDetails.disableStatsUpdateBasedOnTransactions')}
                      </Box>
                    }
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!(organization?.excludeAdminFeesFromClients ?? false)}
                        onChange={async (event) => setOrganization({ ...organization, excludeAdminFeesFromClients: !event.target.checked })}
                      />
                    }
                    label={t('organizationDetails.chargeAdminFeesToClient')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={<Switch checked={organization?.requireFeeApproval ?? false} onChange={async (event) => setOrganization({ ...organization, requireFeeApproval: event.target.checked })} />}
                    label={t('organizationDetails.requireFeeApproval')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <AmountField
                  label={t('organizationDetails.minimumInitialDeposit')}
                  fullWidth
                  value={organization.minInitialDepositCents}
                  onChange={(e: any) => setOrganization({ ...organization, minInitialDepositCents: e.target.valueCents })}
                  error={!validMinInitialDepositCents}
                  helperText={!validMinInitialDepositCents && t('organizationDetails.minimumInitialDeposit1Dollar')}
                />
              </ListItem>
              <ListItem>
                <AmountField
                  label={t('organizationDetails.minRecurringDepositCents')}
                  fullWidth
                  value={organization.minRecurringDepositCents}
                  onChange={(e: any) => setOrganization({ ...organization, minRecurringDepositCents: e.target.valueCents })}
                  error={!validMinRecurringDepositCents}
                  helperText={!validMinRecurringDepositCents && t('organizationDetails.minRecurringDepositError')}
                />
              </ListItem>
              <ListItem>
                <AmountField
                  label={t('organizationDetails.minAmountDeposit')}
                  fullWidth
                  value={organization.minDepositCents}
                  onChange={(e: any) => setOrganization({ ...organization, minDepositCents: e.target.valueCents })}
                  error={!validMinAmountDeposit}
                  helperText={!validMinAmountDeposit && t('organizationDetails.minAmountDepositError')}
                />
              </ListItem>
              <ListItem>
                <TextField
                  select
                  fullWidth
                  value={organization?.defaultTradingProcess ?? ORG_TRADING_PROCESS_LIST[0].value}
                  label={t('organizationDetails.defaultTradingProcess')}
                  onChange={(e) => setOrganization({ ...organization, defaultTradingProcess: e.target.value })}
                >
                  {ORG_TRADING_PROCESS_LIST.map((x: any) => (
                    <MenuItem key={x.value} value={x.value}>
                      {x.name}
                    </MenuItem>
                  ))}
                </TextField>
              </ListItem>
              <ListItem>
                <TextField
                  select
                  fullWidth
                  value={organization?.bulkTradingProcess ?? orgBulkTradingProcessList[0].value}
                  label={t('organizationDetails.bulkTradingProcess')}
                  onChange={(e) => setOrganization({ ...organization, bulkTradingProcess: e.target.value })}
                >
                  {orgBulkTradingProcessList.map((x: any) => (
                    <MenuItem key={x.value} value={x.value}>
                      {x.name}
                    </MenuItem>
                  ))}
                </TextField>
              </ListItem>
              <ListItem>
                <NumberField
                  label={t('organizationDetails.cashOnHoldToWithdrawDays')}
                  fullWidth
                  decimalPlaces={0}
                  value={organization?.cashOnHoldToWithdrawDays}
                  setNumberValue={(value) => setOrganization({ ...organization, cashOnHoldToWithdrawDays: value })}
                  inputError={!validWithdrawalHoldTime}
                  errorText={t('organizationDetails.holdTimeRange')}
                />
              </ListItem>
              <ListItem>
                <NumberField
                  label={t('organizationDetails.cashOnHoldToTradeDays')}
                  fullWidth
                  decimalPlaces={0}
                  value={organization?.cashOnHoldToTradeDays}
                  setNumberValue={(value) => setOrganization({ ...organization, cashOnHoldToTradeDays: value })}
                  inputError={!validTradingHoldTime}
                  errorText={t('organizationDetails.holdTimeRange')}
                />
              </ListItem>
              <ListItem>
                <RoleSelect
                  value={organization?.defaultSignUpRole?.id ?? ''}
                  label={t('organizationDetails.defaultSignUpRole')}
                  onChange={(event: any) => setOrganization({ ...organization, defaultSignUpRole: { id: event.target.value as string } })}
                  organizationId={organization.id}
                  includeEmpty
                />
              </ListItem>
              <ListItem>
                <RoleSelect
                  value={organization?.defaultAdvisorRole?.id ?? ''}
                  label={t('organizationDetails.defaultAdvisorRole')}
                  onChange={(event: any) => setOrganization({ ...organization, defaultAdvisorRole: { id: event.target.value as string } })}
                  organizationId={organization.id}
                  includeEmpty
                />
              </ListItem>
              <ListItem>
                <RoleSelect
                  value={organization?.defaultClientRole?.id ?? ''}
                  label={t('organizationDetails.defaultClientRole')}
                  onChange={(event: any) => setOrganization({ ...organization, defaultClientRole: { id: event.target.value as string } })}
                  organizationId={organization.id}
                  includeEmpty
                />
              </ListItem>
              <ListItem>
                <NumberField
                  label={t('organizationDetails.profileReviewTimeInMonths')}
                  fullWidth
                  decimalPlaces={0}
                  value={organization?.profileReviewTimeInMonths ?? undefined}
                  setNumberValue={(value) => setOrganization({ ...organization, profileReviewTimeInMonths: value })}
                  inputError={!validProfileReviewTimeInMonths}
                  errorText={t('organizationDetails.profileReviewTimeInMonthsError')}
                />
              </ListItem>
              <ListItem>
                <TextField
                  select
                  fullWidth
                  label={t('organizationDetails.productShelfCurrencies')}
                  value={organization?.productShelfCurrencies || [CurrencyCodes.CAD]}
                  onChange={handleCurrencyChange}
                  SelectProps={{
                    multiple: true,
                    renderValue: (selected: any) => (
                      <Box sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 0.5,
                      }}>
                        {selected.map((value: string) => (
                          <Chip
                            key={value}
                            label={value}
                            size="small"
                          />
                        ))}
                      </Box>
                    ),
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          maxHeight: 200,
                          mt: 1,
                        },
                      },
                    },
                  }}
                >
                  {PRODUCT_SHELF_AVAILABLE_CURRENCIES.map((currency) => (
                    <MenuItem
                      key={currency}
                      value={currency}
                      disabled={organization?.productShelfCurrencies?.length === 1
                        && organization?.productShelfCurrencies?.includes(currency)}
                    >
                      {currency}
                    </MenuItem>
                  ))}
                </TextField>
              </ListItem>

              {!!getNotCADCurrencies(organization?.productShelfCurrencies ?? []).length && (
                <>
                  <ListItem>
                    <Grid container alignItems='center' xs={12}>
                      <Grid item xs={12} sx={{ pb: 1 }}>
                        <Typography>{t('exchangeRatesConfiguration.title')}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <FormGroup aria-label='position' row>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={organization?.enableManualUpdateExchangeRates ?? false}
                                onChange={async (event) => setOrganization({ ...organization, enableManualUpdateExchangeRates: event.target.checked })}
                              />
                            }
                            label={t('exchangeRatesConfiguration.manualUpdate')}
                            labelPlacement='end'
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={6} sx={{ justifyItems: 'right' }}>
                        <FormGroup aria-label='position' row>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={organization?.enableAutoUpdateExchangeRates ?? false}
                                onChange={async (event) => setOrganization({ ...organization, enableAutoUpdateExchangeRates: event.target.checked })}
                              />
                            }
                            label={t('exchangeRatesConfiguration.autoUpdate')}
                            labelPlacement='end'
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </ListItem>

                  {!!organization?.enableManualUpdateExchangeRates && (
                    <>
                      {loadingExchangeRates && (
                        <Paper sx={{ m: 2, mt: 0, textAlign: 'center' }}>
                          <CircularProgress sx={{ m: 3 }} />
                        </Paper>
                      )}

                      {!loadingExchangeRates && (
                        <Paper sx={{ m: 2, mt: 0 }}>
                          {getNotCADCurrencies(organization?.productShelfCurrencies ?? []).map((currency) => (
                            <ListItem>
                              <Grid container alignItems='center' xs={12}>
                                <Grid item xs={4}>
                                  <Typography>{t('exchangeRatesConfiguration.fromTo', { from: CurrencyCodes.CAD, to: currency })}</Typography>
                                </Grid>

                                <Grid item xs={8}>
                                  <AmountField
                                    fullWidth
                                    decimalPlaces={4}
                                    value={manualExchangeRates.get(currency)?.rate ?? 0}
                                    onChange={(e: any) => {
                                      manualExchangeRates.set(currency, {
                                        from: CurrencyCodes.CAD,
                                        to: currency,
                                        rate: e.target.valueCents,
                                      });

                                      setManualExchangeRates(new Map(manualExchangeRates));
                                    }}
                                    error={!isManualExchangeRateValid(currency)}
                                    helperText={!isManualExchangeRateValid(currency) && t('exchangeRatesConfiguration.rateError')}
                                  />
                                </Grid>
                              </Grid>
                            </ListItem>
                          ))}
                        </Paper>
                      )}
                    </>
                  )}
                </>
              )}
            </Paper>
            <Paper>
              <ListItem>
                <NumberField
                  label={t('organizationDetails.userManagementFeeBps')}
                  fullWidth
                  decimalPlaces={2}
                  value={organization?.userManagementFeeBps ?? undefined}
                  setNumberValue={(value) => setOrganization({ ...organization, userManagementFeeBps: value })}
                />
              </ListItem>
              <ListItem>
                <NumberField
                  label={t('organizationDetails.revenueShareBps')}
                  fullWidth
                  decimalPlaces={2}
                  value={organization?.revenueShareBps ?? undefined}
                  setNumberValue={(value) => setOrganization({ ...organization, revenueShareBps: value })}
                />
              </ListItem>
              <ListItem>
                <NumberField
                  label={t('organizationDetails.revenueShareTaxes')}
                  fullWidth
                  decimalPlaces={2}
                  value={organization?.revenueShareTaxes ?? undefined}
                  setNumberValue={(value) => setOrganization({ ...organization, revenueShareTaxes: value })}
                />
              </ListItem>
            </Paper>
          </Stack>
        </Grid>
        <Grid item md={6} xs={12}>
          <Stack spacing={2}>
            <Paper>
              <ListItem>
                <Typography fontWeight={'bold'}>{t('organizationDetails.localization')}</Typography>
              </ListItem>
              <ListItem>
                <TextField
                  select
                  fullWidth
                  label={t('organizationDetails.localization')}
                  value={localizationId}
                  onChange={(e) => {
                    setManualCurrencySelection(false);
                    setLocalizationId(e.target.value);
                  }
                  }
                >
                  <MenuItem value='none'><i>{t('organizationDetails.noLocalization')}</i></MenuItem>
                  {localizationsData?.fetchLocalizations.localizations.map((l: any) => <MenuItem key={l.id} value={l.id}>
                    {l.name} &nbsp; &nbsp;
                    <Box component="span" color='gray'>({t('organizationDetails.country')}: {l.countries.join(', ')} – {t('organizationDetails.currency')}: {l.defaultCurrency})</Box>
                  </MenuItem>)}
                </TextField>
              </ListItem>
              <EditOrganizationJurisdictions
                organization={organization}
                setOrganization={setOrganization}
                selectedLocalization={selectedLocalization}
              />
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={<Switch
                      checked={organization?.displayCurrency ?? false}
                      onChange={async (event) => setOrganization({ ...organization, displayCurrency: event.target.checked })}
                    />}
                    label={t('organizationDetails.displayCurrency')}
                  />
                </FormGroup>
              </ListItem>
            </Paper>
            <Paper>
              <ListItem>
                <Typography fontWeight={'bold'}>{t('organizationDetails.theme')}</Typography>
              </ListItem>
              <ListItem>
                <ColorPickerField
                  label={t('organizationDetails.topBarColor')}
                  value={organization?.themeTokens?.comp?.layout?.backgroundColor ?? ''}
                  onChange={(e) => setOrganization(
                    {
                      ...organization,
                      themeTokens: {
                        ...organization.themeTokens,
                        comp: {
                          ...organization.themeTokens?.comp,
                          layout: {
                            ...organization.themeTokens?.comp?.layout,
                            backgroundColor: e,
                          },
                        },
                      },
                    },
                  )}
                />
              </ListItem>
              <ListItem>
                <ColorPickerField
                  label={t('organizationDetails.topBarTextColor')}
                  value={organization?.themeTokens?.comp?.layout?.textColor ?? ''}
                  onChange={(e) => setOrganization(
                    {
                      ...organization,
                      themeTokens: {
                        ...organization.themeTokens,
                        comp: {
                          ...organization.themeTokens?.comp,
                          layout: {
                            ...organization.themeTokens?.comp?.layout,
                            textColor: e,
                          },
                        },
                      },
                    },
                  )}
                />
              </ListItem>
              <ListItem>
                <ColorPickerField
                  label={t('organizationDetails.primaryColor')}
                  value={organization?.themeTokens?.ref?.palette?.primary50 ?? ''}
                  onChange={(e) => setOrganization(
                    {
                      ...organization,
                      themeTokens: {
                        ...organization.themeTokens,
                        ref: {
                          ...organization.themeTokens?.ref,
                          palette: {
                            ...organization.themeTokens?.ref?.palette,
                            primary50: e,
                          },
                        },
                      },
                    },
                  )}
                />
              </ListItem>
              <ListItem>
                <ColorPickerField
                  label={t('organizationDetails.pageBackgroundColor')}
                  value={organization?.themeTokens?.sys?.color?.background ?? ''}
                  onChange={(e) => setOrganization(
                    {
                      ...organization,
                      themeTokens: {
                        ...organization.themeTokens,
                        sys: {
                          ...organization.themeTokens?.sys,
                          color: {
                            ...organization.themeTokens?.sys?.color,
                            background: e,
                          },
                        },
                      },
                    },
                  )}
                />
              </ListItem>
              <ListItem>
                <EditOrganizationLogo
                  title={t('organizationDetails.uploadLogo')}
                  organizationId={organization.id}
                  onFileChosen={(dropFile: File | undefined) => {
                    setFile(dropFile);
                  }}
                  onFileDeleted={() => setFile(undefined)}
                />
              </ListItem>
            </Paper>
            <Paper>
              <TaxIdDetails applicableCountries={applicableCountries} />
            </Paper>
            <Paper>
              <ListItem>
                <Typography fontWeight={'bold'}>{t('organizationDetails.authenticationTitle')}</Typography>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={organization?.enableMultiFactorAuthentication ?? false}
                        onChange={async (event) => setOrganization({ ...organization, enableMultiFactorAuthentication: event.target.checked })}
                      />
                    }
                    label={t('organizationDetails.enableMultiFactorAuthentication')}
                  />
                </FormGroup>
              </ListItem>
              {organization?.enableMultiFactorAuthentication && (
                <>
                  <ListItem>
                    <TextField
                      select
                      fullWidth
                      label={t('organizationDetails.multiFactorAuthenticationTitle')}
                      value={organization?.multiFactorAuthenticationOptions && organization?.multiFactorAuthenticationOptions.length ? organization?.multiFactorAuthenticationOptions : ['SMS']}
                      onChange={handleMfaOptionsChange}
                      SelectProps={{
                        multiple: true,
                        renderValue: (selected: any) => (
                          <Box sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 0.5,
                          }}>
                            {selected.map((value: string) => (
                              <Chip
                                key={value}
                                label={t(`organizationDetails.multiFactorAuthenticationOptions.${value}`)}
                                size="small"
                              />
                            ))}
                          </Box>
                        ),
                        MenuProps: {
                          PaperProps: {
                            sx: {
                              maxHeight: 200,
                              mt: 1,
                            },
                          },
                        },
                      }}
                    >
                      {MULTI_FACTOR_AUTHENTICATION_OPTIONS.map((mfaOption: any) => (
                        <MenuItem
                          key={mfaOption}
                          value={mfaOption}
                          disabled={organization?.multiFactorAuthenticationOptions?.length === 1
                            && organization?.multiFactorAuthenticationOptions?.includes(mfaOption)}
                        >
                          {t(`organizationDetails.multiFactorAuthenticationOptions.${mfaOption}`)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </ListItem>
                  <ListItem>
                    <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                      <FormControlLabel
                        control={
                          <Switch
                          checked={organization?.enableRemeberBrowser ?? false}
                          onChange={async (event) => setOrganization({ ...organization, enableRemeberBrowser: event.target.checked })}
                          />
                        }
                        label={t('organizationDetails.enableRemeberBrowser')}
                        />
                    </FormGroup>
                  </ListItem>
                </>
              )}
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={organization?.allowAccessToSubOrganizations ?? false}
                        onChange={async (event) => setOrganization({ ...organization, allowAccessToSubOrganizations: event.target.checked })}
                      />
                    }
                    label={t('organizationDetails.allowAccessToSubOrganizations')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={<Switch
                      checked={organization?.displayMenuBar ?? false}
                      onChange={async (event) => setOrganization({ ...organization, displayMenuBar: event.target.checked })}
                    />}
                    label={t('organizationDetails.displayMenuBar')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={<Switch
                      checked={organization?.allowLogout ?? true}
                      onChange={async (event) => setOrganization({ ...organization, allowLogout: event.target.checked })}
                    />}
                    label={t('organizationDetails.allowLogout')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={<Switch
                      checked={organization?.enableInactivityTimeOut ?? false}
                      onChange={async (event) => setOrganization({ ...organization, enableInactivityTimeOut: event.target.checked })}
                    />}
                    label={t('organizationDetails.enableInactivityTimeOut')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <TextField
                  label={t('organizationDetails.logoutRedirectUrl')}
                  fullWidth
                  helperText={
                    <Box alignItems='center' display='flex' mt={0.5}>
                      <HelpOutlineIcon sx={{ mr: '4px', fontSize: '16px' }} />
                      <Typography sx={{ fontSize: '14px' }}>{t('organizationDetails.customRedirectUrlHelperText')}</Typography>
                    </Box>
                  }
                  value={organization.logoutRedirectUrl ?? ''}
                  onChange={(e) => setOrganization({ ...organization, logoutRedirectUrl: e.target.value })}
                />
              </ListItem>
              <ListItem>
                <NumberField
                  label={t('organizationDetails.inactivityTimeoutInMinutes')}
                  fullWidth
                  decimalPlaces={2}
                  value={organization?.inactivityTimeoutInMinutes ?? undefined}
                  setNumberValue={(value) => setOrganization({ ...organization, inactivityTimeoutInMinutes: value })}
                />
              </ListItem>
              <ListItem>
                <TextField
                  select
                  fullWidth
                  value={organization?.defaultAuthenticationConnection ?? AuthenticationConnections.EMAIL_AND_PASSWORD}
                  label={t('organizationDetails.defaultAuthenticationConnectionTitle')}
                  onChange={(e) => setOrganization({ ...organization, defaultAuthenticationConnection: e.target.value as AuthenticationConnections })}
                >
                  {Object.values(AuthenticationConnections).map((x: any) => (
                    <MenuItem key={x} value={x}>
                      {t(`organizationDetails.defaultAuthenticationConnectionOptions.${x}`)}
                    </MenuItem>
                  ))}
                </TextField>
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={organization?.autoInviteImportedUsers ?? false}
                        onChange={async (event) => setOrganization({ ...organization, autoInviteImportedUsers: event.target.checked })}
                      />
                    }
                    label={t('organizationDetails.autoInviteImportedUsers')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <TextField
                  label={t('organizationDetails.authenticationTheme.logo')}
                  fullWidth
                  value={organization.theme?.authenticationTheme?.logo ?? ''}
                  error={!!logoUrlError}
                  helperText={logoUrlError}
                  onChange={(e) => {
                    const authenticationTheme = { ...organization?.theme?.authenticationTheme, logo: e.target.value };
                    setOrganization({ ...organization, theme: { ...organization.theme, authenticationTheme } });

                    if (logoUrlError) validateLogoUrl(e.target.value);
                  }}
                />
              </ListItem>
              <ListItem>
                <ColorPickerField
                  label={t('organizationDetails.authenticationTheme.primaryColor')}
                  value={organization.theme?.authenticationTheme?.primaryColor ?? ''}
                  onChange={(e) => setOrganization({
                    ...organization,
                    theme: { ...organization.theme, authenticationTheme: { ...organization?.theme?.authenticationTheme, primaryColor: e } },
                  })
                  }
                />
              </ListItem>
              <ListItem>
                <ColorPickerField
                  label={t('organizationDetails.authenticationTheme.pageBackgroundColor')}
                  value={organization.theme?.authenticationTheme?.pageBackgroundColor ?? ''}
                  onChange={(e) => setOrganization({
                    ...organization,
                    theme: { ...organization.theme, authenticationTheme: { ...organization?.theme?.authenticationTheme, pageBackgroundColor: e } },
                  })
                  }
                />
              </ListItem>
              <ListItem sx={{ mb: 2 }}>
                <AuthenticationDomainsTable organization={organization} setOrganization={setOrganization} />
              </ListItem>
            </Paper>
            {applicableCountries.includes(CountryCodes.CA) && (
              <Paper>
                <ListItem>
                  <Typography fontWeight={'bold'}>{t('organizationDetails.accountTypeTaxRanking')}</Typography>
                </ListItem>
                <AccountTaxRanks taxRanks={taxRank} setTaxRank={setTaxRank} />
              </Paper>
            )}
            <Paper>
              <ListItem>
                <Typography fontWeight={'bold'}>{t('organizationDetails.errorPage')}</Typography>
              </ListItem>
              <ListItem>
                <TranslatableString
                  label={t('organizationDetails.errorMessage')}
                  value={organization?.errorPageMessage ?? {}}
                  onChange={(value) => setOrganization({ ...organization, errorPageMessage: value })}
                />
              </ListItem>
              <ListItem>
                <TranslatableString
                  label={t('organizationDetails.errorDescription')}
                  value={organization?.errorPageDescription ?? {}}
                  onChange={(value) => setOrganization({ ...organization, errorPageDescription: value })}
                />
              </ListItem>
              <ListItem>
                <TranslatableString
                  label={t('organizationDetails.errorButtonText')}
                  value={organization?.errorPageButtonText ?? {}}
                  onChange={(value) => setOrganization({ ...organization, errorPageButtonText: value })}
                />
              </ListItem>
              <ListItem>
                <ColorPickerField
                  label={'Background color'}
                  value={organization?.errorPageBackgroundColor ?? ''}
                  onChange={(e) => setOrganization({
                    ...organization,
                    errorPageBackgroundColor: e,
                  })}
                />
              </ListItem>
              <ListItem>
                <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={organization?.errorPageShowNavbar ?? false}
                        onChange={async (event) => setOrganization({ ...organization, errorPageShowNavbar: event.target.checked })}
                      />
                    }
                    label={t('organizationDetails.displayNavbar')}
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <EditOrganizationErrorPageBackgroundImage
                  title={t('organizationDetails.uploadErrorPageBackgroundImage')}
                  organizationId={organization.id}
                  onFileChosen={(dropFile: File | undefined) => {
                    setErrorBackgroundImage(dropFile);
                  }}
                  onFileDeleted={() => setErrorBackgroundImage(undefined)}
                />
              </ListItem>
            </Paper>
          </Stack>
        </Grid>
      </Grid >
      <Button
        sx={{ my: 2 }}
        fullWidth
        label={t('organizationDetails.saveChanges')}
        onClick={update}
        disabled={!allInputsValid || loading || updating || uploadingLogo || !areManualExchangeRatesValid()}
      />
    </>
  );
};

const TaxIdDetails = ({ applicableCountries }: { applicableCountries: CountryCodes[] }) => {
  const { t } = useTranslation(['orgSettings']);

  return <>
    <ListItem>
      <Typography fontWeight={'bold'}>{t('organizationDetails.taxIdDetails')}</Typography>
    </ListItem>
    <ListItem>
      <Table>
        <TableRow>
          <TableHeadCell>{t('shared:country')}</TableHeadCell>
          <TableHeadCell>{t('organizationDetails.clientType')}</TableHeadCell>
          <TableHeadCell>{t('shared:type')}</TableHeadCell>
          <TableHeadCell>{t('organizationDetails.formatting')}</TableHeadCell>
        </TableRow>
        {applicableCountries.map((country) => {
          const typesIndividual = eligibleTaxIdTypes(country, true);
          const typesNonIndividual = eligibleTaxIdTypes(country, false);
          const count = typesIndividual.length + typesNonIndividual.length;

          return (count > 0)
            ? <>
              {typesIndividual.map((type, index) => <TableRow key={index}>
                {index === 0 && <TableCell rowSpan={count}>{getCountryName(country)}</TableCell>}
                {index === 0 && <TableCell rowSpan={typesIndividual.length}>{t('entityTypes:INDIVIDUAL')}</TableCell>}
                <TableCell>{t(`taxId:${type}`)} ({t(`taxId:abbreviated.${type}`)})</TableCell>
                <TableCell>{taxIdFormattingMask(type)}</TableCell>
              </TableRow>)}
              {typesNonIndividual.map((type, index) => <TableRow key={index}>
                {index === 0 && <TableCell rowSpan={typesNonIndividual.length}>{t('entityTypes:NON_INDIVIDUAL')}</TableCell>}
                <TableCell>{t(`taxId:${type}`)} ({t(`taxId:abbreviated.${type}`)})</TableCell>
                <TableCell>{taxIdFormattingMask(type)}</TableCell>
              </TableRow>)}
            </>
            : <>
              <TableRow>
                <TableCell>{getCountryName(country)}</TableCell>
                <TableCell colSpan={3} sx={{ textAlign: 'center' }} align='center'><i>{t('organizationDetails.notImplemented')}</i></TableCell>
              </TableRow>
            </>;
        })
        }
      </Table>
    </ListItem>
  </>;
};

export default OrgDetails;

export { OrgDetailsBreadcrumb } from './orgDetailsBreadcrumb';

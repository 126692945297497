import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Typography } from '../../../1-primative';
import {
  Button, TextField, TranslatableTextField, DroppableFileInput, FileTypes,
} from '../../../2-component';
import { TranslatedString } from '../../../../interfaces';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';

export interface Article {
  id?: string;
  articleUrl: string;
  translatedName: TranslatedString;
  translatedDescription: TranslatedString;
}

export const CreateAndEditNewsAndInsightsForm = ({
  imageSrc, resetFileImageAndSrc, article, setArticleToUpdate, errorFields, disabled = false, handleFileInputChange, handleValidationClear,
}: {
  imageSrc: string | null; resetFileImageAndSrc: any; article: Article; setArticleToUpdate: any; errorFields: string[]; disabled?: boolean;
  handleFileInputChange: (file: File) => void; handleValidationClear: (value: string, field: string) => void;
}) => {
  const { t } = useTranslation('newsAndInsight');
  const { sys } = useThemeTokens();

  return (
    <>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Typography variant='bodyLarge' mb={1}>{t('newsAndInsight:imageOptional')}</Typography>
        {imageSrc && (
          <Button size='sm' sx={{ mb: 1 }} disabled={disabled} onClick={resetFileImageAndSrc} variant='text' label={t('newsAndInsight:remove')} trailingIcon={DeleteIcon}
            dataTestId='news-and-insights-delete-button' />
        )}
      </Box>
      {!imageSrc && <DroppableFileInput acceptedTypes={[FileTypes.PNG, FileTypes.JPG, FileTypes.JPEG]} onFileChosen={handleFileInputChange} testId='news-and-insights-image' disabled={disabled} />}
      {imageSrc && <img src={imageSrc} alt='articleImage' width='100%' />}
      <TranslatableTextField fullWidth
        testId='news-and-insights-title'
        label={t('newsAndInsight:title')}
        sx={{ marginTop: sys.spacing.xxl, marginBottom: sys.spacing.xxl }}
        value={article.translatedName}
        disabled={disabled}
        error={errorFields.includes('translatedName')}
        errorText={t('newsAndInsight:errorStateMessage')}
        onChange={(translatedString: TranslatedString) => {
          handleValidationClear(translatedString.en ?? '', 'translatedName');
          setArticleToUpdate({ ...article, translatedName: translatedString });
        }}
      />
      <TextField fullWidth
        testId='news-and-insights-link-to-article'
        label={t('newsAndInsight:linkToArticle')}
        sx={{ marginBottom: sys.spacing.xxl }}
        value={article.articleUrl}
        disabled={disabled}
        error={errorFields.includes('articleUrl')}
        errorText={t('newsAndInsight:errorUrlStateMessage')}
        onChange={(e: any) => {
          handleValidationClear(e.target.value, 'articleUrl');
          setArticleToUpdate({ ...article, articleUrl: e.target.value });
        }}
      />
      <TranslatableTextField fullWidth multiline rows={4}
        testId='news-and-insights-summary'
        label={t('newsAndInsight:summary')}
        sx={{ marginBottom: sys.spacing.xxl }}
        value={article.translatedDescription}
        disabled={disabled}
        error={errorFields.includes('translatedDescription')}
        errorText={t('newsAndInsight:errorStateMessage')}
        onChange={(translatedString: TranslatedString) => {
          handleValidationClear(translatedString.en ?? '', 'translatedDescription');
          setArticleToUpdate({ ...article, translatedDescription: translatedString });
        }}
      />
    </>
  );
};

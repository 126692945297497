import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '../../../../../1-primative';
import {
  Button, Dialog, Form, MenuItem, SelectField, TextField,
} from '../../../../../2-component';
import { RelationType } from '../accountConfig';
import { AffiliationRelations } from '../../../../../../interfaces';

const CREATE_AFFILIATE = gql`
  mutation createAffiliate($input: CreateAffiliateInput!) {
    createAffiliate(input: $input) {
      user { id }
    }
  }
`;

export interface InitiatedAffiliate {
  id: string,
  firstName: string,
  lastName: string,
  relation: RelationType,
}

export const InitialAffiliate = ({
  open, setOpen, onCreated,
}: {
  open: boolean, setOpen: (open: boolean) => void, onCreated: (initiatedAffiliate: InitiatedAffiliate) => void;
}) => {
  const { t } = useTranslation(['affiliationTypes', 'client']);
  const emptyAffiliate = {
    firstName: '',
    lastName: '',
  };
  const [initialAffiliate, setInitialAffiliate] = useState(emptyAffiliate);
  const [initialAffiliateRelation, setInitialAffiliateRelation] = useState(RelationType.OTHER);
  const [createAffiliate] = useMutation(CREATE_AFFILIATE);

  const submit = () => {
    createAffiliate({
      variables: {
        input: {
          firstName: initialAffiliate.firstName,
          lastName: initialAffiliate.lastName,
        },
      },
      onCompleted: (res) => onCreated({
        ...initialAffiliate,
        id: res.createAffiliate.user.id,
        relation: initialAffiliateRelation,
      }),
    });
  };

  return (
    <Dialog
            sx={{
              '.MuiDialog-container': {
                '.MuiPaper-root': {
                  padding: '20px',
                },
              },
            }}
            open={open}
            onClose={setOpen}
            fullWidth
            title={t('affiliationRelationship:createNewAffliate')}
          >
            <Form onSubmit={submit}>
              <Grid item xs={12} sx={{ mb: 4 }}>
                <Typography variant='headingMedium' sx={{ mt: 1 }}>
                  {t('createNewAffliate')}
                </Typography>
              </Grid>
              <Grid item xs={12} mb={1}>
                <TextField
                  label={t('components:accessToEntity.addButton.createTab.firstName')}
                  fullWidth
                  value={initialAffiliate.firstName}
                  onChange={(e: any) => setInitialAffiliate({ ...initialAffiliate, firstName: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} mb={1}>
                <TextField
                  label={t('components:accessToEntity.addButton.createTab.lastName')}
                  fullWidth
                  value={initialAffiliate.lastName}
                  onChange={(e: any) => setInitialAffiliate({ ...initialAffiliate, lastName: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} mb={1}>
                <SelectField
                  label={t('components:accessToEntity.addButton.createTab.relation')}
                  value={initialAffiliateRelation || AffiliationRelations.Other}
                  onChange={(e: any) => setInitialAffiliateRelation(e.target.value)}
                  fullWidth
                >
                  {Object.values(RelationType).map((relation) => (
                    <MenuItem key={relation} value={relation}>
                      {t(`affiliationRelationship.${relation}`)}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
              <Grid item xs={12} mt={3} justifyContent='end' display='flex'>
                <Button type='submit' color='primary' disabled={!(initialAffiliate.firstName && initialAffiliate.lastName && initialAffiliateRelation)} label={t('Create')} />
              </Grid>
            </Form>
          </Dialog>
  );
};

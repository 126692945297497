/* eslint-disable react-hooks/exhaustive-deps */
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { useContext, useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import { usePageState } from '../../../util/usePageState';
import { Box, Skeleton, Typography } from '../../1-primative';
import {
  Table, TableHeadCell, TableRow, TableCell,
  TableBody, Pagination, Card, CardContent,
  TextField,
  Menu,
  MenuItem,
  Badge,
  Button,
  SelectField,
  IconButton,
} from '../../2-component';
import { getBackendLanguage, translateBackend } from '../../../assets/i18n/config';
import { FinancialProductState, Theme } from '../../../interfaces/financialProduct';
import { TRANSITION_MODEL } from '../../../pages/buildModels/components/editDetails';
import { FeatureFlagTypes } from '../../../interfaces/organizationFeatureFlag';
import { CreateModelPortfolioWorkflow } from '../../4-module/createModelPortfolioworkflow/createModelPortfolioWorkflow';
import useTextDebounce from '../../../util/useTextDebounce';

const getStateBadgeColor = (state: FinancialProductState | undefined): 'warning' | 'positive' | 'negative' => {
  if (state === 'DRAFT') {
    return 'warning';
  }

  if (state === 'ACTIVE') {
    return 'positive';
  }

  return 'negative';
};

const FETCH_THEMES = gql`
  query fetchThemes($input: FetchThemesInput!) {
    fetchThemes(input: $input) {
      themes {
        id
        translatedName {
          en
          fr
        }
        translatedDescription {
          en
          fr
        }
        id
      }
      totalCount
    }
  }
`;

interface ThemeItem {
  themeId?: string;
  themeName?: string;
}

const sortThemeItems = (themeItems: ThemeItem[]) => {
  const currentLanguage = getBackendLanguage();
  const collator = new Intl.Collator(currentLanguage);
  const sorted = themeItems.sort((a, b) => collator.compare(a.themeName ?? '', b.themeName ?? ''));
  return sorted;
};

const FETCH_MODEL_PORTFOLIOS = gql`
  query fetchModelPortfolios($input: FetchModelPortfoliosInput!) {
    fetchModelPortfolios(input: $input) {
      modelPortfolios {
        id
        translatedThemeName {
          en
          fr
        }
        theme {
          id
          translatedName {
            en
            fr
          }
          key
        }
        state
        translatedPortfolioDescription {
          en
          fr
        }
        translatedName {
          en
          fr
        }
        primaryAssetClass {
          translatedName {
            en
            fr
          }
        }
      }
      totalCount
    }
  }
`;

const getTotalCount = (data: any, previousData: any) => {
  const totalCount = (data || previousData)?.fetchModelPortfolios?.totalCount ?? 0;
  return totalCount;
};

export const ModelPortfolios = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['modelPortfolios', 'clients', 'components']);
  const { showToast } = useGlobalToast();

  // Get URL params once at component initialization
  const params = new URLSearchParams(location.search);

  // Pagination
  const [pageStr, setPageStr] = usePageState<string>(params.get('page') || '1', 'page');
  const page = parseInt(pageStr, 10);
  const setPage = (newPage: number) => setPageStr(newPage.toString());

  const [pageSizeStr, setPageSizeStr] = usePageState<string>(params.get('pageSize') || '25', 'pageSize');
  const pageSize = parseInt(pageSizeStr, 10);
  const setPageSize = (newPageSize: number) => setPageSizeStr(newPageSize.toString());

  // Sorting
  const defaultSortField = `translatedName.${getBackendLanguage()}`;
  const [sortField, setSortField] = usePageState<string>(
    params.get('sf') || defaultSortField,
    'sf',
  );
  const [sortDescStr, setSortDescStr] = usePageState<string>(params.get('sd') || 'false', 'sd');
  const sortDesc = sortDescStr === 'true';
  const setSortDesc = (value: boolean) => setSortDescStr(value.toString());

  // Filtering
  const [themeIdsStr, setThemeIdsStr] = usePageState<string>(params.get('themeIds') || 'ANY', 'themeIds');
  const themeIds = themeIdsStr.split(',').filter(Boolean);
  const setThemeIds = (ids: string[]) => setThemeIdsStr(ids.join(','));

  // Search state with debounce
  const [immediateSearchQuery, setImmediateSearchQuery] = useState(params.get('searchQuery') || '');
  const debouncedSearchQuery = useTextDebounce({ value: immediateSearchQuery, delay: 500 });
  const [searchQuery, setSearchQuery] = usePageState<string>(params.get('searchQuery') || '', 'searchQuery');

  // Update URL when debounced value changes

  useEffect(() => {
    if (debouncedSearchQuery !== searchQuery) {
      setSearchQuery(debouncedSearchQuery);
    }
  }, [debouncedSearchQuery]);

  // Sync search input with URL changes

  useEffect(() => {
    // to prevent loops and maintain proper state
    if (searchQuery !== immediateSearchQuery && searchQuery !== debouncedSearchQuery) {
      setImmediateSearchQuery(searchQuery);
    }
  }, [searchQuery]);

  const [state, setState] = usePageState<string>(params.get('state') || 'ANY', 'state');
  const [themeOptions, setThemeOptions] = useState<ThemeItem[]>([]);

  // Feature flags
  const isModelPortFolioV2Enabled = activeOrganization.availableFeatureFlags?.includes(
    FeatureFlagTypes.MODEL_PORTFOLIOS_V2,
  );

  const [transitionModelPortfolio] = useMutation(TRANSITION_MODEL, {
    refetchQueries: ['fetchModelPortfolios'],
  });

  const [createModelWorkflowOpen, setCreateModelWorkflowOpen] = useState(false);

  // Keep the menu state for the row actions menu
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedModelId, setSelectedModelId] = useState<string | null>(null);
  const menuOpen = Boolean(menuAnchorEl);

  const onSort = (field: string) => {
    const currentLanguage = getBackendLanguage();
    const localizedField = field.replace(/\.(en|fr)$/, `.${currentLanguage}`);

    if (sortField === localizedField) {
      setSortDesc(!sortDesc);
    }
    setSortField(localizedField);
  };

  const sortDirection = (field: string) => (sortField === field ? (sortDesc ? 'desc' : 'asc') : undefined);

  const {
    loading, data, previousData, refetch,
  } = useQuery(FETCH_MODEL_PORTFOLIOS, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          themeIds: themeIds.includes('ANY') ? undefined : themeIds,
          searchText: searchQuery || undefined,
          states: state === 'ANY' ? ['ACTIVE', 'ARCHIVED', 'DRAFT'] : [state],
        },
        pagination: {
          perPage: pageSize,
          sortField,
          sortDesc,
          offset: (page - 1) * pageSize,
          collation: {
            locale: getBackendLanguage(),
            strength: 2,
          },
        },
      },
    },
  });

  useQuery(FETCH_THEMES, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
      },
    },
    onCompleted: (themeData) => {
      const newOptions = themeData.fetchThemes.themes.map((th: Theme) => ({
        themeId: th.id,
        themeName: translateBackend(th?.translatedName),
      }));
      setThemeOptions(sortThemeItems(newOptions));
    },
  });

  const transition = async (modelId: string, action: 'activate' | 'draft' | 'archive') => {
    try {
      await transitionModelPortfolio({
        variables: {
          input: {
            modelPortfolioId: modelId,
            transition: action,
          },
        },
      });
      closeMenu();
    } catch (error) {
      showToast(t('modelPortfolios:error.transitionModelPortfolio'));
    }
  };

  const resetFilters = () => {
    setImmediateSearchQuery('');
    setSearchQuery('');
    setThemeIdsStr('ANY');
    setState('ANY');
    setPageStr('1');
    setPageSizeStr('25');
    setSortField(`translatedName.${getBackendLanguage()}`);
    setSortDescStr('false');
    navigate('/modelPortfolios');
  };

  const portfolioState = (status: string) => status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();

  const handleThemeChange = (event: any) => {
    const selectedValues = event.target.value as string[];

    if (selectedValues.includes('ANY') && !themeIds.includes('ANY')) {
      setThemeIds(['ANY']);
      return;
    }

    if (themeIds.includes('ANY')) {
      setThemeIds(selectedValues.filter((value: string) => value !== 'ANY'));
      return;
    }

    if (selectedValues.length === 0) {
      setThemeIds(['ANY']);
      return;
    }

    setThemeIds(selectedValues);
  };

  const handleRowClick = (modelId: string) => {
    const currentHash = window.location.hash;
    const searchParams = new URLSearchParams();

    // Add all the current filter/sort/pagination states
    if (searchQuery) searchParams.set('searchQuery', searchQuery);
    if (themeIdsStr !== 'ANY') searchParams.set('themeIds', themeIdsStr);
    if (state) searchParams.set('state', state);
    if (pageStr !== '1') searchParams.set('page', pageStr);
    if (pageSizeStr !== '25') searchParams.set('pageSize', pageSizeStr);
    if (sortField !== `translatedName.${getBackendLanguage()}`) searchParams.set('sf', sortField);
    if (sortDescStr !== 'true') searchParams.set('sd', sortDescStr);

    const searchString = searchParams.toString();
    navigate(`/modelPortfolios/${modelId}${searchString ? `?${searchString}` : ''}${currentHash}`);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, modelId: string) => {
    event.stopPropagation();
    setSelectedModelId(modelId);
    setMenuAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
    setSelectedModelId(null);
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    closeMenu();
  };

  return isModelPortFolioV2Enabled ? (
    <>
      <Card loading={loading} data-testid="model-portfolios-card" sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 160px)',
        mb: 2,
      }}>
        <Box sx={{
          display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden',
        }}>
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
              <Box display="flex" gap={2}>
                <TextField
                  data-testid="model-portfolios-search"
                  label={t('clients:search')}
                  value={immediateSearchQuery}
                  fullWidth
                  onChange={(e: any) => setImmediateSearchQuery(e.target.value)}
                  leadingIcon="search"
                  sx={{ width: '220px' }}
                />
                <SelectField
                  data-testid="model-portfolios-theme-filter"
                  value={themeIds ?? ['ANY']}
                  label={t('modelPortfolioTable.modelFamily')}
                  fullWidth
                  sx={{ width: '220px' }}
                  onChange={handleThemeChange}
                  multiple
                >
                  <MenuItem value="ANY">{t('components:any')}</MenuItem>
                  {themeOptions.map((th: ThemeItem, index) => (
                    <MenuItem key={index} value={th.themeId}>
                      {th.themeName}
                    </MenuItem>
                  ))}
                </SelectField>
                <SelectField
                  data-testid="model-portfolios-status-filter"
                  value={state}
                  sx={{ width: '180px' }}
                  fullWidth
                  label={t('modelPortfolioTable.status')}
                  onChange={(e: any) => setState(e.target.value)}
                >
                  <MenuItem value="ANY">{t('components:any')}</MenuItem>
                  <MenuItem value="DRAFT">DRAFT</MenuItem>
                  <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                  <MenuItem value="ARCHIVED">ARCHIVED</MenuItem>
                </SelectField>
                {(immediateSearchQuery || !themeIds.includes('ANY') || state !== 'ANY') && (
                  <Button
                    data-testid="model-portfolios-clear-filters"
                    label={t('feeAndBilling:feeGrid.clearFilter')}
                    onClick={resetFilters}
                    variant="text"
                    sx={{ alignSelf: 'flex-end' }}
                  />
                )}
              </Box>
              {/* Update Create button to directly open workflow */}
              {permissions.includes('write:model_portfolios') && (
                <Box display='flex'>
                  <Button
                    data-testid="model-portfolios-create-button"
                    label={t('clients:auditTypes.CREATE')}
                    onClick={(event: any) => {
                      event.stopPropagation();
                      setCreateModelWorkflowOpen(true);
                    }}
                    leadingIcon={AddIcon}
                    sx={{ ml: 1 }}
                  />
                </Box>
              )}
            </Box>
          </CardContent>
          <Box sx={{
            display: 'flex', flexDirection: 'column', flex: 1, minHeight: 0,
          }}>
            <Box sx={{ overflowX: 'auto', overflowY: 'auto', flex: 1 }}>
              <Table sx={{ minWidth: 650 }} aria-label="table" data-testid="model-portfolios-table">
                <TableBody>
                  <TableRow>
                    <TableHeadCell
                      width="20%"
                      isSortable
                      onClick={() => onSort(`translatedName.${getBackendLanguage()}`)}
                      sortDirection={sortDirection(`translatedName.${getBackendLanguage()}`)}
                    >
                      <Typography variant='labelSmall' sx={{ fontSize: '12px' }}>{t('modelPortfolioTable.name')}</Typography>
                    </TableHeadCell>
                    <TableHeadCell
                      width="20%"
                      isSortable
                      onClick={() => onSort(`translatedThemeName.${getBackendLanguage()}`)}
                      sortDirection={sortDirection(`translatedThemeName.${getBackendLanguage()}`)}
                    >
                      <Typography variant='labelSmall' sx={{ fontSize: '12px' }}>{t('modelPortfolioTable.modelFamily')}</Typography>
                    </TableHeadCell>
                    <TableHeadCell
                      width="50%"
                      isSortable
                      onClick={() => onSort(`translatedPortfolioDescription.${getBackendLanguage()}`)}
                      sortDirection={sortDirection(`translatedPortfolioDescription.${getBackendLanguage()}`)}
                    >
                      <Typography variant='labelSmall' sx={{ fontSize: '12px' }}>{t('components:tradeModal.description')}</Typography>
                    </TableHeadCell>
                    <TableHeadCell
                      width="15%"
                      isSortable
                      onClick={() => onSort('state')}
                      sortDirection={sortDirection('state')}
                    >
                      <Typography variant='labelSmall' sx={{ fontSize: '12px' }}>{t('modelPortfolioTable.status')}</Typography>
                    </TableHeadCell>
                  </TableRow>
                  {loading && [...Array(pageSize)].map((_, index) => (
                    <TableRow key={index}>
                      <TableCell width="20%"><Skeleton width='100%' /></TableCell>
                      <TableCell width="20%"><Skeleton width='100%' /></TableCell>
                      <TableCell width="50%"><Skeleton width='100%' /></TableCell>
                      <TableCell width="15%"><Skeleton width='100%' /></TableCell>
                    </TableRow>
                  ))}
                  {(data || previousData)?.fetchModelPortfolios?.modelPortfolios?.map((modelPortfolio: any) => (
                    <TableRow
                      hover
                      key={modelPortfolio.id}
                      sx={{ textDecoration: 'none', cursor: 'pointer' }}
                      onClick={() => handleRowClick(modelPortfolio.id)}
                    >
                      <TableCell width="20%">
                        <Typography variant='bodyMedium' sx={{ fontSize: '14px' }}>
                          {translateBackend(modelPortfolio?.translatedName)}
                        </Typography>
                      </TableCell>
                      <TableCell width="20%">
                        <Typography variant='bodyMedium' sx={{ fontSize: '14px' }}>
                          {translateBackend(modelPortfolio?.translatedThemeName)}
                        </Typography>
                      </TableCell>
                      <TableCell width="50%">
                        <Typography variant='bodyMedium' sx={{ fontSize: '14px' }}>
                          {translateBackend(modelPortfolio?.translatedPortfolioDescription)}
                        </Typography>
                      </TableCell>
                      <TableCell width="15%">
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Badge
                            label={portfolioState(modelPortfolio?.state)}
                            color={getStateBadgeColor(modelPortfolio?.state)}
                            sx={{ fontSize: '14px' }}
                          />
                          <Box
                            className="hover-element"
                            sx={{
                              opacity: 0,
                              '.MuiTableRow-root:hover &': {
                                opacity: 1,
                              },
                              marginLeft: 'auto',
                            }}
                          >
                            <IconButton
                              onClick={(event) => handleMenuClick(event, modelPortfolio.id)}
                              size="medium"
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            <Box sx={{
              backgroundColor: 'background.paper',
              borderTop: '1px solid',
              borderColor: 'divider',
            }}>
              <Box sx={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1,
              }}>
                <Typography variant="button">
                  {t('modelPortfolioTable.totalCount')}: {getTotalCount(data, previousData)}
                </Typography>
                <Pagination
                  data-testid="model-portfolios-pagination"
                  count={Math.ceil(getTotalCount(data, previousData) / pageSize)}
                  page={page}
                  perPage={pageSize}
                  onChangePerPage={(newPageSize) => setPageSize(newPageSize)}
                  onChange={(_e, newPage) => setPage(newPage)}
                  sx={{
                    '.MuiPagination-ul': {
                      justifyContent: 'end',
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
      {/* Row actions menu */}
      <Menu
        data-testid="model-portfolios-actions-menu"
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
          closeMenu();
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {selectedModelId && (data || previousData)?.fetchModelPortfolios?.modelPortfolios?.map((model: any) => {
          if (model.id === selectedModelId) {
            return (
              <Box key={model.id}>
                <MenuItem onClick={(e) => {
                  e.stopPropagation();
                  // Handle Clone Model
                  closeMenu();
                }}>
                  {t('modelPortfolioTable.actions.clone')}
                </MenuItem>
                <MenuItem onClick={(e) => {
                  e.stopPropagation();
                  // Handle Edit Model
                  if (selectedModelId) {
                    navigate(`/modelPortfolios/${selectedModelId}/edit`);
                  }
                  closeMenu();
                }}>
                  {t('modelPortfolioTable.actions.edit')}
                </MenuItem>
                {model.state !== 'ACTIVE' && (
                  <MenuItem onClick={(e) => {
                    e.stopPropagation();
                    transition(selectedModelId, 'activate');
                  }}>
                    {t('modelPortfolioTable.actions.activate')}
                  </MenuItem>
                )}
                {model.state !== 'DRAFT' && (
                  <MenuItem onClick={(e) => {
                    e.stopPropagation();
                    transition(selectedModelId, 'draft');
                  }}>
                    {t('modelPortfolioTable.actions.draft')}
                  </MenuItem>
                )}
                {model.state !== 'ARCHIVED' && (
                  <MenuItem onClick={(e) => {
                    e.stopPropagation();
                    transition(selectedModelId, 'archive');
                  }}>
                    {t('modelPortfolioTable.actions.archive')}
                  </MenuItem>
                )}
              </Box>
            );
          }
          return null;
        })}
      </Menu>
      {activeOrganization?.id && (
        <CreateModelPortfolioWorkflow
          open={createModelWorkflowOpen}
          setOpen={setCreateModelWorkflowOpen}
          objectId={activeOrganization.id}
          refetch={refetch}
        />
      )}
    </>
  ) : (
    <>
      <Typography variant='bodyLarge' data-testid="model-portfolios-v2-disabled">
        Model Portfolios V2 is not enabled. Please contact Admin.
      </Typography>
    </>
  );
};

export default ModelPortfolios;

import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import NewRoleProfile from './components/newRoleProfile';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import EditRoleProfile from './components/editRoleProfile';
import { Typography, Box, Skeleton } from '../../../1-primative';
import {
  Table, TableHeadCell, TableRow, TableCell, TableBody, Pagination, Badge, Tooltip, Card, CardContent,
} from '../../../2-component';
import { usePageState } from '../../../../util/usePageState';
import { SettingsTitle } from '../../../3-pattern';

export const FETCH_ROLE_PROFILES = gql`
  query fetchRoleProfiles($input: FetchRoleProfilesInput!) {
    fetchRoleProfiles(input: $input) {
      totalCount
      roleProfiles {
        id
        organization { id name }
        translatedName { en }
        translatedDescription { en }
        roleCount
        template {
          accessiblePages
          dashboards { id name }
          permissions
          navigationStyle
          nonIndividualPageConfiguration { id }
          individualPageConfiguration { id }
          goalPageConfiguration { id }
          accountPageConfiguration { id }
          householdPageConfiguration { id }
          subAccountPageConfiguration { id }
          individualTableStaticPageWidgetConfiguration { id }
          nonIndividualTableStaticPageWidgetConfiguration { id }
          householdTableStaticPageWidgetConfiguration { id }
        }
      }
    }
  }
`;

const PAGE_SIZE = 20;

export const RoleProfilesSettings = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [page, setPage] = usePageState(1, 'page');
  const [selectedRoleProfile, setSelectedRoleProfile] = useState<any>(null);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const { t } = useTranslation(['orgSettings', 'shared']);
  const {
    loading, error, data, refetch, previousData,
  } = useQuery(FETCH_ROLE_PROFILES, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
        pagination: { perPage: PAGE_SIZE, offSet: (page - 1) * PAGE_SIZE },
      },
    },
  });

  if (error) (<Typography>Error</Typography>);

  return (
    <>
      <SettingsTitle title={t('settings:roleProfiles.title')} description={t('settings:roleProfiles.description')} />
      <Card loading={loading}>
        {permissions.includes('write:roles') && (
          <CardContent>
            <Box display='flex' justifyContent='end'>
              <NewRoleProfile afterCreate={refetch} defaultOrg={activeOrganization.id} />
            </Box>
          </CardContent>
        )}
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableHeadCell>{t('roleProfilesTable.name')}</TableHeadCell>
              <TableHeadCell right>{t('roleProfilesTable.organization')}</TableHeadCell>
            </TableRow>
            { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
              <TableRow key={i}>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
              </TableRow>
            ))}
            {data?.fetchRoleProfiles?.roleProfiles?.map((roleProfile: any) => (
              <TableRow
                hover
                key={roleProfile.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={() => {
                  if (roleProfile?.organization.id === activeOrganization.id && permissions.includes('write:role_profiles')) {
                    setSelectedRoleProfile(roleProfile);
                    setUpdateDialogOpen(true);
                  }
                }}
              >
                <TableCell component="th" scope="row">{roleProfile.translatedName.en}</TableCell>
                <TableCell right>
                  {roleProfile.organization.id !== activeOrganization.id ? (
                    <Tooltip title={t('shared:inheritedTooltip', { model: 'role profile', organization: roleProfile.organization?.name })}>
                      <Badge label={t('roleProfilesTable.inherited')} />
                    </Tooltip>
                  ) : (
                    roleProfile?.organization?.name
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={Math.ceil(((data || previousData)?.fetchRoleProfiles?.totalCount ?? 0) / PAGE_SIZE)}
          page={page}
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />

        {updateDialogOpen && (
          <EditRoleProfile
            roleProfileToUpdate={selectedRoleProfile}
            afterUpdate={() => {
              setUpdateDialogOpen(false);
              refetch();
            }}
            handleClose={() => setUpdateDialogOpen(false)}
            userPermissions={permissions}
          />
        )}
      </Card>
    </>
  );
};

import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ImportFile } from '../../../../../interfaces/bulkImport';

const FETCH_IMPORT_FILE_BREADCRUMB = gql`
  query fetchImportFileLineBreadcrumb($importFileId: ObjectID!) {
    fetchImportFile(importFileId: $importFileId) {
      importFile {
        type
        fileDocument { id name }
      }
    }
  }
`;

export const ImportFileBreadcrumb = () => {
  const { data } = useQuery(FETCH_IMPORT_FILE_BREADCRUMB, { variables: { importFileId: useParams().importFileId } });
  const { t } = useTranslation();
  const importFile: ImportFile = data?.fetchImportFile?.importFile;

  return data ? `${t(`bulkImport:fileTypes.${importFile.type}`)} - ${importFile.fileDocument?.name}` : '';
};

import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ConfirmationModal from 'components/modals/confirmationModal';
import { Typography, Skeleton, Box } from '../../../1-primative';
import {
  Table, TableRow, TableHeadCell, TableBody, Pagination,
  Tooltip, Menu, MenuItem, IconButton,
  Card, CardContent, TableCell, Badge,
} from '../../../2-component';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import { usePageState } from '../../../../util/usePageState';
import { Localization } from '../../../../interfaces';
import { NewLocalization } from './components/newLocalization';
import { EditLocalization } from './components/editLocalization';
import { getCountryName } from '../../../resources';
import { UpdateOrganizationSwitch } from '../../../4-module/updateOrganizationSwitch/updateOrganizationSwitch';
import { SettingsTitle } from '../../../3-pattern';

export const FETCH_LOCALIZATIONS = gql`
  query fetchLocalizations($input: FetchLocalizationsInput!) {
    fetchLocalizations(input: $input) {
      localizations {
        id
        organization { id name }
        name
        countries
        defaultCurrency
        languages
        defaultLanguage
        dateFormat
      }
      totalCount
    }
  }
`;

const DELETE_LOCALIZATION = gql`
  mutation deleteLocalization($localizationId: ObjectID!) {
    deleteLocalization(localizationId: $localizationId)
  }
`;

export const LocalizationConfigurationSettings = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 20;
  const { t } = useTranslation(['orgSettings', 'settings']);
  const [selectedRecord, setSelectedRecord] = useState<Localization | null>(null);
  const [selectedRecordMenuAnchor, setSelectedRecordMenuAnchor] = useState<null | HTMLElement>(null);
  const [deletedRecord, setDeletedRecord] = useState<Localization | null>(null);

  const { loading, data, previousData } = useQuery(FETCH_LOCALIZATIONS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
        pagination: {
          perPage: pageSize, offSet: (page - 1) * pageSize, sortField: 'name', sortDesc: false,
        },
      },
    },
    fetchPolicy: 'no-cache',
  });

  const [deleteLocalization] = useMutation(DELETE_LOCALIZATION, { refetchQueries: [FETCH_LOCALIZATIONS] });

  const doDelete = (localizationId: string) => {
    deleteLocalization({ variables: { localizationId } });
  };

  const languageName = (lang: string) => t(`localizationSettings.language.${lang}`);

  const isInherited = (l: Localization) => l.organization?.id !== activeOrganization.id;

  return (
    <>
      <SettingsTitle title={t('settings:localization.title')} description={t('settings:localization.description')} />
      <Card loading={loading}>
        {permissions.includes('write:localizations') && (
          <CardContent>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              {permissions.includes('write:advanced_organization_settings') && (
                <UpdateOrganizationSwitch
                  field='useParentLocalizations'
                  label={t('settings:localizations.useParentLocalizations')}
                  organizationId={activeOrganization.id}
                  refetchQueries={[FETCH_LOCALIZATIONS]}
                />
              )}
              <NewLocalization />
            </Box>
          </CardContent>
        )}
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableHeadCell>{t('shared:name')}</TableHeadCell>
              <TableHeadCell>{t('localizationSettings.countries')}</TableHeadCell>
              <TableHeadCell>{t('localizationSettings.defaultCurrency')}</TableHeadCell>
              <TableHeadCell>{t('localizationSettings.languages')}</TableHeadCell>
              <TableHeadCell>{t('localizationSettings.dateFormat')}</TableHeadCell>
              <TableHeadCell>{t('shared:organization')}</TableHeadCell>
              <TableHeadCell></TableHeadCell>
            </TableRow>
            { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
              <TableRow key={i}>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
              </TableRow>
            ))}
            {data?.fetchLocalizations?.localizations?.map((localization: Localization, index: number) => (
              <TableRow
                key={index}
                hover
                pointer
                onClick={() => {
                  setSelectedRecord(localization);
                  setUpdateDialogOpen(true);
                }}
              >
                <TableCell component="th" scope="row">{localization.name}</TableCell>
                <TableCell>
                  {localization.countries.map(getCountryName).join(', ')}
                </TableCell>
                <TableCell>{localization.defaultCurrency}</TableCell>
                <TableCell>
                  {localization.languages.length === 1 && languageName(localization.languages[0])}
                  {localization.languages.length > 1
                    && localization.languages.map((lang) => (<span key={lang}>
                      {languageName(lang)}
                      {lang === localization.defaultLanguage && <> ({t('localizationSettings.default')})</>}
                      <br />
                    </span>))
                  }
                </TableCell>
                <TableCell>
                  <code>{localization.dateFormat}</code>
                  {localization.dateFormat
                    && <Typography color='gray'>
                      <i>{dayjs().format(localization.dateFormat)}</i>
                    </Typography>
                  }
                </TableCell>
                <TableCell>
                  {isInherited(localization)
                    ? (<Tooltip title={t('shared:inheritedTooltip', { model: t('tabs.localizationSettings'), organization: localization.organization?.name })}>
                      <Badge label={t('shared:inheritedFlag')} />
                    </Tooltip>)
                    : (localization.organization?.name)}
                </TableCell>
                <TableCell onClick={(e) => { e.stopPropagation(); }}>
                  <IconButton size='small'
                    onClick={(e) => {
                      setSelectedRecord(localization);
                      setSelectedRecordMenuAnchor(e.currentTarget);
                    }}
                  ><MoreVertIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={Math.ceil(((data || previousData)?.fetchLocalizations?.totalCount ?? 0) / pageSize)}
          page={page}
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />

        {/* Context per-row menu */}
        <Menu
          anchorEl={selectedRecordMenuAnchor}
          open={!!selectedRecordMenuAnchor}
          onClose={() => setSelectedRecordMenuAnchor(null)}
          onClick={() => setSelectedRecordMenuAnchor(null)}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <Typography sx={{ p: 2 }}>{selectedRecord?.id}</Typography>
          <MenuItem
            disabled={!!selectedRecord && isInherited(selectedRecord)}
            onClick={() => setDeletedRecord(selectedRecord)}><DeleteIcon />&nbsp; {t('shared:delete')}
          </MenuItem>
        </Menu>

        {/* per-row editing dialog */}
        {updateDialogOpen && selectedRecord && (
          <EditLocalization
            localization={selectedRecord}
            handleClose={() => setUpdateDialogOpen(false)}
            inherited={isInherited(selectedRecord)}
          />
        )}

        {/* Deletion - confirmation modal */}
        {deletedRecord && <ConfirmationModal
          open
          loading={false}
          title={t('localizationSettings.deleteConfirmationModal.title')}
          onConfirm={async () => {
            doDelete(deletedRecord.id);
            setDeletedRecord(null);
          }}
          onCancel={() => setDeletedRecord(null)}
          confirmButton={t('localizationSettings.deleteConfirmationModal.confirm')}
          cancelButton={t('localizationSettings.deleteConfirmationModal.cancel')}
        >
          <Typography my={1}>
            {t('shared:name')}: <b>{deletedRecord.name}</b>
          </Typography>
          <Typography my={1}>
            {t('localizationSettings.countries')}: {deletedRecord.countries.map(getCountryName).join(', ')}
          </Typography>
          <Typography my={1}>
            {t('localizationSettings.defaultCurrency')}: {deletedRecord.defaultCurrency}
          </Typography>
        </ConfirmationModal>
        }
      </Card>
    </>
  );
};

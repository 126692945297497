import { ChevronLeft, Close as CloseIcon } from '@mui/icons-material';
import {
  createContext, Dispatch, SetStateAction, useContext, useState, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { translateBackend } from 'assets/i18n/config';
import { kebabCase } from 'lodash';
import { colors } from '../../0-tokens';
import {
  Box, Typography, Link, RefBox,
} from '../../1-primative';
import {
  Button, IconButton, Dialog, DialogTitle, DialogContent, DialogFooter,
} from '../../2-component';
import { FullScreenDialog } from '../../3-pattern';
import { UserContext } from '../../../providers/userContextProvider';
import {
  PersonalInformation,
  RelationshipInformation,
  ResidencyInformation,
  EmploymentInformation,
  PersonsOfInterest,
  IncomeAssetsAndDebts,
  InvestmentKnowledge,
  RiskQuestion,
  IdVerification,
  Approval,
  Documents,
  Household,
  Account,
  Goals,
  RelatedEntities,
  SubAccounts,
  Transfer,
  CreateGoal,
  FinancialProjection,
  RiskProfile,
  CreateModelPortfolio,
  AddModelPortfolioDetails,
} from './subSteps';
import MugImage from '../../../assets/images/custom/mug.svg';
import { EditGoal } from './subSteps/editGoal/editGoal';
import { EditAccount } from './subSteps/editAccount/editAccount';
import { CreateAccountForGoal } from './subSteps/createAccountForGoal/createAccountForGoal';
import { AssignPortfolioToSubAccount } from './subSteps/assignPortfolioToSubAccount/assignPortfolioToSubAccount';
import { ReviewAccount } from './subSteps/reviewAccount/reviewAccount';
import { GoalRiskQuestion } from './subSteps/goalRiskQuestion/goalRiskQuestion';
import { ReviewGoal } from './subSteps/reviewGoal/reviewGoal';
import { DepositType } from './subSteps/depositType/depositType';
import { DepositForm } from './subSteps/depositForm/depositForm';
import { DepositReview } from './subSteps/depositReview/depositReview';
import { WithdrawReview } from './subSteps/withdrawReview/withdrawReview';
import { WithdrawForm } from './subSteps/withdrawForm/withdrawForm';
import { TypeSelect } from './subSteps/typeSelect/typeSelect';
import { SimpleForm } from './subSteps/simpleForm/simpleForm';
import { NonIndividualInformation } from './subSteps/nonIndividualInformation/nonIndividualInformation';
import { NonIndividualSetup } from './subSteps/nonIndividualSetup/nonIndividualSetup';
import { CreateSubAccountForAccount } from './subSteps/createSubAccountForAccount/createSubAccountForAccount';
import { StepIndicator } from './components/stepIndicator';
import { Review } from './subSteps/review/review';
import { ReviewPortfolioChanges } from './subSteps/reviewPortfolioChanges/reviewPortfolioChanges';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { ReviewBankAccountInformation } from './subSteps/reviewBankAccountInformation/reviewBankAccountInformation';
import { CollectSigners } from './subSteps/collectSigners/collectSigners';
import { CreateAccount } from './subSteps/createAccount/createAccount';
import { AddModelPortfolioSecurities } from './subSteps/addModelPortfolioSecurities/addModelPortfolioSecurities';

interface WorkflowCompletionContextInterface {
  scrollWorkflowDialog: () => void;
  setHasQuebecAddress: (value: boolean) => void;
}

export const WorkflowCompletionContext = createContext<WorkflowCompletionContextInterface>({
  scrollWorkflowDialog: () => {},
  setHasQuebecAddress: (value: boolean) => {},
});

export const WorkflowCompletionVisual = ({
  workflowCompletion,
  open,
  onClose,
  onNext,
  previousStep,
  loading,
  data,
  onChangeSetData,
  setActiveWorkflowCompletion,
  isInnerWorkflow,
  handleIdSkip,
}: {
  workflowCompletion: any;
  open: boolean;
  onClose: () => void;
  onNext: () => void;
  previousStep: () => void;
  loading: boolean;
  data?: any;
  onChangeSetData?: Dispatch<SetStateAction<any>>;
  setActiveWorkflowCompletion?: any;
  isInnerWorkflow?: boolean;
  handleIdSkip?: () => void;
}) => {
  const { userContext } = useContext(UserContext);
  const [hasQuebecAddress, setHasQuebecAddress] = useState(false);
  const [openSkipIDVerification, setOpenSkipIDVerification] = useState(false);
  const ref = useRef<HTMLInputElement | null>(null);

  const { t } = useTranslation('workflowCompletions');
  const { sys } = useThemeTokens();
  const handleSkip = () => {
    if (hasQuebecAddress && workflowCompletion?.currentSubStep?.skippable !== true) {
      setOpenSkipIDVerification(true);
    } else {
      onNext();
    }
  };
  const handleGoBack = () => {
    if (hasQuebecAddress) setHasQuebecAddress(false);
    previousStep();
  };
  const noMaxWidth = (stepType: string) => ['WITHDRAW_FORM'].includes(stepType);
  const subStepMapping = (subStep: any) => {
    switch (subStep?.type) {
      case 'ACCOUNTS':
        return <Account options={subStep.options} onNext={onNext} stepLoading={loading} userId={workflowCompletion.objectId} />;
      case 'APPROVAL':
        return <Approval objectId={workflowCompletion.objectId} objectType={workflowCompletion.objectType} options={subStep.options} onNext={onNext} stepLoading={loading} />;
      case 'DOCUMENTS':
        return <Documents options={subStep.options} workflowCompletion={workflowCompletion} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} />;
      case 'EMPLOYMENT_INFORMATION':
        return <EmploymentInformation options={subStep.options} workflowCompletion={workflowCompletion} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} />;
      case 'GOALS':
        return <Goals options={subStep.options} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} />;
      case 'HOUSEHOLD':
        return <Household options={subStep.options} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} />;
      case 'ID_VERIFICATION':
        return <IdVerification options={subStep.options} workflowCompletion={workflowCompletion} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} />;
      case 'INCOME_ASSETS_AND_DEBTS':
        return <IncomeAssetsAndDebts options={subStep.options} workflowCompletion={workflowCompletion} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} />;
      case 'INVESTMENT_KNOWLEDGE':
        return <InvestmentKnowledge options={subStep.options} workflowCompletion={workflowCompletion} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} />;
      case 'CREATE_MODEL_PORTFOLIO':
        return <CreateModelPortfolio options={subStep.options} workflowCompletion={workflowCompletion} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} />;
      case 'ADD_MODEL_PORTFOLIO_DETAILS':
        return (<AddModelPortfolioDetails options={subStep.options} workflowCompletion={workflowCompletion} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} />);
      case 'ADD_MODEL_PORTFOLIO_SECURITIES':
        return (<AddModelPortfolioSecurities options={subStep.options} workflowCompletion={workflowCompletion} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} />);
      case 'PERSONAL_INFORMATION':
        return <PersonalInformation options={subStep.options} workflowCompletion={workflowCompletion} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} />;
      case 'PERSONS_OF_INTEREST':
        return <PersonsOfInterest options={subStep.options} workflowCompletion={workflowCompletion} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} />;
      case 'RELATED_ENTITIES':
        return <RelatedEntities options={subStep.options} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} />;
      case 'RELATIONSHIP_INFORMATION':
        return <RelationshipInformation options={subStep.options} workflowCompletion={workflowCompletion} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} />;
      case 'RESIDENCY_INFORMATION':
        return <ResidencyInformation options={subStep.options} workflowCompletion={workflowCompletion} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} />;
      case 'RISK_QUESTION_1':
        return <RiskQuestion question={1} workflowCompletion={workflowCompletion} options={subStep.options} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} />;
      case 'RISK_QUESTION_2':
        return <RiskQuestion question={2} workflowCompletion={workflowCompletion} options={subStep.options} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} />;
      case 'SUB_ACCOUNTS':
        return <SubAccounts options={subStep.options} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} />;
      case 'CREATE_GOAL':
        return <CreateGoal options={subStep.options} workflowCompletion={workflowCompletion} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} />;
      case 'EDIT_GOAL':
        return <EditGoal options={subStep.options} workflowCompletion={workflowCompletion} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} />;
      case 'REVIEW_GOAL':
        return (
          <ReviewGoal
            options={subStep.options}
            onNext={onNext}
            userId={workflowCompletion.objectId}
            setActiveWorkflowCompletion={setActiveWorkflowCompletion}
            workflowCompletion={workflowCompletion}
          />
        );
      case 'GOAL_RISK_QUESTION_1':
        return <GoalRiskQuestion options={subStep.options} workflowCompletion={workflowCompletion} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} />;
      case 'CREATE_ACCOUNT_FOR_GOAL':
        return <CreateAccountForGoal options={subStep.options} workflowCompletion={workflowCompletion} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} />;
      case 'EDIT_ACCOUNT':
        return (
          <EditAccount
            direction={workflowCompletion?.direction ?? 'FORWARD'}
            options={subStep.options}
            userId={workflowCompletion.objectId}
            onNext={onNext}
            stepLoading={loading}
            previousStep={previousStep}
            workflowCompletion={workflowCompletion}
          />
        );
      case 'REVIEW_ACCOUNT':
        return (
          <ReviewAccount
            options={subStep.options}
            onNext={onNext}
            setActiveWorkflowCompletion={setActiveWorkflowCompletion}
            workflowCompletion={workflowCompletion}
            userId={workflowCompletion.objectId}
          />
        );
      case 'CREATE_SUBACCOUNT_FOR_ACCOUNT':
        return <CreateSubAccountForAccount options={subStep.options} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} />;
      case 'ASSIGN_PORTFOLIO_TO_SUB_ACCOUNT':
        return <AssignPortfolioToSubAccount options={subStep.options} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} workflowCompletion={workflowCompletion} />;
      case 'REVIEW_PORTFOLIO_CHANGES':
        return <ReviewPortfolioChanges options={subStep.options} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} />;
      case 'TRANSFERS':
        return <Transfer options={subStep.options} workflowCompletion={workflowCompletion} onNext={onNext} stepLoading={loading} userId={workflowCompletion.objectId} />;
      case 'DEPOSIT_TYPE':
        return <DepositType options={subStep.options} onNext={onNext} direction={workflowCompletion?.direction ?? 'FORWARD'} />;
      case 'DEPOSIT_FORM':
        return <DepositForm options={subStep.options} onNext={onNext} />;
      case 'DEPOSIT_REVIEW':
        return <DepositReview options={subStep.options} onNext={onNext} />;
      case 'WITHDRAW_FORM':
        return <WithdrawForm options={subStep.options} onNext={onNext} />;
      case 'WITHDRAW_REVIEW':
        return <WithdrawReview options={subStep.options} onNext={onNext} />;
      case 'SIMPLE_FORM':
        return data && onChangeSetData && <SimpleForm options={subStep.options} onNext={onNext} onChangeSetData={onChangeSetData} data={data} stepLoading={loading} />;
      case 'TYPE_SELECT':
        return data && onChangeSetData && <TypeSelect options={subStep.options} onNext={onNext} onChangeSetData={onChangeSetData} data={data} />;
      case 'NON_INDIVIDUAL_INFORMATION':
        return <NonIndividualInformation workflowCompletion={workflowCompletion} options={subStep.options} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} />;
      case 'NON_INDIVIDUAL_SETUP':
        return (
          <NonIndividualSetup
            workflowCompletion={workflowCompletion}
            options={subStep.options}
            userId={workflowCompletion.objectId}
            onNext={onNext}
            stepLoading={loading}
            workflowCompletionId={workflowCompletion.id}
          />
        );
      case 'PROJECTIONS':
        return <FinancialProjection options={subStep.options} workflowCompletion={workflowCompletion} onNext={onNext} stepLoading={loading} />;
      case 'FINANCIAL_PROJECTIONS':
        return <FinancialProjection options={subStep.options} workflowCompletion={workflowCompletion} onNext={onNext} stepLoading={loading} />;
      case 'REVIEW':
        return <Review options={subStep.options} onNext={onNext} stepLoading={loading} />;
      case 'REVIEW_BANK_ACCOUNT_INFORMATION':
        return <ReviewBankAccountInformation options={subStep.options} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} />;
      case 'RISK_PROFILE':
        return <RiskProfile options={subStep.options} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} />;
      case 'COLLECT_SIGNERS':
        return <CollectSigners options={subStep.options} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} />;
      case 'CREATE_ACCOUNT':
        return <CreateAccount options={subStep.options} userId={workflowCompletion.objectId} onNext={onNext} stepLoading={loading} workflowCompletion={workflowCompletion.id} />;
      default:
        return (
          <>
            <Typography variant='displayLarge' sx={{ mt: 1 }}>
              {translateBackend(workflowCompletion?.currentSubStep?.options?.title)}
            </Typography>
            <Typography variant='bodyLarge' sx={{ mb: 3 }}>
              {translateBackend(workflowCompletion?.currentSubStep?.options?.subtitle)}
            </Typography>
            <Box display='flex' justifyContent='end'>
              <Button label={t('workflowCompletions:continue')} disabled={loading} sx={{ mt: 3, textAlign: 'center' }} onClick={onNext} />
            </Box>
          </>
        );
    }
  };

  const subStepPermittedRoles = workflowCompletion?.currentSubStep?.rolesCompleteableBy ?? [];
  const userRoleProfileId = userContext?.role?.publicRoleProfile?.id;
  const isSubStepRolePermitted = subStepPermittedRoles.length === 0 || subStepPermittedRoles.map((role: { id: string }) => role?.id).includes(userRoleProfileId);

  return (
    <WorkflowCompletionContext.Provider
      value={{
        scrollWorkflowDialog: () => {
          if (ref && ref.current) ref?.current?.scrollTo(0, 0);
        },
        setHasQuebecAddress: (value: boolean) => {
          if (workflowCompletion?.currentSubStep?.skippable !== true) {
            setHasQuebecAddress(value);
          }
        },
      }}
    >
      <FullScreenDialog isInnerWorkflow={isInnerWorkflow} data-testid='workflow-completion-full-screen-dialogue' open={open}>
        <Box display='flex' height='100%' sx={{ backgroundColor: colors.white }}>
          <RefBox ref={ref} flex={3} overflow='auto' pb={12}>
            <Box display='flex' justifyContent='space-between' flexDirection='column' height='100%'>
              <Box>
                <Box display='flex' justifyContent='space-between' p={3}>
                  {workflowCompletion?.currentSubStep?.canGoBack ? <Button variant='text' label='Back' leadingIcon={ChevronLeft} onClick={() => handleGoBack()} /> : <Box />}
                  <IconButton onClick={() => onClose()}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Box pt='20px' pl={isInnerWorkflow ? 6 : 2} pr={isInnerWorkflow ? 5 : 2} display='flex' justifyContent='center'>
                  {isSubStepRolePermitted ? (
                    <Box maxWidth={noMaxWidth(workflowCompletion?.currentSubStep?.type) ? '1000px' : '552px'} width='100%' pb={8}>
                      <StepIndicator workflowCompletion={workflowCompletion} />
                      {subStepMapping(workflowCompletion?.currentSubStep)}
                    </Box>
                  ) : (
                    <Box display='flex' alignItems='center' flexDirection='column' gap={3}>
                      <img src={MugImage} alt='mug-icon' />
                      <Typography variant='displayLarge' sx={{ mt: 1, textAlign: 'center' }}>
                        {t('caughtUp')}
                      </Typography>
                      <Typography variant='bodyLarge' sx={{ mt: 1, maxWidth: '500px', textAlign: 'center' }}>
                        {t('caughtUpSubtitle')}
                      </Typography>
                      <Button variant='text' size='lg' label={t('workflowCompletions:closeWorkflow')} onClick={onClose} />
                    </Box>
                  )}
                </Box>
              </Box>
              {(workflowCompletion?.currentSubStep?.skippable || hasQuebecAddress) && (
                <Box display='flex' justifyContent='end' p={3}>
                  <Button variant='text' label={t('workflowCompletions:skip')} onClick={handleSkip} dataTestId={`skip-button-${kebabCase(workflowCompletion?.currentSubStep?.type)}`} />
                </Box>
              )}
            </Box>
          </RefBox>
        </Box>
      </FullScreenDialog>
      <Dialog open={openSkipIDVerification}>
        <DialogTitle>{t('workflowCompletions:idVerificationSkipDialog.title')}</DialogTitle>
        <DialogContent>
          <Typography variant='bodyLarge'>
            {t('workflowCompletions:idVerificationSkipDialog.content1')}
            <Link underline='none' target='_blank' href={userContext.organization?.supportUrl || 'https://onevest.zendesk.com/hc/en-us/requests/new'}>
              {t('workflowCompletions:idVerificationSkipDialog.content2')}
            </Link>
            {t('workflowCompletions:idVerificationSkipDialog.content3')}
          </Typography>
        </DialogContent>
        <DialogFooter>
          <Button
            sx={{ backgroundColor: `${sys.color.primaryVariant} !important`, color: `${sys.color.onPrimaryVariant} !important` }}
            label={t('workflowCompletions:idVerificationSkipDialog.cancel')}
            onClick={() => setOpenSkipIDVerification(false)}
          />
          <Button
            sx={{ backgroundColor: `${sys.color.primary} !important`, color: `${sys.color.onPrimary} !important` }}
            label={t('workflowCompletions:idVerificationSkipDialog.continue')}
            onClick={() => {
              if (handleIdSkip) handleIdSkip();
              setOpenSkipIDVerification(false);
              setHasQuebecAddress(false);
              onNext();
            }}
          />
        </DialogFooter>
      </Dialog>
    </WorkflowCompletionContext.Provider>
  );
};

export default WorkflowCompletionVisual;

import { useTranslation } from 'react-i18next';
import { Button, Form } from '../../../2-component';
import { SettingsCard } from '../../../3-pattern';
import { FeatureFlag, useFeatureFlags } from '../../../4-module/featureFlag/featureFlag';
import { Box } from '../../../1-primative';
import { useGlobalToast } from '../../../../providers/globalToastProvider';
import { usePermissions } from '../../../../providers/userContextProvider';

export const GeneralProductExperienceSettings = () => {
  const { showToast } = useGlobalToast();
  const { t } = useTranslation(['settings', 'shared']);
  const { permissions } = usePermissions();
  const { featureFlags, updateFeatureFlag, saveFeatureFlags } = useFeatureFlags();

  const updateOrganization = async () => {
    await saveFeatureFlags();
    showToast({
      message: t('shared:successfullySaved'),
      severity: 'success',
    });
  };

  return (
    <SettingsCard title={t('generalProductExperience.title')} description={t('generalProductExperience.description')}>
      <Form onSubmit={updateOrganization}>
        <Box display='flex' flexDirection='column' gap={2}>
          {permissions.includes('write:advanced_organization_settings') && (
            <>
              <FeatureFlag featureFlag={featureFlags.find((ff) => ff.key === 'HOUSEHOLD')} onChange={updateFeatureFlag} testId='settings-general-product-experience-household' underline />
              <FeatureFlag
                featureFlag={featureFlags.find((ff) => ff.key === 'ONBOARDING_WITHOUT_GOALS')}
                onChange={updateFeatureFlag}
                testId='settings-general-product-experience-onboarding-without-goals'
                underline
              />
            </>
          )}
          <Box display='flex' justifyContent='flex-end'>
            <Button type='submit' label={t('shared:save')} dataTestId='settings-general-product-experience-submit-button' />
          </Box>
        </Box>
      </Form>
    </SettingsCard>
  );
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import { isUndefined } from 'lodash';
import {
  Button, Dialog, DialogContent, DialogTitle, Form, IconButton,
} from '../../../2-component';
import { Box, Typography } from '../../../1-primative';
import { EditField } from '../../../4-module/configurableOptionFields/optionEditComponent';
import { StaticPageWidgetOptions } from '../../../../interfaces';
import { StaticPageWidgetType } from '../staticPageWidgetConfiguration';

const FETCH_STATIC_WIDGET_OPTIONS = gql`
  query FetchStaticWidgetOptions($widgetType: StaticWidgetConfigurationTypes!) {
    fetchStaticWidgetOptions(widgetType: $widgetType) {
      widgetOptions {
        options
        optionType
        label { en fr }
        key
        default
      }
    }
  }
`;

export const EditStaticPageWidget = ({
  setWidget, widget, open, setOpen,
}: {
  widget: StaticPageWidgetType; setWidget: (widget: StaticPageWidgetType) => void; open: boolean; setOpen: (o: boolean) => void;
}) => {
  const { t } = useTranslation('pageConfiguration');
  const [options, setOptions] = useState<StaticPageWidgetOptions[]>([]);

  const { data } = useQuery(FETCH_STATIC_WIDGET_OPTIONS, { variables: { widgetType: widget.type }, fetchPolicy: 'network-only' });

  useEffect(() => {
    if (data?.fetchStaticWidgetOptions?.widgetOptions && open) {
      const newOptions: StaticPageWidgetOptions[] = [...(data?.fetchStaticWidgetOptions?.widgetOptions || [])];
      const itemsToRemove: string[] = [];

      newOptions.forEach((x: StaticPageWidgetOptions, i: number) => {
        const parentOptionsIndex = newOptions.findIndex((opt) => opt.key === 'table');
        if (x.key?.includes('MobileView')) {
          const mobileTableView = { ...x, value: !isUndefined(widget?.options[x.key]) ? widget?.options[x.key] : x.default };
          newOptions[parentOptionsIndex] = { ...newOptions[parentOptionsIndex], mobileTableView };
          itemsToRemove.push(x.key);
        }

        newOptions[i] = { ...x, value: x.key && !isUndefined(widget?.options[x.key]) ? widget?.options[x.key] : x.default };
      });

      if (itemsToRemove.length > 0) {
        itemsToRemove.forEach((key) => {
          const idx = newOptions.findIndex((elem) => elem.key === key);
          newOptions.splice(idx, 1);
        });
      }

      if (JSON.stringify(options) !== JSON.stringify(newOptions)) {
        setOptions(newOptions);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, open]);

  const onSubmit = () => {
    if (options) {
      const newFormattedOptions: any = {};
      options.forEach((x: any) => {
        newFormattedOptions[x.key] = x.value;
        if (x.mobileTableView) {
          const { key } = x.mobileTableView;
          newFormattedOptions[key] = x.mobileTableView.value;
        }
      });
      if (JSON.stringify(widget.options) !== JSON.stringify(newFormattedOptions)) {
        setWidget({ ...widget, options: newFormattedOptions });
      }
    }
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth='md' fullWidth>
      <DialogTitle>
        <Box display='flex' justifyContent='space-between' width='100%' alignItems='center'>
          <Typography variant='headingSmall' sx={{ mr: 0.5 }}>{t('editWidget')}</Typography>
          <IconButton onClick={() => setOpen(false)} aria-label='close' size='medium'><CloseIcon /></IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Form onSubmit={onSubmit}>
          {options.length > 0 && (
            <Box mt={2}>
              <Typography variant='bodyMedium' weight='bold' mb={1}>{t('options')}</Typography>
              {(options || []).map((x: any, i: number) => (
                <React.Fragment key={i}>
                  <EditField option={x} options={options} setOptions={setOptions} i={i} optionType={x.optionType} widgetType={widget.type} />
                </React.Fragment>
              ))}
            </Box>
          )}
          <Box display='flex' flexDirection='row' justifyContent='end'>
            <Button type='submit' disabled={!widget.type} label={t('add')} sx={{ mt: 3 }} />
          </Box>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

import {
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
  Stack,
  styled,
} from '@mui/material';
import { Typography } from 'ovComponents/1-primative';
import { useEffect, useState } from 'react';
import { useSwitchTokens } from './switch.tokens';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import InfoDialog from '../infoDialog/infoDialog';

interface SwitchFunctionalProps {
  checked?: boolean
  onChange?: (onoff: boolean) => void
  disabled?: boolean
  error?: boolean
}
interface SwitchVisualProps {
  label?: string
  labelPosition?: 'right' | 'left'
  size?: 'medium' | 'small'
  infoTooltip?: string
  fullWidth?: boolean
  sx?: any
  testId?: string
  underline?: boolean
}

export const Switch = ({
  checked, onChange, disabled = false, error,
  label, labelPosition = 'right', size = 'medium', infoTooltip,
  fullWidth = false, sx, testId,
}: SwitchFunctionalProps & SwitchVisualProps) => {
  const [on, setOn] = useState<boolean>(checked ?? false);

  useEffect(() => {
    if (checked !== undefined) setOn(checked);
  }, [checked]);

  const setOnAndPropagate = (newOn: boolean) => {
    setOn(newOn);
    if (onChange) onChange(newOn);
  };

  return (
    <Stack direction='row' alignItems='center' spacing={1.5} justifyContent={fullWidth ? 'space-between' : undefined} sx={sx}>
      {label && labelPosition === 'left' && (
        <LabelAndTooltip label={label} size={size} infoTooltip={infoTooltip} disabled={disabled} toggleOn={() => setOnAndPropagate(!on)} />
      )}
      <StyledMuiSwitch
        data-testid={testId}
        checked={on}
        disabled={disabled}
        size={size}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setOnAndPropagate(event.target.checked); }}
        inputProps={{ role: 'switch', 'data-testid': `${testId ?? 'switch'}-input` } as React.InputHTMLAttributes<HTMLInputElement>}
      />
      {label && labelPosition === 'right' && (
        <LabelAndTooltip error={error} label={label} size={size} infoTooltip={infoTooltip} disabled={disabled} toggleOn={() => setOnAndPropagate(!on)} />
      )}
    </Stack>
  );
};

const LabelAndTooltip = (
  {
    label, size, infoTooltip, disabled, toggleOn, error,
  }: { label: string, size: 'medium' | 'small', infoTooltip?: string, disabled: boolean, toggleOn: () => void, error?: boolean },
) => {
  const clickable = !disabled;
  const tokens = useThemeTokens(useSwitchTokens());
  const { comp } = tokens;

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div onClick={clickable ? toggleOn : () => { }}>
          <Typography
            variant={size === 'small' ? 'bodySmall' : 'bodyLarge'}
            sx={{ color: !disabled ? comp.switch.labelColor : error ? comp.switch.errorColor : comp.switch.disabledColor, mr: '6px', cursor: clickable ? 'pointer' : 'default' }}
          >
            {label}
            {infoTooltip && (<InfoDialog information={infoTooltip} />)}
          </Typography>
        </div>
      </div>
    </>
  );
};

const StyledMuiSwitch = styled((props: MuiSwitchProps) => (
  <MuiSwitch focusVisibleClassName=".Mui-focusVisible" {...props} />
))((props) => {
  const ovalWidth = props.size === 'medium' ? 44 : 28;
  const ovalHeight = props.size === 'medium' ? 24 : 16;
  const tokens = useThemeTokens(useSwitchTokens());
  const { comp } = tokens;

  return {
    width: ovalWidth,
    height: ovalHeight,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: `translateX(${(ovalWidth - 4) / 2}px)`,
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: comp.switch.onColor,
          opacity: 1,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          backgroundColor: comp.switch.disabledColor,
          opacity: 1,
        },
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        backgroundColor: comp.switch.onDisabled,
        opacity: 1,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: comp.switch.disabledColor,
        opacity: 1,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      boxShadow: 'none',
      width: ovalHeight - 4,
      height: ovalHeight - 4,
    },
    '& .MuiSwitch-track': {
      borderRadius: ovalHeight / 2,
      backgroundColor: comp.switch.onContainer,
    },
  };
});

export const SettingsSwitch = ({
  checked, onChange, disabled = false,
  label, sx, testId, underline = false,
}: SwitchFunctionalProps & SwitchVisualProps) => {
  const [on, setOn] = useState<boolean>(checked ?? false);
  const { sys } = useThemeTokens(useSwitchTokens());

  useEffect(() => {
    if (checked !== undefined) setOn(checked);
  }, [checked]);

  const setOnAndPropagate = (newOn: boolean) => {
    setOn(newOn);
    if (onChange) onChange(newOn);
  };

  return (
    <Stack direction='row' alignItems='center' justifyContent={'space-between'} pt={1} pb={3} sx={sx} borderBottom={underline ? `1px solid ${sys.color.outlineVariant}` : 'none'}>
      <Typography variant='labelLarge' onClick={() => setOnAndPropagate(!on)} sx={{ cursor: 'pointer' }}>{label}</Typography>
      <StyledMuiSwitch
        data-testid={testId}
        checked={on}
        size='medium'
        disabled={disabled}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setOnAndPropagate(event.target.checked); }}
        inputProps={{ role: 'switch', 'data-testid': `${testId ?? 'switch'}-input` } as React.InputHTMLAttributes<HTMLInputElement>}
      />
    </Stack>
  );
};

import { gql, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uniq } from 'lodash/fp';
import { TextField } from '../../../../2-component';
import { FormModal } from '../../../../3-pattern';
import ScheduleDates from './scheduleDates';

const UPDATE_SCHEDULE = gql`
  mutation updateSchedule($input: UpdateScheduleInput!) {
    updateSchedule(input: $input) {
      schedule {
        id
      }
    }
  }
`;

const EditSchedule = ({
  afterUpdate, scheduleToUpdate, open, handleClose,
}: { afterUpdate: () => void, scheduleToUpdate: any, open: boolean, handleClose: () => void }) => {
  const { t } = useTranslation(['client', 'configureModels']);
  const [localOpen, setLocalOpen] = useState(open);
  const [updatingSchedule, setUpdatingSchedule] = useState<any>({
    name: scheduleToUpdate.name,
    dates: scheduleToUpdate.dates,
  });

  useEffect(() => {
    setLocalOpen(open);
    setUpdatingSchedule((prevState: any) => ({ ...prevState, ...scheduleToUpdate }));
  }, [open, scheduleToUpdate]);

  const [updateSchedule, { loading }] = useMutation(UPDATE_SCHEDULE, {
    variables: {
      input: {
        scheduleId: updatingSchedule?.id,
        dates: updatingSchedule?.dates,
        name: updatingSchedule?.name,
      },
    },
  });
  const update = async () => {
    await updateSchedule();
    afterUpdate();
  };

  if (!updatingSchedule) {
    return <></>;
  }
  let hasDuplicates = false;
  if (updatingSchedule && updatingSchedule.dates) {
    hasDuplicates = uniq(updatingSchedule.dates).length !== updatingSchedule.dates.length;
  }
  const disabled = !(updatingSchedule.name && !hasDuplicates);
  return (
    <FormModal loading={loading} disabled={disabled} title={t('schedule:addModal.updateTitle')} formButton={t('update')} onSubmit={update} open={localOpen} handleClose={handleClose}>
      <TextField
        label={t('schedule:addModal.name')}
        fullWidth
        value={updatingSchedule.name}
        onChange={(e: any) => setUpdatingSchedule({ ...updatingSchedule, name: e.target.value as string })}
        data-testid="schedule-name"
      />
      <ScheduleDates schedule={updatingSchedule} setSchedule={setUpdatingSchedule} isEdit />
    </FormModal>
  );
};

export default EditSchedule;

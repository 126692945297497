import { useThemeTokens } from '../../../providers/themeTokenProvider';

export interface BadgeTokens {
  badge: {
    number?: {
      backgroundColor?: string,
      textColor?: string,
      borderRadius?: string,
    },
    text?: {
      borderRadius?: string,
      neutral?: {
        backgroundColor?: string,
        textColor?: string,
      },
      positive?: {
        backgroundColor?: string,
        textColor?: string,
      },
      warning?: {
        backgroundColor?: string,
        textColor?: string,
      },
      negative?: {
        backgroundColor?: string,
        textColor?: string,
      },
      supportOne?: {
        backgroundColor?: string,
        textColor?: string,
      },
      supportTwo?: {
        backgroundColor?: string,
        textColor?: string,
      },
      supportThree?: {
        backgroundColor?: string,
        textColor?: string,
      },
      supportFour?: {
        backgroundColor?: string,
        textColor?: string,
      },
      supportFive?: {
        backgroundColor?: string,
        textColor?: string,
      },
      supportSix?: {
        backgroundColor?: string,
        textColor?: string,
      },
    }
  },
}

export const useBadgeTokens = (): BadgeTokens => {
  const baseTokens = useThemeTokens();
  const { sys } = baseTokens;
  const comp: BadgeTokens = {
    badge: {
      number: {
        backgroundColor: sys.color.negative,
        textColor: sys.color.onNegative,
        borderRadius: '100px',
      },
      text: {
        borderRadius: sys.borderRadius.sm,
        neutral: {
          backgroundColor: sys.color.supportOneVariant,
          textColor: sys.color.supportOneOnVariant,
        },
        positive: {
          backgroundColor: sys.color.positiveVariant,
          textColor: sys.color.onPositiveVariant,
        },
        warning: {
          backgroundColor: sys.color.warningVariant,
          textColor: sys.color.onWarningVariant,
        },
        negative: {
          backgroundColor: sys.color.negativeVariant,
          textColor: sys.color.onNegativeVariant,
        },
        supportOne: {
          backgroundColor: sys.color.supportOneNewVariant,
          textColor: sys.color.supportOneOnNewVariant,
        },
        supportTwo: {
          backgroundColor: sys.color.supportTwoVariant,
          textColor: sys.color.supportTwoOnVariant,
        },
        supportThree: {
          backgroundColor: sys.color.supportThreeVariant,
          textColor: sys.color.supportThreeOnVariant,
        },
        supportFour: {
          backgroundColor: sys.color.supportFourVariant,
          textColor: sys.color.supportFourOnVariant,
        },
        supportFive: {
          backgroundColor: sys.color.supportFiveVariant,
          textColor: sys.color.supportFiveOnVariant,
        },
        supportSix: {
          backgroundColor: sys.color.supportSixVariant,
          textColor: sys.color.supportSixOnVariant,
        },
      },
    },
  };

  return comp;
};

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable object-curly-newline */
import { useMutation } from '@apollo/client';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { Button, Dialog, DialogContent, DialogFooter, DialogTitle, Form, TextField } from '../../../2-component';
import { FieldOptions, FormErrors, validateFields } from '../../../4-module/workflowCompletion/subSteps/utils';
import { Box } from '../../../1-primative';
import { FETCH_REP_CODES } from '..';
import { FETCH_REP_CODES_SELECT } from '../../../3-pattern/repCodeSelect/repCodeSelect';
import { UPSERT_REP_CODE } from './repCode.queries';

export const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9_]+$/;

const NewRepCode = ({ afterCreate, defaultOrg }: { afterCreate: () => void; defaultOrg?: string }) => {
  const { t } = useTranslation(['orgSettings']);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState<FormErrors>(null);
  const [focused, setFocused] = useState<string[]>([]);
  const initialRepCode = {
    code: '',
    name: '',
    organizationId: defaultOrg ?? '',
  };
  const [repCode, setRepCode] = useState(initialRepCode);

  const [upsertRepCode, { loading }] = useMutation(UPSERT_REP_CODE, {
    variables: {
      input: {
        ...repCode,
      },
    },
    refetchQueries: [FETCH_REP_CODES, FETCH_REP_CODES_SELECT],
  });

  const fieldOptions: FieldOptions = useMemo(
    () => ({
      code: { required: true },
      name: { required: true },
    }),
    [repCode],
  );

  const validate = useCallback(
    (candidateFields?: string[]): FormErrors => {
      const newErrors = validateFields(fieldOptions, repCode, candidateFields);
      setErrors(newErrors);
      return newErrors;
    },
    [repCode, fieldOptions],
  );

  useEffect(() => {
    validate(focused);
  }, [validate, focused]);

  const create = async (event: any) => {
    const formErrors = validate();
    if (formErrors) {
      setFocused(Object.keys(formErrors));
      return;
    }
    await upsertRepCode();
    setRepCode(initialRepCode);
    setFocused([]);
    setErrors(null);
    setOpen(false);
    afterCreate();
  };

  return (
    <>
      <Button label={t('shared:add')} onClick={() => setOpen(true)} leadingIcon={AddIcon} sx={{ ml: 1 }} />
      <Dialog maxWidth='sm' title={t('repCodeModal.title')} fullWidth open={open} onClose={() => setOpen(false)}>
        <Form onSubmit={create}>
          <DialogTitle onClose={() => setOpen(false)}>
            {t('repCodeModal.title')}
          </DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              overflow: 'hidden',
            }}
          >
            <Box>
              <TextField
                fullWidth
                value={repCode?.code}
                label={t('repCodeModal.code')}
                onChange={(e: any) => {
                  setRepCode({ ...repCode, code: e.target.value.toUpperCase().trim() });
                }}
                onBlur={() => {
                  setFocused([...focused, 'code']);
                }}
                helpText={t('repCodeModal.codeHelp')}
                required={true}
                onKeyDown={(event: any) => {
                  if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                error={!!errors?.code}
                errorText={errors?.code?.message}
              />
            </Box>
            <Box>
              <TextField
                fullWidth
                value={repCode?.name}
                label={t('repCodeModal.name')}
                onChange={(e: any) => {
                  setRepCode({ ...repCode, name: e.target.value });
                }}
                onBlur={() => {
                  setFocused([...focused, 'name']);
                }}
                required={true}
                error={!!errors?.name}
                errorText={errors?.name?.message}
              />
            </Box>
          </DialogContent>
          <DialogFooter>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant='tonal'
                label={t('shared:cancel')}
                onClick={() => setOpen(false)}
                sx={{ mr: 1 }}
              />
              <Button
                disabled={loading}
                label={t('shared:create')}
                type='submit'
              />
            </Box>
          </DialogFooter>
        </Form>
      </Dialog>
    </>
  );
};

export default NewRepCode;

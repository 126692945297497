/* eslint-disable react-hooks/exhaustive-deps */
import { CurrencyCodes } from '@onevesthq/ov-enums';
import dayjs from 'dayjs';
import { useLocation, useParams } from 'react-router-dom';
import { isNil, isUndefined } from 'lodash/fp';
import { useContext, useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { Box, Grid, Typography } from '../../1-primative';
import { Card, CardContent } from '../../2-component';
import { translateBackend } from '../../../assets/i18n/config';
import { ObjectType, PerformancePeriods, useStats } from '../../../providers/statsHooks';
import { usePageState } from '../../../util/usePageState';
import { MarketValueContent } from '../marketValue/marketValueContent';
import { MarketValueLineChart } from '../marketValue/marketValueLineChart';
import { type HistoricalTimeFrame, MarketValueTimeFrames } from '../marketValue/marketValueTimeFrames';
import { Highlights } from './highlights';

// TODO: Replace with new components
import { PageObjectType } from '../../5-page';
import { GoalCompletion } from './goalCompletion';
import { HouseholdMembers } from './householdMembers/householdMembers';
import { ChangeTheme } from './changeTheme/changeTheme';
import { UserContext } from '../../../providers/userContextProvider';
import { AccountHighlights } from './accountHighlights/accountHighlights';
import { RecentTransactions } from './recentTransactions/recentTransactions';
import { getUserIdFromPath } from '../../../util/getUserIdFromPath';
import { useLocalization } from '../../../util/useLocalization';
import { useThemeTokens } from '../../../providers/themeTokenProvider';

const startDateFunc = (timeFrame: HistoricalTimeFrame): string | undefined => {
  if (timeFrame === 'YTD') return dayjs().startOf('year').format('YYYY-MM-DD');
  if (timeFrame.endsWith('M')) return dayjs().subtract(Number(timeFrame.slice(0, -1)), 'month').format('YYYY-MM-DD');
  if (timeFrame.endsWith('Y')) return dayjs().subtract(Number(timeFrame.slice(0, -1)), 'year').format('YYYY-MM-DD');
  return undefined; // meaning inception date aka "MAX" time
};

const objectTypeMapping = (objectType: PageObjectType): ObjectType => {
  const types = {
    [PageObjectType.INDIVIDUAL]: ObjectType.USER,
    [PageObjectType.NON_INDIVIDUAL]: ObjectType.USER,
    [PageObjectType.HOUSEHOLD]: ObjectType.CLIENT_GROUP,
    [PageObjectType.GOAL]: ObjectType.GOAL,
    [PageObjectType.SUB_ACCOUNT]: ObjectType.SUB_ACCOUNT,
    [PageObjectType.ACCOUNT]: ObjectType.ACCOUNT,
  };
  return types[objectType];
};

export const FETCH_SUB_ACCOUNT = gql`
  query fetchSubAccount($subAccountId: ObjectID!) {
    fetchSubAccount(subAccountId: $subAccountId) {
      subAccount {
        financialProduct { id }
      }
    }
  }
`;

export const FETCH_GOAL = gql`
  query fetchGoal($goalId: ObjectID!) {
    fetchGoal(goalId: $goalId) {
      goal {
        financialProduct { id }
      }
    }
  }
`;

export const MarketValueChart = ({
  type, id, needUpdate, onlyUserId, options = {},
}: { type: PageObjectType, id: string, needUpdate?: number, onlyUserId?: string, options?: any }) => {
  const { activeCurrency, activeOrganization } = useContext(UserContext);
  const [timeFrame, setTimeFrame] = usePageState<HistoricalTimeFrame>(options.defaultTimeRange || 'MAX', 'assets/timeframe');
  const [customStartDate, setCustomStartDate] = usePageState<string>('', 'assets/startdate');
  const [customEndDate, setCustomEndDate] = usePageState<string>('', 'assets/enddate');
  const { userId, clientGroupId } = useParams();
  const { pathname: path } = useLocation();
  const { localizedDateTime } = useLocalization();
  const { sys } = useThemeTokens();

  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const [period, setPeriod] = useState<PerformancePeriods | undefined>(undefined);
  const [mouseIsOverChart, setMouseIsOverChart] = useState<boolean>(false);
  const [activeMarketValueCents, setActiveMarketValueCents] = useState<number | undefined>();
  const [activeNetContributionCents, setActiveNetContributionCents] = useState<number | undefined>();

  const showNetContributionOnHover = options.displayNetContributions && mouseIsOverChart && activeNetContributionCents !== undefined;
  const userIdFromParams = getUserIdFromPath({ userId, clientGroupId, path });

  useEffect(() => {
    setTimeFrame(options.defaultTimeRange);
  }, [options.defaultTimeRange]);

  useEffect(() => {
    if (timeFrame !== 'custom') {
      setStartDate(startDateFunc(timeFrame));
      setEndDate(undefined);
      setPeriod(undefined);
    }
    if (customStartDate && customEndDate) {
      setStartDate(dayjs(customStartDate).format('YYYY-MM-DD'));
      setEndDate(dayjs(customEndDate).format('YYYY-MM-DD'));
    }
    switch (timeFrame) {
      case '1M':
        setPeriod(PerformancePeriods.ONE_MONTH);
        break;
      case '3M':
        setPeriod(PerformancePeriods.THREE_MONTHS);
        break;
      case '6M':
        setPeriod(PerformancePeriods.SIX_MONTHS);
        break;
      case '1Y':
        setPeriod(PerformancePeriods.ONE_YEAR);
        break;
      case '5Y':
        setPeriod(PerformancePeriods.FIVE_YEARS);
        break;
      case 'YTD':
        setPeriod(PerformancePeriods.YEAR_TO_DATE);
        break;
      case 'MAX':
        setPeriod(PerformancePeriods.ALL_TIME);
        break;
      default:
        setPeriod(undefined);
    }
  }, [timeFrame, customStartDate, customEndDate]);

  const useCustodianData = [ObjectType.USER, ObjectType.ACCOUNT, ObjectType.CLIENT_GROUP, ObjectType.GOAL].includes(objectTypeMapping(type)) && options.useExternalStatistics;

  const {
    statistics, loading, history, historyLoading, refetchAll, object,
  } = useStats({
    type: objectTypeMapping(type),
    id,
    startDate,
    endDate,
    period,
    onlyUserId: type === PageObjectType.GOAL ? userIdFromParams : onlyUserId,
    useCustodianData,
    fetchHistory: options.displayMarketValueHistory,
    currency: activeCurrency,
    skipErrorHandler: true,
  });

  const hasHighlights = options.displayCashOnHoldToWithdraw || options.displayCashOnHoldToTrade || options.displayNewContributions || options.displayCashAvailable;

  const hasSideBar = () => {
    if (type === PageObjectType.ACCOUNT && !options.displayAccountHighlights && !options.showRecentTransactions) return false;
    if (type === PageObjectType.GOAL) {
      if ((options.showCompletionPercentage === false || (object && !object.targetAmountCents))
      && ((options.setModelPortfolio !== true
          && options.displayPortfolioTheme !== true
          && options.showFundFacts !== true
      ))) {
        return false;
      }
    }
    const hasNoHighlights = !options.displayCashOnHoldToWithdraw && !options.displayCashOnHoldToTrade && !options.displayNewContributions && !options.displayCashAvailable;

    if ((type === PageObjectType.INDIVIDUAL || type === PageObjectType.NON_INDIVIDUAL) && useCustodianData && hasHighlights) return false;

    if (type === PageObjectType.SUB_ACCOUNT && hasNoHighlights && !options.displaySimplifiedSubAccountStatus) return false;

    if ((type === PageObjectType.INDIVIDUAL || type === PageObjectType.NON_INDIVIDUAL) && hasNoHighlights) return false;

    return [PageObjectType.GOAL, PageObjectType.ACCOUNT, PageObjectType.HOUSEHOLD, PageObjectType.SUB_ACCOUNT, PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL].includes(type);
  };

  const sideBar = () => {
    switch (type) {
      case (PageObjectType.GOAL): {
        return (
          <>
            {(options.showCompletionPercentage !== false && (object && object.targetAmountCents)) && (
              <GoalCompletion totalMarketValue={statistics?.marketValueCents ?? 0} goalValue={object.targetAmountCents} />
            )}
            {(options.setModelPortfolio || (options.displayPortfolioTheme !== false) || options.showFundFacts) && (
              <ChangeTheme objectId={id} objectType={type} options={options} />
            )}
          </>
        );
      }
      case (PageObjectType.ACCOUNT):
        return (
          <>
            {options.displayAccountHighlights ? (
              <AccountHighlights objectId={id} options={options} useCustodianData={useCustodianData} statistics={statistics}/>
            ) : options.showRecentTransactions && (
              <RecentTransactions objectId={id} objectType={type} singleColumn options={{ useCustodianStatistics: options.useExternalStatistics }} />
            )}
          </>
        );
      case (PageObjectType.HOUSEHOLD):
        return <HouseholdMembers objectId={id} useCustodianData={useCustodianData} options={options} />;
      case (PageObjectType.SUB_ACCOUNT): {
        return (hasHighlights || options.displaySimplifiedSubAccountStatus) && (
          <Box>
            <ChangeTheme objectId={id} objectType={type} options={options} useCustodianData={useCustodianData} statistics={statistics} />
          </Box>
        );
      }
      case (PageObjectType.NON_INDIVIDUAL):
      case (PageObjectType.INDIVIDUAL): {
        return hasHighlights && (
          <Highlights options={options} useCustodianData={useCustodianData} statistics={statistics} />
        );
      }
      default:
        return null;
    }
  };

  const availableTimeframes = () => {
    const timeframes: HistoricalTimeFrame[] = [];
    if (isUndefined(options.showOneMonth) || options.showOneMonth) {
      timeframes.push('1M');
    }
    if (isUndefined(options.showThreeMonths) || options.showThreeMonths) {
      timeframes.push('3M');
    }
    if (options.showSixMonths) {
      timeframes.push('6M');
    }
    if (isUndefined(options.showOneYear) || options.showOneYear) {
      timeframes.push('1Y');
    }
    if (options.showFiveYears) {
      timeframes.push('5Y');
    }
    if (isUndefined(options.showYearToDate) || options.showYearToDate) {
      timeframes.push('YTD');
    }
    if (isUndefined(options.showAllTime) || options.showAllTime) {
      timeframes.push('MAX');
    }

    return timeframes;
  };

  /* When the needUpdate counter gets incremented */
  useEffect(() => {
    if (!loading && !historyLoading) refetchAll();
  }, [needUpdate]);

  const showTimeWeightedReturn = !!options.showTimeWeightedReturn && !isNil(statistics?.timeWeightedReturn);
  const showMoneyWeightedReturn = !!options.showMoneyWeightedReturn && !isNil(statistics?.moneyWeightedReturn);
  const showSimpleRateOfReturn = !!options.showSimpleRateOfReturn && !isNil(statistics?.simpleReturnAmountCents);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={hasSideBar() ? 8 : 12}>
        <Card fullWidthOnMobile sx={{
          height: options.displayMarketValueHistory ? '450px' : undefined,
          ...(options.displayMarketValueHistory ? {} : { background: 'transparent', boxShadow: 'none' }),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          margin: '0px !important',
        }}>
          <CardContent sx={{ ...(options.displayMarketValueHistory ? { paddingBottom: 0 } : { paddingX: { sm: 0 }, paddingBottom: 1 }) }}>
            <Grid container justifyContent='space-between'>
              <MarketValueContent
                options={options}
                statistics={statistics}
                mouseIsOverChart={mouseIsOverChart}
                loading={loading}
                showNetContributionOnHover={showNetContributionOnHover}
                showTimeWeightedReturn={showTimeWeightedReturn}
                showMoneyWeightedReturn={showMoneyWeightedReturn}
                showSimpleRateOfReturn={showSimpleRateOfReturn}
                activeNetContributionCents={activeNetContributionCents}
                activeMarketValueCents={activeMarketValueCents}
                activeCurrency={activeCurrency as CurrencyCodes}
                displayCurrency={activeOrganization.displayCurrency}
              />
              <Grid item xs={12} sm={8}>
                {options.displayMarketValueHistory && (
                  <MarketValueTimeFrames
                    id={id}
                    timeFrame={timeFrame}
                    startDate={customStartDate}
                    endDate={customEndDate}
                    availableTimeFrames={availableTimeframes()}
                    showCustom={isUndefined(options.showCustom) || options.showCustom}
                    setTimeFrame={setTimeFrame}
                    setStartDate={setCustomStartDate}
                    setEndDate={setCustomEndDate}
                  />
                )}
              </Grid>
            </Grid>
          </CardContent>

          {options.displayMarketValueHistory && (
            <MarketValueLineChart
              displayNetContributions={options?.displayNetContributions}
              history={history}
              loading={historyLoading}
              hideGraph={false}
              netContributionsLabel={translateBackend(options.netContributionsLabel)}
              mouseIsOverChart={mouseIsOverChart}
              showSimpleRateOfReturn={showSimpleRateOfReturn}
              showTimeWeightedReturn={showTimeWeightedReturn}
              showMoneyWeightedReturn={showMoneyWeightedReturn}
              setMouseIsOverChart={setMouseIsOverChart}
              setActiveMarketValueCents={setActiveMarketValueCents}
              setActiveNetContributionCents={setActiveNetContributionCents}
            />
          )}
            {options?.showLastUpdatedTimestamp && (
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                alignSelf: 'start',
                paddingBottom: '16px',
                paddingX: '16px',
                ...(options.displayMarketValueHistory ? {} : { paddingX: { sm: 0 } }),
              }}>
                <Typography data-testid='accounts-last-updated-title' variant='bodySmall' sx={{ color: sys.color.outline }}>
                  {`${translateBackend(options.lastUpdatedTimestampLabel)}: ${statistics?.lastUpdatedAt ? localizedDateTime(statistics?.lastUpdatedAt) : ''}`}
                </Typography>
              </Box>
            )}
        </Card>
      </Grid>

      {hasSideBar() && <Grid item xs={12} md={4}>{sideBar()}</Grid>}
    </Grid>
  );
};

export default MarketValueChart;

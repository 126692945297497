import { t } from 'i18next';
import { TextField } from '../../2-component';

interface NumberFieldProps {
  number: string,
  setNumber: (number: string) => void,
  label?: string,
  onBlur?: any,
  error?: any,
  sx?: any,
  disabled?: boolean
  locked?: boolean
  infoTooltip?: string,
  decimalPlaces?: number,
  testId?: string,
  trailingIcon?: 'percent' | 'calendar' | undefined,
  showArrows?: boolean,
  textSize?: string | number,
  textAlign?: 'left' | 'center' | 'right',
  InputProps?: any,
}
export const NumberField = ({
  number, setNumber, label, onBlur, error, sx, infoTooltip, disabled, locked, testId,
  decimalPlaces = 2, trailingIcon = undefined, showArrows = true, textSize = '16px', textAlign = 'left',
  InputProps,
}: NumberFieldProps) => <TextField
  testId={testId ?? 'number-field'}
  onChange={(e: any) => {
    const { value } = e.target;
    if (value.includes('.')) {
      const [whole, decimal] = value.split('.');
      if (decimal.length > decimalPlaces) {
        setNumber(`${whole}.${decimal.slice(0, decimalPlaces)}`);
        return;
      }
    }
    setNumber(value);
  }}
  label={label ?? t('components:number')}
  fullWidth
  lockMessage={t('pageConfiguration:notEditableMessage')}
  disabled={disabled}
  locked={locked}
  value={number}
  decimalPlaces={decimalPlaces}
  placeholder={ decimalPlaces ? `0.${'0'.repeat(decimalPlaces)}` : '0'}
  type="number"
  onBlur={onBlur}
  error={error}
  sx={sx}
  infoTooltip={infoTooltip}
  trailingIcon={trailingIcon}
  showArrows={showArrows}
  textSize={textSize}
  textAlign={textAlign}
  InputProps={InputProps}
/>;

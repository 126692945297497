/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { SxProps } from '@mui/material';
import { HoldingItem } from '../../../holdingItem/holdingItem';
import { useSupportColors } from '../holdings.resources';
import { Box, Skeleton, Typography } from '../../../../1-primative';
import {
  Dialog, DialogContent, DialogTitle, IconButton, EmptyState,
} from '../../../../2-component';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { translateBackend } from '../../../../../assets/i18n/config';
import { RefinitivSecurityModal } from '../../refinitivSecurityModal/refinitivSecurityModal';
import { AssetProgressBar } from '../../../../3-pattern/assetProgressBar/assetProgressBar';
import { useAccordionTokens } from '../../../../2-component/accordion/accordian.tokens';

export const AccordionHoldings = ({
  assetClassHoldings, loading, variant = 'elevated', showTotal, sx, options,
}: { assetClassHoldings: any[]; loading: boolean; variant?: 'elevated' | 'outlined' | 'none', showTotal?: boolean, sx?: SxProps, options?: any, }): JSX.Element => {
  const { t } = useTranslation('client');
  const { sys } = useThemeTokens();
  const { comp } = useThemeTokens(useAccordionTokens());
  const supportColors = useSupportColors();

  const [displayAssertClassDescriptionModal, setDisplayAssertClassDescriptionModal] = useState(false);
  const [modalHolding, setModalHolding] = useState<any>({});
  const [modalHoldingIndex, setModalHoldingIndex] = useState<number>(0);
  const [ricCode, setRicCode] = useState<string>('');
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const showAsserClassDialog = (): void => {
    setDisplayAssertClassDescriptionModal(true);
  };

  const dialogClose = (): void => {
    setDisplayAssertClassDescriptionModal(false);
  };

  return (
    <>
      {loading ? (
        <Box mt={2}>
          <Skeleton width='100%' height='300px' variant='rectangular'></Skeleton>
        </Box>
      ) : assetClassHoldings[0]?.totalCents === 0 ? (
        <Box mt={2}>
          <EmptyState>
            <Typography variant='bodyLarge'>{t('holdingsSummary.noHoldings')}</Typography>
          </EmptyState>
        </Box>
      ) : (
        <Box
          data-testid="accordion-holding"
          mt={2}
          sx={{
            boxShadow: variant === 'elevated' ? sys.elevation.surface : 'none',
            borderRadius: sys.borderRadius.xl,
            border: variant === 'outlined' ? `1px solid ${sys.color.outlineVariant}` : 'none',
          }}
        >
          {assetClassHoldings
            .sort((a, b) => b.percentage - a.percentage)
            .map((item, index) => (
              <HoldingItem
                primaryHoldingIndex={index}
                testId={`${item.assetClass.key}-${index}`}
                key={`${item.assetClass.key}-${index}`}
                allPrimaryAssetClassHoldings={assetClassHoldings}
                onClick={(secondaryAsset: any) => {
                  setModalHolding(secondaryAsset);
                  showAsserClassDialog();
                  setModalHoldingIndex(index);
                }}
                primaryAssetClassHoldings={item}
                sx={sx}
                options={options}
              />
            ))}
          {showTotal && <Box sx={{ padding: '15px 12px', borderTop: `1px solid ${comp.accordion.multipleBorderColor}` }}>
            <Box display="flex">
              <Typography variant='titleMedium'>{t('total')}</Typography>
            </Box>
            <Box sx={{ width: '100%' }} mt={2} display="flex" alignItems="center" justifyContent="space-between">
              <AssetProgressBar
                sx={{ width: '100%' }}
                data={assetClassHoldings.map((item, index) => ({
                  key: `section-${item.assetClass.key}-${index}`,
                  percentage: item?.percentage ?? 0,
                  position: 1,
                  colorCode: supportColors[index]?.active || sys.color.primary!,
                }))}
              />
              <Typography sx={{ marginLeft: '10px' }}>100%</Typography>
            </Box>
          </Box>}
        </Box>
      )}

      {displayAssertClassDescriptionModal ? (
        <Dialog open={displayAssertClassDescriptionModal} onClose={dialogClose} fullWidth maxWidth='sm'>
          <DialogTitle>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography variant='headingMedium'>{translateBackend(modalHolding?.assetClass.translatedName)}</Typography>
              <IconButton onClick={dialogClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Typography variant='bodyMedium' mb={3}>
              {translateBackend(modalHolding?.assetClass.translatedDescription)}
            </Typography>
            {(modalHolding?.securityHoldings ?? []).map((item: any, index: number) => (
              <Box width='100%' display='flex' justifyContent='space-between' mt={1} key={index}>
                <Box display='flex' justifyContent='start' alignItems='center'>
                  <Box height={16} width={16} mr={1} bgcolor={supportColors[modalHoldingIndex].active || sys.color.primary!} borderRadius={1} />
                  <Typography
                    variant='bodyMedium'
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (item?.financialProduct?.ric && item.financialProduct.ric !== '') {
                        setRicCode(item.financialProduct.ric);
                        setModalOpen(true);
                      }
                    }}
                  >
                    <b>{item.financialProduct.ticker || 'CAD'}</b> - {translateBackend(item.financialProduct.translatedName)}
                  </Typography>
                </Box>
                <Typography variant='bodyMedium' weight='bold'>
                  {Math.round(item?.percentage ?? 0)}%
                </Typography>
              </Box>
            ))}
          </DialogContent>
        </Dialog>
      ) : undefined}
      {modalOpen && (
        <RefinitivSecurityModal
          onClose={() => {
            setModalOpen(false);
            setRicCode('');
          }}
          ricCode={ricCode}
        />
      )}
    </>
  );
};

import { useContext, useEffect, useState } from 'react';
import { Grid, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  getAreasOfStudy, getJobTitles, getProfessions, AreaOfStudy, JobTitle, Profession, User, PhysicalAddress,
} from 'interfaces';
import {
  Autocomplete, Button, TextField, Typography, AddressField, Form, SelectField, Box,
} from 'ovComponents';
import { translateBackend } from 'assets/i18n/config';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import CustomFieldRenderer from 'ovComponents/3-pattern/customField/customFieldRenderer';
import { additionalInfo } from 'ovComponents/4-module/configurableOptionFields';
import InfoDialog from 'ovComponents/2-component/infoDialog/infoDialog';
import { kebabCase } from 'lodash';
import { invalidFields } from '../utils';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';

const STATUSES_REQUESTING_EMPLOYMENT_INFO = ['RETIRED', 'EMPLOYED', 'SELF_EMPLOYED', 'UNEMPLOYED'];
const NOT_EDITABLE = 'NOT_EDITABLE';
const NOT_REQUIRED = 'NOT_REQUIRED';

export const EmploymentInformationVisual = ({
  options, userData, loading, updateUser, continueFunc, activeCustomFields, workflowCompletion, grid = false, updateMode = false,
  ignoreCustomFieldTriggerRules,
}: {
  options: any, userData: Partial<User>, loading: boolean, grid?: boolean, updateMode?: boolean, activeCustomFields?: string[],
  workflowCompletion?: any, updateUser: (user: Partial<User> & { customFields: any[] }) => void, continueFunc: () => void,
  ignoreCustomFieldTriggerRules?: boolean,
}) => {
  const { sendAnalytic } = useContext(AnalyticsContext);
  const { t } = useTranslation(['client', 'employmentInfo']);
  const [focused, setFocused] = useState<string[]>([]);
  const [updated, setUpdated] = useState<boolean>(false);
  const [invalidFieldsList, setInvalidFieldsList] = useState<string[]>([]);
  const [customOptionSelected, setCustomOptionSelected] = useState<any[]>([]);

  useEffect(() => {
    const customOptions: any[] = [];
    Object.keys(options).forEach((key: string) => {
      if (options[key]?.customField && options[key].enabled && activeCustomFields?.includes(key)) {
        customOptions.push(options[key]);
      }
    });
    setCustomOptionSelected(customOptions);
  }, [options, activeCustomFields]);

  const validate = () => {
    const fields = invalidFields({
      ...options,
      studentAreaOfStudy: {
        ...options.studentAreaOfStudy,
        requiredIf: (data: any) => data?.employmentStatus === 'STUDENT',
      },
      companyType: {
        ...options.companyType,
        requiredIf: (data: any) => ['RETIRED', 'EMPLOYED', 'SELF_EMPLOYED'].includes(data?.employmentStatus),
      },
      jobTitle: {
        ...options.jobTitle,
        requiredIf: (data: any) => ['RETIRED', 'EMPLOYED', 'SELF_EMPLOYED'].includes(data?.employmentStatus),
      },
      employerCompanyAddress: {
        ...options.employerCompanyAddress,
        requiredIf: (data: any) => data?.employmentStatus === 'EMPLOYED',
      },
      employerName: {
        ...options.employerName,
        requiredIf: (data: any) => data?.employmentStatus === 'EMPLOYED',
      },
      employmentSituation: {
        ...options.employmentSituation,
        requiredIf: (data: any) => data?.employmentStatus === 'EMPLOYED',
      },
    }, userData);

    setInvalidFieldsList(fields);
    setFocused(fields);
    return fields.length === 0;
  };

  const submit = () => {
    if (validate()) {
      sendAnalytic(ovAnalyticsEvents.workflowsEmploymentInformationContinueButtonSelect, {
        workflowStepTitle: options?.title,
        workflowId: workflowCompletion?.workflow?.id,
        workflowName: workflowCompletion?.workflow?.name,
        activeWorkflowCompletionId: workflowCompletion?.id,
        objectId: workflowCompletion?.objectId,
        objectType: workflowCompletion?.objectType,
      });
      continueFunc();
      setUpdated(false);
    }
  };

  const professions = getProfessions().map((profession: Profession) => profession.title);
  const jobTitles = getJobTitles({ professionTitle: userData.companyType }).map((jobTitle: JobTitle) => jobTitle.title);
  const areasOfStudy = getAreasOfStudy().map((areaOfStudy: AreaOfStudy) => areaOfStudy.title);

  const lockMessage = (): string => (options.customLockedMessage ? translateBackend(options.customLockedMessage) : t('pageConfiguration:notEditableMessage'));

  const update = (newValue: any) => {
    setUpdated(true);
    updateUser(newValue);
  };

  const companyTypeInput = (
    !(options?.companyType?.enabled && userData.employmentStatus && STATUSES_REQUESTING_EMPLOYMENT_INFO.includes(userData.employmentStatus))
      ? null
      : (
        <Grid item xs={12} md={grid ? 4 : 12}>
          {options?.companyType?.selectField?.checked ? (
            <Autocomplete
              testId='company-type'
              label={translateBackend(options?.companyType?.label)}
              infoTooltip={additionalInfo(options?.companyType?.additionalInfo)}
              options={professions}
              value={userData.companyType ?? ''}
              fullWidth
              onChange={(_: React.SyntheticEvent, value: string) => update({ ...userData, companyType: value })}
              onBlur={() => setFocused([...focused, 'companyType'])}
              disabled={loading || options?.companyType?.required === NOT_EDITABLE}
              error={!userData.companyType && focused.includes('companyType') && options?.companyType?.required !== NOT_REQUIRED}
              locked={options?.companyType?.required === NOT_EDITABLE}
              lockMessage={lockMessage()}
              renderOption={(props: any, option:any) => (
                <li {...props} data-testid={`company-type-autocomplete-${kebabCase(option)}`}>
                  {option}
                </li>
              )}
            />
          ) : (
            <TextField
              testId="company-type"
              onChange={(e: any) => update({ ...userData, companyType: e.target.value })}
              disabled={loading || options?.companyType?.required === NOT_EDITABLE}
              locked={options?.companyType?.required === NOT_EDITABLE}
              lockMessage={lockMessage()}
              label={translateBackend(options?.companyType?.label)}
              infoTooltip={additionalInfo(options?.companyType?.additionalInfo)}
              fullWidth
              value={userData.companyType ?? ''}
              onBlur={() => setFocused([...focused, 'companyType'])}
              error={((!userData.companyType && focused.includes('companyType'))
                || invalidFieldsList.includes('companyType')) && options?.companyType?.required !== NOT_REQUIRED}
            />
          )}
        </Grid>
      )
  );

  const jobTitleInput = (
    !(options?.jobTitle?.enabled && userData.employmentStatus && STATUSES_REQUESTING_EMPLOYMENT_INFO.includes(userData.employmentStatus))
      ? null
      : (
        <Grid item xs={12} md={grid ? 4 : 12}>
          {options?.jobTitle?.selectField?.checked ? (
            <Autocomplete
              testId="job-title"
              label={translateBackend(options?.jobTitle?.label)}
              infoTooltip={additionalInfo(options?.jobTitle?.additionalInfo)}
              options={jobTitles}
              value={userData.jobTitle ?? ''}
              fullWidth
              onChange={(_: React.SyntheticEvent, value: string) => update({ ...userData, jobTitle: value })}
              onBlur={() => setFocused([...focused, 'jobTitle'])}
              disabled={loading || options?.jobTitle?.required === NOT_EDITABLE}
              error={!userData.jobTitle && focused.includes('jobTitle') && options?.jobTitle?.required !== NOT_REQUIRED}
              locked={options?.jobTitle?.required === NOT_EDITABLE}
              lockMessage={lockMessage()}
              renderOption={(props: any, option:any) => (
                <li {...props} data-testid={`job-title-${kebabCase(option)}`}>
                  {option}
                </li>
              )}
            />
          ) : (
            <TextField
              testId="job-title"
              onChange={(e: any) => update({ ...userData, jobTitle: e.target.value })}
              disabled={loading || options?.jobTitle?.required === NOT_EDITABLE}
              locked={options?.jobTitle?.required === NOT_EDITABLE}
              lockMessage={lockMessage()}
              label={translateBackend(options?.jobTitle?.label)}
              infoTooltip={additionalInfo(options?.jobTitle?.additionalInfo)}
              fullWidth
              value={userData.jobTitle ?? ''}
              onBlur={() => setFocused([...focused, 'jobTitle'])}
              error={((!userData.jobTitle && focused.includes('jobTitle'))
                || invalidFieldsList.includes('jobTitle')) && options?.jobTitle?.required !== NOT_REQUIRED}
            />
          )}
        </Grid>
      )
  );

  const areaOfStudyInput = (
    !(options?.studentAreaOfStudy?.enabled && userData.employmentStatus === 'STUDENT')
      ? null
      : (
        <Grid item xs={12} md={grid ? 4 : 12}>
          {options?.studentAreaOfStudy?.selectField?.checked ? (
            <Autocomplete
              testId="student-area-of-study"
              label={translateBackend(options?.studentAreaOfStudy?.label)}
              infoTooltip={additionalInfo(options?.studentAreaOfStudy?.additionalInfo)}
              options={areasOfStudy}
              value={userData.studentAreaOfStudy ?? ''}
              fullWidth
              onChange={(_: React.SyntheticEvent, value: string) => update({ ...userData, studentAreaOfStudy: value })}
              onBlur={() => setFocused([...focused, 'studentAreaOfStudy'])}
              disabled={loading || options?.studentAreaOfStudy?.required === NOT_EDITABLE}
              error={!userData.studentAreaOfStudy && focused.includes('studentAreaOfStudy') && options?.studentAreaOfStudy?.required !== NOT_REQUIRED}
              locked={options?.studentAreaOfStudy?.required === NOT_EDITABLE}
              lockMessage={lockMessage()}
              renderOption={(props: any, option:any) => (
                <li {...props} data-testid={`student-area-of-study-${kebabCase(option)}`}>
                  {option}
                </li>
              )}
            />
          ) : (
            <TextField
              testId="student-area-of-study"
              onChange={(e: any) => update({ ...userData, studentAreaOfStudy: e.target.value })}
              disabled={loading || options?.studentAreaOfStudy?.required === NOT_EDITABLE}
              locked={options?.studentAreaOfStudy?.required === NOT_EDITABLE}
              lockMessage={lockMessage()}
              label={translateBackend(options?.studentAreaOfStudy?.label)}
              infoTooltip={additionalInfo(options?.studentAreaOfStudy?.additionalInfo)}
              fullWidth
              value={userData.studentAreaOfStudy}
              onBlur={() => setFocused([...focused, 'studentAreaOfStudy'])}
              error={((!userData.studentAreaOfStudy && focused.includes('studentAreaOfStudy'))
                || invalidFieldsList.includes('studentAreaOfStudy')) && options?.studentAreaOfStudy?.required !== NOT_REQUIRED}
            />
          )}
        </Grid>
      )
  );

  return (
    <Form onSubmit={submit}>
      {options?.title && (
        <Typography variant='displayLarge' sx={{ mt: 1 }}>
          {translateBackend(options?.title)}
          {additionalInfo(options?.title?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.title?.additionalInfo) ?? ''} />)}
        </Typography>
      )}
      {options?.subtitle && (
        <Typography display='inline-flex' alignItems='end' variant='bodyLarge' component='div' sx={{ mb: 3, table: { width: '100%' } }}>
          <Box display='inline-block'>
            <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.subtitle)}</ReactMarkdown>
          </Box>
          {additionalInfo(options?.subtitle?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.subtitle?.additionalInfo) ?? ''} />)}
        </Typography>
      )}

      <Grid container spacing={2}>
        {options?.employmentStatus?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <SelectField
              testId="employment-status"
              onChange={(e: any) => update({ ...userData, employmentStatus: e.target.value })}
              disabled={loading || options?.employmentStatus?.required === NOT_EDITABLE}
              locked={options?.employmentStatus?.required === NOT_EDITABLE}
              lockMessage={lockMessage()}
              label={translateBackend(options?.employmentStatus?.label)}
              infoTooltip={additionalInfo(options?.employmentStatus?.additionalInfo)}
              fullWidth
              value={userData.employmentStatus ?? ''}
              onBlur={() => setFocused([...focused, 'employmentStatus'])}
              error={
                ((!userData.employmentStatus && focused.includes('employmentStatus'))
                  || invalidFieldsList.includes('employmentStatus')) && options?.employmentStatus?.required !== NOT_REQUIRED}
            >
              <MenuItem data-testid="employment-status-employed" value='EMPLOYED'>{t('edit.employmentStatusOptions.EMPLOYED')}</MenuItem>
              <MenuItem data-testid="employment-status-self-employed" value='SELF_EMPLOYED'>{t('edit.employmentStatusOptions.SELF_EMPLOYED')}</MenuItem>
              <MenuItem data-testid="employment-status-unemployed" value='UNEMPLOYED'>{t('edit.employmentStatusOptions.UNEMPLOYED')}</MenuItem>
              <MenuItem data-testid="employment-status-stay-at-home-parent" value='STAY_AT_HOME_PARENT'>{t('edit.employmentStatusOptions.STAY_AT_HOME_PARENT')}</MenuItem>
              <MenuItem data-testid="employment-status-student" value='STUDENT'>{t('edit.employmentStatusOptions.STUDENT')}</MenuItem>
              <MenuItem data-testid="employment-status-retired" value='RETIRED'>{t('edit.employmentStatusOptions.RETIRED')}</MenuItem>
            </SelectField>
          </Grid>
        )}

        {companyTypeInput}
        {jobTitleInput}
        {areaOfStudyInput}

        {options?.employerName?.enabled && userData.employmentStatus === 'EMPLOYED' && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              testId="employer-name"
              onChange={(e: any) => update({ ...userData, employerName: e.target.value })}
              disabled={loading || options?.employerName?.required === NOT_EDITABLE}
              locked={options?.employerName?.required === NOT_EDITABLE}
              lockMessage={lockMessage()}
              label={translateBackend(options?.employerName?.label)}
              infoTooltip={additionalInfo(options?.employerName?.additionalInfo)}
              fullWidth
              value={userData.employerName ?? ''}
              onBlur={() => setFocused([...focused, 'employerName'])}
              error={((!userData.employerName && focused.includes('employerName'))
                || invalidFieldsList.includes('employerName')) && options?.employerName?.required !== NOT_REQUIRED}
            />
          </Grid>
        )}
        {options?.employerCompanyAddress?.enabled && userData.employmentStatus === 'EMPLOYED' && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <AddressField
              testId="employer-company-address"
              onChange={(e: PhysicalAddress) => update({ ...userData, employerCompanyAddress: e })}
              disabled={loading || options?.employerCompanyAddress?.required === NOT_EDITABLE}
              label={translateBackend(options?.employerCompanyAddress?.label)}
              infoTooltip={additionalInfo(options?.employerCompanyAddress?.additionalInfo)}
              lockMessage={lockMessage()}
              fullWidth
              address={userData.employerCompanyAddress as PhysicalAddress}
              onFocus={() => setFocused([...focused, 'employerCompanyAddress'])}
              error={((!userData.employerCompanyAddress?.streetName && focused.includes('employerCompanyAddress'))
                || invalidFieldsList.includes('employerCompanyAddress')) && focused.includes('employerCompanyAddress') && options?.employerCompanyAddress?.required !== NOT_REQUIRED}
            />
          </Grid>
        )}
        {options?.employmentSituation?.enabled && userData.employmentStatus === 'EMPLOYED' && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <SelectField
              testId="employment-situation"
              onChange={(e: any) => update({ ...userData, employmentSituation: e.target.value })}
              disabled={loading || options?.employmentSituation?.required === NOT_EDITABLE}
              locked={options?.employmentSituation?.required === NOT_EDITABLE}
              lockMessage={lockMessage()}
              label={translateBackend(options?.employmentSituation?.label)}
              infoTooltip={additionalInfo(options?.employmentSituation?.additionalInfo)}
              fullWidth
              value={userData.employmentSituation ?? ''}
              onBlur={() => setFocused([...focused, 'employmentSituation'])}
              error={((!userData.employmentSituation && focused.includes('employmentSituation'))
                || invalidFieldsList.includes('employmentSituation')) && options?.employmentSituation?.required !== NOT_REQUIRED}
            >
              <MenuItem data-testid="employment-situation-stable-and-secure" value='STABLE_AND_SECURE'>{t('edit.employmentSituationOptions.STABLE_AND_SECURE')}</MenuItem>
              <MenuItem data-testid="employment-situation-may-change-in-future" value='STABLE_BUT_MAY_CHANGE_IN_FUTURE'>
                {t('edit.employmentSituationOptions.STABLE_BUT_MAY_CHANGE_IN_FUTURE')}
              </MenuItem>
              <MenuItem data-testid="employment-situation-somewhat-stabe" value='SOMEWHAT_STABLE'>{t('edit.employmentSituationOptions.SOMEWHAT_STABLE')}</MenuItem>
              <MenuItem data-testid="employment-situation-unemployed-but-looking" value='UNEMPLOYED_BUT_LOOKING'>{t('edit.employmentSituationOptions.UNEMPLOYED_BUT_LOOKING')}</MenuItem>
              <MenuItem data-testid="employment-situation-other" value='OTHER'>{t('edit.employmentSituationOptions.OTHER')}</MenuItem>
            </SelectField>
          </Grid>
        )}
        {options?.employmentSituation?.enabled && userData.employmentSituation === 'OTHER' && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              testId="employment-situation-other-description"
              onChange={(e: any) => update({ ...userData, employmentSituationOtherDescription: e.target.value })}
              disabled={loading || options?.employmentSituation?.required === NOT_EDITABLE}
              locked={options?.employmentSituation?.required === NOT_EDITABLE}
              lockMessage={lockMessage()}
              label={''}
              placeholder={t('other')}
              fullWidth
              value={userData.employmentSituationOtherDescription ?? ''}
              onBlur={() => setFocused([...focused, 'employmentSituation'])}
              error={((!userData.employmentSituationOtherDescription && focused.includes('employmentSituation'))
                || invalidFieldsList.includes('employmentSituation')) && focused.includes('employmentSituation') && options?.employmentSituation?.required !== NOT_REQUIRED}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <CustomFieldRenderer
            customOptions={customOptionSelected}
            customData={userData}
            update={update}
            focused={focused}
            setFocused={setFocused}
            loading={loading}
            grid={grid}
            ignoreCustomFieldTriggerRules={ignoreCustomFieldTriggerRules}
          />
        </Grid>
      </Grid>
      <Box display='flex' justifyContent='end'>
        {(!updateMode || options.displayUpdateButton) && (
          <Button
            dataTestId="employment-information-continue-button"
            label={t(updateMode ? 'update' : 'continue')}
            disabled={loading || (!updated && updateMode)}
            sx={{ mt: 3, textAlign: 'center' }}
            type='submit'
          />
        )}
      </Box>
    </Form>
  );
};

export default EmploymentInformationVisual;

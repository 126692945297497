/* eslint-disable react-hooks/exhaustive-deps */
import {
  Typography, Box, CircularProgress, Table, TableHead,
  TableRow, TableCell, TableBody, Pagination,
  Chip, Grid, TextField, MenuItem, IconButton,
  Menu, Link as MuiLink,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useCorporateContext } from '../../../pages/corporation';
import { useHouseholdContext } from '../../../pages/household';
import { DateTime } from '../../misc/dateTime/dateTime';
import { formatMoneyValue } from '../../../util';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import StateChangesModal from '../../modals/stateChangesModal';
import { usePageState } from '../../../util/usePageState';
import NewTrade from './newTrade';
import { NoPermissionAlert } from '../../misc/noPermissionAlert';
import { useClientContext } from '../../../pages/client';
import DownloadTrades from './downloadTrades';
import SecuritySelect from '../../inputs/securitySelect';
import { STATE_CHANGES_FIELD } from '../../../util/reusableGraphQLFields';

export const FETCH_TRADES_QUERY = (permissions: string[]) => `query fetchTrades($input: FetchTradesInput!) {
  fetchTrades(input: $input) {
    trades {
      id
      actualQuantity
      actualTradePriceCents
      expectedQuantity
      expectedTradePriceCents
      state
      type
      moneyAllocatedCents
      ${STATE_CHANGES_FIELD}
      rebalance { id }
      account {
        id
        type
        user {
          id
          ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
          ${permissions.includes('read:client_low_risk_pii') ? 'lastName' : ''}
        }
        organization { id }
      }
      financialProduct {
        ticker
        currency
        translatedName {
          en
        }
      }
      createdAt
      reconciledAt
    }
    totalCount
  }
}`;

export const FETCH_SUB_TRADES_QUERY = (permissions: string[]) => `query fetchSubTrades($input: FetchSubTradesInput!) {
  fetchSubTrades(input: $input) {
    subTrades {
      id
      actualQuantity
      actualTradePriceCents
      state
      type
      moneyAllocatedCents
      ${STATE_CHANGES_FIELD}
      trade {
        expectedQuantity
        expectedTradePriceCents
      }
      rebalance { id }
      subAccount {
        account {
          id
          type
          user {
            id
            ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
            ${permissions.includes('read:client_low_risk_pii') ? 'lastName' : ''}
          }
          organization { id }
        }
      }
      financialProduct {
        ticker
        currency
        translatedName {
          en
        }
      }
      createdAt
      reconciledAt
    }
    totalCount
  }
}`;

const FETCH_TRADES = (permissions: string[]) => gql`${FETCH_TRADES_QUERY(permissions)}`;

const FETCH_SUB_TRADES = (permissions: string[]) => gql`${FETCH_SUB_TRADES_QUERY(permissions)}`;

const TRANSITION_TRADE = gql`
  mutation transitionTrade($input: TransitionTradeInput!) {
    transitionTrade(input: $input) {
      trade {
        id
      }
    }
  }
`;

enum TradeStates {
  INITIATED = 'INITIATED',
  PROCESSING = 'PROCESSING',
  RECONCILED = 'RECONCILED',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
}

const transitions = [
  {
    name: 'process',
    from: [TradeStates.INITIATED],
  },
  {
    name: 'reconcile',
    from: [TradeStates.PROCESSING],
  },
  {
    name: 'fail',
    from: [TradeStates.PROCESSING, TradeStates.INITIATED],
  },
  {
    name: 'forceCancel',
    from: [TradeStates.INITIATED, TradeStates.PROCESSING],
  },
  {
    name: 'cancelReconciled',
    from: [TradeStates.RECONCILED],
  },
];

const TradesTable = ({
  showClient = false, showExpected = false, filter, onTransition = () => { }, objectType = 'TRADE',
}: { showClient?: boolean, showExpected?: boolean, filter: any, onTransition?: () => void, objectType?: 'TRADE' | 'SUB_TRADE' }) => {
  const { t } = useTranslation(['components']);
  const { permissions } = usePermissions();
  const [page, setPage] = usePageState(1, 'tra/page');
  const [state, setState] = usePageState('any', 'tra/state');
  const [activeTrade, setActiveTrade] = useState<any>({});
  const [modalOpen, setModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { activeOrganization } = useContext(UserContext);
  const clientContext = useClientContext();
  const corporateContext = useCorporateContext();
  const householdContext = useHouseholdContext();
  const [trades, setTrades] = useState<{ trades: any[], totalCount: number }>();
  const open = Boolean(anchorEl);
  const pageSize = 15;
  const organizationId = (filter?.clientGroupId || filter?.userIds)
    ? (corporateContext?.orgSettings?.id ?? householdContext?.orgSettings?.id)
    : (clientContext?.orgSettings?.id ?? activeOrganization.id);
  const canWrite = (): boolean => permissions.includes('write:trade_basic');
  const [financialProductId, setFinancialProductId] = useState<string>('ANY');

  const [transitionTrade] = useMutation(TRANSITION_TRADE);

  const isTrades = objectType === 'TRADE';

  const queryFilter = {
    organizationId: organizationId ?? activeOrganization.id,
    [isTrades ? 'tradeState' : 'subTradeState']: state === 'any' ? undefined : state,
    financialProductId: financialProductId === 'ANY' ? undefined : financialProductId,
  };

  const { loading, previousData, refetch } = useQuery(isTrades ? FETCH_TRADES(permissions) : FETCH_SUB_TRADES(permissions), {
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        filter: {
          ...filter,
          ...queryFilter,
        },
        pagination: {
          sortField: 'createdAt', sortDesc: false, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
    onCompleted(data) {
      if (isTrades) {
        setTrades({ trades: data.fetchTrades?.trades, totalCount: data.fetchTrades?.totalCount });
      } else {
        setTrades({ trades: data.fetchSubTrades?.subTrades, totalCount: data.fetchSubTrades?.totalCount });
      }
    },
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const stateColor = (forState: any) => (forState === 'RECONCILED' ? 'success' : ['FAILED', 'CANCELED'].includes(forState) ? 'error' : 'warning');

  const tradeStates = [
    'INITIATED',
    'PROCESSING',
    'RECONCILED',
    'FAILED',
    'CANCELED',
  ];

  useEffect(() => function cleanupOnUnmount() {
    setState('any');
    setPage(1);
  }, []);

  const onTradeChange = () => {
    refetch();
    onTransition();
  };

  if (!permissions.includes('read:trade_basic')) return <NoPermissionAlert missing='read:trade_basic' />;

  const getAccount = (trade: any) => (isTrades ? trade?.account : trade?.subAccount?.account);

  return (
    <Box>
      {loading && !previousData ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          {!showExpected && (
            <Grid container sx={{ p: 2 }} spacing={1}>
              <Grid item sm={3}>
                <TextField
                  select
                  value={state}
                  label={t('tradeTable.state')}
                  onChange={(e: any) => setState(e.target.value)}
                  size='small'
                  fullWidth
                  placeholder={t('any')}
                >
                  <MenuItem value={'any'}>{t('any')}</MenuItem>
                  {tradeStates.map((x: any) => (
                    <MenuItem value={x} key={x}>{t(x)}</MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item sm={3}>
                <SecuritySelect
                  value={financialProductId ?? ''}
                  setSecurity={(e) => setFinancialProductId(e?.id ?? e)}
                  label={t('components:security')}
                  size='small'
                  withAny
                />
              </Grid>

              <Grid item sm={6} sx={{ textAlign: 'right' }}>
                {permissions.includes('read:api_exports') && (<DownloadTrades filter={filter} queryFilter={queryFilter} isTrades={isTrades} />)}

                <span style={{ marginRight: '5px' }} />

                {!loading && canWrite() && (
                  <NewTrade afterCreate={onTradeChange}
                    userId={filter.userId} userIds={filter.userIds}
                    {...filter.userId && { forObject: 'USER', forId: filter.userId }}
                    {...filter.clientGroupId && { forObject: 'CLIENT_GROUP', forId: filter.clientGroupId }}
                    {...filter.accountId && { forObject: 'ACCOUNT', forId: filter.accountId }}
                  />
                )}
              </Grid>

              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                {loading ? (<CircularProgress size='30px' sx={{ marginTop: '6px' }} />) : <></>}
              </Grid>
            </Grid>
          )}
          <Grid container sx={{ overflow: 'hidden' }}>
            <Grid item xs={12} sx={{ overflow: 'auto' }}>
              <Table aria-label="table">
                <TableHead>
                  <TableRow>
                    {showClient && (
                      <TableCell><Typography variant='overline'>{t('tradeTable.client')}</Typography></TableCell>
                    )}
                    <TableCell><Typography variant='overline'>{t('tradeTable.account')}</Typography></TableCell>
                    <TableCell><Typography variant='overline'>{t('tradeTable.security')}</Typography></TableCell>
                    <TableCell><Typography variant='overline'>{t('tradeTable.type')}</Typography></TableCell>
                    {showExpected ? (
                      <>
                        <TableCell><Typography variant='overline'>{t('tradeTable.expectedQuantity')}</Typography></TableCell>
                        <TableCell><Typography variant='overline'>{t('tradeTable.expectedPrice')}</Typography></TableCell>
                        <TableCell><Typography variant='overline'>{t('tradeTable.expectedTotalValue')}</Typography></TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell><Typography variant='overline'>{t('tradeTable.quantity')}</Typography></TableCell>
                        <TableCell><Typography variant='overline'>{t('tradeTable.price')}</Typography></TableCell>
                        <TableCell><Typography variant='overline'>{t('tradeTable.totalValue')}</Typography></TableCell>
                      </>
                    )}
                    <TableCell><Typography variant='overline'>{t('tradeTable.state')}</Typography></TableCell>
                    <TableCell><Typography variant='overline'>{t('tradeTable.createdAt')}</Typography></TableCell>
                    {!showExpected && (<TableCell><Typography variant='overline'>{t('tradeTable.reconciledAt')}</Typography></TableCell>)}
                    {permissions.includes('transition:trade') && !showExpected && (
                      <TableCell align='right'><Typography variant='overline'>{t('tradeTable.actions')}</Typography></TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {trades?.trades.map((trade: any) => (
                    <TableRow
                      hover
                      onClick={() => {
                        setActiveTrade(trade);
                        setModalOpen(true);
                      }}
                      key={trade.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
                    >
                      {showClient && (
                        <TableCell component="th" scope="row">
                          <MuiLink component={Link} to={`/clients/${getAccount(trade).user.id}`}>{getAccount(trade).user.firstName} {getAccount(trade).user.lastName}</MuiLink>
                        </TableCell>
                      )}
                      <TableCell component="th" scope="row">{t(`accountTypes:${getAccount(trade).type}`)}</TableCell>
                      <TableCell component="th" scope="row"><b>{trade.financialProduct.ticker}</b></TableCell>
                      <TableCell><Chip size='small' label={trade.type} /></TableCell>
                      {showExpected || trade.state === 'INITIATED' ? (
                        <>
                          <TableCell>{isTrades ? trade?.expectedQuantity : trade?.trade?.expectedQuantity}</TableCell>
                          <TableCell>{formatMoneyValue(isTrades ? trade?.expectedTradePriceCents : trade?.trade?.expectedTradePriceCents)}</TableCell>
                          <TableCell>{formatMoneyValue(trade.moneyAllocatedCents)}</TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell>{trade.actualQuantity}</TableCell>
                          <TableCell>{formatMoneyValue(trade.actualTradePriceCents)}</TableCell>
                          <TableCell>{formatMoneyValue(trade.actualTradePriceCents * trade.actualQuantity)}</TableCell>
                        </>
                      )}

                      <TableCell>
                        <Chip
                          size='small'
                          label={trade.state}
                          color={stateColor(trade.state)}
                        />
                      </TableCell>
                      <TableCell><DateTime variant='subtitle2' date={trade.createdAt} /></TableCell>
                      {!showExpected && (<TableCell><DateTime variant='subtitle2' date={trade.reconciledAt} /></TableCell>)}
                      {permissions.includes('transition:trade') && !showExpected && (
                        <TableCell align='right'><IconButton size='small'
                          onClick={(e) => {
                            e.stopPropagation();
                            setActiveTrade(trade);
                            handleClick(e);
                          }}
                          disabled={objectType === 'SUB_TRADE' || transitions.filter((x: any) => x.from.includes(trade.state)).length === 0}
                        ><MoreVertIcon /></IconButton></TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            <Pagination
              count={Math.ceil((trades?.totalCount ?? 0) / pageSize)}
              page={page}
              onChange={(_e, newPage) => setPage(newPage)}
              sx={{
                p: 1,
                textAlign: 'right',
                '.MuiPagination-ul': {
                  justifyContent: 'end',
                },
              }}
            />
          </Grid>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {transitions.map((transition: any) => (
              transition.from.includes(activeTrade.state) && (
                <MenuItem key={transition.name} onClick={async () => {
                  await transitionTrade({ variables: { input: { tradeId: activeTrade.id, transition: transition.name } } });
                  onTradeChange();
                }}>
                  {t(`tradeTransitions.${transition.name}`)}
                </MenuItem>
              )
            ))}
          </Menu>
          <StateChangesModal
            open={modalOpen}
            handleClose={() => setModalOpen(false)}
            stateChanges={[{ to: TradeStates.INITIATED, createdAt: activeTrade.createdAt }, ...(activeTrade?.stateMachineChanges || [])]}
            title={`${activeTrade.type} - ${formatMoneyValue(activeTrade.actualTradePriceCents * activeTrade.actualQuantity)} - ${activeTrade?.financialProduct?.translatedName?.en}`}
            id={activeTrade.id}
          />
        </>
      )}
    </Box>
  );
};

export default TradesTable;

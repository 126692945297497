import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SecondaryTabs } from '../../../3-pattern';
import NotificationGroups from './components/notificationGroups';
import NotificationDefinitions from './components/notificationDefinitions';

export const Notifications = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation(['settings']);

  return (
    <>
      <SecondaryTabs tabs={[
        { label: t('notifications.groups'), icon: '' },
        { label: t('notifications.definitions'), icon: '' },
      ]}
      activeTab={activeTab} setActiveTab={(tab: number) => {
        setActiveTab(tab);
      }} />
      {activeTab === 0 && <NotificationGroups />}
      {activeTab === 1 && <NotificationDefinitions />}
    </>
  );
};

import { useContext } from 'react';
import { gql, useMutation } from '@apollo/client';
import { isNil } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import {
  NotificationChannelTypes, UserNotificationPreference,
} from '../../../interfaces';
import { Box, Typography } from '../../1-primative';
import { Switch } from '../../2-component';
import { translateBackend } from '../../../assets/i18n/config';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { UserContext } from '../../../providers/userContextProvider';

const SET_USER_NOTIFICATION_PREFERENCES = gql`
  mutation setUserNotificationPreferences($input: setUserNotificationPreferencesInput!) {
    setUserNotificationPreferences(input: $input) {
      organizationUser {
        id
      }
    }
  }
`;

interface NotificationPreferencesSettingsProps {
  notificationPreference: UserNotificationPreference,
  notificationPreferences: UserNotificationPreference[],
  setNotificationPreferences: (value: any) => void
}

export const NotificationPreferencesSettings = ({ notificationPreference, notificationPreferences, setNotificationPreferences }: NotificationPreferencesSettingsProps) => {
  const { t } = useTranslation('components');
  const { sys } = useThemeTokens();
  const { userContext } = useContext(UserContext);

  const [setUserNotificationPreferences] = useMutation(SET_USER_NOTIFICATION_PREFERENCES);

  const updateNotificationPreferences = (key: string, value: boolean) => {
    const notificationPreferencesInput: any = [];
    const newNotificationPreferences = notificationPreferences.map((elem: UserNotificationPreference) => {
      let preferences = { ...elem.preferences };
      if (elem.notificationGroup.id === notificationPreference.notificationGroup.id) preferences = { ...preferences, [key]: value };
      notificationPreferencesInput.push({ notificationGroupId: elem.notificationGroup.id, preferences });
      return { ...elem, preferences };
    });
    setNotificationPreferences(newNotificationPreferences);
    setUserNotificationPreferences({
      variables: {
        input: {
          organizationUserId: userContext.id,
          userNotificationPreferences: notificationPreferencesInput,
        },
      },
    });
  };

  const disablePreferenceToggle = (channelType: NotificationChannelTypes) => {
    const value = (notificationPreference.notificationGroup?.notificationChannels ?? []).find((notificationChannel) => notificationChannel.type === channelType)?.allowEditByClient;
    if (isNil(value)) return value;
    return !value;
  };

  if (!notificationPreference.notificationGroup) return <></>;

  return (
    <Box display='flex' flexDirection='column' gap={1.5}>
      <Box>
        <Typography variant='titleLarge'>{translateBackend(notificationPreference.notificationGroup?.name)}</Typography>
        {notificationPreference.notificationGroup?.description && (
          <Typography variant='bodyMedium' sx={{ mt: 0.25, fontWeight: 400, color: sys.color.onSurfaceVariant }}>
            {translateBackend(notificationPreference.notificationGroup?.description)}
          </Typography>
        )}
      </Box>
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='bodyLarge' sx={{ fontWeight: 500 }}>{t('notificationSettings.options.inApp')}</Typography>
        <Switch
          checked={notificationPreference.preferences?.enableInApp}
          disabled={disablePreferenceToggle(NotificationChannelTypes.IN_APP)}
          onChange={(e) => updateNotificationPreferences('enableInApp', e)}
        />
      </Box>
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='bodyLarge' sx={{ fontWeight: 500 }}>{t('notificationSettings.options.push')}</Typography>
        <Switch
          checked={notificationPreference.preferences?.enablePush}
          disabled={disablePreferenceToggle(NotificationChannelTypes.PUSH)}
          onChange={(e) => updateNotificationPreferences('enablePush', e)}
        />
      </Box>
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='bodyLarge' sx={{ fontWeight: 500 }}>{t('notificationSettings.options.email')}</Typography>
        <Switch
          checked={notificationPreference.preferences?.enableEmail}
          disabled={disablePreferenceToggle(NotificationChannelTypes.EMAIL)}
          onChange={(e) => updateNotificationPreferences('enableEmail', e)}
        />
      </Box>
    </Box>
  );
};

import { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { UserContext } from '../../../providers/userContextProvider';
import { Autocomplete } from '../../2-component';
import { translateBackend } from '../../../assets/i18n/config';

export const FETCH_ROLE_PROFILES = gql`
  query fetchRoleProfiles($input: FetchRoleProfilesInput!) {
    fetchRoleProfiles(input: $input) {
      roleProfiles {
        id
        publicOrganization {
          id
          name
        }
        translatedName {
          en
          fr
        }
        translatedDescription {
          en
        }
      }
    }
  }
`;

export const RoleProfileSelect = ({
  selectedRoleProfileIds, onChange, label, onBlur, error, testId, disabled = false,
}: {
  selectedRoleProfileIds: any[], onChange: (e: any) => void, label: string, onBlur?: () => void, error?: boolean, testId?: string, disabled?: boolean,
}) => {
  const { activeOrganization } = useContext(UserContext);
  const roleProfiles = useQuery(FETCH_ROLE_PROFILES, {
    variables: {
      input: {
        filter: { organizationId: activeOrganization.id },
        pagination: { perPage: 1000 },
      },
    },
  });

  if (roleProfiles.loading) return <div></div>;

  const getRoleProfileNames = (roleProfileIds: any): string[] => roleProfileIds.map((profile: any) => {
    const fetchedProfile = roleProfiles?.data?.fetchRoleProfiles?.roleProfiles?.find(
      (fetched: any) => fetched?.id === (profile.id || profile),
    );
    return { id: fetchedProfile?.id, name: fetchedProfile?.translatedName, orgName: fetchedProfile?.publicOrganization?.name };
  }).filter((obj: any) => Object.keys(obj).length !== 0);

  return (
    <Autocomplete
      label={label}
      options={roleProfiles?.data?.fetchRoleProfiles?.roleProfiles?.map((x: any) => ({
        key: x.id,
        label: `${translateBackend(x?.translatedName)}${x?.publicOrganization?.name ? ` - ${x?.publicOrganization?.name}` : ''}`,
      })) || []}
      value={getRoleProfileNames(selectedRoleProfileIds || [])?.map((x: any) => ({
        key: x.id,
        label: `${translateBackend(x.name)}${x.orgName ? ` - ${x.orgName}` : ''}`,
      })) || []}
      isOptionEqualToValue={(option: any, value: any) => option.key === value.key}
      multiple
      fullWidth
      disabled={disabled}
      onChange={(e: React.SyntheticEvent, value: any) => onChange(value.map((x: any) => x.key))}
      onBlur={onBlur}
      error={error}
      testId={testId}
    />
  );
};

import { gql } from '@apollo/client';
import { STATE_CHANGES_FIELD } from '../../../../util/reusableGraphQLFields';

export const FETCH_TRANSFERS_QUERY = (permissions: string[]) => `query fetchTransfers($input: FetchTransfersInput!) {
  fetchTransfers(input: $input) {
    transfers {
      id
      amountCents
      initiatedByRepresentative
      state
      type
      source
      sourceId
      ${STATE_CHANGES_FIELD}
      instantInvest
      dividendFor {
        ticker
      }
      fromSubAccount {
        id
        account {
          type
        }
      }
      failReason
      cancelReason
      cancelReconciledReason
      bankAccount {
        id
        name
        institutionNumber
        bankAccountNumber
        transitNumber
        ${permissions.includes('read:bank_account_disclosed') ? 'transitNumberDisclosed' : ''}
        ${permissions.includes('read:bank_account_disclosed') ? 'bankAccountNumberDisclosed' : ''}
      }
      isWithdrawAll
      ${permissions.includes('read:alert_report_basic') ? 'isSuspicious' : ''}
      ${permissions.includes('write:alert_report_basic') ? `
        alertReport {
          id
          rules {
            type
            translatedDescription {
              en
            }
          }
        }
      ` : ''}
      subAccount {
        id
        account {
          id
          type
          user {
            id
            ${permissions.includes('read:client_low_risk_pii') ? 'firstName middleName lastName' : ''}
          }
          organization {
            id
          }
        }
        goal {
          name
        }
        financialProduct {
          translatedName {
            en
          }
        }
        statistics {
          marketValueCents
          moneyAvailableCents
          availableFundsToWithdrawCents
        }
      }
      createdAt
      reconciledAt
      isRecurring
      manualProcess {
        id
        type
        signedUrl
        institution { name }
        transferAccount
        transferAccountNumber
        transferMethod
      }
    }
    totalCount
  }
}`;

export const FETCH_TRANSFERS = (permissions: string[]) => gql`${FETCH_TRANSFERS_QUERY(permissions)}`;

export const TRANSITION_TRANSFER = gql`
  mutation transitionTransfer($input: TransitionTransferInput!) {
    transitionTransfer(input: $input) {
      transfer {
        id
      }
    }
  }
`;

import { gql, useQuery } from '@apollo/client';
import {
  Dispatch, SetStateAction, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { CountryCodes } from '@onevesthq/ov-enums';
import { Typography, Box } from '../../../../1-primative';
import {
  Checkbox, Chip, MenuItem, SelectField, TextField,
} from '../../../../2-component';
import { countryList, getCountryName } from '../../../../resources';
import { BareLocalization } from '../../../../../interfaces';
import { resources as supportedLanguages } from '../../../../../assets/i18n/config';

const FETCH_COUNTRY_AND_CURRENCY_CODES = gql`
  query fetchCurrencyCodes {
    CurrencyCodes:__type(name: "CurrencyCodes") {
      enumValues { name }
    }
  }
`;

export const LocalizationForm = (
  {
    localization,
    setLocalization,
    readonly = false,
  }: {
    localization: BareLocalization,
    setLocalization: Dispatch<SetStateAction<BareLocalization>>
    readonly?: boolean
  },
) => {
  const { t } = useTranslation('orgSettings');
  const { data: codes, loading: codesLoading } = useQuery(FETCH_COUNTRY_AND_CURRENCY_CODES);

  const handleLanguageCheckbox = (value: any) => {
    if (!localization.languages.includes(value)) {
      setLocalization((previous) => ({ ...previous, languages: [...previous.languages, value] }));
    } else {
      setLocalization((previous) => ({ ...previous, languages: previous.languages.filter((l) => l !== value) }));
    }
  };

  useEffect(() => {
    if (localization.languages.length === 0) {
      setLocalization({ ...localization, defaultLanguage: '' });
    }
    if (localization.languages.length === 1) {
      setLocalization({ ...localization, defaultLanguage: localization.languages[0] });
    }
    if (localization.languages.length > 1 && !localization.languages.includes(localization.defaultLanguage)) {
      setLocalization({ ...localization, defaultLanguage: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localization.languages]);

  const languageName = (lang: string) => t(`localizationSettings.language.${lang}`);

  return (
    <Box display='flex' flexDirection='column' gap={2}>
      <TextField
        fullWidth
        value={localization.name}
        label={t('localizationModal.name')}
        onChange={(e: any) => setLocalization({ ...localization, name: e.target.value })}
        helpText={t('localizationModal.nameHelper')}
        required
        disabled={readonly}
      />

      {/* Country Select */}
      <SelectField multiple
        value={localization.countries}
        fullWidth
        label={t('localizationModal.countries')}
        onChange={(e: any) => setLocalization({ ...localization, countries: e.target.value })}
        renderValue={(selected: any) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value: any) => (
              <Chip key={value} text={`${value} - ${getCountryName(value)}`} />
            ))}
          </Box>
        )}
        required
        disabled={readonly}
      >
        {countryList.map((country, index) => <MenuItem key={index} value={country.code}>
          <Checkbox checked={localization.countries.indexOf(country.code as CountryCodes) > -1} label={`${country.code} - ${country.name}`} />
        </MenuItem>)}
      </SelectField>
      {/* Currency Select */}
      <SelectField
        fullWidth
        value={localization.defaultCurrency}
        label={t('localizationModal.defaultCurrency')}
        onChange={(e: any) => setLocalization({ ...localization, defaultCurrency: e.target.value })}
        helpText={t('localizationModal.defaultCurrencyHelper')}
        required
        disabled={codesLoading || readonly}
      >
        {codes?.CurrencyCodes.enumValues.map((enumValue: any, index: number) => <MenuItem key={index} value={enumValue.name}>
          {enumValue.name}
        </MenuItem>)}
      </SelectField>
      {/* Languages Select */}
      <Typography variant='labelSmall' colorVariant='variant'>{t('localizationModal.languages')}</Typography>
      {Object.keys(supportedLanguages).map((lang) => <Checkbox
        checked={localization.languages.includes(lang)}
        onChange={() => handleLanguageCheckbox(lang)}
        label={languageName(lang)}
      />)
      }
      {localization.languages.length > 1 && (
        <SelectField
          fullWidth
          label={t('localizationModal.defaultLanguage')}
          value={localization.defaultLanguage}
          onChange={(e: any) => setLocalization({ ...localization, defaultLanguage: e.target.value })}
          disabled={readonly}
        >
          {localization.languages.map((lang: any) => <MenuItem key={lang} value={lang}>
            {languageName(lang)}
          </MenuItem>)}
        </SelectField>
      )}

      {/* Date Format and Examples */}
        <Box display='flex' justifyContent='space-between'>
          <Box>
            <TextField
              value={localization.dateFormat}
              label={t('localizationModal.dateFormat')}
              onChange={(e: any) => setLocalization({ ...localization, dateFormat: e.target.value })}
              required
              disabled={readonly}
            />
            <Typography variant='labelSmall'>
              {t('localizationModal.dateFormatHelper1')}
              <a href="https://day.js.org/docs/en/display/format" target='_blank' rel='noreferrer'>
                {t('localizationModal.dateFormatHelper2')}
              </a>
            </Typography>
          </Box>
          <Box>
            <Typography>{t('localizationModal.datePreview')}:</Typography>
            {localization.dateFormat && (<>
              <Typography>
                {dayjs().set('date', 1).format(localization.dateFormat)}&nbsp;
                ({t('localizationModal.firstDay')})
              </Typography>
              <Typography>
                {dayjs().format(localization.dateFormat)}&nbsp;
                ({t('localizationModal.today')})
              </Typography>
              <Typography>
                {dayjs().endOf('month').format(localization.dateFormat)}&nbsp;
                ({t('localizationModal.lastDay')})
              </Typography>
            </>)}
          </Box>
        </Box>
    </Box>
  );
};

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en, { namespaces, defaultNS } from './en';
import fr from './fr';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';

import { Languages, TranslatedLanguage } from '../../interfaces';

export const resources = {
  en,
  fr,
} as const;

i18n.use(LanguageDetector).init();
i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  ns: namespaces,
  defaultNS,
  interpolation: { escapeValue: false },
  resources,
});

export const getBackendLanguage = (): TranslatedLanguage => {
  if (i18n.language === 'fr') return TranslatedLanguage.fr;
  return TranslatedLanguage.en;
};

export const setBackendLanguage = (language: Languages) => {
  const languageI18n = {
    ENGLISH: TranslatedLanguage.en,
    FRENCH: TranslatedLanguage.fr,
  };
  i18n.changeLanguage(languageI18n[language]);
};

export const translateBackend = (translatedObject: any, fallbackLanguage: TranslatedLanguage = TranslatedLanguage.en): string => (
  translatedObject?.[getBackendLanguage()]
    ? translatedObject[getBackendLanguage()]
    : translatedObject?.[fallbackLanguage]
    ?? ''
);

export const getTranslatedObject = (translatedObject: any, fallbackLanguage: TranslatedLanguage = TranslatedLanguage.en): any => (
  translatedObject?.[getBackendLanguage()]
    ? translatedObject[getBackendLanguage()]
    : translatedObject?.[fallbackLanguage]
);

export default i18n;

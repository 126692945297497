import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import { AddTwoTone } from '@mui/icons-material';
import { kebabCase } from 'lodash';
import { Box, Typography } from '../../../../../1-primative';
import { Button, EmptyState } from '../../../../../2-component';
import { CompletionBox } from '../../../../../3-pattern/completionBox/completionBox';
import {
  AccountTypes, Affiliation,
} from '../../../../../../interfaces';
import { UserContext } from '../../../../../../providers/userContextProvider';
import { AffiliateModal, UPDATE_AFFILIATIONS } from './affiliateModal';
import { type FieldType, FieldTypeOption, RelationType } from '../accountConfig';
import { AffiliateCompletionBox } from './affiliateCompletionBox';
import { WorkflowContext } from '../../../workflowCompletion';
import {
  ActionContext,
} from '../../../../..';
import { InnerWorkflowModal } from '../../../../../3-pattern/innerWorkflowModal/innerWorkflowModal';
import { usePageState } from '../../../../../../util/usePageState';
import { InitialAffiliate, InitiatedAffiliate } from './initialAffiliate';

export const WORKFLOW_COMPLETION = `#graphql
  workflowCompletion {
    id
    objectId
    objectType
    context
    workflow {
      id
      complex
      name { en fr }
      steps {
        id
        name { en fr }
        subSteps { id type }
      }
    }
    currentStep {
      id
      name { en fr }
      subSteps { id type }
    }
    currentSubStep {
      id
      options
      type
      canGoBack
      skippable
      rolesCompleteableBy { id }
    }
  }
`;

const CREATE_WORKFLOW_COMPLETION = gql`
  mutation createWorkflowCompletion($input: CreateWorkflowCompletionInput!) {
    createWorkflowCompletion(input: $input) {
      ${WORKFLOW_COMPLETION}
    }
  }
`;

const UPDATE_WORKFLOW_COMPLETION = gql`
  mutation updateWorkflowCompletion($input: UpdateWorkflowCompletionInput!) {
    updateWorkflowCompletion(input: $input) {
      ${WORKFLOW_COMPLETION}
    }
  }
`;

export enum AffiliateType {
  AUTHORIZED_INDIVIDUAL = 'AUTHORIZED_INDIVIDUAL',
  BENEFICIAL_OWNER = 'BENEFICIAL_OWNER',
  CONTINGENT_BENEFICIARY = 'CONTINGENT_BENEFICIARY',
  CONTRIBUTOR = 'CONTRIBUTOR',
  DIRECTOR = 'DIRECTOR',
  GRANTOR = 'GRANTOR',
  JOINT = 'JOINT',
  OTHER = 'OTHER',
  POWER_OF_ATTORNEY = 'POWER_OF_ATTORNEY',
  PRIMARY_BENEFICIARY = 'PRIMARY_BENEFICIARY',
  PROTECTOR = 'PROTECTOR',
  SETTLOR = 'SETTLOR',
  SUCCESSOR = 'SUCCESSOR',
  THIRD_PARTY = 'THIRD_PARTY',
  TRUSTEE = 'TRUSTEE',
  DECEDENT = 'DECEDENT',
}

export const respAccountTypes = ['RESP_FAMILY', 'RESP_FAMILY_JOINT', 'RESP_SINGLE', 'RESP_SINGLE_JOINT'];

export const Affiliate = ({
  type, account, affiliates = [], multi = false, showAllocation = false, showRelationship = false, fields = [], optionalFields = [],
  defaultRelationType, refetch, updateAccount, onEdit, allAffiliates, updateMode = false, edittable = true, title, useAccountHoldersAddress,
  error, errorText, supportText, options, actionTitle, hideDelete = false, canUseExistingAffiliate,
}: {
  type: AffiliateType; account: any; affiliates: Affiliation[]; multi: boolean; showAllocation?: boolean; showRelationship?: boolean;
  showMissingAffiliate?: boolean; fields?: FieldType[]; optionalFields: FieldType[]; defaultRelationType?: RelationType; refetch?: () => void;
  updateAccount?: (account: any) => void; onEdit: () => void; allAffiliates: Affiliation[]; updateMode?: boolean; edittable?: boolean; title?: string;
  useAccountHoldersAddress?: boolean; error?: boolean; errorText?: string; supportText?: string; options?: any; actionTitle?: string; hideDelete?: boolean; canUseExistingAffiliate?: boolean
}) => {
  const { t } = useTranslation(['affiliationTypes', 'client']);
  const { userContext, activeOrganization } = useContext(UserContext);
  const { workflowData } = useContext(WorkflowContext);
  const { refetch: refetchWorkflow, activeWorkflows } = useContext(ActionContext);
  const [newActiveWorkflow, setNewActiveWorkflow] = useState<any>();
  const [workflowCompletionId] = usePageState<string | undefined>(undefined, 'workflow');

  const activeParentWorkflow = activeWorkflows.find((x: any) => x?.id === workflowCompletionId);
  const [workflowCompletion] = useState<any>({
    ...activeParentWorkflow,
  });
  const [newJointAffiliate, setNewJointAffiliate] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);
  const [innerOpen, setInnerOpen] = useState(false);
  const [openBasicAffiliateModal, setOpenBasicAffiliateModal] = useState(false);

  const [action, setAction] = useState<'create' | 'edit'>('create');
  const [activeAffiliate, setActiveAffiliate] = useState<any>(null);

  const [updateWorkflowCompletion] = useMutation(UPDATE_WORKFLOW_COMPLETION);

  const [createWorkflowCompletion] = useMutation(CREATE_WORKFLOW_COMPLETION, {
    onCompleted: (data: any) => {
      refetchWorkflow();
      setNewActiveWorkflow({
        ...data.createWorkflowCompletion.workflowCompletion,
        context: workflowData,
      });
      setInnerOpen(true);
      if (workflowCompletion?.id) {
        const updatingContext = {
          ...workflowData,
          childWorkflowCompletion: data.createWorkflowCompletion.workflowCompletion,
        };
        updateParentWorkflowCompletion(workflowCompletion.id, updatingContext);
      }
    },
  });

  const updateParentWorkflowCompletion = (parentWorkflowComplationId: string, updatedContext: any) => {
    updateWorkflowCompletion({
      variables: {
        input: {
          workflowCompletionId: parentWorkflowComplationId,
          context: updatedContext ?? undefined,
        },
      },
    });
  };

  useEffect(() => {
    if (workflowCompletion && workflowCompletion?.context?.childWorkflowCompletion) {
      setNewActiveWorkflow({
        ...workflowCompletion?.context?.childWorkflowCompletion,
        context: workflowData,
      });
    }
  }, [workflowCompletion, workflowData]);

  const [updateAffiliations] = useMutation(UPDATE_AFFILIATIONS);

  const getJointHolderWorkflowId = (accountType: AccountTypes) => {
    if (accountType === AccountTypes.CASH_JOINT && options.enableJointAccountNewJointHolder) {
      return options.jointAccountHolderWorkflow;
    }
    if (accountType === AccountTypes.RESP_FAMILY_JOINT && options.enableJointFamilyRESPNewJointHolder) {
      return options.jointFamilyRESPHolderWorkflow;
    }
    if (accountType === AccountTypes.RESP_SINGLE_JOINT && options.enableJointSingleRESPNewJointHolder) {
      return options.jointSingleRESPHolderWorkflow;
    }
    return 'default';
  };

  const onInitialAffiliateCreation = (initialAffiliate: InitiatedAffiliate) => {
    const jointWorkflowId = getJointHolderWorkflowId(account?.type as AccountTypes);
    setNewJointAffiliate({
      userId: initialAffiliate.id,
      relation: initialAffiliate.relation,
      type: AffiliateType.JOINT,
    });
    setOpenBasicAffiliateModal(false);
    createWorkflowCompletion({
      variables: {
        input: {
          objectId: initialAffiliate.id,
          objectType: 'USER',
          workflowId: jointWorkflowId,
          organizationId: activeOrganization?.id,
          parentWorkflowCompletionId: workflowCompletion?.id,
        },
      },
    });
  };

  const createOrEditAffiliate = (actionType: 'create' | 'edit', aff?: Affiliation) => {
    setActiveAffiliate(aff ?? { relation: defaultRelationType || undefined, user: {} });
    const jointWorkflowId = getJointHolderWorkflowId(account?.type as AccountTypes);
    const featureFlagEnabled = activeOrganization.availableFeatureFlags?.includes('ENABLE_JOINT_AFFILIATIONS_FLOW') || false;
    const isJointAffiliateWithWorkflow = type === AffiliateType.JOINT && jointWorkflowId !== 'default';
    if (featureFlagEnabled && isJointAffiliateWithWorkflow) {
      if (actionType === 'create') {
        setOpenBasicAffiliateModal(true);
        setAction(actionType);
      }
      if (aff && aff?.user?.id && actionType === 'edit') {
        setAction(actionType);
        // initiate update affiliate child workflow
        createWorkflowCompletion({
          variables: {
            input: {
              objectId: aff?.user?.id,
              objectType: 'USER',
              workflowId: jointWorkflowId,
              organizationId: activeOrganization?.id,
              parentWorkflowCompletionId: workflowCompletion?.id,
            },
          },
        });
      }
    } else {
      setAction(actionType);
      setOpen(true);
    }
  };

  const removeAffiliate = (affiliateId: string) => {
    const newAff = allAffiliates.filter((item: any) => item.id !== affiliateId);
    const allocationEnabled = fields.filter((f: FieldType) => ['allocation', 'allocated'].includes(f.type as FieldTypeOption))?.length > 0;

    updateAffiliations({
      variables: {
        input: {
          accountId: account.accountId,
          affiliations: newAff.map((item: any) => ({
            allocation: item.type === type && allocationEnabled ? Math.floor(100 / ((allAffiliates.filter((x: any) => x.type === item.type).length || 0) - 1)) : item.allocation,
            relation: item.relation,
            type: item.type,
            userId: item.user.id,
          })),
        },
      },
      onCompleted: refetch,
    });
  };

  const updateAccountAffiliations = () => {
    const currentAffiliates = account?.affiliations || [];
    if (action === 'create') {
      const updatedAffliates = currentAffiliates.concat(newJointAffiliate);
      updateAffiliations({
        variables: {
          input: {
            accountId: account.accountId,
            affiliations: updatedAffliates,
          },
        },
        onCompleted: (data: any) => {
          if (refetch) refetch();
        },
      });
    }
    if (action === 'edit') {
      if (refetch) refetch();
    }
  };

  const onChange = (event: any, affiliateId: string) => {
    const newAff = allAffiliates.map((item: any) => {
      if (item.id === affiliateId) {
        return { ...item, allocation: parseInt(event.target.value, 10) };
      }
      return item;
    });

    onEdit();
    updateAccount?.({ ...account, affiliations: newAff });
  };

  const defaultAffiliateType = type === 'OTHER' && defaultRelationType ? defaultRelationType : type;

  const showContributionAmount = options.useCustodianAffiliates && options.hideContributionAmount === false && type === 'PRIMARY_BENEFICIARY' && respAccountTypes.includes(account.type);
  const showGrantAmount = options.useCustodianAffiliates && options.hideGrantAmount === false && type === 'PRIMARY_BENEFICIARY' && respAccountTypes.includes(account.type);

  if (affiliates.length === 0 && !edittable) {
    return (
      <Box mb={2}>
        <EmptyState>
          <Typography variant='bodyLarge'>{t('noLinkedAffiliate', { type: t(defaultAffiliateType) })}</Typography>
        </EmptyState>
      </Box>
    );
  }

  return (
    <>
      {affiliates.length > 0 ? (
        affiliates.map((affiliate: any) => (
          <AffiliateCompletionBox
            testId={`affiliate-completion-box-${kebabCase(type)}`}
            updateMode={updateMode}
            affiliate={affiliate}
            title={title ?? t(defaultAffiliateType)}
            edittable={edittable}
            hideDelete={hideDelete}
            error={error}
            errorText={errorText}
            showRelationship={showRelationship}
            showAllocation={showAllocation}
            supportText={supportText}
            supportUrl={userContext.organization?.supportUrl}
            createOrEditAffiliate={createOrEditAffiliate}
            removeAffiliate={removeAffiliate}
            onChange={onChange}
            showContributionAmount={showContributionAmount}
            showGrantAmount={showGrantAmount}
          />
        ))
      ) : (
        <CompletionBox
          testId={`affiliate-completion-box-${type}`}
          variant={updateMode ? 'view' : 'edit'}
          state='todo'
          edittable
          title={actionTitle ?? t(`button.${defaultAffiliateType}`)}
          onAdd={() => createOrEditAffiliate('create')}
          error={error}
          errorText={errorText}
        >
          <Typography variant='bodyMedium'>{t(`description.${account.type}.${defaultAffiliateType}`)}</Typography>
        </CompletionBox>
      )}

      {multi && edittable && affiliates.filter((x: any) => x.type === type).length >= 1 && (
        <Button variant='text' label={t(`button.${type}`)} leadingIcon={AddTwoTone} onClick={() => createOrEditAffiliate('create')} sx={{ mb: 1 }} />
      )}

      <AffiliateModal
        data-testid='affiliate-modal'
        open={open}
        setOpen={setOpen}
        type={type}
        accountId={account.accountId}
        fields={fields}
        optionalFields={optionalFields}
        affiliate={activeAffiliate || { relation: defaultRelationType || undefined, user: {} }}
        allAffiliates={allAffiliates}
        affiliates={affiliates}
        refetch={refetch}
        action={action}
        useAccountHoldersAddress={useAccountHoldersAddress}
        account={account}
        title={actionTitle}
        canUseExistingAffiliate={canUseExistingAffiliate}
        options={options}
      />

      {newActiveWorkflow && (
        <InnerWorkflowModal
          newActiveWorkflow={newActiveWorkflow}
          setOpen={setInnerOpen}
          open={innerOpen}
          refetch={() => {
            refetchWorkflow();
            updateAccountAffiliations();
          }}
          updateParent={(activeCompletion?: any) => {
            if (!activeCompletion) {
              delete workflowData.childWorkflowCompletion;
              updateParentWorkflowCompletion(workflowCompletion.id, {
                ...workflowData,
              });
            } else {
              const updatingContext = {
                ...workflowData,
                childWorkflowCompletion: activeCompletion,
              };
              updateParentWorkflowCompletion(workflowCompletion.id, updatingContext);
            }
          }}
        />
      )}

      {openBasicAffiliateModal && <InitialAffiliate open={openBasicAffiliateModal} setOpen={setOpenBasicAffiliateModal} onCreated={onInitialAffiliateCreation} />}
    </>
  );
};

import { useContext, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { CollectSignersVisual } from './collectSigners.visual';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';

const FETCH_USER_LATEST_INTEGRATION_FORM_AGREEMENT = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        firstName
        lastName
        primaryEmail
        latestFormAgreements {
          id
          type
          integrations {
            iid
            externalData
          }
        }
      }
    }
  }
`;

export const UPDATE_INTEGRATION_FORM_AGREEMENT = gql`
  mutation sendIntegrationFormAgreement($input: SendIntegrationFormAgreementInput!) {
    sendIntegrationFormAgreement(input: $input) {
      formAgreement {
        id
      }
    }
  }
`;

/**
 * TODO This is PWL specific. This needs to be changed when the collectSigners step is being scaled for other clients
*/
export const isAccountHolder = (role: any) => role.roleName === 'client1';

export const CollectSigners = ({
  options, userId, onNext, stepLoading, workflowCompletion,
}: { options: any; userId: any; onNext: () => void; stepLoading: boolean, workflowCompletion?: any, }) => {
  const { sendAnalytic } = useContext(AnalyticsContext);
  const [signingRoles, setSigningRoles] = useState<any>([]);
  const [integrationFormAgreement, setIntegrationFormAgreement] = useState<any>({});

  const { loading } = useQuery(FETCH_USER_LATEST_INTEGRATION_FORM_AGREEMENT, {
    variables: { userId },
    fetchPolicy: 'no-cache',
    onCompleted: async (data: any) => {
      const formAgreements = data?.fetchUser?.user?.latestFormAgreements?.filter((f: any) => f.type === 'INTEGRATION');
      if (formAgreements.length > 0) {
        /**
         * TODO We may need to update this to support multiple integrations, right now there's only 1 so it works.
         * In the future we may need to update the WorkflowCompletion schema to pass along an iid for us to filter on.
         */
        setIntegrationFormAgreement(formAgreements[0]);

        const signerRoles = formAgreements[0]?.integrations[0]?.externalData?.signers?.filter((sr: any) => sr.roleType === 'SIGNER');

        const signerRolesWithAccountHolderInfo = signerRoles?.map((r: any) => {
          if (isAccountHolder(r)) {
            return {
              ...r,
              firstName: data?.fetchUser?.user?.firstName,
              lastName: data?.fetchUser?.user?.lastName,
              email: data?.fetchUser?.user?.primaryEmail,
            };
          }
          return r;
        });
        setSigningRoles(signerRolesWithAccountHolderInfo);
      }
    },
  });

  return (
    <CollectSignersVisual
      options={options}
      integrationFormAgreementData={integrationFormAgreement}
      setIntegrationFormAgreementData={setIntegrationFormAgreement}
      signingRoles={signingRoles}
      loading={loading || stepLoading}
      onNext={() => {
        sendAnalytic(
          ovAnalyticsEvents.workflowsCollectSignersSendButtonSelect,
          {
            workflowStepTitle: options?.title,
            workflowId: workflowCompletion?.workflow?.id,
            workflowName: workflowCompletion?.workflow?.name,
            activeWorkflowCompletionId: workflowCompletion?.id,
            objectId: workflowCompletion?.objectId,
            objectType: workflowCompletion?.objectType,
          },
        );
        onNext();
      }}
    />
  );
};

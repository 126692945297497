import { SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import InfoDialog from 'ovComponents/2-component/infoDialog/infoDialog';
import { countryList } from '../../resources';
import {
  Autocomplete, Box, Grid, IconButton, MaskedTaxIdField, Radio, RadioGroup, Typography,
} from '../..';
import { ForeignTaxInformation } from '../../../interfaces/foreignTaxInformation';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { useTextFieldTokens } from '../../2-component/textField/textField.tokens';
import { translateBackend } from '../../../assets/i18n/config';
import { TranslatedString } from '../../../interfaces';

type CustomLabelProps = TranslatedString & { enabled: boolean };

export const ForeignTaxField = ({
  readonly = false, disabled = false, foreignTax, setForeignTax, error, label, sx, infoTooltip, customLabels,
}: {
  setForeignTax: (value: ForeignTaxInformation[]) => void,
  readonly?: boolean,
  disabled?: boolean,
  foreignTax: ForeignTaxInformation[],
  error?: boolean,
  label: string,
  sx?: SxProps,
  infoTooltip?: string,
  customLabels?: { mainSwitch: CustomLabelProps }
}) => {
  const { t } = useTranslation('components');
  const tokens = useThemeTokens(useTextFieldTokens());
  const { comp } = tokens;

  const addNewForeignTax = (): void => {
    setForeignTax([...foreignTax, { foreignTaxCountry: '', foreignTaxNumber: '' }]);
  };
  const resetForeignTax = (): void => {
    setForeignTax([]);
  };
  const removeFromForeignTax = (index: number) => {
    setForeignTax([...foreignTax.filter((element, idx) => idx !== index)]);
  };
  const updateTaxItemInList = (value: string, index: number, property?: 'country' | 'number') => foreignTax?.map((taxItem, mapIndex) => {
    if (index === mapIndex) {
      return property === 'number' ? { ...taxItem, foreignTaxCountry: value } : { ...taxItem, foreignTaxNumber: value };
    }
    return taxItem;
  }) ?? [];
  const onAutoCompleteChanged = (index: number, value: string) => {
    if (value) {
      const countryCode = countryList.find((country) => country.name === value)?.code ?? '';
      setForeignTax([...updateTaxItemInList(countryCode, index, 'number')]);
    }
  };

  return (
    <Box sx={sx}>
      <Box display="flex" flexDirection="row" alignItems={'center'}>
        <Typography variant='labelSmall' sx={{ mb: '4px', mr: '4px', color: error ? comp.textField.errorColor : comp.textField.labelColor }}>
          {label}
          {infoTooltip && (<InfoDialog information={infoTooltip} />)}
        </Typography>
      </Box>
      <RadioGroup
        value={foreignTax?.length > 0 ? 'yes' : 'no'}
        error={error}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (event.target.value === 'yes') {
            addNewForeignTax();
          } else {
            resetForeignTax();
          }
        }}
        disabled={disabled || readonly}
        label={
          customLabels?.mainSwitch?.enabled === true
            ? translateBackend(customLabels?.mainSwitch)
            : t('components:taxInformationField.label')
        }
        testId="foreign-tax-switch"
      >
        <Radio testId={'foreign-tax-switch-option-no'} value={'no'} label={t('shared:no')} />
        <Radio testId={'foreign-tax-switch-option-yes'} value={'yes'} label={t('shared:yes')} />
      </RadioGroup>

      {
        foreignTax?.length > 0 ? foreignTax.map((item, index) => (
          <Grid key={index} container spacing={2} mt={0}>
            <Grid key={`country-${index}`} item md={6} xs={12}>
              <Autocomplete
                testId={`country-autocomplete-${index}`}
                value={!item.foreignTaxCountry ? null : { label: countryList.find((country) => country.code === foreignTax[index].foreignTaxCountry)?.name ?? '' }}
                isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
                disabled={disabled || readonly}
                onChange={(e: any, value: any) => {
                  onAutoCompleteChanged(index, value?.label ?? '');
                }}
                error={error}
                fullWidth
                options={countryList.filter((e) => e.code !== 'CA').map((country) => ({ label: country.name }))}
                label={t('components:taxInformationField.country')}
              />
            </Grid>
            <Grid key={`tin-${index}`} item md={readonly ? 6 : 4} xs={readonly ? 12 : 8}>
              <MaskedTaxIdField
                fullWidth
                data-testid={`tin-${index}`}
                value={foreignTax[index].foreignTaxNumber}
                disclosedValue={foreignTax[index].foreignTaxNumberDisclosed}
                label={t('components:taxInformationField.tin')}
                error={error}
                setValue={(newForeignTIN: any) => {
                  setForeignTax([...updateTaxItemInList(newForeignTIN, index)]);
                }}
                disabled={disabled || readonly}
              />
            </Grid>
            {readonly ? undefined
              : <>
                <Grid key={`delete-icon-${index}`} item md={1} xs={2} display={'flex'} alignItems="end">
                  <IconButton label='' disabled={disabled}>
                    <DeleteRoundedIcon data-testid={`remove-button-${index}`} sx={{ cursor: 'pointer' }} onClick={() => removeFromForeignTax(index)} />
                  </IconButton>
                  <IconButton label='' disabled={disabled}>
                    {index === (foreignTax.length - 1) ? <AddRoundedIcon data-testid="add-button" sx={{ cursor: 'pointer' }} onClick={() => addNewForeignTax()} /> : undefined}
                  </IconButton>
                </Grid>
              </>
            }
          </Grid>
        )) : undefined
      }
    </Box>
  );
};

export default ForeignTaxField;

import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SourceOfFunds, User } from 'interfaces';
import { translateBackend } from 'assets/i18n/config';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { additionalInfo } from 'ovComponents/4-module/configurableOptionFields';
import InfoDialog from 'ovComponents/2-component/infoDialog/infoDialog';
import { kebabCase } from 'lodash';
import { invalidFields } from '../utils';
import {
  Button, TextField, Typography, Form, SelectField, Box, MenuItem, Autocomplete, Grid,
} from '../../../..';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';

interface SelectOption {
  label?: string,
  value?: string,
}

export const IncomeAssetsAndDebtsVisual = ({
  options, userData, loading, updateUser, continueFunc, workflowCompletion, grid = false, updateMode = false,
}: {
  options: any, userData: Partial<User> & { debtAssociated?: any }, loading: boolean, grid?: boolean, updateMode?: boolean,
  workflowCompletion?: any, updateUser: (user: Partial<User> & { debtAssociated?: any }) => void, continueFunc: () => void,
}) => {
  const { sendAnalytic } = useContext(AnalyticsContext);
  const { t } = useTranslation(['client', 'components']);
  const [focused, setFocused] = useState<string[]>([]);
  const [dirty, setDirty] = useState<boolean>(false);

  const sourceOfWealthOptions = [
    { value: 'EMPLOYMENT_INCOME', label: t('edit.sourceOfWealthOptions.EMPLOYMENT_INCOME') },
    { value: 'SALE_OF_LARGE_ASSET', label: t('edit.sourceOfWealthOptions.SALE_OF_LARGE_ASSET') },
    { value: 'PROPERTY_SALE', label: t('edit.sourceOfWealthOptions.PROPERTY_SALE') },
    { value: 'INHERITANCE', label: t('edit.sourceOfWealthOptions.INHERITANCE') },
    { value: 'FAMILY_ESTATE', label: t('edit.sourceOfWealthOptions.FAMILY_ESTATE') },
    { value: 'INVESTMENT', label: t('edit.sourceOfWealthOptions.INVESTMENT') },
    { value: 'BUSINESS_OWNERSHIP_INTEREST', label: t('edit.sourceOfWealthOptions.BUSINESS_OWNERSHIP_INTEREST') },
    { value: 'DEBT', label: t('edit.sourceOfWealthOptions.DEBT') },
    { value: 'LOTTERY_WINNING', label: t('edit.sourceOfWealthOptions.LOTTERY_WINNING') },
    { value: 'OTHER', label: t('edit.sourceOfWealthOptions.OTHER') },
  ];

  const debtAssociatedOptions = [
    { value: 'STUDENT_LOAN', label: t('edit.debtAssociatedOptions.STUDENT_LOAN') },
    { value: 'MORTGAGE', label: t('edit.debtAssociatedOptions.MORTGAGE') },
    { value: 'CREDIT_CARD', label: t('edit.debtAssociatedOptions.CREDIT_CARD') },
  ];

  const sourceOfFundsOptions = Object.values(SourceOfFunds).map((item: string) => ({
    value: item,
    label: t(`edit.sourceOfFundsOptions.${item}`),
  }));

  const cents2dollars = (value?: number) => (value === undefined || value === null ? '' : value / 100);
  const dollars2cents = (value?: number) => Number(value) * 100;

  const submit = () => {
    const fields = invalidFields(options, userData);
    setFocused(fields);
    if (fields.length === 0) {
      sendAnalytic(ovAnalyticsEvents.workflowsIncomeAssetsAndDebtsContinueButtonSelect, {
        workflowStepTitle: options?.title,
        workflowId: workflowCompletion?.workflow?.id,
        workflowName: workflowCompletion?.workflow?.name,
        activeWorkflowCompletionId: workflowCompletion?.id,
        objectId: workflowCompletion?.objectId,
        objectType: workflowCompletion?.objectType,
      });
      continueFunc();
    }
  };

  const update = (newValue: any) => {
    setDirty(true);
    updateUser(newValue);
  };

  useEffect(() => {
    if (loading === false) setDirty(false);
  }, [loading]);

  const lockMessage = (): string => (options.customLockedMessage ? translateBackend(options.customLockedMessage) : t('pageConfiguration:notEditableMessage'));

  const annualIncomeInput = !options?.annualIncomeCents?.enabled
    ? null
    : options?.annualIncomeCents?.selectField?.checked
      ? (
        <Grid item xs={12} md={grid ? 4 : 12}>
          <SelectField
            testId="annual-income"
            disabled={loading}
            label={translateBackend(options?.annualIncomeCents?.label)}
            infoTooltip={additionalInfo(options?.annualIncomeCents?.additionalInfo)}
            fullWidth
            locked={options?.annualIncomeCents?.required === 'NOT_EDITABLE'}
            lockMessage={lockMessage()}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const selectedValue = e.target.value;
              const selectedLabel = options?.annualIncomeCents?.selectField?.options?.find((option: SelectOption) => option.value === selectedValue)?.label;
              update({
                ...userData,
                annualIncomeCents: Number(selectedValue) * 100,
                annualIncomeStr: selectedLabel,
              });
            }}
            value={(userData.annualIncomeCents ?? 0) / 100}
            onBlur={() => setFocused([...focused, 'annualIncomeCents'])}
            error={!userData.annualIncomeCents && focused.includes('annualIncomeCents') && options?.annualIncomeCents?.required !== 'NOT_REQUIRED'}
          >
            {options?.annualIncomeCents?.selectField?.options.map((selectOption: SelectOption) => (
              <MenuItem data-testid={`annual-income-${kebabCase(selectOption.value)}`} value={selectOption.value}>{selectOption.label}</MenuItem>
            ))}
          </SelectField>
        </Grid>
      ) : (
        <Grid item xs={12} md={grid ? 4 : 12}>
          <TextField
            type='dollar'
            testId="annual-income"
            onChange={(e: any) => update({ ...userData, annualIncomeCents: dollars2cents(e.target.value) })}
            disabled={loading}
            locked={options?.annualIncomeCents?.required === 'NOT_EDITABLE'}
            lockMessage={lockMessage()}
            label={translateBackend(options?.annualIncomeCents?.label)}
            infoTooltip={additionalInfo(options?.annualIncomeCents?.additionalInfo)}
            fullWidth
            value={cents2dollars(userData.annualIncomeCents)}
            leadingIcon='dollar'
            onBlur={() => setFocused([...focused, 'annualIncomeCents'])}
            error={!userData.annualIncomeCents && focused.includes('annualIncomeCents') && options?.annualIncomeCents?.required !== 'NOT_REQUIRED'}
          />
        </Grid>
      );

  return (
    <Form onSubmit={submit}>
      {options?.title && (
        <Typography variant='displayLarge' sx={{ mt: 1 }}>
          {translateBackend(options?.title)}
          {additionalInfo(options?.title?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.title?.additionalInfo) ?? ''} />)}
        </Typography>
      )}
      {options?.subtitle && (
        <Typography variant='bodyLarge' component='div' display='inline-flex' alignItems='end' sx={{ mb: 3, table: { width: '100%' } }}>
          <Box display='inline-block'>
            <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.subtitle)}</ReactMarkdown>
          </Box>
          {additionalInfo(options?.subtitle?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.subtitle?.additionalInfo) ?? ''} />)}
        </Typography>
      )}

      <Grid container spacing={2}>
        {annualIncomeInput}
        {options?.financialLiquidAssetsCents?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              testId="financial-liquid-assets-cents"
              type='dollar'
              onChange={(e: any) => update({ ...userData, financialLiquidAssetsCents: dollars2cents(e.target.value) })}
              disabled={loading}
              label={translateBackend(options?.financialLiquidAssetsCents?.label)}
              infoTooltip={additionalInfo(options?.financialLiquidAssetsCents?.additionalInfo)}
              fullWidth
              locked={options?.financialLiquidAssetsCents?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              value={cents2dollars(userData.financialLiquidAssetsCents)}
              leadingIcon='dollar'
              errorText='Liquid assets are required'
              onBlur={() => setFocused([...focused, 'financialLiquidAssetsCents'])}
              error={userData.financialLiquidAssetsCents === undefined && focused.includes('financialLiquidAssetsCents') && options?.financialLiquidAssetsCents?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.financialFixedAssetsCents?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              testId="financial-fixed-assets-cents"
              type='dollar'
              onChange={(e: any) => update({ ...userData, financialFixedAssetsCents: dollars2cents(e.target.value) })}
              disabled={loading}
              locked={options?.financialFixedAssetsCents?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              label={translateBackend(options?.financialFixedAssetsCents?.label)}
              infoTooltip={additionalInfo(options?.financialFixedAssetsCents?.additionalInfo)}
              fullWidth
              value={cents2dollars(userData.financialFixedAssetsCents)}
              leadingIcon='dollar'
              onBlur={() => setFocused([...focused, 'financialFixedAssetsCents'])}
              error={userData.financialFixedAssetsCents === undefined && focused.includes('financialFixedAssetsCents') && options?.financialFixedAssetsCents?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.totalDebtCents?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              testId="total-debts-to-cent"
              type='dollar'
              onChange={(e: any) => update({ ...userData, totalDebtCents: dollars2cents(e.target.value) })}
              disabled={loading}
              locked={options?.totalDebtCents?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              label={translateBackend(options?.totalDebtCents?.label)}
              infoTooltip={additionalInfo(options?.totalDebtCents?.additionalInfo)}
              fullWidth
              value={cents2dollars(userData.totalDebtCents)}
              leadingIcon='dollar'
              onBlur={() => setFocused([...focused, 'totalDebtCents'])}
              error={userData.totalDebtCents === undefined && focused.includes('totalDebtCents') && options?.totalDebtCents?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.annualDebtPaymentsCents?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
             testId="annual-debt-payments-cents"
              type='dollar'
              onChange={(e: any) => update({ ...userData, annualDebtPaymentsCents: dollars2cents(e.target.value) })}
              disabled={loading}
              locked={options?.annualDebtPaymentsCents?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              label={translateBackend(options?.annualDebtPaymentsCents?.label)}
              infoTooltip={additionalInfo(options?.annualDebtPaymentsCents?.additionalInfo)}
              fullWidth
              value={cents2dollars(userData.annualDebtPaymentsCents)}
              leadingIcon='dollar'
              onBlur={() => setFocused([...focused, 'annualDebtPaymentsCents'])}
              error={userData.annualDebtPaymentsCents === undefined && focused.includes('annualDebtPaymentsCents') && options?.annualDebtPaymentsCents?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.accreditedInvestor?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <SelectField
              testId="accredited-investor"
              onChange={(e: any) => update({ ...userData, isAccreditedInvestor: e.target.value === 'Yes' })}
              disabled={loading}
              locked={options?.accreditedInvestor?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              label={translateBackend(options?.accreditedInvestor?.label)}
              infoTooltip={additionalInfo(options?.accreditedInvestor?.additionalInfo)}
              fullWidth
              value={userData.isAccreditedInvestor === true ? 'Yes' : 'No'}
              onBlur={() => setFocused([...focused, 'isAccreditedInvestor'])}
              error={(!userData.isAccreditedInvestor as any) && focused.includes('isAccreditedInvestor') && options?.accreditedInvestor?.required !== 'NOT_REQUIRED'}
            >
              <MenuItem data-testid="accredited-investor-yes" value='Yes'>{t('components:booleanOptions.yes')}</MenuItem>
              <MenuItem data-testid="accredited-investor-no" value='No'>{t('components:booleanOptions.no')}</MenuItem>
            </SelectField>
          </Grid>
        )}
        {options?.debtAssociated?.enabled && (
          <Grid item xs={12}>
            <Autocomplete
              testId="debt-associated"
              multiple
              fullWidth
              locked={options?.debtAssociated?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              label={translateBackend(options?.debtAssociated?.label)}
              infoTooltip={additionalInfo(options?.debtAssociated?.additionalInfo)}
              onChange={(e: any, items: any) => {
                update({ ...userData, debtAssociated: [...items.map((item: { value: string, label: string }) => item.value)] });
              }}
              value={debtAssociatedOptions.filter((option) => userData.debtAssociated && userData.debtAssociated.includes(option.value))}
              getOptionLabel={(option: { value: string, label: string }) => option.label}
              options={debtAssociatedOptions}
              renderOption={(props: any, option:any) => (
                <li {...props} data-testid={`debt-associated-option-${kebabCase(option.value)}`}>
                  {option.label}
                </li>
              )}
              error={(!userData.debtAssociated || userData?.debtAssociated?.length === 0) && focused.includes('debtAssociated') && options?.debtAssociated?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.sourceOfWealth?.enabled && (
          <Grid item xs={12}>
            <Autocomplete
              testId="source-of-wealth"
              multiple
              fullWidth
              locked={options?.sourceOfWealth?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              label={translateBackend(options?.sourceOfWealth?.label)}
              infoTooltip={additionalInfo(options?.sourceOfWealth?.additionalInfo)}
              onChange={(e: any, items: any) => {
                update({ ...userData, sourceOfWealth: [...items.map((item: { value: string, label: string }) => item.value)] });
              }}
              value={sourceOfWealthOptions.filter((option) => userData.sourceOfWealth && userData.sourceOfWealth.includes(option.value))}
              getOptionLabel={(option: { value: string, label: string }) => option.label}
              options={sourceOfWealthOptions}
              renderOption={(props: any, option:any) => (
                <li {...props} data-testid={`source-of-wealth-option-${kebabCase(option.value)}`}>
                  {option.label}
                </li>
              )}
              error={(!userData.sourceOfWealth || userData?.sourceOfWealth?.length === 0) && focused.includes('sourceOfWealth') && options?.sourceOfWealth?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.sourceOfFunds?.enabled && (
          <Grid item xs={12}>
            <Autocomplete
              testId="source-of-funds"
              multiple
              fullWidth
              label={translateBackend(options?.sourceOfFunds?.label)}
              locked={options?.sourceOfFunds?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              infoTooltip={additionalInfo(options?.sourceOfFunds?.additionalInfo)}
              onChange={(e: any, items: any) => {
                update({ ...userData, sourceOfFunds: [...items.map((item: { value: string, label: string }) => item.value)] });
              }}
              value={sourceOfFundsOptions.filter((option) => userData.sourceOfFunds && userData.sourceOfFunds.includes(option.value))}
              getOptionLabel={(option: { value: string, label: string }) => option.label}
              options={sourceOfFundsOptions}
              renderOption={(props: any, option:any) => (
                <li {...props} data-testid={`source-of-funds-option-${kebabCase(option.value)}`}>
                  {option.label}
                </li>
              )}
              error={(!userData.sourceOfFunds || userData?.sourceOfFunds?.length === 0) && focused.includes('sourceOfFunds') && options?.sourceOfFunds?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.uniqueCircumstances?.enabled && (
          <Grid item xs={12}>
            <TextField
              testId="unique-circumstance"
              multiline
              rows={5}
              onChange={(e: any) => update({ ...userData, uniqueCircumstances: e.target.value })}
              locked={options?.uniqueCircumstances?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              disabled={loading}
              label={translateBackend(options?.uniqueCircumstances?.label)}
              infoTooltip={additionalInfo(options?.uniqueCircumstances?.additionalInfo)}
              fullWidth
              value={userData.uniqueCircumstances}
              onBlur={() => setFocused([...focused, 'uniqueCircumstances'])}
              error={userData.uniqueCircumstances === '' && focused.includes('uniqueCircumstances') && options?.uniqueCircumstances?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
      </Grid>
      <Box display='flex' justifyContent='end'>
        {(!updateMode || options.displayUpdateButton) && (
          <Button
            dataTestId={`income-assets-${kebabCase(translateBackend(options.title))}-continue-button`}
            label={t(updateMode ? 'update' : 'continue')}
            disabled={loading || (updateMode && !dirty)}
            sx={{ mt: 3, textAlign: 'center' }}
            type='submit'
          />
        )}
      </Box>
    </Form>
  );
};

export default IncomeAssetsAndDebtsVisual;

import InfoIcon from '@mui/icons-material/Info';
import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { Typography, Grid, Box } from '../../../../1-primative';
import {
  TextField, Switch, Tooltip, MenuItem,
} from '../../../../2-component';
import { BillingSchedule, BillingScheduleFrequencies, BillingScheduleStates } from '../../../../../interfaces';
import { ScheduleDateSelect } from '../../../../3-pattern';
import { FETCH_BILLING_SCHEDULES } from '../billingSchedules';
import { useLocalization } from '../../../../../util/useLocalization';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';

const thisBillingPeriod = (frequency: BillingScheduleFrequencies = BillingScheduleFrequencies.MONTHLY) => {
  let endDate: Dayjs;
  let startDate: Dayjs;
  if (frequency === BillingScheduleFrequencies.MONTHLY) {
    startDate = dayjs().startOf('month');
    endDate = dayjs().endOf('month');
  } else if (frequency === BillingScheduleFrequencies.QUARTERLY) {
    startDate = dayjs().startOf('year').set('month', Math.floor(dayjs().month() / 3) * 3);
    endDate = startDate.add(3, 'month').subtract(1, 'day');
  } else if (frequency === BillingScheduleFrequencies.SEMESTRAL) {
    startDate = dayjs().startOf('year').set('month', Math.floor(dayjs().month() / 6) * 6);
    endDate = startDate.add(6, 'month').subtract(1, 'day');
  } else if (frequency === BillingScheduleFrequencies.ANNUAL) {
    startDate = dayjs().startOf('year');
    endDate = dayjs().endOf('year');
  } else {
    return {};
  }

  return {
    startDate: startDate.format('YYYY-MM-DD'),
    endDate: endDate.format('YYYY-MM-DD'),
  };
};

export const date2day = (scheduledDate: string): number => {
  const date = dayjs(scheduledDate).date();
  if (date > 28) return 28;
  return date;
};

export const defineNextScheduledDate = (todayRef: string, day: number, frequency: BillingScheduleFrequencies) => {
  const today = dayjs(todayRef);
  let refDate = dayjs(todayRef);
  refDate = refDate.set('date', day);
  if (refDate.date() <= today.date()) {
    refDate = refDate.add(1, 'month');
  }
  if (
    frequency === BillingScheduleFrequencies.QUARTERLY
    && ![0, 3, 6, 9].includes(refDate.month()) // when it is not the first month of the quarter
  ) {
    const currentQuarterStartDate = new Date(today.year(), (3 * Math.floor((today.month() / 3))), 1);
    refDate = dayjs(currentQuarterStartDate).add(3, 'month').set('date', day);
  }
  return refDate.format('YYYY-MM-DD');
};

const BillingScheduleForm = ({
  billingSchedule,
  setBillingSchedule,
  setValid,
  organizationId,
  readonly,
}: {
  billingSchedule: BillingSchedule,
  setBillingSchedule: Dispatch<SetStateAction<BillingSchedule>>,
  setValid: (valid: boolean) => void,
  organizationId?: string,
  readonly?: boolean,
}) => {
  const { t } = useTranslation(['feeAndBilling']);
  const { localizedDate } = useLocalization();
  const [calculationDay, setCalculationDay] = useState<number | undefined>(billingSchedule.nextCalculationDate ? date2day(billingSchedule.nextCalculationDate) : undefined);
  const [billingDay, setBillingDay] = useState<number | undefined>(billingSchedule.nextBillingDate ? date2day(billingSchedule.nextBillingDate) : undefined);
  const { sys } = useThemeTokens();
  const invalidDateSequence: boolean = !!calculationDay && !!billingDay && calculationDay > billingDay;

  const { data } = useQuery(FETCH_BILLING_SCHEDULES, {
    variables: {
      input: {
        filter: {
          organizationId,
          states: [BillingScheduleStates.ACTIVE],
        },
      },
    },
  });

  useEffect(() => {
    setValid(!(!calculationDay || !billingDay || invalidDateSequence));
  }, [calculationDay, billingDay, invalidDateSequence, setValid]);

  useEffect(() => {
    const periodEndDate = thisBillingPeriod(billingSchedule.frequency).endDate;
    // calculationDay (17) to calculationDate (2034-11-17)
    if (calculationDay) {
      setBillingSchedule((prev) => ({
        ...prev,
        nextCalculationDate: defineNextScheduledDate(dayjs(periodEndDate).format('YYYY-MM-DD'), calculationDay, billingSchedule.frequency),
      }));
    }
    // billingDay (18) to billingDate (2034-11-18)
    if (billingDay) {
      setBillingSchedule((prev) => ({
        ...prev,
        nextBillingDate: defineNextScheduledDate(dayjs(periodEndDate).format('YYYY-MM-DD'), billingDay, billingSchedule.frequency),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculationDay, billingDay, billingSchedule.frequency]);

  const defaultedBillingSchedule = (billSchedule: BillingSchedule) => billSchedule.default && (billSchedule.default === true);

  const nonInheritedDefaultExists = (): boolean => {
    const billingSchedules = data?.fetchBillingSchedules?.billingSchedules;
    if (billingSchedules && organizationId) {
      const nonInheritedBillingSchedules = billingSchedules.filter(
        (item: any) => (item.organization?.id === organizationId) && item.default,
      );

      if (nonInheritedBillingSchedules.length > 0) return true;
    }
    return false;
  };

  return (
    <Box display='flex' flexDirection='column' gap={2}>
      <TextField
        select
        value={billingSchedule.frequency ?? BillingScheduleFrequencies.MONTHLY}
        label={t('feeAndBilling:billingSchedule.edit.frequencyLabel')}
        onChange={(e: any) => setBillingSchedule({ ...billingSchedule, frequency: e.target.value })}
        fullWidth
        disabled={readonly}
      >
        {[
          BillingScheduleFrequencies.MONTHLY,
          BillingScheduleFrequencies.QUARTERLY,
        ].map((x: BillingScheduleFrequencies) => (
          <MenuItem value={x} key={x}>{t(`feeAndBilling:billingSchedule.frequencies.${x}`)}</MenuItem>
        ))}
      </TextField>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <ScheduleDateSelect
            label={t('feeAndBilling:billingSchedule.edit.calculationDate')}
            readonly={readonly}
            data-testid='nextCalculationScheduleSelect'
            day={calculationDay}
            setDate={setCalculationDay}
            error={invalidDateSequence}
            helperText={invalidDateSequence ? t('feeAndBilling:billingSchedule.edit.calcDateMustBePastBillingDate') : undefined}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <ScheduleDateSelect
            label={t('feeAndBilling:billingSchedule.edit.billingDate')}
            readonly={readonly}
            data-testid='nextBillingScheduleSelect'
            day={billingDay}
            setDate={setBillingDay}
          />
        </Grid>
      </Grid>
      <Typography sx={{ background: sys.color.primaryVariant, p: 1, borderRadius: '8px' }}>
        {
          t('feeAndBilling:billingSchedule.edit.disclaimerPart1')
        }
        &nbsp;
        {
          (billingSchedule.nextBillingDate && billingSchedule.nextCalculationDate)
          && t('feeAndBilling:billingSchedule.edit.disclaimerPart2', {
            nextCalculationDate: localizedDate(billingSchedule.nextCalculationDate),
            nextBillingDate: localizedDate(billingSchedule.nextBillingDate),
            startDate: localizedDate(thisBillingPeriod(billingSchedule.frequency).startDate),
            endDate: localizedDate(thisBillingPeriod(billingSchedule.frequency).endDate),
          })
        }
      </Typography>
      <Box display='flex' flexDirection='row' gap={2}>
        <Tooltip
          title={
            (nonInheritedDefaultExists() && !defaultedBillingSchedule(billingSchedule)) ? t('feeAndBilling:billingSchedule.edit.default.defaultFlagTooltip') : ''
          }
        >
          <Switch
            disabled={readonly || (billingSchedule.default ? false : nonInheritedDefaultExists())}
            checked={billingSchedule.default}
            onChange={async (event) => {
              setBillingSchedule((prev) => ({ ...prev, default: event }));
            }}
            label={t('feeAndBilling:billingSchedule.edit.default.label')}
            />
        </Tooltip>
        <Tooltip title={t('feeAndBilling:billingSchedule.edit.default.tooltip')}><InfoIcon color='secondary' /></Tooltip>
      </Box>
    </Box>
  );
};

export default BillingScheduleForm;

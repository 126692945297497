import {
  Typography, Box, CircularProgress, Table, TableHead, TableRow, TableCell, TableBody, Grid, Menu, MenuItem, IconButton, Chip, Tooltip,
  ListItemText,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { usePermissions, UserContext } from 'providers/userContextProvider';
import {
  CurrentIntegrationType, IntegrationState,
  IntegrationTransitionState, IntegrationType,
} from 'interfaces/integrations';
import EditIntegration from './editIntegration';
import NewIntegration from './newIntegration';
import ConfirmationDialog from '../../../ovComponents/3-pattern/confirmationDialog/confirmationDialog';
import { Typography as ModalTypography } from '../../../ovComponents/1-primative/typography/typography';
import useCopyToClipboard from '../../../util/useCopyToClipboard';

export const FETCH_INTEGRATIONS = gql`
  query fetchIntegrations($input: FetchIntegrationsInput!) {
    fetchIntegrations(input: $input) {
      integrations {
        id
        configuration
        provider
        type
        state
        scope
        organization {
          id
          name
          parent {
            id
            name
          }
        }
      }
      totalCount
    }
  }
`;

const TRANSITION_INTEGRATION = gql`
  mutation transitionIntegration($input: TransitionIntegrationInput!) {
    transitionIntegration(input: $input) {
      integration {
        id
      }
    }
  }
`;
export const Integrations = () => {
  const { t } = useTranslation(['devSettings']);
  const { permissions } = usePermissions();
  const navigate = useNavigate();
  const [copy] = useCopyToClipboard();
  const { activeOrganization } = useContext(UserContext);
  const [selectedIntegration, setSelectedIntegration] = useState<CurrentIntegrationType>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClose = () => {
    setOpenEditModal(false);
  };

  const {
    loading, error, data, previousData,
  } = useQuery(FETCH_INTEGRATIONS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
      },
    },
    fetchPolicy: 'no-cache',
  });
  const integrationsList = data?.fetchIntegrations?.integrations;

  const [transitionIntegration] = useMutation(TRANSITION_INTEGRATION, { refetchQueries: [FETCH_INTEGRATIONS] });

  const handleTransition = async ({ integrationId, transition }: { integrationId: string; transition: IntegrationTransitionState }) => {
    await transitionIntegration({
      variables: {
        input: {
          integrationId,
          transition,
        },
      },
    });
  };

  const handleDelete = async ({ integrationId, transition }: { integrationId: string; transition: IntegrationTransitionState }) => {
    await handleTransition({ integrationId, transition });
    setOpenDeleteDialog(false);
  };

  if (error) <Typography>Error</Typography>;

  return (
    <Box sx={{ m: '-16px -16px' }}>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 100 }} />
        </Box>
      ) : (
        <>
          <Grid container justifyContent={'flex-end'}>
            <Grid item>{permissions.includes('write:integrations') && <NewIntegration defaultOrg={activeOrganization.id ?? ''} integrationsList={integrationsList} />}</Grid>
          </Grid>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant='overline'>{t('integrationsTable.type')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='overline'>{t('integrationsTable.name')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='overline'>{t('integrationsTable.organization')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='overline'>{t('integrationsTable.state')}</Typography>
                </TableCell>
                <TableCell align='right'>
                  <Typography variant='overline'>{t('integrationsTable.organizationSetting')}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || previousData)?.fetchIntegrations?.integrations?.map((integration: any) => (
                <TableRow
                  hover
                  sx={{ cursor: 'pointer' }}
                  key={integration.id}
                  onClick={() => {
                    setSelectedIntegration(integration);
                    if (integration.organization.id === activeOrganization.id && permissions.includes('write:integrations')) {
                      setOpenEditModal(true);
                    }
                  }}
                >
                  <TableCell>
                    <Typography>{t(`integrationsTableOptions.type.${integration.type}`)}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{t(`integrationsTableOptions.provider.${integration.provider}`)}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{integration.organization.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Chip
                      size='small'
                      label={integration.state === IntegrationState.ACTIVE ? t('integrationsTable.active') : t('integrationsTable.inactive')}
                      color={integration.state === IntegrationState.ACTIVE ? 'success' : 'error'}
                    />
                  </TableCell>
                  <TableCell align='right'>
                    {integration.organization.id !== activeOrganization.id ? (
                      <Tooltip title={t('shared:inheritedTooltip', { model: 'Integration', organization: integration.organization?.name })}>
                        <Chip size='medium' label={t('integrationsTableOptions.organizationSetting.inherited')} />
                      </Tooltip>
                    ) : (
                      <IconButton
                        size='small'
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setAnchorEl(e.currentTarget);
                          setSelectedIntegration(integration);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {/* Context menu   */}
          {selectedIntegration && (
            <>
              <Menu
                anchorEl={anchorEl}
                id='integrations-menu'
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                {permissions.includes('write:integrations') && (
                  <Box>
                    {selectedIntegration.state === IntegrationState.ACTIVE ? (
                      <MenuItem onClick={() => handleTransition({ integrationId: selectedIntegration.id, transition: IntegrationTransitionState.DEACTIVATE })}>{t('shared:deactivate')}</MenuItem>
                    ) : (
                      <MenuItem onClick={() => handleTransition({ integrationId: selectedIntegration.id, transition: IntegrationTransitionState.ACTIVATE })}>{t('shared:activate')}</MenuItem>
                    )}
                    <MenuItem onClick={() => setOpenDeleteDialog(true)}>{t('shared:delete')}</MenuItem>
                  </Box>
                )}
                {selectedIntegration.type === IntegrationType.DATA_INGESTION && permissions.includes('read:bulk_upsert_request') && (
                  <MenuItem onClick={() => navigate(`/bulkUpsertRequests#int:${selectedIntegration.id}`)}>{t('integrationsTableOptions.viewDataIngestionRequests')}</MenuItem>
                )}
                <MenuItem
                  style={{ display: 'flex', justifyContent: 'right' }}
                  onClick={() => { copy(selectedIntegration.id); handleClose(); }}>
                    <ContentCopyRoundedIcon style={{ marginRight: '10px' }} />
                    <ListItemText primary={t('shared:copyID')} secondary={selectedIntegration.id} />
                </MenuItem>
              </Menu>
            </>
          )}

          {/* Edit - Editing Dialog */}
          {selectedIntegration && <EditIntegration currentIntegration={selectedIntegration} open={openEditModal} handleEditClose={handleEditClose} />}

          {/* Delete - Deletion Confirmation */}
          {selectedIntegration && (
            <ConfirmationDialog
              onConfirm={async () => handleDelete({ integrationId: selectedIntegration.id, transition: IntegrationTransitionState.ARCHIVE })}
              onCancel={() => {
                setOpenDeleteDialog(false);
              }}
              title={t('integrationsDeleteModal.title')}
              open={openDeleteDialog}
            >
              <ModalTypography variant='bodyLarge'>{t('integrationsDeleteModal.body')}</ModalTypography>
            </ConfirmationDialog>
          )}
        </>
      )}
    </Box>
  );
};

import { FirebaseConfigType, initFirebase, sendAnalyticsEvent as FirebaseSendEvent } from './firebase';
import {
  initMixPanel,
  MixpanelConfigType,
  sendAnalyticsEvent as MixPanelSendEvent,
} from './mixpanel';
import { AnalyticsProviderType, AnalyticsProviderTypes } from '../../providers/analyticsProvider';

const sendAnalyticsEvent = async (providers: AnalyticsProviderType[], eventName: string, meta?: any): Promise<void> => {
  providers.forEach((provider) => {
    if (provider.type === AnalyticsProviderTypes.GOOGLE) {
      FirebaseSendEvent(eventName, meta, provider.id);
    } else {
      MixPanelSendEvent(eventName, meta, provider.id);
    }
  });
};

const initAnalytics = ({
  provider, configType, user, providerId,
} :{ provider: AnalyticsProviderTypes, configType: FirebaseConfigType | MixpanelConfigType, user?: any, providerId?: string }): void => {
  if (provider === AnalyticsProviderTypes.GOOGLE) {
    initFirebase({ config: configType as FirebaseConfigType, providerId });
  }
  if (provider === AnalyticsProviderTypes.MIXPANEL) {
    initMixPanel({ config: configType as MixpanelConfigType, user, providerId });
  }
};

export { initAnalytics, sendAnalyticsEvent };

export const ovAnalyticsEvents = {
  dashboardOrganizationSelect: 'dashboard_organization_select',
  dashboardBasicSelect: 'dashboard_basic_select',
  dashboardPortfolioManagerSelect: 'dashboard_portfolio_manager_select',
  dashboardComplianceSelect: 'dashboard_compliance_select',
  dashboardOperationsSelect: 'dashboard_operations_select',
  clientManagementClientsSelect: 'client_management_clients_select',
  clientManagementHouseholdSelect: 'client_management_household_select',
  clientManagementCorporationsSelect: 'client_management_corporations_select', // superseded by client_management_non_individual_clients_select_select, TODO: remove this once OV-8830 finished
  clientManagementNonIndividualClientsSelect: 'client_management_non_individual_clients_select_select',
  portfolioManagementModelPortfoliosSelect: 'portfolio_management_model_portfolios_select',
  portfolioManagementProductShelfSelect: 'portfolio_management_product_shelf_select',
  portfolioManagementAssetClassesSelect: 'portfolio_management_asset_classes_select',
  portfolioManagementTradingSchedulesSelect: 'portfolio_management_trading_schedules_select',
  tradingRebalancingSelect: 'trading_rebalancing_select',
  tradingRebalanceReportV1Select: 'trading_rebalance_report_select',
  tradingTLHSelect: 'trading_TLH_select',
  complianceAccountsReviewSelect: 'compliance_accounts_review_select',
  complianceComplianceReviewSelect: 'compliance_compliance_review_select',
  complianceSuspiciousTransfersSelect: 'compliance_suspicious_transfers_select',
  complianceAllTransactionsSelect: 'compliance_all_transactions_select',
  complianceElderlyClientsSelect: 'compliance_elderly_clients_select',
  complianceAnnualProfileUpdateSelect: 'compliance_annual_profile_update_select',
  complianceNewBankAccountApprovalSelect: 'compliance_new_bank_account_approval_select',
  complianceInstitutionsApprovalSelect: 'compliance_institutions_approval_select',
  reportsSelect: 'reports_select',
  feesAndBillingFeeGridSelect: 'fees_&_billing_fee_grid_select',
  feesAndBillingBillingSchedulesSelect: 'fees_&_billing_billing_schedules_select',
  feesAndBillingFeeReportSelect: 'fees_&_billing_fee_report_select',
  feesAndBillingRevenueShareSelect: 'fees_&_billing_revenue_share_select',
  feesAndBillingIncurredFeeSelect: 'fees_&_billing_incurred_fee_select',
  systemManagementReconciliationSelect: 'system_management_reconciliation_select',
  systemManagementStatementsReviewSelect: 'system_management_statements_review_select',
  systemManagementIntegrationSettingsSelect: 'system_management_integration_settings_select',
  systemManagementOrganizationSettingsSelect: 'system_management_organization_settings_select',
  clientsProfileOverviewTabSelect: 'clients_profile_overview_tab_select',
  clientsProfileDetailsTabSelect: 'clients_profile_details_tab_select',
  clientsProfileDocumentsTabSelect: 'clients_profile_documents_tab_select',
  clientsProfileStatementsTabSelect: 'clients_profile_statements_tab_select',
  clientsProfileTransfersTabSelect: 'clients_profile_transfers_tab_select',
  clientsProfileTradesTabSelect: 'clients_profile_trades_tab_select',
  clientsProfileLedgerTabSelect: 'clients_profile_ledger_tab_select',
  clientsProfileNotesTabSelect: 'clients_profile_notes_tab_select',
  clientsProfileAuditLogTabSelect: 'clients_profile_audit_log_tab_select',
  homepageStartNewWorkflowSelect: 'homepage_start_new_workflow_select',
  homepageContinueInProgressWorkflowSelect: 'homepage_continue_in_progress_workflow_select',
  workflowAgreementsContinueButtonSelect: 'workflow_agreements_continue_button_select',
  homepageContactSupportSelect: 'homepage_contact_support_select',
  homepageResourceArticlesSelect: 'homepage_resource_articles_select',
  homepageGoalSelect: 'homepage_goal_select',
  homepageAccountSelect: 'homepage_account_select',
  homepageDepositButtonSelect: 'homepage_deposit_button_select',
  homepageWithdrawButtonSelect: 'homepage_withdraw_button_select',
  goalDetailsWithdrawButtonSelect: 'goal_details_withdraw_button_select',
  goalDetailsDepositButtonSelect: 'goal_details_deposit_button_select',
  tradingPortfolioOptimizerSelect: 'trading_portfolio_optimizer_select',
  tradingBulkTraderSelect: 'trading_bulk_trader_select',
  systemManagementBulkImportSelect: 'system_management_bulk_import_select',
  clientsAccessTabSelect: 'clients_access_tab_select',
  homepageProfileSelect: 'homepage_profile_select',
  workflowsPersonalInformationContinueButtonSelect: 'workflows_personal_information_continue_button_select',
  workflowsNonIndividualInformationContinueButtonSelect: 'workflows_non_individual_information_continue_button_select',
  workflowsNonIndividualSetupContinueButtonSelect: 'workflows_non_individual_setup_continue_button_select',
  workflowsRelationshipInformationContinueButtonSelect: 'workflows_relationship_information_continue_button_select',
  workflowsEmploymentInformationContinueButtonSelect: 'workflows_employment_information_continue_button_select',
  workflowsResidencyInformationContinueButtonSelect: 'workflows_residency_information_continue_button_select',
  workflowsPersonOfInterestContinueButtonSelect: 'workflows_person_of_interest_continue_button_select',
  workflowsIncomeAssetsAndDebtsContinueButtonSelect: 'workflows_income_assets_and_debts_continue_button_select',
  workflowsInvestmentKnowledgeContinueButtonSelect: 'workflows_investment_knowledge_continue_button_select',
  workflowsCreateModelPortfolioContinueButtonSelect: 'workflows_create_model_portfolio_continue_button_select',
  workflowsAddModelPortfolioDetailsContinueButtonSelect: 'workflows_add_model_portfolio_details_continue_button_select',
  workflowsRiskQuestion1ContinueButtonSelect: 'workflows_risk_question_1_continue_button_select',
  workflowsRiskQuestion2ContinueButtonSelect: 'workflows_risk_question_2_continue_button_select',
  workflowsIdVerificationContinueButtonSelect: 'workflows_id_verification_continue_button_select',
  workflowsDocumentsContinueButtonSelect: 'workflows_documents_continue_button_select',
  workflowsCollectSignersSendButtonSelect: 'workflows_collect_signers_send_button_select',
  workflowsTransfersContinueButtonSelect: 'workflows_transfers_continue_button_select',
  workflowsCreateGoalContinueButtonSelect: 'workflows_create_goal_continue_button_select',
  workflowsEditGoalContinueButtonSelect: 'workflows_edit_goal_continue_button_select',
  workflowsReviewGoalContinueButtonSelect: 'workflows_review_goal_continue_button_select',
  workflowsGoalRiskQuestion1ContinueButtonSelect: 'workflows_goal_risk_question_1_continue_button_select',
  workflowsCreateAccountForGoalContinueButtonSelect: 'workflows_create_account_for_goal_continue_button_select',
  workflowsCreateAccountContinueButtonSelect: 'workflows_create_account_continue_button_select',
  workflowsEditAccountContinueButtonSelect: 'workflows_edit_account_continue_button_select',
  workflowsReviewAccountContinueButtonSelect: 'workflows_review_account_continue_button_select',
  workflowsAssignPortfolioToSubAccountContinueButtonSelect: 'workflows_assign_portfolio_to_sub_account_continue_button_select',
  workflowsProjectionsContinueButtonSelect: 'workflows_projections_continue_button_select',
  workflowsReviewContinueButtonSelect: 'workflows_review_continue_button_select',
  workflowsCompletionStart: 'workflows_completion_start',
  workflowsCompletionEnd: 'workflows_completion_end',
  marketValueChartTimeFrameSelect(timeFrame: string) {
    return `market_value_chart_timeframe_${timeFrame}_select`;
  },
  tabSelect(prefix: string, name: string) {
    return `${prefix}_${name}_tab_select`;
  },
};

import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { kebabCase } from 'lodash';
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  SelectField,
  TranslatableTextField,
} from '../..';

export const CreateModelPortfolioOptionsEdit = ({
  optionList, setOptionList,
}: {
  optionList: any, setOptionList: (x: any) => void,
}) => {
  const { t } = useTranslation('modelPortfolios');
  return (
    <>
      {optionList?.map((option: any, num: number) => (
        <Grid
          key={num}
          container
          display='flex'
          flexDirection='row'
          sx={{ mb: 2 }}
          justifyContent='space-between'
          spacing={1}
        >
          <Grid item xs={3}>
            <TranslatableTextField
              label={t('createModelPortfolio.name')}
              value={option.label}
              fullWidth
              onChange={(value) => {
                const newOptions = [...optionList];
                newOptions[num] = { ...option, label: value };
                setOptionList(newOptions);
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <TranslatableTextField
              label={t('createModelPortfolio.description')}
              value={option.description}
              fullWidth
              onChange={(value) => {
                const newOptions = [...optionList];
                newOptions[num] = { ...option, description: value };
                setOptionList(newOptions);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <SelectField label={t('type')} value={option.value} fullWidth onChange={(e: any) => {
              const newOptions = [...optionList];
              newOptions[num] = { ...option, value: e.target.value };
              setOptionList(newOptions);
            }}>
              {['FROM_SCRATCH', 'FROM_TEMPLATE'].map((y: string) => (
                <MenuItem
                  key={y}
                  value={y}
                  data-testid={`type-${kebabCase(y)}`}
                >
                  {t(`createModelPortfolio.${y}`)}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
          <Grid item xs={1} justifyContent='center' display='flex' sx={{ mt: 2 }}>
            <IconButton onClick={() => {
              const newOptions = [...optionList];
              newOptions.splice(num, 1);
              setOptionList(newOptions);
            }}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Box display='flex' justifyContent='end'>
        <Button label={t('add')} variant='outlined' onClick={() => {
          setOptionList([...optionList, { label: { en: '' }, description: { en: '' }, value: 'FROM_SCRATCH' }]);
        }} />
      </Box>
    </>
  );
};

import { useContext, useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Jurisdictions } from '@onevesthq/ov-enums';
import { difference, intersection } from 'lodash';
import { first, uniq } from 'lodash/fp';
import { CreateAccountForGoalVisual } from './createAccountForGoal.visual';
import { WorkflowContext } from '../../workflowCompletion';
import {
  Account, AccountStates, AccountTypes, AffiliationRelations, AffiliationTypes, EntityTypes,
  GoalTypes, getAccountTypes,
} from '../../../../../interfaces';
import { UserContext } from '../../../../../providers/userContextProvider';
import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import { setupWorkflowData } from '../createAccount/createAccount';

const HARDCODED_GOAL_WORKFLOW_ID = '123';
const HARCODED_GOAL_WORFLOW_EN_NAME = 'New Goal';

export interface CreateAccountForGoalArgs {
  accountType: string
}

const CREATE_SUB_ACCOUNT = gql`
  mutation createSubAccount($input: CreateSubAccountInput!) {
    createSubAccount(input: $input) {
      subAccount {
        id
        account {
          id state type affiliations { id relation type }
          user { id primaryEmail type }
        }
      }
    }
  }
`;

const CREATE_ACCOUNT = gql`
  mutation createAccount($input: CreateAccountInput!) {
    createAccount(input: $input) {
      account { id type }
    }
  }
`;

const UPDATE_ACCOUNT_AFFILIATIONS = gql`
  mutation updateAffiliations($input: UpdateAffiliationsInput!) {
    updateAffiliations(input: $input) {
      account { id }
      incompleteAffiliations { id relation type incompleteFields }
    }
  }
`;

const FETCH_USER = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        dateOfBirth
        type
        accounts {
          id
          type
          state
          affiliations { id relation type }
        }
        physicalAddress { jurisdiction }
      }
    }
  }
`;

const FETCH_GOAL = gql`
  query fetchGoal($goalId: ObjectID!) {
    fetchGoal(goalId: $goalId) {
      goal {
        id
        financialProduct { id }
      }
    }
  }
`;

const CLOSE_ACCOUNT = gql`
  mutation closeAccount($input: CloseAccountInput!) {
    closeAccount(input: $input) {
      account { id }
    }
  }
`;

const CLOSE_SUB_ACCOUNT = gql`
  mutation closeSubAccount($input: CloseSubAccountInput!) {
    closeSubAccount(input: $input) {
      subAccount { id }
    }
  }
`;

// Suggested account types per goal type to be shown first
const GOAL_ACCOUNT_MAP: { [key in GoalTypes]: AccountTypes[] } = {
  [GoalTypes.EDUCATION]: [
    AccountTypes.RESP_FAMILY, AccountTypes.RESP_FAMILY_JOINT, AccountTypes.USA_529, AccountTypes.USA_ESA,
  ],
  [GoalTypes.MAJOR_PURCHASE]: [
    AccountTypes.TFSA, AccountTypes.PERSONAL, AccountTypes.FHSA, AccountTypes.CASH_JOINT,
    AccountTypes.USA_BROKERAGE, AccountTypes.USA_JT_CP, AccountTypes.USA_JT_JTBE, AccountTypes.USA_JT_TIC, AccountTypes.USA_JT_WROS,
  ],
  [GoalTypes.RETIREMENT]: [
    AccountTypes.RRSP, AccountTypes.RRSP_SPOUSAL,
    AccountTypes.USA_IRA, AccountTypes.USA_RT_IRA, AccountTypes.USA_INH_IRA, AccountTypes.USA_INH_RT_IRA,
    AccountTypes.USA_SEP_IRA, AccountTypes.USA_SOLO_401K,
  ],
  [GoalTypes.CAR]: [],
  [GoalTypes.BUILD_WEALTH]: [
    AccountTypes.USA_BROKERAGE, AccountTypes.USA_JT_CP, AccountTypes.USA_JT_JTBE, AccountTypes.USA_JT_TIC, AccountTypes.USA_JT_WROS,
    AccountTypes.USA_IRA, AccountTypes.USA_RT_IRA,
  ],
  [GoalTypes.HOUSE]: [],
  [GoalTypes.VACATION]: [],
  [GoalTypes.START_A_BUSINESS]: [],
  [GoalTypes.WEDDING]: [],
  [GoalTypes.PARENTAL_LEAVE]: [],
  [GoalTypes.SAFETY_NET]: [],
  [GoalTypes.OTHER]: [],
  [GoalTypes.CASH_RESERVE]: [AccountTypes.TFSA, AccountTypes.PERSONAL, AccountTypes.CASH_JOINT],
  [GoalTypes.LEGACY]: [AccountTypes.PERSONAL, AccountTypes.CASH_JOINT],
  [GoalTypes.GROUP]: [],
  [GoalTypes.RETIREMENT_INCOME]: [AccountTypes.RRSP, AccountTypes.RRSP_SPOUSAL],
};

const ACCOUNT_JURISDICTION_ALLOW_LIST: { [key in AccountTypes]?: Jurisdictions[] } = {
  [AccountTypes.USA_JT_JTBE]: [
    Jurisdictions.US_AK, Jurisdictions.US_AR, Jurisdictions.US_DE, Jurisdictions.US_FL, Jurisdictions.US_HI,
    Jurisdictions.US_IL, Jurisdictions.US_IN, Jurisdictions.US_KY, Jurisdictions.US_MD, Jurisdictions.US_MA,
    Jurisdictions.US_MI, Jurisdictions.US_MS, Jurisdictions.US_MO, Jurisdictions.US_NJ, Jurisdictions.US_NY,
    Jurisdictions.US_NC, Jurisdictions.US_OH, Jurisdictions.US_OK, Jurisdictions.US_OR, Jurisdictions.US_PA,
    Jurisdictions.US_RI, Jurisdictions.US_TN, Jurisdictions.US_VT, Jurisdictions.US_VA, Jurisdictions.US_WY,
  ],
  [AccountTypes.USA_JT_CP]: [
    Jurisdictions.US_AZ, Jurisdictions.US_CA, Jurisdictions.US_ID, Jurisdictions.US_LA, Jurisdictions.US_NM,
    Jurisdictions.US_NV, Jurisdictions.US_TX, Jurisdictions.US_WA, Jurisdictions.US_WI,
  ],
};

const isUserEighteenBeforeBeginingOfTheYear = (dateOfBirthString: string): boolean => {
  const date = new Date(dateOfBirthString);
  const ageUserWillBeThisYear = new Date().getFullYear() - date.getFullYear();
  const ageLastYear = ageUserWillBeThisYear - 1;
  return ageLastYear >= 18;
};

export const CreateAccountForGoal = ({
  options, userId, onNext, stepLoading, workflowCompletion,
}: { options: any, userId: string, onNext: () => void, stepLoading: boolean, workflowCompletion?: any }) => {
  const { t } = useTranslation('workflowCompletions');
  const { workflowData, setWorkflowData, updateWorkflowContext } = useContext(WorkflowContext);
  const { activeOrganization, userContext } = useContext(UserContext);
  const { showToast } = useGlobalToast();

  const [userDOB, setUserDOB] = useState('');
  const [existingAccountTypes, setExistingAccountTypes] = useState<string[]>([]);
  const [isPortfolioAssigned, setIsPortfolioAssigned] = useState(false);
  const [suggestedTypes, setSuggestedTypes] = useState<AccountTypes[]>([]);
  const [otherTypes, setOtherTypes] = useState<AccountTypes[]>([]);
  const [saving, setSaving] = useState<boolean>(false);
  const [skipEditAccount, setSkipEditAccount] = useState(false);

  const [updateAccountAffiliations] = useMutation(UPDATE_ACCOUNT_AFFILIATIONS);
  const [closeAccount] = useMutation(CLOSE_ACCOUNT);
  const [closeSubAccount] = useMutation(CLOSE_SUB_ACCOUNT);

  // match the id and name of the hardcoded subaccount workflow
  const isGoalHardcodedWorkflow = workflowCompletion?.id === HARDCODED_GOAL_WORKFLOW_ID && workflowCompletion?.workflow?.steps?.[0]?.name?.en === HARCODED_GOAL_WORFLOW_EN_NAME;

  const { loading: userLoading } = useQuery(FETCH_USER, {
    variables: { userId },
    fetchPolicy: 'no-cache',
    onCompleted: (userData) => {
      const userType = userData?.fetchUser.user?.type;
      const userJurisdiction = userData?.fetchUser.user?.physicalAddress?.jurisdiction as Jurisdictions;

      setExistingAccountTypes(
        userData.fetchUser.user.accounts
          .filter((x: Account) => ['ACTIVE', 'FROZEN', 'INITIATED', 'READY', 'REQUESTED'].includes(x.state))
          .map((a: Account) => a.type),
      );

      determineAccountTypes(userType, userJurisdiction);

      setUserDOB(userData.fetchUser.user.dateOfBirth);
    },
  });

  const { data: goalData } = useQuery(FETCH_GOAL, {
    variables: { goalId: workflowData.currentGoalId },
    fetchPolicy: 'no-cache',
    skip: !workflowData.currentGoalId,
  });

  useEffect(() => {
    if (goalData && workflowData.currentGoalId) {
      if (goalData?.fetchGoal?.goal?.financialProduct) {
        setIsPortfolioAssigned(true);
      }
    }
  }, [goalData, workflowData.currentGoalId]);

  const determineAccountTypes = (userType: EntityTypes, userJurisdiction: Jurisdictions) => {
    // types allowed by feature flags, by country
    const availableTypes0 = getAccountTypes(userType, activeOrganization?.availableFeatureFlags, activeOrganization.applicableLocalization?.countries);

    // drop types out of client's jurisdiction
    const availableTypes = availableTypes0.filter((type) => {
      if (!userJurisdiction) return true;
      const allowList = ACCOUNT_JURISDICTION_ALLOW_LIST[type];
      if (!allowList) return true;
      if (allowList.includes(userJurisdiction)) return true;
      return false;
    });

    // out of availableTypes, pick some as "suggested"
    const allSuggestedTypes = (GOAL_ACCOUNT_MAP[workflowData.currentGoalType as GoalTypes] || []);
    setSuggestedTypes(intersection(allSuggestedTypes, availableTypes) || []);
    setOtherTypes(difference(availableTypes, allSuggestedTypes));

    // auto-select when there's only one type
    if (availableTypes.length === 1 && !workflowData.currentAccountType) {
      setWorkflowData({ ...workflowData, currentAccountType: availableTypes[0] });
    }
  };

  const autoAssignAffiliations = (account: Account) => {
    if (account.affiliations && account.affiliations.length > 0) {
      return;
    }
    const contextIndividuals = (userContext?.entities ?? []).filter((e) => e.entity.type === EntityTypes.INDIVIDUAL) ?? [];
    if (uniq(contextIndividuals.map((entityObj: any) => entityObj?.entity?.id)).length !== 1) {
      /* TODO: should be contextIndividuals.length !== 1, but currently we are experiencing a known
        bug which is entities getting duplicated after newly added
      */
      return;
    }
    const individual = first(contextIndividuals)?.entity;
    const affiliations: any[] = [];
    if (individual) {
      if (options.autoAssignAuthorizedIndividual) {
        affiliations.push({
          relation: AffiliationRelations.Other,
          type: AffiliationTypes.AuthorizedIndividual,
          userId: individual.id,
        });
      }
      if (options.autoAssignIndividualAsDirector) {
        affiliations.push({
          relation: AffiliationRelations.Other,
          type: AffiliationTypes.Director,
          userId: individual.id,
        });
      }
      if (options.autoAssignBeneficialOwner) {
        affiliations.push({
          relation: AffiliationRelations.Other,
          type: AffiliationTypes.BeneficialOwner,
          userId: individual.id,
          allocation: 100,
        });
      }
      if (account.type === AccountTypes.RESP_ADULT) {
        affiliations.push({
          relation: AffiliationRelations.Other,
          type: AffiliationTypes.PrimaryBeneficiary,
          userId: individual.id,
          allocation: 100,
        });
      }
    }
    if (affiliations.length === 0) {
      return;
    }
    updateAccountAffiliations({
      variables: {
        input: { accountId: account.id, affiliations },
      },
      onCompleted: (data: any) => {
        // if entity is incomplete thre will be incompleteAffiliations and incompleteFields and affiliations will not be added
        if (data?.updateAffiliations?.incompleteAffiliations && data?.updateAffiliations?.incompleteAffiliations?.length > 0) {
          showToast({ message: t('corporateCashAccountCreation.incompleteAffiliationError'), severity: 'error' });
        }
      },
    });
  };

  const create = async ({ accountType }: CreateAccountForGoalArgs) => {
    if (!workflowData.currentHouseholdClientGroupId && existingAccountTypes.includes(accountType)) {
      if (isGoalHardcodedWorkflow) {
        setSkipEditAccount(true);
      }
      await createSubAccount({
        variables: {
          input: {
            accountType,
            goalId: workflowData.currentGoalId,
            userId,
            skipIPS: workflowData.currentGoalId && isPortfolioAssigned ? true : undefined,
          },
        },
      });
    } else {
      setSkipEditAccount(false);
      await createAccount();
    }
  };

  const [createAccount] = useMutation(CREATE_ACCOUNT, {
    variables: {
      input: {
        userId,
        type: workflowData.currentAccountType,
        applyForGovFunds: workflowData.currentAccountType?.includes('RESP') ? ['BASIC_CESG'] : undefined,
        householdClientGroupId: workflowData.currentHouseholdClientGroupId ?? undefined,
      },
    },
    onCompleted: (d: { createAccount: { account: { id: string, type: string } } }) => {
      createSubAccount({
        variables: {
          input: {
            accountId: d.createAccount.account.id,
            accountType: workflowData.currentAccountType,
            goalId: workflowData.currentGoalId,
            userId,
            skipIPS: workflowData.currentGoalId && isPortfolioAssigned ? true : undefined,
          },
        },
      });
    },
  });

  const [createSubAccount] = useMutation(CREATE_SUB_ACCOUNT, {
    onCompleted: async (d: { createSubAccount: { subAccount: any } }) => {
      if (
        workflowData.currentAccountType === AccountTypes.CORPORATE_CASH
        && (options.autoAssignAuthorizedIndividual || options.autoAssignBeneficialOwner || options.autoAssignIndividualAsDirector)
      ) {
        const account = d.createSubAccount?.subAccount?.account;
        autoAssignAffiliations(account);
      }

      setupWorkflowData({
        setWorkflowData, workflowData, data: d, skipEditAccount,
      });

      if (updateWorkflowContext) await updateWorkflowContext();
      onNext();
    },
  });

  const onContinue = async ({ accountType }: CreateAccountForGoalArgs) => {
    if (accountType === 'RESP_ADULT' && !isUserEighteenBeforeBeginingOfTheYear(userDOB)) {
      showToast({ message: t('respAccountCreation.respAgeRestrictionError'), severity: 'error' });
      return;
    }

    setSaving(true);
    if (
      workflowData.currentAccountId
      && workflowData.currentAccountState === AccountStates.INITIATED
      && workflowData.currentAccountType !== accountType
    ) {
      // It should close original account if a new type is selected
      await closeAccount({
        variables: {
          input: {
            accountId: workflowData.currentAccountId,
            inactiveReason: 'OTHER',
          },
        },
      });
      await create({ accountType });
    } else if (
      workflowData.currentSubAccountId
      && workflowData.currentAccountType !== accountType
    ) {
      // If only a subAccount was opened it should close the previous before continuing
      await closeSubAccount({
        variables: {
          input: {
            subAccountId: workflowData.currentSubAccountId,
            inactiveReason: 'OTHER',
          },
        },
      });
      await create({ accountType });
    } else if (
      workflowData.currentSubAccountId
      && workflowData.currentAccountType === accountType
    ) {
      await create({ accountType });
    } else if (!workflowData.currentSubAccountId) {
      await create({ accountType });
    } else {
      onNext();
    }
  };

  return (
    <CreateAccountForGoalVisual
      options={options}
      suggestedTypes={suggestedTypes}
      otherTypes={otherTypes}
      accountType={workflowData.currentAccountType ?? ''}
      continueFunc={onContinue}
      loading={userLoading || stepLoading}
      saving={saving}
      workflowCompletion={workflowCompletion}
    />
  );
};

export default CreateAccountForGoal;

import { gql, useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Chip, SxProps } from '@mui/material';
import { Autocomplete } from '../../2-component';
import { Institution, PhysicalAddress } from '../../../interfaces';
import { generateAddressString } from '../../../util';

type Item = Required<{ id: string, name: string, physicalAddress: PhysicalAddress }>;
type SearchResults = { items: Item[], count: number };

export const FETCH_INSTITUTIONS = gql`
  query fetchInstitutions($input: FetchInstitutionsInput!) {
    fetchInstitutions(input: $input) {
      totalCount
      institutions {
        id
        isDraft
        name
        physicalAddress {
          city
          province
          streetName
          postal
          unitNumber
          houseNumber
          neighborhood
        }
      }
    }
  }
`;

export const BankSelectField = ({
  institution, setInstitution, label, disabled, fullWidth, labelSx, sx, onBlur, error,
}: {
  institution: any, setInstitution: (institution: Institution | undefined) => void, label: string, disabled?: boolean, onBlur?: any, error?: any,
  fullWidth?: boolean, isJointAccount?: boolean, labelSx?: SxProps, sx?: SxProps,
}) => {
  const [searchResult, setSearchResult] = useState<SearchResults>({ items: [], count: 0 });
  const [searchInstitutions] = useLazyQuery(FETCH_INSTITUTIONS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const searchFn = (query: string): Promise<SearchResults> => new Promise<SearchResults>((resolve) => {
    searchInstitutions({
      variables: {
        input: {
          filter: {
            searchText: query,
          },
        },
      },
      onCompleted: (data) => {
        setSearchResult({
          items: [...data.fetchInstitutions.institutions],
          count: Number(data.fetchInstitutions.totalCount),
        });
      },
      onError: () => {
        setSearchResult({
          items: [],
          count: 0,
        });
      },
    }).then();
  });

  useEffect(() => {
    searchFn('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <Autocomplete
        data-testid="userSelectField"
        freeSolo
        disabled={disabled}
        // value={Object.keys(user).length === 0 ? null : { label: defineName(user), id: user.id }}
        options={searchResult.items.map((item) => ({ label: `${item.name} - ${generateAddressString(item.physicalAddress)}`, id: item.id }))}
        fullWidth={fullWidth}
        label={label}
        isOptionEqualToValue={(option: any, value: any) => option.label === value}
        onInputChange={(e: any, value: any) => searchFn(value)}
        onBlur={onBlur}
        error={error}
        onChange={(e: any, value: any) => {
          if (value?.id) {
            const result = searchResult.items.find((item) => item.id === value.id);
            setInstitution(result as Institution);
          }
        }}
        sx={sx}
        renderTags={(tagValue: any, getTagProps: any) => tagValue.map((option: any, index: number) => (
            <Chip
                label={option}
                sx={{ marginTop: '-2px !important' }}
                {...getTagProps({ index })}
                size='small'
                onDelete={undefined}
            />
        ))
        }
        labelSx={labelSx}
      />
  );
};

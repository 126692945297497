import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CountryCodes } from '@onevesthq/ov-enums';
import { Box } from '../../../1-primative';
import {
  Button, Form,
} from '../../../2-component';
import { SettingsCard } from '../../../3-pattern';
import { UserContext } from '../../../../providers/userContextProvider';
import { useGlobalToast } from '../../../../providers/globalToastProvider';
import { FeatureFlag, useFeatureFlags } from '../../../4-module/featureFlag/featureFlag';

export const AccountsSettings = () => {
  const { activeOrganization } = useContext(UserContext);
  const { showToast } = useGlobalToast();
  const { t } = useTranslation(['settings', 'shared']);
  const [flags, setFlags] = useState<any[]>([]);
  const {
    featureFlags, updateFeatureFlag, saveFeatureFlags,
  } = useFeatureFlags();

  useEffect(() => {
    const countries = activeOrganization.applicableLocalization?.countries;
    let newFlags = [];
    if (!countries) {
      newFlags = featureFlags.filter((ff) => ['ACCOUNT_TYPES', 'ACCOUNT_TYPES_USA', 'ACCOUNT_TYPES_UK'].includes(ff.group));
    } else {
      const types: any[] = [];
      if (countries.includes(CountryCodes.CA)) types.push('ACCOUNT_TYPES');
      if (countries.includes(CountryCodes.US)) types.push('ACCOUNT_TYPES_USA');
      if (countries.includes(CountryCodes.GB)) types.push('ACCOUNT_TYPES_UK');
      newFlags = featureFlags.filter((ff) => types.includes(ff.group));
    }

    setFlags(newFlags);
  }, [featureFlags, activeOrganization]);

  const save = async () => {
    await saveFeatureFlags();
    showToast({
      message: t('shared:successfullySaved'),
      severity: 'success',
    });
  };

  return (
    <SettingsCard
      title={t('accounts.title')}
      description={t('accounts.description')}
    >
      <Form onSubmit={save}>
        <Box display='flex' flexDirection='column' gap={2}>
          { flags.map((ff) => (
            <FeatureFlag featureFlag={ff} onChange={updateFeatureFlag} testId={`settings-accounts-${ff.key}`} underline />
          ))}
          <Box display='flex' justifyContent='flex-end'>
            <Button type='submit' label={t('shared:save')} dataTestId='settings-general-submit-button'/>
          </Box>
        </Box>
      </Form>
    </SettingsCard>
  );
};

import { useTranslation } from 'react-i18next';
import type { ChartOptions, Plugin, ScriptableContext } from 'chart.js';
import convert from 'color-convert';
import { isNil } from 'lodash';
import { Box } from '../../1-primative';
import {
  CircularProgress,
  EmptyStateAlt,
  EmptyStateHeaderTitle,
  LineChart,
  getChartBackground,
} from '../../2-component';
import { useLocalization } from '../../../util/useLocalization';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { NET_CONTRIBUTION_INDICATOR_IMAGE_SIZE } from './marketValueContent';

import EmptyChart from '../../../assets/images/custom/emptyChart.svg';
import MarketValueIndicatorIcon from '../../../assets/images/market-value-indicator-icon.svg';

// TODO: Replace with new components
import { useLineChartTokens } from '../../2-component/lineChart/lineChart.tokens';

// this method pads (adds extra zeros) the net contribution cents array
function getNetContributionsCentsArray(netContributionCentsArray: any[], marketValueCentsArray: number[]): number[] {
  const lengthDifference = marketValueCentsArray.length - netContributionCentsArray.length;
  if (lengthDifference > 0) {
    return [...netContributionCentsArray, ...new Array(lengthDifference).fill(0)];
  }
  return netContributionCentsArray;
}

interface IMarketValueLineChart {
  readonly displayNetContributions?: boolean;
  readonly netContributionsLabel: string;
  readonly history: { marketValueCents: number, netContributionCents: number }[];
  readonly loading?: boolean;
  readonly hideGraph?: boolean;
  readonly mouseIsOverChart?: boolean;
  readonly showSimpleRateOfReturn?: boolean;
  readonly showTimeWeightedReturn?: boolean;
  readonly showMoneyWeightedReturn?: boolean;
  readonly setMouseIsOverChart: (value: boolean) => void;
  readonly setActiveMarketValueCents: (value: number) => void;
  readonly setActiveNetContributionCents: (value: number) => void;
}

export const MarketValueLineChart: React.FC<IMarketValueLineChart> = ({
  history,
  loading,
  hideGraph,
  mouseIsOverChart,
  displayNetContributions = true,
  netContributionsLabel,
  showSimpleRateOfReturn,
  showTimeWeightedReturn,
  showMoneyWeightedReturn,
  setMouseIsOverChart,
  setActiveMarketValueCents,
  setActiveNetContributionCents,
}: any) => {
  const { t } = useTranslation(['client', 'components']);
  const { localizedDate } = useLocalization();
  const { sys, comp } = useThemeTokens(useLineChartTokens());

  const netContributionCentsArray = history?.filter((x: any) => x.marketValueCents !== 0).map((x: any) => x.netContributionCents);
  const marketValueCentsArray = history?.filter((x: any) => x.marketValueCents !== 0).map((x: any) => x.marketValueCents);

  const labels = history?.filter((x: any) => x.marketValueCents !== 0)?.map((x: any) => localizedDate(x.date)) ?? [];
  const rgb = convert.hex.rgb(comp.lineChart.color || '#000000');

  const getChartHeight = (breakpoint: 'xs' | 'sm') => {
    let baseValue = breakpoint === 'xs' ? 141 : 107;
    if (showSimpleRateOfReturn) baseValue += 19;
    if (showTimeWeightedReturn || showMoneyWeightedReturn) baseValue += 28;
    if (!showSimpleRateOfReturn && !showTimeWeightedReturn && !showMoneyWeightedReturn) baseValue -= 4;

    return `calc(100% - ${baseValue}px)`;
  };

  const tooltipLine: Plugin<'line'> = {
    id: 'tooltipLine',
    afterDraw: (chart: any) => {
      const chartPointNetContributionIcon = new Image();
      chartPointNetContributionIcon.src = MarketValueIndicatorIcon;

      if (chart.tooltip?._active && chart.tooltip._active.length) {
        const ctx3 = chart.ctx;
        ctx3.save();
        const activePoint = chart.tooltip._active[0];
        const netContributionPoint = chart.tooltip._active[1] || null;

        // Get the index of the active tooltip
        const { index } = activePoint;
        const dateLabel = chart.data.labels[index];

        // Market Value Cents
        const hoveredMarketValue = chart.data.datasets[0].data[index];
        setActiveMarketValueCents(hoveredMarketValue);
        if (displayNetContributions) {
          const hoveredNetContribution = chart.data.datasets[1]?.data[index];
          setActiveNetContributionCents(hoveredNetContribution);
        }

        ctx3.beginPath();
        ctx3.setLineDash([2, 2]);

        ctx3.moveTo(activePoint.element.x, chart.chartArea.top + 5);
        ctx3.lineTo(activePoint.element.x, activePoint.element.y);
        ctx3.lineWidth = 1;
        ctx3.strokeStyle = comp.lineChart.secondaryLine;
        ctx3.stroke();

        // Add text above the dashed line
        const width = ctx3.canvas.offsetWidth;
        ctx3.font = `14px ${sys.font.base}`;
        ctx3.fillStyle = comp.lineChart.secondaryLine;
        ctx3.textAlign = activePoint.element.x <= 42 ? 'left' : activePoint.element.x >= width - 42 ? 'right' : 'center';
        ctx3.fillText(new Date(dateLabel).toLocaleDateString('en-US', dateTimeFormatOption), activePoint.element.x, chart.chartArea.top - 15);

        ctx3.restore();
        ctx3.beginPath();
        ctx3.setLineDash([2, 2]);

        ctx3.moveTo(activePoint.element.x, activePoint.element.y);
        ctx3.lineTo(activePoint.element.x, chart.chartArea.bottom);
        ctx3.lineWidth = 1;
        ctx3.strokeStyle = comp.lineChart.secondaryLine;
        ctx3.stroke();

        // Draw the net contribution icon on top of the line
        if (displayNetContributions && netContributionPoint) {
          ctx3.drawImage(
            chartPointNetContributionIcon,
            netContributionPoint.element.x - 7, // Adjust positioning as needed
            netContributionPoint.element.y - 7, // Adjust positioning as needed
            NET_CONTRIBUTION_INDICATOR_IMAGE_SIZE, // Width of the image
            NET_CONTRIBUTION_INDICATOR_IMAGE_SIZE, // Height of the image
          );
        }

        ctx3.restore();
      }
    },
  };

  return (
    <Box data-testid='market-value-line-chart-container' display='flex' flexDirection='column' alignItems='center' justifyContent='center' height={{
      xs: getChartHeight('xs'), sm: getChartHeight('sm'),
    }}>
      {loading ? (
        <Box height='100%' display='flex' alignItems='center'>
          <CircularProgress data-testid='market-value-circular-progress-loading' />
        </Box>
      ) : (
        <Box sx={{ width: '100%', height: '100%' }}>
          {!isNil(history) ? (
            (history ?? []).length < 2 || hideGraph ? (
              <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' width='100%' height='100%' data-testid='market-value-line-chart-empty'>
                <img alt='empty-chart' src={EmptyChart} style={{ maxWidth: '300px' }} />
                <EmptyStateHeaderTitle sx={{ mt: 2 }} title={t('assetsOverview.emptyChart')} />
              </Box>
            ) : (
              <LineChart
                testId='market-value-line-chart'
                labels={labels}
                plugins={[tooltipLine]}
                overrideOptions={overrideLineOptions}
                overrideData={{
                  labels,
                  datasets: [
                    { label: t('marketValue'), data: marketValueCentsArray },
                    ...(displayNetContributions ? [{ label: netContributionsLabel, data: getNetContributionsCentsArray(netContributionCentsArray, marketValueCentsArray) }] : []),
                  ].map((x) => ({
                    fill: x?.label !== netContributionsLabel,
                    label: x?.label,
                    data: x?.data,
                    borderColor: x?.label === netContributionsLabel ? (displayNetContributions && mouseIsOverChart ? comp.lineChart.secondaryLine : '#00000000') : comp.lineChart.color,
                    borderDash: x?.label === netContributionsLabel ? [8, 4] : [],
                    borderWidth: x?.label === netContributionsLabel ? 1.5 : undefined,
                    backgroundColor: (context: ScriptableContext<'line'>) => getChartBackground(context, rgb),
                    tension: x?.label === netContributionsLabel ? 0 : comp.lineChart.tension,
                  })),
                }}
                mouseMove={() => setMouseIsOverChart(true)}
                mouseOut={() => setMouseIsOverChart(false)}
              />
            )
          ) : (
            <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' width='100%' height='100%' data-testid='market-value-line-chart-empty'>
              <img alt='empty-chart' src={EmptyChart} style={{ maxWidth: '300px' }} />
              <EmptyStateAlt sx={{
                mt: 2,
                textAlign: 'center',
                height: '100px',
                flexDirection: 'column',
              }} title={t('shared:errroStateTitle')} subtitle={t('shared:errorStateSubText')} />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

const dateTimeFormatOption: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  timeZone: 'UTC',
};

const overrideLineOptions: ChartOptions<'line'> = {
  plugins: { tooltip: { enabled: false } },
  layout: {
    padding: {
      top: 25,
      bottom: 25,
    },
  },
};

import { useEffect, useState } from 'react';
import { translateBackend } from 'assets/i18n/config';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import {
  Box, Button, Form, Grid, TextField, Typography,
} from '../../../..';
import { isAccountHolder, UPDATE_INTEGRATION_FORM_AGREEMENT } from './collectSigners';

interface SignerInfo {
  firstName: string;
  lastName: string;
  email: string;
}

export const CollectSignersVisual = ({
  options,
  integrationFormAgreementData,
  setIntegrationFormAgreementData,
  signingRoles,
  loading,
  onNext,
}: {
  options: any;
  integrationFormAgreementData: any;
  setIntegrationFormAgreementData: any;
  signingRoles: any[];
  loading: boolean;
  onNext: () => void;
}) => {
  const { t } = useTranslation('client');
  const [disableContinue, setDisableContinue] = useState<boolean>(true);
  const accountHolderRole = signingRoles.find((sr: any) => isAccountHolder(sr));
  const [signerInfo, setSignerInfo] = useState<any>({
    [accountHolderRole?.roleId]: {
      firstName: accountHolderRole?.firstName,
      lastName: accountHolderRole?.lastName,
      email: accountHolderRole?.email,
    },
  });

  const [sendIntegrationFormAgreement, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_INTEGRATION_FORM_AGREEMENT);

  const handleChange = (roleId: string, field: keyof SignerInfo, value: string) => {
    const info = {
      ...signerInfo,
      [roleId]: {
        ...signerInfo[roleId],
        [field]: value,
      },
    };

    setSignerInfo(info);
    setIntegrationFormAgreementData({
      ...integrationFormAgreementData,
      signers: signingRoles.map((role: any) => ({
        ...role,
        ...signerInfo[role.roleId],
      })),
    });
  };

  const submit = () => {
    sendIntegrationFormAgreement({
      variables: {
        input: {
          signers: signingRoles.map((role: any) => ({
            ...role,
            ...signerInfo[role.roleId],
          })),
          /**
           * TODO We may need to update this to support multiple integrations, right now there's only 1 so it works.
           * In the future we may need to update the WorkflowCompletion schema to pass along an iid for us to filter on.
           */
          externalId: integrationFormAgreementData?.integrations[0].iid,
        },
      },
    });
    if (!updateError) {
      onNext();
    }
  };

  useEffect(() => {
    const missingInfo = signingRoles.map((role: any) => {
      if (!isAccountHolder(role) && (!signerInfo[role.roleId]?.firstName || !signerInfo[role.roleId]?.lastName || !signerInfo[role.roleId]?.email)) {
        return true;
      }
      return false;
    });
    setDisableContinue(missingInfo.includes(true));
  }, [signerInfo, signingRoles]);

  return (
    <Form onSubmit={submit}>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>
        {translateBackend(options?.title)}
      </Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>
        {translateBackend(options?.subtitle)}
      </Typography>
      <Grid container spacing={2}>
        {signingRoles?.map(
          (role: any) => role && (
            <Grid item xs={12} md={12} key={role.roleId}>
              <Typography variant='labelLarge' sx={{ mb: 2 }}>
                {t(role.roleName)}
              </Typography>
              <TextField
                testId=''
                label={t('details.firstName')}
                onChange={(e: any) => handleChange(role.roleId, 'firstName', e.target.value)}
                value={signerInfo[role.roleId]?.firstName ?? role.firstName ?? ''}
                onBlur={undefined}
                fullWidth
                sx={{ mb: 2 }}
                disabled={loading}
                error={isAccountHolder(role) ? undefined : !signerInfo[role.roleId]?.firstName}
                InputProps={{
                  readOnly: isAccountHolder(role),
                }}
              />
              <TextField
                testId=''
                label={t('details.lastName')}
                onChange={(e: any) => handleChange(role.roleId, 'lastName', e.target.value)}
                value={signerInfo[role.roleId]?.lastName ?? role.lastName ?? ''}
                onBlur={undefined}
                fullWidth
                sx={{ mb: 2 }}
                disabled={loading}
                error={isAccountHolder(role) ? undefined : !signerInfo[role.roleId]?.lastName}
                InputProps={{
                  readOnly: isAccountHolder(role),
                }}
              />
              <TextField
                testId=''
                label={t('details.primaryEmail')}
                onChange={(e: any) => handleChange(role.roleId, 'email', e.target.value)}
                value={signerInfo[role.roleId]?.email ?? role.email ?? ''}
                onBlur={undefined}
                fullWidth
                sx={{ mb: 2 }}
                disabled={loading}
                error={isAccountHolder(role) ? undefined : !signerInfo[role.roleId]?.email}
                InputProps={{
                  readOnly: isAccountHolder(role),
                }}
              />
            </Grid>
          ),
        )}
        <Box display='flex' justifyContent='end'>
          <Button dataTestId='' label={'send'} sx={{ mt: 3, textAlign: 'center' }} type='submit' disabled={loading || disableContinue} loading={updateLoading} />
        </Box>
      </Grid>
    </Form>
  );
};

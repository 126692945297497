import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Box, Typography, Button, CircularProgress, Table, TableHead,
  TableCell, TableRow, TableBody, TextField, InputAdornment, Grid, Dialog, DialogTitle, DialogContent, IconButton, Chip, styled,
} from '@mui/material';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import { round } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Exchanges } from '../../../interfaces';
import { useGlobalToast } from '../../../providers/globalToastProvider';

const DISCREPANCY_THRESHOLD_PCT_PRICE = 3;
const DISCREPANCY_THRESHOLD_PCT_QUANTITY = 0;

const FETCH_BULK_TRADE_RUN = gql`
  query fetchBulkTradeRun($bulkTradeRunId: ObjectID!, $exchanges: [Exchanges!]) {
    fetchBulkTradeRun(bulkTradeRunId: $bulkTradeRunId) {
      bulkTradeRun {
        id
        bulkTradeRequests(exchanges: $exchanges) {
          type
          bulkTrades {
            _id
            expectedTradePriceCents
            expectedTradePriceInCurrencyCents
            currency
            total
            actualTotal
            actualTradePriceCents
            actualTradePriceInCurrencyCents
            financialProduct { id }
          }
        }
      }
    }
  }
`;

export type AccountBulkTrade = {
  _id: string;
  expectedTradePriceCents: number;
  expectedTradePriceInCurrencyCents: number;
  expectedTradePriceInCurrency: number;
  currency: string;
  total: number;
  originalPriceInCurrencyCents: number;
  originalPriceInCurrency: number;
  originalTotal: number;
  actualTradePriceCents?: number,
  actualTradePriceInCurrencyCents?: number,
  actualTotal?: number,
};

const UPDATE_PRICE_AND_QUANTITY = gql`
  mutation calculateTradeRequestFills($input: CalculateTradeRequestFillsInput!){
    calculateTradeRequestFills(input: $input) {
      success
    }
  }
`;

export const limitDecimals = (number: string): string => {
  let value = number.toString();

  const [integerPart, decimalPart] = value.split('.');

  if (decimalPart) {
    const limitedDecimal = decimalPart.slice(0, 6);
    value = `${integerPart}.${limitedDecimal}`;
  }

  return value; // should be a string to allow for a decimal point, converted to a number later
};

export const bulkPriceUpdate = (bulkTrades: any[], content: string, priceField: string, callback: (bulkTrades: any[], isHasErrors: boolean, notFoundSecurities: string[]) => void) => {
  const mappedValues = content.split('\n').map((x: any) => x.split('\t'));
  let isHasErrors = false;
  const notFoundSecurities: string[] = [];
  const localBulkTrades: any[] = [...bulkTrades];

  mappedValues.forEach((line: any) => {
    if (line[0]) {
      const index = bulkTrades.findIndex((x: any) => x._id === line[0]);
      const price = round(parseFloat((line[1] ?? '').replace('$', '')), 6);
      const quantity = parseInt(line[2] ?? '', 10);

      if (index > -1) {
        if (!Number.isNaN(price)) {
          localBulkTrades[index][priceField] = price;
        }

        if (!Number.isNaN(quantity)) {
          localBulkTrades[index].total = quantity;
        }
      } else {
        notFoundSecurities.push(line[0]);
      }
    }
  });

  if (notFoundSecurities.length) {
    isHasErrors = true;
  }

  callback(localBulkTrades, isHasErrors, notFoundSecurities);
};

const UpdateTradesTable = ({
  type,
  bulkTradeRunId,
  setDiscrepancyWarning,
  exchanges = [],
  onPriceUpdate,
}: {
  type: 'BUY' | 'SELL',
  bulkTradeRunId: string,
  setDiscrepancyWarning: any,
  exchanges?: Exchanges[],
  onPriceUpdate?: () => void,
}) => {
  const { t } = useTranslation('bulkTrader');
  const { showToast } = useGlobalToast();

  const [bulkTrades, setBulkTrades] = useState<AccountBulkTrade[]>([]);
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState('');

  const { data, loading } = useQuery(FETCH_BULK_TRADE_RUN, {
    variables: {
      bulkTradeRunId,
      ...(exchanges.length > 0 && { exchanges }),
    },
    fetchPolicy: 'no-cache',
  });

  const [updatePriceAndQuantity, { loading: updateLoading }] = useMutation(UPDATE_PRICE_AND_QUANTITY, {
    onCompleted: () => {
      if (onPriceUpdate) {
        onPriceUpdate();
      }
    },
  });

  useEffect(() => {
    if (data) {
      setBulkTrades(
        data.fetchBulkTradeRun.bulkTradeRun.bulkTradeRequests
          .find((x: any) => x.type === type)
          .bulkTrades.map((bt: AccountBulkTrade) => {
            const expectedTradePriceInCurrencyCents = (bt.actualTradePriceInCurrencyCents ?? bt.actualTradePriceCents) ?? (bt.expectedTradePriceInCurrencyCents ?? bt.expectedTradePriceCents);
            const expectedTradePriceInCurrency = round((expectedTradePriceInCurrencyCents ?? 0) / 100, 6);

            return {
              ...bt,
              originalPriceCents: bt.expectedTradePriceCents,
              originalPriceInCurrencyCents: bt.expectedTradePriceInCurrencyCents,
              originalPriceInCurrency: round((bt.expectedTradePriceInCurrencyCents ?? 0) / 100, 6),
              originalTotal: bt.total,
              total: bt.actualTotal ?? bt.total,
              expectedTradePriceInCurrencyCents,
              expectedTradePriceInCurrency,
              currency: bt.currency || 'CAD',
            };
          }),
      );
    }
  }, [data, type]);

  const bulkUpdate = () => {
    bulkPriceUpdate(bulkTrades, content, 'expectedTradePriceInCurrency', (updatedBulkTrades: any[], isHasErrors: boolean, notFoundSecurities: string[]) => {
      if (isHasErrors) {
        showToast({ message: t('securitiesWereNotFound', { securities: notFoundSecurities.join(', ') }), severity: 'warning' });
      }

      if (!isHasErrors) {
        setBulkTrades(updatedBulkTrades);

        setOpen(false);
      }
    });
  };

  const percentualDifference = (a: number, b: number): number => {
    try {
      return Math.abs(100 * ((a - b) / b));
    } catch {
      return 0;
    }
  };

  useEffect(() => {
    let numWarnings = 0;
    bulkTrades.forEach((bt) => {
      if (percentualDifference(bt.expectedTradePriceInCurrency, bt.originalPriceInCurrency) > DISCREPANCY_THRESHOLD_PCT_PRICE) {
        numWarnings++;
      }
      if (percentualDifference(bt.total, bt.originalTotal) > DISCREPANCY_THRESHOLD_PCT_QUANTITY) {
        numWarnings++;
      }
    });
    setDiscrepancyWarning(numWarnings > 0);
  }, [bulkTrades, setDiscrepancyWarning]);

  const DifferenceChip = ({
    newValue, origValue, warningThresholdPct = 0,
  } : {
    newValue: number, origValue: number, warningThresholdPct?: number
  }):JSX.Element => {
    const delta = percentualDifference(newValue, origValue);
    const text = `${(newValue > origValue ? '+' : '-') + delta.toFixed(2)}%`;
    return (
      <Chip label={text} color={delta >= warningThresholdPct ? 'warning' : 'default'} sx={{ marginLeft: '0.5em' }}/>
    );
  };

  const NarrowTableCell = styled(TableCell)({ padding: 0, align: 'right' });

  /**
   * use regex to prevent non numeric values
   * avoiding using "type='number'" on an input
   * @see https://mui.com/material-ui/react-text-field/#type-quot-number-quot
   */
  const updateTradePrice = (value: string, index: number): void => {
    if (!/^(\d+(\.\d*)?|)$/.test(value)) return;

    bulkTrades[index].expectedTradePriceInCurrency = limitDecimals(value) as any;
    setBulkTrades([...bulkTrades]);
  };

  const sanitizeTradePrice = (value: string, index: number): void => {
    bulkTrades[index].expectedTradePriceInCurrency = (Number(value.toString().replace(/^0+|(?:\.|(\..*?))0+$/gm, '$1')) as any) || 0;
    setBulkTrades([...bulkTrades]);
  };

  return (
    <Box>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : !bulkTrades.length ? (
        <></>
      ) : (
        <>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Typography sx={{ p: 2 }} variant='h6'>{t(`bulk${type}Trades`)}</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={() => setOpen(true)} sx={{ m: 2 }}>
                <BackupTableIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><Typography variant='overline'>{t('security')}</Typography></TableCell>
                <TableCell></TableCell>
                <TableCell><Typography variant='overline'>{t('price')}</Typography></TableCell>
                <TableCell></TableCell>
                <TableCell><Typography variant='overline'>{t('quantity')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bulkTrades.map((line: any, index: number) => (
                <TableRow>
                  <TableCell>
                    <Typography fontWeight="bold">{line._id}</Typography>
                  </TableCell>
                  <NarrowTableCell>
                    { line.expectedTradePriceInCurrency !== line.originalPriceInCurrency
                      && <Typography color="GrayText">{Number(line.originalPriceInCurrency).toFixed(2)}</Typography>
                    }
                  </NarrowTableCell>
                  <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      value={line.expectedTradePriceInCurrency}
                      onBlur={(e: any) => sanitizeTradePrice(e.target.value, index)}
                      sx={{ width: '12em' }}
                      onChange={(e: any) => updateTradePrice(e.target.value, index)}
                      size='small'
                      autoComplete='off'
                      inputMode='decimal'
                      InputProps={{
                        startAdornment: <InputAdornment position='start'>{line.currency === 'USD' ? 'US$' : '$'}</InputAdornment>,
                        inputProps: { min: 0, step: 'any' },
                      }}
                    />
                    { line.expectedTradePriceInCurrency !== line.originalPriceInCurrency
                      && <DifferenceChip
                        newValue = {line.expectedTradePriceInCurrency}
                        origValue = {line.originalPriceInCurrency}
                        warningThresholdPct={DISCREPANCY_THRESHOLD_PCT_PRICE}
                      />
                    }
                  </TableCell>
                  <NarrowTableCell>
                    { line.total !== line.originalTotal
                      && <Typography color="GrayText">{line.originalTotal}</Typography>
                    }
                  </NarrowTableCell>
                  <TableCell>
                    <TextField
                      value={line.total}
                      sx={{ width: '9em' }}
                      size='small'
                      type='number'
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                      onChange={(e: any) => {
                        bulkTrades[index].total = round(parseFloat(e.target.value), 0);
                        setBulkTrades([...bulkTrades]);
                      }}
                    />
                    { line.total !== line.originalTotal
                      && <DifferenceChip
                        newValue = {line.total}
                        origValue = {line.originalTotal}
                        warningThresholdPct={DISCREPANCY_THRESHOLD_PCT_QUANTITY}
                      />
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container justifyContent='flex-end'>
            <Grid item>
                <Button
                  variant='contained'
                  sx={{ m: 2 }}
                  disabled={updateLoading}
                  onClick={() => updatePriceAndQuantity({
                    variables: {
                      input: {
                        bulkTradeRunId,
                        type,
                        bulkTrades: bulkTrades.map((bulkTrade: any) => ({
                          actualPriceCents: Number(
                            round(bulkTrade.expectedTradePriceInCurrency * 100, 6),
                          ),
                          totalQuantity: bulkTrade.total,
                          financialProductId: bulkTrade.financialProduct.id,
                        })),
                      },
                    },
                  })}
                >{t('save')}</Button>
            </Grid>
          </Grid>
        </>
      )}
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth='sm' fullWidth>
        <DialogTitle>{t('pasteFromExcel')}</DialogTitle>
        <DialogContent>
          <Typography>{t('orderOfContent')}</Typography>
          <TextField multiline rows={10} value={content} onChange={(e) => setContent(e.target.value)} fullWidth/>
          <Button variant="contained" sx={{ marginTop: 1 }} onClick={bulkUpdate}>{t('update')}</Button>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default UpdateTradesTable;

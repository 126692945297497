import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { Box, Grid, MenuItem } from '@mui/material';
import { kebabCase } from 'lodash';

import {
  Button, Form, SelectField, TextField, Typography, Switch,
} from 'ovComponents';
import { additionalInfo } from 'ovComponents/4-module/configurableOptionFields';
import InfoDialog from 'ovComponents/2-component/infoDialog/infoDialog';
import { getBackendLanguage, translateBackend } from 'assets/i18n/config';
import { Theme } from 'interfaces';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';

export enum ModelType {
  STANDARD = 'STANDARD',
}

export const AddModelPortfolioDetailsVisual = ({
  options, modelPortfolioData, loading, continueFunc, themes = [], workflowCompletion, setModelPortfolioData,
}: {
  options: any, modelPortfolioData: any, loading: boolean, continueFunc: any, themes?: Theme[], workflowCompletion?: any, setModelPortfolioData: any,
}) => {
  const { t } = useTranslation(['modelPortfolios, shared']);
  const [focused, setFocused] = useState<string[]>([]);
  const { sendAnalytic } = useContext(AnalyticsContext);

  const validate = () => {
    const fields = Object.keys(options).filter((key) => {
      if (!options[key]?.enabled) return false;
      if (options[key]?.required === 'NOT_REQUIRED') return false;

      if (key === 'translatedName') {
        return !modelPortfolioData[key]?.[getBackendLanguage()];
      }

      if (key === 'suggestable') return false;
      return !modelPortfolioData[key];
    });

    setFocused(fields);
    return fields.length === 0;
  };

  const submit = () => {
    if (validate()) {
      sendAnalytic(ovAnalyticsEvents.workflowsAddModelPortfolioDetailsContinueButtonSelect, {
        workflowStepTitle: options?.title,
        workflowId: workflowCompletion?.workflow?.id,
        workflowName: workflowCompletion?.workflow?.name,
        activeWorkflowCompletionId: workflowCompletion?.id,
        objectId: workflowCompletion?.objectId,
        objectType: workflowCompletion?.objectType,
      });
      continueFunc();
    }
  };

  const update = (newValue: any) => {
    setModelPortfolioData({ ...modelPortfolioData, ...newValue });
  };

  return (
    <Form onSubmit={submit}>
      {options?.title && (
        <Typography variant="displayLarge" sx={{ mt: 1, mb: 2 }}>
          {translateBackend(options?.title)}
          {additionalInfo(options?.title?.additionalInfo) && (
            <InfoDialog information={additionalInfo(options?.title?.additionalInfo) ?? ''} />
          )}
        </Typography>
      )}

      <Grid container spacing={2}>
        {options?.modelType?.enabled && (
          <Grid item xs={12} md={6}>
            <SelectField
              testId="model-type"
              onChange={(e: any) => update({ modelType: e.target.value })}
              disabled={loading}
              label={translateBackend(options?.modelType?.label)}
              infoTooltip={additionalInfo(options?.modelType?.additionalInfo)}
              fullWidth
              value={modelPortfolioData.modelType}
              onBlur={() => setFocused([...focused, 'modelType'])}
              error={!modelPortfolioData.modelType && focused.includes('modelType')}
            >
              <MenuItem
                value={ModelType.STANDARD}
                data-testid={`type-${kebabCase(ModelType.STANDARD)}`}
              >
                {t('modelPortfolios:modelType.STANDARD')}
              </MenuItem>
            </SelectField>
          </Grid>
        )}

        {options?.translatedName?.enabled && (
          <Grid item xs={12} md={6}>
            <TextField
              testId="model-name"
              onChange={(e: any) => update({ translatedName: { [getBackendLanguage()]: e.target.value } })}
              disabled={loading}
              label={translateBackend(options?.translatedName?.label)}
              infoTooltip={additionalInfo(options?.translatedName?.additionalInfo)}
              fullWidth
              value={modelPortfolioData.translatedName?.[getBackendLanguage()] ?? ''}
              onBlur={() => setFocused([...focused, 'translatedName'])}
              error={!modelPortfolioData.translatedName?.[getBackendLanguage()] && focused.includes('translatedName')}
            />
          </Grid>
        )}

        {options?.translatedPortfolioDescription?.enabled && (
          <Grid item xs={12}>
            <TextField
              testId="model-description"
              onChange={(e: any) => update({ translatedPortfolioDescription: { [getBackendLanguage()]: e.target.value } })}
              disabled={loading}
              label={translateBackend(options?.translatedPortfolioDescription?.label)}
              infoTooltip={additionalInfo(options?.translatedPortfolioDescription?.additionalInfo)}
              fullWidth
              multiline
              rows={3}
              value={modelPortfolioData.translatedPortfolioDescription?.[getBackendLanguage()] ?? ''}
              onBlur={() => setFocused([...focused, 'translatedPortfolioDescription'])}
              error={!modelPortfolioData.translatedPortfolioDescription?.[getBackendLanguage()] && focused.includes('translatedPortfolioDescription')}
            />
          </Grid>
        )}

        {options?.riskProfileName?.enabled && (
          <Grid item xs={12}>
            <TextField
              testId="risk-profile"
              onChange={(e: any) => update({ riskProfileName: { [getBackendLanguage()]: e.target.value } })}
              disabled={loading}
              label={translateBackend(options?.riskProfileName?.label)}
              infoTooltip={additionalInfo(options?.riskProfileName?.additionalInfo)}
              fullWidth
              value={modelPortfolioData.riskProfileName?.[getBackendLanguage()] ?? ''}
              onBlur={() => setFocused([...focused, 'riskProfileName'])}
              error={!modelPortfolioData.riskProfileName?.[getBackendLanguage()] && focused.includes('riskProfileName')}
            />
          </Grid>
        )}

        {options?.themeId?.enabled && (
          <Grid item xs={12} md={6}>
            <SelectField
              testId="model-family"
              onChange={(e: any) => update({ themeId: e.target.value })}
              disabled={loading}
              label={translateBackend(options?.themeId?.label)}
              infoTooltip={additionalInfo(options?.themeId?.additionalInfo)}
              fullWidth
              value={modelPortfolioData.themeId}
              onBlur={() => setFocused([...focused, 'themeId'])}
              error={!modelPortfolioData.themeId && focused.includes('themeId')}
            >
              {themes.map((theme) => (
                <MenuItem
                  key={theme.id}
                  value={theme.id}
                  data-testid={`theme-${kebabCase(theme.translatedName?.[getBackendLanguage()] || '')}`}
                >
                  {theme.translatedName?.[getBackendLanguage()]}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
        )}

        {options?.riskReturnLevel?.enabled && (
          <Grid item xs={12} md={6}>
            <TextField
              testId="risk-return-level"
              type="number"
              onChange={(e: any) => update({ riskReturnLevel: parseFloat(e.target.value) })}
              disabled={loading}
              fullWidth
              label={translateBackend(options?.riskReturnLevel?.label)}
              infoTooltip={additionalInfo(options?.riskReturnLevel?.additionalInfo)}
              value={modelPortfolioData.riskReturnLevel}
            />
          </Grid>
        )}

        {options?.suggestable?.enabled && (
          <Grid item xs={12}>
            <Switch
              testId="suggestable"
              onChange={(e: any) => update({ suggestable: e || options?.suggestable?.defaultValue })}
              disabled={loading}
              label={translateBackend(options?.suggestable?.label)}
              checked={modelPortfolioData.suggestable || options?.suggestable?.defaultValue}
            />
          </Grid>
        )}

        {options?.lowThresholdScore?.enabled && (
          <Grid item xs={12} md={4}>
            <TextField
              testId="low-threshold-score"
              type="number"
              onChange={(e: any) => update({ lowThresholdScore: parseFloat(e.target.value) })}
              disabled={loading}
              label={translateBackend(options?.lowThresholdScore?.label)}
              infoTooltip={additionalInfo(options?.lowThresholdScore?.additionalInfo)}
              fullWidth
              value={modelPortfolioData.lowThresholdScore}
              onBlur={() => setFocused([...focused, 'lowThresholdScore'])}
              error={!modelPortfolioData.lowThresholdScore && focused.includes('lowThresholdScore')}
            />
          </Grid>
        )}

        {options?.suggestedMinScore?.enabled && (
          <Grid item xs={12} md={4}>
            <TextField
              testId="suggested-min-score"
              type="number"
              onChange={(e: any) => update({ suggestedMinScore: parseFloat(e.target.value) })}
              disabled={loading}
              label={translateBackend(options?.suggestedMinScore?.label)}
              infoTooltip={additionalInfo(options?.suggestedMinScore?.additionalInfo)}
              fullWidth
              value={modelPortfolioData.suggestedMinScore}
              onBlur={() => setFocused([...focused, 'suggestedMinScore'])}
              error={!modelPortfolioData.suggestedMinScore && focused.includes('suggestedMinScore')}
            />
          </Grid>
        )}

        {options?.suggestedMaxScore?.enabled && (
          <Grid item xs={12} md={4}>
            <TextField
              testId="suggested-max-score"
              type="number"
              onChange={(e: any) => update({ suggestedMaxScore: parseFloat(e.target.value) })}
              disabled={loading}
              label={translateBackend(options?.suggestedMaxScore?.label)}
              infoTooltip={additionalInfo(options?.suggestedMaxScore?.additionalInfo)}
              fullWidth
              value={modelPortfolioData.suggestedMaxScore}
              onBlur={() => setFocused([...focused, 'suggestedMaxScore'])}
              error={!modelPortfolioData.suggestedMaxScore && focused.includes('suggestedMaxScore')}
            />
          </Grid>
        )}

        {options?.forecastedRateOfReturn?.enabled && (
          <Grid item xs={12} md={6}>
            <TextField
              testId="forecasted-rate-of-return"
              type="number"
              onChange={(e: any) => update({ forecastedRateOfReturn: parseFloat(e.target.value) })}
              disabled={loading}
              label={translateBackend(options?.forecastedRateOfReturn?.label)}
              infoTooltip={additionalInfo(options?.forecastedRateOfReturn?.additionalInfo)}
              fullWidth
              value={modelPortfolioData.forecastedRateOfReturn}
            />
          </Grid>
        )}

        {options?.forecastedStandardDeviation?.enabled && (
          <Grid item xs={12} md={6}>
            <TextField
              testId="forecasted-standard-deviation"
              type="number"
              onChange={(e: any) => update({ forecastedStandardDeviation: parseFloat(e.target.value) })}
              disabled={loading}
              label={translateBackend(options?.forecastedStandardDeviation?.label)}
              infoTooltip={additionalInfo(options?.forecastedStandardDeviation?.additionalInfo)}
              fullWidth
              value={modelPortfolioData.forecastedStandardDeviation}
            />
          </Grid>
        )}

        {options?.url?.enabled && (
          <Grid item xs={12}>
            <TextField
              testId="url"
              onChange={(e: any) => update({ url: e.target.value })}
              disabled={loading}
              label={translateBackend(options?.url?.label)}
              infoTooltip={additionalInfo(options?.url?.additionalInfo)}
              fullWidth
              value={modelPortfolioData.url ?? ''}
            />
          </Grid>
        )}

      </Grid>

      <Box display="flex" justifyContent="end">
        <Button
          dataTestId="add-model-portfolio-details-continue-button"
          label={t('shared:continue')}
          disabled={loading}
          sx={{ mt: 3, textAlign: 'center' }}
          type="submit"
        />
      </Box>
    </Form>
  );
};

export default AddModelPortfolioDetailsVisual;

import { gql, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
import { Badge, Tooltip } from '../../../../2-component';
import { FormModal } from '../../../../3-pattern';
import { FeeGridScopeTypes, FeeTier } from '../../../../../interfaces';
import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import FeeGridForm from './feeGridForm';
import { FETCH_FEE_GRIDS } from '../feeGrids';
import { FETCH_ORGANIZATION_DEFAULT_FEE_TIER } from './defaultOrgFeeGridBox';

export const UPDATE_FEE_GRID = gql`
  mutation updateFeeTier($input: UpdateFeeTierInput!) {
    updateFeeTier(input: $input) {
      feeTier {
        id
      }
    }
  }
`;

const EditFeeGrid = ({
  feeTierToUpdate, open, handleClose, canWrite, isInheirited, organizationId,
}: {
  feeTierToUpdate: FeeTier,
  open: boolean,
  handleClose: () => void,
  canWrite: boolean,
  isInheirited: boolean,
  organizationId?: string,
}) => {
  const { t } = useTranslation(['feeAndBilling', 'shared']);
  const { showToast } = useGlobalToast();
  const [feeTier, setFeeTier] = useState(feeTierToUpdate);
  const [type, setType] = useState<'FLAT' | 'TIERED'>(!isNil(feeTierToUpdate.flatAnnualFeeBps) ? 'FLAT' : 'TIERED');

  useEffect(() => {
    setFeeTier({ ...feeTierToUpdate, billableAUM: feeTierToUpdate.gridScopeType === FeeGridScopeTypes.CLIENT_GROUP });
    setType(!isNil(feeTierToUpdate.flatAnnualFeeBps) ? 'FLAT' : 'TIERED');
  }, [feeTierToUpdate]);

  const [updateFeeTier, { loading }] = useMutation(UPDATE_FEE_GRID, {
    refetchQueries: [FETCH_FEE_GRIDS, FETCH_ORGANIZATION_DEFAULT_FEE_TIER],
  });

  const update = async () => {
    const inputProps = {
      name: feeTier.name,
      chargeForCash: feeTier.chargeForCash ?? true,
      chargeForCashOnHold: feeTier.chargeForCashOnHold ?? true,
      default: feeTier.default ?? false,
      ...(
        type === 'FLAT' ? {
          flatAnnualFeeBps: feeTier.flatAnnualFeeBps,
          blendedGrid: null,
          grid: null,
        } : {
          flatAnnualFeeBps: null,
          blendedGrid: feeTier.blendedGrid ?? true,
          grid: feeTier.grid?.map((g) => ({
            minMarketValueCents: g.minMarketValueCents,
            annualFeeBps: g.annualFeeBps,
            annualFeeCents: g.annualFeeCents,
          })),
        }
      ),
      gridScopeType: feeTier.billableAUM ? FeeGridScopeTypes.CLIENT_GROUP : FeeGridScopeTypes.USER,
    };
    const response = await updateFeeTier({
      variables: {
        input: {
          feeTierId: feeTierToUpdate?.id,
          ...inputProps,
        },
      },
    });
    if (response?.data) {
      showToast({ severity: 'success', message: t('feeAndBilling:feeGrid.edit.updatedToastMessage') });
      handleClose();
    }
  };

  const disabled = !(
    canWrite && !isInheirited
    && feeTier.name && (
      (type === 'FLAT' && !isNil(feeTier.flatAnnualFeeBps))
      || (type === 'TIERED' && feeTier.grid && feeTier.grid.length > 1 && !feeTier.grid?.find((g) => isNil(g.annualFeeBps) && isNil(g.annualFeeCents)))
    )
  );
  return (
    <FormModal
      loading={loading}
      disabled={disabled}
      title={t('feeAndBilling:feeGrid.edit.title')}
      formButton={t('shared:update')}
      onSubmit={update}
      open={open}
      handleClose={handleClose}
      maxWidth='md'
    >
      {isInheirited && canWrite && (
        <Tooltip title={t('shared:inheritedTooltip', { model: 'Fee Grid', organization: feeTier.organization?.name })}>
          <Badge label={t('shared:inheritedFlag')} sx={{ mb: 2 }} />
        </Tooltip>
      )}
      <FeeGridForm feeTier={feeTier} setFeeTier={setFeeTier} type={type} setType={setType} disabled={!canWrite || isInheirited} organizationId={organizationId} />
    </FormModal>
  );
};

export default EditFeeGrid;

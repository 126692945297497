import { useTranslation } from 'react-i18next';
import { SettingsTitle } from '../../../3-pattern';
import { ManageNewsAndInsight } from '../../manageNewsAndInsights/manageNewsAndInsights';

export const NewsAndInsights = () => {
  const { t } = useTranslation(['settings']);
  return (
    <>
      <SettingsTitle title={t('newsAndInsights.title')} description={t('newsAndInsights.description')} />
      <ManageNewsAndInsight />
    </>
  );
};

import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Skeleton } from '../../../1-primative';
import {
  Table, TableHeadCell, TableRow, TableCell, TableBody, Pagination,
  CardContent,
  Card,
} from '../../../2-component';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import { usePageState } from '../../../../util/usePageState';
import NewPageConfiguration from './components/newPageConfiguration';
import { UpdateOrganizationSwitch } from '../../../4-module/updateOrganizationSwitch/updateOrganizationSwitch';
import { SettingsTitle } from '../../../3-pattern';

const FETCH_PAGE_CONFIGURATIONS = gql`
  query fetchPageConfigurations($input: FetchPageConfigurationsInput!) {
    fetchPageConfigurations(input: $input) {
      totalCount
      pageConfigurations {
        id
        name
        type
        createdAt
        organization {
          name
        }
      }
    }
  }
`;

export const PageConfigurationsSettings = () => {
  const navigate = useNavigate();
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 50;
  const { t } = useTranslation(['orgSettings', 'shared', 'settings']);

  const {
    loading, error, data, refetch, previousData,
  } = useQuery(FETCH_PAGE_CONFIGURATIONS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
        pagination: {
          sortField: 'type', sortDesc: false, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  if (error) (<Typography>Error</Typography>);

  return (
    <>
      <SettingsTitle title={t('settings:pageConfigurations.title')} description={t('settings:pageConfigurations.description')} />
      <Card loading={loading}>
        { permissions.includes('write:page_configurations') && (
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
                {permissions.includes('write:advanced_organization_settings') && (
                  <UpdateOrganizationSwitch
                    field='useParentPageConfigurations'
                    label={t('settings:pageConfigurations.useParentPageConfigurations')}
                    organizationId={activeOrganization.id}
                    refetchQueries={[FETCH_PAGE_CONFIGURATIONS]}
                  />
                )}
              </Box>
              <NewPageConfiguration afterCreate={refetch} defaultOrg={activeOrganization.id} />
            </Box>
          </CardContent>
        )}
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableHeadCell>{t('pageConfigurationTable.name')}</TableHeadCell>
              <TableHeadCell>{t('pageConfigurationTable.type')}</TableHeadCell>
              <TableHeadCell align="right">{t('pageConfigurationTable.organization')}</TableHeadCell>
            </TableRow>
            { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
              <TableRow key={i}>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
              </TableRow>
            ))}
            {data?.fetchPageConfigurations?.pageConfigurations?.map((pageConfiguration: any) => (
              <TableRow
                key={pageConfiguration.id}
                hover
                pointer
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                data-testid={`pageconfiguration-${pageConfiguration.id}`}
                onClick={() => {
                  navigate(`${pageConfiguration.id}`);
                }}
              >
                <TableCell component="th" scope="row">
                  {pageConfiguration.name}
                </TableCell>
                <TableCell>{pageConfiguration.type}</TableCell>
                <TableCell align="right">{pageConfiguration.organization?.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={Math.ceil(((data || previousData)?.fetchPageConfigurations?.totalCount ?? 0) / pageSize)}
          page={page}
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />
      </Card>
    </>
  );
};

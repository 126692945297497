import {
  useEffect, useState,
} from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { kebabCase } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { translateBackend } from 'assets/i18n/config';
import CustomFieldRenderer from 'ovComponents/3-pattern/customField/customFieldRenderer';
import { additionalInfo } from 'ovComponents/4-module/configurableOptionFields';
import { invalidFields } from '../../workflowCompletion/subSteps/utils';
import {
  Button, TextField, Typography, Form, Box, Grid,
  SelectField,
  MenuItem,
} from '../../..';
import { Goal, GoalRiskLevels, GoalTimeHorizons } from '../../../../interfaces';
import { GoalTypes } from '../../../resources';
import InfoDialog from '../../../2-component/infoDialog/infoDialog';

const GoalInformationVisual = ({
  options, goalData, loading, updateGoal, continueFunc, grid = false, updateMode = false, activeCustomFields,
  ignoreCustomFieldTriggerRules,
}: {
  options: any, goalData: Partial<Goal>, loading: boolean, updateGoal: (value: Goal) => void, continueFunc: () => void, grid?: boolean, updateMode?: boolean,
  activeCustomFields?: string[], ignoreCustomFieldTriggerRules?: boolean,
}) => {
  const { t } = useTranslation('client');
  const [focused, setFocused] = useState<string[]>([]);
  const [invalidFieldsList, setInvalidFieldsList] = useState<string[]>([]);
  const [updated, setUpdated] = useState<boolean>(false);
  const [customOptionSelected, setCustomOptionSelected] = useState<any[]>([]);

  const submit = () => {
    const fields = invalidFields({
      ...options,
      targetAmountCents: { ...options.targetAmount },
    }, goalData);

    setFocused(fields);
    setInvalidFieldsList(fields);
    if (fields.length === 0) continueFunc();
  };

  const update = (newValue: any) => {
    setUpdated(true);
    updateGoal(newValue);
  };

  useEffect(() => {
    const customOptions: any[] = [];
    Object.keys(options).forEach((key: string) => {
      if (options[key]?.customField && options[key].enabled && activeCustomFields?.includes(key)) {
        customOptions.push(options[key]);
      }
    });
    setCustomOptionSelected(customOptions);
  }, [options, activeCustomFields]);

  const error = (key: string): boolean => (
    ((!goalData?.[key] && focused.includes(key)) || invalidFieldsList.includes(key)) && options?.[key === 'targetAmountCents' ? 'targetAmount' : key]?.required !== 'NOT_REQUIRED'
  );

  return (
    <Form onSubmit={submit}>
      {options?.title && (
        <Typography variant='displayLarge' sx={{ mt: 1 }}>
          {translateBackend(options?.title)}
          {additionalInfo(options?.title?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.title?.additionalInfo) ?? ''} />)}
        </Typography>
      )}
      {options?.subtitle && (
        <Typography display='inline-flex' alignItems='end' variant='bodyLarge' component={'div'} sx={{ mb: 3, table: { width: '100%' } }}>
          <Box display='inline-block'>
            <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.subtitle)}</ReactMarkdown>
          </Box>
          {additionalInfo(options?.subtitle?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.subtitle?.additionalInfo) ?? ''} />)}
        </Typography>
      )}
      <Grid container spacing={2}>
        {options?.type?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <SelectField
              testId="goal-type"
              onChange={(e: any) => update({ ...goalData, type: e.target.value })}
              disabled={loading || options?.type?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.type?.label)}
              infoTooltip={additionalInfo(options?.type?.additionalInfo)}
              fullWidth
              locked={options?.type?.required === 'NOT_EDITABLE'}
              lockMessage={t('pageConfiguration:notEditableMessage')}
              value={goalData.type ?? ''}
              onBlur={() => setFocused([...focused, 'type'])}
              error={error('type')}
            >
              {Object.values(GoalTypes).map((goalType: string) => (
                <MenuItem data-testid={`goal-type-${kebabCase(goalType)}`} value={goalType}>{t(`goalType.${goalType}`)}</MenuItem>
              ))}
            </SelectField>
          </Grid>
        )}
        {options?.name?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              testId='goal-name'
              onChange={(e: any) => update({ ...goalData, name: e.target.value })}
              disabled={loading || options?.name?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.name?.label)}
              fullWidth
              value={goalData.name ?? ''}
              onBlur={() => setFocused([...focused, 'name'])}
              error={error('name')}
              locked={options?.name?.required === 'NOT_EDITABLE'}
              lockMessage={t('pageConfiguration:notEditableMessage')}
              infoTooltip={additionalInfo(options?.name?.additionalInfo)}
            />
          </Grid>
        )}
        {options?.timeHorizon?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <SelectField
              testId="goal-time-horizon"
              onChange={(e: any) => update({ ...goalData, timeHorizon: e.target.value })}
              disabled={loading || options?.timeHorizon?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.timeHorizon?.label)}
              infoTooltip={additionalInfo(options?.timeHorizon?.additionalInfo)}
              fullWidth
              locked={options?.timeHorizon?.required === 'NOT_EDITABLE'}
              lockMessage={t('pageConfiguration:notEditableMessage')}
              value={goalData.timeHorizon ?? ''}
              onBlur={() => setFocused([...focused, 'timeHorizon'])}
              error={error('timeHorizon')}
            >
              {Object.values(GoalTimeHorizons).map((goalTimeHorizon: string) => (
                <MenuItem data-testid={`goal-time-horizon-${kebabCase(goalTimeHorizon)}`} value={goalTimeHorizon}>{t(`goalTimeHorizon.${goalTimeHorizon}`)}</MenuItem>
              ))}
            </SelectField>
          </Grid>
        )}
        {options?.targetAmount?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              testId='goal-target-amount'
              type='dollar'
              leadingIcon='dollar'
              onChange={(e: any) => update({ ...goalData, targetAmountCents: e.target.value * 100 })}
              disabled={loading || options?.targetAmount?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.targetAmount?.label)}
              fullWidth
              value={(goalData.targetAmountCents ?? 0) / 100}
              onBlur={() => setFocused([...focused, 'targetAmountCents'])}
              error={error('targetAmountCents')}
              locked={options?.targetAmount?.required === 'NOT_EDITABLE'}
              lockMessage={t('pageConfiguration:notEditableMessage')}
              infoTooltip={additionalInfo(options?.targetAmount?.additionalInfo)}
            />
          </Grid>
        )}
        {options?.riskQuestion1?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <SelectField
              testId="goal-risk-question-1"
              onChange={(e: any) => update({ ...goalData, riskQuestion1: e.target.value })}
              disabled={loading || options?.riskQuestion1?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.riskQuestion1?.label)}
              infoTooltip={additionalInfo(options?.riskQuestion1?.additionalInfo)}
              fullWidth
              locked={options?.riskQuestion1?.required === 'NOT_EDITABLE'}
              lockMessage={t('pageConfiguration:notEditableMessage')}
              value={goalData.riskQuestion1 ?? ''}
              onBlur={() => setFocused([...focused, 'riskQuestion1'])}
              error={error('riskQuestion1')}
            >
              {Object.values(GoalRiskLevels).map((rq1: string) => (
                <MenuItem data-testid={`goal-risk-question-1-${kebabCase(rq1)}`} value={rq1}>{t(`edit.riskQuestion1Options.${rq1}`)}</MenuItem>
              ))}
            </SelectField>
          </Grid>
        )}
        <Grid item xs={12}>
          <CustomFieldRenderer
            customOptions={customOptionSelected}
            customData={goalData}
            update={update}
            grid={grid}
            focused={focused}
            setFocused={setFocused}
            loading={loading}
            ignoreCustomFieldTriggerRules={ignoreCustomFieldTriggerRules}
          />
        </Grid>
      </Grid>
      <Box display='flex' justifyContent='end'>
        <Button
          dataTestId="goal-information-confirm-button"
          label={t(updateMode ? 'update' : 'continue')}
          disabled={loading || (!updated && updateMode)}
          sx={{ mt: 3, textAlign: 'center' }}
          type='submit' />
      </Box>
    </Form>
  );
};

export default GoalInformationVisual;

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  ListItem, Radio, RadioGroup, Switch, TextField,
} from '@mui/material';
import { HouseholdMemberSelect } from 'components/inputs/householdMemberSelect';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { usePermissions } from 'providers/userContextProvider';
import FormModal from '../../../components/modals/formModal';
import DroppableFileInput from './droppableFileInput';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import {
  FETCH_ALL_USER_AGREEMENTS, FETCH_ACCOUNT_AGREEMENTS, FETCH_DOCUMENTS, FETCH_CLIENT_GROUP_AGREEMENTS,
} from './documents';
import { SubAccountSelect } from '../../../components/inputs/subAccountSelect';
import AccountSelect from '../../../components/inputs/accountSelect';
import { FileDocumentObjectTypes } from '../../../interfaces/fileDocument';

export const FETCH_FILE_UPLOAD_URL = gql`
  query fetchFileUploadUrl ($input:FetchFileUploadUrlInput!) {
    fetchFileUploadUrl(input:$input) {
      temporarySignedURL
      s3Path
    }
  }
`;

export const CREATE_FILE_DOCUMENT = gql`
  mutation createFileDocument ($input:CreateFileDocumentInput!) {
    createFileDocument(input:$input) {
      fileDocument {
        id name fileName s3Key type
      }
    }
  }
`;

enum FileDocumentTypes {
  FORM = 'FORM',
  INVOICE = 'INVOICE',
  NOTES = 'NOTES',
  OTHER = 'OTHER',
  STATEMENT = 'STATEMENT',
  LOGO = 'LOGO',
}

const DocumentUpload = ({
  forUserId, handleClose, fixedObjectType, fixedObjectId, docType, clientGroupId,
}: {
  forUserId: string,
  handleClose: () => void,
  fixedObjectType?: string,
  fixedObjectId?: string
  docType?: string,
  clientGroupId?: string,
}) => {
  const [file, setFile] = useState<File>();
  const [name, setName] = useState('');
  const [nameIsAutomatic, setNameIsAutomatic] = useState(false);
  const type = (docType ? FileDocumentTypes.FORM : FileDocumentTypes.OTHER);
  const [associatedToObjectType, setAssociatedToObjectType] = useState<string>(fixedObjectType ?? FileDocumentObjectTypes.USER);
  const [associatedToAccountId, setAssociatedToAccountId] = useState<string>(fixedObjectType === FileDocumentObjectTypes.ACCOUNT ? (fixedObjectId ?? '') : '');
  const [associatedToSubAccountId, setAssociatedToSubAccountId] = useState<string>(fixedObjectType === FileDocumentObjectTypes.SUBACCOUNT ? (fixedObjectId ?? '') : '');
  const [associatedToClientId, setAssociatedToClientId] = useState<string>(forUserId);
  const [sharedClient, setSharedClient] = useState(false);
  const [sharedCustodian, setSharedCustodian] = useState(false);

  const { t } = useTranslation('client');
  const { permissions } = usePermissions();

  const { showToast } = useGlobalToast();

  const [fetchFileUploadUrl, { loading: loadingUploadUrl }] = useLazyQuery(FETCH_FILE_UPLOAD_URL, { fetchPolicy: 'no-cache' });
  const [uploadPending, setUploadPending] = useState(false);
  const [createFileDocument, { loading: createPending }] = useMutation(CREATE_FILE_DOCUMENT);

  const associatedToObjectID = () => (fixedObjectId || (associatedToObjectType === FileDocumentObjectTypes.SUBACCOUNT
    ? associatedToSubAccountId
    : associatedToObjectType === FileDocumentObjectTypes.ACCOUNT
      ? associatedToAccountId
      : clientGroupId ? associatedToClientId : forUserId));

  const doTheUpload = async () => {
    if (!file) return;

    const createFileInput:any = {
      objectType: associatedToObjectType,
      objectId: (docType as any) === 'PAD_AGREEMENT' ? fixedObjectId : associatedToObjectID(),
      fileName: file.name,
      type,
    };

    /* (1) fetch the S3 upload URL from backend */
    const queryResult = await fetchFileUploadUrl({ variables: { input: { ...createFileInput, userId: forUserId, generateUniqueFileName: true } } });
    const uploadUrl = queryResult?.data?.fetchFileUploadUrl.temporarySignedURL;
    const s3Path = queryResult?.data?.fetchFileUploadUrl.s3Path;
    if (!uploadUrl || queryResult?.error) {
      showToast({ severity: 'error', message: `Upload failed, step 1 of 3 (FETCH_FILE_UPLOAD_URL) ${queryResult?.error}` });
      return;
    }

    /* (2) do the upload */
    try {
      setUploadPending(true);
      const uploaded:Response = await fetch(new Request(uploadUrl, { method: 'PUT', body: file }));
      if (!uploaded.ok) throw (new Error(`${uploaded.status} ${uploaded.statusText}`));
    } catch (e:any) {
      showToast({ severity: 'error', message: `Upload failed, step 2 of 3 (HTTP PUT) ${e}` });
      return;
    } finally {
      setUploadPending(false);
    }

    /* (3) create the fileDocument within backend */
    createFileInput.name = name;
    createFileInput.mediaType = file.type;
    createFileInput.sharedClient = sharedClient;
    createFileInput.sharedCustodian = sharedCustodian;
    createFileInput.permissionType = 'PUBLIC';
    createFileInput.sourceType = docType;
    createFileInput.s3Path = s3Path;

    try {
      if (type === FileDocumentTypes.FORM) {
        await createFileDocument({
          variables: { input: createFileInput },
          refetchQueries: [
            FETCH_ALL_USER_AGREEMENTS(permissions),
            FETCH_ACCOUNT_AGREEMENTS,
            FETCH_CLIENT_GROUP_AGREEMENTS,
            FETCH_DOCUMENTS,
          ],
          onQueryUpdated: (observableQuery) => {
            // Refetch after delay
            setTimeout(() => {
              observableQuery.refetch();
            }, 2000);
            return false;
          },
        });
      } else {
        await createFileDocument({
          variables: { input: createFileInput },
          refetchQueries: [FETCH_DOCUMENTS],
        });
      }
    } catch (error) {
      showToast({ severity: 'error', message: `Upload failed, step 3 of 3 (CREATE_FILE_DOCUMENT) ${error}` });
      return;
    }

    showToast({ severity: 'success', message: `Document uploaded successfully: ${file.name}` });
    handleClose();
  };
  useEffect(() => {
    if (!file?.name) return;
    if (!name || nameIsAutomatic) { setName(file.name); setNameIsAutomatic(true); }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <FormModal
      onSubmit={async (event: any) => {
        event.preventDefault();
        await doTheUpload();
      } }
      loading={false}
      title={'Document Upload'}
      open={true}
      handleClose={handleClose}
      formButton={'upload document'}
      disabled={ (!file || !name) || (loadingUploadUrl || uploadPending || createPending) }
    >
      <ListItem>
        <DroppableFileInput
          onFileChosen={(dropFile: File) => {
            setFile(dropFile);
          }}
        />
      </ListItem>
      <ListItem>
        <TextField fullWidth
          label={t('documentUpload.documentName')}
          value={name}
          onChange={(e) => { setName(e.target.value.trim()); setNameIsAutomatic(false); }}
        />
      </ListItem>
      {/* selecting associated to will be enable only if user came from clicking
      any pending form Icon and upload document */}
     { !fixedObjectType && (<><ListItem >
        <FormControl>
          <FormLabel id="radio-buttons-group-label">{t('documentUpload.associatedTo')}</FormLabel>
          <RadioGroup row
            aria-labelledby="radio-buttons-group-label"
            name="radio-buttons-group"
            value={associatedToObjectType}
            onChange={(e) => { setAssociatedToObjectType(e.target.value); }}
          >
            <FormControlLabel value="USER" control={<Radio />} label={t('client')} />
            <FormControlLabel value="ACCOUNT" control={<Radio />} label={t('account')}/>
            <FormControlLabel value="SUBACCOUNT" control={<Radio />} label={t('subAccount')}/>
          </RadioGroup>
        </FormControl>
      </ListItem>
      <ListItem>
          {clientGroupId && (associatedToObjectType === FileDocumentObjectTypes.USER)
            && <HouseholdMemberSelect
              label={t('accountHolder')}
              clientGroupId={clientGroupId}
              value={associatedToClientId}
              onChange={(e) => {
                setAssociatedToClientId(e.target.value);
              }}
            />
          }
          { associatedToObjectType === FileDocumentObjectTypes.ACCOUNT
          && <AccountSelect
            forObject='USER'
            forId={forUserId}
            setAccount={(id) => setAssociatedToAccountId(id)}
            selectFirst
          />
        }
        { associatedToObjectType === FileDocumentObjectTypes.SUBACCOUNT
          && <SubAccountSelect
            label=''
            value={associatedToSubAccountId}
            setSubAccount={(e) => setAssociatedToSubAccountId(e.target.value)}
            forObject='USER'
            forId={forUserId}
            selectFirst
          />
        }
      </ListItem> </>)}
      <ListItem>
        <Grid container>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={sharedClient}
                  onChange={(e) => setSharedClient(e.target.checked)}
                />
              }
              label={t('documents.allowClientToView')}
            />
          </Grid>
          <Grid item xs={6}>
          <FormControlLabel
              control={
                <Switch
                  checked={sharedCustodian}
                  onChange={(e) => setSharedCustodian(e.target.checked)}
                />
              }
              label={t('documents.sendToCustodian')}
            />
          </Grid>
        </Grid>
      </ListItem>
    </FormModal>
  );
};

export default DocumentUpload;

import { gql } from '@apollo/client';

export const FETCH_MEMBERS = (useCustodianData: boolean | undefined, currency: string | undefined) => gql`
  query fetchClientGroup($clientGroupId: ObjectID!, ${useCustodianData ? '' : '$currency: StatisticsCurrencyTypes'}) {
    fetchClientGroup(clientGroupId: $clientGroupId) {
      clientGroup {
        id
        relationships {
          type
          user {
            id
            firstName
            lastName
            entityName
            ${useCustodianData ? `custodianStatistics ${currency ? `(input: {currency: ${currency}})` : ''} { marketValueCents currency }` : `statistics(input: {currency: $currency}) {
              marketValueCents
              currency
            }`}
          }
        }
      }
    }
  }
`;

export const FETCH_MEMBER_ACCOUNTS = (useCustodianData: boolean | undefined, currency: string | undefined) => gql`
  query fetchAccounts($input: FetchAccountsInput!, ${useCustodianData ? '' : '$currency: StatisticsCurrencyTypes'}) {
    fetchAccounts(input: $input) {
      accounts {
        id
        ${useCustodianData ? `custodianStatistics ${currency ? `(input: {currency: ${currency}})` : ''} { marketValueCents currency }` : `statistics(input: {currency: $currency}) {
          marketValueCents
          currency
        }`}
      }
    }
  }
`;

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  gql, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOrganizationUser } from './deleteOrganizationUser';
import { entityName } from '../../../../../util';
import {
  mapOrganizationUserLoginStatus, OrganizationUser, OrganizationUserAccessTypes, OrganizationUserEntity, OrganizationUserLoginStatus,
  OrganizationUserRepCodeEntity,
} from '../../../../../interfaces/organizationUser';
import { usePermissions } from '../../../../../providers/userContextProvider';
import ResetOrganizationUserMultifactor from './resetOrganizationUserMultifactor';
import { Languages } from '../../../../../interfaces';
import SendOrganizationUserInvitations from './sendOrganizationUserInvitations';
import RevokeOrganizationUserInvitation from './revokeOrganizationUserInvitation';
import { Typography, Link as MuiLink, Box } from '../../../../1-primative';
import {
  MenuItem, Table, TableBody, TableCell, TableRow, TextField, RadioGroup, Radio, Button, TableHeadCell, DroppableFileInput, FileTypes, SelectField,
} from '../../../../2-component';
import { base64ToFile } from '../../../../4-module/workflowCompletion/subSteps/utils';
import { CREATE_FILE_DOCUMENT, FETCH_FILE_UPLOAD_URL } from '../../../../3-pattern/addBankAccount/addBankAccountManually/addBankAccountManually';
import { DELETE_FILE_DOCUMENT } from '../../../manageNewsAndInsights/components/queries';
import { InlineImage } from '../../../../2-component/inlineImage/inlineImage';
import { ConfirmationModal, FormModal, RoleSelect } from '../../../../3-pattern';
import { RepCodeMultiSelect } from '../../../../3-pattern/repCodeSelect/repCodeMultiSelect';

const UPDATE_ORGANIZATION_USER = gql`
  mutation updateOrganizationUser($input: UpdateOrganizationUserInput!) {
    updateOrganizationUser(input: $input) {
      organizationUser {
        id
      }
    }
  }
`;

const FETCH_ORGANIZATION_USER_ENTITIES = gql`
  query fetchOrganizationUserEntities($organizationUserId: ObjectID!) {
    fetchOrganizationUser(organizationUserId: $organizationUserId) {
      organizationUser {
        id
        avatar
        entities {
          relation
          entity {
            id
            type
            entityName
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const FETCH_ORGANIZATION_USER_REP_CODE_ENTITIES = gql`
query fetchOrganizationUserRepCodeEntities($organizationUserId: ObjectID!) {
  fetchOrganizationUser(organizationUserId: $organizationUserId) {
    organizationUser {
      id
      avatar
      repCodeEntities {
        entity {
          id
          type
          entityName
          firstName
          lastName
        }
        repCodes { code }
      }
    }
  }
}
`;

const EditOrganizationUser = ({
  afterUpdate,
  organizationUserToUpdate,
  open,
  handleClose,
}: {
  afterUpdate: () => void;
  organizationUserToUpdate: OrganizationUser;
  open: boolean;
  handleClose: () => void;
}) => {
  const { t } = useTranslation(['orgSettings', 'shared']);
  const { permissions } = usePermissions();
  const [organizationUser, setOrganizationUser] = useState(organizationUserToUpdate);
  const [localOpen, setLocalOpen] = useState(open);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [resetMfaDialogOpen, setResetMfaDialogOpen] = useState(false);
  const [emailUpdateConfirmationOpen, setEmailUpdateConfirmationOpen] = useState(false);
  const [sendInviteDialogOpen, setSendInviteDialogOpen] = useState(false);
  const [revokeInviteDialogOpen, setRevokeInviteDialogOpen] = useState(false);
  const [startLoading, setStartLoading] = useState(false);
  const [fetchFileUploadUrl] = useLazyQuery(FETCH_FILE_UPLOAD_URL, { fetchPolicy: 'no-cache' });
  const [createFileDocument, { loading: createLoading }] = useMutation(CREATE_FILE_DOCUMENT);
  const [deleteFileDocument, { loading: deleteLoading }] = useMutation(DELETE_FILE_DOCUMENT);
  const languages = Object.values(Languages);

  const canUpdateEmail = permissions.includes('write:organization_user_authentication');
  const canResetMfa = canUpdateEmail && (organizationUserToUpdate.phone || organizationUserToUpdate?.mfaEnrollmentId) && organizationUserToUpdate.organization?.enableMultiFactorAuthentication;

  const canSeeEntities = permissions.includes('read:organization_user_entities');

  const canSeeRepCodes = permissions.includes('read:rep_code');
  const canUpdateRepCodes = permissions.includes('write:rep_code');

  const { data: entitiesData } = useQuery(FETCH_ORGANIZATION_USER_ENTITIES, {
    variables: {
      organizationUserId: organizationUserToUpdate.id,
    },
    skip: !canSeeEntities,
  });
  const { data: entitiesRepCodeData } = useQuery(FETCH_ORGANIZATION_USER_REP_CODE_ENTITIES, {
    variables: {
      organizationUserId: organizationUserToUpdate.id,
    },
    skip: !canSeeRepCodes,
  });

  const loginStatus = mapOrganizationUserLoginStatus(organizationUserToUpdate);
  const canSendInvite = (permissions.includes('write:invite_organization_users') || permissions.includes('write:organization_users'))
    && [OrganizationUserLoginStatus.NOT_INVITED, OrganizationUserLoginStatus.INVITATION_EXPIRED].includes(loginStatus);
  const canRevokeInvite = (permissions.includes('write:invite_organization_users') || permissions.includes('write:organization_users')) && loginStatus === OrganizationUserLoginStatus.INVITED;
  const inviteTextKey = loginStatus === OrganizationUserLoginStatus.INVITATION_EXPIRED ? 'resendInvitation' : 'inviteUser';

  const isEmailUpdated = organizationUserToUpdate.email && organizationUserToUpdate.email.toLowerCase() !== organizationUser.email?.toLowerCase();
  const isPhoneUpdated = organizationUserToUpdate.phone && organizationUserToUpdate.phone !== organizationUser.phone;

  const [updateOrganizationUser, { loading }] = useMutation(UPDATE_ORGANIZATION_USER, {
    variables: {
      input: {
        organizationUserId: organizationUser?.id,
        firstName: organizationUser?.firstName,
        lastName: organizationUser?.lastName,
        language: organizationUser?.language ?? Languages.ENGLISH,
        roleId: organizationUser?.role?.id,
        accessType: organizationUser?.accessType,
        avatar: organizationUser?.avatar,
        ...(canUpdateEmail && organizationUser.phone && (isPhoneUpdated || !organizationUserToUpdate.phone) ? { phone: organizationUser.phone } : {}),
        ...(canUpdateEmail && organizationUser.email && (isEmailUpdated || !organizationUserToUpdate.email) ? { email: organizationUser.email } : {}),
        ...(canUpdateRepCodes && organizationUser.repCodes ? { repCodes: organizationUser.repCodes.map((r) => r.code) } : {}),
      },
    },
  });

  const setAvatar = async (file: File) => {
    setStartLoading(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result as string;
      doUpload(base64String);
    };
  };

  const doUpload = async (newAvatar: string): Promise<any> => {
    const uploadingFile = base64ToFile(newAvatar!, 'avatar');
    const createFileInput: any = {
      objectType: 'ORGANIZATION_USER',
      objectId: organizationUser.id,
      fileName: 'avatar',
      type: 'AVATAR',
    };

    /* (1) fetch the S3 upload URL from backend */
    const queryResult = await fetchFileUploadUrl({ variables: { input: { ...createFileInput, userId: organizationUser.id, generateUniqueFileName: true } } });
    const uploadUrl = queryResult?.data?.fetchFileUploadUrl.temporarySignedURL;
    const s3Path = queryResult?.data?.fetchFileUploadUrl.s3Path;
    if (!uploadUrl || queryResult?.error) {
      return;
    }
    /* (2) do the upload */
    try {
      const uploaded: Response = await fetch(new Request(uploadUrl, { method: 'PUT', body: uploadingFile }));
      if (!uploaded.ok) throw new Error(`${uploaded.status} ${uploaded.statusText}`);
    } catch (e: any) {
      return;
    }

    /* (3) create the fileDocument within backend */
    createFileInput.name = uploadingFile.name;
    createFileInput.mediaType = uploadingFile.type;
    createFileInput.permissionType = 'PUBLIC';
    createFileInput.sourceType = 'ORGANIZATION_USER';
    createFileInput.s3Path = s3Path;

    await createFileDocument({
      variables: { input: createFileInput },
      onCompleted: async (data: any) => {
        setOrganizationUser({ ...organizationUser, avatar: data.createFileDocument.fileDocument.id });
        setStartLoading(false);
      },
    });
  };

  useEffect(() => {
    setLocalOpen(open);
    setOrganizationUser(organizationUserToUpdate);
  }, [organizationUserToUpdate, open]);

  const doUpdate = async () => {
    await updateOrganizationUser();
    afterUpdate();
  };

  const update = async (event: any) => {
    event?.preventDefault();
    if (isEmailUpdated && canUpdateEmail) {
      setEmailUpdateConfirmationOpen(true);
    } else {
      doUpdate();
    }
  };

  const deleteFile = async () => {
    await deleteFileDocument({
      variables: { fileDocumentId: organizationUser.avatar },
      onCompleted(data: any) {
        setOrganizationUser({ ...organizationUser, avatar: undefined });
        updateOrganizationUser({
          variables: {
            input: {
              organizationUserId: organizationUser.id,
              avatar: null,
            },
          },
        } as any);
      },
    });
  };
  const onDeleteMenuClick = () => {
    setDeleteDialogOpen(true);
  };
  const onResetMfaMenuClick = () => {
    setResetMfaDialogOpen(true);
  };
  const onSendInviteMenuClick = () => {
    setSendInviteDialogOpen(true);
  };
  const onRevokeInviteMenuClick = () => {
    setRevokeInviteDialogOpen(true);
  };

  if (!organizationUser) {
    return <></>;
  }
  const menuItems = [
    <MenuItem data-testId='access-users-edit-delete' key='menu1' onClick={onDeleteMenuClick}>
      {t('shared:delete')}
    </MenuItem>,
    ...(canResetMfa
      ? [
          <MenuItem data-testId='access-users-edit-reset-mfa' key='menu2' onClick={onResetMfaMenuClick}>
            {t('userModal.resetMfa')}
          </MenuItem>,
      ]
      : []),
    ...(canSendInvite
      ? [
          <MenuItem data-testId='access-users-invite-users' key='menu3' onClick={onSendInviteMenuClick}>
            {t(`userModal.${inviteTextKey}`)}
          </MenuItem>,
      ]
      : []),
    ...(canRevokeInvite
      ? [
          <MenuItem data-testId='access-users-edit-revoke-invite' key='menu4' onClick={onRevokeInviteMenuClick}>
            {t('userModal.revokeInvitation')}
          </MenuItem>,
      ]
      : []),
  ];
  return (
    <>
      <FormModal
        loading={loading || createLoading || deleteLoading || startLoading}
        title={t('userModal.editTitle')}
        formButton={t('shared:update')}
        onSubmit={update}
        open={localOpen}
        handleClose={handleClose}
        menuItems={menuItems}
        testId='access-users-edit'
      >
        <Box display='flex' flexDirection='column' gap={2}>
          <TextField
            fullWidth
            label={t('userModal.firstName')}
            value={organizationUser.firstName ?? ''}
            onChange={(e: any) => setOrganizationUser({ ...organizationUser, firstName: e.target.value })}
            testId='access-users-edit-firstName'
          />
          <TextField
            fullWidth
            label={t('userModal.lastName')}
            value={organizationUser.lastName ?? ''}
            onChange={(e: any) => setOrganizationUser({ ...organizationUser, lastName: e.target.value })}
            testId='access-users-edit-lastName'
          />
          <TextField
            fullWidth
            label={t('userModal.email')}
            required
            value={organizationUser.email ?? ''}
            onChange={(e: any) => setOrganizationUser({ ...organizationUser, email: e.target.value })}
            disabled={!canUpdateEmail}
            testId='access-users-edit-email'
          />
          <TextField
            fullWidth
            label={t(`userModal.${organizationUser?.mfaEnrollmentId ? 'phoneUsedForLogin' : 'phone'}`)}
            sx={{ width: '100%' }}
            value={organizationUser.phone}
            onChange={(e: any) => setOrganizationUser({ ...organizationUser, phone: e.target.value })}
            disabled={!!organizationUserToUpdate?.mfaEnrollmentId}
            testId='access-users-edit-phone'
          />
          <RadioGroup
            label={t('userModal.language')}
            value={organizationUser.language ?? Languages.ENGLISH}
            onChange={(e: any) => setOrganizationUser({ ...organizationUser, language: e.target.value as Languages })}
            testId='access-users-edit-language'
          >
            {languages.map((lang) => (
              <Radio testId={`access-users-edit-language-${lang.toLowerCase()}`} value={lang} label={t(`userModal.languageList.${lang}`)} />
            ))}
          </RadioGroup>
          {organizationUser.role?.id && organizationUser.organization?.id && (
            <RoleSelect
              value={organizationUser.role.id}
              label={t('userModal.role')}
              onChange={(event: any) => setOrganizationUser({ ...organizationUser, role: { id: event.target.value as string } })}
              testId='access-users-edit-role'
            />
          )}
          <SelectField
            value={organizationUser.accessType}
            label={t('userModal.accessType')}
            fullWidth
            onChange={(event: any) => setOrganizationUser({ ...organizationUser, accessType: event.target.value })}
            testId='access-users-access-type'
          >
            <MenuItem data-testId='access-users-edit-access-type-organization' key='1' value={OrganizationUserAccessTypes.ORGANIZATION}>
              {t(OrganizationUserAccessTypes.ORGANIZATION)}
            </MenuItem>
            <MenuItem data-testId='access-users-edit-access-type-entity' key='2' value={OrganizationUserAccessTypes.ENTITY}>
              {t(OrganizationUserAccessTypes.ENTITY)}
            </MenuItem>
          </SelectField>
          <Box display='flex' flexDirection='column' width='100%'>
            <Typography variant='labelSmall' colorVariant='variant' mb={2}>
              {t('userModal.avatar')}
            </Typography>
            {!organizationUser.avatar && (
              <DroppableFileInput
                testId='access-users-avatar'
                onFileChosen={(file: File) => setAvatar(file)}
                acceptedTypes={[FileTypes.JPEG, FileTypes.JPG, FileTypes.PNG, FileTypes.HEIC]}
                fileMaxSize={3}
              />
            )}
            {organizationUser.avatar && (
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <InlineImage
                  fileDocumentId={organizationUser.avatar}
                  style={{
                    height: '100px',
                    width: '100px',
                    borderRadius: '100px',
                    marginBottom: '8px',
                    objectFit: 'cover',
                  }}
                />
                <Button onClick={() => deleteFile()} variant='text' label={t('shared:delete')} dataTestId='access-users-avatar-delete-button'/>
              </Box>
            )}
          </Box>
          {organizationUser.accessType === OrganizationUserAccessTypes.ENTITY && canSeeEntities && (
            <Table aria-label='simple table' sx={{ mb: 2, padding: 10 }}>
              <TableBody>
                <TableRow>
                  <TableHeadCell>{t('userEntityTable.name')}</TableHeadCell>
                  <TableHeadCell>{t('userEntityTable.relation')}</TableHeadCell>
                  <TableHeadCell>{t('userEntityTable.entityType')}</TableHeadCell>
                </TableRow>
                {entitiesData?.fetchOrganizationUser?.organizationUser?.entities?.map((e: OrganizationUserEntity) => (
                  <TableRow hover key={e.entity?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}>
                    <TableCell component='th' scope='row'>
                      <MuiLink component={Link} to={`/clients/${e.entity?.id}`}>
                        {entityName(e.entity)}
                      </MuiLink>
                    </TableCell>
                    <TableCell>{t(`components:accessToEntity.relations.${e.relation}`)}</TableCell>
                    <TableCell>{t(`entityTypes:${e.entity?.type}`)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          {canSeeRepCodes && (
            <RepCodeMultiSelect
              setRepCodes={(repCodes: string[]) => {
                setOrganizationUser({ ...organizationUser, repCodes: repCodes.map((code) => ({ code })) });
              }}
              selectedRepCodes={organizationUser.repCodes?.map((r) => r.code!) || []}
              label={t('userModal.repCodes')}
              disabled={!canUpdateRepCodes}
            />
          )}
          {canSeeRepCodes && organizationUser.repCodes && organizationUser.repCodes.length > 0 && (
            <Table aria-label='simple table' sx={{ mb: 2, padding: 10 }}>
              <TableBody>
                <TableRow>
                  <TableHeadCell>{t('userRepCodeEntityTable.name')}</TableHeadCell>
                  <TableHeadCell>{t('userRepCodeEntityTable.repCodes')}</TableHeadCell>
                </TableRow>
                {entitiesRepCodeData?.fetchOrganizationUser?.organizationUser?.repCodeEntities?.map((e: OrganizationUserRepCodeEntity) => (
                  <TableRow hover key={e.entity?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}>
                    <TableCell component='th' scope='row'>
                      <MuiLink component={Link} to={`/clients/${e.entity?.id}`}>
                        {entityName(e.entity)}
                      </MuiLink>
                    </TableCell>
                    <TableCell>
                        {e.repCodes?.map((r) => r.code).join(', ')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Box>
      </FormModal>
      <DeleteOrganizationUser
        open={deleteDialogOpen}
        organizationUserToDelete={organizationUser}
        afterDelete={() => {
          setDeleteDialogOpen(false);
          afterUpdate();
        }}
        handleClose={() => setDeleteDialogOpen(false)}
      />
      {canResetMfa && (
        <ResetOrganizationUserMultifactor
          open={resetMfaDialogOpen}
          organizationUserToReset={organizationUser}
          afterReset={() => {
            setResetMfaDialogOpen(false);
            afterUpdate();
          }}
          handleClose={() => setResetMfaDialogOpen(false)}
        />
      )}
      {canUpdateEmail && isEmailUpdated && (
        <ConfirmationModal
          title={t('emailUpdateConfirmation.title')}
          bodyText={t('emailUpdateConfirmation.body', { email: organizationUser.email })}
          open={emailUpdateConfirmationOpen}
          maxWidth='sm'
          onConfirm={async () => {
            setEmailUpdateConfirmationOpen(false);
            doUpdate();
          }}
          onCancel={() => {
            setEmailUpdateConfirmationOpen(false);
          }}
        />
      )}
      {canSendInvite && sendInviteDialogOpen && (
        <SendOrganizationUserInvitations
          open={sendInviteDialogOpen}
          organizationUser={organizationUser}
          onComplete={() => {
            setSendInviteDialogOpen(false);
            afterUpdate();
          }}
          handleClose={() => setSendInviteDialogOpen(false)}
        />
      )}
      {canRevokeInvite && revokeInviteDialogOpen && (
        <RevokeOrganizationUserInvitation
          open={revokeInviteDialogOpen}
          organizationUser={organizationUser}
          onComplete={() => {
            setRevokeInviteDialogOpen(false);
            afterUpdate();
          }}
          handleClose={() => setRevokeInviteDialogOpen(false)}
        />
      )}
    </>
  );
};

export default EditOrganizationUser;

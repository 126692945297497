import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Box } from '../../../1-primative';
import {
  Button, Form, SettingsSwitch,
} from '../../../2-component';
import { NumberField, SettingsCard } from '../../../3-pattern';
import { UserContext } from '../../../../providers/userContextProvider';
import { useGlobalToast } from '../../../../providers/globalToastProvider';
import { FeatureFlag, useFeatureFlags } from '../../../4-module/featureFlag/featureFlag';

const FETCH_ORGANIZATION = gql`
  query fetchOrganization($organizationId: String!) {
    fetchOrganization(organizationId: $organizationId) {
      organization {
        id
        name
        allowInactiveUserRebalancing
        allowInactiveUserFeeCalculation
        allowInactiveUserAdjustments
        allowInstantInvest
        requireManualAccountApproval
        requireSubTradeRequestApproval
        profileReviewTimeInMonths
      }
    }
  }
`;

const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        id
      }
    }
  }
`;

export const ComplianceSettings = () => {
  const { activeOrganization } = useContext(UserContext);
  const { showToast } = useGlobalToast();
  const { t } = useTranslation(['settings', 'shared']);
  const [organization, setOrganization] = useState<any>(null);
  const {
    featureFlags, updateFeatureFlag, saveFeatureFlags,
  } = useFeatureFlags();

  const { data, loading } = useQuery(FETCH_ORGANIZATION, {
    variables: { organizationId: activeOrganization?.id },
  });

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION, {
    variables: {
      input: {
        organizationId: activeOrganization?.id,
        allowInactiveUserRebalancing: organization?.allowInactiveUserRebalancing,
        allowInactiveUserFeeCalculation: organization?.allowInactiveUserFeeCalculation,
        allowInactiveUserAdjustments: organization?.allowInactiveUserAdjustments,
        allowInstantInvest: organization?.allowInstantInvest,
        requireManualAccountApproval: organization?.requireManualAccountApproval,
        requireSubTradeRequestApproval: organization?.requireSubTradeRequestApproval,
        profileReviewTimeInMonths: parseInt(organization?.profileReviewTimeInMonths, 10),
      },
    },
    onCompleted: async () => {
      await saveFeatureFlags();
      showToast({
        message: t('shared:successfullySaved'),
        severity: 'success',
      });
    },
  });

  useEffect(() => {
    if (data?.fetchOrganization?.organization) {
      setOrganization(data?.fetchOrganization?.organization);
    }
  }, [data]);

  return (
    <SettingsCard
      title={t('compliance.title')}
      description={t('compliance.description')}
    >
      <Form onSubmit={updateOrganization}>
        <Box display='flex' flexDirection='column' gap={2}>
          <SettingsSwitch
            label={t('compliance.allowInactiveUserRebalancing')}
            checked={organization?.allowInactiveUserRebalancing}
            onChange={(e: any) => setOrganization({ ...organization, allowInactiveUserRebalancing: e })}
            testId='settings-compliance-allowInactiveUserRebalancing'
            underline
          />
          <SettingsSwitch
            label={t('compliance.allowInactiveUserFeeCalculation')}
            checked={organization?.allowInactiveUserFeeCalculation}
            onChange={(e: any) => setOrganization({ ...organization, allowInactiveUserFeeCalculation: e })}
            testId='settings-compliance-allowInactiveUserFeeCalculation'
            underline
          />
          <SettingsSwitch
            label={t('compliance.allowInactiveUserAdjustments')}
            checked={organization?.allowInactiveUserAdjustments}
            onChange={(e: any) => setOrganization({ ...organization, allowInactiveUserAdjustments: e })}
            testId='settings-compliance-allowInactiveUserAdjustments'
            underline
          />
          <SettingsSwitch
            label={t('compliance.allowInstantInvest')}
            checked={organization?.allowInstantInvest}
            onChange={(e: any) => setOrganization({ ...organization, allowInstantInvest: e })}
            testId='settings-compliance-allowInstantInvest'
            underline
          />
          <SettingsSwitch
            label={t('compliance.requireManualAccountApproval')}
            checked={organization?.requireManualAccountApproval}
            onChange={(e: any) => setOrganization({ ...organization, requireManualAccountApproval: e })}
            testId='settings-compliance-requireManualAccountApproval'
            underline
          />
          <SettingsSwitch
            label={t('compliance.requireSubTradeRequestApproval')}
            checked={organization?.requireSubTradeRequestApproval}
            onChange={(e: any) => setOrganization({ ...organization, requireSubTradeRequestApproval: e })}
            testId='settings-compliance-requireSubTradeRequestApproval'
            underline
          />
          <FeatureFlag
            featureFlag={featureFlags.find((ff) => ff.key === 'ADVISOR_APPROVAL_REQUIRED_FOR_CLIENTS_TO_SIGN_AGREEMENTS')}
            onChange={updateFeatureFlag}
            testId='settings-general-help-center-toggle'
            underline
          />
          <FeatureFlag
            featureFlag={featureFlags.find((ff) => ff.key === 'ANNUAL_INFORMATION_UPDATE')}
            onChange={updateFeatureFlag}
            testId='settings-general-help-center-toggle'
            underline
          />
          <FeatureFlag
            featureFlag={featureFlags.find((ff) => ff.key === 'SUITABILITY_REVIEW_OVERDUE_BLOCK_TRANSFERS')}
            onChange={updateFeatureFlag}
            testId='settings-general-help-center-toggle'
            underline
          />
          <FeatureFlag
            featureFlag={featureFlags.find((ff) => ff.key === 'ID_VERIFICATION')}
            onChange={updateFeatureFlag}
            testId='settings-general-help-center-toggle'
            underline
          />
          <FeatureFlag
            featureFlag={featureFlags.find((ff) => ff.key === 'BYPASS_ID_VERIFICATION')}
            onChange={updateFeatureFlag}
            testId='settings-general-help-center-toggle'
            underline
          />
          <NumberField
            label={t('compliance.profileReviewTimeInMonths')}
            number={organization?.profileReviewTimeInMonths}
            decimalPlaces={0}
            setNumber={(e: any) => setOrganization({ ...organization, profileReviewTimeInMonths: e })}
            testId='settings-compliance-profileReviewTimeInMonths'
          />
          <Box display='flex' justifyContent='flex-end'>
            <Button type='submit' disabled={loading} label={t('shared:save')} dataTestId='settings-general-submit-button'/>
          </Box>
        </Box>
      </Form>
    </SettingsCard>
  );
};

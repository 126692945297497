import { CSSProperties, forwardRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, TextField as MuiTextField, SxProps, InputAdornment as MuiInputAdornment, InputAdornmentProps,
} from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import NumberFormat from 'react-number-format';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import PercentRoundedIcon from '@mui/icons-material/PercentRounded';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TranslateIcon from '@mui/icons-material/Translate';
import LockIcon from '@mui/icons-material/Lock';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { colors } from '../../0-tokens';
import { Icon, Typography } from '../../1-primative';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { useTextFieldTokens } from './textField.tokens';
import { Tooltip } from '../tooltip/tooltip';
import { IconButton } from '../iconButton/iconButton';
import InfoDialog from '../infoDialog/infoDialog';

export const InputAdornment = (props: InputAdornmentProps) => (<MuiInputAdornment {...props} />);

interface FormatterProps {
  onChange: (event: { target: { valueCents: undefined | number; name: string; value: string } }) => void;
  name: string;
  decimalplaces: number;
}

interface TextFieldProps {
  testId?: string,
  type?: 'default' | 'multiLanguage' | 'number' | 'dollar' | 'phone' | '3-3-3' | '3-2-4' | '2-7',
  label?: string,
  placeholder?: string,
  helpText?: string,
  error?: boolean,
  errorText?: string,
  success?: boolean,
  successText?: string,
  fullWidth?: boolean,
  disabled?: boolean,
  locked?: boolean,
  lockMessage?: string,
  sx?: SxProps,
  leadingIcon?: 'dollar' | 'percent' | 'search',
  trailingIcon?: 'calendar' | 'percent',
  size?: 'small' | 'medium',
  textSize?: string | number,
  textAlign?: 'left' | 'center' | 'right',
  children?: React.ReactNode[] | React.ReactNode,
  select?: boolean,
  value?: any,
  defaultValue?: any,
  onChange?: any,
  onFocus?: any,
  onBlur?: any,
  onClick?: any,
  onTranslate?: any,
  renderValue?: any,
  InputProps?: any,
  inputProps?: any,
  multiline?: boolean,
  minRows?: number | string
  maxRows?: number | string
  rows?: number | string
  infoTooltip?: string,
  multiple?: boolean,
  required?: boolean,
  onKeyDown?: any,
  menuTestId?: string,
  decimalPlaces?: number,
  visibilityToggle?: boolean,
  onVisibilityChange?: (reveal: boolean) => void,
  showArrows?: boolean,
}

const StyledTextField = styled(MuiTextField)(() => {
  const tokens = useThemeTokens(useTextFieldTokens());
  const { comp } = tokens;

  return {
    '& .MuiOutlinedInput-root': {
      '& input': {
        paddingLeft: '12px',
        paddingRight: '12px',
        color: comp.textField.textColor,
        fontSize: comp.textField.fontSize,
        fontWeight: comp.textField.fontWeight,
        textAlign: 'inherit',
        '&::placeholder': {
          textAlign: 'inherit',
        },
      },
      '&.hide-number-spinners input[type="number"]': {
        MozAppearance: 'textfield',
        '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
      },
      '&.Mui-focused fieldset': {
        borderColor: comp.textField.focusBorderColor,
      },
      '& fieldset': {
        borderColor: comp.textField.borderColor,
      },
      '&.MuiInputBase-adornedStart .MuiInputAdornment-positionStart': {
        marginRight: '0px',
      },
      '& .MuiSelect-select': {
        paddingLeft: '0px',
        marginLeft: '12px',
        marginRight: '12px',
        color: comp.textField.textColor,
        fontSize: comp.textField.fontSize,
        fontWeight: comp.textField.fontWeight,
      },
      '& .MuiInputAdornment-positionEnd': {
        marginLeft: '0px',
      },
      '&.Mui-error': {
        '& input': {
          color: comp.textField.errorColor,
        },
        '& fieldset': {
          borderColor: comp.textField.errorColor,
        },
      },
    },
  };
});

const CurrencyFormatter = forwardRef<NumberFormat<string>, FormatterProps>(
  (props, ref) => {
    const { onChange, decimalplaces = 2, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values, sourceInfo) => {
          // !todo: onValueChange is triggered on any event or prop change - don't need that if it's not user generated,
          // it forces react to re-trigger state change and rebuild the portfolio table multiple times
          /** @see https://s-yadav.github.io/react-number-format/docs/props#onvaluechange-values-sourceinfo-- */
          if (sourceInfo.event?.type !== 'change') return;
          const { value } = values;

          if (Number.isNaN(Number(value))) {
            return;
          }
          const formattedValue = value ? Number(value).toFixed(decimalplaces) : '';

          onChange({
            target: {
              name: props.name,
              value: formattedValue,
              valueCents: formattedValue === '' ? undefined : Math.round(Number(formattedValue) * 100),
            },
          });
        }}
        decimalScale={decimalplaces}
        fixedDecimalScale={false}
        thousandSeparator
        isNumericString
        defaultValue={0}
        allowNegative={true}
        allowEmptyFormatting={false}
        allowLeadingZeros={false}
      />
    );
  },
);

export const DigitFormatter333 = forwardRef<NumberFormat<string>, FormatterProps>(
  (props, ref) => {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
              valueCents: undefined,
            },
          });
        }}
        format={'### ### ###'}
        isNumericString
      />
    );
  },
);

export const DigitFormatter324 = forwardRef<NumberFormat<string>, FormatterProps>(
  (props, ref) => {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
              valueCents: undefined,
            },
          });
        }}
        format={'###-##-####'}
        isNumericString
      />
    );
  },
);

export const DigitFormatter27 = forwardRef<NumberFormat<string>, FormatterProps>(
  (props, ref) => {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
              valueCents: undefined,
            },
          });
        }}
        format={'##-#######'}
        isNumericString
      />
    );
  },
);

export const PhoneFormatter = forwardRef<NumberFormat<string>, FormatterProps>(
  (props, ref) => {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
              valueCents: undefined,
            },
          });
        }}
        format={'(###) ###-####'}
        isNumericString
      />
    );
  },
);

export const TextField = ({
  label, type = 'default', helpText, error = false, errorText, success = false,
  successText, fullWidth, disabled = false, locked = false, lockMessage, sx, leadingIcon, trailingIcon,
  size = 'medium', onChange, onFocus, onBlur, onClick, onTranslate, renderValue, placeholder, children,
  select, value, infoTooltip, multiple, required, onKeyDown, testId, decimalPlaces = 2,
  visibilityToggle, onVisibilityChange, showArrows = true, textSize = '16px', textAlign = 'left', ...restProps
}: TextFieldProps) => {
  const { sys, comp } = useThemeTokens(useTextFieldTokens());
  const { t } = useTranslation('components');
  const [visibility, setVisibility] = useState<boolean>(false);
  const variantStyle: CSSProperties = {};

  let leadingIconComponent: SvgIconComponent | null = null;
  let trailingIconComponent: SvgIconComponent | null = null;

  switch (leadingIcon) {
    case 'dollar': leadingIconComponent = AttachMoneyRoundedIcon; break;
    case 'percent': leadingIconComponent = PercentRoundedIcon; break;
    case 'search': leadingIconComponent = SearchIcon; break;
  }
  switch (trailingIcon) {
    case 'calendar': trailingIconComponent = CalendarMonthIcon; break;
    case 'percent': trailingIconComponent = PercentRoundedIcon; break;
  }

  const style = {
    ...variantStyle,
    height: restProps.multiline ? 'auto' : size === 'medium' ? '40px' : '32px',
    fontSize: textSize,
    fontFamily: sys.font.base,
    fontWeight: 500,
    borderRadius: '4px',
    width: fullWidth ? '100%' : 'auto',
    background: colors.white,
  };

  const footerText = error ? errorText ?? t('fieldErrors.default', { fieldName: label }) : success ? successText : helpText;

  const footerIcon = error
    ? <ErrorIcon sx={{ mr: '4px', color: comp.textField.errorColor, fontSize: '14px' }} />
    : success
      ? <CheckCircleIcon sx={{ mr: '4px', color: comp.textField.successColor, fontSize: '14px' }} />
      : <HelpOutlineIcon sx={{ mr: '4px', color: comp.textField.labelColor, fontSize: '14px' }} />;

  const getFormatter = (): any => {
    switch (type) {
      case 'dollar': {
        return CurrencyFormatter;
      }
      case '3-3-3': {
        return DigitFormatter333;
      }
      case '3-2-4': {
        return DigitFormatter324;
      }
      case '2-7': {
        return DigitFormatter27;
      }
      case 'phone': {
        return PhoneFormatter;
      }
      default: {
        return undefined;
      }
    }
  };

  const htmlInputType = type === 'number' ? 'number' : type === 'phone' ? 'tel' : 'text';

  const endAdornment: JSX.Element[] = [];

  if (locked) {
    endAdornment.push(
      <Tooltip title={lockMessage ?? ''} key='lock-icon'>
        <Icon icon={LockIcon} size='small' />
      </Tooltip>,
    );
  }
  const iconButtonSize = style.height === 'auto' ? 'auto' : `${Number(style.height.slice(0, -2)) - 8}px`;

  if (type === 'multiLanguage') {
    endAdornment.push(
      <IconButton
        key='translate-icon'
        style={{
          height: iconButtonSize,
          width: iconButtonSize,
        }}
        onClick={locked || disabled ? null : onTranslate}
      >
        <Icon icon={TranslateIcon} size='small' />
      </IconButton>,
    );
  }

  if (visibilityToggle) {
    endAdornment.push(
      <IconButton
        key='visibility-icon'
        style={{
          height: iconButtonSize,
          width: iconButtonSize,
        }}
        onClick={(e) => {
          setVisibility(!visibility);
          onVisibilityChange?.(!visibility);
          e.stopPropagation();
        }}
      >
        <Icon icon={visibility ? VisibilityIcon : VisibilityOffIcon} size='small' />
      </IconButton>,
    );
  }

  if (trailingIconComponent) {
    endAdornment.push(<Icon icon={trailingIconComponent} size='small' key='trailing-icon' />);
  }

  return (
    <Box sx={sx}>
      <Box display="flex" flexDirection="row" alignItems={'center'}>
        <Typography variant='labelSmall' sx={{ mb: label ? '4px' : '0px', color: error ? comp.textField.errorColor : comp.textField.labelColor }}>
          {label}
          {required && '*'}
          {infoTooltip && (<InfoDialog information={infoTooltip} />)}
        </Typography>
      </Box>
      <StyledTextField
        {...restProps}
        data-testid={testId ?? 'text-field'}
        type={htmlInputType}
        onClick={onClick}
        onChange={onChange}
        error={!!error}
        style={style}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled || locked}
        placeholder={placeholder}
        select={select}
        value={value}
        required={required}
        InputProps={{
          style,
          className: type === 'number' && !showArrows ? 'hide-number-spinners' : '',
          inputProps: {
            ...(restProps.inputProps ?? {}),
            'data-testid': `${testId ?? 'text-field'}-input`,
            decimalplaces: decimalPlaces,
            step: 'any',
          },
          inputComponent: getFormatter(),
          startAdornment: leadingIconComponent ? (
            <MuiInputAdornment position="start">
              <Icon icon={leadingIconComponent} size='small' />
            </MuiInputAdornment>
          ) : null,
          endAdornment: endAdornment.length > 0 && <MuiInputAdornment position="end"> {endAdornment} </MuiInputAdornment>,
          ...restProps.InputProps,
        }}
        onKeyDown={onKeyDown}
        SelectProps={select ? {
          renderValue,
          ...(locked ? { IconComponent: 'span' } : {}),
          multiple: multiple ?? undefined,
          MenuProps: {
            PaperProps: {
              'data-testid': `${restProps.menuTestId}`,
            },
          },
        } : undefined}
      >
        {children}
      </StyledTextField>
      {footerText && (
        <Box alignItems='center' display='flex' mt={0.5}>
          {footerIcon}
          <Typography variant='bodySmall' sx={{
            color: error ? comp.textField.errorColor : success ? comp.textField.successColor : comp.textField.labelColor,
          }}>{footerText}</Typography>
        </Box>
      )}
    </Box>
  );
};

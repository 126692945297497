/* eslint-disable operator-linebreak */
import { gql, useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Archive, CheckCircle, Drafts } from '@mui/icons-material';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import { FormTemplate } from '../../../interfaces';
import { translateBackend } from '../../../assets/i18n/config';
import TemplateViewerModal from './components/templateViewerModal';
import { useLocalization } from '../../../util/useLocalization';
import {
  Badge, Card, CardContent, IconButton, Menu, MenuItem, Pagination, Table, TableBody, TableCell, TableHeadCell, TableRow, TextField, Tooltip,
} from '../../2-component';
import { BetaBadge, Box, Skeleton } from '../..';
import TitleEdit from './components/titleEdit';
import {
  INDIVIDUAL_FORMS, NON_INDIVIDUAL_FORMS, ACCOUNT_FORMS, SUB_ACCOUNT_FORMS, GOAL_FORMS, SCHEDULE_TRANSFER_FORMS, CLIENT_GROUP_FORMS,
} from './components/formCategories';
import { UpdateOrganizationSwitch } from '../../4-module/updateOrganizationSwitch/updateOrganizationSwitch';
import useTextDebounce from '../../../util/useTextDebounce';

export const FETCH_LOCALIZATIONS = gql`
  query fetchLocalizations($input: FetchLocalizationsInput!) {
    fetchLocalizations(input: $input) {
      localizations {
        id
        organization {
          id
          name
        }
        name
        countries
        defaultCurrency
        languages
        defaultLanguage
        dateFormat
      }
      totalCount
    }
  }
`;

export const FETCH_FORM_TEMPLATES = gql`
  query fetchFormTemplates($input: FetchFormTemplatesInput!) {
    fetchFormTemplates(input: $input) {
      formTemplates {
        id
        organization {
          id
          name
        }
        state
        type
        templateUrl
        minVersion
        translatedHtml {
          en
          fr
        }
        updatedAt
        translatedDisplayName {
          en
          fr
        }
      }
      totalCount
    }
  }
`;

const TRANSITION_FORM_TEMPLATE = gql`
  mutation transitionFormTemplate($input: TransitionFormTemplateInput!) {
    transitionFormTemplate(input: $input) {
      formTemplate {
        id
      }
    }
  }
`;

const DocumentManagement = () => {
  const { permissions } = usePermissions();
  const navigate = useNavigate();
  const { activeOrganization } = useContext(UserContext);
  const [page, setPage] = usePageState(1, 'page');
  const { t } = useTranslation(['orgSettings', 'formTypes', 'settings']);
  const { localizedDateTime } = useLocalization();
  const [selectedRecord, setSelectedRecord] = useState<FormTemplate | null>();
  const [selectedRecordMenuAnchor, setSelectedRecordMenuAnchor] = useState<null | HTMLElement>(null);
  const [viewRecord, setViewRecord] = useState<FormTemplate | null | undefined>(null);
  const [editRecord, setEditRecord] = useState<FormTemplate | null | undefined>(null);
  const [pageSize, setPageSize] = usePageState(15, 'ps');
  const [openTitleEdit, setOpenTitleEdit] = useState(false);
  const [searchText, setSearchText] = useState('');

  const debouncedSearchText = useTextDebounce({ value: searchText, delay: 500 });

  const getAssociatedObject = (formTemplateType: string): string => {
    const isUserForm = INDIVIDUAL_FORMS.includes(formTemplateType) || NON_INDIVIDUAL_FORMS.includes(formTemplateType);
    const isAccountForm = ACCOUNT_FORMS.includes(formTemplateType);
    const isSubAccountForm = SUB_ACCOUNT_FORMS.includes(formTemplateType);
    const isGoalForm = GOAL_FORMS.includes(formTemplateType);
    const isScheduleTransferForm = SCHEDULE_TRANSFER_FORMS.includes(formTemplateType);
    const isClientGroupForm = CLIENT_GROUP_FORMS.includes(formTemplateType);
    if (isUserForm) return 'USER';
    if (isAccountForm) return 'ACCOUNT';
    if (isSubAccountForm) return 'SUB_ACCOUNT';
    if (isGoalForm) return 'GOAL';
    if (isScheduleTransferForm) return 'SCHEDULE_TRANSFER';
    if (isClientGroupForm) return 'CLIENT_GROUP';
    return 'NotAssigned';
  };

  const { loading, data, previousData } = useQuery(FETCH_FORM_TEMPLATES, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          name: debouncedSearchText,
          states: ['ACTIVE', 'DRAFT'],
        },
        pagination: {
          perPage: pageSize,
          offSet: (page - 1) * pageSize,
          sortField: 'name',
          sortDesc: false,
        },
      },
    },
    fetchPolicy: 'no-cache',
  });

  const [transitionFormTemplate] = useMutation(TRANSITION_FORM_TEMPLATE, {
    refetchQueries: [FETCH_FORM_TEMPLATES],
  });

  const handleTransition = async ({ transition, templateId } : { transition: string, templateId: string }) => {
    await transitionFormTemplate({
      variables: {
        input: {
          formTemplateId: templateId,
          transition,
        },
      },
    });
  };

  const isInherited = (l: FormTemplate) => l.organization?.id !== activeOrganization.id;

  return (
    <Card loading={loading}>
      <Box>
        <CardContent>
          <Box display='flex' alignItems={'center'} gap={2} flexWrap='wrap'>
            <TextField
              value={searchText}
              placeholder={t('documentManagement.searchByName')}
              onChange={(event: any) => {
                setSearchText(event.target.value);
                setPage(1);
              }}
              leadingIcon='search'
            />
            {permissions.includes('write:advanced_organization_settings') && (
              <UpdateOrganizationSwitch
                field='useParentFormTemplates'
                label={t('settings:forms.useParentFormTemplates')}
                organizationId={activeOrganization.id}
                refetchQueries={[FETCH_FORM_TEMPLATES]}
              />
            )}
            <BetaBadge/>
          </Box>
        </CardContent>
        <Box sx={{ overflowX: 'auto' }}>
          <Table sx={{ minWidth: 650 }} aria-label='table'>
            <TableBody>
              <TableRow>
                <TableHeadCell>{t('documentManagement.name')}</TableHeadCell>
                <TableHeadCell>{t('documentManagement.association')}</TableHeadCell>
                <TableHeadCell>{t('documentManagement.lastUpdatedAt')}</TableHeadCell>
                <TableHeadCell>{t('documentManagement.minVersion')}</TableHeadCell>
                <TableHeadCell>{t('documentManagement.state')}</TableHeadCell>
                <TableHeadCell>{t('shared:organization')}</TableHeadCell>
                <TableHeadCell></TableHeadCell>
              </TableRow>
              {loading &&
                !previousData &&
                [...Array(10)].map((x: any, i: number) => (
                  <TableRow key={i}>
                    <TableCell>
                      <Skeleton width='100%' />
                    </TableCell>
                    <TableCell>
                      <Skeleton width='100%' />
                    </TableCell>
                    <TableCell>
                      <Skeleton width='100%' />
                    </TableCell>
                    <TableCell>
                      <Skeleton width='100%' />
                    </TableCell>
                    <TableCell>
                      <Skeleton width='100%' />
                    </TableCell>
                    <TableCell>
                      <Skeleton width='100%' />
                    </TableCell>
                    <TableCell>
                      <Skeleton width='100%' />
                    </TableCell>
                  </TableRow>
                ))}
              {(data || previousData)?.fetchFormTemplates?.formTemplates?.map((formTemplate: FormTemplate, index: number) => (
                <TableRow hover key={formTemplate.id} sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                  <TableCell component='th' scope='row'>
                    {formTemplate.translatedDisplayName && formTemplate.translatedDisplayName.en !== '' ? translateBackend(formTemplate.translatedDisplayName) : t(`formTypes:${formTemplate.type}`)}
                  </TableCell>
                  <TableCell>{t(`documentManagement.${getAssociatedObject(formTemplate.type)}`)}</TableCell>
                  <TableCell>{localizedDateTime(formTemplate?.updatedAt)}</TableCell>
                  <TableCell>{formTemplate.minVersion}</TableCell>
                  <TableCell>
                    <Badge label={t(`documentManagement.templateState.${formTemplate.state}`)} color={formTemplate.state === 'ACTIVE' ? 'positive' : 'neutral'} />
                  </TableCell>
                  <TableCell>
                    {isInherited(formTemplate) ? (
                      <Tooltip title={t('shared:inheritedTooltip', { model: t('documentManagement.template'), organization: formTemplate.organization?.name })}>{t('shared:inheritedFlag')}</Tooltip>
                    ) : (
                      formTemplate.organization?.name
                    )}
                  </TableCell>
                  <TableCell
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <IconButton
                      disabled={isInherited(formTemplate)}
                      sx={{ m: 0 }}
                      onClick={(e) => {
                        setSelectedRecord(formTemplate);
                        setSelectedRecordMenuAnchor(e.currentTarget);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Pagination
          count={Math.ceil(((data || previousData)?.fetchFormTemplates?.totalCount ?? 0) / pageSize)}
          page={page}
          perPage={pageSize}
          onChangePerPage={(newPageSize) => setPageSize(newPageSize)}
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />
      </Box>

      {/* Context per-row menu */}
      <Menu
        anchorEl={selectedRecordMenuAnchor}
        open={!!selectedRecordMenuAnchor}
        onClose={() => setSelectedRecordMenuAnchor(null)}
        onClick={() => setSelectedRecordMenuAnchor(null)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => setViewRecord(selectedRecord)} disabled>
          <RemoveRedEyeIcon />
          &nbsp; {t('documentManagement.view')}
        </MenuItem>
        {permissions.includes('write:form_template') && (
          <MenuItem
            disabled={!!selectedRecord && isInherited(selectedRecord)}
            onClick={() => {
              setEditRecord(selectedRecord);
              navigate(`formTemplate/${selectedRecord?.id}`);
            }}
          >
            <EditIcon />
            &nbsp; {t('documentManagement.edit')}
          </MenuItem>
        )}
        <MenuItem disabled>
          <HistoryIcon />
          &nbsp; {t('documentManagement.history')}
        </MenuItem>
        {(selectedRecord?.state === 'ACTIVE' || selectedRecord?.state === 'ARCHIVED') && permissions.includes('write:form_template') && (
            <MenuItem onClick={() => handleTransition({ transition: 'draft', templateId: selectedRecord?.id })}>
              <Drafts />
              &nbsp; {t('documentManagement.draft')}
            </MenuItem>
        )}
        {(selectedRecord?.state === 'ACTIVE' || selectedRecord?.state === 'DRAFT') && permissions.includes('write:form_template') && (
            <MenuItem onClick={() => handleTransition({ transition: 'archive', templateId: selectedRecord?.id })}>
              <Archive />
              &nbsp; {t('documentManagement.archive')}
            </MenuItem>
        )}
        {selectedRecord?.state === 'DRAFT' && permissions.includes('write:form_template') && (
          <MenuItem onClick={() => handleTransition({ transition: 'activate', templateId: selectedRecord?.id })}>
            <CheckCircle />
            &nbsp; {t('documentManagement.activate')}
          </MenuItem>
        )}
      </Menu>

      {viewRecord && (
        <TemplateViewerModal
          formTemplate={viewRecord}
          origin='TABLE'
          onModalClose={() => {
            setViewRecord(null);
          }}
        />
      )}
      <TitleEdit
        formTemplate={editRecord}
        openDialog={openTitleEdit}
        setOpenDialog={setOpenTitleEdit}
        value={editRecord?.translatedDisplayName ?? {}}
        onChange={(titleData: any) => {
          if (editRecord) {
            setEditRecord({
              ...editRecord,
              translatedDisplayName: titleData,
            });
          }
        }}
      />
    </Card>
  );
};

export default DocumentManagement;

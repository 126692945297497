import { useContext, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { CircularProgress } from '@mui/material';
import { validateUrl } from '../../../../util';
import {
  Button, Dialog, DialogContent, DialogFooter, DialogTitle, IconButton,
} from '../../../2-component';
import { CreateAndEditNewsAndInsightsForm, type Article } from './createAndEditNewsAndInsightsForm';
import { Box } from '../../../1-primative';
import { UserContext } from '../../../../providers/userContextProvider';
import { CREATE_FILE_DOCUMENT, FETCH_FILE_UPLOAD_URL } from '../../../3-pattern/addBankAccount/addBankAccountManually/addBankAccountManually';
import {
  DELETE_ARTICLE_IMAGE, FETCH_ARTICLE, FETCH_DOCUMENTS, UPDATE_ARTICLE,
} from './queries';

const DEFAULT_ARTICLE_STATE: Article = {
  id: '',
  articleUrl: '',
  translatedName: { en: '', fr: '' },
  translatedDescription: { en: '', fr: '' },
};

export const EditArticle = ({
  open, setOpen, article, refetch,
}: {
  open: boolean, setOpen: (open: boolean) => void, article: any, refetch: () => void,
}) => {
  const { t } = useTranslation('newsAndInsight');
  const { activeOrganization, userContext } = useContext(UserContext);
  const [createFileDocument] = useMutation(CREATE_FILE_DOCUMENT);

  const [articleToUpdate, setArticleToUpdate] = useState<Article>(DEFAULT_ARTICLE_STATE);
  const [articleImageFile, setArticleImageFile] = useState<File>();
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const [fetchFileUploadUrl] = useLazyQuery(FETCH_FILE_UPLOAD_URL, { fetchPolicy: 'no-cache' });
  const [fetchDocuments] = useLazyQuery(FETCH_DOCUMENTS, { variables: { filter: { sourceIds: [article.id], types: ['ARTICLE'] } }, fetchPolicy: 'network-only' });

  const [deleteArticleImageMutation] = useMutation(DELETE_ARTICLE_IMAGE, { variables: { input: { articleId: article.id } }, fetchPolicy: 'network-only' });

  const { loading: fetchArticleLoading } = useQuery(FETCH_ARTICLE, {
    variables: { articleId: article.id },
    fetchPolicy: 'network-only',
    onCompleted: (data) => setFetchedArticle(data.fetchArticle.article),
    onError: () => setFetchedArticle(article),
  });

  const [updateArticleMutation] = useMutation(UPDATE_ARTICLE, {
    variables: {
      input: {
        articleId: articleToUpdate.id,
        articleUrl: articleToUpdate.articleUrl,
        translatedDescription: { en: articleToUpdate.translatedDescription.en, fr: articleToUpdate.translatedDescription.fr },
        translatedName: { en: articleToUpdate.translatedName.en, fr: articleToUpdate.translatedName.fr },
      },
    },
    onCompleted: () => {
      handleCreateArticleDialogClose();
      refetch();
    },
  });

  const setFetchedArticle = (data: Article & { imageUrl: string | null }) => {
    setArticleToUpdate({
      id: data.id,
      articleUrl: data.articleUrl,
      translatedName: data.translatedName,
      translatedDescription: data.translatedDescription,
    });
    setImageSrc(data.imageUrl);
  };

  const handleCreateArticleDialogClose = () => {
    setErrorFields([]);
    resetFileImageAndSrc();
    setOpen(false);
    setArticleToUpdate(DEFAULT_ARTICLE_STATE);
  };

  const doUpload = async (uploadingFile: File) => {
    const createFileInput: any = {
      objectType: 'ORGANIZATION',
      objectId: activeOrganization.id,
      fileName: uploadingFile?.name,
      type: 'ARTICLE',
    };

    /* (1) fetch the S3 upload URL from backend */
    const queryResult = await fetchFileUploadUrl({ variables: { input: { ...createFileInput, userId: userContext.id, generateUniqueFileName: true } } });
    const uploadUrl = queryResult?.data?.fetchFileUploadUrl.temporarySignedURL;
    const s3Path = queryResult?.data?.fetchFileUploadUrl.s3Path;
    if (!uploadUrl || queryResult?.error) {
      // showToast({ severity: 'error', message: 'File upload failed' });
      return;
    }
    /* (2) do the upload */
    try {
      // setUploadPending(true);
      const uploaded: Response = await fetch(new Request(uploadUrl, { method: 'PUT', body: uploadingFile }));
      if (!uploaded.ok) throw (new Error(`${uploaded.status} ${uploaded.statusText}`));
    } catch (e: any) {
      // showToast({ severity: 'error', message: 'File upload failed' });
      return;
    } finally {
      // setUploadPending(false);
    }

    /* (3) create the fileDocument within backend */
    createFileInput.name = uploadingFile.name;
    createFileInput.sourceId = article.id;
    createFileInput.mediaType = uploadingFile.type;
    createFileInput.permissionType = 'PUBLIC';
    createFileInput.sourceType = 'ARTICLE';
    createFileInput.s3Path = s3Path;

    try {
      await createFileDocument({ variables: { input: createFileInput }, refetchQueries: [FETCH_DOCUMENTS] });
    } catch (error) {
      // showToast({ severity: 'error', message: 'File upload failed' });
    }
    // showToast({ severity: 'success', message: `Document uploaded successfully: ${uploadingFile.name}` });
  };

  const handleFileInputChange = (file: File) => {
    setImageSrc(URL.createObjectURL(file));
    setArticleImageFile(file);
  };

  const resetFileImageAndSrc = () => {
    setImageSrc(null);
    setArticleImageFile(undefined);
  };

  const handleValidationClear = (value: string, fieldName: string) => {
    if (value.length > 0 && errorFields.includes(fieldName)) {
      const removedField = errorFields.filter((field) => field !== fieldName);
      setErrorFields([...removedField]);
    }
  };

  const hasInValidFields = (): boolean => {
    const fieldsWithError: string[] = [];
    if (!articleToUpdate.translatedName.en) fieldsWithError.push('translatedName');
    if (!validateUrl(articleToUpdate.articleUrl)) fieldsWithError.push('articleUrl');
    if (!articleToUpdate.translatedDescription.en) fieldsWithError.push('translatedDescription');

    setErrorFields((prevState) => [...prevState, ...fieldsWithError]);
    return fieldsWithError.length > 0;
  };

  const updateArticle = async () => {
    if (hasInValidFields()) return;

    try {
      setLoading(true);
      const documentsResponse = await fetchDocuments();
      if ((documentsResponse.data?.fetchFileDocuments?.fileDocuments ?? []).length === 0 && articleImageFile) {
        await doUpload(articleImageFile);
      } else if ((documentsResponse.data?.fetchFileDocuments?.fileDocuments ?? []).length > 0 && (imageSrc === null || articleImageFile)) {
        await deleteArticleImageMutation();
        if (articleImageFile) {
          await doUpload(articleImageFile);
        }
      }
      await updateArticleMutation();
    } catch {
      // empty
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={handleCreateArticleDialogClose}>
      <DialogTitle display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        {t('newsAndInsight:articleConfiguration')}
        <IconButton onClick={handleCreateArticleDialogClose} testId='edit-article-close-button'><CloseRoundedIcon /></IconButton>
      </DialogTitle>
      <DialogContent>
        {fetchArticleLoading ? (
          <Box display='flex' justifyContent='center' width='100%'><CircularProgress /></Box>
        ) : (
          <CreateAndEditNewsAndInsightsForm
            imageSrc={imageSrc}
            resetFileImageAndSrc={resetFileImageAndSrc}
            handleFileInputChange={handleFileInputChange}
            article={articleToUpdate}
            setArticleToUpdate={setArticleToUpdate}
            errorFields={errorFields}
            handleValidationClear={handleValidationClear}
            disabled={loading}
          />
        )}
      </DialogContent>
      <DialogFooter>
        <Button disabled={loading} label={t('shared:update')} onClick={updateArticle} dataTestId='edit-article-update-button' />
      </DialogFooter>
    </Dialog>
  );
};

import { gql, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Switch } from '../../2-component';

const FETCH_ORGANIZATION = (field: string) => gql`
  query fetchOrganization($organizationId: String!) {
    fetchOrganization(organizationId: $organizationId) {
      organization {
        id
        ${field}
      }
    }
  }
`;

const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        id
      }
    }
  }
`;

export const UpdateOrganizationSwitch = ({
  organizationId, field, label, refetchQueries,
}: {
  organizationId?: string, field: string, label: string, refetchQueries?: any[]
}) => {
  const [checked, setChecked] = useState(false);

  const { data } = useQuery(
    FETCH_ORGANIZATION(field),
    {
      variables: { organizationId },
      skip: !organizationId,
    },
  );

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION, {
    variables: {
      input: {
        organizationId,
        [field]: !checked,
      },
    },
    onCompleted: () => {
      setChecked(!checked);
    },
    refetchQueries,
  });

  useEffect(() => {
    setChecked(data?.fetchOrganization?.organization[field]);
  }, [data, field]);

  return (
    <Switch checked={checked} onChange={() => updateOrganization()} label={label} />
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useEffect, useState } from 'react';
import { CountryCodes, Jurisdictions } from '@onevesthq/ov-enums';
import { useTranslation } from 'react-i18next';
import { Localization, Organization } from '../../../../../interfaces';
import { getCountryName } from '../../../../resources';
import {
  Box, Typography,
} from '../../../../1-primative';
import {
  Checkbox, Chip, MenuItem, SelectField, Radio, RadioGroup,
} from '../../../../2-component';

const EditOrganizationJurisdictions = ({
  organization,
  setOrganization,
  selectedLocalization,
}: {
  organization: Organization,
  setOrganization: (newValue: Organization) => void
  selectedLocalization?: Localization
}) => {
  const { t } = useTranslation(['orgSettings']);
  const countries: CountryCodes[] = selectedLocalization?.countries as CountryCodes[] ?? [];
  const [allSelector, setAllSelector] = useState<boolean>(
    organization?.jurisdictions
      ? !!organization.jurisdictions.all
      : true,
  );
  const [jurisdictionsByCountry, setJurisdictionsByCountry] = useState<Record<string, Jurisdictions[]>>(
    splitByCountry(organization?.jurisdictions?.only),
  );

  // clean jurisdictions on click to "all" radio
  useEffect(() => {
    if (allSelector === true) {
      setJurisdictionsByCountry({});
    }
  }, [allSelector]);

  // propagate state up to <EditOrganization>
  useEffect(() => {
    if (allSelector === true) {
      setOrganization({ ...organization, jurisdictions: { all: true } });
    }
    if (allSelector === false) {
      setOrganization({ ...organization, jurisdictions: { all: false, only: Object.values(jurisdictionsByCountry).flat() } });
    }
  }, [allSelector, jurisdictionsByCountry]);

  // clean jurisdictions when localization/countries change
  useEffect(() => {
    if (countries.length === 0) return;
    Object.keys(jurisdictionsByCountry).forEach((country) => {
      if (!countries.includes(country as CountryCodes)) {
        setAllSelector(true);
        setJurisdictionsByCountry({});
      }
    });
  }, [countries]);

  return (
    <Box>
      <Typography variant='labelSmall' colorVariant='variant'>{t('organizationDetails.enabledJurisdictions')}</Typography>
      <RadioGroup onChange={(e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === 'all') setAllSelector(true);
        if (e.target.value === 'selected') setAllSelector(false);
      }}
        value={allSelector ? 'all' : 'selected'}
      >
        <Radio value='all' label={t('organizationDetails.allJurisdictions')}
          disabled={countries.length === 0}
        />
        <Radio value='selected' label={t('organizationDetails.selectedJurisdictions')}
          disabled={countries.length === 0}
        />
      </RadioGroup >
      {
        countries.length === 0 && (
          <Typography color={'gray'}>{t('organizationDetails.jurisdictionsDisabledMessage')}</Typography>
        )
      }
      {allSelector === false && (
        countries?.map((country, index) => (
          <JurisdictionSelectorForCountry
            key={index}
            country={country}
            jurisdictions={(jurisdictionsByCountry ?? {})[country] ?? []}
            setJurisdictions={(newList) => setJurisdictionsByCountry({ ...jurisdictionsByCountry, [country]: newList })}
          />
        ))
      )}
    </Box>
  );
};

const JurisdictionSelectorForCountry = ({
  country,
  jurisdictions,
  setJurisdictions,
}: {
  country: CountryCodes
  jurisdictions: Jurisdictions[]
  setJurisdictions: (list: Jurisdictions[]) => void
}) => {
  const { t } = useTranslation(['orgSettings']);
  const myJurisdictionsOnly = (code: string) => code.substring(0, 2).toLowerCase() === country.toLowerCase();
  const selectableJurisdictions = Object.values(Jurisdictions).filter(myJurisdictionsOnly).sort();

  return (
    <SelectField multiple fullWidth
      value={jurisdictions.filter(myJurisdictionsOnly).sort()}
      label={t('organizationDetails.jurisdictionsIn', { country: getCountryName(country) })}
      onChange={(e: any) => setJurisdictions(e.target.value)}
      renderValue={(selected: any) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selected.map((value: any) => (
            <Chip key={value} text={`${value} - ${t(`geoNames:${value}`)}`} />
          ))}
        </Box>
      )}
      required
    >
      {selectableJurisdictions.map((jCode, index) => (
        <MenuItem key={index} value={jCode}>
          <Checkbox checked={jurisdictions.indexOf(jCode) > -1} />
          {`${jCode} - ${t(`geoNames:${jCode}`)}`}
        </MenuItem>))}
    </SelectField>
  );
};

/**
 * @param list of jurisdiction codes ['CA_AB', 'CA_ON', 'US_WA']
 * @returns by country { CA: ['CA_AB', 'CA_ON'], US: ['US_WA'] }
 */
const splitByCountry = (list?: Jurisdictions[]) => {
  if (!list) return {};

  const sorted: Record<string, Jurisdictions[]> = {};
  list.forEach((j) => {
    const c = j.substring(0, 2);
    if (!sorted[c]) sorted[c] = [];
    sorted[c].push(j);
  });

  return sorted;
};

export default EditOrganizationJurisdictions;

import { gql } from '@apollo/client';
import { STATE_CHANGES_FIELD } from '../../../../util/reusableGraphQLFields';

export const FETCH_UPCOMING_TRANSFERS_QUERY = (permissions: string[]) => `
  query fetchUpcomingTransfers($input: FetchUpcomingTransfersInput!) {
    fetchUpcomingTransfers(input: $input) {
      upcomingTransfers {
        id
        amountCents
        subAccount {
          id
          organization {
            id
          }
          financialProduct {
            id
            cusip
          }
          account {
            id
            type
            householdClientGroup {
              id
            }
            user {
              id
              ${permissions.includes('read:client_low_risk_pii') ? 'firstName middleName lastName' : ''}
            }
          }
          goal {
            id
            name
          }
        }
        bankAccount {
          name
          institutionNumber
          bankAccountNumber
          transitNumber
        }
        source
        state
        type
        createdAt
        ${STATE_CHANGES_FIELD}
        frequency
        scheduledDate
        currency
      }
      totalCount
    }
  }
`;

export const FETCH_ACCOUNT_CUSTODIAN_UPCOMING_TRANSACTION_QUERY = (permissions: string[]) => `
  query fetchCustodianUpcomingTransactions($input: FetchCustodianUpcomingTransactionsInput!){
    fetchCustodianUpcomingTransactions(input: $input){
      totalCount
      upcomingTransactions {
        amountCents
        type
        frequency
        createdAt
        scheduledDate
        state
        source
        bankAccount {
          institutionNumber
          bankAccountNumber
          transitNumber
        }
        financialProduct {
          cusip
        }
        account {
          id
          type
          householdClientGroup {
            id
          }
          organization {
            id
          }
          user {
            id
            ${permissions.includes('read:client_low_risk_pii') ? 'firstName middleName lastName' : ''}
          }
        }
      }
    }
  }
`;

export const FETCH_UPCOMING_TRANSFERS = (permissions: string[]) => gql`${FETCH_UPCOMING_TRANSFERS_QUERY(permissions)}`;

export const TRANSITION_TRANSFER = gql`
  mutation transitionTransfer($input: TransitionTransferInput!) {
    transitionTransfer(input: $input) {
      transfer {
        id
      }
    }
  }
`;

export const CANCEL_SCHEDULED_TRANSFER = gql`
  mutation cancelScheduledTransfer($scheduledTransferId: ObjectID!) {
    cancelScheduledTransfer(scheduledTransferId: $scheduledTransferId) {
      scheduledTransfer { id }
    }
  }
`;

export const FETCH_ACCOUNT_CUSTODIAN_UPCOMING_TRANSACTION = (permissions: string[]) => gql`${FETCH_ACCOUNT_CUSTODIAN_UPCOMING_TRANSACTION_QUERY(permissions)}`;

import { useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { CheckCircle } from '@mui/icons-material';
import { FormAgreement, TranslatedString } from 'interfaces';
import {
  FETCH_ALL_ACCOUNT_LATEST_FORM_AGREEMENT, FETCH_ALL_HOUSEHOLD_LATEST_FORM_AGREEMENT, FETCH_ALL_LATEST_FORM_AGREEMENT, FETCH_FORM_AGREEMENT_SIGNED_URL, RENDER_FORM_AGREEMENT,
} from '../documents.queries';
import { Box, Skeleton, Typography } from '../../../../1-primative';
import { EmptyStateHeaderTitle, StatusTag } from '../../../../2-component';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { translateBackend } from '../../../../../assets/i18n/config';
import { DocumentDialog } from './documentDialog';
import { PageObjectType } from '../../../../5-page';
import { useLocalization } from '../../../../../util/useLocalization';

export const Agreements = ({
  objectId, objectType, documentTypes, filters,
}: {
  objectId: string, objectType: PageObjectType, documentTypes: string[],
  filters?: { searchText?: string, dateStart?: string, dateEnd?: string },
}) => {
  const { t } = useTranslation(['document']);
  const { localizedDate } = useLocalization();
  const { sys } = useThemeTokens();
  const [open, setOpen] = useState<boolean>(false);
  const [previewHtml, setPreviewHtml] = useState<string>('');
  const [pdfUrl, setPdfUrl] = useState<string>('');
  const [documentId, setDocumentId] = useState<string>('');
  const [translatedDisplayName, setTranslatedDisplayName] = useState<TranslatedString>();
  const client = useApolloClient();

  const { data, loading } = useQuery(FETCH_ALL_LATEST_FORM_AGREEMENT, {
    variables: {
      userId: objectId,
      types: documentTypes,
    },
    skip: ![PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL].includes(objectType),
  });

  const { data: accountData, loading: accountLoading } = useQuery(FETCH_ALL_ACCOUNT_LATEST_FORM_AGREEMENT, {
    variables: {
      accountId: objectId,
      types: documentTypes,
    },
    skip: ![PageObjectType.ACCOUNT].includes(objectType),
  });

  const { data: householdData, loading: householdLoading } = useQuery(FETCH_ALL_HOUSEHOLD_LATEST_FORM_AGREEMENT, {
    variables: {
      clientGroupId: objectId,
      types: documentTypes,
    },
    skip: ![PageObjectType.HOUSEHOLD].includes(objectType),
  });

  const getSignedDownloadUrl = async (id: string) => {
    const result = await client.query({
      query: FETCH_FORM_AGREEMENT_SIGNED_URL,
      variables: {
        input: {
          formAgreementId: id,
        },
      },
      fetchPolicy: 'no-cache',
    });
    return result?.data?.fetchFormAgreement?.signedUrl || '';
  };

  const openInANewTab = (url: string): void => {
    if (window) {
      window.open(url, '_blank');
    }
  };

  const downloadFile = async (id: string) => {
    const signedUrl = await getSignedDownloadUrl(id);
    if (signedUrl) {
      openInANewTab(signedUrl);
    }
  };

  const previewAgreementClick = async (agreement: FormAgreement): Promise<void> => {
    let html = '';
    setTranslatedDisplayName(agreement.translatedDisplayName);
    setDocumentId(agreement.id);
    const result = await client.query({
      query: RENDER_FORM_AGREEMENT,
      variables: {
        input: {
          formAgreementId: agreement.id,
        },
      },
    });
    if (result.data && result.data.renderFormAgreement.html) {
      html = result.data.renderFormAgreement.html;
      setPreviewHtml(html);
      setPdfUrl('');
      setOpen(true);
    }
    if (!html && agreement.signedUrl) {
      const signedUrl = await getSignedDownloadUrl(agreement.id);
      if (signedUrl) {
        setPdfUrl(signedUrl);
        setPreviewHtml('');
        setOpen(true);
      }
    }
  };

  if (loading || accountLoading || householdLoading) {
    return (
      <>
      <Box display={'flex'} gap={2} pt={2}>
        <Skeleton width='80%' height='24px' />
        <Skeleton width='20%' height='24px'/>
      </Box>
      <Box display={'flex'} gap={2} pt={2}>
        <Skeleton width='80%' height='24px' />
        <Skeleton width='20%' height='24px'/>
      </Box>
      <Box display={'flex'} gap={2} pt={2}>
        <Skeleton width='80%' height='24px' />
        <Skeleton width='20%' height='24px'/>
      </Box>
      </>
    );
  }

  let agreements: FormAgreement[] = (
    data?.fetchUser?.user?.allLatestFormAgreements
    || accountData?.fetchAccount?.account?.latestFormAgreements
    || householdData?.fetchClientGroup.clientGroup.allLatestFormAgreements
    || []
  );
  const originalAgreementsCount = agreements.length;

  const useUserFilter = filters && (filters.searchText || filters.dateStart || filters.dateEnd);
  if (useUserFilter) {
    agreements = agreements.filter((a: FormAgreement) => {
      let success = true;
      if (filters.searchText) {
        success = translateBackend(a.translatedDisplayName).includes(filters.searchText);
      }
      if (filters.dateStart) {
        success = success && !!a.signedAt && !dayjs(a.signedAt).isBefore(filters.dateStart);
      }
      if (filters.dateEnd) {
        success = success && !!a.signedAt && !dayjs(a.signedAt).isAfter(filters.dateEnd);
      }
      return success;
    });
  }

  return (
    <Box width='100%'>
      {
        agreements.length === 0 && (
          <EmptyStateHeaderTitle sx={{ mt: 2 }} title={originalAgreementsCount > 0 && useUserFilter ? t('document:noDocumentsFiltered') : t('document:noAgreements')} />
        )
      }
      {
        agreements.map((agreement: FormAgreement) => (
          <Box
            key={agreement.id}
            display='flex'
            pl={2}
            pt={1}
            pb={1}
            pr={2}
            justifyContent='space-between'
            alignItems='center'
            onClick={() => previewAgreementClick(agreement)}
            sx={{
              borderRadius: sys.borderRadius.md,
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: sys.color.surfaceContainerVariant,
              },
            }}
          >
            <Box width='100%' display='flex'>
              <Typography variant='bodyMedium' sx={{ minWidth: '100px', color: sys.color.onSurfaceVariant }}>
                {localizedDate(agreement.signedAt)}
              </Typography>
              <Typography variant='bodyMedium'>{translateBackend(agreement.translatedDisplayName)}</Typography>
            </Box>
            <StatusTag label={t('document:signed')} icon={CheckCircle} color='positive' />
          </Box>
        ))
      }
      <DocumentDialog
        content={previewHtml}
        pdfUrl={pdfUrl}
        documentId={documentId}
        translatedDisplayName={translatedDisplayName}
        downloadFile={downloadFile}
        handleClose={() => setOpen(false)}
        open={open}
      />
    </Box>
  );
};

import {
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { translateBackend } from 'assets/i18n/config';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { InputAdornment, TextField } from '@mui/material';
import { useThemeTokens } from 'providers/themeTokenProvider';
import Search from '@mui/icons-material/Search';
import {
  Typography, Form, SelectionTile,
} from '../../../..';
import { AccountTypes } from '../../../../../interfaces';
import { WorkflowContext } from '../../workflowCompletion';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';
import { CreateAccountForGoalArgs } from './createAccountForGoal';

interface AccountListItem {
  weight: number,
  type: string,
}

// custom weighted list by Josh
const generateWeightedList = (accountTypeList: string[]): AccountListItem[] => {
  const weightedList = accountTypeList.map((accountItem: string) => {
    if (accountItem === AccountTypes.PERSONAL) {
      return {
        weight: 1,
        type: accountItem,
      };
    }
    if (accountItem === AccountTypes.CASH_JOINT) {
      return {
        weight: 2,
        type: accountItem,
      };
    }
    if (accountItem === AccountTypes.TFSA) {
      return {
        weight: 3,
        type: accountItem,
      };
    }
    if (accountItem === AccountTypes.RRSP) {
      return {
        weight: 4,
        type: accountItem,
      };
    }
    if (accountItem === AccountTypes.RRSP_SPOUSAL) {
      return {
        weight: 5,
        type: accountItem,
      };
    }
    if (accountItem === AccountTypes.RESP_FAMILY) {
      return {
        weight: 6,
        type: accountItem,
      };
    }
    if (accountItem === AccountTypes.RESP_SINGLE) {
      return {
        weight: 7,
        type: accountItem,
      };
    }
    if (accountItem === AccountTypes.RESP_ADULT) {
      return {
        weight: 8,
        type: accountItem,
      };
    }
    if (accountItem === AccountTypes.RESP_FAMILY_JOINT) {
      return {
        weight: 9,
        type: accountItem,
      };
    }
    if (accountItem === AccountTypes.RESP_SINGLE_JOINT) {
      return {
        weight: 10,
        type: accountItem,
      };
    }
    if (accountItem === AccountTypes.RRIF) {
      return {
        weight: 11,
        type: accountItem,
      };
    }
    return {
      weight: 15,
      type: accountItem,
    };
  });
  return weightedList.sort((a, b) => a.weight - b.weight);
};

export const CreateAccountForGoalVisual = ({
  options, continueFunc, accountType,
  suggestedTypes, otherTypes,
  workflowCompletion, loading, saving = false,
}: {
  options: any, accountType: string, continueFunc: (createAccountForGoalArgs: CreateAccountForGoalArgs) => Promise<void>,
  suggestedTypes: AccountTypes[], otherTypes: AccountTypes[],
  workflowCompletion?: any,
  loading: boolean, saving?: boolean
}) => {
  const { t } = useTranslation(['client', 'accountTypes', 'accountsDetail']);
  const { sendAnalytic } = useContext(AnalyticsContext);
  const { sys } = useThemeTokens();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedComponent, setSelectedComponent] = useState<'SUGGESTED' | 'OTHER' | 'DEFAULT'>('DEFAULT');
  const { workflowData, setWorkflowData } = useContext(WorkflowContext);
  const [suggestedList, setSuggestedList] = useState<string[]>([]);
  const [otherList, setOtherList] = useState<string[]>([]);

  useEffect(() => {
    const bySearchTerm = (type: string) => {
      const techHaystack = type.toUpperCase();
      const translatedHaystack = t(`accountTypes:${type}`).toUpperCase();
      const needle = searchQuery.trim().toUpperCase();
      return techHaystack.includes(needle) || translatedHaystack.includes(needle);
    };

    setSuggestedList(suggestedTypes.filter(bySearchTerm));
    setOtherList(otherTypes.filter(bySearchTerm));
  }, [searchQuery, t, suggestedTypes, otherTypes]);

  const onTileSelect = async (e: any) => {
    if (loading || saving) return;
    setSelectedComponent('SUGGESTED');
    await setWorkflowData({
      ...workflowData,
      currentAccountType: e.target.value,
    });
    sendAnalytic(
      ovAnalyticsEvents.workflowsCreateAccountForGoalContinueButtonSelect,
      {
        workflowStepTitle: options?.title,
        workflowId: workflowCompletion?.workflow?.id,
        workflowName: workflowCompletion?.workflow?.name,
        activeWorkflowCompletionId: workflowCompletion?.id,
        objectId: workflowCompletion?.objectId,
        objectType: workflowCompletion?.objectType,
      },
    );
    await continueFunc({ accountType: e.target.value });
  };

  if (!options) return (<></>);

  const searchQueryExists = searchQuery.trim() !== '';

  const asValueLabelSubtitle = (a: AccountListItem) => ({
    value: a.type,
    label: t(`accountTypes:${a.type}`),
    subtitle: options.displayAccountDefinitions ? t(`accountsDetail:accountDescriptions.${a.type}`) : undefined,
  });

  return (
    <Form>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>
        <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.subtitle)}</ReactMarkdown>
      </Typography>
      {!loading && <>
        <TextField
          value={searchQuery}
          data-testid="search-accounts"
          label=''
          fullWidth
          size='small'
          placeholder={t('accountsDetail:searchAccounts')}
          onChange={(e) => (setSearchQuery(e.target.value))}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          inputProps={{ 'data-testid': 'fulltextsearch-select' }}
          sx={{ mb: 2 }}
          disabled={saving}
        />

        {!searchQueryExists && suggestedList.length > 0
          && <Typography variant='headingSmall' fontWeight={900} sx={{ mb: 3 }}>{translateBackend(options.commonTypesLabel)}</Typography>
        }
        <SelectionTile
          testId={'account-type-suggested'}
          onChange={onTileSelect}
          value={accountType}
          options={generateWeightedList(suggestedList).map(asValueLabelSubtitle)}
          type='completion'
          selectedComponent={selectedComponent}
          component='SUGGESTED'
          disabled={saving}
        />

        {!searchQueryExists && suggestedList.length > 0
          && <Typography variant='headingSmall' fontWeight={900} sx={{ mb: 3, mt: 3 }}>{t('accountsDetail:otherAccountTypes')}</Typography>
        }
        <SelectionTile
          testId="account-type"
          onChange={onTileSelect}
          value={accountType}
          options={generateWeightedList(otherList).map(asValueLabelSubtitle)}
          type='completion'
          selectedComponent={selectedComponent}
          component='OTHER'
          disabled={saving}
        />

        {(suggestedList.length === 0 && otherList.length === 0)
          && <Typography variant='bodyLarge' sx={{ color: sys.color.onSurfaceVariant }}>{t('accountsDetail:noAccountOptions')}</Typography>
        }
      </>
      }
    </Form>
  );
};

export default CreateAccountForGoalVisual;

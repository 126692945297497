import { useState, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { UserContext } from 'providers/userContextProvider';
import { AddModelPortfolioDetailsVisual, ModelType } from './addModelPortfolioDetails.visual';
import { FETCH_THEMES } from '../../../../../pages/buildModels/index';
import { WorkflowContext } from '../../workflowCompletion';
import { TranslatedString } from '../../../../../interfaces/shared';

interface ModelPortfolioData {
  modelType: ModelType;
  themeId: string;
  translatedName: TranslatedString;
  url?: string;
  translatedPortfolioDescription: TranslatedString;
  forecastedRateOfReturn?: number;
  forecastedStandardDeviation?: number;
  lowThresholdScore?: number;
  riskProfileName: TranslatedString;
  riskReturnLevel?: number;
  suggestedMaxScore?: number;
  suggestedMinScore?: number;
}

interface Props {
  options: {
    [key: string]: any;
  };
  userId: string;
  onNext: () => void;
  stepLoading: boolean;
  workflowCompletion?: {
    [key: string]: any;
  };
}

export const AddModelPortfolioDetails = ({
  options,
  userId,
  onNext,
  stepLoading,
  workflowCompletion,
}: Props) => {
  const { activeOrganization } = useContext(UserContext);
  const { workflowData, setWorkflowData, updateWorkflowContext } = useContext(WorkflowContext);
  const [modelPortfolioData, setModelPortfolioData] = useState<ModelPortfolioData>({
    modelType: workflowData.modelPortfolioDetails?.modelType ?? ModelType.STANDARD,
    themeId: workflowData.modelPortfolioDetails?.themeId ?? '',
    translatedName: workflowData.modelPortfolioDetails?.translatedName ?? { en: '', fr: '' },
    translatedPortfolioDescription: workflowData.modelPortfolioDetails?.translatedPortfolioDescription ?? { en: '', fr: '' },
    riskProfileName: workflowData.modelPortfolioDetails?.riskProfileName ?? { en: '', fr: '' },
    ...workflowData.modelPortfolioDetails,
  });

  const { data: themesData } = useQuery(FETCH_THEMES, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization?.id,
        },
        pagination: {
          perPage: 25,
          sortField: 'createdAt',
          sortDesc: false,
        },
      },
    },
    skip: !activeOrganization?.id,
  });

  const handleContinue = async () => {
    try {
      await setWorkflowData({
        ...workflowData,
        modelPortfolioDetails: {
          ...modelPortfolioData,
          themeId: modelPortfolioData.themeId,
        },
      });

      if (updateWorkflowContext) {
        await updateWorkflowContext();
      }

      onNext();
    } catch (error) { /* empty */ }
  };

  return (
    <AddModelPortfolioDetailsVisual
      modelPortfolioData={modelPortfolioData}
      options={options}
      loading={stepLoading}
      continueFunc={handleContinue}
      themes={themesData?.fetchThemes?.themes ?? []}
      workflowCompletion={workflowCompletion}
      setModelPortfolioData={setModelPortfolioData}
    />
  );
};

export default AddModelPortfolioDetails;

import { gql, useQuery } from '@apollo/client';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  TextField, Checkbox, CircularProgress, Table, TableBody, TableCell, TableRow, Tooltip,
} from '../../2-component';
import { Typography, Box } from '../../1-primative';

export const FETCH_PERMISSIONS = gql`
  query fetchPermissions {
    fetchPermissions {
      permissions {
        value
        translatedDescription {
          en
        }
      }
    }
  }
`;

export const PermissionsSelect = ({
  permissions,
  onChange,
  scopedPermissions,
}: {
  permissions: string[],
  onChange: (permission: string, checked: boolean) => void,
  scopedPermissions?: string[],
}) => {
  const { t } = useTranslation('orgSettings');
  const { loading, error, data } = useQuery(FETCH_PERMISSIONS);
  const [grouping, setGrouping] = useState<any>({});
  const [filteredGrouping, setFilteredGrouping] = useState<any>({});
  const [searchText, setSearchText] = useState('');
  if (error) (<Typography>Error</Typography>);
  if (loading) (<CircularProgress />);

  useEffect(() => {
    if (data) {
      const ps = scopedPermissions || data.fetchPermissions.permissions.map(({ value }: { value: string }) => value);
      const groups: any = {};

      ps.forEach((permission: string) => {
        const permSplit = permission.split(':');
        if (!groups[permSplit[1].replace('_basic', '')]) {
          groups[permSplit[1].replace('_basic', '')] = [];
        }
        groups[permSplit[1].replace('_basic', '')].push(permission);
      });
      setGrouping(groups);
      setFilteredGrouping(groups);
    }
  }, [data, scopedPermissions]);

  const filterGroupings = (searchTerm: string) => {
    const filteredGroupingObj = Object.entries(grouping).reduce((acc: any, [key, values]) => {
      // Check if the key matches the search term
      if (key.toLowerCase().includes(searchTerm)) {
        acc[key] = values;
      }
      return acc;
    }, {});
    setFilteredGrouping(filteredGroupingObj);
  };
  return (
    <Box width={'100%'}>
      <Box>
        <TextField
          value={searchText}
          placeholder={t('search')}
          onChange={(event: any) => {
            setSearchText(event.target.value);
            filterGroupings(event.target.value);
          }}
          testId='permission-search'
          label={t('roleModal.permissions')}
          leadingIcon='search'
          fullWidth
          sx={{ mb: 1 }}
        />
      </Box>
      <Box>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>{t('orgSettings:roleModal.permissionsTable.read')}</TableCell>
              <TableCell>{t('orgSettings:roleModal.permissionsTable.write')}</TableCell>
              <TableCell>{t('orgSettings:roleModal.permissionsTable.transition')}</TableCell>
            </TableRow>
            {Object.keys(filteredGrouping).map((key: string) => (
              <TableRow key={key}>
                <TableCell>
                  <Box display='flex' alignItems='center' gap={1}>
                    {key}
                    {(key === 'form_template' || key === 'advanced_organization_settings') && (
                      <Tooltip title={t('orgSettings:roleModal.formTemplateWarning')} placement='right-end'>
                        <InfoIcon sx={{ position: 'relative', top: '5px', cursor: 'pointer' }} fontSize='small' />
                      </Tooltip>
                    )}
                  </Box>
                </TableCell>
                <TableCell>
                  <Checkbox
                    onChange={(checked: boolean) => onChange(filteredGrouping[key].find((x: string) => x.includes('read')), checked)}
                    disabled={!filteredGrouping[key].find((x: string) => x.includes('read'))}
                    checked={permissions.includes(filteredGrouping[key].find((x: string) => x.includes('read')))}
                  />
                </TableCell>
                <TableCell>
                  <Checkbox
                    onChange={(checked: boolean) => onChange(filteredGrouping[key].find((x: string) => x.includes('write')), checked)}
                    disabled={!filteredGrouping[key].find((x: string) => x.includes('write'))}
                    checked={permissions.includes(filteredGrouping[key].find((x: string) => x.includes('write')))}
                  />
                </TableCell>
                <TableCell>
                  <Checkbox
                    onChange={(checked: boolean) => onChange(filteredGrouping[key].find((x: string) => x.includes('transition')), checked)}
                    disabled={!filteredGrouping[key].find((x: string) => x.includes('transition'))}
                    checked={permissions.includes(filteredGrouping[key].find((x: string) => x.includes('transition')))}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

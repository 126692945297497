import {
  Typography, Card, CardContent, Grid, FormGroup, FormControlLabel, Switch, TextField, Button, IconButton, Menu, MenuItem, InputAdornment,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import { Chart, ArcElement } from 'chart.js';
import { round, isNil } from 'lodash';
import TranslatableString from '../../../components/inputs/translatableString';
import ThemeSelect from '../../../components/inputs/themeSelect';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { FinancialProduct } from '../../../interfaces';
import { FETCH_MODEL_PORTFOLIOS } from './themeModels';
import { FETCH_MODEL } from './editModel';

Chart.register(ArcElement);

const UPDATE_MODEL = gql`
  mutation updateModelPortfolio($input: UpdateModelPortfolioInput!) {
    updateModelPortfolio(input: $input) {
      modelPortfolio {
        id
      }
    }
  }
`;

export const TRANSITION_MODEL = gql`
  mutation transitionModelPortfolio($input: TransitionModelPortfolioInput!) {
    transitionModelPortfolio(input: $input) {
      modelPortfolio {
        id
      }
    }
  }
`;

const EditDetails = ({ model, setModel }: { model: FinancialProduct, setModel: (v: FinancialProduct) => void }) => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation('buildModels');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const canWrite = (): boolean => (permissions.includes('write:model_portfolios') || false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const refetchModelPortfoliosVars = {
    input: {
      filter: {
        themeId: model.theme?.id,
        organizationId: activeOrganization.id,
      },
    },
  };

  const [updateModel, { loading }] = useMutation(UPDATE_MODEL, {
    variables: {
      input: {
        modelPortfolioId: model.id,
        translatedName: { en: model.translatedName?.en, fr: model.translatedName?.fr },
        themeId: model.theme?.id,
        suggestable: model.suggestable,
        forecastedRateOfReturn: model.forecastedRateOfReturn ? round(model.forecastedRateOfReturn, 2) : undefined,
        forecastedStandardDeviation: model.forecastedStandardDeviation ? round(model.forecastedStandardDeviation, 2) : undefined,
        lowThresholdScore: !isNil(model.lowThresholdScore) ? round(model.lowThresholdScore, 2) : undefined,
        suggestedMinScore: !isNil(model.suggestedMinScore) ? round(model.suggestedMinScore, 2) : undefined,
        suggestedMaxScore: model.suggestedMaxScore ? round(model.suggestedMaxScore, 2) : undefined,
        riskReturnLevel: model.riskReturnLevel ? round(model.riskReturnLevel, 2) : undefined,
        url: model.url,
      },
    },
  });

  const [transitionModel] = useMutation(TRANSITION_MODEL, {
    refetchQueries: [
      { query: FETCH_MODEL_PORTFOLIOS, variables: refetchModelPortfoliosVars },
      { query: FETCH_MODEL, variables: { input: { modelPortfolioId: model.id } } },
    ],
  });

  const transition = (name: string) => {
    transitionModel({ variables: { input: { modelPortfolioId: model.id, transition: name } } });
  };

  const [themeHasBeenChanged, setThemeHasBeenChanged] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!themeHasBeenChanged) {
      // refetch the one specific theme
      updateModel({ refetchQueries: [{ query: FETCH_MODEL_PORTFOLIOS, variables: refetchModelPortfoliosVars }] });
    } else {
      setThemeHasBeenChanged(false);
      // refetch all themes
      updateModel({ refetchQueries: [FETCH_MODEL_PORTFOLIOS] });
    }
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2} component='form' onSubmit={handleSubmit} justifyContent='flex-end'>
          <Grid item xs={6}><Typography variant='subtitle1'>{t('details')}</Typography></Grid>
          <Grid item xs={6}>
            {permissions.includes('write:model_portfolios') && (
              <IconButton sx={{ float: 'right' }} onClick={handleClick}><MoreVertIcon /></IconButton>
            )}
          </Grid>
          <Grid item xs={12}>
            <TranslatableString
              onChange={(value) => setModel({ ...model, translatedName: value })}
              value={model.translatedName}
              label={t('edit.name')}
              readonly={!canWrite()}
            />
          </Grid>
          <Grid item md={8}>
            <ThemeSelect
              value={model.theme?.id || ''}
              onChange={(newValue) => { setModel({ ...model, theme: { id: newValue } }); setThemeHasBeenChanged(true); }}
              label={t('edit.theme')}
              readonly={!canWrite()}
            />
          </Grid>
          <Grid item md={4}>
            <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
              <FormControlLabel
                control={<Switch
                  checked={model.suggestable}
                  disabled={!canWrite()}
                  onChange={async (event) => setModel({ ...model, suggestable: event.target.checked })}
                />}
                label={t('edit.suggestable')}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type='number'
              onChange={(e) => setModel({ ...model, forecastedRateOfReturn: parseFloat(e.target.value) })}
              value={model.forecastedRateOfReturn === null ? '' : model.forecastedRateOfReturn}
              label={t('edit.forecastedRateOfReturn')}
              InputProps={{
                readOnly: !canWrite(),
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type='number'
              onChange={(e) => setModel({ ...model, forecastedStandardDeviation: parseFloat(e.target.value) })}
              value={model.forecastedStandardDeviation === null ? '' : model.forecastedStandardDeviation}
              label={t('edit.forecastedStandardDeviation')}
              InputProps={{
                readOnly: !canWrite(),
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              type='number'
              onChange={(e) => setModel({ ...model, lowThresholdScore: parseFloat(e.target.value) })}
              value={model.lowThresholdScore === null ? '' : model.lowThresholdScore}
              label={t('edit.lowThresholdScore')}
              InputProps={{
                readOnly: !canWrite(),
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              type='number'
              onChange={(e) => setModel({ ...model, suggestedMinScore: parseFloat(e.target.value) })}
              value={model.suggestedMinScore === null ? '' : model.suggestedMinScore}
              label={t('edit.suggestedMinScore')}
              InputProps={{
                readOnly: !canWrite(),
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              type='number'
              onChange={(e) => setModel({ ...model, suggestedMaxScore: parseFloat(e.target.value) })}
              value={model.suggestedMaxScore === null ? '' : model.suggestedMaxScore}
              label={t('edit.suggestedMaxScore')}
              InputProps={{
                readOnly: !canWrite(),
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              type='number'
              onChange={(e) => setModel({ ...model, riskReturnLevel: parseFloat(e.target.value) })}
              value={model.riskReturnLevel === null ? '' : model.riskReturnLevel}
              label={t('edit.riskReturnLevel')}
              InputProps={{
                readOnly: !canWrite(),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              onChange={(value) => setModel({ ...model, url: value.target.value })}
              value={model.url}
              label={t('edit.url')}
            />
          </Grid>
          {canWrite() && (
            <Grid item>
              <Button type='submit' disabled={loading} fullWidth variant='contained'>
                {t('edit.save')}
              </Button>
            </Grid>
          )}
        </Grid>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {model.state !== 'ACTIVE' && (
            <MenuItem onClick={() => transition('activate')}>
              {t('transition.activate')}
            </MenuItem>
          )}
          {model.state !== 'DRAFT' && (
            <MenuItem onClick={() => transition('draft')}>
              {t('transition.draft')}
            </MenuItem>
          )}
          {model.state !== 'ARCHIVED' && (
            <MenuItem onClick={() => transition('archive')}>
              {t('transition.archive')}
            </MenuItem>
          )}
        </Menu>
      </CardContent>
    </Card>
  );
};

export default EditDetails;

import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TranslatableTextField } from '../../../../2-component';
import { CreateNewModal } from '../../../../3-pattern';
import { TranslatedString } from '../../../../../interfaces';

const CREATE_WORKFLOW = gql`
  mutation createWorkflow($input: CreateWorkflowInput!) {
    createWorkflow(input: $input) {
      workflow {
        id
      }
    }
  }
`;

const NewWorkflow = ({ afterCreate, defaultOrg }: { afterCreate: () => void, defaultOrg?: string }) => {
  const { t } = useTranslation(['orgSettings']);
  const [workflow, setWorkflow] = useState({
    name: { en: '' } as TranslatedString,
    steps: [],
    objectType: 'USER',
    organizationId: defaultOrg ?? '',
  });

  const [createWorkflow, { loading }] = useMutation(CREATE_WORKFLOW, {
    variables: {
      input: workflow,
    },
  });

  const create = async (event: any) => {
    await createWorkflow();
    afterCreate();
  };
  const disabled = !(workflow.name.en || !workflow.organizationId);
  return (
    <CreateNewModal loading={loading} disabled={disabled} title={t('workflowModal.title')} onSubmit={create} maxWidth='md'>
      <TranslatableTextField
        label={t('workflowModal.name')}
        value={workflow.name}
        onChange={(value) => setWorkflow({ ...workflow, name: value })} />
    </CreateNewModal>
  );
};

export default NewWorkflow;

import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Add } from '@mui/icons-material';
import { useLocation, useParams } from 'react-router-dom';
import {
  Typography, Box, Grid, Skeleton,
} from '../../1-primative';
import { PageObjectType } from '../../5-page';
import SubAccountItem from '../subAccountItem/subAccountItem';
import {
  EmptyStateAlt, IconButton, MenuItem, SelectField,
} from '../../2-component';
import { CreateSubAccountWorkflow } from '../createSubAccountWorkflow/createSubAccountWorkflow';
import { UserContext } from '../../../providers/userContextProvider';
import { CreateSubAccountWizard } from '../../../components/wizards/createSubAccountWizard/wizard';
import { getUserIdFromPath } from '../../../util/getUserIdFromPath';
import { FilterModal } from '../../3-pattern';
import { AffiliationTypes, SubAccount, SubAccountStates } from '../../../interfaces';
import { generateClientNameString } from '../../../util';

export const FETCH_SUB_ACCOUNTS = gql`
  query fetchSubAccounts($input: FetchSubAccountsInput!) {
    fetchSubAccounts(input: $input) {
      totalCount
      subAccounts {
        id
        state
        statistics { marketValueCents simpleReturnAmount simpleReturnPercent }
        theme { key translatedName { en fr } iconKey }
        goal { name }
        account {
          id
          state
          type
          custodianAccountNumber
          householdClientGroup { id name }
          user { id firstName }
          affiliations { type user { id firstName } }
        }
        allowClientDeposits
      }
    }
  }
`;

const baseSimplifiedStatusFilter = {
  ACTIVE: true,
  PENDING: true,
  CLOSED: false,
};

const DEFAULT_SUB_ACCOUNT_ITEM_HEIGHT = '72px';

export const SubAccounts = ({
  objectType, objectId, singleColumn = false, options,
}: {
  objectType: PageObjectType, objectId: string, singleColumn?: boolean, options: any,
}) => {
  const { activeEntity } = useContext(UserContext);
  const { userId: paramsUserId, clientGroupId } = useParams();
  const { pathname: path } = useLocation();
  const { t } = useTranslation(['client, accountsDetail']);

  const [open, setOpen] = useState(false);
  const [subAccounts, setSubAccounts] = useState<any[]>([]);
  const [filterBySimplifiedStatus, setFilterBySimplifiedStatus] = useState<Record<string, boolean>>(baseSimplifiedStatusFilter);
  const [filterByState, setFilterByState] = useState<Record<string, boolean>>(() => (options.simplifiedSubAccountStatus ? ({
    [SubAccountStates.ACTIVE]: baseSimplifiedStatusFilter.ACTIVE,
    [SubAccountStates.AWAITING_APPROVAL]: baseSimplifiedStatusFilter.PENDING,
    [SubAccountStates.INACTIVE]: baseSimplifiedStatusFilter.CLOSED,
  }) : ({
    [SubAccountStates.ACTIVE]: options.filterForActive,
    [SubAccountStates.AWAITING_APPROVAL]: options.filterForAwaitingApproval,
    [SubAccountStates.INACTIVE]: false,
  })));

  const userId = paramsUserId ?? activeEntity?.id;
  const isHoushold = clientGroupId && !paramsUserId;
  const { loading, refetch } = useQuery(FETCH_SUB_ACCOUNTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        filter: {
          ...([PageObjectType.GOAL].includes(objectType) && {
            goalId: objectId,
            userId: getUserIdFromPath({ userId: paramsUserId, clientGroupId, path }),
          }),
          ...([PageObjectType.ACCOUNT].includes(objectType) && { accountId: objectId }),
        },
        pagination: { perPage: 1000 },
      },
    },
    onCompleted: (e) => setSubAccounts([...e.fetchSubAccounts.subAccounts]),
  });

  const setSimplifiedFilter = (value: any) => {
    const newFilterBySimplifiedStatus = Object.fromEntries(Object.keys(filterBySimplifiedStatus).map((key) => [key, value.includes(key)]));
    setFilterBySimplifiedStatus({ ...newFilterBySimplifiedStatus });
    setFilterByState({
      ACTIVE: newFilterBySimplifiedStatus.ACTIVE,
      AWAITING_APPROVAL: newFilterBySimplifiedStatus.PENDING,
      INACTIVE: newFilterBySimplifiedStatus.CLOSED,
    });
  };

  const statesShown = Object.keys(filterByState).filter((key) => filterByState[key]);
  const filteredSubAccounts = subAccounts.filter((x: any) => statesShown.includes(x.state));
  const subAccountsSorted = filteredSubAccounts.sort((a, b) => (b.statistics?.marketValueCents ?? 0) - (a.statistics?.marketValueCents ?? 0));

  const getSequenceNumber = (subAccount: SubAccount, id: string): number => {
    const sequenceMap: Record<string, number> = {
      [subAccount.account.user.id]: 0,
    };

    subAccount.account.affiliations?.forEach((affiliate, index) => {
      if (!(affiliate.user.id in sequenceMap)) {
        sequenceMap[affiliate.user.id] = index + 1;
      }
    });

    return sequenceMap[id];
  };

  const getTags = (subAccount: SubAccount) => {
    if (clientGroupId) {
      const tags = [{
        sequenceNumber: getSequenceNumber(subAccount, subAccount.account.user.id),
        text: generateClientNameString(subAccount.account.user),
      }];

      const joint = subAccount.account.affiliations?.find((affiliate: any) => affiliate.type === AffiliationTypes.Joint);

      if (joint) {
        tags.push({
          sequenceNumber: getSequenceNumber(subAccount, joint.user.id),
          text: generateClientNameString(joint.user),
        });
      }

      return tags;
    }

    return undefined;
  };

  const HeaderIcons = () => (
    <Box display='flex'>
      {options.filterEnabled && (
        <Box>
          <FilterModal
            testIds={{ dialogOpenButton: 'filter-modal-open-button', dialogSubmitButton: 'filter-modal-submit-button', dialogCloseButton: 'filter-modal-close-button' }}
            filterExists={true}
          >
            <SelectField
              fullWidth
              label={t('accountsDetail:state')}
              value={options.simplifiedSubAccountStatus
                ? Object.keys(filterBySimplifiedStatus).filter((key) => filterBySimplifiedStatus[key])
                : Object.keys(filterByState).filter((key) => filterByState[key])
              }
              multiple
              onChange={(e: any) => {
                options.simplifiedSubAccountStatus
                  ? setSimplifiedFilter(e.target.value)
                  : setFilterByState(Object.fromEntries(Object.keys(filterByState).map((key) => [key, e.target.value.includes(key)])));
              }}
            >
              {options.simplifiedSubAccountStatus ? (
                Object.keys(filterBySimplifiedStatus).map((state, index) => (
                  <MenuItem key={index} value={state}>{t(`client:subAccountState.${state}`)}</MenuItem>
                ))
              ) : (
                [
                  ...(options.filterForActive) ? [SubAccountStates.ACTIVE] : [],
                  ...(options.filterForAwaitingApproval) ? [SubAccountStates.AWAITING_APPROVAL] : [],
                  ...(options.filterForInActive) ? [SubAccountStates.INACTIVE] : [],
                ].map((state, index) => <MenuItem key={index} value={state}>{t(`client:subAccountState.${state}`)}</MenuItem>))
              }
            </SelectField>
          </FilterModal>
        </Box>
      )}
      {options.allowNewSubAccountsToBeCreated && (
        <>
          <IconButton data-testid='add-subaccount-button' onClick={() => setOpen(true)}><Add /></IconButton>
          {open && (
            isHoushold ? (
              <CreateSubAccountWizard forId={objectId} forObject={objectType as 'GOAL' | 'ACCOUNT'} handleClose={() => setOpen(false)} />
            ) : (
              <CreateSubAccountWorkflow open={open} setOpen={setOpen} objectId={userId || ''} objectType={objectType} refetch={refetch} goalId={objectId} />
            )
          )}
        </>
      )}
    </Box>
  );

  return (
    <>
      <Box display="flex" justifyContent="space-between" marginBottom="9px">
        <Box display="flex" alignItems='center'>
          <Typography variant="headingSmall">{t('client:subAccountsSummary.header')}</Typography>
        </Box>
        <HeaderIcons/>
      </Box>
      <Grid container spacing={2}>
        {loading ? (
          <Grid item xs={12} sm={singleColumn ? 12 : 6}><Skeleton width='100%' height='114px' variant='rectangular' /></Grid>
        ) : subAccountsSorted.map((subAccount) => (
          <Grid item xs={12} sm={singleColumn || subAccountsSorted.length === 1 ? 12 : 6} key={subAccount.id}>
            <SubAccountItem
              objectType={isHoushold ? PageObjectType.HOUSEHOLD : objectType}
              subAccount={subAccount}
              tags={getTags(subAccount)}
              options={options}
              showSimplifiedState={options.simplifiedSubAccountStatus}
            />
          </Grid>
        ))}
        {!loading && subAccountsSorted.length === 0 && (
          <Grid item xs={12}>
            <EmptyStateAlt
              data-testid='accounts-card-empty-state'
              sx={{ height: DEFAULT_SUB_ACCOUNT_ITEM_HEIGHT }}
              title={t('client:subAccountsSummary.noSubAccounts')}
            />
          </Grid>
        )}
      </Grid>
  </>
  );
};

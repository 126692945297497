/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import { CurrencyCodes } from '@onevesthq/ov-enums';
import dayjs from 'dayjs';
import { isNil } from 'lodash/fp';
import {
  useContext, useEffect, useState,
} from 'react';
import { ScriptableContext } from 'chart.js';
import convert from 'color-convert';
import {
  Typography, Box, Grid, Skeleton,
} from '../../../1-primative';
import {
  Card, CardContent, CircularProgress, EmptyStateHeaderTitle, LineChart,
} from '../../../2-component';
import { useLocalization } from '../../../../util/useLocalization';
import { formatMoneyValue, formatPercentValue } from '../../../../util';
import { ObjectType } from '../../../../providers/statsHooks';
import ArrowDownRightRed from '../../../../assets/images/icons-outline/arrow-down-right-red.svg';
import ArrowUpRightGreen from '../../../../assets/images/icons-outline/arrow-up-right-green.svg';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';

import EmptyChart from '../../../../assets/images/custom/emptyChart.svg';
import { translateBackend } from '../../../../assets/i18n/config';
import InfoButton from '../../../2-component/infoButton/infoButton';
import MarketValueIndicatorIcon from '../../../../assets/images/market-value-indicator-icon.svg';
import { PageObjectType } from '../../../5-page';
import { UserContext } from '../../../../providers/userContextProvider';
import { ValueSuperscriptedCents } from '../../../3-pattern';
import { useGlobalToast } from '../../../../providers/globalToastProvider';
import { useLineChartTokens } from '../../../2-component/lineChart/lineChart.tokens';
import { RateOfReturnInformation } from '../../widgets/rateOfReturnInformation';
import { useClientReportContext } from '../../../../providers/clientReportContextProvider';
import { useHistoricalStats } from '../../../../providers/historicalStatsHooks';
import { PAGE_HEIGHT, PrintContext } from '../../../5-page/viewClientReport/components/printReport';

const objectTypeMapping = (objectType: PageObjectType): ObjectType => {
  const types = {
    [PageObjectType.INDIVIDUAL]: ObjectType.USER,
    [PageObjectType.NON_INDIVIDUAL]: ObjectType.USER,
    [PageObjectType.HOUSEHOLD]: ObjectType.CLIENT_GROUP,
    [PageObjectType.GOAL]: ObjectType.GOAL,
    [PageObjectType.SUB_ACCOUNT]: ObjectType.SUB_ACCOUNT,
    [PageObjectType.ACCOUNT]: ObjectType.ACCOUNT,
  };
  return types[objectType];
};

export const MarketValueChart = ({
  type, id, needUpdate, onlyUserId, options = {}, print = false, i = 0,
}: { type: PageObjectType, id: string, needUpdate?: number, onlyUserId?: string, options?: any, print?: boolean, i?: number }) => {
  const { timePeriod } = useClientReportContext();
  const netContributionIndicatorImageSize = 16;
  const { t } = useTranslation('client');
  const { showToast } = useGlobalToast();
  const { localizedDate } = useLocalization();
  const [hideGraph, setHideGraph] = useState(false);
  const { sys, comp } = useThemeTokens(useLineChartTokens());
  const [startDate, setStartDate] = useState<string | undefined>(dayjs(timePeriod.startDate).format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState<string | undefined>(dayjs(timePeriod.endDate).format('YYYY-MM-DD'));
  const { activeCurrency, activeOrganization } = useContext(UserContext);
  const [mouseIsOverChart, setMouseIsOverChart] = useState<boolean>(false);
  const [activeMarketValueCents, setActiveMarketValueCents] = useState<number | undefined>();
  const [activeNetContributionCents, setActiveNetContributionCents] = useState<number | undefined>();
  const [newPage, setNewPage] = useState(false);
  const { setOptions, options: printOptions } = useContext(PrintContext);

  const dateTimeFormatOption: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC',
  };

  const showNetContributionOnHover = options.displayNetContributions && mouseIsOverChart && activeNetContributionCents !== undefined;
  const getNetContributionWithCurrency = (value: number, currency = 'USD'): string => new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  }).format(value / 100);

  const tooltipLine = {
    id: 'tooltipLine',
    afterDraw: (chart: any) => {
      const chartPointNetContributionIcon = new Image();
      chartPointNetContributionIcon.src = MarketValueIndicatorIcon;
      if (chart.tooltip._active && chart.tooltip._active.length) {
        const ctx3 = chart.ctx;
        ctx3.save();
        const activePoint = chart.tooltip._active[0];
        const netContributionPoint = chart.tooltip._active[1] || null;

        // Get the index of the active tooltip
        const { index } = activePoint;
        const dateLabel = chart.data.labels[index];

        // Market Value Cents
        const hoveredMarketValue = chart.data.datasets[0].data[index];
        setActiveMarketValueCents(hoveredMarketValue);
        if (options?.displayNetContributions) {
          const hoveredNetContribution = chart.data.datasets[1].data[index];
          setActiveNetContributionCents(hoveredNetContribution);
        }

        ctx3.beginPath();
        ctx3.setLineDash([2, 2]);

        ctx3.moveTo(activePoint.element.x, chart.chartArea.top + 5);
        ctx3.lineTo(activePoint.element.x, activePoint.element.y);
        ctx3.lineWidth = 1;
        ctx3.strokeStyle = comp.lineChart.secondaryLine;
        ctx3.stroke();

        // Add text above the dashed line
        const width = ctx3.canvas.offsetWidth;
        ctx3.font = `14px ${sys.font.base}`;
        ctx3.fillStyle = comp.lineChart.secondaryLine;
        ctx3.textAlign = activePoint.element.x <= 42 ? 'left' : activePoint.element.x >= width - 42 ? 'right' : 'center';
        ctx3.fillText(new Date(dateLabel).toLocaleDateString('en-US', dateTimeFormatOption), activePoint.element.x, chart.chartArea.top - 15);

        ctx3.restore();
        ctx3.beginPath();
        ctx3.setLineDash([2, 2]);

        ctx3.moveTo(activePoint.element.x, activePoint.element.y);
        ctx3.lineTo(activePoint.element.x, chart.chartArea.bottom);
        ctx3.lineWidth = 1;
        ctx3.strokeStyle = comp.lineChart.secondaryLine;
        ctx3.stroke();

        // Draw the net contribution icon on top of the line
        if (options.displayNetContributions && netContributionPoint) {
          ctx3.drawImage(
            chartPointNetContributionIcon,
            (netContributionPoint.element.x - 7), // Adjust positioning as needed
            (netContributionPoint.element.y - 7), // Adjust positioning as needed
            netContributionIndicatorImageSize, // Width of the image
            netContributionIndicatorImageSize, // Height of the image
          );
        }

        ctx3.restore();
      }
    },
  };
  const overrideLineOptions = {
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
  };

  useEffect(() => {
    setStartDate(dayjs(timePeriod.startDate).format('YYYY-MM-DD'));
    setEndDate(dayjs(timePeriod.endDate).format('YYYY-MM-DD'));
  }, [timePeriod]);

  const useCustodianData = [ObjectType.USER, ObjectType.ACCOUNT, ObjectType.CLIENT_GROUP].includes(objectTypeMapping(type)) && options.useExternalStatistics;
  const {
    statistics, loading, history, historyLoading, refetchAll,
  } = useHistoricalStats(
    {
      type: objectTypeMapping(type),
      id,
      startDate,
      endDate,
      onlyUserId,
      fetchHistory: options.displayMarketValueHistory,
      currency: activeCurrency,
    },
  );
  const custodianStatistics = {
    ...statistics,
  };

  useEffect(() => {
    if (!loading && !historyLoading) {
      const copy = [...printOptions];

      copy[i] = {
        heightLeftOnLastPage: (i === 0 ? PAGE_HEIGHT - 104 : (printOptions[i - 1]?.heightLeftOnLastPage || 0)) - 382,
        loading: false,
      };

      setOptions(copy);
      setNewPage((i === 0 ? PAGE_HEIGHT - 104 : (printOptions[i - 1]?.heightLeftOnLastPage || 0)) < 382);
    }
  }, [i, loading, historyLoading, setNewPage, setOptions]);

  /* When the needUpdate counter gets incremented */
  useEffect(() => {
    if (!loading && !historyLoading) refetchAll();
  }, [needUpdate]);

  useEffect(() => {
    if (useCustodianData && (custodianStatistics?.originalCurrenciesMissingRate ?? []).length > 0) {
      setHideGraph(true);
      showToast({ message: t('assetsOverview.missingExchangeRateMessage'), severity: 'error' });
    } else {
      setHideGraph(false);
    }
  }, [statistics.holdingsMissingRate]);

  const showTimeWeightedReturn = useCustodianData ? !!options.showTimeWeightedReturn && !isNil(custodianStatistics?.timeWeightedReturn) : !!options.showTimeWeightedReturn;
  const showMoneyWeightedReturn = useCustodianData ? !!options.showMoneyWeightedReturn && !isNil(custodianStatistics?.moneyWeightedReturn) : !!options.showMoneyWeightedReturn;
  const showSimpleRateOfReturn = useCustodianData ? !!options.showSimpleRateOfReturn && !isNil(custodianStatistics?.simpleReturnAmountCents) : !!options.showSimpleRateOfReturn;
  const showExchangeRates = useCustodianData
    ? (custodianStatistics?.foreignExchangeRates ?? []).length > 0 && !!options.displayFxRate
    : (statistics?.foreignExchangeRates ?? []).length > 0 && !!options.displayFxRate;
  const foreignExchangeRates = useCustodianData ? (custodianStatistics?.foreignExchangeRates ?? []) : (statistics?.foreignExchangeRates ?? []);

  const getChartHeight = (breakpoint: 'xs' | 'sm') => {
    let baseValue = breakpoint === 'xs' ? 141 : 101;
    if (showSimpleRateOfReturn) baseValue += 19;
    if (showTimeWeightedReturn || showMoneyWeightedReturn) baseValue += 28;
    if (!showSimpleRateOfReturn && !showTimeWeightedReturn && !showMoneyWeightedReturn) baseValue -= 4;

    return `calc(100% - ${baseValue}px)`;
  };
  const labels = history?.filter((x: any) => x.marketValueCents !== 0)?.map((x: any) => localizedDate(x.date)) ?? [];
  const rgb = convert.hex.rgb(comp.lineChart.color || '#000000');

  // this method pads (adds extra zeros) the net contribution cents array
  function getNetContributionsCentsArray(marketValueCentsArray: number[]): number[] {
    const netContributionCentsArray = history.filter((x: any) => x.marketValueCents !== 0).map((x: any) => x.netContributionCents);
    const lengthDifference = marketValueCentsArray.length - netContributionCentsArray.length;
    if (lengthDifference > 0) {
      return [...netContributionCentsArray, ...new Array(lengthDifference).fill(0)];
    }
    return netContributionCentsArray;
  }

  return (
    <Box sx={{ pageBreakBefore: newPage && i !== 0 ? 'always' : undefined, pt: newPage ? '40px' : 0 }}>
      <Card
        sx={{
          height: print ? '350px' : '450px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        fullWidthOnMobile
        variant={print ? 'outlined' : 'elevated'}
      >
        <CardContent>
          <Grid container justifyContent='space-between'>
            <Grid item xs={12}>
              <Box display='flex'>
                <Typography data-testid='market-value-chart-total-assets' sx={{ color: sys.color.outline, mr: 1 }} variant='labelLarge'>{t('assetsOverview.totalAssets')}</Typography>
                {(showTimeWeightedReturn || showMoneyWeightedReturn || showSimpleRateOfReturn || showExchangeRates) && (
                  <InfoButton
                    data-testid='market-value-chart-total-assets-info'
                    items={[
                      {
                        show: showTimeWeightedReturn,
                        information: translateBackend(options.timeWeightedReturnDefinition),
                        title: options.timeWeightedReturnLabel ? translateBackend(options.timeWeightedReturnLabel) : t('performanceMetricsModal.timeWeightedReturns'),
                      },
                      {
                        show: showMoneyWeightedReturn,
                        information: translateBackend(options.moneyWeightedReturnDefinition),
                        title: options.moneyWeightedReturnLabel ? translateBackend(options.moneyWeightedReturnLabel) : t('performanceMetricsModal.moneyWeightedReturns'),
                      },
                      {
                        show: showSimpleRateOfReturn,
                        information: translateBackend(options.simpleRateOfReturnDefinition),
                        title: t('performanceMetricsModal.simpleRateOfReturn'),
                      },
                      {
                        show: showExchangeRates,
                        information: <>
                          {foreignExchangeRates.map((elem: any) => <>
                            {t('performanceMetricsModal.fxRate.body', { from: elem.from, to: elem.to, rate: elem.rate })}<br />
                          </>)}
                        </>,
                        title: t('performanceMetricsModal.fxRate.title'),
                      },
                    ]}
                    title={t('performanceMetricsModal.title')}
                  />
                )}
              </Box>
              {loading ? (
                <>
                  <Skeleton width='200px' height='33px' variant='text' />
                  <Skeleton width='100px' height='19px' variant='text' />
                </>
              ) : (
                <>
                  <Box display='flex' gap={1}>
                    <ValueSuperscriptedCents
                      data-testid='market-value-chart-value-cent'
                      value={mouseIsOverChart ? activeMarketValueCents : statistics?.marketValueCents}
                      mainVariant='headingLarge'
                      centsVariant='titleLarge'
                      currency={activeCurrency as CurrencyCodes}
                      color={mouseIsOverChart ? comp.lineChart.color : undefined}
                    />
                    {activeOrganization.displayCurrency && <Typography sx={{ color: mouseIsOverChart ? comp.lineChart.color : undefined }} variant='titleLarge'>{`${activeCurrency}`}</Typography>}
                  </Box>
                  <Box minHeight="50px" maxHeight="50px" sx={{ marginTop: '0.25em' }} boxSizing="border-box">
                    {!showNetContributionOnHover && showSimpleRateOfReturn ? (
                        <Typography
                            data-testid='market-value-chart-simple-rate-of-return'
                            variant='bodySmall'
                            sx={{
                              color: (useCustodianData ? custodianStatistics.simpleReturnAmountCents : statistics.simpleReturnAmount) >= 0 ? sys.color.positive : sys.color.negative,
                            }}
                        >
                          <img
                              style={{ verticalAlign: 'bottom' }}
                              src={(useCustodianData ? custodianStatistics.simpleReturnAmountCents : statistics.simpleReturnAmount) >= 0 ? ArrowUpRightGreen : ArrowDownRightRed} alt="arrow-icon"
                          />
                          {
                            useCustodianData ? (
                              (custodianStatistics?.originalCurrenciesMissingRate ?? []).length === 0 ? formatMoneyValue(custodianStatistics.simpleReturnAmountCents) : '$ -'
                            ) : (
                              formatMoneyValue(statistics.simpleReturnAmount)
                            )
                          } ({
                          useCustodianData ? (
                            (custodianStatistics?.originalCurrenciesMissingRate ?? []).length === 0 ? formatPercentValue(custodianStatistics.simpleReturnPercent) : '- %'
                          ) : (
                            formatPercentValue(statistics.simpleReturnPercent)
                          )
                        })
                        </Typography>
                    ) : <Box sx={{ height: '1px' }} /> }
                    {!showNetContributionOnHover && (showTimeWeightedReturn || showMoneyWeightedReturn)
                        && <Box mt={1}>
                          <RateOfReturnInformation rateOfReturns={[
                            {
                              title: options.timeWeightedReturnLabel ? translateBackend(options.timeWeightedReturnLabel) : t('assetsOverview.timeWeightedRoRAbbreviation'),
                              stat: useCustodianData ? (
                                (custodianStatistics?.originalCurrenciesMissingRate ?? []).length === 0 ? formatPercentValue(custodianStatistics.timeWeightedReturn) : '-'
                              ) : (
                                formatPercentValue(statistics.timeWeightedReturn ?? 0)
                              ),
                              show: showTimeWeightedReturn,
                              testId: 'market-value-chart-time-weighted-rate-of-return',
                            },
                            {
                              title: options.moneyWeightedReturnLabel ? translateBackend(options.moneyWeightedReturnLabel) : t('assetsOverview.moneyWeightedRoRAbbreviation'),
                              stat: useCustodianData ? (
                                (custodianStatistics?.originalCurrenciesMissingRate ?? []).length === 0 ? formatPercentValue(custodianStatistics.moneyWeightedReturn) : '-'
                              ) : (
                                formatPercentValue(statistics.moneyWeightedReturn ?? 0)
                              ),
                              show: showMoneyWeightedReturn,
                              testId: 'market-value-chart-money-weighted-rate-of-return',
                            },
                          ]} />
                        </Box>
                    }
                    {showNetContributionOnHover
                      && <Box display="flex" alignItems="center">
                        <img src={MarketValueIndicatorIcon} height={`${netContributionIndicatorImageSize}px`} width={`${netContributionIndicatorImageSize}px`} alt="market-value-indicator" />
                        <Typography variant='bodyLarge' ml={sys.spacing.md}>
                          {`Net Contributions: ${getNetContributionWithCurrency(activeNetContributionCents ?? 0, activeCurrency as CurrencyCodes)}`}
                        </Typography>
                      </Box>
                    }
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
        </CardContent>
        <Box sx={{
          height: { xs: getChartHeight('xs'), sm: getChartHeight('sm') },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}>
          {historyLoading ? (<Box height='100%' display='flex' alignItems='center'><CircularProgress /></Box>) : (
            <Box sx={{ width: '100%', height: '100%' }}>
              {(history ?? []).length < 2 || hideGraph ? (
                <Box sx={{
                  width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
                }}>
                  <img
                    alt='empty-chart'
                    src={EmptyChart}
                    style={{ maxWidth: '300px' }}
                  />
                  <EmptyStateHeaderTitle sx={{ mt: 2 }} title={t('assetsOverview.emptyChart')} />
                </Box>
              ) : (
                <LineChart
                  labels={labels}
                  plugins={[tooltipLine]}
                  overrideOptions={overrideLineOptions}
                  overrideData={{
                    labels,
                    datasets: ([
                      { label: t('marketValue'), data: history.filter((x: any) => x.marketValueCents !== 0).map((x: any) => x.marketValueCents) },
                      ...options.displayNetContributions ? [
                        { label: t('netContribution'), data: getNetContributionsCentsArray(history.filter((x: any) => x.marketValueCents !== 0).map((x: any) => x.marketValueCents)) },
                      ] : [],
                    ]).map((x: any, index: number) => ({
                      fill: x?.label !== 'netContribution',
                      label: x?.label,
                      data: x?.data,
                      borderColor: x?.label === 'netContribution' ? (options.displayNetContributions && mouseIsOverChart ? comp.lineChart.secondaryLine : '#00000000') : comp.lineChart.color,
                      borderDash: x?.label === 'netContribution' ? [8, 4] : [],
                      borderWidth: x?.label === 'netContribution' ? 1.5 : undefined,
                      backgroundColor: (context: ScriptableContext<'line'>) => {
                        const { ctx } = context.chart;
                        const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                        gradient.addColorStop(0, `rgba(${rgb[0]},${rgb[1]},${rgb[2]},0.3)`);
                        gradient.addColorStop(1, `rgba(${rgb[0]},${rgb[1]},${rgb[2]},0)`);
                        return gradient;
                      },
                      tension: x?.label === 'netContribution' ? 0 : comp.lineChart.tension,
                    })),
                  }}
                  mouseMove={() => {
                    setMouseIsOverChart(true);
                  }}
                  mouseOut={() => {
                    setMouseIsOverChart(false);
                  }}
                />
              )}
            </Box>
          )}
        </Box>
      </Card>
    </Box>
  );
};

export default MarketValueChart;

import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Box, Grid } from '../../../../1-primative';
import {
  Dialog, DialogTitle, DialogContent, MenuItem, Button, TextField, DialogFooter, Form,
} from '../../../../2-component';
import { ActionContext } from '../../../../5-page';

const FETCH_HOUSEHOLD = gql`
  query fetchHousehold($clientGroupId: ObjectID!) {
    fetchClientGroup(clientGroupId: $clientGroupId) {
      clientGroup { id name }
    }
  }
`;

const UPDATE_HOUSEHOLD = gql`
  mutation updateClientGroup($input: UpdateClientGroupInput!) {
    updateClientGroup(input: $input) {
      clientGroup { id }
    }
  }
`;

export const EditHousehold = ({ objectId, onClose, options = {} }: { objectId: string, onClose: () => void, options?: any }) => {
  const { t } = useTranslation('client');
  const { refreshWidgets } = useContext(ActionContext);

  const [updated, setUpdated] = useState(false);
  const [householdName, setHouseholdName] = useState('');
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const { loading, refetch } = useQuery(FETCH_HOUSEHOLD, {
    variables: { clientGroupId: objectId },
    onCompleted: (e) => setHouseholdName(e.fetchClientGroup?.clientGroup.name),
  });

  const [updateHousehold] = useMutation(UPDATE_HOUSEHOLD, { variables: { input: { clientGroupId: objectId, name: householdName } } });

  const submit = async () => {
    setUpdated(false);
    await updateHousehold();

    if (refreshWidgets) refreshWidgets();
    refetch();
  };

  const onEditDialogClose = () => setIsEditDialogOpen(false);
  const onEditDialogOpen = () => {
    onClose();
    setIsEditDialogOpen(true);
  };

  return (
    <>
      {options.canEditHouseholdName && <MenuItem onClick={onEditDialogOpen}>{t('editHousehold.edit')}</MenuItem>}

      <Dialog open={isEditDialogOpen} onClose={onEditDialogClose} fullWidth maxWidth='xs'>
        <DialogTitle onClose={onEditDialogClose}>{t('editHousehold.editHousehold')}</DialogTitle>
        <Form onSubmit={submit}>
          <DialogContent>
            <Grid item spacing={2}>
              <TextField fullWidth data-testid='edit-household-name' label={t('editHousehold.name')} disabled={loading} value={householdName} error={!householdName} onChange={(e: any) => {
                setUpdated(true);
                setHouseholdName(e.target.value);
              }} />
            </Grid>
          </DialogContent>
          <DialogFooter>
            <Box display='flex' justifyContent='end'>
              <Button data-testid="confirm-button" label={t('client:update')} disabled={loading || !updated} type='submit' />
            </Box>
          </DialogFooter>
        </Form>
      </Dialog>
    </>
  );
};

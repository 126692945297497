import { useTranslation } from 'react-i18next';
import { sum } from 'lodash/fp';
import { TableTotalCell } from '../../../../2-component';
import { formatMoneyValue, formatPercentValue } from '../../../../../util';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';

export const AccountTotalCell = ({
  accounts, type, totalMarketValue, useExternalStatisticsEnabled, isFirst,
}: { accounts: any[], type: string, totalMarketValue: number, useExternalStatisticsEnabled?: boolean, isFirst?: boolean }) => {
  const { t } = useTranslation('client');
  const { sys } = useThemeTokens();
  const getUnrealizedGainLoss = () => {
    if (useExternalStatisticsEnabled) {
      return (
          <>
            {`${formatMoneyValue(sum(accounts.map((a: any) => a?.custodianStatistics?.simpleReturnAmountCents ?? 0)))}
             (${formatPercentValue(
              sum(accounts.map((a: any) => a?.custodianStatistics?.simpleReturnPercent ?? 0)),
            )})`}
          </>
      );
    }
    return (
        <>
          {`${formatMoneyValue(sum(accounts.map((a: any) => a.statistics.simpleReturnAmount)))}
          (${formatPercentValue(
            sum(accounts.map((a: any) => a.statistics.simpleReturnAmount)) / totalMarketValue,
          )})`}
        </>
    );
  };
  switch (type) {
    case 'holder':
      return (
        <TableTotalCell isFirst={isFirst}>{t('accountTable.total')}</TableTotalCell>
      );
    case 'nickname':
      return (
        <TableTotalCell isFirst={isFirst}></TableTotalCell>
      );
    case 'name':
      return (
        <TableTotalCell isFirst={isFirst}></TableTotalCell>
      );
    case 'accountNumber':
      return (
        <TableTotalCell isFirst={isFirst}></TableTotalCell>
      );
    case 'program':
      return (
        <TableTotalCell isFirst={isFirst}></TableTotalCell>
      );
    case 'unrealizedGainLoss':
      return (
        <TableTotalCell number isFirst={isFirst} sx={{
          textAlign: 'right',
          color: sum(accounts.map((a: any) => a.statistics.simpleReturnAmount)) >= 0 ? sys.color.positive : sys.color.negative,
          backgroundColor: '#FBFBFB',
          borderBottom: 'none',
        }}>
          {getUnrealizedGainLoss()}
        </TableTotalCell>
      );
    case 'percentOfTotal':
      return (
        <TableTotalCell isFirst={isFirst} right>100%</TableTotalCell>
      );
    case 'currentValue':
      return (
        <TableTotalCell isFirst={isFirst} right bold number>{formatMoneyValue(totalMarketValue)}</TableTotalCell>
      );
    default:
      return (
        <TableTotalCell isFirst={isFirst}>-</TableTotalCell>
      );
  }
};

import {
  Pagination,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { uniq } from 'lodash/fp';
import {
  Box, Grid, Skeleton,
} from '../../../../1-primative';

import { useClientContext } from '../../../../../pages/client';
import { useHouseholdContext } from '../../../../../pages/household';
import AccountItem from '../../../accountItem/accountItem';
import { Account, AffiliationTypes } from '../../../../../interfaces';
import { PageObjectType } from '../../../../5-page';
import { generateClientNameString } from '../../../../../util';

export const AccountCards = ({
  accounts,
  singleColumn,
  totalCount,
  page,
  setPage,
  loading,
  objectType,
  objectId,
  showSimplifiedState,
  useCustodianData,
  options,
}: {
  accounts: Account[],
  totalCount: number,
  singleColumn?: boolean,
  loading?: boolean,
  useCustodianData?: boolean,
  objectType: PageObjectType,
  objectId: string,
  page: number,
  options?: any,
  showSimplifiedState?: boolean;
  setPage: (page: number) => void,
}) => {
  const { t } = useTranslation(['client']);
  const clientContext = useClientContext();
  const householdContext = useHouseholdContext();

  const PAGE_SIZE = singleColumn ? 3 : 15;
  const householdLinkedAccounts = accounts.filter((a) => a.householdClientGroup);
  const householdIds = uniq(householdLinkedAccounts.map((a) => a.householdClientGroup?.id));
  const indexedHouseholdIds = Object.fromEntries(householdIds.map((id, index) => [id, index === 0 ? 'HOUSEHOLD' : index + 1]));

  const getTags = (account: Account) => {
    if (clientContext && objectType !== PageObjectType.HOUSEHOLD) {
      if (!account.householdClientGroup) return undefined;

      return [{
        sequenceNumber: indexedHouseholdIds[account.householdClientGroup.id],
        text: `${account.householdClientGroup.name} ${t('shared:household')}`,
      }];
    }

    if (householdContext) {
      const tags = [{
        sequenceNumber: householdContext.indexedMembers[account.user.id],
        text: generateClientNameString(account.user),
      }];

      const joint = account.affiliations?.find((affiliate) => affiliate.type === AffiliationTypes.Joint);

      if (joint) {
        tags.push({
          sequenceNumber: householdContext.indexedMembers[joint.user.id],
          text: generateClientNameString(joint.user),
        });
      }

      return tags;
    }

    return undefined;
  };

  return (
    <>
      <Grid container spacing={2}>
        {loading
          ? <Grid item xs={12} sm={singleColumn ? 12 : 6}><Skeleton width='100%' height='114px' variant='rectangular' /></Grid>
          : accounts.map((account) => (
            <Grid item xs={12} sm={singleColumn ? 12 : 6} key={account.id}>
              <AccountItem
                data-testid={`account-${account?.id}`}
                objectId={objectId}
                account={account}
                objectType={objectType}
                tags={getTags(account)}
                enableTooltip={false}
                useCustodianData={useCustodianData}
                options={options}
                showSimplifiedState={showSimplifiedState}
              />
            </Grid>
          ))
        }
      </Grid>
      {totalCount > PAGE_SIZE ? (
        <Box display='flex' flexDirection='column' alignItems='end' mt={1}>
          <Pagination
            count={Math.ceil(totalCount / PAGE_SIZE)}
            sx={{ marginBottom: '10px' }}
            page={page}
            onChange={(_e, newPage) => {
              setPage(newPage);
            }}
          />
        </Box>
      ) : undefined}
    </>
  );
};

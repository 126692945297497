import { useTranslation } from 'react-i18next';
import { integrationConfig } from './integrationConfig';
import { NewIntegrationType, CurrentIntegrationType } from '../../../../../interfaces/integrations';
import { Switch, TextField } from '../../../../2-component';
import { Box } from '../../../../1-primative';

interface IntegrationsConfigurationProps {
  integration: NewIntegrationType | CurrentIntegrationType;
  setIntegrationConfiguration: (newConfig: any) => void;
}

const IntegrationsConfiguration = ({ integration, setIntegrationConfiguration }: IntegrationsConfigurationProps) => {
  const { t } = useTranslation(['devSettings']);
  if (!integration?.type) return null;
  const integrations = integrationConfig[integration?.type].integration;

  if (!integrations) return null;
  const findIntegration = integrations?.find((x: any) => x.provider === integration.provider);
  const booleanFields = ['enableNameMatching'];

  return (
    <Box display='flex' flexDirection='column' gap={1}>
      {findIntegration?.configuration?.map((x: string) => (
        booleanFields.includes(x) ? (
          <Switch
            label={t(`integrationsModal.configuration.${x}`)}
            checked={integration.configuration[x] ? integration.configuration[x] : false}
            onChange={(e: any) => {
              setIntegrationConfiguration({ ...integration.configuration, [x]: e });
            }}
          />
        ) : (
          <TextField
            required
            value={integration.configuration[x] ? integration.configuration[x] : ''}
            label={t(`integrationsModal.configuration.${x}`)}
            fullWidth
            onChange={(e: any) => {
              setIntegrationConfiguration({ ...integration.configuration, [x]: e.target.value });
            }}
          />
        )
      ))}
    </Box>
  );
};

export default IntegrationsConfiguration;

import { useTranslation } from 'react-i18next';
import { SettingsTitle } from '../../../3-pattern';
import { Dashboards as DashboardsTable } from '../../dashboards/dashboards';

export const Dashboards = () => {
  const { t } = useTranslation(['settings']);
  return (
    <>
      <SettingsTitle title={t('dashboards.title')} description={t('dashboards.description')} />
      <DashboardsTable />
    </>
  );
};

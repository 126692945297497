import { useTranslation } from 'react-i18next';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  Button, Dialog, DialogContent, DialogFooter, DialogTitle, IconButton, MenuItem, SelectField,
} from '../../../2-component';

export const FilterArticles = ({
  open, setOpen, state, setState, loading, clearFilter,
}: {
  open: boolean, setOpen: (open: boolean) => void, state: any, setState: (e: string) => void, loading: boolean, clearFilter: () => void,
}) => {
  const { t } = useTranslation('newsAndInsight');

  return (
    <Dialog maxWidth="sm" fullWidth open={open}>
      <DialogTitle display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        {t('newsAndInsight:filter')}
        <IconButton onClick={() => setOpen(false)}><CloseRoundedIcon /></IconButton>
      </DialogTitle>
      <DialogContent>
        <SelectField value={state} fullWidth label={t('newsAndInsight:state')} onChange={(e: any) => setState(e.target.value)} testId='filter-articles-state'>
          <MenuItem data-testId='filter-articles-state-active' key="ACTIVE" value="ACTIVE">{t('newsAndInsight:active')}</MenuItem>
          <MenuItem data-testId='filter-articles-state-draft' key="DRAFT" value="DRAFT">{t('newsAndInsight:draft')}</MenuItem>
        </SelectField>
      </DialogContent>
      <DialogFooter>
        <Button variant='tonal' disabled={loading} label={t('newsAndInsight:clearFilter')} onClick={clearFilter} dataTestId='filter-articles-clear-filter-button' />
      </DialogFooter>
    </Dialog>
  );
};

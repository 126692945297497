import { gql } from '@apollo/client';

export const STATIC_PAGE_WIDGET_CONFIGURATION = `#graphql
  staticPageWidgetConfiguration {
    id
    name
    widget { id type options }
  }
`;

export const FETCH_STATIC_PAGE_WIDGET_CONFIGURATION = gql`
  query fetchStaticPageWidgetConfiguration($staticPageWidgetConfigurationId: ObjectID!) {
    fetchStaticPageWidgetConfiguration (staticPageWidgetConfigurationId: $staticPageWidgetConfigurationId) {
      ${STATIC_PAGE_WIDGET_CONFIGURATION}
    }
  }
`;

export const UPDATE_STATIC_PAGE_WIDGET_CONFIGURATION = gql`
  mutation UpdateStaticPageWidgetConfiguration($input: UpdateStaticPageWidgetConfigurationInput!) {
    updateStaticPageWidgetConfiguration(input: $input) {
      ${STATIC_PAGE_WIDGET_CONFIGURATION}
    }
  }
`;

/* eslint-disable array-callback-return */
import { gql } from '@apollo/client';
import { Organization } from './organization';
import { TranslatedString } from './shared';

export enum CustomFieldFormats {
  TOGGLE_SWITCH = 'TOGGLE_SWITCH',
  CHECKBOX = 'CHECKBOX',
  RADIO_BUTTON = 'RADIO_BUTTON',
  DROPDOWN_LIST = 'DROPDOWN_LIST',
  SEARCH_LIST = 'SEARCH_LIST',
  NUMBER = 'NUMBER',
  CURRENCY = 'CURRENCY',
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  TEXT_AREA = 'TEXT_AREA',
  SIMPLE_TEXT = 'SIMPLE_TEXT',
  PHYSICAL_ADDRESS = 'PHYSICAL_ADDRESS',
  CUSTOM_AGREEMENTS = 'CUSTOM_AGREEMENTS',
}

export enum CustomFieldTypes {
  TEXT = 'TEXT',
  INTEGER = 'INTEGER',
  DECIMAL = 'DECIMAL',
  SINGLE_SELECT = 'SINGLE_SELECT',
  MULTIPLE_SELECT = 'MULTIPLE_SELECT',
  DATE = 'DATE',
  BOOLEAN = 'BOOLEAN',
  CUSTOM_OBJECT = 'CUSTOM_OBJECT',
}

export enum CustomFieldStates {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum CustomFieldWorkflowSteps {
  PERSONAL_INFORMATION = 'PERSONAL_INFORMATION',
  PERSONS_OF_INTEREST = 'PERSONS_OF_INTEREST',
  NON_INDIVIDUAL_INFORMATION = 'NON_INDIVIDUAL_INFORMATION',
  EMPLOYMENT_INFORMATION = 'EMPLOYMENT_INFORMATION',
  APPROVAL = 'APPROVAL',
  DOCUMENTS = 'DOCUMENTS',
  EDIT_GOAL = 'EDIT_GOAL',
  EDIT_ACCOUNT = 'EDIT_ACCOUNT',
  HOUSEHOLD = 'HOUSEHOLD',
}

export enum CustomFieldObjectTypes {
  HOUSEHOLD = 'HOUSEHOLD',
  ENTITY = 'ENTITY',
  ACCOUNT = 'ACCOUNT',
  SUB_ACCOUNT = 'SUB_ACCOUNT',
  GOAL = 'GOAL',
}

export enum CustomFieldSource {
  CUSTODIAN = 'CUSTODIAN',
  INTERNAL = 'INTERNAL',
}

export interface SelectOption {
  displayText?: TranslatedString,
  value: string,
}

export enum CustomFieldConditionalRulesComparisonTypes {
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  INCLUDES = 'INCLUDES',
  NOT_INCLUDES = 'NOT_INCLUDES',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  GREATER_THAN_AGO = 'GREATER_THAN_AGO',
  LESS_THAN_AGO = 'LESS_THAN_AGO',
}

export interface CustomFieldConditionalRule {
  field: string,
  comparison: CustomFieldConditionalRulesComparisonTypes,
  value: any,
}

export interface CustomField {
  id?: string,
  format: CustomFieldFormats,
  organization: Organization,
  type: CustomFieldTypes,
  key: string,
  translatedDescription?: TranslatedString,
  translatedName?: TranslatedString,
  workflowStep?: CustomFieldWorkflowSteps,
  objectType: CustomFieldObjectTypes,
  selectOptions?: SelectOption[],
  additionalInfo?: any,
  externalField?: CustomFieldSource,
  conditionalRules?: CustomFieldConditionalRule[],
  isTriggerRulesEnabled?: boolean,
  isEnabled?: boolean,
}

export interface CustomOption {
  customField: CustomField,
  enabled: boolean,
  label: TranslatedString,
  required: string,
  additionalInfo?: any,
  position?: number,
}
export interface CustomFieldValue {
  key: string,
  value?: any,
  customField?: CustomField,
}

export const CUSTOM_FIELDS_FORMAT_MAP: { [key in CustomFieldTypes]: CustomFieldFormats[] } = {
  [CustomFieldTypes.TEXT]: [
    CustomFieldFormats.SIMPLE_TEXT,
    CustomFieldFormats.TEXT_AREA,
    CustomFieldFormats.EMAIL,
    CustomFieldFormats.PHONE,
  ],
  [CustomFieldTypes.INTEGER]: [
    CustomFieldFormats.NUMBER,
    CustomFieldFormats.CURRENCY,
  ],
  [CustomFieldTypes.DECIMAL]: [
    CustomFieldFormats.NUMBER,
    CustomFieldFormats.CURRENCY,
  ],
  [CustomFieldTypes.SINGLE_SELECT]: [
    CustomFieldFormats.RADIO_BUTTON,
    CustomFieldFormats.DROPDOWN_LIST,
    CustomFieldFormats.SEARCH_LIST,
  ],
  [CustomFieldTypes.MULTIPLE_SELECT]: [
    CustomFieldFormats.CHECKBOX,
    CustomFieldFormats.DROPDOWN_LIST,
    CustomFieldFormats.SEARCH_LIST,
  ],
  [CustomFieldTypes.DATE]: [
    CustomFieldFormats.DATE,
    CustomFieldFormats.DATE_TIME,
  ],
  [CustomFieldTypes.CUSTOM_OBJECT]: [
    CustomFieldFormats.PHYSICAL_ADDRESS,
    CustomFieldFormats.CUSTOM_AGREEMENTS,
  ],
  [CustomFieldTypes.BOOLEAN]: [
    CustomFieldFormats.TOGGLE_SWITCH,
    CustomFieldFormats.CHECKBOX,
  ],
};

export const CUSTOM_FIELDS_WORKFLOW_STEP_MAP: {
  [key in CustomFieldObjectTypes]: CustomFieldWorkflowSteps[]
} = {
  [CustomFieldObjectTypes.HOUSEHOLD]: [
    CustomFieldWorkflowSteps.HOUSEHOLD,
    CustomFieldWorkflowSteps.APPROVAL,
  ],
  [CustomFieldObjectTypes.ENTITY]: [
    CustomFieldWorkflowSteps.PERSONAL_INFORMATION,
    CustomFieldWorkflowSteps.PERSONS_OF_INTEREST,
    CustomFieldWorkflowSteps.NON_INDIVIDUAL_INFORMATION,
    CustomFieldWorkflowSteps.EMPLOYMENT_INFORMATION,
    CustomFieldWorkflowSteps.APPROVAL,
    CustomFieldWorkflowSteps.DOCUMENTS,
  ],
  [CustomFieldObjectTypes.ACCOUNT]: [
    CustomFieldWorkflowSteps.EDIT_ACCOUNT,
    CustomFieldWorkflowSteps.APPROVAL,
  ],
  [CustomFieldObjectTypes.SUB_ACCOUNT]: [
    CustomFieldWorkflowSteps.APPROVAL,
  ],
  [CustomFieldObjectTypes.GOAL]: [
    CustomFieldWorkflowSteps.EDIT_GOAL,
    CustomFieldWorkflowSteps.APPROVAL,
  ],
};
export const CUSTOM_FIELDS_AVIALABLE_SUB_STEP = [
  CustomFieldWorkflowSteps.PERSONAL_INFORMATION,
  CustomFieldWorkflowSteps.PERSONS_OF_INTEREST,
  CustomFieldWorkflowSteps.NON_INDIVIDUAL_INFORMATION,
  CustomFieldWorkflowSteps.EMPLOYMENT_INFORMATION,
  CustomFieldWorkflowSteps.APPROVAL,
  CustomFieldWorkflowSteps.DOCUMENTS,
  CustomFieldWorkflowSteps.EDIT_ACCOUNT,
  CustomFieldWorkflowSteps.APPROVAL,
  CustomFieldWorkflowSteps.EDIT_GOAL,
  CustomFieldWorkflowSteps.HOUSEHOLD,
];
export const FETCH_CUSTOM_FIELDS = gql`
query fetchCustomFields($input: FetchCustomFieldsInput!) {
  fetchCustomFields(input: $input) {
  customFields{
    id
    key
    type
    format
    objectType
    workflowStep
    translatedName{
      en
    }
    selectOptions{
      value
      displayText { en fr }
    }
    translatedDescription {
        en
        fr
      }
    externalField
    conditionalRules {
      field
      comparison
      value
    }
  }
}
}
`;

export const modifyUpdatingCustomFields = (
  userCustomFields: { key: string, value: any }[],
  derivedCustomFields: CustomField[],
  activeCustomFieldKeys?: string[],
) => {
  const customFieldArray: { key: string, value: any }[] = [];
  const keysWithData: string[] = [];
  userCustomFields && userCustomFields.map((field: any) => {
    if (activeCustomFieldKeys?.includes(field.key)) {
      keysWithData.push(field.key);
      customFieldArray.push({
        key: field.key,
        value: field.value,
      });
    }
  });
  derivedCustomFields?.map((f: CustomField) => {
    if (!keysWithData.includes(f.key) && f.type === 'BOOLEAN') {
      if (activeCustomFieldKeys?.includes(f.key)) {
        customFieldArray.push({
          key: f.key,
          value: false,
        });
      }
    }
  });
  return customFieldArray;
};

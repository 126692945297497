import { gql } from '@apollo/client';

export const FETCH_DOCUMENTS = gql`
  query fetchFileDocuments($filter: FileDocumentQueryFilter) {
    fetchFileDocuments(input: { filter: $filter, pagination: { perPage: 1000 } }) {
      fileDocuments {
        id type name fileName mediaType uploadedAt updatedAt
        objectType objectId
        sharedClient sharedCustodian
        creator { id }
        user { id firstName lastName }
      }
    }
  }
`;

const ARTICLE = `#graphql
  id
  articleUrl
  createdAt
  createdBy { id firstName lastName }
  organization { id }
  state
  translatedDescription { en fr }
  translatedName { en fr }
  imageUrl
  updatedAt
`;

export const FETCH_ARTICLES = gql`
  query fetchArticles($input: FetchArticlesInput!) {
    fetchArticles(input: $input) {
      articles { ${ARTICLE} }
      totalCount
    }
  }
`;

export const FETCH_ARTICLE = gql`
  query fetchArticle($articleId: ObjectID!) {
    fetchArticle(articleId: $articleId) {
      article { ${ARTICLE} }
    }
  }
`;

export const CREATE_ARTICLE = gql`
  mutation createArticle($input: CreateArticleInput!) {
    createArticle(input: $input) {
      article { id }
    }
  }
`;

export const TRANSITION_ARTICLE = gql`
  mutation transitionArticle($input: TransitionArticleInput!) {
    transitionArticle(input: $input) {
      article { id }
    }
  }
`;

export const UPDATE_ARTICLE = gql`
  mutation updateArticle($input: UpdateArticleInput!) {
    updateArticle(input: $input) {
      article { id }
    }
  }
`;

export const DELETE_FILE_DOCUMENT = gql`
  mutation deleteFileDocument($fileDocumentId: ObjectID!) {
    deleteFileDocument(input: { fileDocumentId: $fileDocumentId }) {
      fileDocument { id }
    }
  }
`;

export const DELETE_ARTICLE_IMAGE = gql`
  mutation deleteArticleImage($input: DeleteArticleImageInput!) {
    deleteArticleImage(input: $input) {
      article { id }
    }
  }
`;

export const DELETE_ARTICLE = gql`
  mutation deleteArticle($articleId: ObjectID!) {
    deleteArticle(articleId: $articleId) { deleted }
  }
`;

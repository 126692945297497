/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from '@apollo/client';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { formatMoneyValue } from '../../../../util';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import { usePageState } from '../../../../util/usePageState';
import { TRANSITION_TRANSFER, FETCH_TRANSFERS } from './transfers.queries';
import { useLocalization } from '../../../../util/useLocalization';
import {
  Box, Skeleton, Typography,
} from '../../../1-primative';
import {
  Table, TableRow, TableCell, TableBody, Pagination, MenuItem,
  TableHeadCell, Card, CardContent, SelectField, Badge, Menu, IconButton,
} from '../../../2-component';
import { PageObjectType } from '../../../5-page';
import { translateBackend } from '../../../../assets/i18n/config';
import { TransferState } from '../../../../interfaces';
import { TransferModal } from '../pendingTransfers/components/transferModal';
import { TransferTransitionReasonDialog } from '../pendingTransfers/components/transferTransitionReasonDialog';
import { getUserIdFromPath } from '../../../../util/getUserIdFromPath';
import { getAccountName } from '../../../../util/appUtils';
import { TransferSourcesTypes } from '../../../../interfaces/transaction';
import {
  FilterModal, SubAccountSelect,
} from '../../../3-pattern';
import { baseSubAccountFilter } from '../ledger/ledger';
import UpdateTransfer from './components/updateTransfer';

const PAGE_SIZE = 15;

const TRANSFERS_STATES: TransferState[] = [
  TransferState.Initiated,
  TransferState.Ready,
  TransferState.Processing,
  TransferState.Requested,
  TransferState.Reviewing,
  TransferState.Reconciled,
  TransferState.Failed,
  TransferState.Canceled,
];

const baseFilter = (objectType: PageObjectType, objectId: string, userId?: string) => {
  switch (objectType) {
    case PageObjectType.INDIVIDUAL:
    case PageObjectType.NON_INDIVIDUAL:
      return { userId: objectId };
    case PageObjectType.HOUSEHOLD:
      return { clientGroupId: objectId };
    case PageObjectType.ACCOUNT:
      return { accountId: objectId };
    case PageObjectType.SUB_ACCOUNT:
      return { subAccountId: objectId };
    case PageObjectType.GOAL:
      return {
        goalId: objectId,
        userId,
      };
    default:
      return {};
  }
};

const color = (state: TransferState | 'DRAFT' | 'ACTIVE') : 'neutral' | 'positive' | 'negative' | 'warning' => {
  switch (state) {
    case 'DRAFT':
      return 'warning';
    case TransferState.Reconciled:
    case 'ACTIVE':
      return 'positive';
    case TransferState.Failed:
    case TransferState.Canceled:
      return 'negative';
    case TransferState.Initiated:
    case TransferState.Ready:
    case TransferState.Processing:
    case TransferState.Requested:
    case TransferState.Reviewing:
    default:
      return 'neutral';
  }
};

export const Transfers = ({
  objectType, objectId, options,
}: { objectType: PageObjectType, objectId: string, options: any }) => {
  const { t } = useTranslation(['components', 'transfer', 'shared']);
  const { localizedDate } = useLocalization();
  const { permissions } = usePermissions();
  const [page, setPage] = usePageState(1, 'tra/page');
  const { activeOrganization } = useContext(UserContext);
  const { userId, clientGroupId } = useParams();
  const { pathname: path } = useLocation();
  const [perPage, setPerPage] = useState<number>(PAGE_SIZE);

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [transferFilter, setTransferFilter] = useState<any>({});
  const [activeTransfer, setActiveTransfer] = useState<any>({});
  const [modalOpen, setModalOpen] = useState(false);
  const [transfers, setTransfers] = useState<{ transfers: any[], totalCount: number }>();
  const [showTransitionDialog, setShowTransitionDialog] = useState(false);
  const [selectedTransition, setSelectedTransition] = useState<string>();
  const [openUpdateModal, setUpdateModal] = useState(false);

  const openContextMenu = (onElement: HTMLElement) => {
    setMenuAnchorEl(onElement);
  };
  const handleClose = () => setMenuAnchorEl(null);

  const queryFilter = {
    organizationId: activeOrganization.id,
    state: transferFilter.state === 'any' ? undefined : transferFilter.state,
    subAccountId: transferFilter.subAccount ? transferFilter.subAccount.id : undefined,
    source: transferFilter.source === 'any' ? undefined : transferFilter.source,
    type: transferFilter.type === 'any' ? undefined : transferFilter.type,
  };

  const filter = baseFilter(objectType, objectId, getUserIdFromPath({ userId, clientGroupId, path }));
  const filterExists = [
    { value: transferFilter.state, compare: 'any' },
    { value: transferFilter.subAccount, compare: undefined },
    { value: transferFilter.source, compare: 'any' },
    { value: transferFilter.type, compare: 'any' },
  ].some(({ value, compare }) => value && value !== compare);

  const { loading, previousData, refetch } = useQuery(FETCH_TRANSFERS(permissions), {
    notifyOnNetworkStatusChange: true,
    skip: !permissions.includes('read:transfer_basic'),
    variables: {
      input: {
        filter: { ...queryFilter, ...filter },
        pagination: {
          sortField: 'createdAt', sortDesc: false, perPage, offSet: (page - 1) * perPage,
        },
      },
    },
    onCompleted(data) {
      setTransfers({ transfers: data.fetchTransfers?.transfers, totalCount: data.fetchTransfers?.totalCount || 0 });

      if (activeTransfer?.id) {
        setActiveTransfer(data.fetchTransfers.transfers.find((x: any) => x.id === activeTransfer.id));
      }
    },
  });

  const [transition] = useMutation(TRANSITION_TRANSFER, { onCompleted: refetch });

  useEffect(() => function cleanupOnUnmount() {
    setPage(1);
  }, []);

  const handleTransition = (transferId: string, isScheduled: boolean, variables: any) => {
    if (['cancel', 'fail', 'cancelReconciled'].some((action) => variables.transition.includes(action))) {
      setSelectedTransition(variables.transition);
      setShowTransitionDialog(true);
    } else {
      transition({
        variables: { input: { transferId, ...variables } },
      });
    }
    setModalOpen(false);
  };

  const handleTransitionWithReason = (reason: string) => {
    if (selectedTransition && activeTransfer?.id) {
      const reasonField = {
        fail: 'failReason',
        cancel: 'cancelReason',
        cancelReconciled: 'cancelReconciledReason',
      }[selectedTransition];

      if (reasonField) {
        transition({
          variables: {
            input: {
              transferId: activeTransfer?.id,
              transition: selectedTransition,
              [reasonField]: reason,
            },
          },
        });
        setShowTransitionDialog(false);
      }
    }
  };

  const showPagination = transfers && transfers?.totalCount > PAGE_SIZE;
  const CAN_EDIT = options?.canEdit && permissions.includes('write:adjustment_transfer');
  const HAS_NO_TRANSFERS = !transfers?.transfers?.length;
  const HAS_NO_PREVIOUS_TRANSFER = !previousData || (previousData && previousData.fetchTransfers.totalCount === 0);

  if (!permissions.includes('read:transfer_basic')) return <></>;
  return (
    <Box>
      {options.customTitle && (
        <Typography variant='headingSmall' sx={{ mb: 2 }}>{translateBackend(options.customTitle)}</Typography>
      )}
      <Card>
        <CardContent>
          <Box display='flex' justifyContent='end'>
            <FilterModal filterExists={filterExists}>
              {/* Type filter */}
              <SelectField
                value={transferFilter.state ? transferFilter.state : 'any' }
                label={t('transferTable.state')}
                onChange={(e: any) => setTransferFilter({ ...transferFilter, state: e.target.value })}
                fullWidth
                placeholder={t('any')}
                sx={{ mb: 2 }}
              >
                <MenuItem value={'any'}>{t('any')}</MenuItem>
                {TRANSFERS_STATES.map((x) => (
                  <MenuItem value={x} key={x}>{t(`transfer:states.${x}`)}</MenuItem>
                ))}
              </SelectField>
              {/* Sub account filter */}
              <SubAccountSelect
                sx={{ mb: 2 }}
                filter={baseSubAccountFilter(objectType, objectId, filter)}
                selectedSubAccount={transferFilter.subAccount}
                onSubAccountSelect={(subAccount: any) => {
                  setTransferFilter({ ...transferFilter, subAccount });
                  setPage(1);
                }}
                label={t('components:subAccount')}
              />
              <SelectField
                value={transferFilter.source ? transferFilter.source : 'any'}
                label={t('transferTable.source')}
                onChange={(e: any) => setTransferFilter({ ...transferFilter, source: e.target.value })}
                fullWidth
                placeholder={t('any')}
                sx={{ mb: 2 }}
              >
                <MenuItem value={'any'}>{t('any')}</MenuItem>
                {Object.values(TransferSourcesTypes).map((x: any) => (
                  <MenuItem value={x} key={x}>{t(`transferSources.${x}`)}</MenuItem>
                ))}
              </SelectField>
              <SelectField
                value={transferFilter.type ? transferFilter.type : 'any'}
                label={t('transferTable.type')}
                onChange={(e: any) => setTransferFilter({ ...transferFilter, type: e.target.value })}
                fullWidth
                placeholder={t('any')}
                sx={{ mb: 2 }}
              >
                <MenuItem value={'any'}>{t('any')}</MenuItem>
                <MenuItem value='DEPOSIT'>{t('shared:deposit')}</MenuItem>
                <MenuItem value='WITHDRAW'>{t('shared:withdraw')}</MenuItem>
              </SelectField>
            </FilterModal>
          </Box>
        </CardContent>
        <Table aria-label="table">
          <TableBody>
            <TableRow>
              <TableHeadCell dense>{t('components:transaction.table.account')}</TableHeadCell>
              <TableHeadCell dense>{t('components:transaction.table.type')}</TableHeadCell>
              <TableHeadCell dense>{t('components:transaction.table.source')}</TableHeadCell>
              <TableHeadCell dense>{t('components:transaction.table.state')}</TableHeadCell>
              <TableHeadCell dense right>{t('components:transaction.table.valueCents')}</TableHeadCell>
              <TableHeadCell dense>{t('components:transaction.table.date')}</TableHeadCell>
              {
                options?.canEdit && permissions.includes('write:adjustment_transfer') && (
                  <TableHeadCell dense></TableHeadCell>)
              }
            </TableRow>

            {loading ? (
              <>
                {[...Array(15)].map((_x: any, i: number) => (
                  <TableRow key={i}>
                    <TableCell dense><Skeleton/></TableCell>
                    <TableCell dense><Skeleton/></TableCell>
                    <TableCell dense><Skeleton/></TableCell>
                    <TableCell dense><Skeleton/></TableCell>
                    <TableCell dense><Skeleton/></TableCell>
                    <TableCell dense><Skeleton/></TableCell>
                    {
                      options?.canEdit && permissions.includes('write:adjustment_transfer') && (
                        <TableCell dense><Skeleton/></TableCell>)
                    }
                  </TableRow>
                ))}
              </>
            ) : (
              <>
                {transfers?.transfers.map((transfer: any) => (
                  <TableRow
                    hover
                    pointer
                    onClick={() => {
                      setActiveTransfer(transfer);
                      setModalOpen(true);
                    }}
                    key={transfer.id}
                    sx={{ textDecoration: 'none', cursor: 'pointer' }}
                  >
                    <TableCell dense>{getAccountName(transfer, t)}</TableCell>
                    <TableCell dense>{t(`components:pendingTransfers.types.${transfer.type}`)}</TableCell>
                    <TableCell dense>
                      {t(`components:transferSources.${transfer.source}`)}
                      {transfer.dividendFor?.ticker ? ' - ' : ''}
                      {transfer.dividendFor?.ticker && <b>{transfer.dividendFor.ticker}</b>}
                    </TableCell>
                    <TableCell dense><Badge label={t(`components:pendingTransfers.states.${transfer.state}`)}
                                            color={color(transfer.state)}/></TableCell>
                    <TableCell dense
                               number>{formatMoneyValue(transfer.amountCents * (transfer.type === 'WITHDRAW' ? -1 : 1))}</TableCell>
                    <TableCell dense>{localizedDate(transfer.createdAt)}</TableCell>
                    {
                      options?.canEdit && permissions.includes('write:adjustment_transfer') && (
                        <TableCell dense>
                          <IconButton
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                              event.stopPropagation();
                              setActiveTransfer(transfer);
                              openContextMenu(event.currentTarget);
                            }}
                          >
                            <MoreVertIcon/>
                          </IconButton>
                        </TableCell>
                      )
                    }
                  </TableRow>
                ))}
              </>
            )}
            {HAS_NO_TRANSFERS && HAS_NO_PREVIOUS_TRANSFER && !loading && (
              <TableRow>
                <TableCell colSpan={CAN_EDIT ? 7 : 6} sx={{ textAlign: 'center' }}>
                  <Typography weight='bold' variant='bodyLarge'>{t('components:activityTable.noTransfersExist')}</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {showPagination && (
          <Box sx={{ px: 2, py: 1 }}>
            <Pagination
              count={Math.ceil((transfers?.totalCount ?? 0) / perPage)}
              page={page}
              total={transfers?.totalCount ?? 0}
              perPage={perPage}
              size='small'
              onChangePerPage={(newPerPage) => {
                setPerPage(newPerPage);
                setPage(1);
                refetch();
              }}
              onChange={(_e, newPage) => setPage(newPage)}
            />
          </Box>
        )}
        <TransferModal
          transfer={activeTransfer}
          open={modalOpen}
          handleClose={() => setModalOpen(false)}
          options={{ ...options, canCancelPending: options.canTransition }}
          transition={handleTransition}
        />

        <TransferTransitionReasonDialog
          title={t(`transferTransitions.${selectedTransition === 'fail' ? 'failtitle' : 'canceltitle'}`)}
          confirmButtonLabel={t('transferTransitions.confirm')}
          abortButtonLabel={t('transferTransitions.abort')}
          open={showTransitionDialog}
          onClose={() => {
            setShowTransitionDialog(false);
            setModalOpen(true);
          }}
          onConfirm={handleTransitionWithReason}
        />
        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleClose}
          onClick={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{ cursor: 'pointer' }}
        >
          <MenuItem value="DAY" onClick={() => setUpdateModal(true)}>{t('Edit')}</MenuItem>
        </Menu>
        <UpdateTransfer
          open={openUpdateModal}
          handleClose={() => setUpdateModal(false)}
          transaction={activeTransfer}
          afterUpdate={refetch} options={options}
        />
      </Card>
    </Box>
  );
};

export default Transfers;

import { gql } from '@apollo/client';

export const UPSERT_REP_CODE = gql`
  mutation upsertRepCode($input: UpsertRepCodeInput!) {
    upsertRepCode(input: $input) {
      repCode {
        code
      }
    }
  }
`;

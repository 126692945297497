import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translateBackend } from 'assets/i18n/config';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useThemeTokens } from 'providers/themeTokenProvider';
import Search from '@mui/icons-material/Search';
import {
  Typography, Form, SelectionTile, TextField, InputAdornment, Box,
  BetaBadge,
} from '../../../..';
import { AccountTypes } from '../../../../../interfaces';
import { WorkflowContext } from '../../workflowCompletion';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';
import { ContinueFunctionArgs, CreateAccountOptionsType } from './createAccount';

interface AccountListItem {
  weight: number;
  type: string;
}

// custom weighted list by Josh
const generateWeightedList = (accountTypeList: string[]): AccountListItem[] => {
  const weightedList = accountTypeList.map((accountItem: string) => {
    if (accountItem === AccountTypes.PERSONAL) {
      return {
        weight: 1,
        type: accountItem,
      };
    }
    if (accountItem === AccountTypes.CASH_JOINT) {
      return {
        weight: 2,
        type: accountItem,
      };
    }
    if (accountItem === AccountTypes.TFSA) {
      return {
        weight: 3,
        type: accountItem,
      };
    }
    if (accountItem === AccountTypes.RRSP) {
      return {
        weight: 4,
        type: accountItem,
      };
    }
    if (accountItem === AccountTypes.RRSP_SPOUSAL) {
      return {
        weight: 5,
        type: accountItem,
      };
    }
    if (accountItem === AccountTypes.RESP_FAMILY) {
      return {
        weight: 6,
        type: accountItem,
      };
    }
    if (accountItem === AccountTypes.RESP_SINGLE) {
      return {
        weight: 7,
        type: accountItem,
      };
    }
    if (accountItem === AccountTypes.RESP_ADULT) {
      return {
        weight: 8,
        type: accountItem,
      };
    }
    if (accountItem === AccountTypes.RESP_FAMILY_JOINT) {
      return {
        weight: 9,
        type: accountItem,
      };
    }
    if (accountItem === AccountTypes.RESP_SINGLE_JOINT) {
      return {
        weight: 10,
        type: accountItem,
      };
    }
    if (accountItem === AccountTypes.RRIF) {
      return {
        weight: 11,
        type: accountItem,
      };
    }
    return {
      weight: 15,
      type: accountItem,
    };
  });
  return weightedList.sort((a, b) => a.weight - b.weight);
};

export const CreateAccountVisual = ({
  options,
  continueFunc,
  accountType,
  availableAccountTypes,
  workflowCompletion,
  loading,
}: {
  options: CreateAccountOptionsType;
  loading: boolean;
  accountType: string;
  continueFunc: (continueFunctionArgs: ContinueFunctionArgs) => Promise<void>;
  availableAccountTypes: AccountTypes[];
  workflowCompletion?: any;
}) => {
  const { t } = useTranslation(['client', 'accountTypes', 'accountsDetail']);
  const { sendAnalytic } = useContext(AnalyticsContext);
  const { sys } = useThemeTokens();
  const [searchQuery, setSearchQuery] = useState('');
  const { workflowData, setWorkflowData } = useContext(WorkflowContext);
  const [accountList, setAccountList] = useState<string[]>([]);

  useEffect(() => {
    const bySearchTerm = (type: string) => {
      const techHaystack = type.toUpperCase();
      const translatedHaystack = t(`accountTypes:${type}`).toUpperCase();
      const needle = searchQuery.trim().toUpperCase();
      return techHaystack.includes(needle) || translatedHaystack.includes(needle);
    };

    setAccountList(availableAccountTypes.filter(bySearchTerm));
  }, [searchQuery, t, availableAccountTypes]);

  const onTileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (loading) return;
    await setWorkflowData({
      ...workflowData,
      currentAccountType: e.target.value,
    });
    sendAnalytic(ovAnalyticsEvents.workflowsCreateAccountContinueButtonSelect, {
      workflowStepTitle: options?.title,
      workflowId: workflowCompletion?.workflow?.id,
      workflowName: workflowCompletion?.workflow?.name,
      activeWorkflowCompletionId: workflowCompletion?.id,
      objectId: workflowCompletion?.objectId,
      objectType: workflowCompletion?.objectType,
    });
    await continueFunc({ accountType: e.target.value });
  };

  if (!options) return <></>;

  const asValueLabelSubtitle = (a: AccountListItem) => ({
    value: a.type,
    label: t(`accountTypes:${a.type}`),
    subtitle: options.displayAccountDefinitions ? t(`accountsDetail:accountDescriptions.${a.type}`) : undefined,
  });

  return (
    <Form>
      <Box display={'flex'} alignItems={'center'} gap={2}>
        <Typography variant='displayLarge' sx={{ mt: 1 }}>
          {translateBackend(options?.title)}
        </Typography>
        <BetaBadge/>
      </Box>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>
        <ReactMarkdown linkTarget='_blank' remarkPlugins={[remarkGfm]}>
          {translateBackend(options?.subtitle)}
        </ReactMarkdown>
      </Typography>
      <TextField
        testId='create-account-search-field'
        value={searchQuery}
        label=''
        fullWidth
        size='small'
        placeholder={t('accountsDetail:searchAccounts')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Search />
            </InputAdornment>
          ),
        }}
        sx={{ mb: 2 }}
      />

      <>
        <SelectionTile testId='create-account-type' onChange={onTileSelect} value={accountType ?? ''} options={generateWeightedList(accountList).map(asValueLabelSubtitle)} type='completion' />

        {accountList.length === 0 && (
          <Typography variant='bodyLarge' sx={{ color: sys.color.onSurfaceVariant }}>
            {t('accountsDetail:noAccountOptions')}
          </Typography>
        )}
      </>
    </Form>
  );
};

export default CreateAccountVisual;

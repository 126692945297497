import {
  ListItem, MenuItem, Typography, TextField,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { remove } from 'lodash/fp';
import { NavigationStyles } from 'interfaces';
import PageConfigurationSelect from 'components/inputs/pageConfigurationSelect';
import TranslatableString from '../../../components/inputs/translatableString';
import FormModal from '../../../components/modals/formModal';
import OrganizationSelect from '../../../components/inputs/organizationSelect';
import PermissionsSelect from '../../../components/inputs/permissionsSelect';
import AccessiblePagesSelect from '../../../components/inputs/accessiblePagesSelect';
import DashboardsSelect from '../../../components/inputs/dashboardsSelect';
import ArchiveRoleProfile from './archiveRoleProfile';
import ConfirmationDialog, { ConfirmButtonColors } from '../../../ovComponents/3-pattern/confirmationDialog/confirmationDialog';
import { Checkbox } from '../../../ovComponents/2-component/checkbox/checkbox';
import { Typography as ModalTypography } from '../../../ovComponents/1-primative/typography/typography';
import StaticPageWidgetConfigurationSelect from '../../../components/inputs/staticPageWidgetConfiguration';

type RoleProfileState = {
  id?: string;
  translatedName: { en: string; fr?: string };
  translatedDescription: { en: string; fr?: string };
  organization: { id: string };
  roleCount?: number;
  template: {
    accessiblePages: any[];
    dashboards: any[];
    permissions: string[];
    navigationStyle: NavigationStyles;
    nonIndividualPageConfiguration?: { id: string };
    individualPageConfiguration?: { id: string };
    goalPageConfiguration?: { id: string };
    accountPageConfiguration?: { id: string };
    householdPageConfiguration?: { id: string };
    subAccountPageConfiguration?: { id: string };
    individualTableStaticPageWidgetConfiguration?: { id: string };
    nonIndividualTableStaticPageWidgetConfiguration?: { id: string };
    householdTableStaticPageWidgetConfiguration?: { id: string };
  };
};

const UPDATE_ROLE_PROFILE = gql`
  mutation updateRoleProfile($input: UpdateRoleProfileInput!) {
    updateRoleProfile(input: $input) {
      roleProfile { id }
    }
  }
`;

const EditRoleProfile = ({
  afterUpdate, roleProfileToUpdate, handleClose, userPermissions,
}: { afterUpdate: () => void; roleProfileToUpdate: any; handleClose: () => void; userPermissions: string[];
}) => {
  const { t } = useTranslation(['orgSettings']);
  const [roleProfile, setRoleProfile] = useState<RoleProfileState>(roleProfileToUpdate);
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [updateAllRoles, setUpdateAllRoles] = useState(false);

  const { roleCount } = roleProfile;
  const enableCheckbox = userPermissions.includes('write:roles' && 'write:role_profiles');

  const [updateRoleProfile, { loading }] = useMutation(UPDATE_ROLE_PROFILE, {
    variables: {
      input: {
        roleProfileId: roleProfile?.id,
        translatedName: { en: roleProfile?.translatedName?.en, fr: roleProfile?.translatedName?.fr },
        translatedDescription: { en: roleProfile?.translatedDescription?.en, fr: roleProfile?.translatedDescription?.fr },
        updateAllRoles,
        template: {
          permissions: roleProfile?.template?.permissions,
          accessiblePages: roleProfile?.template?.accessiblePages,
          dashboards: (roleProfile?.template?.dashboards || []).map((x: any) => x.id),
          navigationStyle: roleProfile?.template.navigationStyle,
          nonIndividualPageConfigurationId: roleProfile?.template.nonIndividualPageConfiguration?.id || null,
          individualPageConfigurationId: roleProfile?.template.individualPageConfiguration?.id || null,
          goalPageConfigurationId: roleProfile?.template.goalPageConfiguration?.id || null,
          accountPageConfigurationId: roleProfile?.template.accountPageConfiguration?.id || null,
          householdPageConfigurationId: roleProfile?.template.householdPageConfiguration?.id || null,
          subAccountPageConfigurationId: roleProfile?.template.subAccountPageConfiguration?.id || null,
          individualTableStaticPageWidgetConfigurationId: roleProfile?.template.individualTableStaticPageWidgetConfiguration?.id || null,
          nonIndividualTableStaticPageWidgetConfigurationId: roleProfile?.template.nonIndividualTableStaticPageWidgetConfiguration?.id || null,
          householdTableStaticPageWidgetConfigurationId: roleProfile?.template.householdTableStaticPageWidgetConfiguration?.id || null,
        },
      },
    },
  });

  const onArchiveMenuClick = () => setArchiveDialogOpen(true);
  const confirmationDialogClick = (event: any) => {
    event.preventDefault();
    setConfirmationDialogOpen(true);
  };

  const update = async () => {
    await updateRoleProfile();
    setConfirmationDialogOpen(false);
    afterUpdate();
  };

  if (!roleProfile) {
    return <></>;
  }

  const menuItems = [
    <MenuItem key='archiveItem' onClick={onArchiveMenuClick}>{t('shared:archive')}</MenuItem>,
  ];

  return (
    <>
      <FormModal
        key={roleProfile.id}
        loading={loading}
        title={t('roleProfileModal.editTitle')}
        formButton={t('update')}
        onSubmit={confirmationDialogClick}
        open
        handleClose={handleClose}
        maxWidth='md'
        menuItems={menuItems}
      >
        <ListItem>
          <TranslatableString label={t('roleProfileModal.name')} value={roleProfile.translatedName} onChange={(value) => setRoleProfile({ ...roleProfile, translatedName: value })} />
        </ListItem>
        <ListItem>
          <TranslatableString label={t('ro leProfileModal.description')}value={roleProfile.translatedDescription}onChange={(value) => setRoleProfile({ ...roleProfile, translatedDescription: value })}
          />
        </ListItem>
        <ListItem>
          <OrganizationSelect value={roleProfile.organization.id} label={t('roleProfileModal.organization')} onChange={(event: any) => {
            setRoleProfile({ ...roleProfile, organization: { id: event.target.value as string } });
          }} />
        </ListItem>
        <ListItem>
          <AccessiblePagesSelect accessiblePages={roleProfile?.template?.accessiblePages || []} label={t('roleProfileModal.accessiblePages')} onChange={(event: any) => {
            setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, accessiblePages: event.target.value } }));
          }} />
        </ListItem>
        <ListItem>
          <DashboardsSelect selectedDashboards={(roleProfile?.template?.dashboards || []).map((x: any) => x.id) || []} label={t('roleProfileModal.dashboards')} onChange={(event: any) => {
            setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, dashboards: event.target.value.map((x: any) => ({ id: x })) } }));
          }} />
        </ListItem>
        <ListItem>
          <TextField select value={roleProfile?.template.navigationStyle ?? NavigationStyles.FULL} label={t('roleTable.navigationStyle')} sx={{ width: '100%' }} onChange={(event: any) => {
            setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, navigationStyle: event.target.value } }));
          }}>
            <MenuItem key='1' value={NavigationStyles.FULL}>{NavigationStyles.FULL}</MenuItem>
            <MenuItem key='2' value={NavigationStyles.SIMPLE}>{NavigationStyles.SIMPLE}</MenuItem>
          </TextField>
        </ListItem>

        <ListItem>
          <PageConfigurationSelect
            filter={{ type: 'NON_INDIVIDUAL' }}
            value={roleProfile.template.nonIndividualPageConfiguration?.id as string}
            label={t('roleModal.nonIndividualPageConfiguration')}
            organizationId={roleProfile.organization.id}
            onChange={(event: any) => {
              setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, nonIndividualPageConfiguration: { id: event.target.value } } }));
            }}
          />
        </ListItem>
        <ListItem>
          <PageConfigurationSelect
            filter={{ type: 'INDIVIDUAL' }}
            value={roleProfile.template.individualPageConfiguration?.id as string}
            label={t('roleModal.individualPageConfiguration')}
            organizationId={roleProfile.organization.id}
            onChange={(event: any) => {
              setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, individualPageConfiguration: { id: event.target.value } } }));
            }}
          />
        </ListItem>
        <ListItem>
          <PageConfigurationSelect
            filter={{ type: 'GOAL' }}
            value={roleProfile.template.goalPageConfiguration?.id as string}
            label={t('roleModal.goalPageConfiguration')}
            organizationId={roleProfile.organization.id}
            onChange={(event: any) => {
              setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, goalPageConfiguration: { id: event.target.value } } }));
            }}
          />
        </ListItem>
        <ListItem>
          <PageConfigurationSelect
            filter={{ type: 'ACCOUNT' }}
            value={roleProfile.template.accountPageConfiguration?.id as string}
            label={t('roleModal.accountPageConfiguration')}
            organizationId={roleProfile.organization.id}
            onChange={(event: any) => {
              setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, accountPageConfiguration: { id: event.target.value } } }));
            }}
          />
        </ListItem>
        <ListItem>
          <PageConfigurationSelect
            filter={{ type: 'SUB_ACCOUNT' }}
            value={roleProfile.template.subAccountPageConfiguration?.id as string}
            label={t('roleModal.subAccountPageConfiguration')}
            organizationId={roleProfile.organization.id}
            onChange={(event: any) => {
              setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, subAccountPageConfiguration: { id: event.target.value } } }));
            }}
          />
        </ListItem>
        <ListItem>
          <PageConfigurationSelect
            filter={{ type: 'HOUSEHOLD' }}
            value={roleProfile.template.householdPageConfiguration?.id as string}
            label={t('roleModal.householdPageConfiguration')}
            organizationId={roleProfile.organization.id}
            onChange={(event: any) => {
              setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, householdPageConfiguration: { id: event.target.value } } }));
            }}
          />
        </ListItem>

        <ListItem>
          <StaticPageWidgetConfigurationSelect
            filter={{ widgetType: 'INDIVIDUAL_TABLE' }}
            value={roleProfile.template.individualTableStaticPageWidgetConfiguration?.id as string}
            label={t('roleModal.individualTableStaticPageWidgetConfiguration')}
            organizationId={roleProfile.organization.id}
            onChange={(event: any) => {
              setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, individualTableStaticPageWidgetConfiguration: { id: event.target.value } } }));
            }}
          />
        </ListItem>
        <ListItem>
          <StaticPageWidgetConfigurationSelect
            filter={{ widgetType: 'NON_INDIVIDUAL_TABLE' }}
            value={roleProfile.template.nonIndividualTableStaticPageWidgetConfiguration?.id as string}
            label={t('roleModal.nonIndividualTableStaticPageWidgetConfiguration')}
            organizationId={roleProfile.organization.id}
            onChange={(event: any) => {
              setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, nonIndividualTableStaticPageWidgetConfiguration: { id: event.target.value } } }));
            }}
          />
        </ListItem>
        <ListItem>
          <StaticPageWidgetConfigurationSelect
            filter={{ widgetType: 'HOUSEHOLD_TABLE' }}
            value={roleProfile.template.householdTableStaticPageWidgetConfiguration?.id as string}
            label={t('roleModal.householdTableStaticPageWidgetConfiguration')}
            organizationId={roleProfile.organization.id}
            onChange={(event: any) => {
              setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, householdTableStaticPageWidgetConfiguration: { id: event.target.value } } }));
            }}
          />
        </ListItem>

        <ListItem>
          <Typography>{t('roleProfileModal.permissions')}</Typography>
        </ListItem>
        <ListItem>
          <PermissionsSelect permissions={roleProfile?.template?.permissions || []} onChange={(event: any) => {
            let permissions = [...(roleProfile?.template?.permissions || [])];
            if (event.target.checked) {
              permissions.push(event.target.value);
            } else {
              permissions = remove((x) => x === event.target.value, permissions);
            }
            setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, permissions } }));
          }} />
        </ListItem>
      </FormModal>

      <ArchiveRoleProfile open={archiveDialogOpen} roleProfileToArchive={roleProfile} handleClose={() => setArchiveDialogOpen(false)} afterDelete={() => {
        setArchiveDialogOpen(false);
        afterUpdate();
      }} />
      <ConfirmationDialog
        onConfirm={update}
        onCancel={() => setConfirmationDialogOpen(false)}
        title={t('roleProfileConfirmationModal.title')}
        open={confirmationDialogOpen}
        confirmButtonColor={ConfirmButtonColors.primary}
      >
        <ModalTypography variant='bodyLarge'>{t('roleProfileConfirmationModal.body')}</ModalTypography>
        {enableCheckbox && (
          <Checkbox
            label={t('roleProfileConfirmationModal.checkbox', { roles: roleCount ?? 0 })}
            checked={updateAllRoles}
            onChange={setUpdateAllRoles}
            size='small'
            customStyle={{ mt: 2 }}
            checkBoxStyle={{ height: '16px' }}
          />
        )}
      </ConfirmationDialog>
    </>
  );
};

export default EditRoleProfile;

import { useContext } from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { colors } from 'ovComponents';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Home } from '@mui/icons-material';
import './breadcrumbs.css';
import { UserContext } from '../../../providers/userContextProvider';

type BreadcrumbItem = { href:string, label: string | React.FunctionComponent, testId?: string };
export type BreadcrumbsProps = { items: BreadcrumbItem[], overrideColor?: string };

export function Breadcrumbs({ items, overrideColor }: BreadcrumbsProps) {
  const { userContext } = useContext(UserContext);
  let homeItem: any = null;
  if (userContext.role?.navigationStyle === 'SIMPLE') {
    const clientItems = items.splice(0, 2);
    // eslint-disable-next-line prefer-destructuring
    homeItem = clientItems[1];
  }
  if (items.length === 0) {
    return (<></>);
  }
  return (
    <div>
      { homeItem && (
        <>
          <Link to={homeItem.href} className="breadcrumb-link" style={{ color: colors.neutral600 }} data-testid='home-breadcrumb-link'>
            <Home sx={{ fontSize: '20px', color: overrideColor ?? colors.neutral600, marginBottom: '-3px' }} />
          </Link>
          <ChevronRightIcon style={ { color: overrideColor || colors.neutral600 }} sx={{ height: '18px', marginBottom: '-3px' }}/>
        </>
      )}
      { items.map((item, index) => {
        const isLast = index === items.length - 1;
        return <span key={index}>
          <Typography fontWeight={isLast ? 600 : 500} lineHeight={'20px'} component='span'>
            <SmartBreadcrumb key={index}
              href={item.href}
              TheLabel={item.label}
              color={ overrideColor || (isLast ? colors.neutral700 : colors.neutral600) }
              testId={item.testId}
              />
          </Typography>
          { isLast || <ChevronRightIcon style={ { color: overrideColor || colors.neutral600 }} sx={{ height: '18px', marginBottom: '-3px' }}/> }
          </span>;
      })}
    </div>
  );
}

function SmartBreadcrumb({
  href, TheLabel, color, testId,
}: {
  href: string, TheLabel: string | React.FunctionComponent, color: string, testId?: string,
}) {
  return (
    <Link to={href} className="breadcrumb-link" style={{ color }} data-testid={testId ?? 'breadcrumb-link'}>
      {typeof (TheLabel) === 'function' ? <TheLabel/> : TheLabel}
    </Link>
  );
}

import dayjs from 'dayjs';
import { AuthenticationConnections, Organization } from './organization';
import { Role } from './role';
import { Languages, User } from './user';
import { NotificationGroup } from './notificationGroup';
import { RepCode } from './repCode';

export enum AuthAudienceTypes {
  CLIENT = 'CLIENT',
  ONEHUB = 'ONEHUB',
}

export enum OrganizationUserAccessTypes {
  ORGANIZATION = 'ORGANIZATION',
  ENTITY = 'ENTITY',
}

export enum OrganizationUserEntityRelationTypes {
  ADVISOR = 'ADVISOR',
  OTHER = 'OTHER',
  OWNER = 'OWNER',
  POWER_OF_ATTORNEY = 'POWER_OF_ATTORNEY',
}

export interface OrganizationUserEntity {
  entity?: User,
  readOnly?: boolean,
  relation?: OrganizationUserEntityRelationTypes,
}

export interface OrganizationUserRepCodeEntity {
  entity?: User,
  repCodes?: RepCode[],
}

export interface NotificationPreference {
  enableInApp?: boolean,
  enablePush?: boolean,
  enableEmail?: boolean,
}

export interface UserNotificationPreference {
  notificationGroup: NotificationGroup,
  preferences?: NotificationPreference,
}

export interface OrganizationUser {
  id?: string,
  organization?: Organization,
  firstName?: string,
  lastName?: string,
  email?: string,
  phone?: string,
  avatar?: string,
  language?: Languages,
  authAudience?: AuthAudienceTypes,
  role?: Role,
  auth0invitationId?: string,
  auth0invitationExpiresAt?: Date,
  authConnectionType?: AuthenticationConnections,
  accessType?: OrganizationUserAccessTypes,
  entities?: OrganizationUserEntity[],
  referenceId?: string,
  lastLoggedInAt?: Date,
  mfaEnrollmentId?: string,
  notificationPreferences?: UserNotificationPreference[],
  repCodes?: RepCode[],
  repCodeEntities?: OrganizationUserRepCodeEntity[],
}

export const getOrganizationUserName = (organizationUser?: OrganizationUser): string => (
  `${organizationUser?.firstName || ''} ${organizationUser?.lastName || ''}`.trimEnd()
);

export enum OrganizationUserLoginStatus {
  LOGGED_IN = 'LOGGED_IN',
  NOT_LOGGED_IN = 'NOT_LOGGED_IN',
  INVITED = 'INVITED',
  INVITATION_EXPIRED = 'INVITATION_EXPIRED',
  NOT_INVITED = 'NOT_INVITED',
}

export const mapOrganizationUserLoginStatus = (user: OrganizationUser): OrganizationUserLoginStatus => {
  if (user.referenceId && user.lastLoggedInAt) {
    return OrganizationUserLoginStatus.LOGGED_IN;
  }
  if (user.auth0invitationId && dayjs(user.auth0invitationExpiresAt).isBefore(dayjs())) {
    return OrganizationUserLoginStatus.INVITATION_EXPIRED;
  } if (user.auth0invitationId) {
    return OrganizationUserLoginStatus.INVITED;
  } if (!user.auth0invitationId && user.authConnectionType === AuthenticationConnections.EMAIL_AND_PASSWORD) {
    return OrganizationUserLoginStatus.NOT_INVITED;
  }
  return OrganizationUserLoginStatus.NOT_LOGGED_IN;
};

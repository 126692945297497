import { useTranslation } from 'react-i18next';
import { Box } from '../../../1-primative';
import {
  Button, Form,
} from '../../../2-component';
import { SettingsCard } from '../../../3-pattern';
import { FeatureFlag, useFeatureFlags } from '../../../4-module/featureFlag/featureFlag';

export const StatementsAndTaxDocumentsSettings = () => {
  const { t } = useTranslation(['settings', 'shared']);
  const {
    featureFlags, updateFeatureFlag, loading, saveFeatureFlags,
  } = useFeatureFlags();

  return (
    <SettingsCard
      title={t('statementsAndTaxDocuments.title')}
      description={t('statementsAndTaxDocuments.description')}
    >
      <Form onSubmit={saveFeatureFlags}>
        <Box display='flex' flexDirection='column' gap={2}>
          <FeatureFlag
            underline
            featureFlag={featureFlags.find((ff) => ff.key === 'CONFIGURABLE_CLIENT_REPORTS')}
            onChange={updateFeatureFlag}
            testId='settings-general-CONFIGURABLE_CLIENT_REPORTS-toggle'
          />
          <FeatureFlag
            underline
            featureFlag={featureFlags.find((ff) => ff.key === 'ENABLE_ONEVEST_AGREEMENTS')}
            onChange={updateFeatureFlag}
            testId='settings-general-ENABLE_ONEVEST_AGREEMENTS-toggle'
          />
          <FeatureFlag
            underline
            featureFlag={featureFlags.find((ff) => ff.key === 'ONEVEST_STATEMENT')}
            onChange={updateFeatureFlag}
            testId='settings-general-ONEVEST_STATEMENT-toggle'
          />
          <FeatureFlag
            underline
            featureFlag={featureFlags.find((ff) => ff.key === 'CUSTODIAN_STATEMENT')}
            onChange={updateFeatureFlag}
            testId='settings-general-CUSTODIAN_STATEMENT-toggle'
          />
          <FeatureFlag
            underline
            featureFlag={featureFlags.find((ff) => ff.key === 'VIEW_AGREEMENTS')}
            onChange={updateFeatureFlag}
            testId='settings-general-VIEW_AGREEMENTS-toggle'
          />
          <FeatureFlag
            underline
            featureFlag={featureFlags.find((ff) => ff.key === 'VIEW_STATEMENTS')}
            onChange={updateFeatureFlag}
            testId='settings-general-VIEW_STATEMENTS-toggle'
          />
          <FeatureFlag
            featureFlag={featureFlags.find((ff) => ff.key === 'VIEW_TAX_DOCUMENTS')}
            onChange={updateFeatureFlag}
            testId='settings-general-VIEW_TAX_DOCUMENTS-toggle'
          />
          <Box display='flex' justifyContent='flex-end'>
            <Button type='submit' disabled={loading} label={t('shared:save')} dataTestId='settings-general-submit-button'/>
          </Box>
        </Box>
      </Form>
    </SettingsCard>
  );
};

import { useTranslation } from 'react-i18next';
import {
  ReactElement, useContext, useEffect, useState,
} from 'react';
import { groupBy, isUndefined } from 'lodash/fp';
import { gql, useQuery } from '@apollo/client';
import { Typography, Box } from '../../../1-primative';
import {
  Card, CardContent, EmptyStateAlt, MenuItem, SelectField,
} from '../../../2-component';
import { UserContext, usePermissions } from '../../../../providers/userContextProvider';
import {
  Account, AccountStates, NavigationStyles, Relationship,
} from '../../../../interfaces';
import { usePageState } from '../../../../util/usePageState';
import { PageObjectType } from '../../../5-page';
import { AccountCards } from './components/accountCards';
import { AccountTable } from './components/accountTable';
import { translateBackend } from '../../../../assets/i18n/config';
import { ClientContext } from '../../../../pages/client';
import { HouseholdContext } from '../../../../pages/household';
import { generateClientInitials, generateClientNameString } from '../../../../util';
import { FilterModal } from '../../../3-pattern';
import { CustomFieldSource } from '../../../../interfaces/customField';
import { STATE_CHANGES_FIELD } from '../../../../util/reusableGraphQLFields';
import { ACCOUNT_ITEM_CARD_HEIGHT } from '../../accountItem/accountItem';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';
import { useLocalization } from '../../../../util/useLocalization';

const SHARED_CUSTODIAN_STATS = (currency: string | undefined = undefined) => `#graphql
  custodianStatistics ${currency ? `(input: {currency: ${currency}})` : ''} {
    marketValueCents
    currency
    simpleReturnAmountCents
    simpleReturnPercent
    timeWeightedReturn
    lastUpdatedAt
    moneyWeightedReturn
    originalCurrencies { currency marketValueCents }
    holdings {
      adjustedCostBaseCents
      quantity
      totalCents
      financialProduct { ticker isCash }
      originalCurrency { currency totalCents }
    }
  }
`;
const SHARED_STATS = (forUserId = undefined) => `#graphql
  id
  statistics(input: {currency: $currency},${forUserId ? `forUserId:"${forUserId}"` : ''}) {
    marketValueCents
    currency
    simpleReturnPercent(period: ALL_TIME)
    simpleReturnAmount(period: ALL_TIME)
    timeWeightedReturn(period: ALL_TIME)
    moneyWeightedReturn(period: ALL_TIME)
    #TODO: Add lastUpdatedAt in stats-service
    #lastUpdatedAt
    holdings {
      totalCents
      adjustedCostBaseCents
      quantity
      financialProduct { ticker isCash }
      originalCurrency { currency totalCents }
      userBookValue
      goalBookValue
      accountBookValue
      subAccountBookValue
      householdClientGroupBookValue
    }
  }
`;

const SHARED_CONTEXT_CUSTODIAN_STATS = (currency: string | undefined = undefined) => `#graphql
  custodianStatistics ${currency ? `(input: {currency: ${currency}})` : ''} {
    marketValueCents
  }
`;

const SHARED_CONTEXT_STATS = `#graphql
  statistics(input: {currency: $currency}) {
    marketValueCents
  }
`;

export const FETCH_ACCOUNTS = (
  permissions: string[],
  useExternalStatisticsEnabled = undefined,
  currency: string | undefined = undefined,
  customFieldKeys: string[] | undefined = undefined,
  custodianCustomFields = undefined,
) => gql`
  query fetchAccounts($input: FetchAccountsInput!, ${useExternalStatisticsEnabled ? '' : '$currency: StatisticsCurrencyTypes,'}) {
    fetchAccounts(input: $input) {
      totalCount
      accounts {
        id
        type
        state
        baseCurrency
        availableCurrencies
        custodianSortingOrderPriority
        ${STATE_CHANGES_FIELD}
        tlhEnabled
        nickName
        translatedNickName { en fr }
        ${permissions.includes('read:account_number') ? 'custodianAccountNumber' : ''}
        ${permissions.includes('read:rep_code') ? 'repCode { code name }' : ''}
        custodianAccountName
        ${useExternalStatisticsEnabled ? SHARED_CUSTODIAN_STATS(currency) : SHARED_STATS()}
        ${(customFieldKeys ?? []).length > 0 ? `customFields(keys: ${JSON.stringify(customFieldKeys)}) {
          key
          value
          customField { id format key type }
        }` : ''}
        ${custodianCustomFields && useExternalStatisticsEnabled ? `custodianCustomFields {
          key
          value
        }` : ''}
        subAccounts {
          id
          state
          theme {
            translatedName { en fr }
          }
        }
        affiliations {
          type
          user { id firstName }
        }
        incompleteFormAgreements { id }
        user {
          id
          ${permissions.includes('read:client_low_risk_pii') ? 'firstName entityName' : ''}
          organization { availableFeatureFlags }
          ${useExternalStatisticsEnabled ? SHARED_CONTEXT_CUSTODIAN_STATS(currency) : SHARED_CONTEXT_STATS}
        }
        householdClientGroup {
          id
          name
          ${useExternalStatisticsEnabled ? SHARED_CONTEXT_CUSTODIAN_STATS(currency) : SHARED_CONTEXT_STATS}
          relationships {
            type
            user {
              id
              state
              ${permissions.includes('read:client_low_risk_pii') ? 'firstName middleName lastName primaryEmail' : ''}
              ${permissions.includes('read:client_high_risk_pii') ? 'phone' : ''}
              ${permissions.includes('read:client_suitability') ? 'financialLiquidAssetsCents financialFixedAssetsCents totalDebtCents' : ''}
              ${permissions.includes('read:client_low_risk_pii') ? 'entityName' : ''}
              type
            }
            accessType
          }
        }
      }
    }
  }
`;

const baseSimplifiedStatusFilter = {
  ACTIVE: true,
  PENDING: true,
  CLOSED: false,
};

interface AccountToSort extends Account {
  sortAccountNumber?: string
  holder?: string
}

export const Accounts = ({
  objectType, objectId, newAccountComponent, singleColumn = false, options,
}: {
  objectType: PageObjectType, objectId: string, newAccountComponent?: ReactElement, singleColumn?: boolean, options: any,
}) => {
  const PAGE_SIZE = singleColumn ? 3 : 15;
  const { sys } = useThemeTokens();
  const { localizedDateTime } = useLocalization();
  const useSimplifiedAccountStatusFilter = [PageObjectType.HOUSEHOLD, PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL].includes(objectType) && !!options.simplifiedAccountStatusFilter;

  const { t } = useTranslation(['client']);
  const { permissions } = usePermissions();
  const { activeCurrency, custodianConnection, userContext } = useContext(UserContext);
  const [page, setPage] = usePageState(1, 'accountsPage');

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(PAGE_SIZE);
  const [filterByState, setFilterByState] = useState<Record<string, boolean>>(() => (useSimplifiedAccountStatusFilter ? ({
    [AccountStates.ACTIVE]: baseSimplifiedStatusFilter.ACTIVE,
    [AccountStates.REQUESTED]: baseSimplifiedStatusFilter.PENDING,
    [AccountStates.FROZEN]: baseSimplifiedStatusFilter.PENDING,
    [AccountStates.INITIATED]: baseSimplifiedStatusFilter.PENDING,
    [AccountStates.READY]: baseSimplifiedStatusFilter.PENDING,
    [AccountStates.FAILED]: baseSimplifiedStatusFilter.CLOSED,
    [AccountStates.INACTIVE]: baseSimplifiedStatusFilter.CLOSED,
    [AccountStates.CANCELED]: baseSimplifiedStatusFilter.CLOSED,
  }) : ({
    [AccountStates.ACTIVE]: isUndefined(options.filterForActive) ? true : options.filterForActive,
    [AccountStates.REQUESTED]: isUndefined(options.filterForRequested) ? true : options.filterForRequested,
    [AccountStates.FROZEN]: isUndefined(options.filterForFrozen) ? true : options.filterForFrozen,
    [AccountStates.FAILED]: isUndefined(options.filterForFailed) ? true : options.filterForFailed,
    [AccountStates.INITIATED]: isUndefined(options.filterForInitiated) ? true : options.filterForInitiated,
    [AccountStates.READY]: isUndefined(options.filterForReady) ? true : options.filterForReady,
    [AccountStates.INACTIVE]: false,
    [AccountStates.CANCELED]: false,
  })));
  const [filterBySimplifiedStatus, setFilterBySimplifiedStatus] = useState<Record<string, boolean>>(baseSimplifiedStatusFilter);

  const isUnifiedClientExperience = userContext.role?.navigationStyle === NavigationStyles.SIMPLE;
  const useExternalStatisticsEnabled = isUnifiedClientExperience ? options.useExternalStatisticsEnabled : options.useExternalStatisticsEnabled && custodianConnection?.enableFetchCustodianStatistics;

  const setSimplifiedFilter = (value: any) => {
    const newFilterBySimplifiedStatus = Object.fromEntries(Object.keys(filterBySimplifiedStatus).map((key) => [key, value.includes(key)]));
    setFilterBySimplifiedStatus({ ...newFilterBySimplifiedStatus });
    setFilterByState({
      ACTIVE: newFilterBySimplifiedStatus.ACTIVE,
      REQUESTED: newFilterBySimplifiedStatus.PENDING,
      FROZEN: newFilterBySimplifiedStatus.PENDING,
      INITIATED: newFilterBySimplifiedStatus.PENDING,
      READY: newFilterBySimplifiedStatus.PENDING,
      FAILED: newFilterBySimplifiedStatus.CLOSED,
      INACTIVE: newFilterBySimplifiedStatus.CLOSED,
      CANCELED: newFilterBySimplifiedStatus.CLOSED,
    });
  };

  const customFieldsKeys = options.display === 'table' && options.accountTable.filter(
    (elem: any) => 'customField' in elem && elem.customField.externalField !== CustomFieldSource.CUSTODIAN,
  ).map((elem: any) => elem.customField.key);

  const hasCustodianCustomFieldsOption = options.display === 'table' && options.accountTable.some(
    (elem: any) => 'customField' in elem && elem.customField.externalField === CustomFieldSource.CUSTODIAN,
  );

  const { loading } = useQuery(FETCH_ACCOUNTS(permissions, useExternalStatisticsEnabled, activeCurrency, customFieldsKeys, hasCustodianCustomFieldsOption), {
    fetchPolicy: options.cacheCustodianAccounts ? 'cache-first' : 'cache-and-network',
    errorPolicy: 'ignore',
    variables: {
      input: {
        filter: {
          ...([PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL].includes(objectType) && { userId: objectId }),
          ...(objectType === PageObjectType.HOUSEHOLD && { clientGroupId: objectId }),
          includeHousehold: objectType === PageObjectType.HOUSEHOLD,
          states: Object.keys(filterByState).filter((key) => filterByState[key]),
        },
        pagination: { perPage: 1000 },
      },
      currency: activeCurrency,
      skipErrorHandler: true,
    },
    onCompleted: (e) => {
      setAccounts([...e.fetchAccounts.accounts]);
      setTotalCount(e.fetchAccounts.totalCount);
    },
  });

  const lastUpdatedAt = accounts
    .flatMap((account) => (useExternalStatisticsEnabled ? account.custodianStatistics?.lastUpdatedAt ?? [] : account.statistics?.lastUpdatedAt ?? []))
    .reduce<string | null>((max, date) => (max === null || new Date(date) > new Date(max) ? date : max), null);

  const getAccountHolders = (account: Account) => {
    const names = [
      account.user.firstName,
      ...(account?.affiliations ?? []).filter((aff: any) => ['JOINT', 'AUTHORIZED_INDIVIDUAL', 'TRUSTEE'].includes(aff.type)).map((affiliation: any) => affiliation.user.firstName),
    ];
    return names.join(', ');
  };

  let accountsSorted: AccountToSort[] = [];
  const hasCustodianSortingOrderPriority = accounts.every((acc) => acc.custodianSortingOrderPriority);

  if (hasCustodianSortingOrderPriority) {
    const accountsToSort: AccountToSort[] = accounts.map((account) => ({
      ...account,
      sortAccountNumber: account.custodianAccountNumber?.slice(0, -1),
      holder: getAccountHolders(account),
    }));
    const accountsGroupedByHolder = groupBy('holder', accountsToSort);
    const accountsGroupedByHolderAndNumber: any = {};

    Object.keys(accountsGroupedByHolder).forEach((key) => {
      accountsGroupedByHolderAndNumber[key] = groupBy('sortAccountNumber', accountsGroupedByHolder[key].sort((a, b) => (
        (a.custodianSortingOrderPriority ?? 0) - (b.custodianSortingOrderPriority ?? 0)
      )));
    });
    Object.keys(accountsGroupedByHolderAndNumber).forEach((outerKey) => {
      Object.keys(accountsGroupedByHolderAndNumber[outerKey]).forEach((innerKey) => {
        accountsGroupedByHolderAndNumber[outerKey][innerKey].forEach((elem: AccountToSort) => {
          accountsSorted.push(elem);
        });
      });
    });
  } else {
    accountsSorted = accounts.sort((a, b) => {
      if (a.custodianAccountNumber && b.custodianAccountNumber) {
        return a.custodianAccountNumber.localeCompare(b.custodianAccountNumber);
      }
      return (b.statistics?.marketValueCents ?? 0) - (a.statistics?.marketValueCents ?? 0);
    });
  }

  const accountsPaginated = accountsSorted.slice((page - 1) * perPage, page * perPage);

  useEffect(() => {
    setPage(1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByState]);

  const HeaderIcons = () => (
    <Box display='flex'>
      {options.filterEnabled && (
        <Box>
          <FilterModal
            testIds={{ dialogOpenButton: 'filter-modal-open-button', dialogSubmitButton: 'filter-modal-submit-button', dialogCloseButton: 'filter-modal-close-button' }}
            filterExists={true}
          >
            <SelectField
              fullWidth
              label={t('accountsDetail:state')}
              value={useSimplifiedAccountStatusFilter
                ? Object.keys(filterBySimplifiedStatus).filter((key) => filterBySimplifiedStatus[key])
                : Object.keys(filterByState).filter((key) => filterByState[key])
              }
              multiple
              onChange={(e: any) => {
                useSimplifiedAccountStatusFilter
                  ? setSimplifiedFilter(e.target.value)
                  : setFilterByState(Object.fromEntries(Object.keys(filterByState).map((key) => [key, e.target.value.includes(key)])));
              }}
            >
              {useSimplifiedAccountStatusFilter ? (
                Object.keys(filterBySimplifiedStatus).map((state, index) => (
                  <MenuItem key={index} value={state}>{t(`accountsDetail:accountState.${state}`)}</MenuItem>
                ))
              ) : (
                [
                  ...(isUndefined(options.filterForActive) || options.filterForActive) ? [AccountStates.ACTIVE] : [],
                  ...(isUndefined(options.filterForRequested) || options.filterForRequested) ? [AccountStates.REQUESTED] : [],
                  ...(isUndefined(options.filterForFrozen) || options.filterForFrozen) ? [AccountStates.FROZEN] : [],
                  ...(isUndefined(options.filterForFailed) || options.filterForFailed) ? [AccountStates.FAILED] : [],
                  ...(isUndefined(options.filterForInitiated) || options.filterForInitiated) ? [AccountStates.INITIATED] : [],
                  ...(isUndefined(options.filterForReady) || options.filterForReady) ? [AccountStates.READY] : [],
                  ...(isUndefined(options.filterForClosed) || options.filterForClosed) ? [AccountStates.INACTIVE] : [],
                  ...(isUndefined(options.filterForCanceled) || options.filterForCanceled) ? [AccountStates.CANCELED] : [],
                ].map((state, index) => <MenuItem key={index} value={state}>{t(`accountsDetail:accountState.${state}`)}</MenuItem>))
              }
            </SelectField>
          </FilterModal>
        </Box>
      )}
      {options.addNewAccountEnabled && <>{newAccountComponent}</>}
    </Box>
  );

  return (
    <>
      <Box display="flex"flexDirection='column' justifyContent="space-between" pb={2} minHeight='40px'>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems={'center'}>
            <Typography data-testid='accounts-title' variant='headingSmall'>
              {options.customTitle || options.accountsCustomTitle ? translateBackend(options.customTitle ?? options.accountsCustomTitle) : t('accountsSummary.header')}
            </Typography>
          </Box>
          {options.display === 'cards' && (<HeaderIcons />)}
        </Box>
        {options?.showLastUpdatedTimestamp && (
          <Box display="flex" alignItems='center'>
            <Typography data-testid='accounts-last-updated-title' variant='bodySmall' sx={{ color: sys.color.outline, mr: 0.5 }}>
              {`${translateBackend(options.lastUpdatedTimestampLabel)}: ${lastUpdatedAt ? localizedDateTime(lastUpdatedAt) : ''}`}
            </Typography>
          </Box>
        )}
      </Box>
      {loading ? (
        options.display === 'table' ? (
          <Card sx={{ overflowX: 'auto' }}>
            <CardContent>
              <Box display='flex' justifyContent='end'>
                <HeaderIcons />
              </Box>
            </CardContent>
            <AccountTable
              perPage={perPage}
              setPerPage={setPerPage}
              data-testid='accounts-table'
              accountsPaginated={accountsPaginated}
              accounts={accountsSorted}
              singleColumn={singleColumn}
              totalCount={totalCount}
              page={page}
              setPage={setPage}
              loading={loading}
              options={options}
            />
          </Card>
        ) : (
          <AccountCards
            data-testid='accounts-card'
            accounts={accountsPaginated}
            objectType={objectType}
            objectId={objectId}
            singleColumn={singleColumn}
            totalCount={totalCount}
            page={page}
            setPage={setPage}
            loading={loading}
            options={options}
          />
        )
      ) : (
        <ClientContext.Provider value={{
          id: accounts[0]?.user.id,
          orgSettings: accounts[0]?.user.organization,
          totalMarketValueCents: useExternalStatisticsEnabled ? accounts[0]?.user?.custodianStatistics?.marketValueCents ?? 0 : accounts[0]?.user?.statistics?.marketValueCents ?? 0,
        }}>
          <HouseholdContext.Provider value={{
            orgSettings: accounts[0]?.user.organization,
            totalMarketValueCents: useExternalStatisticsEnabled
              ? accounts[0]?.householdClientGroup?.custodianStatistics?.marketValueCents ?? 0
              : accounts[0]?.householdClientGroup?.statistics?.marketValueCents ?? 0,
            members: accounts[0]?.householdClientGroup ? accounts[0]?.householdClientGroup?.relationships.map((rel: Relationship) => ({
              id: rel.user.id,
              initials: generateClientInitials(rel.user),
              name: generateClientNameString(rel.user),
            })) : [],
            indexedMembers: accounts[0]?.householdClientGroup ? Object.fromEntries(accounts[0]?.householdClientGroup.relationships.map((rel: Relationship, index: number) => [rel.user.id, index]))
              : {},
          }}>
            {options.display === 'table' && (
              <Card>
                <CardContent>
                  <Box display='flex' justifyContent='end'>
                    <HeaderIcons />
                  </Box>
                </CardContent>
                <AccountTable
                  perPage={perPage}
                  setPerPage={setPerPage}
                  data-testid='accounts-table'
                  accounts={accountsSorted}
                  accountsPaginated={accountsPaginated}
                  totalCount={totalCount}
                  singleColumn={singleColumn}
                  page={page}
                  setPage={setPage}
                  loading={loading}
                  options={options}
                />
              </Card>
            )}
            {
             options.display === 'cards' && accountsPaginated.length === 0 && (
                <EmptyStateAlt
                  data-testid='accounts-card-empty-state'
                  sx={{ height: ACCOUNT_ITEM_CARD_HEIGHT, width: '100%' }}
                  title={t('accountsSummary.noAccounts')}
                />
             )
            }
            {options.display === 'cards' && (accountsPaginated.length > 0) && (
              <AccountCards
                data-testid='accounts-card'
                accounts={accountsPaginated}
                objectType={objectType}
                objectId={objectId}
                singleColumn={singleColumn}
                totalCount={totalCount}
                page={page}
                setPage={setPage}
                loading={loading}
                useCustodianData={useExternalStatisticsEnabled}
                options={options}
                showSimplifiedState={useSimplifiedAccountStatusFilter}
              />
            )}
          </HouseholdContext.Provider>
        </ClientContext.Provider>
      )}
    </>
  );
};

import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Typography } from '../../1-primative';
import { SelectField, MenuItem } from '../../2-component';
import { usePermissions } from '../../../providers/userContextProvider';

export const FETCH_SINGLE_ROLE_PROFILES = gql`
  query fetchRoleProfiles($input: FetchRoleProfilesInput!) {
    fetchRoleProfiles(input: $input) {
      roleProfiles {
        id
        organization { id }
        translatedName { en }
        translatedDescription { en }
        template {
          permissions
          accessiblePages
          dashboards { id name }
          navigationStyle
          nonIndividualPageConfiguration { id }
          individualPageConfiguration { id }
          goalPageConfiguration { id }
          accountPageConfiguration { id }
          householdPageConfiguration { id }
          subAccountPageConfiguration { id }
          individualTableStaticPageWidgetConfiguration { id }
          nonIndividualTableStaticPageWidgetConfiguration { id }
          householdTableStaticPageWidgetConfiguration { id }
        }
      }
    }
  }
`;

// TODO: Merge with RoleProfileSelect, and ensure usage everywhere is similiar
export const SingleRoleProfileSelect = ({
  onChange, value, label, size, organizationId, readonly = false, parent,
}: {
  onChange: (event: any) => void; value: string | undefined; label: string; size?: 'small' | 'medium' | undefined;
  organizationId?: any; readonly?: boolean; parent?: any;
}) => {
  const { permissions } = usePermissions();
  const {
    loading, error, data, refetch,
  } = useQuery(FETCH_SINGLE_ROLE_PROFILES, {
    variables: {
      input: {
        filter: { organizationId },
        pagination: { perPage: 100 },
      },
    },
    skip: !organizationId,
  });

  useEffect(() => {
    if (organizationId) {
      refetch({
        input: {
          filter: { organizationId },
          pagination: { perPage: 100 },
        },
      });
    }
  }, [organizationId, refetch]);

  if (error) <Typography>Error</Typography>;

  const handleSelectChange = (event: any) => {
    const selectedId = event.target.value;
    const selectedProfile = data?.fetchRoleProfiles?.roleProfiles.find((x: any) => x.id === selectedId);
    if (selectedProfile) {
      const profileData = {
        id: selectedProfile.id,
        permissions: selectedProfile.template.permissions,
        dashboards: selectedProfile.template.dashboards,
        accessiblePages: selectedProfile.template.accessiblePages,
        navigationStyle: selectedProfile.template.navigationStyle,
        nonIndividualPageConfigurationId: selectedProfile.template.nonIndividualPageConfiguration?.id,
        individualPageConfigurationId: selectedProfile.template.individualPageConfiguration?.id,
        goalPageConfigurationId: selectedProfile.template.goalPageConfiguration?.id,
        accountPageConfigurationId: selectedProfile.template.accountPageConfiguration?.id,
        householdPageConfigurationId: selectedProfile.template.householdPageConfiguration?.id,
        subAccountPageConfigurationId: selectedProfile.template.subAccountPageConfiguration?.id,
        individualTableStaticPageWidgetConfigurationId: selectedProfile.template.individualTableStaticPageWidgetConfiguration?.id,
        nonIndividualTableStaticPageWidgetConfigurationId: selectedProfile.template.nonIndividualTableStaticPageWidgetConfiguration?.id,
        householdTableStaticPageWidgetConfigurationId: selectedProfile.template.householdTableStaticPageWidgetConfiguration?.id,
      };
      onChange(profileData);
    } else {
      onChange({
        id: '',
        permissions: [],
        dashboards: [],
        accessiblePages: [],
        nonIndividualPageConfigurationId: undefined,
        individualPageConfigurationId: undefined,
        goalPageConfigurationId: undefined,
        accountPageConfigurationId: undefined,
        householdPageConfigurationId: undefined,
        subAccountPageConfigurationId: undefined,
        individualTableStaticPageWidgetConfigurationId: undefined,
        nonIndividualTableStaticPageWidgetConfigurationId: undefined,
        householdTableStaticPageWidgetConfigurationId: undefined,
      });
    }
  };

  return (
    <>
      {permissions.includes('read:organizations') ? (
        <SelectField
          disabled={readonly}
          value={data && value !== undefined ? value : ''}
          label={label}
          fullWidth
          onChange={handleSelectChange}
          size={size}
          data-testid='RoleProfileSelect-TextField'
        >
          {loading ? (
            <MenuItem>...</MenuItem>
          ) : (
            (data?.fetchRoleProfiles?.roleProfiles || [])?.map((x: any) => (
              <MenuItem key={x.id} value={x.id}>
                {x.translatedName.en}
              </MenuItem>
            ))
          )}
          {parent && (
            <MenuItem key={parent.id} value={parent.id}>
              {parent.translatedName.en}
            </MenuItem>
          )}
        </SelectField>
      ) : (
        <></>
      )}
    </>
  );
};

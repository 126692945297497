import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import { CountryCodes, CurrencyCodes } from '@onevesthq/ov-enums';
import { Box } from '../../../1-primative';
import {
  Button, Chip, Form, MenuItem, SelectField, SettingsSwitch,
} from '../../../2-component';
import { SettingsCard } from '../../../3-pattern';
import { UserContext } from '../../../../providers/userContextProvider';
import { useGlobalToast } from '../../../../providers/globalToastProvider';
import { Localization } from '../../../../interfaces/localization';
import { TaxIdDetails } from './components/taxIdDetails';
import EditOrganizationJurisdictions from './components/editOrganizationJurisdictions';

const FETCH_ORGANIZATION = gql`
  query fetchOrganization($organizationId: String!) {
    fetchOrganization(organizationId: $organizationId) {
      organization {
        id
        name
        useParentLocalizations
        displayCurrency
        localization { id }
        jurisdictions { all only }
        productShelfCurrencies
      }
    }
  }
`;

const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        id
      }
    }
  }
`;

export const FETCH_LOCALIZATIONS_BY_ORG = gql`
  query fetchLocalizationsByOrganization($organizationId: ObjectID!) {
    fetchLocalizations(input: { filter: { organizationId: $organizationId } } ) {
      localizations {
        id
        name
        countries
        defaultCurrency
      }
    }
  }
`;

const PRODUCT_SHELF_AVAILABLE_CURRENCIES = [
  CurrencyCodes.CAD,
  CurrencyCodes.USD,
];

export const LocalizationSettings = () => {
  const { activeOrganization } = useContext(UserContext);
  const { showToast } = useGlobalToast();
  const { t } = useTranslation(['settings', 'shared']);
  const [organization, setOrganization] = useState<any>(null);
  const [localizationId, setLocalizationId] = useState<string>('none');
  const [selectedLocalization, setSelectedLocalization] = useState<any>(null);
  const [applicableCountries, setApplicableCountries] = useState<CountryCodes[]>([CountryCodes.CA]);

  const { data, loading } = useQuery(FETCH_ORGANIZATION, {
    variables: { organizationId: activeOrganization?.id },
  });

  const { data: localizationsData } = useQuery(FETCH_LOCALIZATIONS_BY_ORG, {
    variables: { organizationId: activeOrganization.id },
    fetchPolicy: 'no-cache',
  });

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION, {
    variables: {
      input: {
        organizationId: activeOrganization?.id,
        useParentLocalizations: organization?.useParentLocalizations,
        displayCurrency: organization?.displayCurrency,
        localizationId: localizationId === 'none' ? null : localizationId,
        jurisdictions: {
          all: organization?.jurisdictions?.all,
          only: organization?.jurisdictions?.only ?? undefined,
        },
        productShelfCurrencies: organization?.productShelfCurrencies,
      },
    },
    onCompleted: async () => {
      showToast({
        message: t('shared:successfullySaved'),
        severity: 'success',
      });
    },
  });

  useEffect(() => {
    if (data?.fetchOrganization?.organization) {
      setOrganization(data?.fetchOrganization?.organization);
      setLocalizationId(data?.fetchOrganization?.organization?.localization?.id ?? 'none');
    }
  }, [data]);

  useEffect(() => {
    if (localizationsData?.fetchLocalizations?.localizations) {
      const newLocalization = (localizationsData?.fetchLocalizations.localizations ?? []).find((l: Localization) => l.id === localizationId);
      setSelectedLocalization(newLocalization);
      setApplicableCountries(newLocalization ? newLocalization.countries : [CountryCodes.CA]);
    }
  }, [localizationsData, localizationId]);

  return (
    <SettingsCard
      title={t('localization.title')}
      description={t('localization.description')}
    >
      <Form onSubmit={updateOrganization}>
        <Box display='flex' flexDirection='column' gap={2}>
          <SelectField
            fullWidth
            label={t('localization.localization')}
            value={localizationId}
            onChange={(e: any) => {
              setLocalizationId(e.target.value);
            }}
          >
            <MenuItem value='none'><i>{t('localization.noLocalization')}</i></MenuItem>
            {localizationsData?.fetchLocalizations.localizations.map((l: any) => <MenuItem key={l.id} value={l.id}>
              {l.name} &nbsp; &nbsp;
              <Box component="span" color='gray'>({t('localization.country')}: {l.countries.join(', ')} – {t('localization.currency')}: {l.defaultCurrency})</Box>
            </MenuItem>)}
          </SelectField>
          <EditOrganizationJurisdictions
            organization={organization}
            setOrganization={setOrganization}
            selectedLocalization={selectedLocalization}
          />
          <TaxIdDetails applicableCountries={applicableCountries} />
          <SelectField
            fullWidth
            label={t('localization.productShelfCurrencies')}
            value={organization?.productShelfCurrencies || [CurrencyCodes.CAD]}
            onChange={(e: any) => {
              setOrganization({ ...organization, productShelfCurrencies: e.target.value });
            }}
            multiple
            renderValue={(selected: any) => (
              <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 0.5,
              }}>
                {selected.map((value: string) => (
                  <Chip
                    key={value}
                    text={value}
                  />
                ))}
              </Box>
            )}
          >
            {PRODUCT_SHELF_AVAILABLE_CURRENCIES.map((currency) => (
              <MenuItem
                key={currency}
                value={currency}
                disabled={organization?.productShelfCurrencies?.length === 1
                  && organization?.productShelfCurrencies?.includes(currency)}
              >
                {currency}
              </MenuItem>
            ))}
          </SelectField>
          <SettingsSwitch
            checked={organization?.displayCurrency ?? false}
            onChange={async (e) => setOrganization({ ...organization, displayCurrency: e })}
            label={t('localization.displayCurrency')}
          />
          <Box display='flex' justifyContent='flex-end'>
            <Button type='submit' disabled={loading} label={t('shared:save')} dataTestId='authentication-submit-button'/>
          </Box>
        </Box>
      </Form>
    </SettingsCard>
  );
};

import { TranslatedString } from './shared';

export enum NotificationChannelTypes {
  EMAIL = 'EMAIL',
  IN_APP = 'IN_APP',
  PUSH = 'PUSH',
  WEBHOOK = 'WEBHOOK',
}

export interface NotificationChannel {
  allowEditByClient?: boolean,
  setDefaultState?: boolean,
  type: NotificationChannelTypes,
  value: boolean,
}

export interface NotificationGroup {
  id: string,
  name: TranslatedString,
  description?: TranslatedString,
  notificationChannels: NotificationChannel[],
}

import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState, useEffect } from 'react';
import { Typography, Box, Skeleton } from '../../../1-primative';
import {
  Table, TableHeadCell, TableRow, TableCell, TableBody,
  Pagination, TextField, Button,
  Card,
  CardContent,
} from '../../../2-component';
import { FilterModal, RoleSelect, SettingsTitle } from '../../../3-pattern';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import { usePageState } from '../../../../util/usePageState';
import { AuthAudienceTypes, mapOrganizationUserLoginStatus, OrganizationUser } from '../../../../interfaces/organizationUser';
import SendOrganizationUserInvitations from './components/sendOrganizationUserInvitations';
import { useLocalization } from '../../../../util/useLocalization';
import { NewAccessUser } from './components/newAccessUser';
import EditOrganizationUser from './components/editOrganizationUser';

type Users = Required<{ id: string }>;
export type SearchResults = { users: Users[]; count: number };

const MAX_SEARCH_RESULTS = 50;
export const SEARCH_USERS = (permissions: string[]) => gql`
  query searchOrgUsers($query: String!, $organizationId: ObjectID!, $roleIds: [ObjectID!], $authAudience: AuthAudienceTypes!, $offSet: Int!) {
    fetchOrganizationUsers(input: {
      filter: { searchText: $query, organizationId: $organizationId, roleIds: $roleIds, authAudience: $authAudience }
      pagination: { perPage:${MAX_SEARCH_RESULTS}, sortDesc: true, sortField: "firstName", offSet: $offSet },
    }){
      totalCount
      organizationUsers {
        id
        firstName
        lastName
        language
        email
        phone
        avatar
        referenceId
        lastLoggedInAt
        auth0invitationId
        auth0invitationExpiresAt
        authConnectionType
        mfaEnrollmentId
        role {
          id
          translatedName { en }
          defaultAuthenticationConnection
        }
        accessType
        organization {
          id
          name
          enableMultiFactorAuthentication
          defaultAuthenticationConnection
        }
        ${permissions.includes('read:rep_code') ? 'repCodes { code name }' : ''}
      }
    }
  }
`;

const FETCH_ORG_PENDING_INVITATIONS = gql`
  query fetchOrgPendingInvitations($organizationId: String!) {
    fetchOrganization(organizationId: $organizationId) {
      organization {
        id
        pendingInvitationsCount
      }
    }
  }
`;

export const AccessUsersSettings = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 50;
  const [selectedOrganizationUser, setSelectedOrganizationUser] = useState<OrganizationUser>();
  const [roleId, setRoleId] = usePageState('', 'roleId');
  const [searchValue, setSearchValue] = useState('');
  const [foundUsers, setFoundUsers] = useState<SearchResults>();
  const [sendInvitesDialogOpen, setSendInvitesDialogOpen] = useState(false);
  const { t } = useTranslation(['orgSettings', 'shared', 'settings']);
  const { localizedDateTime } = useLocalization();
  const [searchUsers, {
    data, error, refetch, loading, previousData,
  }] = useLazyQuery(SEARCH_USERS(permissions), {
    notifyOnNetworkStatusChange: true,
  });
  useEffect(() => {
    const searchFn = (query: string): Promise<SearchResults> => new Promise<SearchResults>((resolve) => {
      searchUsers({
        variables: {
          query,
          organizationId: activeOrganization.id,
          authAudience: AuthAudienceTypes.ONEHUB, // exclude Client Auth profiles
          ...(roleId ? { roleIds: roleId } : {}),
          offSet: (page - 1) * pageSize,
        },
        onCompleted: (searchData) => {
          resolve({
            users: searchData.fetchOrganizationUsers.organizationUsers,
            count: Number(searchData.fetchOrganizationUsers.totalCount),
          });
        },
        onError: () => {
          resolve({
            users: [],
            count: 0,
          });
        },
      });
    });
    searchFn(searchValue).then((found) => {
      setFoundUsers(found);
    });
  }, [activeOrganization.id, searchUsers, searchValue, roleId, page, data]);

  const mapStatusText = (user: OrganizationUser) => {
    if (user.referenceId && user.lastLoggedInAt) {
      return localizedDateTime(user.lastLoggedInAt);
    }
    const status = mapOrganizationUserLoginStatus(user);
    return t(`userTable.status.${status}`);
  };

  const pendingInvitationsQuery = useQuery(FETCH_ORG_PENDING_INVITATIONS, {
    variables: {
      organizationId: activeOrganization.id,
    },
  });

  const pendingInvitationsCount = pendingInvitationsQuery.data?.fetchOrganization?.organization?.pendingInvitationsCount ?? 0;
  const canSendInvites = (
    permissions.includes('write:invite_organization_users') || permissions.includes('write:organization_users')
  ) && pendingInvitationsCount > 0;

  const handleSendInviteClick = () => {
    setSendInvitesDialogOpen(true);
  };

  const afterUserCreate = () => {
    refetch();
    pendingInvitationsQuery.refetch();
  };

  if (error) <Typography>Error</Typography>;

  return (
    <>
      <SettingsTitle title={t('settings:accessUsers.title')} description={t('settings:accessUsers.description')} />
      <Card loading={loading}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box display='flex' alignItems='center' gap={1}>
              <TextField
                label=''
                value={searchValue}
                onChange={(e: any) => setSearchValue(e.target.value)}
                fullWidth
                leadingIcon='search'
              />
            </Box>
            <Box display='flex' alignItems='center' gap={1}>
              <FilterModal>
                <RoleSelect
                  includeEmpty
                  value={roleId}
                  label={t('userModal.role')}
                  onChange={(e: any) => {
                    setRoleId(e.target.value);
                  }}
                />
              </FilterModal>
              {canSendInvites && <Button variant='tonal' onClick={handleSendInviteClick} label={t('userTable.inviteUsersButton')} />}
              {permissions.includes('write:organization_users') && <NewAccessUser afterCreate={afterUserCreate} defaultOrg={activeOrganization.id} />}
            </Box>
          </Box>
      </CardContent>
        <Table aria-label='simple table'>
          <TableBody>
            <TableRow>
              <TableHeadCell>{t('userTable.name')}</TableHeadCell>
              <TableHeadCell>{t('userTable.email')}</TableHeadCell>
              <TableHeadCell>{t('userTable.role')}</TableHeadCell>
              <TableHeadCell>{t('userTable.accessType')}</TableHeadCell>
              <TableHeadCell>{t('userTable.organization')}</TableHeadCell>
              <TableHeadCell>{t('userTable.status.header')}</TableHeadCell>
            </TableRow>
            { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
              <TableRow key={i}>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                </TableRow>
            ))}
            {foundUsers?.users?.map((user: OrganizationUser) => (
              <TableRow
                key={user.id}
                hover
                pointer
                onClick={() => {
                  setSelectedOrganizationUser(user);
                  setUpdateDialogOpen(true);
                }}
              >
                <TableCell component='th' scope='row'>
                  {user.firstName} {user.lastName}
                </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user?.role?.translatedName?.en}</TableCell>
                <TableCell>{user.accessType}</TableCell>
                <TableCell>{user?.organization?.name}</TableCell>
                <TableCell>{mapStatusText(user)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={Math.ceil((foundUsers?.count ?? 0) / pageSize)}
          page={page}
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />
        {selectedOrganizationUser && (
          <EditOrganizationUser
            open={updateDialogOpen}
            organizationUserToUpdate={selectedOrganizationUser}
            afterUpdate={() => {
              setUpdateDialogOpen(false);
              refetch();
              pendingInvitationsQuery.refetch();
            }}
            handleClose={() => setUpdateDialogOpen(false)}
          />
        )}
        {canSendInvites && sendInvitesDialogOpen && (
          <SendOrganizationUserInvitations
            open={sendInvitesDialogOpen}
            pendingUsersCount={pendingInvitationsCount}
            onComplete={() => {
              setSendInvitesDialogOpen(false);
              refetch();
              pendingInvitationsQuery.refetch();
            }}
            handleClose={() => setSendInvitesDialogOpen(false)}
          />
        )}
      </Card>
    </>
  );
};

import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from '../../../../3-pattern';

export const ARCHIVE_ROLE_PROFILE = gql`
mutation transitionRoleProfile($input: TransitionRoleProfileInput!) {
  transitionRoleProfile(input: $input) {
    roleProfile {
        id
        state
    }
  }
}
`;

const ArchiveRoleProfile = ({
  open,
  roleProfileToArchive,
  afterDelete,
  handleClose,
}: {
  open: boolean,
  roleProfileToArchive: any,
  afterDelete: () => void,
  handleClose: () => void
}) => {
  const { t } = useTranslation(['orgSettings']);

  const [archiveRoleProfile, { loading }] = useMutation(ARCHIVE_ROLE_PROFILE, {
    variables: {
      input: {
        roleProfileId: roleProfileToArchive.id,
        transition: 'archive',
      },
    },
  });

  if (!roleProfileToArchive) {
    return <></>;
  }

  return (
    <ConfirmationModal
      onCancel={handleClose}
      onConfirm={async () => { await archiveRoleProfile(); afterDelete(); } }
      open={open}
      loading={loading}
      title={`${t('roleProfileArchiveModal.archiveRoleProfile')} ${roleProfileToArchive.translatedName.en}?`}
      bodyText={`${t('roleProfileArchiveModal.archiveConfirmation')} ${t('roleProfileArchiveModal.wantToProceed')}`}
    >
    </ConfirmationModal>
  );
};

export default ArchiveRoleProfile;

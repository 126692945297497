import type { SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Box, Link, Typography } from '../../../../../1-primative';
import { TextField } from '../../../../../2-component';
import { CompletionBox } from '../../../../../3-pattern/completionBox/completionBox';
import type { Affiliation } from '../../../../../../interfaces';
import { useThemeTokens } from '../../../../../../providers/themeTokenProvider';
import { formatMoneyValue } from '../../../../../../util';

const affiliateName = (affiliate: Affiliation) => `${affiliate.user.firstName} ${affiliate.user.lastName}`;

export const AffiliateCompletionBox = ({
  testId = 'affiliate-completion-box', updateMode, affiliate, title, edittable, error, errorText, showRelationship, showAllocation,
  supportText, supportUrl, createOrEditAffiliate, removeAffiliate, onChange, showContributionAmount = false, showGrantAmount = false,
  hideDelete = false,
}: {
  testId?: string, updateMode?: boolean, affiliate: Affiliation, title: string, edittable?: boolean, error?: boolean,
  errorText?: string, showRelationship?: boolean, showAllocation?: boolean, supportText?: string, supportUrl?: string,
  createOrEditAffiliate: (action: 'create' | 'edit', affiliate?: Affiliation) => void,
  removeAffiliate: (affiliateId: string) => void, onChange?: (event: any, affiliateId: string) => void,
  showContributionAmount?: boolean, showGrantAmount?: boolean; hideDelete?: boolean;
}) => {
  const { sys } = useThemeTokens();
  const { t } = useTranslation(['affiliationTypes', 'client']);

  return (
    <CompletionBox
      testId={testId}
      variant={updateMode ? 'view' : 'edit'}
      state='complete'
      edittable={edittable}
      hideDelete={hideDelete}
      title={title}
      onEdit={() => createOrEditAffiliate('edit', affiliate)}
      onRemove={() => removeAffiliate(affiliate.id ?? '')}
      onAdd={() => createOrEditAffiliate('create', affiliate)}
      error={error}
      errorText={errorText}
    >
      <Typography variant='bodyMedium' data-testid={`${testId}-name`}>
        <span style={{ color: sys.color.onSurfaceVariant }}>{t('name')}:</span> <b>{affiliateName(affiliate)}</b>
      </Typography>
      {showRelationship && (
        <Typography variant='bodyMedium' data-testid={`${testId}-relationship`}>
          <span style={{ color: sys.color.onSurfaceVariant }}>{t('relationship')}:</span> <b>{t(`affiliationRelationship.${affiliate.relation}`)}</b>
        </Typography>
      )}
      {showAllocation && edittable && (
        <Box bgcolor={sys.color.surfaceContainerVariant} borderRadius={sys.borderRadius.md} sx={allocationContainerSx} data-testid={`${testId}-allocation-box`}>
          <Typography variant='bodyMedium' data-testid={`${testId}-allocation-text`}>{t('allocation')}</Typography>
          <TextField
            testId='affiliate-allocation'
            type='number'
            trailingIcon='percent'
            value={affiliate.allocation}
            size='small'
            sx={{ width: '100px' }}
            label={''}
            onChange={(e: any) => onChange?.(e, affiliate.id ?? '')}
          />
        </Box>
      )}
      {showAllocation && !edittable && (
        <Typography variant='bodyMedium' data-testid={`${testId}-allocation`}>
          <span style={{ color: sys.color.onSurfaceVariant }}>{t('allocation')}:</span> <b>{affiliate.allocation}%</b>
        </Typography>
      )}
      {!edittable && supportText !== '' && (
        <Typography mt={1} variant='bodyMedium' data-testid={`${testId}-support`}>
          <Link target='_blank' href={supportUrl || 'https://onevest.zendesk.com/hc/en-us/requests/new'}>
            {supportText ?? t('contactSupport')}
          </Link>
        </Typography>
      )}

      {showContributionAmount && !edittable && (
        <Typography variant='bodyMedium' data-testid={`${testId}-contribution-amount`}>
          <span style={{ color: sys.color.onSurfaceVariant }}>{t('contributionAmount')}:</span>
          <b>{formatMoneyValue((affiliate?.contributionAmount ? affiliate.contributionAmount : 0), '$', 2, false) }</b>
        </Typography>
      )}

      {showGrantAmount && !edittable && (
        <Typography variant='bodyMedium' data-testid={`${testId}-grant-amount`}>
          <span style={{ color: sys.color.onSurfaceVariant }}>{t('grantAmount')}:</span> <b>{formatMoneyValue((affiliate.grantAmount ? affiliate.grantAmount : 0), '$', 2, false)}</b>
        </Typography>
      )}
    </CompletionBox>
  );
};

const allocationContainerSx: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '4px 8px 8px 12px',
  ml: -1.5,
  mt: 0.5,
};

import { useTranslation } from 'react-i18next';
import { IntegrationType } from 'interfaces';
import { SelectField, MenuItem } from '../../../../2-component';

const IntegrationsTypeSelect = ({
  value, label, disabled, setIntegrationType,
}: { value: string; label: string; disabled?: boolean; setIntegrationType: (event: any) => void }) => {
  const { t } = useTranslation('devSettings');

  return (
    <SelectField
      required
      disabled={disabled}
      value={value ?? ''}
      label={label}
      fullWidth
      onChange={(e: any) => {
        setIntegrationType(e);
      }}
    >
      {Object.keys(IntegrationType).map((x: any) => (
        <MenuItem key={x.toString()} value={x}>
          {t(`integrationsTableOptions.type.${x}`)}
        </MenuItem>
      ))}
    </SelectField>
  );
};
export default IntegrationsTypeSelect;

import { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../1-primative';
import {
  Button, Dialog, DialogContent, DialogFooter, DialogTitle,
} from '../../../2-component';
import { DELETE_ARTICLE, DELETE_ARTICLE_IMAGE, FETCH_DOCUMENTS } from './queries';

export const DeleteArticle = ({
  open, setOpen, article, refetch, loading,
}: {
  open: boolean, setOpen: (open: boolean) => void, article: any, refetch: () => void, loading: boolean,
}) => {
  const { t } = useTranslation('newsAndInsight');
  const [deleteArticleLoading, setDeleteArticleLoading] = useState(false);

  const [fetchDocuments] = useLazyQuery(FETCH_DOCUMENTS, {
    variables: { filter: { sourceIds: [article.current?.id], types: ['ARTICLE'] } },
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
  });

  const [deleteArticleMutation] = useMutation(DELETE_ARTICLE, { variables: { articleId: article.current?.id ?? '' } });
  const [deleteArticleImageMutation] = useMutation(DELETE_ARTICLE_IMAGE, { variables: { input: { articleId: article.current?.id ?? '' } }, fetchPolicy: 'network-only' });

  const deleteArticle = async () => {
    try {
      setDeleteArticleLoading(true);
      const documentsResponse = await fetchDocuments();
      // Deletes the image first if any
      if ((documentsResponse?.data?.fetchFileDocuments?.fileDocuments?.length ?? 0) > 0) {
        await deleteArticleImageMutation();
      }
      await deleteArticleMutation();

      refetch();
      setOpen(false);
    } catch {
      // empty
    } finally {
      setDeleteArticleLoading(false);
    }
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={() => setOpen(false)}>
      <DialogTitle display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">{t('deleteArticle')}</DialogTitle>
      <DialogContent>
        <Typography variant="bodyLarge">{t('deleteArticleMessage')}</Typography>
      </DialogContent>
      <DialogFooter>
        <Button variant='tonal' disabled={loading || deleteArticleLoading} label={t('cancel')} onClick={() => setOpen(false)} dataTestId='delete-article-cancel-button' />
        <Button disabled={loading || deleteArticleLoading} label={t('confirm')} onClick={() => deleteArticle()} dataTestId='delete-article-delete-button' />
      </DialogFooter>
    </Dialog>
  );
};

import { gql, useQuery } from '@apollo/client';
import { MenuItem } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectField } from '../../2-component';
import { Theme, ThemeState } from '../../../interfaces';
import { translateBackend } from '../../../assets/i18n/config';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';

const FETCH_AVAILABLE_THEMES = gql`
  query fetchAvailableThemes($input: FetchAvailableThemesInput!) {
    fetchAvailableThemes(input: $input) {
      themes {
        translatedName {
          en fr
        }
        translatedDescription {
          en fr
        }
        id
      }
    }
  }
`;

const FETCH_THEMES = gql`
  query fetchThemes($input: FetchThemesInput!) {
    fetchThemes(input: $input) {
      themes {
        id
        translatedName {
          en
        }
        translatedDescription {
          en fr
        }
      }
    }
  }
`;

interface ThemeSelectProp {
  label: string,
  onThemeSelect: (theme: Theme) => void,
  selectedTheme: Theme | undefined,
  sx?: any,
  size?: 'small' | 'medium',
  suitabilityScore?: number,
  disabled?: boolean,
}

export const ThemeSelect = ({
  label, onThemeSelect, selectedTheme, sx = {}, size, suitabilityScore, disabled,
}: ThemeSelectProp) => {
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation('components');
  const { permissions } = usePermissions();
  const doReadAllThemes = permissions.includes('read:all_themes');
  const [noSuitabilityScore, setNoSuitabilityScore] = useState(false);

  useEffect(() => {
    if (!doReadAllThemes && !suitabilityScore) {
      setNoSuitabilityScore(true);
    } else {
      setNoSuitabilityScore(false);
    }
  }, [doReadAllThemes, suitabilityScore]);

  const { data, loading } = useQuery(doReadAllThemes ? FETCH_THEMES : FETCH_AVAILABLE_THEMES, {
    skip: !doReadAllThemes && !suitabilityScore,
    variables: {
      // fetchAvailableThemes
      input: !doReadAllThemes ? {
        suitabilityScore,
        organizationId: activeOrganization.id,
      } : {
        // fetchThemes
        filter: { organizationId: activeOrganization.id, state: ThemeState.ACTIVE },
        pagination: { perPage: 1000 },
      },
    },
  });
  const themesResponse = doReadAllThemes ? data?.fetchThemes : data?.fetchAvailableThemes;

  return (
    <SelectField
      onChange={(e: any) => onThemeSelect(themesResponse?.themes?.find((item: Theme) => item.id === e.target.value))}
      label={label}
      fullWidth
      sx={sx}
      size={size}
      value={selectedTheme?.id ?? ''}
      disabled={loading || disabled}
      error={noSuitabilityScore}
      errorText={t('changeTheme.noSuitabilityScore')}
    >
      {
        themesResponse?.themes?.map((theme: Theme) => (
          <MenuItem value={theme.id}>{translateBackend(theme.translatedName)}</MenuItem>
        )) ?? []
      }
    </SelectField>
  );
};

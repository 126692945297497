import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Box } from '../../1-primative';
import {
  Button, Form, MenuItem, SelectField, TextField,
} from '../../2-component';

const CREATE_STATIC_PAGE_WIDGET_CONFIGURATION = gql`
  mutation createStaticPageWidgetConfiguration($input: CreateStaticPageWidgetConfigurationInput!) {
    createStaticPageWidgetConfiguration(input: $input) {
      staticPageWidgetConfiguration { id name widget { id } }
    }
  }
`;

const StaticPageWidgetConfigurationForm = ({ defaultOrg, afterSubmit }: { defaultOrg?: string, afterSubmit: () => void }) => {
  const { t } = useTranslation(['orgSettings']);
  const [staticPageWidgetConfiguration, setStaticPageWidgetConfiguration] = useState({
    name: '', widget: { type: 'INDIVIDUAL_TABLE' }, organizationId: defaultOrg ?? '',
  });

  const [createStaticPageWidgetConfiguration] = useMutation(CREATE_STATIC_PAGE_WIDGET_CONFIGURATION, {
    variables: {
      input: {
        widget: staticPageWidgetConfiguration.widget,
        organizationId: staticPageWidgetConfiguration.organizationId,
        name: staticPageWidgetConfiguration.name,
      },
    },
  });

  const create = async () => {
    await createStaticPageWidgetConfiguration();
    afterSubmit();
  };

  const disabled = !staticPageWidgetConfiguration.name || !staticPageWidgetConfiguration.organizationId || !staticPageWidgetConfiguration.widget.type;

  return (
    <Form onSubmit={create}>
      <TextField fullWidth sx={{ mb: 2 }} label={t('staticPageWidgetConfigurationModal.name')} value={staticPageWidgetConfiguration.name} onChange={(e: any) => {
        setStaticPageWidgetConfiguration({ ...staticPageWidgetConfiguration, name: e.target.value });
      }} />
      <SelectField fullWidth label={''} value={staticPageWidgetConfiguration.widget.type} sx={{ mb: 2 }} onChange={(e: any) => {
        setStaticPageWidgetConfiguration({ ...staticPageWidgetConfiguration, widget: { ...staticPageWidgetConfiguration.widget, type: e.target.value } });
      }}>
        {['INDIVIDUAL_TABLE', 'NON_INDIVIDUAL_TABLE', 'HOUSEHOLD_TABLE'].map((type) => (
          <MenuItem key={type} value={type}>{t(`staticPageWidgetConfigurationModal.types.${type}`)}</MenuItem>
        ))}
      </SelectField>

      <Box display='flex' justifyContent='flex-end' mt={2}>
        <Button type='submit' disabled={disabled} label={t('staticPageWidgetConfigurationModal.create')} />
      </Box>
    </Form>
  );
};

export default StaticPageWidgetConfigurationForm;

import { useState } from 'react';
import {
  gql, useMutation, useQuery,
} from '@apollo/client';
import { ReviewPortfolioChangesVisual } from './reviewPortfolioChanges.visual';
import { Goal, GoalStates } from '../../../../../interfaces';

export enum PortfolioSelectionType {
  CURRENT = 'CURRENT',
  SUGGESTED = 'SUGGESTED',
}

const UPDATE_SUB_ACCOUNT = gql`
  mutation updateSubAccount($input: UpdateSubAccountInput!) {
    updateSubAccount(input: $input) {
      subAccount {
        id
      }
    }
  }
`;

const UPDATE_GOAL = gql`
  mutation updateGoal($input: UpdateGoalInput!) {
    updateGoal(input: $input) {
      goal {
        id
      }
    }
  }
`;

export const FETCH_GOALS = gql`
  query fetchGoals($input: FetchGoalsInput!) {
    fetchGoals(input: $input) {
      goals {
        id
        name
        type
        financialProduct {
          id
          url
          riskReturnLevel
          suggestable
          theme {
            translatedName { en fr}
            translatedDescription { en fr}
          }
        }
        suggestedFinancialProduct {
          id
          url
          riskReturnLevel
          theme {
            translatedName { en fr}
            translatedDescription { en fr}
          }
        }
        subAccounts {
          id
          state
          account {
            type
          }
          financialProduct {
            id
            url
            riskReturnLevel
            suggestable
            theme {
              translatedName { en fr}
              translatedDescription { en fr}
            }
          }
          suggestedFinancialProduct {
            id
            url
            riskReturnLevel
            theme {
              translatedName { en fr}
              translatedDescription { en fr}
            }
          }
        }
      }
    }
  }
`;

export const ReviewPortfolioChanges = ({
  options, userId, onNext, stepLoading,
}: { options: any, userId: string, onNext: () => void, stepLoading: boolean }) => {
  const [goals, setGoals] = useState<Goal[]>([]);
  const { loading } = useQuery(FETCH_GOALS, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filter: {
          userId,
          states: GoalStates.ACTIVE,
        },
        pagination: {
          perPage: 100,
        },
      },
    },
    onCompleted: async (e) => {
      const updatedGoals = await processPortfolioToUpdate(e.fetchGoals.goals);
      setGoals([...updatedGoals]);
    },
  });

  const processPortfolioToUpdate = async (inputGoals: Goal[]) => {
    const updatedGoals = [...inputGoals];

    for (let i = 0; i < updatedGoals.length; i++) {
      const goal = updatedGoals[i];

      if (goal?.financialProduct) {
        if (updatedGoals[i]) {
          updatedGoals[i].portfolioSelectionType = goal?.suggestedFinancialProduct?.id ? PortfolioSelectionType.SUGGESTED : PortfolioSelectionType.CURRENT;
        }
      }

      if (goal?.subAccounts && goal.subAccounts.length > 0) {
        for (let j = 0; j < goal.subAccounts.length; j++) {
          const subAccount = goal.subAccounts[j];

          if (subAccount?.financialProduct) {
            const currentSubAccount = updatedGoals[i]?.subAccounts?.[j];
            if (currentSubAccount) {
              currentSubAccount.portfolioSelectionType = subAccount?.suggestedFinancialProduct?.id ? PortfolioSelectionType.SUGGESTED : PortfolioSelectionType.CURRENT;
            }
          }
        }
      }
    }

    return updatedGoals;
  };

  const [updateSubAccount, { loading: subAccountLoading }] = useMutation(UPDATE_SUB_ACCOUNT);
  const [updateGoal, { loading: goalLoading }] = useMutation(UPDATE_GOAL);

  const onContinue = async () => {
    if (goals && goals.length > 0) {
      for await (const goal of goals) {
        if (goal.financialProduct?.id !== goal.suggestedFinancialProduct?.id
          && goal.portfolioSelectionType === PortfolioSelectionType.SUGGESTED) {
          await updateGoal({
            variables: {
              input: {
                goalId: goal.id,
                financialProductId: goal.suggestedFinancialProduct?.id,
              },
            },
          });
        }

        if (!goal.financialProduct && goal.subAccounts && goal.subAccounts.length > 0) {
          for await (const subAccount of goal.subAccounts) {
            if (subAccount.financialProduct?.id !== subAccount.suggestedFinancialProduct?.id
              && subAccount.portfolioSelectionType === PortfolioSelectionType.SUGGESTED) {
              await updateSubAccount({
                variables: {
                  input: {
                    subAccountId: subAccount.id,
                    financialProductId: subAccount.suggestedFinancialProduct?.id,
                  },
                },
              });
            }
          }
        }
      }
    }
    onNext();
  };

  return (
    <ReviewPortfolioChangesVisual
      options={options}
      continueFunc={onContinue}
      loading={loading || stepLoading || subAccountLoading || goalLoading}
      goals={goals}
      onUpdateGoals={setGoals}
    />
  );
};

export default ReviewPortfolioChanges;

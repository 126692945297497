import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Jurisdictions } from '@onevesthq/ov-enums';
import { first, uniq } from 'lodash/fp';
import { WorkflowContext, WorkflowContextType, WorkflowDataType } from '../../workflowCompletion';
import {
  Account, AccountStates, AccountTypes, AffiliationRelations, AffiliationTypes, EntityTypes, getAccountTypes, GoalRiskLevels, GoalTimeHorizons, GoalTypes,
  TranslatedString,
} from '../../../../../interfaces';
import { UserContext } from '../../../../../providers/userContextProvider';
import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import {
  UPDATE_ACCOUNT_AFFILIATIONS, CLOSE_ACCOUNT, CLOSE_SUB_ACCOUNT, FETCH_USER, CREATE_ACCOUNT, CREATE_SUB_ACCOUNT, CREATE_GOAL,
} from './createAccount.queries';
import { CreateAccountVisual } from './createAccount.visual';

export interface ContinueFunctionArgs {
  accountType: string;
}

export interface CreateAccountOptionsType {
  title?: TranslatedString;
  subtitle?: TranslatedString;
  displayAccountDefinitions?: boolean;
  autoAssignIndividualAsDirector?: boolean;
  autoAssignAuthorizedIndividual?: boolean;
  autoAssignBeneficialOwner?: boolean;
  options?: any;
}

const ACCOUNT_JURISDICTION_ALLOW_LIST: { [key in AccountTypes]?: Jurisdictions[] } = {
  [AccountTypes.USA_JT_JTBE]: [
    Jurisdictions.US_AK,
    Jurisdictions.US_AR,
    Jurisdictions.US_DE,
    Jurisdictions.US_FL,
    Jurisdictions.US_HI,
    Jurisdictions.US_IL,
    Jurisdictions.US_IN,
    Jurisdictions.US_KY,
    Jurisdictions.US_MD,
    Jurisdictions.US_MA,
    Jurisdictions.US_MI,
    Jurisdictions.US_MS,
    Jurisdictions.US_MO,
    Jurisdictions.US_NJ,
    Jurisdictions.US_NY,
    Jurisdictions.US_NC,
    Jurisdictions.US_OH,
    Jurisdictions.US_OK,
    Jurisdictions.US_OR,
    Jurisdictions.US_PA,
    Jurisdictions.US_RI,
    Jurisdictions.US_TN,
    Jurisdictions.US_VT,
    Jurisdictions.US_VA,
    Jurisdictions.US_WY,
  ],
  [AccountTypes.USA_JT_CP]: [
    Jurisdictions.US_AZ,
    Jurisdictions.US_CA,
    Jurisdictions.US_ID,
    Jurisdictions.US_LA,
    Jurisdictions.US_NM,
    Jurisdictions.US_NV,
    Jurisdictions.US_TX,
    Jurisdictions.US_WA,
    Jurisdictions.US_WI,
  ],
};

const isUserEighteenBeforeBeginningOfTheYear = (dateOfBirthString: string): boolean => {
  const date = new Date(dateOfBirthString);
  const ageUserWillBeThisYear = new Date().getFullYear() - date.getFullYear();
  const ageLastYear = ageUserWillBeThisYear - 1;
  return ageLastYear >= 18;
};

export const setupWorkflowData = ({
  setWorkflowData,
  workflowData,
  data,
  skipEditAccount,
}: {
  setWorkflowData: React.Dispatch<React.SetStateAction<WorkflowDataType>>;
  workflowData: any;
  data: any;
  skipEditAccount?: boolean;
}) => {
  setWorkflowData({
    ...workflowData,
    createdAccounts: [
      ...(workflowData.createdAccounts || []),
      {
        id: data.createSubAccount.subAccount.account.id,
        type: data.createSubAccount.subAccount.account.type,
        state: data.createSubAccount.subAccount.account.state,
      },
    ],
    createdSubAccountIds: [...(workflowData.createdSubAccountIds || []), data.createSubAccount.subAccount.id],
    currentSubAccountId: data.createSubAccount.subAccount.id,
    currentAccountId: data.createSubAccount.subAccount.account.id,
    currentAccountState: data.createSubAccount.subAccount.account.state,
    currentAccountType: data.createSubAccount.subAccount.account.type,
    skipEditAccount,
  });
};

export const CreateAccount = ({
  options, userId, onNext, stepLoading, workflowCompletion,
}: { options: CreateAccountOptionsType; userId: string; onNext: () => void; stepLoading: boolean; workflowCompletion?: any }) => {
  const { t } = useTranslation('workflowCompletions');
  const { workflowData, setWorkflowData, updateWorkflowContext } = useContext(WorkflowContext) as WorkflowContextType;
  const { activeOrganization, userContext } = useContext(UserContext);
  const { showToast } = useGlobalToast();

  const [availableAccountTypes, setAvailableAccountTypes] = useState<AccountTypes[]>([]);
  const [apiFetching, setApiFetching] = useState<boolean>(false);

  const { data: userData, loading: userLoading } = useQuery(FETCH_USER, {
    variables: { userId },
    fetchPolicy: 'no-cache',
  });

  const [updateAccountAffiliations] = useMutation(UPDATE_ACCOUNT_AFFILIATIONS);

  const [closeSubAccount] = useMutation(CLOSE_SUB_ACCOUNT, {
    variables: {
      input: {
        subAccountId: workflowData.currentSubAccountId,
        inactiveReason: 'OTHER',
      },
    },
  });

  const [closeAccount] = useMutation(CLOSE_ACCOUNT, {
    variables: {
      input: {
        accountId: workflowData.currentAccountId,
        inactiveReason: 'OTHER',
      },
    },
    onCompleted: async () => {
      await closeSubAccount();
    },
  });

  const [createSubAccount, { loading }] = useMutation(CREATE_SUB_ACCOUNT, {
    onCompleted: async (data: { createSubAccount: { subAccount: any } }) => {
      if (workflowData.currentAccountType === AccountTypes.CORPORATE_CASH && (options.autoAssignAuthorizedIndividual || options.autoAssignBeneficialOwner || options.autoAssignIndividualAsDirector)) {
        const account = data.createSubAccount?.subAccount?.account;
        autoAssignAffiliations(account);
      }

      setupWorkflowData({ setWorkflowData, workflowData, data });

      if (updateWorkflowContext) await updateWorkflowContext();
      onNext();
    },
  });

  const [createAccount] = useMutation(CREATE_ACCOUNT, {
    variables: {
      input: {
        userId,
        type: workflowData.currentAccountType,
        applyForGovFunds: workflowData.currentAccountType?.includes('RESP') ? ['BASIC_CESG'] : undefined,
        householdClientGroupId: workflowData.currentHouseholdClientGroupId ?? undefined,
      },
    },
    onCompleted: (d: { createAccount: { account: { id: string; type: string } } }) => {
      createSubAccount({
        variables: {
          input: {
            accountId: d.createAccount.account.id,
            accountType: workflowData.currentAccountType,
            goalId: workflowData.currentGoalId,
            userId,
          },
        },
      });
    },
  });

  const [createGoal, { loading: goalLoading }] = useMutation(CREATE_GOAL, {
    variables: {
      input: {
        userId,
        type: GoalTypes.OTHER,
        timeHorizon: GoalTimeHorizons.VERY_LONG_TERM,
        riskQuestion1: GoalRiskLevels.LEVEL_5,
      },
    },
    onCompleted: (data) => {
      setWorkflowData({
        ...workflowData,
        createdGoalIds: [...(workflowData.createdGoalIds || []), data.createGoal.goal.id],
        currentGoalId: data.createGoal.goal.id,
      });
    },
  });

  useEffect(() => {
    if (!workflowData.currentGoalId) {
      createGoal();
    }
  }, [createGoal, workflowData]);

  const userType = userData?.fetchUser.user?.type;
  const userJurisdiction = userData?.fetchUser.user?.physicalAddress?.jurisdiction as Jurisdictions;

  useEffect(() => {
    // types allowed by feature flags & by country
    const allAccountTypes = getAccountTypes(userType, activeOrganization?.availableFeatureFlags, activeOrganization.applicableLocalization?.countries);

    // drop types out of client's jurisdiction
    const availableTypes = allAccountTypes.filter((type) => {
      if (!userJurisdiction) return true;
      const allowList = ACCOUNT_JURISDICTION_ALLOW_LIST[type];
      if (!allowList) return true;
      if (allowList.includes(userJurisdiction)) return true;
      return false;
    });

    // set the available account types
    setAvailableAccountTypes(availableTypes);

    // auto-select when there's only one type
    if (availableTypes.length === 1 && !workflowData.currentAccountType) {
      setWorkflowData({ ...workflowData, currentAccountType: availableTypes[0] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrganization, workflowData.currentGoalType, userType, userJurisdiction, workflowData.currentAccountType]);

  const autoAssignAffiliations = (account: Account) => {
    if (account.affiliations && account.affiliations.length > 0) {
      return;
    }
    const contextIndividuals = (userContext?.entities ?? []).filter((e) => e.entity.type === EntityTypes.INDIVIDUAL) ?? [];
    if (uniq(contextIndividuals.map((entityObj: any) => entityObj?.entity?.id)).length !== 1) {
      /* TODO: should be contextIndividuals.length !== 1, but currently we are experiencing a known
        bug which is entities getting duplicated after newly added
      */
      return;
    }
    const individual = first(contextIndividuals)?.entity;
    const affiliations: any[] = [];
    if (individual) {
      if (options.autoAssignAuthorizedIndividual) {
        affiliations.push({
          relation: AffiliationRelations.Other,
          type: AffiliationTypes.AuthorizedIndividual,
          userId: individual.id,
        });
      }
      if (options.autoAssignIndividualAsDirector) {
        affiliations.push({
          relation: AffiliationRelations.Other,
          type: AffiliationTypes.Director,
          userId: individual.id,
        });
      }
      if (options.autoAssignBeneficialOwner) {
        affiliations.push({
          relation: AffiliationRelations.Other,
          type: AffiliationTypes.BeneficialOwner,
          userId: individual.id,
          allocation: 100,
        });
      }
      if (account.type === AccountTypes.RESP_ADULT) {
        affiliations.push({
          relation: AffiliationRelations.Other,
          type: AffiliationTypes.PrimaryBeneficiary,
          userId: individual.id,
          allocation: 100,
        });
      }
    }
    if (affiliations.length === 0) {
      return;
    }
    updateAccountAffiliations({
      variables: {
        input: { accountId: account.id, affiliations },
      },
      onCompleted: (data: any) => {
        // if entity is incomplete there will be incompleteAffiliations and incompleteFields and affiliations will not be added
        if (data?.updateAffiliations?.incompleteAffiliations && data?.updateAffiliations?.incompleteAffiliations?.length > 0) {
          showToast({ message: t('corporateCashAccountCreation.incompleteAffiliationError'), severity: 'error' });
        }
      },
    });
  };

  const onContinue = async ({ accountType }: ContinueFunctionArgs) => {
    if (accountType === 'RESP_ADULT' && !isUserEighteenBeforeBeginningOfTheYear(userData.fetchUser.user.dateOfBirth)) {
      showToast({ message: t('respAccountCreation.respAgeRestrictionError'), severity: 'error' });
      return;
    }

    setApiFetching(true);
    if (!workflowData.currentAccountId) {
      await createAccount();
    } else if (workflowData.currentAccountId && workflowData.currentAccountState === AccountStates.INITIATED && workflowData.currentAccountType !== accountType) {
      // It should close original account if a new type is selected
      // Because we are creating a 'phantom' goal here - the goal should stay the same

      await closeAccount();
      await createAccount();
      setApiFetching(false);
    } else {
      setApiFetching(false);
      onNext();
    }
  };

  return (
    <CreateAccountVisual
      options={options}
      availableAccountTypes={availableAccountTypes}
      accountType={workflowData.currentAccountType ?? ''}
      continueFunc={onContinue}
      loading={loading || goalLoading || userLoading || stepLoading || apiFetching}
      workflowCompletion={workflowCompletion}
    />
  );
};

export default CreateAccount;

import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { translateBackend } from 'assets/i18n/config';
import remarkGfm from 'remark-gfm';
import { useTranslation } from 'react-i18next';
import CustomFieldRenderer from 'ovComponents/3-pattern/customField/customFieldRenderer';
import { kebabCase } from 'lodash';
import {
  Button, Typography, Form, Box,
} from '../../../..';

export const ApprovalVisual = ({
  options,
  loading,
  continueFunc,
  activeCustomFields,
  approvalData,
  updateApproval,
  ignoreCustomFieldTriggerRules,
}: {
  options: any;
  loading: boolean;
  continueFunc: any;
  activeCustomFields?: string[];
  approvalData?: any;
  updateApproval?: any;
  ignoreCustomFieldTriggerRules?: boolean;
}) => {
  const { t } = useTranslation(['workflowCompletions']);
  const [focused, setFocused] = useState<string[]>([]);
  const submit = () => {
    continueFunc();
  };
  const getMarkdown = (): string => {
    if (options?.filledTemplate) {
      return translateBackend(options?.filledTemplate);
    }
    return translateBackend(options?.template);
  };
  const [customOptionSelected, setCustomOptionSelected] = useState<any[]>([]);
  useEffect(() => {
    const customOptions: any[] = [];
    Object.keys(options).forEach((key: string) => {
      if (options[key]?.customField && options[key].enabled && activeCustomFields?.includes(key)) {
        customOptions.push(options[key]);
      }
    });
    setCustomOptionSelected(customOptions);
  }, [options, activeCustomFields]);

  return (
    <Form onSubmit={submit}>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>
        {translateBackend(options?.title)}
      </Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>
        <ReactMarkdown linkTarget='_blank' remarkPlugins={[remarkGfm]}>
          {translateBackend(options?.subtitle)}
        </ReactMarkdown>
      </Typography>
      <Typography variant='bodyLarge' component={'div'} sx={{ mb: 3, table: { width: '100%' } }}>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{getMarkdown()}</ReactMarkdown>
      </Typography>
      {customOptionSelected && customOptionSelected.length > 0 && (
        <CustomFieldRenderer
          customOptions={customOptionSelected}
          customData={approvalData}
          update={updateApproval}
          focused={focused}
          setFocused={setFocused}
          loading={loading}
          ignoreCustomFieldTriggerRules={ignoreCustomFieldTriggerRules}
        />
      )}
      <Box display='flex' justifyContent='end'>
        <Button
          dataTestId={`approval-${kebabCase(options?.title?.en)}-continue-button`}
          label={t('workflowCompletions:continue')}
          disabled={loading}
          sx={{ mt: 3, textAlign: 'center' }}
          type='submit'
        />
      </Box>
    </Form>
  );
};

export default ApprovalVisual;

import { gql, useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BareLocalization } from '../../../../../interfaces';
import { CreateNewModal } from '../../../../3-pattern';
import { UserContext } from '../../../../../providers/userContextProvider';
import { FETCH_LOCALIZATIONS } from '../localizationConfiguration';
import { LocalizationForm } from './localizationForm';

const CREATE_LOCALIZATION = gql`
  mutation createLocalization($organizationId: ObjectID!, $localization: LocalizationInput!) {
    createLocalization(organizationId: $organizationId, localization: $localization) {
      localization {
        id
      }
    }
  }
`;

const initialLocalization: BareLocalization = {
  name: '',
  countries: [],
  defaultCurrency: '',
  languages: [],
  defaultLanguage: '',
  dateFormat: 'YYYY-MM-DD',
};

export const NewLocalization = () => {
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation('orgSettings');

  const [newLocalization, setNewLocalization] = useState(initialLocalization);

  const [createLocalization, { loading }] = useMutation(CREATE_LOCALIZATION, {
    variables: {
      organizationId: activeOrganization.id,
      localization: newLocalization,
    },
    refetchQueries: [FETCH_LOCALIZATIONS],
  });

  const create = async (event: any) => {
    await createLocalization();
    setNewLocalization(initialLocalization);
  };

  const allFieldsValid = newLocalization.name
    && newLocalization.countries.length > 0
    && newLocalization.defaultCurrency
    && newLocalization.languages.length > 0
    && newLocalization.defaultLanguage
    && newLocalization.dateFormat;

  return (
    <CreateNewModal loading={loading} disabled={!allFieldsValid} title={t('localizationModal.createTitle')} onSubmit={create}>
      <LocalizationForm
        localization={newLocalization}
        setLocalization={setNewLocalization}
      />
    </CreateNewModal >
  );
};

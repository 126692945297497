import dayjs from 'dayjs';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

export enum ObjectType {
  USER = 'Client',
  GOAL = 'Goal',
  ACCOUNT = 'Account',
  SUB_ACCOUNT = 'SubAccount',
  CLIENT_GROUP = 'ClientGroup',
}

const HOLDINGS_ATTRIBUTES = `
  financialProduct {
    id
    url
    translatedName {
      en
      fr
    }
    ticker
    isCash
    ric
    name
    currentPriceCents
    primaryAssetClass{
      id
      key
      translatedName {
        en
        fr
      }
      translatedDescription {
        en
        fr
      }
    }
    secondaryAssetClass {
      id
      key
      translatedName {
        en
        fr
      }
      translatedDescription {
        en
        fr
      }
    }
    tertiaryAssetClass {
      id
      key
      translatedName {
        en
        fr
      }
      translatedDescription {
        en
        fr
      }
    }
  }
  adjustedCostBaseCents
  totalCents
  priceCents
  quantity
  currency
  originalCurrency {
    currency
    totalCents
    priceCents
    adjustedCostBaseCents
  }
`;

const SHARED_STATS = (forUserId = undefined) => `
  id
  statistics(input: {currency: $currency},${forUserId ? `forUserId:"${forUserId}"` : ''}) {
    historySnapshot(date: $date) {
      marketValueCents
      moneyAvailableCents
      currency
      foreignExchangeRates{
        from
        to
        rate
      }
      holdingsMissingRate {
        ${HOLDINGS_ATTRIBUTES}
      }
      netContributionCents
      newContributionCents
      cashOnHoldToWithdrawCents
      cashOnHoldToTradeCents
      holdings {
        ${HOLDINGS_ATTRIBUTES}
      }
    }
    simpleReturnAmount(startDate: $startDate, endDate: $endDate)
    simpleReturnPercent(startDate: $startDate, endDate: $endDate)
    moneyWeightedReturn(startDate: $startDate, endDate: $endDate)
    timeWeightedReturn(startDate: $startDate, endDate: $endDate)
  }
`;

const USER_STATS = () => gql`
  query fetchUserStatistics($userId: ObjectID!, $startDate: Date, $endDate: Date, $date: Date!, $currency: StatisticsCurrencyTypes) {
    fetchUser(userId: $userId) {
      user {
        ${SHARED_STATS()}
      }
    }
  }
`;

export const GOAL_STATS = gql`
  query fetchGoalStatistics($goalId: ObjectID!, $startDate: Date, $endDate: Date, $date: Date!, $currency: StatisticsCurrencyTypes) {
    fetchGoal(goalId: $goalId) {
      goal {
        targetAmountCents
        ${SHARED_STATS()}
      }
    }
  }
`;

export const SUB_ACCOUNT_STATS = gql`
  query fetchSubAccountStatistics($subAccountId: ObjectID!, $startDate: Date, $date: Date!, $endDate: Date, $currency: StatisticsCurrencyTypes) {
    fetchSubAccount(subAccountId: $subAccountId) {
      subAccount {
        ${SHARED_STATS()}
      }
    }
  }
`;

export const ACCOUNT_STATS = () => gql`
  query fetchAccountStatistics($accountId: ObjectID!, $startDate: Date, $endDate: Date, $date: Date!, $currency: StatisticsCurrencyTypes) {
    fetchAccount(accountId: $accountId) {
      account {
        ${SHARED_STATS()}
        user {
          statistics {
            marketValueCents
          }
        }
      }
    }
  }
`;

export const CLIENT_GROUP_STATS = (onlyUserId = undefined) => gql`
  query fetchClientGroupStatistics($clientGroupId: ObjectID!, $startDate: Date, $endDate: Date, $date: Date!, $currency: StatisticsCurrencyTypes) {
    fetchClientGroup(clientGroupId: $clientGroupId) {
      clientGroup {
        ${SHARED_STATS(onlyUserId)}
      }
    }
  }
`;

// TODO: add originalCurrency to holdings
const HISTORY_STATS = `
  date
  marketValueCents
  netContributionCents
`;

export const FETCH_STATS_HISTORY = gql`
  query fetchStatsHistory($input: FetchStatsHistoryInput!) {
    fetchStatsHistory(input: $input) {
      totalCount
      snapshots {
        ${HISTORY_STATS}
      }
    }
  }
`;

const queries: any = {
  Client: USER_STATS,
  Goal: GOAL_STATS,
  Account: ACCOUNT_STATS,
  SubAccount: SUB_ACCOUNT_STATS,
  ClientGroup: CLIENT_GROUP_STATS,
};

const field: any = {
  Client: 'user',
  Goal: 'goal',
  Account: 'account',
  SubAccount: 'subAccount',
  ClientGroup: 'clientGroup',
};

const dailyTypes: any = {
  Client: 'USERS_DAILY',
  Goal: 'GOALS_DAILY',
  Account: 'ACCOUNTS_DAILY',
  SubAccount: 'SUB_ACCOUNTS_DAILY',
  ClientGroup: 'CLIENT_GROUPS_DAILY',
};

const getQuery = ({
  type, onlyUserId,
}: { type: ObjectType, onlyUserId?: string }) => {
  const acceptCustodianData = [ObjectType.USER, ObjectType.ACCOUNT, ObjectType.CLIENT_GROUP].includes(type);
  if (acceptCustodianData) {
    return type !== ObjectType.CLIENT_GROUP
      ? queries[type]()
      : queries[type](onlyUserId);
  }
  return queries[type];
};

export const useHistoricalStats = ({
  type, id, startDate, endDate, onlyUserId, fetchHistory = true, currency,
}: {
  type: ObjectType, id: string, startDate?: string, endDate?: string, onlyUserId?: string, fetchHistory?: boolean, currency?: string,
}) => {
  if (type !== ObjectType.CLIENT_GROUP && onlyUserId) throw new Error('useStats: Only use onlyUserId with type=CLIENT_GROUP');

  const variables: any = {
    Client: { userId: id },
    Goal: { goalId: id },
    Account: { accountId: id },
    SubAccount: { subAccountId: id },
    ClientGroup: { clientGroupId: id },
  };

  const { data, loading, refetch } = useQuery(
    getQuery({
      type, onlyUserId,
    }),
    {
      variables: {
        ...variables[type],
        startDate,
        endDate,
        date: endDate,
        currency,
      },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  );

  const [fetchStatsHistory, { loading: statsHistoryLoading, refetch: historyRefetch }] = useLazyQuery(FETCH_STATS_HISTORY, {
    onCompleted: (historyData: any) => {
      setHistory(historyData.fetchStatsHistory.snapshots);
    },
  });

  const refetchAll = () => {
    refetch();
    if (fetchHistory) historyRefetch();
  };

  const [statistics, setStatistics] = useState<any>({});
  const [history, setHistory] = useState<any>([]);
  const [object, setObject] = useState<any>({});
  useEffect(() => {
    if (data) {
      const firstKey = Object.keys(data)[0];
      const statsObject = data[firstKey][field[type]];
      setStatistics(statsObject.statistics);
      setObject(statsObject);
    }
  }, [data, type, id]);

  useEffect(() => {
    if (fetchHistory) {
      fetchStatsHistory({
        variables: {
          input: {
            type: dailyTypes[type],
            id,
            filter: {
              afterDate: dayjs(startDate).subtract(1, 'day').format('YYYY-MM-DD'),
              beforeDate: dayjs(endDate).add(1, 'day').format('YYYY-MM-DD'),
              userId: onlyUserId,
            },
          },
        },
      });
    }
  }, [type, id, startDate, endDate, onlyUserId, fetchStatsHistory, fetchHistory]);

  return {
    statistics: {
      ...statistics.historySnapshot,
      simpleReturnAmount: statistics.simpleReturnAmount,
      simpleReturnPercent: statistics.simpleReturnPercent,
      moneyWeightedReturn: statistics.moneyWeightedReturn,
      timeWeightedReturn: statistics.timeWeightedReturn,
    },
    loading,
    history,
    historyLoading: statsHistoryLoading,
    refetchAll,
    object,
  };
};

import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useLocation, useParams } from 'react-router-dom';
import { useState, useContext } from 'react';
import { isNil, uniq } from 'lodash/fp';
import { translateBackend } from '../../../../assets/i18n/config';
import { Box, Typography } from '../../../1-primative';
import { PageObjectType } from '../../../5-page';
import { objectTypeMapping } from '../holdings/holdings';
import { ObjectType } from '../../../../providers/statsHooks';
import { AccordionModelPortfolioHoldings } from './components/accordionModelPortfolioHoldings';
import { FinancialProduct, SubAccount } from '../../../../interfaces';
import { getUserIdFromPath } from '../../../../util/getUserIdFromPath';
import { EmptyStateAlt } from '../../../2-component';
import { UserContext } from '../../../../providers/userContextProvider';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';
import { useLocalization } from '../../../../util/useLocalization';

const FINANCIAL_PRODUCT = `
  financialProduct {
    id
    translatedName { en fr }
    url
    theme {
      translatedShortDescription { en fr }
    }
    children {
      id
      percentage
      financialProduct {
        id
        name
        translatedName {
            en
        }
        primaryAssetClass {
          id
          name
          translatedName {
            en
          }
          key
        }
        secondaryAssetClass {
          id
          name
          translatedName {
            en
          }
          key
        }
        tertiaryAssetClass {
          id
          name
          translatedName {
            en
          }
          key
        }
      }
    }
  }
`;

const FETCH_GOAL_FINANCIAL_PRODUCT = (useCustodianHoldingsBreakDown?: string) => gql`
  query fetchGoal($goalId: ObjectID!) {
    fetchGoal(goalId: $goalId) {
      goal {
        id
        ${useCustodianHoldingsBreakDown ? `
        custodianStatistics {
          lastUpdatedAt
          marketValueCents
          holdings {
            financialProduct {
              cusip
            }
          }
        }
        ` : ''}
        ${FINANCIAL_PRODUCT}
        subAccounts {
          id
          account {
            id
            user {
              id
            }
          }
          ${FINANCIAL_PRODUCT}
        }
      }
    }
  }
`;

const FETCH_ACCOUNT_FINANCIAL_PRODUCT = (useCustodianHoldingsBreakDown?: string) => gql`
  query fetchAccount($accountId: ObjectID!) {
    fetchAccount(accountId: $accountId) {
      account {
        id
        ${useCustodianHoldingsBreakDown ? `
        custodianStatistics {
          lastUpdatedAt
          marketValueCents
          holdings {
            financialProduct {
              cusip
            }
          }
        }
        ` : ''}
        subAccounts {
          id
          ${FINANCIAL_PRODUCT}
        }
      }
    }
  }
`;

const FETCH_SUB_ACCOUNT_FINANCIAL_PRODUCT = gql`
  query fetchSubAccount($subAccountId: ObjectID!) {
    fetchSubAccount(subAccountId: $subAccountId) {
      subAccount {
        id
        ${FINANCIAL_PRODUCT}
      }
    }
  }
`;

const FETCH_USER_FINANCIAL_PRODUCT = (useCustodianHoldingsBreakDown?: string) => gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        ${useCustodianHoldingsBreakDown ? `
        custodianStatistics {
          marketValueCents
          lastUpdatedAt
          holdings {
            financialProduct {
              cusip
            }
          }
        }
        ` : ''}
        subAccounts {
          id
          ${FINANCIAL_PRODUCT}
        }
      }
    }
  }
`;

const FETCH_MODEL_PORTFOLIOS = gql`
query FetchModelPortfolios($input: FetchModelPortfoliosInput!) {
  fetchModelPortfolios(input: $input) {
    modelPortfolios {
      id
      name
       url
       translatedName {
        en
        fr
      }
      portfolioDescription
      translatedPortfolioDescription {
        en
      }
      children {
        id
        percentage
        financialProduct {
          name
          translatedName {
            en
          }
          primaryAssetClass {
            id
            key
            name
            translatedName {
              en
            }
          }
          secondaryAssetClass {
            id
            key
            name
            translatedName {
              en
            }
          }
        }
      }
    }
  }
}
`;

const query: any = {
  Client: FETCH_USER_FINANCIAL_PRODUCT,
  Goal: FETCH_GOAL_FINANCIAL_PRODUCT,
  Account: FETCH_ACCOUNT_FINANCIAL_PRODUCT,
  SubAccount: FETCH_SUB_ACCOUNT_FINANCIAL_PRODUCT,
};

const field: any = {
  Client: 'user',
  Goal: 'goal',
  Account: 'account',
  SubAccount: 'subAccount',
};

export const ModelPortfolioHoldings = ({
  objectId,
  objectType,
  options = {},
}: {
  objectId: string,
  objectType: PageObjectType,
  options?: any,
}): JSX.Element => {
  const { t } = useTranslation(['client']);
  const { activeOrganization } = useContext(UserContext);

  const [fetchModelPortfolios, { loading: modelPortfoliosLoading }] = useLazyQuery(FETCH_MODEL_PORTFOLIOS);

  const useCustodianHoldingsBreakDown = options?.useCustodianHoldingsBreakDown;
  const { sys } = useThemeTokens();
  const { localizedDateTime } = useLocalization();
  const [modelPortfolioHoldings, setModelPortfolioHoldings] = useState<FinancialProduct[]>([]);
  const [lastUpdatedAt, setLastUpdatedAt] = useState<string>('');
  const [errorState, setErrorState] = useState(false);

  const { userId, clientGroupId } = useParams();
  const { pathname: path } = useLocation();
  const type = objectTypeMapping(objectType);
  const variables: any = {
    Client: { userId: objectId },
    Goal: { goalId: objectId },
    Account: { accountId: objectId },
    SubAccount: { subAccountId: objectId },
  };
  const userIdFromPath = getUserIdFromPath({ userId, clientGroupId, path });

  const getDataModelPortfolioHoldings = (object: { subAccounts: SubAccount[] }): FinancialProduct[] => (
    (object.subAccounts ?? []).reduce((acc: FinancialProduct[], subAccount: SubAccount) => {
      let financialProduct: FinancialProduct | undefined;
      if (objectType === PageObjectType.GOAL && userIdFromPath) {
        if (subAccount.account.user.id === userIdFromPath) {
          financialProduct = subAccount.financialProduct;
        }
      } else {
        financialProduct = subAccount.financialProduct;
      }
      if (!isNil(financialProduct)) acc.push(financialProduct);
      return acc;
    }, [])
  );

  const fetchCustodianModelPortfolios = async (object: any): Promise<FinancialProduct[]> => {
    if (isNil(object?.custodianStatistics)) {
      setErrorState(true);
    }
    const custodianCusips = new Set(
      object?.custodianStatistics?.holdings.map((holding: any) => holding.financialProduct.cusip),
    );
    setLastUpdatedAt(object?.custodianStatistics?.lastUpdatedAt);

    try {
      const { data } = await fetchModelPortfolios({
        variables: {
          input: {
            filter: {
              organizationId: activeOrganization.id,
            },
            pagination: { perPage: 100 },
          },
        },
      });

      if (!data?.fetchModelPortfolios?.modelPortfolios) return [];

      const filteredModelPortfolios = data.fetchModelPortfolios.modelPortfolios.filter(
        (portfolio: any) => custodianCusips.has(portfolio.translatedName?.fr),
      );

      return filteredModelPortfolios;
    } catch (error) {
      return [];
    }
  };

  const { loading } = useQuery(
    query[type](useCustodianHoldingsBreakDown),
    {
      variables: { ...variables[type], skipErrorHandler: true },
      onCompleted: async (data) => {
        const firstKey = Object.keys(data)[0];
        const object = data[firstKey][field[type]];

        switch (type) {
          case ObjectType.USER: {
            if (useCustodianHoldingsBreakDown) {
              const dataModelPortfolioHoldings = await fetchCustodianModelPortfolios(object);
              setModelPortfolioHoldings(uniq(dataModelPortfolioHoldings));
            } else {
              const dataModelPortfolioHoldings = getDataModelPortfolioHoldings(object);
              setModelPortfolioHoldings(uniq(dataModelPortfolioHoldings));
            }
            break;
          }
          case ObjectType.GOAL: {
            if (useCustodianHoldingsBreakDown) {
              const dataModelPortfolioHoldings = await fetchCustodianModelPortfolios(object);
              setModelPortfolioHoldings(uniq(dataModelPortfolioHoldings));
            }

            if (!useCustodianHoldingsBreakDown && object.financialProduct) {
              setModelPortfolioHoldings([object.financialProduct]);
            }

            if (!useCustodianHoldingsBreakDown && !object.financialProduct) {
              const dataModelPortfolioHoldings = getDataModelPortfolioHoldings(object);
              setModelPortfolioHoldings(uniq(dataModelPortfolioHoldings));
            }
            break;
          }
          case ObjectType.ACCOUNT: {
            if (useCustodianHoldingsBreakDown) {
              const dataModelPortfolioHoldings = await fetchCustodianModelPortfolios(object);
              setModelPortfolioHoldings(uniq(dataModelPortfolioHoldings));
            } else {
              const dataModelPortfolioHoldings = getDataModelPortfolioHoldings(object);
              setModelPortfolioHoldings(uniq(dataModelPortfolioHoldings));
            }
            break;
          }
          case ObjectType.SUB_ACCOUNT: {
            if (object.financialProduct) setModelPortfolioHoldings([object.financialProduct]);
            break;
          }
        }
      },
      onError: () => {
        setErrorState(true);
      },
    },
  );

  return (
    <>
      <Box display="flex" flexDirection='column' justifyContent='center'>
        <Typography variant='headingSmall'>{options.customTitle ? translateBackend(options.customTitle) : t('portfolioTargetHoldings')}</Typography>
        {options?.showLastUpdatedTimestamp && (
          <Box display="flex" alignItems='center'>
            <Typography data-testid='model-portfolio-last-updated-title' variant='bodySmall' sx={{ color: sys.color.outline }}>
            {`${translateBackend(options.lastUpdatedTimestampLabel)}: ${lastUpdatedAt ? localizedDateTime(lastUpdatedAt) : ''}`}
            </Typography>
          </Box>
        )}
      </Box>
      {errorState && !loading && !modelPortfoliosLoading && (
         <EmptyStateAlt
          sx={{
            mt: 2,
            textAlign: 'center',
            height: '100px',
            flexDirection: 'column',
          }}
          title={t('components:modelPortfolioHolding.errorStateTitlePortfolioHolding')}
          subtitle={t('shared:errorStateSubText')}
        />
      )}
      {modelPortfolioHoldings.length === 0 && !loading && !modelPortfoliosLoading && !errorState && (
        <EmptyStateAlt
          title={t('components:modelPortfolioHolding.noPortfolioHolding')}
          sx={{ mt: 2, height: '96px' }}
        />
      )}
      {(loading || modelPortfolioHoldings.length > 0)
        && (<AccordionModelPortfolioHoldings loading={loading || modelPortfoliosLoading} modelPortfolioHoldings={modelPortfolioHoldings} options={options} />)
      }
    </>
  );
};

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  DateField,
} from '../../../../2-component';
import { FilterModal } from '../../../../3-pattern';

dayjs.extend(utc);

export interface DocumentFilterOptions {
  dateStart?: string,
  dateEnd?: string,
}

const setDate = (d: any) => (d ? dayjs(d).utc().format('YYYY-MM-DD') : undefined);

export const DocumentFilter = ({
  filter, setFilter,
}: {
  filter: DocumentFilterOptions, setFilter: (filter: DocumentFilterOptions) => void,
}) => {
  const { t } = useTranslation(['devSettings', 'shared']);
  const filterExists = !!filter.dateStart || !!filter.dateEnd;
  const [filterState, setFilterState] = useState<DocumentFilterOptions>(filter);

  const onConfirm = () => {
    setFilter(filterState);
  };
  const onClose = () => {
    setFilterState(filter);
  };

  const onClearAll = () => {
    setFilterState({});
    setFilter({});
  };

  return (
    <FilterModal filterExists={filterExists} onConfirm={onConfirm} onClose={onClose} onClearAll={onClearAll}>
      <DateField
        label={t('components:fromDate')}
        value={filterState.dateStart ? dayjs.utc(filterState.dateStart) : null}
        onChange={(e: any) => setFilterState((f) => ({ ...f, dateStart: setDate(e) }))}
        fullWidth
        sx={{ mb: 2 }}
      />
      <DateField
        label={t('components:toDate')}
        value={filterState.dateEnd ? dayjs.utc(filterState.dateEnd) : null}
        onChange={(e: any) => setFilterState((f) => ({ ...f, dateEnd: setDate(e) }))}
        fullWidth sx={{ mb: 2 }}
      />
    </FilterModal>
  );
};

import i18n from 'assets/i18n/config';
import { CurrencyCodes, Jurisdictions } from '@onevesthq/ov-enums';
import { TRADING_PROCESS_LIST } from './subAccount';
import { Localization } from './localization';
import { Role } from './role';
import { AccountTypes } from './account';
import { TranslatedString } from './shared';

export interface OrganizationTheme {
  sideBarColor?: string
  logo?: string
  authenticationTheme: {
    logo?: string,
    primaryColor?: string,
    pageBackgroundColor?: string,
  },
}

export enum AuthenticationConnections {
  EMAIL_AND_PASSWORD = 'EMAIL_AND_PASSWORD',
  EMAIL_OTP = 'EMAIL_OTP',
  ENTERPRISE = 'ENTERPRISE',
}

export interface BankingConnectorProvider {
  clientIframeUrl?: string
  customerId?: string
  instanceId?: string
  enableNameMatching?: boolean
}

export interface IdVerificationProvider {
  templateId?: string,
  verificationPassedWebhookSecret?: string,
  inquiryUpdateWebhookSecret?: string,
}

export interface ExternalProvider {
  bankingConnectorProvider?: BankingConnectorProvider,
  idVerificationProvider?: IdVerificationProvider,
}

export interface EnabledJurisdictions {
  all: boolean,
  only?: Jurisdictions[]
}

export interface OrganizationAuthenticationDomain {
  url?: string,
  authenticationType?: AuthenticationConnections,
  enterpriseConnectionName?: string,
  useHostedLogin?: boolean,
}

export enum MultiFactorAuthenticationOptions {
  AUTHENTICATOR = 'AUTHENTICATOR',
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export interface PublicOrganization {
  id: string
  name: string
  subdomain: string
  browserTabTitle?: string
  clientHostedLoginClientId?: string
  clientHostedLoginScope?: string
  clientHostedLoginURL?: string
  faviconLink?: string
  parent?: PublicOrganization
  theme?: OrganizationTheme
}

export interface Organization {
  id: string
  name?: string
  entityName?: string
  subdomain?: string
  parent?: Partial<Organization>
  isReferral?: boolean
  permissions?: [string]
  theme?: OrganizationTheme
  useParentProductShelf?: boolean
  useParentModelPortfolios?: boolean
  useParentThemes?: boolean
  useParentAssetClasses?: boolean
  useParentSchedules?: boolean
  useParentFeeTiers?: boolean
  useParentBillingSchedules?: boolean
  useParentRoleProfiles?: boolean
  useParentWorkflows?: boolean
  useParentPageConfigurations?: boolean
  useParentStaticPageWidgetConfigurations?: boolean
  useParentCustodianConnections?: boolean
  useParentIntegrations?: boolean
  useParentLocalizations?: boolean
  useParentThemeTokens?: boolean
  useParentArticles?: boolean
  defaultAuthenticationConnection?: AuthenticationConnections,
  enableMultiFactorAuthentication?: boolean,
  multiFactorAuthenticationOptions?: MultiFactorAuthenticationOptions,
  enableRemeberBrowser?: boolean,
  enableEmailFrenchTranslation?: boolean,
  allowAccessToSubOrganizations?: boolean,
  availableFeatureFlags?: string[]
  reviewTransactions?: boolean
  allowPortfolioPerGoal?: boolean,
  blockSuspiciousTransactions?: boolean,
  externalProvider?: ExternalProvider,
  autoInviteImportedUsers?: boolean,
  repCode?: string,
  jurisdictions?: EnabledJurisdictions,
  localization?: Localization,
  applicableLocalization?: Localization,
  userManagementFeeBps?: number,
  minInitialDepositCents?: number,
  revenueShareBps?: number,
  revenueShareTaxes?: number,
  allowDirectCommunication?: boolean,
  allowInstantInvest?: boolean,
  requireFeeApproval?: boolean,
  useClientOrgAuth0?: boolean,
  minRecurringDepositCents?: number,
  profileReviewTimeInMonths?: number,
  allowInactiveUserRebalancing?: boolean,
  allowInactiveUserFeeCalculation?: boolean,
  allowInactiveUserAdjustments?: boolean,
  requireManualAccountApproval?: boolean,
  requireSubTradeRequestApproval?: boolean,
  allowViewSubTradeRequestPairs?: boolean,
  allowPendingTransactions?: boolean,
  supportUrl?: string,
  helpCentreUrl?: string,
  defaultTradingProcess?: any,
  bulkTradingProcess?: any,
  defaultSignUpRole?: Role,
  defaultAdvisorRole?: Role,
  defaultClientRole?: Role,
  disableStatsUpdateBasedOnTransactions?: boolean,
  excludeAdminFeesFromClients?: boolean,
  allowPostOptimizationEditing?: boolean,
  cashOnHoldToTradeDays?: number,
  cashOnHoldToWithdrawDays?: number,
  displayMenuBar?: boolean,
  allowLogout?: boolean,
  inactivityTimeoutInMinutes?: number,
  enableInactivityTimeOut?: boolean,
  logoutRedirectUrl?: string,
  useParentCustomFields?: boolean,
  useParentFormTemplates?: boolean,
  displayCurrency?: boolean,
  themeTokens?: any,
  customUrl?: string,
  authenticationDomains?: OrganizationAuthenticationDomain[],
  accountTypeTaxRanks?: [{
    id?: string
    accountType: AccountTypes
    taxRank: number
  }],
  faviconLink?: string,
  browserTabTitle?: string,
  minDepositCents?: number,
  errorPageMessage?: TranslatedString,
  errorPageDescription?: TranslatedString,
  errorPageButtonText?: TranslatedString,
  errorPageShowNavbar?: boolean,
  errorPageBackgroundColor?: string,
  errorPageBackgroundImage?: string,
  productShelfCurrencies?: CurrencyCodes[],
  enableContactSupport?: boolean,
  enableResourcesAndArticles?: boolean,
  enableManualUpdateExchangeRates?: boolean,
  enableAutoUpdateExchangeRates?: boolean,
}

export const ORG_TRADING_PROCESS_LIST = [
  {
    value: 'INHERITED_FROM_PARENT',
    name: i18n.t('client:tradingProcessItems.INHERITED_FROM_PARENT'),
  },
  ...TRADING_PROCESS_LIST,
];

import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState, useEffect } from 'react';
import { CurrentWebHookType } from 'interfaces/webHook';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import { NewWebhook } from './components/newWebHook';
import { EditWebhook } from './components/editWebHook';
import { Typography, Box, Skeleton } from '../../../1-primative';
import {
  Table, TableHeadCell, TableRow, TableCell, TableBody, Pagination,
  CardContent, Card,
} from '../../../2-component';
import { usePageState } from '../../../../util/usePageState';
import { SettingsTitle } from '../../../3-pattern';

export const FETCH_WEBHOOKS = gql`
  query fetchWebhooks($input: FetchWebhooksInput!) {
    fetchWebhooks(input: $input) {
      webhooks{
        id
        type
        eventType
        objectType
        gql
        mutationName
        mutationInputName
        url
        signedJwtHeader
        customHeaders
        customPayloadAttributes
        organization{
          id
          name
        }
      }
      totalCount
    }
  }
`;

export const WebhooksSettings = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [selectedWebHook, setSelectedWebHook] = useState<CurrentWebHookType>();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [page, setPage] = usePageState(1, 'page');
  const [pageSize, setPageSize] = usePageState(15, 'pageSize');
  const { t } = useTranslation(['devSettings']);
  const {
    loading, error, data, refetch, previousData,
  } = useQuery(FETCH_WEBHOOKS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
        pagination: {
          sortField: 'id',
          sortDesc: true,
          perPage: pageSize,
          offSet: (page - 1) * pageSize,
        },
      },
    },
    fetchPolicy: 'no-cache',
  });

  const handleEditClose = () => {
    setOpenEditModal(false);
  };

  useEffect(() => function cleanupOnUnmount() {
    setPage(1);
    setPageSize(15);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) (<Typography>Error</Typography>);

  return (
    <>
      <SettingsTitle title={t('settings:webhooks.title')} description={t('settings:webhooks.description')} />
      <Card loading={loading}>
        { permissions.includes('write:webhook') && (
          <CardContent>
            <Box display='flex' justifyContent='end' alignItems='center'>
              <NewWebhook afterCreate={refetch} defaultOrg={activeOrganization.id ?? ''} />
            </Box>
          </CardContent>
        )}
        <Table>
          <TableBody>
            <TableRow>
              <TableHeadCell>{t('webHooksTable.type')}</TableHeadCell>
              <TableHeadCell>{t('webHooksTable.objects')}</TableHeadCell>
              <TableHeadCell>{t('webHooksTable.organization')}</TableHeadCell>
            </TableRow>
            { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
              <TableRow key={i}>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
              </TableRow>
            ))}
            {data?.fetchWebhooks?.webhooks?.map((webHook: any) => (
              <TableRow
                hover
                pointer
                key={webHook.id}
                onClick={() => {
                  setSelectedWebHook(webHook);
                  if (webHook.organization.id === activeOrganization.id && permissions.includes('write:integrations')) {
                    setOpenEditModal(true);
                  }
                }}
              >
                <TableCell>
                  {t(`webHooksTableOptions.type.${webHook.type}`)}
                </TableCell>
                <TableCell>
                  {t(`webHooksTableOptions.objectType.${webHook.objectType}`)}
                </TableCell>
                <TableCell>
                  {webHook.organization.name}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {selectedWebHook && <EditWebhook currentWebHook={selectedWebHook} open={openEditModal} handleEditClose={handleEditClose} />}
        <Pagination
          count={Math.ceil(((data || previousData)?.fetchWebhooks?.totalCount ?? 0) / pageSize)}
          page={page}
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />
      </Card>
    </>
  );
};

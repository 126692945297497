import { useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {
  Button, Typography, Box, ActionContext, Stack,
} from '../../../..';
import { getSubAccountName } from '../../../../../interfaces';
import { formatMoneyValue } from '../../../../../util';
import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import {
  CREATE_CUSTODIAN_TRANSFER,
  CREATE_DEPOSIT_TRANSFER, CREATE_EXTERNAL_TRANSFER, CREATE_SCHEDULED_DEPOSIT_TRANSFER,
  TRANSFER_ALL_BETWEEN_SUB_ACCOUNTS, TRANSFER_BETWEEN_SUB_ACCOUNTS,
} from './depositReview.queries';
import { translateBackend } from '../../../../../assets/i18n/config';
import { useLocalization } from '../../../../../util/useLocalization';
import { DepositTransferContext } from '../../../depositWorkflow/depositWorkflow';
import { WorkflowContext } from '../../workflowCompletion';
import { updateContextTransfer } from '../../../../../util/updateWorkflowContext';

export const DepositReview = ({
  options, onNext, dummyDeposit, setDummyDeposit,
}: {
  options: any, onNext: any, dummyDeposit?: any, setDummyDeposit?: any,
}) => {
  const { t } = useTranslation('workflowCompletions');
  const { localizedDate } = useLocalization();
  const { transferData, setTransferData } = useContext(DepositTransferContext);
  const { workflowData, setWorkflowData } = useContext(WorkflowContext);
  const applicableData = dummyDeposit ?? (Object.keys(transferData).length !== 0 ? transferData : workflowData);
  const setApplicationContext = setDummyDeposit ?? (Object.keys(transferData).length !== 0 ? setTransferData : undefined);
  const setWorkflowCompletionContext = Object.keys(transferData).length === 0 ? setWorkflowData : undefined;
  const { refetch, refreshWidgets } = useContext(ActionContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { showToast } = useGlobalToast();

  const [createDepositTransfer] = useMutation(CREATE_DEPOSIT_TRANSFER);
  const [createScheduledDepositTransfer] = useMutation(CREATE_SCHEDULED_DEPOSIT_TRANSFER);
  const [createTransferBetweenSubAccounts] = useMutation(TRANSFER_BETWEEN_SUB_ACCOUNTS);
  const [createTransferAllBetweenSubAccounts] = useMutation(TRANSFER_ALL_BETWEEN_SUB_ACCOUNTS);
  const [createExternalTransfer] = useMutation(CREATE_EXTERNAL_TRANSFER);
  const [createCustodianTransfer] = useMutation(CREATE_CUSTODIAN_TRANSFER);

  const fullTransfer = () => applicableData?.fromSubAccount?.statistics?.marketValueCents && applicableData?.amountCents > (applicableData?.fromSubAccount?.statistics?.marketValueCents || 0) * 0.97;

  const submit = () => {
    setLoading(true);
    if (applicableData?.type === 'EFT') {
      if (applicableData?.schedule === 'ONE_TIME' && !applicableData?.scheduledDate) {
        if (options?.createExternalDepositTransfer) {
          createCustodianTransfer({
            variables: {
              input: {
                accountId: applicableData?.subAccount?.account?.id,
                amount: applicableData?.amountCents,
                fund: applicableData?.holdings?.financialProduct?.cusip,
                frequency: applicableData?.schedule,
                startDate: new Date().toISOString().split('T')[0],
                bankAccount: {
                  accountNumber: applicableData?.bankAccount?.bankAccountNumberDisclosed,
                  institutionNumber: applicableData?.bankAccount?.institutionNumber,
                  transitNumber: applicableData?.bankAccount?.transitNumberDisclosed,
                },
              },
            },
            onCompleted: () => {
              setLoading(false);
              showToast({
                message: t('depositReview.success'),
                severity: 'success',
              });
            },
            onError: () => {
              showToast({
                message: t('depositReview.failure'),
                severity: 'error',
              });
            },
          });
        } else {
          createDepositTransfer({
            variables: {
              input: {
                amountCents: applicableData?.amountCents,
                bankAccountId: applicableData?.bankAccount.id,
                subAccountId: applicableData?.subAccount.id,
                fiscalYear: applicableData?.fiscalYear,
              },
            },
            onCompleted: () => {
              refetch();
              setLoading(false);
              showToast({
                message: t('depositReview.success'),
                severity: 'success',
              });
              if (refreshWidgets) refreshWidgets();
            },
          });
        }
      } else {
        createScheduledDepositTransfer({
          variables: {
            input: {
              amountCents: applicableData?.amountCents,
              bankAccountId: applicableData?.bankAccount.id,
              subAccountId: applicableData?.subAccount.id,
              scheduledDate: applicableData?.scheduledDate,
              frequency: applicableData?.schedule,
            },
          },
          onCompleted: () => {
            updateContextTransfer({
              value: { ...applicableData, skipDocuments: false },
              existingContext: applicableData,
              setApplicationContext,
              setWorkflowCompletionContext,
            });
            refetch();
            setLoading(false);
            showToast({
              message: t('depositReview.success'),
              severity: 'success',
            });
            if (refreshWidgets) refreshWidgets();
          },
        });
      }
    } else if (applicableData?.type === 'INTERNAL_TRANSFER') {
      if (!fullTransfer()) {
        createTransferBetweenSubAccounts({
          variables: {
            input: {
              amountCents: applicableData?.amountCents,
              fromSubAccountId: applicableData?.fromSubAccount.id,
              toSubAccountId: applicableData?.subAccount.id,
            },
          },
          onCompleted: () => {
            refetch();
            setLoading(false);
            showToast({
              message: t('depositReview.success'),
              severity: 'success',
            });
            if (refreshWidgets) refreshWidgets();
          },
        });
      } else {
        createTransferAllBetweenSubAccounts({
          variables: {
            input: {
              fromSubAccountId: applicableData?.fromSubAccount.id,
              toSubAccountId: applicableData?.subAccount.id,
            },
          },
          onCompleted: () => {
            refetch();
            setLoading(false);
            showToast({
              message: t('depositReview.success'),
              severity: 'success',
            });
            if (refreshWidgets) refreshWidgets();
          },
        });
      }
    } else {
      createExternalTransfer({
        variables: {
          input: {
            amountCents: applicableData?.amountCents,
            subAccountId: applicableData?.subAccount.id,
            institution: { id: applicableData?.institution.id },
            transferAccountNumber: applicableData?.accountNumber,
            transferMethod: applicableData?.transferType,
            transferAccount: applicableData?.accountType,
          },
        },
        onCompleted: () => {
          refetch();
          setLoading(false);
          showToast({
            message: t('depositReview.success'),
            severity: 'success',
          });
          if (refreshWidgets) refreshWidgets();
        },
      });
    }
    onNext();
  };

  return (
    <>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>{translateBackend(options?.subtitle)}</Typography>
      { applicableData?.type === 'EFT' && (
        <Stack spacing={3}>
          <Box>
            <Typography variant='labelLarge' colorVariant='variant'>{t('depositReview.fromBankAccount')}</Typography>
            <Typography variant='bodyLarge' weight='bold'>{`${applicableData?.bankAccount?.name} ${applicableData?.bankAccount?.bankAccountNumber}`}</Typography>
          </Box>
          <Box>
            <Typography variant='labelLarge' colorVariant='variant'>{t('depositReview.toSubAccount')}</Typography>
            <Typography variant='bodyLarge' weight='bold'>{getSubAccountName(applicableData?.subAccount)}</Typography>
          </Box>
          {options?.showHoldings && (
            <Box>
              <Typography variant='labelLarge' colorVariant='variant'>{t('depositReview.toHolding')}</Typography>
              <Typography variant='bodyLarge' weight='bold'>
                {translateBackend(applicableData?.holdings?.financialProduct?.translatedNickName ?? applicableData?.holdings?.financialProduct?.translatedName)}
              </Typography>
            </Box>
          )}
          <Box>
            <Typography variant='labelLarge' colorVariant='variant'>{t('depositReview.amount')}</Typography>
            <Typography variant='bodyLarge' weight='bold'>{formatMoneyValue(applicableData?.amountCents)}</Typography>
          </Box>
          { applicableData?.schedule !== 'ONE_TIME' && (
            <>
              <Box>
                <Typography variant='labelLarge' colorVariant='variant'>{t('depositReview.schedule')}</Typography>
                <Typography variant='bodyLarge' weight='bold'>{t(`schedule.${applicableData?.schedule}`)}</Typography>
              </Box>
              <Box>
                <Typography variant='labelLarge' colorVariant='variant'>{t('depositReview.startsOn')}</Typography>
                <Typography variant='bodyLarge' weight='bold'>{localizedDate(applicableData?.scheduledDate)}</Typography>
              </Box>
            </>
          )}
          <DisclaimerBox text={translateBackend(options?.eftDisclaimer)}/>
        </Stack>
      )}
      { applicableData?.type === 'INTERNAL_TRANSFER' && (
        <Stack spacing={3}>
          <Box>
            <Typography variant='labelLarge' colorVariant='variant'>{t('depositReview.fromSubAccount')}</Typography>
            <Typography variant='bodyLarge' weight='bold'>{getSubAccountName(applicableData?.fromSubAccount)}</Typography>
          </Box>
          <Box>
            <Typography variant='labelLarge' colorVariant='variant'>{t('depositReview.toSubAccount')}</Typography>
            <Typography variant='bodyLarge' weight='bold'>{getSubAccountName(applicableData?.subAccount)}</Typography>
          </Box>
          <Box>
            <Typography variant='labelLarge' colorVariant='variant'>{t('depositReview.amount')}</Typography>
            <Typography variant='bodyLarge' weight='bold'>{fullTransfer() ? t('depositReview.fullTransfer') : formatMoneyValue(applicableData?.amountCents)}</Typography>
          </Box>
          <DisclaimerBox text={translateBackend(options?.internalTransferDisclaimer)}/>
        </Stack>
      )}
      { applicableData?.type === 'EXTERNAL_TRANSFER' && (
        <Stack spacing={3}>
          <Box>
            <Typography variant='labelLarge' colorVariant='variant'>{t('depositReview.toSubAccount')}</Typography>
            <Typography variant='bodyLarge' weight='bold'>{getSubAccountName(applicableData?.subAccount)}</Typography>
          </Box>
          <Box>
            <Typography variant='labelLarge' colorVariant='variant'>{t('depositReview.financialInstitution')}</Typography>
            <Typography variant='bodyLarge' weight='bold'>{applicableData?.institution.name}</Typography>
          </Box>
          <Box>
            <Typography variant='labelLarge' colorVariant='variant'>{t('depositReview.accountNumber')}</Typography>
            <Typography variant='bodyLarge' weight='bold'>{applicableData?.accountNumber}</Typography>
          </Box>
          <Box>
            <Typography variant='labelLarge' colorVariant='variant'>{t('depositReview.accountType')}</Typography>
            <Typography variant='bodyLarge' weight='bold'>{applicableData?.accountType}</Typography>
          </Box>
          <Box>
            <Typography variant='labelLarge' colorVariant='variant'>{t('depositReview.transferType')}</Typography>
            <Typography variant='bodyLarge' weight='bold'>{t(`depositReview.${applicableData?.transferType}`)}</Typography>
          </Box>
          <Box>
            <Typography variant='labelLarge' colorVariant='variant'>{t('depositReview.amount')}</Typography>
            <Typography variant='bodyLarge' weight='bold'>{fullTransfer() ? t('depositReview.fullTransfer') : formatMoneyValue(applicableData?.amountCents)}</Typography>
          </Box>
          <DisclaimerBox text={translateBackend(options?.externalTransferDisclaimer)}/>
        </Stack>
      )}
      <Box display='flex' justifyContent='end'>
        <Button dataTestId='deposit-review-continue-button' label={t('depositReview.createDeposit')} sx={{ mt: 3, textAlign: 'center' }} disabled={loading} onClick={submit} />
      </Box>
    </>
  );
};

const DisclaimerBox = ({ text }:{ text: string }) => {
  const { sys } = useThemeTokens();

  return (
    <Box p={1} sx={{ background: sys.color.surfaceContainerVariant, borderRadius: sys.borderRadius.md }}>
    <Typography variant='bodySmall' mx={1} my={-1}>
      <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{text}</ReactMarkdown>
    </Typography>
  </Box>
  );
};

export default DepositReview;

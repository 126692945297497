import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import NewApiToken from './components/newApiToken';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import ViewApiToken from './components/viewApiToken';
import { Typography, Box, Skeleton } from '../../../1-primative';
import {
  Table, TableHeadCell, TableRow, TableCell, TableBody, Badge, Button, Card, CardContent,
} from '../../../2-component';
import { SettingsTitle } from '../../../3-pattern';

const FETCH_API_TOKENSS = gql`
  query me {
    me {
      apiTokens {
        id
        name
        state
        organization {
          id
          name
        }
        jwtToken
        permissions
      }
    }
  }
`;

const DEACTIVATE_TOKEN = gql`
  mutation deactivateToken($input: TransitionApiTokenInput!) {
    transitionApiToken(input: $input) {
      apiToken {
        id
      }
    }
  }
`;

export const ApiTokensSettings = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [apiTokenOpen, setApiTokenOpen] = useState(false);
  const [selectedApiToken, setSelectedApiToken] = useState<any>(null);
  const { t } = useTranslation(['devSettings', 'settings']);
  const {
    loading, error, data, refetch, previousData,
  } = useQuery(FETCH_API_TOKENSS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
        pagination: {},
      },
    },
  });

  const [deactivateApiToken] = useMutation(DEACTIVATE_TOKEN);

  if (error) (<Typography>Error</Typography>);

  return (
    <>
      <SettingsTitle title={t('settings:apiTokens.title')} description={t('settings:apiTokens.description')} />
      <Card loading={loading}>
        { permissions.includes('write:api_tokens') && (
          <CardContent>
            <Box display='flex' justifyContent='flex-end'>
              <NewApiToken afterCreate={refetch} defaultOrg={activeOrganization.id ?? ''} />
            </Box>
          </CardContent>
        )}
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableHeadCell>{t('apiTokenTable.name')}</TableHeadCell>
              <TableHeadCell right>{t('apiTokenTable.noPermissions')}</TableHeadCell>
              <TableHeadCell>{t('apiTokenTable.organization')}</TableHeadCell>
              <TableHeadCell right>{t('apiTokenTable.state')}</TableHeadCell>
            </TableRow>
            { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
              <TableRow key={i}>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
              </TableRow>
            ))}
            {data?.me?.apiTokens?.map((apiToken: any) => (
              <TableRow
                hover
                key={apiToken.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                onClick={() => {
                  setSelectedApiToken(apiToken);
                  setApiTokenOpen(true);
                }}
              >
                <TableCell component="th" scope="row">
                  {apiToken.name}
                </TableCell>
                <TableCell number>{apiToken.permissions?.length}</TableCell>
                <TableCell>{apiToken.organization.name}</TableCell>
                <TableCell right>{apiToken.state === 'ACTIVE' ? (
                  <Button variant='outlined' size='sm' color='destructive' onClick={async (e) => {
                    e.stopPropagation();
                    await deactivateApiToken({ variables: { input: { apiTokenId: apiToken.id, transition: 'deactivate' } } });
                    refetch();
                  }} label={t('deactivate')} />
                ) : (
                  <Badge label={t('deactivated')} color='negative' />
                )}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <ViewApiToken apiTokenToView={selectedApiToken} open={apiTokenOpen} handleClose={() => setApiTokenOpen(false)} />
      </Card>
    </>
  );
};

import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Box } from '../../../1-primative';
import {
  Button, Form,
} from '../../../2-component';
import { RoleSelect, SettingsCard } from '../../../3-pattern';
import { UserContext } from '../../../../providers/userContextProvider';
import { useGlobalToast } from '../../../../providers/globalToastProvider';

const FETCH_ORGANIZATION = gql`
  query fetchOrganization($organizationId: String!) {
    fetchOrganization(organizationId: $organizationId) {
      organization {
        id
        name
        defaultSignUpRole { id }
        defaultAdvisorRole { id }
        defaultClientRole { id }
      }
    }
  }
`;

const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        id
      }
    }
  }
`;

export const RoleSettings = () => {
  const { activeOrganization } = useContext(UserContext);
  const { showToast } = useGlobalToast();
  const { t } = useTranslation(['settings', 'shared']);
  const [organization, setOrganization] = useState<any>(null);

  const { data, loading } = useQuery(FETCH_ORGANIZATION, {
    variables: { organizationId: activeOrganization?.id },
  });

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION, {
    variables: {
      input: {
        organizationId: activeOrganization?.id,
        name: organization?.name,
        defaultSignUpRoleId: organization?.defaultSignUpRole?.id || null,
        defaultAdvisorRoleId: organization?.defaultAdvisorRole?.id || null,
        defaultClientRoleId: organization?.defaultClientRole?.id || null,
      },
    },
    onCompleted: async () => {
      showToast({
        message: t('shared:successfullySaved'),
        severity: 'success',
      });
    },
  });

  useEffect(() => {
    if (data?.fetchOrganization?.organization) {
      setOrganization(data?.fetchOrganization?.organization);
    }
  }, [data]);

  return (
    <SettingsCard
      title={t('roleSettings.title')}
      description={t('roleSettings.description')}
    >
      <Form onSubmit={updateOrganization}>
        <Box display='flex' flexDirection='column' gap={2}>
          <RoleSelect
            value={organization?.defaultSignUpRole?.id ?? ''}
            label={t('roleSettings.defaultSignUpRole')}
            onChange={(event: any) => setOrganization({ ...organization, defaultSignUpRole: { id: event.target.value as string } })}
            includeEmpty
          />
          <RoleSelect
            value={organization?.defaultAdvisorRole?.id ?? ''}
            label={t('roleSettings.defaultAdvisorRole')}
            onChange={(event: any) => setOrganization({ ...organization, defaultAdvisorRole: { id: event.target.value as string } })}
            includeEmpty
          />
          <RoleSelect
            value={organization?.defaultClientRole?.id ?? ''}
            label={t('roleSettings.defaultClientRole')}
            onChange={(event: any) => setOrganization({ ...organization, defaultClientRole: { id: event.target.value as string } })}
            includeEmpty
          />
          <Box display='flex' justifyContent='flex-end'>
            <Button type='submit' disabled={loading} label={t('shared:save')} dataTestId='settings-general-submit-button'/>
          </Box>
        </Box>
      </Form>
    </SettingsCard>
  );
};

import { gql, useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loop } from '@mui/icons-material';
import { translateBackend } from 'assets/i18n/config';
import {
  Button, Typography, Box,
  Skeleton,
} from '../../../..';
import { CreatedAccountsType, WorkflowContext } from '../../workflowCompletion';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { Account, AccountStates } from '../../../../../interfaces';

export const WORKFLOW_COMPLETION = `
  workflowCompletion {
    id
    objectId
    context
    workflow {
      name {
        en
        fr
      }
      steps {
        id
        name {
          en
          fr
        }
        subSteps {
          id
          type
        }
      }
    }
    currentStep {
      id
      name {
        en
        fr
      }
      subSteps {
        id
        type
      }
    }
    currentSubStep {
      id
      options
      type
      canGoBack
      skippable
      rolesCompleteableBy {
        id
      }
    }
  }
`;

const FETCH_ACCOUNTS = gql`
  query fetchAccounts($input: FetchAccountsInput!) {
    fetchAccounts(input: $input) {
      accounts {
        id
        type
        applyForGovFunds
        affiliations {
          id
          allocation
          relation
          type
          user {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const GO_TO_STEP = gql`
  mutation goToSubStep($input: GoToSubStepInput!) {
    goToSubStep(input: $input) {
      ${WORKFLOW_COMPLETION}
    }
  }
`;

export const ReviewAccount = ({
  options, onNext, setActiveWorkflowCompletion, workflowCompletion, userId,
}: {
  options: any, onNext: any, setActiveWorkflowCompletion: any, workflowCompletion: any, userId: string
}) => {
  const { sendAnalytic } = useContext(AnalyticsContext);
  const [accounts, setAccounts] = useState<Account[]>();
  const { t } = useTranslation(['workflowCompletions', 'accountsDetail', 'affiliationTypes', 'accountTypes']);
  const { workflowData, setWorkflowData } = useContext(WorkflowContext);
  const createdAccounts = workflowData.createdAccounts ?? [];
  const currentUserId = workflowData.currentUserId || userId;
  const { data, loading } = useQuery(FETCH_ACCOUNTS, {
    variables: {
      input: {
        filter: {
          userId: currentUserId,
          states: [AccountStates.INITIATED],
        },
      },
    },
    skip: !workflowData.currentAccountId || (workflowData.createdAccounts ?? []).length === 0,
    fetchPolicy: 'no-cache',
  });

  const [goToStep] = useMutation(GO_TO_STEP);

  useEffect(() => {
    if (data) {
      setAccounts(data.fetchAccounts.accounts);
    }
  }, [data]);

  const goToAccountCreate = () => {
    const subSteps = workflowCompletion.workflow.steps.map((x: any) => x.subSteps).flat();
    const accountForGoalCreateSubStep = subSteps.find((x: any) => x.type === 'CREATE_ACCOUNT_FOR_GOAL');
    const accountCreateSubStep = subSteps.find((x :any) => x.type === 'CREATE_ACCOUNT');
    const selectedSubStep = accountForGoalCreateSubStep ?? accountCreateSubStep;
    goToStep({
      variables: {
        input: {
          workflowCompletionId: workflowCompletion.id,
          subStepId: selectedSubStep.id,
        },
      },
      onCompleted: (d: any) => {
        setWorkflowData({
          ...workflowData,
          currentGoalId: accountCreateSubStep ? undefined : workflowData.currentGoalId,
          currentAccountId: undefined,
          currentAccountType: undefined,
          currentAccountState: undefined,
          currentSubAccountId: undefined,
        });
        setActiveWorkflowCompletion(d.goToSubStep.workflowCompletion);
      },
    });
  };
  const onSubmit = () => {
    sendAnalytic(
      ovAnalyticsEvents.workflowsReviewAccountContinueButtonSelect,
      {
        workflowStepTitle: options?.title,
        workflowId: workflowCompletion?.workflow?.id,
        workflowName: workflowCompletion?.workflow?.name,
        activeWorkflowCompletionId: workflowCompletion?.id,
        objectId: workflowCompletion?.objectId,
        objectType: workflowCompletion?.objectType,
      },
    );
    onNext();
  };

  return (
    <>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>{translateBackend(options?.subtitle)}</Typography>
      { loading || !workflowData.currentAccountId ? (
        <>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Skeleton width={250} />
            <Skeleton width={150}/>
          </Box>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Skeleton width={250} />
            <Skeleton width={150}/>
          </Box>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Skeleton width={250} />
            <Skeleton width={150}/>
          </Box>
        </>
      ) : (
        <>
        {accounts && accounts.length > 0
        && accounts
          .filter((account: Account) => createdAccounts?.some((createdAccount: CreatedAccountsType) => createdAccount.id === account.id))
          .map((account: Account) => (
          <>
            <Box sx={{ mb: 2 }} display="flex" justifyContent="space-between">
              <Typography variant="bodyLarge">{t('reviewAccount.type')}</Typography>
              <Typography variant="bodyLarge" sx={{ fontWeight: 'bold', textAlign: 'right' }}>
                {t(`accountTypes:${account?.type}`)}
              </Typography>
            </Box>

            {account?.applyForGovFunds?.length > 0
              && account.applyForGovFunds.map((govFund: any, index: number) => (
                <Box sx={{ mb: 2 }} display="flex" justifyContent="space-between" key={index}>
                  <Typography variant="bodyLarge">
                    {t(`accountsDetail:govFundOptions.${govFund}`)}
                  </Typography>
                  <Typography variant="bodyLarge" sx={{ fontWeight: 'bold', textAlign: 'right' }}>
                    {t('applied')}
                  </Typography>
                </Box>
              ))}

            {account && account?.affiliations?.map((affiliation: any) => (
              <Box sx={{ mb: 2 }} display="flex" justifyContent="space-between" key={affiliation.id}>
                <Typography variant="bodyLarge">
                  {t(
                    `affiliationTypes:${
                      affiliation.type === 'OTHER' ? affiliation.relation : affiliation.type
                    }`,
                  )}
                </Typography>
                <Typography variant="bodyLarge" sx={{ fontWeight: 'bold', textAlign: 'right' }}>
                  {affiliation.user.firstName} {affiliation.user.lastName}
                </Typography>
              </Box>
            ))}
          </>
          ))}
      </>
      )}
      <Box display='flex' justifyContent='space-between'>
        {options.createAnotherAccount && (
          <Button label={t('createAnotherAccount')} variant='filled' sx={{ mt: 3, mr: 1 }} disabled={loading} onClick={goToAccountCreate} leadingIcon={Loop}/>
        )}
        <Button label={t('continue')} sx={{ mt: 3 }} disabled={loading} onClick={onSubmit} />
      </Box>
    </>
  );
};

export default ReviewAccount;

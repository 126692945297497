import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { remove } from 'lodash/fp';
import { Box } from '../../../../1-primative';
import { MenuItem, SelectField, TranslatableTextField } from '../../../../2-component';
import {
  CreateNewModal, OrganizationSelect, PermissionsSelect, PageConfigurationSelect, AccessiblePagesSelect, DashboardsSelect, StaticPageWidgetConfigurationSelect,
} from '../../../../3-pattern';
import { NavigationStyles, TranslatedString } from '../../../../../interfaces';

type RoleProfileState = {
  translatedName: TranslatedString;
  translatedDescription: TranslatedString;
  organizationId: string | undefined;
  template: {
    permissions: string[];
    accessiblePages: any[];
    dashboards: any[];
    nonIndividualPageConfigurationId?: string | null;
    individualPageConfigurationId?: string | null;
    goalPageConfigurationId?: string | null;
    accountPageConfigurationId?: string | null;
    householdPageConfigurationId?: string | null;
    subAccountPageConfigurationId?: string | null;
    individualTableStaticPageWidgetConfigurationId?: string | null;
    nonIndividualTableStaticPageWidgetConfigurationId?: string | null;
    householdTableStaticPageWidgetConfigurationId?: string | null;
    navigationStyle: NavigationStyles;
  }
};

const CREATE_ROLE_PROFILE = gql`
  mutation createRoleProfile($input: CreateRoleProfileInput!) {
    createRoleProfile(input: $input) {
      roleProfile { id }
    }
  }
`;

const DEFAULT_INITIAL_STATE = {
  translatedName: { en: '' },
  translatedDescription: { en: '' },
  organizationId: '',
  template: {
    permissions: [],
    accessiblePages: [],
    dashboards: [],
    nonIndividualPageConfigurationId: null,
    individualPageConfigurationId: null,
    goalPageConfigurationId: null,
    accountPageConfigurationId: null,
    householdPageConfigurationId: null,
    subAccountPageConfigurationId: null,
    individualTableStaticPageWidgetConfigurationId: null,
    nonIndividualTableStaticPageWidgetConfigurationId: null,
    householdTableStaticPageWidgetConfigurationId: null,
    navigationStyle: NavigationStyles.FULL,
  },
};

const NewRoleProfile = ({ afterCreate, defaultOrg }: { afterCreate: () => void, defaultOrg?: string }) => {
  const initialState = { ...DEFAULT_INITIAL_STATE, organizationId: defaultOrg ?? '' };

  const { t } = useTranslation(['orgSettings']);
  const [roleProfile, setRoleProfile] = useState<RoleProfileState>(initialState);
  const [createRoleProfile, { loading }] = useMutation(CREATE_ROLE_PROFILE, { variables: { input: roleProfile }, onCompleted: () => setRoleProfile(initialState) });

  const create = async () => {
    await createRoleProfile();
    afterCreate();
  };

  const disabled = !(roleProfile.translatedName.en && roleProfile.translatedDescription.en && roleProfile.organizationId);

  return (
    <CreateNewModal state={roleProfile} initialState={initialState} loading={loading} disabled={disabled} title={t('roleProfileModal.title')} onSubmit={create} maxWidth='md'>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TranslatableTextField label={t('roleProfileModal.name')} value={roleProfile.translatedName} fullWidth onChange={(value) => setRoleProfile({ ...roleProfile, translatedName: value })} />
        <TranslatableTextField label={t('roleProfileModal.description')} value={roleProfile.translatedDescription} fullWidth onChange={(value) => {
          setRoleProfile({ ...roleProfile, translatedDescription: value });
        }} />

        <OrganizationSelect value={roleProfile.organizationId} label={t('roleProfileModal.organization')} onChange={(event: any) => {
          setRoleProfile({ ...roleProfile, organizationId: event as string });
        }} />
        <AccessiblePagesSelect accessiblePages={roleProfile.template.accessiblePages} label={t('roleProfileModal.accessiblePages')} onChange={(event: any) => {
          setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, accessiblePages: event.target.value } }));
        }} />
        <DashboardsSelect selectedDashboards={roleProfile.template.dashboards.map((x: any) => x)} label={t('roleProfileModal.dashboards')} onChange={(event: any) => {
          setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, dashboards: event.target.value } }));
        }} />
        <SelectField value={roleProfile.template.navigationStyle ?? NavigationStyles.FULL} label={t('roleTable.navigationStyle')} fullWidth onChange={(event: any) => {
          setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, navigationStyle: event.target.value } }));
        }}>
          <MenuItem key='1' value={NavigationStyles.FULL}>{NavigationStyles.FULL}</MenuItem>
          <MenuItem key='2' value={NavigationStyles.SIMPLE}>{NavigationStyles.SIMPLE}</MenuItem>
        </SelectField>

        <PageConfigurationSelect
          filter={{ type: 'NON_INDIVIDUAL' }}
          value={roleProfile?.template.nonIndividualPageConfigurationId ?? ''}
          label={t('roleModal.nonIndividualPageConfiguration')}
          organizationId={roleProfile.organizationId ?? ''}
          onChange={(event: any) => {
            setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, nonIndividualPageConfigurationId: event.target.value } }));
          }}
        />
        <PageConfigurationSelect
          filter={{ type: 'INDIVIDUAL' }}
          value={roleProfile.template.individualPageConfigurationId ?? ''}
          label={t('roleModal.individualPageConfiguration')}
          organizationId={roleProfile.organizationId ?? ''}
          onChange={(event: any) => {
            setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, individualPageConfigurationId: event.target.value } }));
          }}
        />
        <PageConfigurationSelect
          filter={{ type: 'GOAL' }}
          value={roleProfile.template.goalPageConfigurationId ?? ''}
          label={t('roleModal.goalPageConfiguration')}
          organizationId={roleProfile.organizationId ?? ''}
          onChange={(event: any) => {
            setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, goalPageConfigurationId: event.target.value } }));
          }}
        />
        <PageConfigurationSelect
          filter={{ type: 'ACCOUNT' }}
          value={roleProfile.template.accountPageConfigurationId ?? ''}
          label={t('roleModal.accountPageConfiguration')}
          organizationId={roleProfile.organizationId ?? ''}
          onChange={(event: any) => {
            setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, accountPageConfigurationId: event.target.value } }));
          }}
        />
        <PageConfigurationSelect
          filter={{ type: 'SUB_ACCOUNT' }}
          value={roleProfile.template.subAccountPageConfigurationId ?? ''}
          label={t('roleModal.subAccountPageConfiguration')}
          organizationId={roleProfile.organizationId ?? ''}
          onChange={(event: any) => {
            setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, subAccountPageConfigurationId: event.target.value } }));
          }}
        />
        <PageConfigurationSelect
          filter={{ type: 'HOUSEHOLD' }}
          value={roleProfile.template.householdPageConfigurationId ?? ''}
          label={t('roleModal.householdPageConfiguration')}
          organizationId={roleProfile.organizationId ?? ''}
          onChange={(event: any) => {
            setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, householdPageConfigurationId: event.target.value } }));
          }}
        />

        <StaticPageWidgetConfigurationSelect
          filter={{ widgetType: 'INDIVIDUAL_TABLE' }}
          value={roleProfile.template.individualTableStaticPageWidgetConfigurationId ?? ''}
          label={t('roleModal.individualTableStaticPageWidgetConfiguration')}
          organizationId={roleProfile.organizationId ?? ''}
          onChange={(event: any) => {
            setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, individualTableStaticPageWidgetConfigurationId: event.target.value } }));
          }}
        />
        <StaticPageWidgetConfigurationSelect
          filter={{ widgetType: 'NON_INDIVIDUAL_TABLE' }}
          value={roleProfile.template.nonIndividualTableStaticPageWidgetConfigurationId ?? ''}
          label={t('roleModal.nonIndividualTableStaticPageWidgetConfiguration')}
          organizationId={roleProfile.organizationId ?? ''}
          onChange={(event: any) => {
            setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, nonIndividualTableStaticPageWidgetConfigurationId: event.target.value } }));
          }}
        />
        <StaticPageWidgetConfigurationSelect
          filter={{ widgetType: 'HOUSEHOLD_TABLE' }}
          value={roleProfile.template.householdTableStaticPageWidgetConfigurationId ?? ''}
          label={t('roleModal.householdTableStaticPageWidgetConfiguration')}
          organizationId={roleProfile.organizationId ?? ''}
          onChange={(event: any) => {
            setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, householdTableStaticPageWidgetConfigurationId: event.target.value } }));
          }}
        />

        <PermissionsSelect permissions={roleProfile.template.permissions} onChange={(permission: string, checked: boolean) => {
          let newPermissions = [...(roleProfile?.template?.permissions ?? [])];
          if (checked) {
            newPermissions.push(permission);
          } else {
            newPermissions = remove((x) => x === permission, newPermissions);
          }
          setRoleProfile((prevRoleProfile) => ({ ...prevRoleProfile, template: { ...prevRoleProfile.template, permissions: newPermissions } }));
        }} />
      </Box>
    </CreateNewModal>
  );
};

export default NewRoleProfile;

import { useEffect, useState } from 'react';
import { Download } from '@mui/icons-material';
import {
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '../../../1-primative';
import {
  Alert,
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from '../../../2-component';
import { InlineImage } from '../../../2-component/inlineImage/inlineImage';
import {
  ConfirmationModal,
} from '../../../3-pattern';
import { FETCH_AWAITING_BANK_ACCOUNTS_COUNT } from '../../../../providers/globalStatsHooks';
import { FETCH_BANK_ACCOUNTS, stateBankAccountStateColor } from '../bankAccountsToReview';
import { usePermissions } from '../../../../providers/userContextProvider';
import { useLocalization } from '../../../../util/useLocalization';
import { generateClientNameString } from '../../../../util';

export const FETCH_FILE_DOCUMENTS = gql`
  query fetchFileDocuments($input: FetchFileDocumentsInput!) {
    fetchFileDocuments(input: $input) {
      fileDocuments {
        id
        mediaType
        fileName
        name
        type
      }
      totalCount
    }
  }
`;

export const FETCH_FILE_DOCUMENT = gql`
  query fetchFileDocument($fileDocumentId: ObjectID!) {
    fetchFileDocument(fileDocumentId: $fileDocumentId) {
      fileDocument {
        id
        downloadUrl
      }
    }
  }
`;

export const TRANSITION_BANK_ACCOUNT = gql`
  mutation transitionBankAccount($input: TransitionBankAccountInput!) {
    transitionBankAccount(input: $input) {
      bankAccount {
        id
      }
    }
  }
`;

const FETCH_BANK_ACCOUNT = (permissions: string[]) => gql`
  query fetchBankAccount($id: ObjectID!) {
    fetchBankAccount(bankAccountId: $id) {
      bankAccount {
        id
        name
        state
        source
        failReason
        bankAccountNumber
        ${permissions.includes('read:bank_account_disclosed') ? 'transitNumberDisclosed' : ''}
        ${permissions.includes('read:bank_account_disclosed') ? 'bankAccountNumberDisclosed' : ''}
        transitNumber
        institutionNumber
        user{
          id
          firstName
          lastName
          entityName
          organization{
            id
            name
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;
enum BankAccountStates {
  AWAITING_REVIEW = 'AWAITING_REVIEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}
const transitions = [
  {
    name: 'approve',
    from: [BankAccountStates.AWAITING_REVIEW],
  },
  {
    name: 'reject',
    from: [BankAccountStates.AWAITING_REVIEW, BankAccountStates.APPROVED],
  },
];

export const BankAccountDocumentViewer = ({
  bankAccount, open, handleClose,
}: { bankAccount: any, open: boolean, handleClose: () => void }) => {
  const { t } = useTranslation(['components', 'bankAccountsReview', 'shared']);
  const { localizedDate } = useLocalization();
  const { permissions } = usePermissions();
  const [transitionStatement] = useMutation(TRANSITION_BANK_ACCOUNT, {
    refetchQueries: [FETCH_BANK_ACCOUNTS, FETCH_AWAITING_BANK_ACCOUNTS_COUNT],
  });
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [currentTransition, setCurrentTransition] = useState('');

  const [fetchAccount, { data: accountData, error }] = useLazyQuery(FETCH_BANK_ACCOUNT(permissions));

  const { data } = useQuery(FETCH_FILE_DOCUMENTS, {
    variables: {
      input: {
        filter: {
          sourceIds: [bankAccount.id],
        },
      },
    },
  });

  const [fetchFileDocument] = useLazyQuery(FETCH_FILE_DOCUMENT, { fetchPolicy: 'network-only' });

  useEffect(() => {
    if (bankAccount.id) {
      fetchAccount({
        variables: {
          id: bankAccount.id,
        },
      });
    }
  }, [bankAccount.id, fetchAccount]);

  if (error) (<Typography>Error</Typography>);

  const confirmTransition = async () => {
    await transitionStatement({ variables: { input: { bankAccountId: bankAccount.id, transition: currentTransition } } });
    setOpenConfirmationModal(false);
    handleClose();
  };

  if (!bankAccount) return <></>;

  const bankAccountData = accountData?.fetchBankAccount?.bankAccount || bankAccount;
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
    >
      <DialogTitle onClose={handleClose}>
        {`${generateClientNameString(bankAccountData.user, false, true)}${t('bankAccountsReview:bankAccountPostfix')}`}
      </DialogTitle>
      <DialogContent>
        <Box display='flex' justifyContent='space-around'>
          <Box display='flex' flexDirection='column' gap={2}>
            <Box display='flex' flexDirection='column'>
              <Typography variant='labelSmall'>
                {t('bankAccountsReview:filters.state')}
              </Typography>
              <Badge label={t(`bankAccountsReview:filters.stateOptions.${bankAccountData.state}`)} color={stateBankAccountStateColor(bankAccountData.state)} />
            </Box>
            <Box display='flex' flexDirection='column'>
              {bankAccountData.state === 'APPROVED' && (<>
                <Typography variant='labelSmall'>{t('bankAccountsReview:filters.approvedDate')} </Typography>
                <Typography variant='bodyLarge'>{localizedDate(bankAccountData.updatedAt)}</Typography>
              </>)}
            </Box>
            <Box display='flex' flexDirection='column'>
              <Typography variant='labelSmall'>{t('bankAccountsReview:filters.source')}</Typography>
              <Typography variant='bodyLarge'>{t(`bankAccountsReview:filters.sourceOptions.${bankAccountData.source}`)}</Typography>
            </Box>
            <Box display='flex' flexDirection='column'>
              <Typography variant='labelSmall'>
              {t('bankAccountsReview:filters.name')}
              </Typography>
              <Typography variant='bodyLarge'>{bankAccountData.name}</Typography>
            </Box>
            <Box display='flex' flexDirection='column'>
              <Typography variant='labelSmall'>{t('bankAccountsReview:filters.failReason')}</Typography>
              <Typography variant='bodyLarge'>{bankAccountData.failReason}</Typography>
            </Box>
          </Box>
          <Box display='flex' flexDirection='column' gap={2}>
            <Box display='flex' flexDirection='column'>
              <Typography variant='labelSmall'>{t('bankAccountsReview:filters.requestedDate')} </Typography>
              <Typography variant='bodyLarge'>{localizedDate(bankAccountData.createdAt)}</Typography>
            </Box>
            <Box display='flex' flexDirection='column'>
              <Typography variant='labelSmall'>
              {t('bankAccountsReview:filters.transitNumber')}
              </Typography>
              <Typography variant='bodyLarge'>{bankAccountData.transitNumberDisclosed ?? bankAccountData.transitNumber}</Typography>
            </Box>
            <Box display='flex' flexDirection='column'>
              <Typography variant='labelSmall'>{t('bankAccountsReview:filters.institutionNumber')}</Typography>
              <Typography variant='bodyLarge'>{bankAccountData.institutionNumber}</Typography>
            </Box>
            <Box display='flex' flexDirection='column'>
              <Typography variant='labelSmall'>{t('bankAccountsReview:filters.bankAccountNumber')} </Typography>
              <Typography variant='bodyLarge'>{bankAccountData.bankAccountNumberDisclosed ?? bankAccountData.bankAccountNumber}</Typography>
            </Box>
          </Box>
        </Box>
        <Box width='100%' mt={2} mb={2}>
          {(data?.fetchFileDocuments?.totalCount !== 0) ? (
            data?.fetchFileDocuments?.fileDocuments.map((doc: any) => {
              const modifiedFile = { ...doc, isFileDocument: true };
              const isImage = doc?.mediaType?.startsWith('image/');
              return (
                <>
                  {isImage
                    ? <InlineImage fileDocumentId={doc.id} style={{ width: '100%' }}/>
                    : <Box display='flex' justifyContent='center' m={3}><Button label={t('shared:download')} variant='tonal' leadingIcon={Download} onClick={() => {
                      fetchFileDocument({
                        variables: { fileDocumentId: modifiedFile.id },
                        onCompleted: (dataDoc) => window.open(dataDoc.fetchFileDocument.fileDocument.downloadUrl, '_blank'),
                      });
                    }} /></Box>
                  }
                </>
              );
            })
          ) : (
            <Alert severity="warning">
              <Typography>{t('bankAccountsReview:noFile')}</Typography>
            </Alert>
          )}
        </Box>
      </DialogContent>
      <DialogFooter>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {transitions.map((transition: any) => (
            transition.from.includes(bankAccountData.state) && (
              <Button key={transition.name} type='button' color={transition.name === 'reject' ? 'destructive' : 'primary'} onClick={async () => {
                setCurrentTransition(transition.name);
                setOpenConfirmationModal(true);
              } } label={t(`bankAccountsReview:bankAccountTransitions.${transition.name}`)} sx={{ ml: 1 }} variant='tonal' />
            )
          ))}
        </Box>
      </DialogFooter>
      <ConfirmationModal
        open={openConfirmationModal}
        title={t('bankAccountsReview:moderateBankAccount')}
        bodyText={t('bankAccountsReview:confirmationDialogTitle', { transition: currentTransition })}
        onCancel={() => setOpenConfirmationModal(false)}
        onConfirm={() => confirmTransition()}
      />
    </Dialog>
  );
};

export default BankAccountDocumentViewer;

import {
  useState, useContext,
  useEffect,
  useCallback,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { usePermissions, UserContext } from 'providers/userContextProvider';
import { PendingTransfersVisual } from './pendingTransfers.visual';
import {
  FETCH_UPCOMING_TRANSFERS, CANCEL_SCHEDULED_TRANSFER, TRANSITION_TRANSFER,
  FETCH_ACCOUNT_CUSTODIAN_UPCOMING_TRANSACTION,
} from './pendingTransfers.queries';
import { PageObjectType } from '../../../5-page';
import { getUserIdFromPath } from '../../../../util/getUserIdFromPath';

const baseFilter = (objectType: PageObjectType, objectId: string, userId?: string) => {
  switch (objectType) {
    case PageObjectType.INDIVIDUAL:
      return { userId: objectId };
    case PageObjectType.NON_INDIVIDUAL:
      return { userId: objectId };
    case PageObjectType.HOUSEHOLD:
      return { clientGroupId: objectId };
    case PageObjectType.GOAL:
      return {
        goalId: objectId,
        userId,
      };
    case PageObjectType.ACCOUNT:
      return { accountId: objectId };
    case PageObjectType.SUB_ACCOUNT:
      return { subAccountId: objectId };
    default:
      return {};
  }
};

export const PendingTransfers = ({ objectId, objectType, options = {} }: { objectId: string, objectType: PageObjectType, options?: any }) => {
  const [upcomingTransfers, setUpcomingTransfers] = useState([]);
  const [pagination, setPagination] = useState<{ page: number, perPage: number, totalCount: number }>({ page: 1, perPage: 15, totalCount: 0 });
  const { permissions } = usePermissions();
  const { custodianConnection } = useContext(UserContext);
  const { userId, clientGroupId } = useParams();
  const { pathname: path } = useLocation();
  const [errorState, setErrorState] = useState(false);

  const useCustodianUpcomingTransfers = options.useCustodianUpcomingTransactions && custodianConnection?.enableFetchCustodianUpcomingTransactions && objectType === PageObjectType.ACCOUNT;
  const filter = {
    ...baseFilter(objectType, objectId, getUserIdFromPath({ userId, clientGroupId, path })),
  };

  const { loading, refetch } = useQuery(FETCH_UPCOMING_TRANSFERS(permissions), {
    variables: {
      input: {
        filter,
        pagination: {
          perPage: pagination.perPage,
          offSet: (pagination.page - 1) * pagination.perPage,
          sortField: 'state',
        },
      },
      skipErrorHandler: true,
    },
    skip: useCustodianUpcomingTransfers,
    onCompleted: (data) => {
      setUpcomingTransfers(data?.fetchUpcomingTransfers?.upcomingTransfers ?? []);
      setPagination((prev) => ({ ...prev, totalCount: data?.fetchUpcomingTransfers?.totalCount || 0 }));
    },
    onError: () => {
      setErrorState(true);
    },
  });

  const { loading: custodianUpcomingTransfersLoading, refetch: custodianUpcomingTransfersRefetch } = useQuery(FETCH_ACCOUNT_CUSTODIAN_UPCOMING_TRANSACTION(permissions), {
    variables: {
      input: {
        filter,
        pagination: {
          perPage: pagination.perPage,
          offSet: (pagination.page - 1) * pagination.perPage,
        },
      },
      skipErrorHandler: true,
    },
    skip: !useCustodianUpcomingTransfers,
    errorPolicy: 'all',
    onCompleted: (responseData) => {
      setUpcomingTransfers(responseData?.fetchCustodianUpcomingTransactions?.upcomingTransactions ?? []);
      setPagination((prev) => ({ ...prev, totalCount: responseData?.fetchCustodianUpcomingTransactions?.totalCount || 0 }));
    },
    onError: () => {
      setErrorState(true);
    },
  });

  const refetchAll = useCallback(() => {
    if (useCustodianUpcomingTransfers) {
      return custodianUpcomingTransfersRefetch();
    }
    return refetch();
  }, [useCustodianUpcomingTransfers, refetch, custodianUpcomingTransfersRefetch]);

  const [transitionTransfer] = useMutation(TRANSITION_TRANSFER);
  const [cancelScheduledTransfer] = useMutation(CANCEL_SCHEDULED_TRANSFER);

  const transition = (transferId: string, isScheduled: boolean, variables: any) => {
    if (isScheduled) {
      cancelScheduledTransfer({
        variables: { scheduledTransferId: transferId },
        onCompleted: refetchAll,
      });
    } else {
      transitionTransfer({
        variables: { input: { transferId, ...variables } },
        onCompleted: refetchAll,
      });
    }
  };

  useEffect(() => {
    refetchAll();
  }, [pagination.page, pagination.perPage, refetchAll]);

  return (
    <PendingTransfersVisual
      upcomingTransfers={upcomingTransfers}
      loading={loading || custodianUpcomingTransfersLoading}
      transition={transition}
      options={options}
      refetchAll={refetchAll}
      pagination={pagination}
      setPagination={setPagination}
      queryFilter={filter}
      useCustodianData={useCustodianUpcomingTransfers}
      errorState={errorState}
    />
  );
};

import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FETCH_SUB_ACCOUNT } from '.';
import { usePermissions } from '../../providers/userContextProvider';

export function SubAccountBreadcrumb() {
  const { t } = useTranslation();
  const { permissions } = usePermissions();
  const { data } = useQuery(FETCH_SUB_ACCOUNT(permissions), { variables: { id: useParams().subAccountId } });
  const goalName = data?.fetchSubAccount?.subAccount?.goal?.name;
  const subAccountName = `${(goalName) ?? ''} ${goalName ? '-' : ''} ${t(`accountTypes:${data?.fetchSubAccount?.subAccount?.account.type}`)}`;

  return data ? subAccountName : t('client:subAccount');
}

import { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { SxProps } from '@mui/material';
import {
  Autocomplete,
} from '../../2-component';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { RepCode } from '../../../interfaces/repCode';

export const FETCH_REP_CODES_SELECT = gql`
  query fetchRepCodes($input: FetchRepCodesInput!) {
    fetchRepCodes(input: $input) {
      repCodes {
        code
        name
      }
      totalCount
    }
  }
`;

export const RepCodeMultiSelect = ({
  setRepCodes,
  selectedRepCodes,
  label,
  onBlur,
  error,
  disabled,
  sx,
}: {
  setRepCodes: (values: string[]) => void;
  selectedRepCodes: string[];
  label: string;
  size?: 'small' | 'medium' | undefined;
  onBlur?: () => void,
  error?: boolean,
  disabled?: boolean,
  sx?: SxProps,
}) => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const filter: any = { organizationId: activeOrganization.id };
  const { data, loading } = useQuery(FETCH_REP_CODES_SELECT, {
    skip: !activeOrganization.id || !permissions.includes('read:rep_code'),
    variables: {
      input: {
        filter,
        pagination: { perPage: 300, sortField: 'code' },
      },
    },
  });

  if (loading) {
    return <></>;
  }

  const filteredList = data?.fetchRepCodes?.repCodes || [];

  const getRepCodeNames = (repCodes: string[]): RepCode[] => repCodes.map((repCode: string) => {
    const fetchedRepCode = filteredList.find((fetched: RepCode) => fetched.code === repCode);
    return { code: fetchedRepCode?.code, name: fetchedRepCode?.name };
  }).filter((obj: any) => Object.keys(obj).length !== 0);

  const getRepCodeName = (r: RepCode) => `${r.code}${r.name ? ` - ${r.name}` : ''}`;

  return (
    <>
      {permissions.includes('read:rep_code') ? (
        <Autocomplete
          label={label}
          options={filteredList.map((x: RepCode) => ({
            key: x.code,
            label: getRepCodeName(x),
          })) || []}
          value={getRepCodeNames(selectedRepCodes || [])?.map((x) => ({
            key: x.code,
            label: x.code,
          })) || []}
          isOptionEqualToValue={(option: any, value: any) => option.key === value.key}
          multiple
          fullWidth
          onChange={(e: React.SyntheticEvent, value: any) => setRepCodes(value.map((x: any) => x.key))}
          onBlur={onBlur}
          error={error}
          disabled={disabled}
          sx={sx}
        />
      ) : (<></>)}
    </>
  );
};

export default RepCodeMultiSelect;

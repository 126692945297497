import { gql, useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '../../1-primative';
import { TextField } from '../../2-component';
import { CreateNewModal, OrganizationSelect } from '../../3-pattern';
import { UserContext } from '../../../providers/userContextProvider';

const CREATE_ORGANIZATION = gql`
  mutation createOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      organization {
        id
      }
    }
  }
`;

export const NewOrganization = ({ afterCreate }: { afterCreate: (newOrgId: string) => void }) => {
  const { t } = useTranslation(['orgSettings']);
  const { activeOrganization } = useContext(UserContext);
  const [organization, setOrganization] = useState({
    name: '',
    subdomain: '',
    parentId: '',
  });

  const [createOrganization, { loading }] = useMutation(CREATE_ORGANIZATION, {
    variables: {
      input: organization,
    },
  });

  useEffect(() => {
    setOrganization({ ...organization, parentId: activeOrganization.id ?? '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrganization]);

  const createOrg = async () => {
    const response = await createOrganization();
    afterCreate(response.data.createOrganization.organization.id);
  };

  return (
    <CreateNewModal loading={loading} title={t('newOrganizationModal.title')} onSubmit={createOrg} buttonVariant='tonal'>
      <Box display='flex' flexDirection='column' gap={2}>
        <TextField label={t('organizationDetails.name')} fullWidth value={organization.name} onChange={(event: any) => setOrganization({ ...organization, name: event.target.value })} />
        <TextField label={t('organizationDetails.subdomain')} fullWidth value={organization.subdomain} onChange={(event: any) => setOrganization({ ...organization, subdomain: event.target.value })} />
        <OrganizationSelect
          value={organization.parentId}
          label={t('organizationDetails.parent')}
          onChange={(event: any) => setOrganization({ ...organization, parentId: event as string })}
        />
      </Box>
    </CreateNewModal>
  );
};
